/* eslint-disable react/prop-types */
import React from 'react';
import { Table } from 'react-bootstrap';
import { getTranslatedText } from '../../util/helperFunctions';

const BuildingView = ({ appData, buildingData, plotData }) => {
  const getFrontValue = () => {
    return 'Road Width: ' + appData.front_road_width + ' Mtrs';
  };

  const filterFloorWiseArea = (data) => {
    console.log(data, "dataChecking")
  }

  const getRearValue = () => {
    if (appData.rear_open_land === true) {
      return 'Open Land';
    } else if (appData.rear_plot_width) {
      return 'Plot Details: ' + appData.rear_plot_width;
    } else if (appData.rear_road_width) {
      return 'Road Width: ' + appData.rear_road_width + ' Mtrs';
    } else if (appData.rear_existing_building) {
      return 'Existing Building: ' + appData.rear_existing_building;
    } else if (appData.rear_others) {
      return 'Others: ' + appData.rear_others;
    }
  };

  const getSide1Value = () => {
    if (appData.side1_open_land === true) {
      return 'Open Land';
    } else if (appData.side1_plot_width) {
      return 'Plot Details: ' + appData.side1_plot_width;
    } else if (appData.side1_road_width) {
      return 'Road Width: ' + appData.side1_road_width + ' Mtrs';
    } else if (appData.side1_existing_building) {
      return 'Existing Building: ' + appData.side1_existing_building;
    } else if (appData.side1_others) {
      return 'Others: ' + appData.side1_others;
    }
  };

  const getSide2Value = () => {
    if (appData.side2_open_land === true) {
      return 'Open Land';
    } else if (appData.side2_plot_width) {
      return 'Plot Details: ' + appData.side2_plot_width;
    } else if (appData.side2_road_width) {
      return 'Road Width: ' + appData.side2_road_width + ' Mtrs';
    } else if (appData.side2_existing_building) {
      return 'Existing Building: ' + appData.side2_existing_building;
    } else if (appData.side2_others) {
      return 'Others: ' + appData.side2_others;
    }
  };

  return (
    <>
      <div className="plot-deatils-form">
        {/* <h5>{getTranslatedText('heading.building_info')}</h5> */}
        {buildingData.building_info ? (
          <Table responsive className="review-table">
            <tbody>
              <tr>
                <td>{getTranslatedText('label.building_usage')}</td>
                <td>
                  {buildingData.building_info.purpose_of_building
                    ? buildingData.building_info.purpose_of_building
                    : 'N/A'}
                </td>
              </tr>
              {(plotData.application_type !== 'REG' ||
                plotData.actual_plot_area > 200) && (
                  <>
                    <tr>
                      <td>{getTranslatedText('label.building_type')}</td>
                      <td>
                        {buildingData.building_info.building_category
                          ? buildingData.building_info.building_category
                          : 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>{getTranslatedText('label.building_subtype')}</td>
                      <td>
                        {buildingData.building_info.building_subcategory
                          ? buildingData.building_info.building_subcategory
                          : 'N/A'}
                      </td>
                    </tr>
                  </>
                )}
              {plotData.application_type === 'REG' && (
                <tr>
                  <td>Net Plot Area</td>
                  <td>
                    {plotData.net_plot_area_by_citizen
                      ? plotData.net_plot_area_by_citizen
                      : 'N/A'}
                  </td>
                </tr>
              )}
              <tr>
                <td>{getTranslatedText('label.numeric_floors')}</td>
                <td>
                  {buildingData.building_info.proposed_no_of_floors_name
                    ? buildingData.building_info.proposed_no_of_floors_name
                    : 'N/A'}
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <h3>Building Info Not found</h3>
        )}
        {appData.existing_building_proceeding && (
          <div>
            <Table responsive className="review-table">
              <tbody>
                <tr>
                  <td>
                    {getTranslatedText('label.existing_building_proceeding')}
                  </td>
                  <td>
                    {appData.existing_building_proceeding
                      ? appData.existing_building_proceeding
                      : 'N/A'}
                  </td>
                </tr>
                <tr>
                  <td>
                    {getTranslatedText(
                      'label.existing_building_proceeding_date'
                    )}
                  </td>
                  <td>
                    {appData.existing_building_proceeding_date
                      ? appData.existing_building_proceeding_date
                      : 'N/A'}
                  </td>
                </tr>
                <tr>
                  <td>{getTranslatedText('label.exising_file_number')}</td>
                  <td>{appData.existing_building_file_number}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        )}
      </div>
      {plotData.application_type !== 'REG' && (
        <>
          <div className="plot-deatils-form">
            <h5>{getTranslatedText('title.builtup_area')}</h5>
            {buildingData.floor_wise_built_up_area &&
              buildingData.floor_wise_built_up_area.data.length > 0 ? (
              <Table responsive className="review-table">
                <tbody>
                  {buildingData.floor_wise_built_up_area.data.map(
                    (builtup, i) => (
                      <tr key={`builtup-area-${i}`}>
                        <td>{builtup.floor_type}</td>
                        <td>
                          {builtup.area}   {filterFloorWiseArea(plotData.plot_area_type)}
                        </td>
                        {builtup.units && <td>{builtup.units} Units</td>}
                      </tr>
                    )
                  )}
                  <tr>
                    <td>{getTranslatedText('label.builtup_total')}</td>
                    <td>
                      {
                        buildingData.floor_wise_built_up_area
                          .total_built_up_area_display
                      }
                    </td>
                  </tr>
                  {parseFloat(plotData.actual_plot_area) > 200 && (
                    <tr>
                      <td>{getTranslatedText('label.no_of_units')}</td>
                      <td>
                        {buildingData.floor_wise_built_up_area.units_number
                          ? buildingData.floor_wise_built_up_area.units_number
                          : '0'}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            ) : (
              <h3>Builtup Area Not found</h3>
            )}
          </div>

          {!buildingData.existing_building && (
            <>
              {plotData.application_type !== 'REG' && (
                <div className="plot-deatils-form">
                  <h5>
                    {getTranslatedText('heading.schedule_of_boundaries')}
                  </h5>
                  <Table responsive className="review-table">
                    <tbody>
                      <tr>
                        <td>Front ({plotData.front_facing})</td>
                        <td>{getFrontValue()}</td>
                      </tr>
                      <tr>
                        <td>Rear ({plotData.rear_facing})</td>
                        <td>{getRearValue()}</td>
                      </tr>
                      <tr>
                        <td>Side 1 ({plotData.side1_facing})</td>
                        <td>{getSide1Value()}</td>
                      </tr>
                      <tr>
                        <td>Side 2 ({plotData.side2_facing})</td>
                        <td>{getSide2Value()}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}

              {buildingData.abutting_road_front && (
                <div className="plot-deatils-form">
                  <h5>{getTranslatedText('title.abutting')} (front)</h5>
                  <Table responsive className="review-table">
                    <tbody>
                      <tr>
                        <td>
                          {getTranslatedText('label.existing_road_width')}
                        </td>
                        <td>
                          {
                            buildingData.abutting_road_front
                              .front_existing_road_width
                          }{' '}
                          Meters
                        </td>
                      </tr>
                      <tr>
                        <td>{getTranslatedText('label.proposed_road')}</td>
                        <td>
                          {
                            buildingData.abutting_road_front
                              .front_proposed_road_width
                          }{' '}
                          Meters
                        </td>
                      </tr>
                      <tr>
                        <td>{getTranslatedText('label.affected_road')}</td>
                        <td>
                          {
                            buildingData.abutting_road_front
                              .front_depth_road_affected
                          }{' '}
                          Meters
                        </td>
                      </tr>
                      <tr>
                        <td>{getTranslatedText('label.affected_area')}</td>
                        <td>
                          {
                            buildingData.abutting_road_front
                              .front_road_affected_area
                          }{' '}
                          {plotData.plot_area_type}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
              {buildingData.abutting_road_side1 && (
                <div className="plot-deatils-form">
                  <h5>
                    {getTranslatedText('title.abutting_road_details')} (Side
                    1)
                  </h5>
                  <Table responsive className="review-table">
                    <tbody>
                      <tr>
                        <td>
                          {getTranslatedText('label.existing_road_width')}
                        </td>
                        <td>
                          {
                            buildingData.abutting_road_side1
                              .side1_existing_road_width
                          }{' '}
                          Meters
                        </td>
                      </tr>
                      <tr>
                        <td>{getTranslatedText('label.proposed_road')}</td>
                        <td>
                          {
                            buildingData.abutting_road_side1
                              .side1_proposed_road_width
                          }{' '}
                          Meters
                        </td>
                      </tr>
                      <tr>
                        <td>{getTranslatedText('label.affected_road')}</td>
                        <td>
                          {
                            buildingData.abutting_road_side1
                              .side1_depth_road_affected
                          }{' '}
                          Meters
                        </td>
                      </tr>
                      <tr>
                        <td>{getTranslatedText('label.affected_area')}</td>
                        <td>
                          {
                            buildingData.abutting_road_side1
                              .side1_road_affected_area
                          }{' '}
                          {plotData.plot_area_type}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
              {buildingData.abutting_road_side2 && (
                <div className="plot-deatils-form">
                  <h5>
                    {getTranslatedText('title.abutting_road_details')} (Side
                    2)
                  </h5>
                  <Table responsive className="review-table">
                    <tbody>
                      <tr>
                        <td>
                          {getTranslatedText('label.existing_road_width')}
                        </td>
                        <td>
                          {
                            buildingData.abutting_road_side2
                              .side2_existing_road_width
                          }{' '}
                          Meters
                        </td>
                      </tr>
                      <tr>
                        <td>{getTranslatedText('label.proposed_road')}</td>
                        <td>
                          {
                            buildingData.abutting_road_side2
                              .side2_proposed_road_width
                          }{' '}
                          Meters
                        </td>
                      </tr>
                      <tr>
                        <td>{getTranslatedText('label.affected_road')}</td>
                        <td>
                          {
                            buildingData.abutting_road_side2
                              .side2_depth_road_affected
                          }{' '}
                          Meters
                        </td>
                      </tr>
                      <tr>
                        <td>{getTranslatedText('label.affected_area')}</td>
                        <td>
                          {
                            buildingData.abutting_road_side2
                              .side2_road_affected_area
                          }{' '}
                          {plotData.plot_area_type}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
              {buildingData.abutting_road_side3 && (
                <div className="plot-deatils-form">
                  <h5>
                    {getTranslatedText('title.abutting_road_details')} (Side
                    3)
                  </h5>
                  <Table responsive className="review-table">
                    <tbody>
                      <tr>
                        <td>
                          {getTranslatedText('label.existing_road_width')}
                        </td>
                        <td>
                          {
                            buildingData.abutting_road_side3
                              .side3_existing_road_width
                          }{' '}
                          Meters
                        </td>
                      </tr>
                      <tr>
                        <td>{getTranslatedText('label.proposed_road')}</td>
                        <td>
                          {
                            buildingData.abutting_road_side3
                              .side3_proposed_road_width
                          }{' '}
                          Meters
                        </td>
                      </tr>
                      <tr>
                        <td>{getTranslatedText('label.affected_road')}</td>
                        <td>
                          {
                            buildingData.abutting_road_side3
                              .side3_depth_road_affected
                          }{' '}
                          Meters
                        </td>
                      </tr>
                      <tr>
                        <td>{getTranslatedText('label.affected_area')}</td>
                        <td>
                          {
                            buildingData.abutting_road_side3
                              .side3_road_affected_area
                          }{' '}
                          {plotData.plot_area_type}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
              <div className="plot-deatils-form">
                <h5>
                  {getTranslatedText('title.abutting_road_details')} (Total)
                </h5>
                <Table responsive className="review-table">
                  <tbody>
                    <tr>
                      <td>{getTranslatedText('label.affected_total')}</td>
                      <td>
                        {buildingData.total_road_affected_area}{' '}
                        {plotData.plot_area_type}
                      </td>
                    </tr>
                    <tr>
                      <td>{getTranslatedText('label.net_plot_area')}</td>
                      <td>{buildingData.net_plot_area_display}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              {buildingData.existing_building && (
                <div className="plot-deatils-form">
                  {/* <h5>{getTranslatedText('title.abutting_road_details')} (Total)</h5> */}
                  <Table responsive className="review-table">
                    <tbody>
                      <tr>
                        <td>
                          Whether the construction commenced on-ground ?
                        </td>
                        <td>
                          {appData.additional_floors_commenced_on_ground
                            ? 'YES'
                            : 'NO'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Whether proposed additional floors construction
                          commenced on ground ?
                        </td>
                        <td>
                          {appData.whether_construction_commenced_on_ground
                            ? 'YES'
                            : 'NO'}
                        </td>
                      </tr>
                      <tr>
                        <td>constructed built up area in sqmtrs</td>
                        <td>{appData.constructed_built_up_area_sqmtrs}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
            </>
          )}
          {buildingData.setback_details &&
            buildingData.setback_details.front_setback && (
              <div className="plot-deatils-form">
                <h5>{getTranslatedText('heading.setback_details')} </h5>
                <Table responsive className="review-table">
                  <tbody>
                    {buildingData.setback_details.front_setback > 0 && (
                      <tr>
                        <td>
                          Front {getTranslatedText('label.setback_table_III')}{' '}
                        </td>
                        <td>
                          {buildingData.setback_details.front_setback} Meters
                        </td>
                      </tr>
                    )}
                    {buildingData.setback_details.other_setback > 0 && (
                      <tr>
                        <td>
                          Other {getTranslatedText('label.setback_table_III')}{' '}
                        </td>
                        <td>
                          {buildingData.setback_details.other_setback} Meters
                        </td>
                      </tr>
                    )}

                    {buildingData.setback_details.front_setback_manual >
                      0 && (
                        <tr>
                          <td>
                            Proposed {getTranslatedText('label.front_setback')}{' '}
                          </td>
                          <td>
                            {buildingData.setback_details.front_setback_manual}{' '}
                            Meters
                          </td>
                        </tr>
                      )}
                    {buildingData.setback_details.rear_setback_manual > 0 && (
                      <tr>
                        <td>
                          Proposed {getTranslatedText('label.rear_setback')}
                        </td>
                        <td>
                          {buildingData.setback_details.rear_setback_manual}{' '}
                          Meters
                        </td>
                      </tr>
                    )}
                    {buildingData.setback_details.side1_setback_manual >
                      0 && (
                        <tr>
                          <td>
                            Proposed{' '}
                            {getTranslatedText('label.side_one_setback')}
                          </td>
                          <td>
                            {buildingData.setback_details.side1_setback_manual}{' '}
                            Meters
                          </td>
                        </tr>
                      )}
                    {buildingData.setback_details.side2_setback_manual >
                      0 && (
                        <tr>
                          <td>
                            Proposed{' '}
                            {getTranslatedText('label.side_two_setback')}
                          </td>
                          <td>
                            {buildingData.setback_details.side2_setback_manual}{' '}
                            Meters
                          </td>
                        </tr>
                      )}
                  </tbody>
                </Table>
              </div>
            )}
          {buildingData.mortgage && buildingData.mortgage.mortgage_reg_no && (
            <div className="plot-deatils-form">
              <h5>{getTranslatedText('heading.mortgage_details')}</h5>
              <Table responsive className="review-table">
                <tbody>
                  <tr>
                    <td>{getTranslatedText('label.mortagage_area')}</td>
                    <td>
                      {buildingData.mortgage.mortgage_area}{' '}
                      {plotData.plot_area_type}
                    </td>
                  </tr>
                  <tr>
                    <td>{getTranslatedText('label.mortgage_floor')}</td>
                    <td>{buildingData.mortgage.mortgage_floor}</td>
                  </tr>
                  <tr>
                    <td>
                      {getTranslatedText('label.mortgage_document_number')}{' '}
                    </td>
                    <td>
                      {buildingData.mortgage.mortgage_reg_no
                        ? buildingData.mortgage.mortgage_reg_no
                        : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td>{getTranslatedText('label.mortgage_date')}</td>
                    <td>
                      {buildingData.mortgage.mortgage_date
                        ? buildingData.mortgage.mortgage_date
                        : 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BuildingView;
