import { useQuery } from '@apollo/client';
import React from 'react'
import { Button, FormLabel, InputGroup, Modal, Tab, Card, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { APPLICATION_CORRECTION_DETAILS } from '../../graphql/query/applicationCorrectionData';
import CorrectionApprove from '../CorrectionTabels';
import ErrorScreen from '../ErrorScreen/index';
import LoadingScreen from '../LoadingScreen/index';
function CorrectionView() {
    const { id } = useParams();

    const { loading, error, data: applicationCorrection, refetch } = useQuery(APPLICATION_CORRECTION_DETAILS, {
        fetchPolicy: 'network-only',
        variables: {
            applicationId: id,
            status: "CORRECTION_COMPLETED"

        }

    })


    function nonDisplayValues(item) {
        if (item.columnName == "mobile" && (item?.newValue !== item.oldValue)) {
            if (item?.newValue !== "") {

                return true
            } else {
                return false
            }

        } else {
            if ((item?.newValue != "")) {
                if (((item?.supportingDocUrl !== "") && (item?.newValue !== item.oldValue)) && item?.oldValue != null) {
                    return true;
                } else {
                    return false
                }
            }
            else {
                return false
            }
        }
    }
    return (
        <div className='main-content'>
            <div className="tv-container" >
                <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className={
                        'tv-header sw-header'
                    }
                >
                    <ol className="breadcrumbs">
                        <li
                            className="active"
                            style={{ cursor: 'pointer', width: "140px" }}

                        >
                            <a href="#!">correction Verification</a>
                        </li>
                        <li style={{ fontSize: "9px" }}>
                            <a href="#!">{applicationCorrection?.applicationCorrectionRequest?.applicationIdentifier}</a>
                        </li>
                    </ol>
                    {/*  */}

                    {/* <div>
                        {show ? <NotesModal handleClose={handleClose} /> : null}
                        {showSidebar && (
                            <div className=" d-flex align-items-center ">
                                <span className="addnote_label_text">
                                     View Notes :
                                </span>
                                <div className=" d-flex add_note_wrapper ml-3  ">
                                    <div
                                        className="viewButton"
                                        type="button"
                                        onClick={() => {
                                            setShow(true);
                                        }}
                                    >
                                        VIEW
                                    </div>
                                    <div
                                        className="addButton"
                                        type="button"
                                        onClick={() => {
                                            setShow(true);
                                        }}
                                    >
                                        
                                    </div>
                                </div>
                            </div>
                        )}
                    </div> */}
                </div>
            </div>
            <Card style={{ height: `calc(100vh - 250px)`, overflowY: "scroll" }}>

                {/* {showSidebarHandler(true)} */}
                {loading && <LoadingScreen />}
                {error && <ErrorScreen message={error} />}
                {/* <ol className="breadcrumbs">
                  <li
                    className="active"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      history.push('/post-verification');
                    }}
                  >
                    <a>Post Verification</a>
                  </li>
                  <li>
                    <a>{props.applicationIdentifier}</a>
                  </li>
                </ol> */}

                <div style={{ width: "100%" }}>
                    <Tab.Container id="left-tabs-example" >
                        <div
                            className="al-header"

                        >
                        </div>
                        <div className="al-content">
                            <Table striped hover width="100%" >
                                <Tab.Content >

                                    <thead>
                                        <tr >
                                            <th style={{ width: "40px" }}>S.No</th>
                                            <th style={{ width: "160px" }} nowrap="true" > Column Name</th>
                                            <th style={{ width: "160px" }} nowrap="true" >Existing</th>
                                            <th style={{ width: "160px" }} nowrap="true" >Proposed </th>
                                            <th style={{ width: "160px" }} nowrap="true">Documents</th>
                                            <th style={{ width: "160px" }}   >Actions</th>

                                            <th style={{ width: "160px" }} >
                                                Remarks
                                            </th>

                                        </tr>

                                    </thead>
                                    {applicationCorrection?.applicationCorrectionRequest != null
                                        && applicationCorrection?.applicationCorrectionRequest?.corrections.length > 0
                                        && applicationCorrection?.applicationCorrectionRequest?.corrections.map((item, index) => {
                                            return <tbody>

                                                {nonDisplayValues(item) ? <tr>
                                                    <td style={{ maxWidth: "40px" }}>
                                                        {index + 1}
                                                    </td>
                                                    <td style={{ maxWidth: "160px" }}>{item?.columnName?.replaceAll('_', ' ')}</td>
                                                    <td style={{ maxWidth: "160px" }}>{item?.oldValue}</td>
                                                    <td style={{ maxWidth: "160px" }} >{item?.newValue}</td>
                                                    <td style={{ width: "160px" }}>
                                                        {item.supportingDocUrl != "" ? <a
                                                            style={{ color: "blue" }}
                                                            href={item.supportingDocUrl}
                                                            target="_blank"
                                                            alt="uploaded_docs"
                                                        >view doc</a> : "no document"}
                                                    </td>
                                                    <td style={{ maxWidth: "120px" }} >
                                                        {applicationCorrection?.applicationCorrectionRequest?.correctionRequestCompleted !== true ? <> <CorrectionApprove
                                                            index={index}
                                                            applicantype={item?.columnName}
                                                            correctionId={item.id}
                                                            isAproved={item.isApprove}

                                                        />
                                                        </> : <> {item.isApprove == true ? "Approved" : "Rejected"}</>}
                                                    </td>

                                                    <td style={{ cursor: "pointer" }}>
                                                        <p style={{ minWidth: "10px" }}>{item.remark == null ? "N/A" : item.remark == "" ? "------" : <> {item.remark} </>} </p>
                                                    </td>
                                                </tr>
                                                    : ""}
                                            </tbody>
                                        })}


                                </Tab.Content>
                            </Table>
                        </div>
                    </Tab.Container>
                </div>

            </Card>
        </div>
    )
}

export default CorrectionView