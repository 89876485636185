import React, { useState, useEffect, useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { parseString } from 'xml2js';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { AppContext } from '../../AppContext';
import apiConstants from '../../constants/apiConstants';
import environment from 'environment';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
const apiURL = environment.host;

const FileUpload = ({
  Label,
  ID,
  question,
  Filename,
  FileType,
  errors,
  formats,
  touched,
  handleBlur,
  customHandleChange,
  handleCurrentChange,
  onClear,
  isSubmit,
  onSubmitCallback,
  hasComments,
  isNotesButtonLoading
}) => {
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [files, setFiles] = useState(false);
  const [awsUrl, setAwsUrl] = useState('');
  const [urlFields, setUrlFields] = useState(false);
  const [filePath, setFilePath] = useState('');
  const ContextValue = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
const Applicaiton_ID = useParams()
  const handleSubmit = () => {

    setUploadPercentage(0);
    setFiles(false);
    setAwsUrl('');
    setUrlFields(false);
    setFilePath('');

  };

  const fileInputRef = useRef(null);
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleBoxClick = () => {
    fileInputRef.current.click();
  };





  const handleFileUpload = async (event) => {
    // setIsLoading(true)

    const uploadedFile = event.target.files[0];
    const maxSize = 5 * 1024 * 1024;

    if (uploadedFile.type == 'application/pdf' ||
      uploadedFile.type == 'image/png' ||
      uploadedFile.type == 'image/jpeg' ||
      uploadedFile.type == 'image/jpg') {
      // File type is allowed, you can process the file here
      if (uploadedFile && uploadedFile.size > maxSize) {
        alert('File size exceeds the allowed limit.');
      } else {
        handleCurrentChange(event, event?.target?.files[0]?.name, 'fileUploades',question)
        ContextValue.updateDisableFileUpload(true);
        setUploadPercentage(25);
        setFiles(event.target.files[0]);
      
        const extension = event?.target?.files[0]?.type
        const authToken = sessionStorage.getItem('accessToken');
     
        axios
          .get(apiURL + apiConstants.FILE_UPLOAD.URL, {
            headers: {
              Authorization: 'Bearer ' + authToken,
            },
            params: {
              content_type: extension,
            },
          })
          .then((response) => {
            // console.log(response);
            setAwsUrl(response.data.url);
            setUrlFields(response.data.url_fields);
            setUploadPercentage(40);

          })
          .catch(function (error) {
            console.log(error);
          })
      };
    } else {
      alert("Invalid file type. Only PDF , JPEG and PNG files are allowed");

    }
  };


  const handleMedia = (filePath) => {
    setUploadPercentage(85);
    // eslint-disable-next-line no-undef
    const authToken = sessionStorage.getItem('accessToken');
    // eslint-disable-next-line no-undef
    const applicationId = sessionStorage.getItem('id');
    const data = {
      application_id: Applicaiton_ID.id,
      s3_file_path: filePath,
      category: FileType,
    };
    const config = {
      headers: {
        Authorization: 'Bearer ' + authToken,
        Accept: '*/*',
      },
    };
    axios
      .post(apiURL + apiConstants.MEDIA.URL, data, config)
      .then((response) => {
       console.log('response-media', response);
        setUploadPercentage(95);
    
        setUrlFields(false);
        setFilePath(response.data.data.media.file_url);
        customHandleChange(question,question.dbField, {
          parent_answer: response.data.data.media.file_url,
        },'FILE');
        setTimeout(() => {
          setUploadPercentage(100);
          ContextValue.updateDisableFileUpload(false);
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    handleFileUpload(e)
    // Perform any additional processing or file upload logic here
  };
  // console.log('fsdfsdfsd', isLoading)
  useEffect(() => {
    if (urlFields) {
      setUploadPercentage(60);
      // Create an object of formData
      // eslint-disable-next-line no-undef
      const formData = new FormData();

      // Update the formData object
      formData.append('key', urlFields['key']);
      formData.append(
        'success_action_status',
        urlFields['success_action_status']
      );
      formData.append('Content-Type', urlFields['Content-Type']);
      formData.append('Content-Disposition', urlFields['Content-Disposition']);
      formData.append('policy', urlFields['policy']);
      formData.append('x-amz-credential', urlFields['x-amz-credential']);
      formData.append('x-amz-algorithm', urlFields['x-amz-algorithm']);
      formData.append('x-amz-date', urlFields['x-amz-date']);
      formData.append('x-amz-signature', urlFields['x-amz-signature']);
      formData.append('file', files);
      const config = {
        headers: {
          Accept: '*/*',
        },
      };
      try {
        axios.post(awsUrl, formData, config).then(function (response) {
          parseString(response.data, function (err, result) {
            console.log('error', err);
            handleMedia(result.PostResponse.Key[0]);
            setUploadPercentage(80);
          });
        });
      } catch (ex) {
        console.log(ex);
      }
    }
  }, [urlFields]);
  const handleCloseUploading = () => {
    ContextValue.updateDisableFileUpload(false);
    setUploadPercentage(0);
    setUrlFields(false);
    setFilePath(false);
    setFiles(false);
    setAwsUrl('');
    customHandleChange(question,question.dbField, {
      parent_answer: '', 
    },'FILE');
  };
  return (
    <>

      <AppContext.Consumer>
        {(value) => (
          <>
            {uploadPercentage > 0 && files ? (
              <Form.Group >
                <div style={{ minWidth: "300px" }} className="document-uplode">
                  <div className="document-content">
                    <div className="document-dec">
                      {/* <FontAwesomeIcon
                      className="arrow-left"
                      size="3x"
                      icon={faFile}
                    />
                     */}

                      <div className="document-text">
                        <h6>{files && files.name}</h6>

                      </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline", gap: "10px" }} className="document-progressBar">
                      {/* <span style={{fontSize:"12px"  }}>{`KB ${files && files.size}`} </span> */}
                      <ProgressBar
                        now={uploadPercentage}
                        label={`${uploadPercentage}%`}
                        striped
                        style={{ minWidth: "280px", height: "12px" }}
                        animated={uploadPercentage !== 100}
                        variant={uploadPercentage === 100 ? 'success' : "info"}
                      />
                      {uploadPercentage === 100 && (
                        <div className="document-close">
                          <a
                            href={filePath}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img width="20px" src={require('../../assets/images/file.png').default} />
                          </a>
                        </div>
                      )}

                      <div style={{ cursor: "pointer" }} className="document-close">

                        <img onClick={handleCloseUploading}
                          width="20px" src={require('../../assets/images/delete.png').default} />
                      </div>
                    </div>
                  </div>


                </div>
                {isSubmit && !isNotesButtonLoading && (
                  <Button
                    disabled={hasComments === ""}
                    onClick={() => {
                      handleSubmit();
                      onSubmitCallback();
                    }}
                  >
                    Submit
                    <img
                      type="button"
                      src={require('../../assets/images/arrow.svg')}
                    />
                  </Button>
                )}
                {isNotesButtonLoading && (
                  <Button variant="secondary">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </Button>)}
              </Form.Group>
            ) : (
              <>
                <div
                  className={`upload-file-box m-top ${errors && errors[Filename] && touched[Filename] && 'has-error'
                    }`}
                >
                  <div
                    // onDragEnter={handleDragEnter}
                    // onDragLeave={handleDragLeave}
                    // onDragOver={handleDragOver}
                    //  onDrop={handleDrop} 
                    className="file-upload-wrapper"

                    onClick={handleBoxClick}
                  >
                    {/* <img src={require('../../assets/images/cloud-computing (1).png')} /> */}
                    <AiOutlineCloudDownload fontSize='25px' />
                    <input
                      ref={fileInputRef}
                      hidden
                      // style={{ border: '1px dashed red', width: "150px", height: "150px", display: "flex", justifyContent: "center", alignItems: "center" }}
                      type="file"
                      placeholder="Upload the document"
                      onChange={(e) => handleFileUpload(e)}
                      // accept={formats}
                      name={Filename}
                      onBlur={handleBlur}
                      accept=".jpg, .jpeg, .png, .pdf"
                      onSubmit={() => {
                        handleSubmit();
                        onClear();
                      }}
                      disabled={value.disableFileUpload}
                    />
                    <span>{"upload file here"}</span>
                  </div>
                  <Form.Label className="upload-file-text" htmlFor={ID}>
                    {/* <FontAwesomeIcon
                    className="arrow-left"
                    size="3x"
                    icon={faFileUpload}
                  />
                   */}
                    <p>{Label}</p>
                  </Form.Label>
                </div>
                {errors && errors[Filename] && touched[Filename] && (
                  <p>{errors[Filename]}</p>
                )}
                {isSubmit && !isNotesButtonLoading && (
                  <Button
                    disabled={hasComments === ""}
                    onClick={() => {
                      handleSubmit();
                      onSubmitCallback();

                    }}
                  >
                    Submit
                    <img
                      type="button"
                      src={require('../../assets/images/arrow.svg')}
                    />
                  </Button>
                )}
                {isNotesButtonLoading && (
                  <Button variant="secondary">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </Button>)}
              </>
            )}
          </>
        )}
      </AppContext.Consumer>
    </>
  );
};
FileUpload.propTypes = {
  Label: PropTypes.string,
  ID: PropTypes.number,
  question: PropTypes.object,
  Filename: PropTypes.string,
  FileType: PropTypes.string,
  errors: PropTypes.array,
  formats: PropTypes.object,
  touched: PropTypes.array,
  handleBlur: PropTypes.func,
  customHandleChange: PropTypes.func,
  onClear: PropTypes.func,
  isSubmit: PropTypes.bool,
  onSubmitCallback: PropTypes.func,
};
export default FileUpload;

