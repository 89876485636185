import { gql } from '@apollo/client';

export const GET_APPLICATION_QUESTIONS = gql`
  query getQuestionsAndAnswers($id: ID!) {
    officersApplication(id: $id) {
      finalRemark
    }
    applicationForms(id: $id) {
      id
      order
     
      title
      status
      stage
      municipalFinalRemark
      
      stageState {
        dueDate
        completedDate
        finalRemark
        l1Remarks
        l2Remarks
        id
       
        name
        stageOfficers {
          action
          forwardedDate
          id
          officerLevel
          officerName
          officerRole
        }
        parentStageName
        receivedDate
        prevEndDate
        prevStartDate
        status
        submittedDate

      }
      answer {
        data
        dataRevised
        isFeesCompleted
        isChecklistFilled
        action
      }
      oldAnswer {
        data
        dataRevised
        isFeesCompleted
        isChecklistFilled
        action
      }
      fields {
        id
        title
        fieldType
        fileUrl
        defaultValue
        order
        dbField
        fileUrl
        options {
          id
          value
      		questions {
            id
            title
            fieldType
            defaultValue
            order
            dbField
            options {
              id
              value
              questions {
                id
                title
                fieldType
                defaultValue
                order
                dbField
                options{
                  id
                  value
                  questions{
                      id
            title
            fieldType
            defaultValue
            order
            dbField
                  }
                }
              }
            }
          }
        }
        children {
          title
          dbField
          fieldType
          defaultValue
          children {
            title
            fieldType
            defaultValue
            dbField
            children {
              title
              dbField
              fieldType
              defaultValue
            }
          }
        }
      }
    }
  }
`;
