import React, { useState } from 'react';
import './index.scss';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

const ShortFallToggle = ({ onShortFall, index }) => {
  const [shortfall, setShortfall] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleShortFall = () => {
    setShortfall(!shortfall);
    onShortFall(!shortfall);
  };

  return (
    <div
      key={index}
      className="d-flex pb-5 pt-5 justify-content-between align-items-center"
    >
      <div
        className="d-flex flex-column"
        style={{
          padding: '0 35px',
        }}
      >
        <h6>Do you like to Raise Shortfall </h6>
        <span>Turn on to yes to raise</span>
      </div>
      <div
        style={{
          paddingRight: '35px',
        }}
      >
        <Form>
          {/* <input type="checkbox" onClick={handleShortFall} id="test1" />
          <label htmlFor="test1" /> */}
          <label htmlFor="icon-switch">
            <Switch
              checked={checked}
              onChange={() => {
                setChecked(!checked);
                handleShortFall();
              }}
              width={80}
              height={35}
              onColor={'#1c9a5b'}
              offColor={'#1c9a5b'}
              uncheckedIcon={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    fontSize: 15,
                    color: 'white',
                    paddingRight: 2,
                  }}
                >
                  NO
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    fontSize: 15,
                    color: 'white',
                    paddingRight: 2,
                  }}
                >
                  YES
                </div>
              }
              className="react-switch"
              id="icon-switch"
            />
          </label>
          {/* <label>
            <Switch onChange={() => setChecked(!checked)} checked={checked} />
          </label> */}
        </Form>
      </div>
    </div>
  );
};

ShortFallToggle.propTypes = {
  onShortFall: PropTypes.func,
  index: PropTypes.number,
};

export default ShortFallToggle;
