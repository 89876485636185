
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import ErrorScreen from '../components/ErrorScreen';
import LoadingScreen from '../components/LoadingScreen';
import VerificationCards from '../components/VerificationCards';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import RightSideBar from '../components/RightSideBar';
import RightSideBarOCView from '../components/RightSideBar/oc-view';
import PropTypes from 'prop-types';
import { GET_OFFICERS_FORM } from '../graphql/query/officersForm';
import { GET_OFFICERS_APPLICATION } from '../graphql/query/officersApplication';
import history from '../services/history';
import { useParams } from 'react-router';
import { GET_FILTERED_APPLICATIONS } from '../graphql/query/officersFilteredApplications';
import { boolean } from 'yup';

const CommissionerVerification = (props) => {
  // console.log(props,"jhfdhdstdstr");
  const [isShowCase,setIsShowCase] = useState(false)
  const { id } = useParams();
  const {
    loading: officersLoading,
    error: officersError,
    data: officersData,
  } = useQuery(GET_OFFICERS_FORM, {
    variables: { id: props.match.params.id },
  });

  const {
    loading: applicationLoading,
    error: applicationError,
    data: applicationData,
  } = useQuery(GET_OFFICERS_APPLICATION, {
    fetchPolicy: 'cache-first',
    variables: { id: props.match.params.id },

  });
  // useEffect(() => {

  // }, [])

  const { loading, error, data:ShowCaseData, refetch } = useQuery(
    GET_FILTERED_APPLICATIONS,
    {
      fetchPolicy: 'cache-first',
    
      variables: {
        filter: "REVIEW",
        searchParam: "",
        receivedDateParam: "",
        status: [],
        applicationType: [],
        page: 1,
      },
    }
  );

  // console.log(ShowCaseData?.applicationsByDateAndTypeFilter?.[0]?.records,"ShowCaseData")
  let sortedData;
let newsortedData;
  if (ShowCaseData && ShowCaseData.applicationsByDateAndTypeFilter?.[0]?.records.length) {
    const cloneOfficersApplications = [
      ...ShowCaseData.applicationsByDateAndTypeFilter?.[0]?.records,
    ];

    sortedData = cloneOfficersApplications;
    sortedData.filter((item)=>{
    if(item.id ==  props.match.params.id ){
      // setIsShowCase(item.isShowCauseRaised)
      // sessionStorage.setItem("isverifieds",item.isShowCauseRaised)
      //  console.log(item);
      newsortedData=item.isShowCauseRaised;
      }
    })
    // console.log(newsortedData,'newsortedData');
    // if (
    //   permissionLevel === PERMISSIONS_LEVELS.SITE_VERIFICATION ||
    //   permissionLevel === PERMISSIONS_LEVELS.TITLE_VERIFICATION
    // ) {
      // sortedData = sortedData.filter(
      //   (app) => app.status !== 'FEE_VERIFICATION' // update this if fee-verification status is comming in another form
      // );
    // }
  }
  // console.log(sortedData,"sydhfsortedData");
  const QUESTIONS_PER_CARD = 5;
  const [officersFormDataGrouped, setOfficersFormDataGrouped] = useState([]);
  if (officersData && officersData.length) {
    const officersFormData = [...officersData.officersForm.fields];
    let officersFormDataGrouped = [];
    const size = QUESTIONS_PER_CARD;
    while (officersFormData.length > 0) {
      officersFormDataGrouped.push(officersFormData.splice(0, size));
    }
    setOfficersFormDataGrouped(officersFormDataGrouped);
  }

  function checkUserLogin () {
    const loginTOken = sessionStorage.getItem('accessToken')
    if(loginTOken != null || loginTOken != "" || loginTOken != undefined) {
      return true
    }else {
      return false
    }
  }

  // console.log(applicationData,'Checking123456')
  return (
    <>
    {/* <IdleTimer/>  */}
      <Header />
      <Sidebar />
      {/* remove this once the api is working */}
      <VerificationCards
        officersFormDataGrouped={officersFormDataGrouped}
        pageType={history.location.state && history.location.state.pageType}
        applicationId={id}
        applicationStatus={history.location.state?.status}
        applicationIdentifier={
          applicationData?.officersApplication.applicationIdentifier
        }
        applicationType={
          applicationData?.officersApplication.applicationType
        }
        isloading={applicationLoading}
        // newsortedData={newsortedData}
        isShowCauseRaised={applicationData?.officersApplication?.isShowCauseRaised}
        isShowCauseRaisedDocUpload = {applicationData?.officersApplication.isShowCauseRaisedDocUpload}
        isShowCauseSevenDaysElapsed={applicationData?.officersApplication?.isShowCauseSevenDaysElapsed}
      />

      {officersLoading && <LoadingScreen />}
      {officersError && <ErrorScreen message={officersError} />}
      {officersFormDataGrouped && officersFormDataGrouped.length && (
        <div>
          <VerificationCards
            officersFormDataGrouped={officersFormDataGrouped}
            pageType={history.location.state && history.location.state.pageType}
            applicationStatus={history.location.state?.status}
            applicationId={id}
            applicationIdentifier={
              applicationData?.officersApplication.applicationIdentifier
            }
            applicationDataStatus = {applicationData?.officersApplication.status}

            applicationType={
              applicationData?.officersApplication.applicationType
            }
            isloading={applicationLoading}
            // newsortedData={newsortedData}
            isShowCauseRaised={applicationData?.officersApplication?.isShowCauseRaised}
            isShowCauseRaisedDocUpload = {applicationData?.officersApplication.isShowCauseRaisedDocUpload}
            isShowCauseSevenDaysElapsed={applicationData?.officersApplication?.isShowCauseSevenDaysElapsed}

          />
        </div>
      )}

      <div className="main-content">
        {applicationLoading && (
          <div className="ad-container">
            <LoadingScreen />
          </div>
        )}
        {applicationError && (
          <div className="ad-container">
            <ErrorScreen message={applicationError} />
          </div>
        )}
        {props.match.params.type === "occ" ?
          <RightSideBarOCView />
          :
          <RightSideBar />}
      </div>
    </>
  );
};

CommissionerVerification.propTypes = {
  match: PropTypes.object,
};

export default CommissionerVerification;

