import React, { useState } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { Table, Button } from 'react-bootstrap';

export default function ChasingActionPanel() {
  const [sms, setSms] = useState(false);
  const [mail, setMail] = useState(false);

  let details = [...Array(25)].map((e, index) => (
    <tr key={index} className="fs-12" id="chasing-row">
      <td className="align-middle">SaiKiranK</td>
      <td className="align-middle">Revenue</td>
      <td className="text-primary align-middle pointer">
        60
        <img
          src={require('../assets/images/arrowBlue.svg')}
          alt=""
          className="pl-2 pb-1"
        />
      </td>
      <td className="align-middle">25</td>
      <td className="align-middle">25</td>
      <td className="align-middle">10</td>
      <td className="d-flex">
        <Button
          variant={sms ? 'outline-success' : 'outline-secondary'}
          onClick={() => setSms(!sms)}
          className="d-flex align-items-center justify-content-around"
        >
          {sms ? (
            <img src={require('../assets/images/sms_active.svg')} alt="" />
          ) : (
            <img src={require('../assets/images/sms.svg')} alt="" />
          )}
          <span className="align-middle">SMS</span>
          {sms ? (
            <img src={require('../assets/images/success.svg')} alt="" />
          ) : (
            <img src={require('../assets/images/notSuccess.svg')} alt="" />
          )}
        </Button>
        <Button
          variant={mail ? 'outline-success' : 'outline-secondary'}
          onClick={() => setMail(!mail)}
          className="d-flex align-items-center justify-content-around"
        >
          {mail ? (
            <img src={require('../assets/images/mail_active.svg')} alt="" />
          ) : (
            <img src={require('../assets/images/mail.svg')} alt="" />
          )}
          <span className="align-middle">MAIL</span>
          {mail ? (
            <img src={require('../assets/images/success.svg')} alt="" />
          ) : (
            <img src={require('../assets/images/notSuccess.svg')} alt="" />
          )}
        </Button>
        <div id="chasing-call" />
      </td>
    </tr>
  ));

  let sendButton;
  if (sms || mail) {
    sendButton = (
      <Button
        variant="success"
        size="sm"
        className="send-btn d-flex align-items-center justify-content-around"
      >
        <span className="text-uppercase">Send</span>
        <img src={require('../assets/images/arrow.svg')} alt="" />
      </Button>
    );
  } else {
    sendButton = <></>;
  }
  return (
    <div>
      <Header />
      <Sidebar />
      <div className="chasing-content" style={{ backgroundColor: '#F8F8F8' }}>
        <div className="my-3 ml-4 pl-2 pt-3 font-weight-bold">Action Panel</div>
        <div className=" bg-white">
          {sendButton}
          <Table id="actionPanel" striped bordered hover>
            <thead>
              <tr>
                <th>OFFICER NAME</th>
                <th>DEPARTMENT</th>
                <th>FILES COUNT</th>
                <th>GREEN</th>
                <th>ORANGE</th>
                <th>RED</th>
                <th />
              </tr>
            </thead>

            <tbody>{details}</tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
