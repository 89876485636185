import React, { useState, useRef, memo, useCallback, useEffect, useContext } from 'react';
import InputComponent from './InputComponent';
import PropTypes from 'prop-types';
import { getQuestionsWithLink } from '../../../util/helperFunctions';
import { AppContext } from '../../../AppContext';
let checkboxvalue;
const Question = memo(
  ({
    childAnswerState,
    question,
    onChange,
    validationReview,
    handleNOCmodal,
    sno,
    relativeIndex,
    ApprovalFor,
    displayChildQuestion,
    applicationType,
    preFilledAnswers = null,
  }) => {
 console.log(applicationType ,"ApprovalFor")
    const [curAnsState, setCurAnsState] = useState('');
    const treeAnsState = useRef({});
    const [getAnswer, setGetAnswer] = useState("")
    const [itemValue, setItemValue] = useState([])
    const ContextValue = useContext(AppContext);
    const [display, setDisplay] = useState(false)
    // let childQues = question?.children?.map((item)=>item)
    // console.log(childQues.length,"question1");
    useEffect(() => {
      console.log(curAnsState, 'curAnsState');
    }, [curAnsState])
    useEffect(() => {
      if (
        preFilledAnswers &&
        preFilledAnswers.hasOwnProperty(question['dbField']) &&
        preFilledAnswers[question['dbField']].parent_answer
      ) {
        setCurAnsState(preFilledAnswers[question['dbField']].parent_answer);
      }
    }, []);

    function isCurrentAnswerBlank(str) {
      // debugger                
      //   if (
      // (question.dbField == "site visit photograph East: ") ||
      // (question.dbField == "site visit photograph South:   ") ||
      // (question.dbField == "site visit photograph North:   ") ||
      // (question.dbField == "site visit photograph West:   ") ||
      // (question.dbField == "Attach/Upload photographs of the infrastructural developments completed in the layout.") 
      // ){
      if (str === '' || str === null) return true;
      // }

    }

    const propagate = () => {
      // console.log(treeAnsState.current,"treeAnsState.current")

      onChange(question, question.dbField, treeAnsState.current, checkboxvalue);
      // console.log(question, "checkong");
    };

    const handleCurrentChange = (e, val, isFile, questionData) => {
      // let newvalue = val.join(",")]
      // //debugger;

      // //debugger;
      // setGetAnswer(val)
      if (isFile == 'fileUploades') {
        setCurAnsState(val)
        checkboxvalue = val
      }
      else if (e == "dropdown") {
        checkboxvalue = val.join(',')
        if (isCurrentAnswerBlank(val.join(","))) treeAnsState.current = {};
        else if (
          !displayChildQuestion(val.join(",")) &&
          !isCurrentAnswerBlank(val.join(","))) {

          treeAnsState.current = { parent_answer: val.join(",") };
        } else treeAnsState.current.parent_answer = val.join(",");
        propagate();
        const newCurAnsState = val.join(",");

        setCurAnsState(newCurAnsState);
      }
      else {
        checkboxvalue = val
        if (e === 'directValue') {

          treeAnsState.current.parent_answer = val;
          propagate();
          const newCurAnsState = val;
          setCurAnsState(newCurAnsState);
        } else {
          if (val) {
            e.target.value = val;
          }
          if (isCurrentAnswerBlank(e.target.value)) treeAnsState.current = {};
          else if (

            !displayChildQuestion(e.target.value) &&
            !isCurrentAnswerBlank(e.target.value) // handled above but for non-ambiguity
          ) {
            // remove child answer data if child  not shown
            treeAnsState.current = { parent_answer: e.target.value };
          }
          else {
            treeAnsState.current.parent_answer = e.target.value;
            checkboxvalue = e.target.value
            // if (treeAnsState.current[question.dbField].parent_answer !== e.target.value) {
            //   const newData = questionData.map(item => item.value != e.target.value)

            //   for (var i = 0; i < newData.length; i++) {
            //     delete treeAnsState.current[newData.question[i].dbField];
            //   }
            // }
            // } else {

            // }
            // console.log(treeAnsState.current[question.dbField].parent_answer , "")
          }
          propagate();
          const newCurAnsState = e.target.value;

          setCurAnsState(newCurAnsState);
        }
      }
    };



    const handleOptionQuestions = (question, checkboxvalue, dbField, value) => {
      //debugger;
      for (var i = 0; i < question.options.length; i++) {
        const option = question.options[i];
        const isYesOption = option.value === "Yes";
        const isNoOption = option.value === "No";

        if ((isYesOption && checkboxvalue === 'true' || isNoOption && checkboxvalue === 'false') && option?.questions?.length > 0) {
          handleChildQuestions(option.questions, checkboxvalue, dbField, value, option);
        } else if ((!isYesOption && checkboxvalue === 'false' || isNoOption && checkboxvalue === 'true') && option.questions?.length > 0) {
          handleChildQuestions(option.questions, checkboxvalue, dbField, value, option);
        } else if ((checkboxvalue === 'false' && isYesOption || checkboxvalue === 'true' && isNoOption) && option.questions?.length > 0) {
          handleChildQuestions(option.questions, checkboxvalue, dbField, value, option);
        } else if (question.defaultValue === "true" && checkboxvalue == 'false' && question.children?.length > 0) {
          handleChildQuestions(option.questions, checkboxvalue, dbField, value, option);
        } else if (question.defaultValue === "false" && checkboxvalue == 'true' && question.children?.length > 0) {
          handleChildQuestions(option.questions, checkboxvalue, dbField, value, option);
        } else {
          handleNonChildOption(option, checkboxvalue, value);
        }
      }
    };


    const handleChildrenQuestions = (question, dbField, checkboxvalue) => {
      //debugger;
      for (var i = 0; i < question.children.length; i++) {
        const NewdbField = question.children[i].dbField
        if (dbField == NewdbField)

          childAnswerState.current[NewdbField] = checkboxvalue;
      }
    };
    const handleChildQuestions = (questions, checkboxvalue, dbField, value, option) => {
      //debugger;
      if (questions && questions.length > 0 && !treeAnsState.current.hasOwnProperty(dbField)) {
        for (var j = 0; j < questions.length; j++) {
          const childDbField = questions[j].dbField;

          if (childDbField !== undefined && ((option.value == 'No' && value['parent_answer'] == 'false') || (option.value == 'Yes' && value['parent_answer'] == 'true'))) {
            childAnswerState.current[dbField] = checkboxvalue;
            // if (value.hasOwnProperty(childDbField) == false)
            childAnswerState.current[childDbField] = "";
          }
          // Recursively call handleChildQuestions for nested questions
          handleChildQuestions(questions[j].options, checkboxvalue, childDbField, value);
        }
      }
    };


    const handleNonChildOption = (option, checkboxvalue, value) => {
      for (var j = 0; j < option.questions?.length; j++) {
        const childDbField = option.questions[j]?.dbField;

        if (childDbField in childAnswerState.current) {
          delete childAnswerState.current[childDbField];
        }
      }
    };

    const handleChildAnswerChange = (question, dbField, value, checkboxvalue) => {
      treeAnsState.current[dbField] = value;
      propagate();
      // debugger
      if (question.fieldType == "CHECKBOX") {
        if (question.dbField === dbField && question.options && question.options?.length > 0) {
          handleOptionQuestions(question, checkboxvalue, dbField, value);
        } else if (question.dbField === dbField && question.children?.length > 0) {
          handleChildrenQuestions(question, dbField, checkboxvalue)
        }
      } else if (question.fieldType == "DROP_DOWN" || question.fieldType == "TEXT_SHORT" || question.fieldType == "TEXT_LONG" || question.fieldType == "FILE") {
        childAnswerState.current[dbField] = checkboxvalue;
      }
    };


    const memoizedHandleAnswerChange = useCallback(handleChildAnswerChange, [
      treeAnsState,
    ]);

    function displayChildQuestion(ans = curAnsState) {
      //  debugger
      if (ans?.toLowerCase() === (question.defaultValue || '').toLowerCase()) {
        return false;
      }
      if (isCurrentAnswerBlank(ans)) {
        return false;
      }

      return true; // show child if the current value is not default & non empty  tv-questions1
    }
    const handleCloseDropDown = () => {
      if (display == true) {
        setDisplay(false)
      }
    }
    const isApprovalFor = () => {
      if (ApprovalFor == "FINAL_LAYOUT" || ApprovalFor == "OCCUPANCY") {
        return true
      } else {
        return false
      }
    }

    return (
      <div>
        <div className="tv-questions">
          {question.fieldType && (
            <>

              <div onClick={handleCloseDropDown} style={{ display: "flex", alignItems: "baseline", gap: "10px" }} className={ApprovalFor !== 'OCCUPANCY' && ''}>
                <span style={{ fontSize: "0.8em" }}>   {sno}  </span>
                <div className='tv-questions-container'>
                  <div className='d-flex justify-content-between'>
                    <p>{getQuestionsWithLink(question.title)} </p>
                    {question.fileUrl != null && applicationType == "SINGLE WINDOW"  && <a style={{ color: "blue", fontSize: "0.8em" }} target='_blank' href={question.fileUrl}>view document</a>}
                  </div>


                  <div>
                    {validationReview && isCurrentAnswerBlank(curAnsState) ? (
                      <a style={{ color: 'red', fontWeight: 'lighter' }}>
                        * required
                      </a>
                    ) : (
                      ''
                    )}
                    <InputComponent
                      onChange={onChange}
                      handleCurrentChange={handleCurrentChange}
                      question={question}
                      handleNOCmodal={handleNOCmodal}
                      preFilledAnswers={preFilledAnswers}
                      applicationType={applicationType}
                      display={display}
                      setDisplay={setDisplay}
                    />

                  </div>
                </div>
              </div>
              {question?.options?.length > 0 ?
                question?.options?.map((childOption, index) => {
                  console.log(childOption, 'childOption')
                  if (((childOption.value.toLowerCase() === 'yes' && curAnsState.toLowerCase() === 'true') || childOption.value.toLowerCase() === curAnsState.toLowerCase()) && childOption?.questions?.length > 0) {
                    return childOption?.questions?.map((childQuestions, i) => {
                      console.log(childQuestions, 'childQuestions')
                      return (
                        <div className='childAnswerQuestions_wrapper'>
                          {/* <div> {question?.children?.length == 0  ?  <span>{sno}.{index + 1}</span> :    'Q'} </div> */}
                          {/* {childOption?.questions[0].options?.question.length ==0 &&   <span>{sno }.{i + 1}.</span> } */}
                          <div>

                            <Question
                              onChange={memoizedHandleAnswerChange}
                              question={childQuestions}
                              key={i}
                              ApprovalFor={ApprovalFor}
                              childAnswerState={childAnswerState}
                              // sno={}
                              applicationType={applicationType}
                              validationReview={validationReview}
                              preFilledAnswers={preFilledAnswers}
                            />
                          </div>

                        </div>
                      );
                    })
                  }
                  if (childOption.value.toLowerCase() === 'no' && curAnsState.toLowerCase() === 'false' && childOption?.questions?.length > 0) {
                    return childOption?.questions?.map((childQuestions, i) => {
                      return (
                        <div className='childAnswerQuestions_wrapper'>

                          <Question
                            onChange={memoizedHandleAnswerChange}
                            question={childQuestions}
                            key={i}
                            applicationType={applicationType}
                            ApprovalFor={ApprovalFor}
                            // sno={sno - i + 1}
                            validationReview={validationReview}
                            childAnswerState={childAnswerState}
                            preFilledAnswers={preFilledAnswers}
                          />
                        </div>

                      )
                    })
                  }
                })
                :
                <div >
                  {question?.children?.map((childQuestion, i) => {
                    if (displayChildQuestion())
                      return (
                        <div className='childAnswerQuestions'>
                          <div className='childAnswerQuestions_wrapper'>
                            {/* <span>{sno}.{i + 1}</span> */}
                            <Question
                              onChange={memoizedHandleAnswerChange}
                              question={childQuestion}
                              key={i}
                              ApprovalFor={ApprovalFor}
                              applicationType={applicationType}
                              validationReview={validationReview}
                              preFilledAnswers={preFilledAnswers}
                            />
                          </div>
                        </div>


                      );
                  })}
                </div>
              }

            </>

          )}
        </div>
      </div>
    );
  }
);

Question.propTypes = {
  question: PropTypes.any,
  onChange: PropTypes.func,
  validationReview: PropTypes.bool,
  handleNOCmodal: PropTypes.func,
  preFilledAnswers: PropTypes.any,
};

Question.defaultProps = {
  handleNOCmodal: () => { },
};

Question.displayName = 'Question';
export default Question;
