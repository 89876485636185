import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import ErrorScreen from '../components/ErrorScreen';
import LoadingScreen from '../components/LoadingScreen';
import QuestionCards from '../components/QuestionCards';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import RightSideBar from '../components/RightSideBar';
import RightSideBarOCView from '../components/RightSideBar/oc-view';
import { AppContext } from './../AppContext';
import PropTypes from 'prop-types';
import { GET_OFFICERS_APPLICATION } from '../graphql/query/officersApplication';
import { GET_APPLICATION_QUESTIONS } from '../graphql/query/applicationQuestions';
import history from '../services/history';
import { useParams } from 'react-router-dom';
export default function TechnicalVerification(props) {
  // console.log(props, "applicaiton_id")
  const [showSidebar, setShowSidebar] = useState(true);
  const { officerType } = useContext(AppContext);

  const showSidebarHandler = (value) => {
    setShowSidebar(value);
  };
  const { id } = useParams();
  const {
    loading: officersLoading,
    error: officersError,
    data: officersData,
    refetch: officersRefetch,
  } = useQuery(GET_APPLICATION_QUESTIONS, {
    variables: { id: props.match.params.id },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const sortCompare = (a, b) => {
    if (!b.order || parseInt(a.order, 10) < parseInt(b.order, 10)) {
      return -1;
    }
    if (!a.order || parseInt(a.order, 10) > parseInt(b.order, 10)) {
      return 1;
    }
    return 0;
  };

  const QueryOfficersForm = () => {
    if (officersLoading) return <LoadingScreen />;
    if (!(officersData && officersData.applicationForms))
      return <LoadingScreen />;

    if (officersError) {
      // loading data from json for development
      return <ErrorScreen message={officersError} retry={officersRefetch} />;
    }
    let titleRelatedForm = officersData.applicationForms.find((form) =>
      form.title.toLowerCase().includes('tech')
    );

    let FormInstanceId = officersData.applicationForms.filter((form) =>
    form.title
      .toLowerCase()
    // .includes(permissionLevel === 'SITE_VERIFICATION' ? 'site' : 'tech')
  ).map((item)=>item.id)

    const allStageStatus = officersData.applicationForms.map(
      (obj) => obj.stageState
    );
    if (!titleRelatedForm) {
      return (
        <ErrorScreen
          message={{ message: 'No form available for this application' }}
          retry={officersRefetch}
        />
      );
    }
    var officersFormData = [...titleRelatedForm.fields];
    var officersFormDataSorted = officersFormData.sort(sortCompare);
    if (props.match.params.type === 'occ' && officerType !== 'GHMC') {
      officersFormDataSorted.push({
        children: [],
        dbField: 'deviation_table',
        defaultValue: 'true',
        fieldType: 'TABLE',
        id: '5069',
        options: [],
        order: 'L2',
        title: 'Deviation Table',
      });
    }
    var officersFormDataGrouped = [];
    const size = 3;
    while (officersFormDataSorted.length > 0) {
      officersFormDataGrouped.push(officersFormDataSorted.splice(0, size));
    }
    return (
      <div>
        <QuestionCards
          officersFormDataGrouped={officersFormDataGrouped}
          formId={titleRelatedForm.id}
          recomID = {FormInstanceId}
          verificationType={'Technical Verification'}
          applicationIdentifier={
            applicationData?.officersApplication.applicationIdentifier
          }
          isVerification={true}
          showSidebarHandler={showSidebarHandler}
          showSidebar={showSidebar}
          stageState={allStageStatus}
        />
      </div>
    );
  };

  const {
    loading: applicationLoading,
    error: applicationError,
    data: applicationData,
    refetch: applicationRefetch,
  } = useQuery(GET_OFFICERS_APPLICATION, {
    fetchPolicy: 'cache-first',
    variables: { id: props.match.params.id },
    notifyOnNetworkStatusChange: true,
  });

  // eslint-disable-next-line no-unused-vars
  const QueryApplicationForm = () => {
    if (applicationLoading)
      return (
        <div className="ad-container">
          <LoadingScreen />
        </div>
      );
    if (applicationError)
      return (
        <div className="ad-container">
          <ErrorScreen message={applicationError} retry={applicationRefetch} />
        </div>
      );
    return <RightSideBar />;
  };

  return (
    <>
      <Header />
      <Sidebar />
      {QueryOfficersForm()}
      {/* <div className="main-content">{QueryApplicationForm()}</div> */}
      <div className="main-content">
        {props.match.params.type === 'occ' ? (
          <RightSideBarOCView
            showSidebar={showSidebar}
            showSidebarHandler={showSidebarHandler}
            applicationType={history?.location?.state?.applicationType}
          />
        ) : (
          <RightSideBar
            showSidebar={showSidebar}
            showSidebarHandler={showSidebarHandler}
            applicationType={history?.location?.state?.applicationType}
          />
        )}
      </div>
    </>
  );
}

TechnicalVerification.propTypes = {
  match: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.shape({
      applicationType: PropTypes.string,
    }),
  }),
};
TechnicalVerification.defaultProps = {
  location: {
    state: {
      applicationType: 'SELF CERTIFICATION',
    },
  },
};

