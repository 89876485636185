import React from 'react'
import { useState } from 'react';
import history from '../../services/history';
import { dayDifference } from '../../util/helperFunctions';
import StatusReviewModal from '../StatusReviewModal';
//import { useHistory } from 'react-router-dom';

const classType = {
    red: 'rejected',
    green: 'approved',
    orange: 'inprogress',
    deemed: 'deemed',
};
function getClassFromDueDate(dueDate) {
    const dayDiff = dayDifference(dueDate);

    // if (dayDiff <= 0) return classType.deemed;
    // if (dayDiff <= 3) return classType.red;
    // if (dayDiff <= 6) return classType.orange;
    if (dayDiff < 0) return classType.red;
    return classType.green;
}

function CorrectionList(props) {
    //   const history = useHistory()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleViewButton = (event) => {
        event.stopPropagation();
         if(props.filter == "CORRECTION_VERIFICATION" ) {
            // SUBMITTED
        history.push(`/correction-verification-/${props.data.id}`)
        return;
         } else if (props.filter == 'CORRECTION_COMPLETED') {

            history.push(`/correction-view-/${props.data.id}`)
            return;
         } else if (props.filter == 'SUBMITTED') {
            history.push(`/commisioner-correction-view /${props.data.id}`)
         }
          

    }

    const getStatus = (str) => {
        let result = str.replace(/_/g, ' ');
        if (
            props.data.applicationType === 'SELF CERTIFICATION' ||
            props.data.applicationType === 'REGISTRATION'
        ) {
            if (result === 'REJECTED') {
                return 'REVOKED';
            }
        }
        if (result === 'PROCEEDINGS IN PROGRESS') {
            return 'FINAL PROCESSING';
        }
        if (result === 'DEEMED') {
            return 'DEEMED APPROVED';
        }
        return result;
    };

    const mapClassTypeFlag = {
        rejected: 'red',
        approved: 'green',
        inprogress: 'orange',
        deemed: 'blue',
    };

    const formatDateToString = (date) => {
        date = date.split(' ')[0];
        date = new Date(date);
        var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
        var MM = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
        var yyyy = date.getFullYear();
        return dd + '/' + MM + '/' + yyyy;
    };

    return (

        <tr className={getClassFromDueDate(props.data.dangerDate)}>
            <td nowrap="true">
                <span className="flag">
                    <img
                        alt=""
                        src={require(`../../assets/images/${mapClassTypeFlag[getClassFromDueDate(props.data.dangerDate)]
                            }.svg`)}
                    />
                </span>
            </td>
            <td nowrap="true">{props.index}</td>
            <td
                nowrap="true"
                className="text-left"
                style={{ cursor: 'pointer', }}
                onClick={(event) => {
                    event.stopPropagation();
                    //   setCurrentFlowGlobally(props.pageType);
                    if (props.data.approvalFor === 'OCCUPANCY') {
                        history.push(`/application-details/occ/${props.data.id}`, {
                            applicationType: props.data.applicationType,
                            enableNotes: props.enableNotes,
                        });
                    } else {
                        history.push(`/application-details/${props.data.id}`, {
                            applicationType: props.data.applicationType,
                            enableNotes: props.enableNotes,
                        });
                    }
                }}
            >
                {(props.data.additional === 'YES' ||
                    props.data.approvalFor === 'OCCUPANCY') && (
                        <div className="addition-tag">
                            {props.data.approvalFor === 'OCCUPANCY' ? (
                                <div className="btns-success">
                                    {props.data.referenceApplicationType}
                                </div>
                            ) : (
                                props.data.additional === 'YES' && (
                                    <>
                                        {/* <div className="btns-danger">Additional</div> */}
                                        <div className="btns-success">
                                            {props.data.additionalType}
                                        </div>
                                    </>
                                )
                            )}
                        </div>
                    )}
                <p>{props.data.applicationIdentifier}</p>
                <span>{props.data.applicant.applicantName}</span>
            </td>

            <td nowrap="true">
                {props.data.approvalFor === 'OCCUPANCY'
                    ? formatDateToString(
                        props.data.appliedOn
                            ? props.data.appliedOn
                            : props.data.createdAt
                    )
                    : props.data.applicationType === 'SELF CERTIFICATION'
                        ? formatDateToString(
                            props.data.paymentCompletedAt
                                ? props.data.paymentCompletedAt
                                : props.data.createdAt
                        )
                        : props.data.applicationType === 'REGISTRATION'
                            ? formatDateToString(
                                props.data.appliedOn
                                    ? props.data.appliedOn
                                    : props.data.createdAt
                            )
                            : formatDateToString(props.data.createdAt)}
            </td>

            <td nowrap="true">
                <p>
                    {props.data.approvalFor === 'OCCUPANCY'
                        ? 'OCCUPANCY'
                        : props.data.additional === 'YES'
                            ? 'ADDITIONAL'
                            : props.data.revision === "YES" ? 'REVISION'
                                : props.data.applicationType === 'SELF CERTIFICATION'
                                    ? 'INSTANT APPROVAL'
                                    : props.data.applicationType === 'REGISTRATION'
                                        ? 'INSTANT REGISTRATION'
                                        : props.data.applicationType}
                </p>
            </td>

            <td nowrap="true">
                <p>{props.data.property.actualPlotArea + ' sq.m'}</p>
                <span>{props.data.property.floor?.noOfFloors}</span>
            </td>
{/* 
            <td nowrap="true">
                {props.data.status !== null && (
                    <button
                        onClick={handleShow}
                        style={{ whiteSpace: 'normal' }}
                        className={`btn  ${getClassFromDueDate(props.data.dangerDate)}`}
                    >
                        {props.data.status && getStatus(props.data.status)}
                    </button>
                )}
                <div className="date-container">
                    {show ? (
                        <StatusReviewModal
                            id={props.data.id}
                            handleClose={handleClose}
                            status={props.data.status && getStatus(props.data.status)}
                        />
                    ) : null}
                </div>


            </td> */}
             <td>
                <button
                    onClick={handleViewButton}
                    className={'verify-btn'}
                    style={{
                        fontSize: '12px',
                        width: 'max-content',
                        textTransform: 'capitalize',
                    }}
                >
                    view
                </button>

            </td>
               
        </tr>

    )
}

export default CorrectionList