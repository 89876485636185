import React, { useEffect, useState } from 'react'
import { Nav, Tab, Table } from 'react-bootstrap'
import CorrectionList from './CorrectionList'
import ErrorScreen from '../ErrorScreen/index';
import LoadingScreen from '../LoadingScreen/index';
import {
  Col,
  Row,
} from 'react-bootstrap';
import Pagination from '@material-ui/lab/Pagination';
import { Card } from '@material-ui/core';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { FILTER_LIST_CORRECTION } from '../../util/helperFunctions';

function CorrectionCards(props) {
  // console.log(props.totalPages,'ttotalPages')
  const [count, setCount] = useState({
    correction: 0,
    completed: 0,
  });
  const { permissionLevel, innerCorrectionValue, currentFlow } =
    useContext(AppContext);
  // console.log(innerCorrectionValue,'innerListValue')
  console.log(props, "data")
  const navFilter = () => {
    let filter = innerCorrectionValue;
    if ([FILTER_LIST_CORRECTION.correction].includes(filter)) return 'Correction Inprocess';
    else if ([FILTER_LIST_CORRECTION.completed].includes(filter)) return 'Correction Processed';
    else if ([FILTER_LIST_CORRECTION.Submitted].includes(filter)) return 'Correction Inprocess'
  };
  useEffect(() => {
    const count = {
      correction: 0,
      completed: 0,
    };
    if (props.currentFilter == FILTER_LIST_CORRECTION.correction) {
      count.correction = props.data?.length || 0;
    }
    if (props.currentFilter == FILTER_LIST_CORRECTION.completed) {
      count.completed = props.data?.length || 0;
    }
    setCount(count)
  }, [props.currentFilter])
  return (

    <>

      {/* <div style={{marginLeft:"200px",marginTop:"300px",zIndex:'1000'}}> */}
      <Card>
        {/* {props.loading && <LoadingScreen />} */}
        {props.error && <ErrorScreen message={props.error} retry={props.refetch} />}
        {/* </div> */}
        <div className="al-container filter-badge">
          <Tab.Container id="left-tabs-example" >
            <div
              className="al-header"
              style={{
                width: '100%',

              }}>
              <Nav variant="tabs" style={{ display: 'contents' }}>

                <Nav.Item className="d-flex align-item-center ">
                  <Nav.Link
                    eventKey={navFilter()}
                    active={true}
                  >
                    <div style={{ display: "flex", gap: "20px" }}>

                      <span className="text-capitalize">{navFilter()}</span>

                      <span className="badge">
                        {props.totalCount}
                      </span>
                    </div>


                  </Nav.Link>

                </Nav.Item>
              </Nav>

            </div>
            <div className="al-content">
              <Tab.Content>
                <Table striped hover style={{ textAlign: 'center', width: '100%' }}>
                  <thead>
                    <tr>
                      <th className="" nowrap="true" />
                      <th nowrap="true" style={{ maxWidth: "120px", overFlowX: "scroll" }}>S.No.</th>
                      <th className="text-left" nowrap="true" style={{ maxWidth: "120px", overFlowX: "scroll" }}>
                        Application ID <br /> Applicant Name
                      </th>
                      <th nowrap="true" style={{ maxWidth: "120px", overFlowX: "scroll" }}>
                        Received Date  <p style={{ fontSize: "12px" }}>
                          ( DD/MM/YYYY )
                        </p>
                        {/* <Form.Control
                      type="date"
                      className="filter-input"
                      placeholder="Received Date"
                    /> */}
                      </th>
                      <th nowrap="true" style={{ maxWidth: "120px", overFlowX: "scroll" }}>
                        Application Type
                        {/* <div className="application-list-item filter-dropdown">
                      <Dropdown style={{ cursor: 'pointer', width: 'fit-content' }}>
                        <Dropdown.Toggle>Verify</Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{ width: 'fit-content' }}
                          flip={false}
                        >
                          <Dropdown.Item style={{ cursor: 'pointer', paddingRight: '0px' }}>ALL</Dropdown.Item>
                          <Dropdown.Item style={{ cursor: 'pointer', paddingRight: '0px' }}>INSTANT REGISTRATION</Dropdown.Item>

  // Example code using Axios:
  const formData = new FormData();                          <Dropdown.Item style={{ cursor: 'pointer', paddingRight: '0px' }}>INSTANT APPROVAL</Dropdown.Item>
                          <Dropdown.Item style={{ cursor: 'pointer', paddingRight: '0px' }}>ADDITIONAL</Dropdown.Item>
                          <Dropdown.Item style={{ cursor: 'pointer', paddingRight: '0px' }}>OCCUPANCY</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div> */}
                      </th>
                      <th nowrap="true" style={{ maxWidth: "120px", overFlowX: "scroll" }}>Plot Area</th>

                      <th nowrap="true" style={{ maxWidth: "120px", overFlowX: "scroll" }}>
                        Action
                      </th>
                    </tr>
                  </thead>

                  {props.data != undefined ? <tbody>

                    {props.data &&

                      props.data.length > 0 && props.data.map((item, index) => {
                        return <>
                          <CorrectionList
                            key={index}
                            data={item}
                            index={index + 1}
                            filter={props.actualFilter}
                          />
                        </>

                      })}

                  </tbody> : ""}





                </Table>


                {props.data != undefined ? <div
                  className="p-3"
                  style={{ marginTop: '45px', marginBottom: '45px' }}
                >
                  <Row>
                    <Col md="12" className='PageNate' >
                      <Pagination
                        count={props.totalPages}
                        page={props.currentPage}
                        onChange={(event, value) => {
                          props.setCurrentPage(value);
                        }}
                      />
                    </Col>
                  </Row>
                </div> : " "}

              </Tab.Content>
            </div>

          </Tab.Container>
          {/* fuck yoiu */}


        </div>
      </Card>
    </>
  )
}

export default CorrectionCards