import React, { useContext } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { GET_OFFICERS_APPLICATION } from '../../graphql/query/officersApplication';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { AppContext } from './../../AppContext';
import { AiOutlineClose } from "react-icons/ai";
function StatusReviewModal(props) {
  console.log(props, 'props');
  const { officerType } = useContext(AppContext);
  const close = props.handleClose;

  const { loading, data: { officersApplication } = {} } = useQuery(
    GET_OFFICERS_APPLICATION,
    {
      fetchPolicy: 'network-only',
      variables: { id: props.id },
    }
  );
  const { stageStates = {} } = officersApplication || {};
  var itemsArray = Array.from(stageStates);
  const filteredStageStates = itemsArray.filter(
    (item) => item.name !== 'VERIFICATION'
  );

  console.log(officersApplication, 'stageStates')
  function getAllStagesData(item) {
    const ans = [];
    stageStates.forEach((state) => {

      state.stageOfficers.forEach((el, index) => {
        let stageOfficerInfo = {
          ...el,
          name:
            state.name === 'TECHNICAL VERIFICATION' &&
              state.stageOfficers.length > 1 &&
              el.officerLevel === 'L2'
              ? 'TECHNICAL VERIFICATION - FEE ADJUSTMENT'
              : state.name,
          receivedDate:
            el.officerLevel === 'L2'
              ? state.stageOfficers.find((obj) => obj.officerLevel === 'L1')
                ?.forwardedDate
              : officersApplication.applicationType === 'SELF CERTIFICATION'
                ?
                // moment(
                state.receivedDate
                // ).format('DD MMMM YYYY')
                : moment(
                  state.receivedDate
                ).format('DD MMMM YYYY'),

          completedDate:
            el.officerLevel === 'L2'
              ? state.stageOfficers.find((obj) => obj.officerLevel === 'L1')
                ?.forwardedDate
              : officersApplication.applicationType === 'SELF CERTIFICATION'
                ?
                moment(
                  state.completedDate
                ).format('DD MMMM YYYY')                // ).format('DD MMMM YYYY')
                :
                moment(
                  state.completedDate
                ).format('DD MMMM YYYY'),
          
          forwardedDate:
            el.officerLevel === 'L2'
              ? state.stageOfficers.find((obj) => obj.officerLevel === 'L1')
                ?.forwardedDate
              : officersApplication.applicationType === 'SELF CERTIFICATION'
                ?
                moment(
                  state.forwardedDate
                ).format('DD MMMM YYYY')
                // ).format('DD MMMM YYYY')
                :
                moment(
                  state.forwardedDate
                ).format('DD MMMM YYYY')
          ,
          dueDays:
          el.officerLevel === 'L2'
            ? state.stageOfficers.find((obj) => obj.officerLevel === 'L1')
              ?.dueDays
            : officersApplication.applicationType === 'SELF CERTIFICATION'
              ?
              state.dueDays
              // ).format('DD MMMM YYYY')
              :
              state.dueDays ,
          status: state.status,
        };
        ans.push(stageOfficerInfo);
      });

    });
    const sortCompare = (a, b) => {
      if (a.officerLevel > b.officerLevel) return -1;
      if (a.officerLevel === b.officerLevel) {
        if (a.id < b.id) return -1;
      }
      return 1;
    };
    const sortedAns = ans.sort(sortCompare);
    return sortedAns;
  }

  function getStatus(status) {

    if (status === 'IN_PROCESS' || status === 'In_process') return 'inProcess';
    if (status === 'SUBMITTED' || status === 'Submitted') return 'deemed';
    if (status === 'RECOMMENDED') return 'recommended';
    if (status === 'NOT_RECOMMENDED') return 'notRecommend';
    if (status === 'SHOW_CAUSE') return 'warning';
    if(status === 'FEE_INTIMATION')  return 'deemed'
    return status;
  }

  function getStatusString(status, role) {
    if ((role === "MUNICIPAL_COMMISSIONER" || role === "FINAL APPROVING OFFICER") && officersApplication?.status == "VERIFICATION_UNDER_PROGRESS") {
      if (status === "IN_PROCESS" || status === 'In_process') {
        return 'NOT  YET RECEIVED'
      }
    } else {

      if (status === 'IN_PROCESS' || status === 'In_process') return 'IN PROCESS';
      if (status === 'NOT_RECOMMENDED')
        return officerType === 'GHMC' && !!role
          ? `NOT RECOMMENDED ${role.replace(/_/g, ' ')}`
          : 'NOT RECOMMENDED';
      if (status === 'RECOMMENDED')
        return officerType === 'GHMC' && !!role
          ? `RECOMMENDED by ${role.replace(/_/g, ' ')}`
          : 'RECOMMENDED';
      if (status === 'SUBMITTED')
        return officerType === 'GHMC' && !!role
          ? `SUBMITTED by ${role.replace(/_/g, ' ')}`
          : 'SUBMITTED';

      return status;
    }
  }

  function CheckWetherFirstApplicationOrNot (item , index) {
    if(index != 0 && item.name == "APPLICANT") {
      return true
    } else {
      return false
    }

  }
  // this function is to calculate completed date by extracting the latest of all forwardedDates
  // available in the array, only when all the items's status is other than 'IN_PROCESS'
  function mostRecentDate() {
    let a = getAllStagesData(filteredStageStates);
    if (a.filter((item) => item.status === 'IN_PROCESS').length > 0) {
      return false;
    } else {
      return moment(
        new Date(
          new Date(
            Math.max.apply(
              null,
              a.map((e) => {
                return e.forwardedDate ? new Date(e.forwardedDate) : new Date();
              })
            )
          )
        )
      ).format('DD MMMM YYYY');
    }
  }

  return (
    <div className="modal-right">
      <div className="modalContent">
        <div className="modalHeader">
          <h5>Proposal Flow</h5>
          <div style={{cursor:"pointer", marginRight:"20px"}} onClick={close}>
          <AiOutlineClose  fontSize="20px"/>
          </div>
      
        </div>
        {officersApplication && (
          <div className="modalSubHeader">
            <div className="subTitleSection">
              <div className="subTitleSection1">
                <div className="subHeaderSection">
                  <p className="subItemHeader">Application ID</p>:
                  <p className="subItemHeader1">
                    {officersApplication.applicationIdentifier}
                  </p>
                </div>
                <div className="subHeaderSection">
                  <p className="subItemHeader">Name</p>:
                  <p className="subItemHeader1 text-capitalize">
                    {officersApplication.applicant.applicantName}
                  </p>
                </div>
                <div className="subHeaderSection">
                  <p className="subItemHeader">Applied Date</p>:
                  <p className="subItemHeader1">
                    {officersApplication.appliedOn
                      ? moment(officersApplication.appliedOn).format(
                        'DD MMMM YYYY'
                      )
                      : moment(officersApplication.createdAt).format(
                        'DD MMMM YYYY'
                      )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {loading && (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <div className="subItemsSection">
          {filteredStageStates && !filteredStageStates.length && !loading && (
            <div>NO DATA</div>
          )}
          {filteredStageStates &&
            filteredStageStates.length > 0 &&

            getAllStagesData(filteredStageStates).map((subItem, index) => {
              console.log(getAllStagesData(filteredStageStates), 'subItem')
              return (
                <>
                  {index === 0 && (
                    <div className="subItemAutoMargin">
                      {/* <div className="circle2">
                        <img
                          alt=""
                          src={require('../../assets/images/dd-arrow.svg')}
                        />
                      </div> */}
                      <div className="subItemSubHeader">
                        {
                          <>
                            {
                              <span className="subItemHeader" >
                                {props.status &&
                                  ['ACCEPTED', 'REVOKED'].includes(
                                    props.status
                                  ) ? (
                                  <span className="highlight-text">
                                    {'Completed on'}{' '}
                                    {
                                      <span>
                                        {mostRecentDate()
                                          ? mostRecentDate()
                                          : '  --  '}{' '}
                                      </span>
                                    }
                                  </span>
                                ) : (
                                  <span className="highlight-text">
                                    {'Completed '}
                                  </span>
                                )}
                              </span>
                            }{' '}
                          </>
                        }
                      </div>
                    </div>
                  )}

                  {/* please use these classes :recommended, notRecommend, deemed, scrutiny */}
         
                 
                { !CheckWetherFirstApplicationOrNot(subItem , index)  &&  
                         <div
                    className={'subItems ' + getStatus(subItem.status)}
                    key={index}
                  >

                    {/* <div className="circle1">
                      <img alt="" src={require('../../assets/images/dd-arrow.svg')} />
                    </div> */}
                    <div className="subItemDetails1">
                      <div className="subItemDetails">
                      {!CheckWetherFirstApplicationOrNot(subItem , index) &&   
                       <div>
                          <span className="subItemHeader">
                            {subItem?.name.replace(/_/g, ' ') || ''}
                            {subItem.officerLevel
                              ? ` - ${subItem.officerLevel}`
                              : ''}
                          </span>
                        </div>
                         } 
                        <div>
                        {subItem.name   != "APPLICANT" && index == 0 &&     <span className="subItemSubHeader">
                            <span style={{fontSize:"14px !important"}}> Officer Name </span>
                            <span  style={{textTransform: "uppercase"}} className="highlight-text">
                              <span>
                              {subItem.officerName &&
                                subItem.officerName.replace(/_/g, ' ')}
                            </span>
                            </span>
                          </span> }
                          {subItem.name   != "APPLICANT" &&
                          <span className="subItemSubHeader">
                            <span style={{fontSize:"14px !important"}}>
                              Officer role
                            </span>
                            <span style={{textTransform: "capitalize"}}  className="highlight-text">
                            <span> {subItem.officerRole &&
                                subItem.officerRole.replace(/_/g, ' ')}
                            </span> </span> 
                          </span> }

                          {subItem.name   != "APPLICANT" && subItem.dueDays != null && 
                          <span className="subItemSubHeader">
                            <span style={{fontSize:"14px !important"}}>
                              Due Days
                            </span>
                            <span style={{textTransform: "capitalize"}}  className="highlight-text">
                            <span> {subItem.dueDays &&
                                subItem.dueDays}
                            </span> </span> 
                          </span> }
                        
                         <span className="subItemSubHeader">
                            <span>  {subItem.name   == "APPLICANT"  ? "File Applied on " : "  File Received on " }</span>

                            <span className="highlight-text">
                              {subItem.receivedDate != null ? (
                                <span >
                                  {/* {' on '}{' '} */}
                                  <>       {/\d{2}:\d{2}:\d{2}/.test(subItem.receivedDate) == true ?
                                    new Date(subItem.receivedDate)?.toLocaleString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })?.replace(/(am|pm)/i, '')
                                    : moment(subItem.receivedDate)?.format(
                                      'DD-MM-YYYY'
                                    )} </>
                                </span>
                              ) : (
                                <span>
                               { subItem.status === 'IN_PROCESS' && (
                                  "Not Yet Received"
                                )}
                                </span>
                              )}
                            </span>
                          </span> 
                            
                       {subItem.name  != "APPLICANT" &&    <span  className="subItemSubHeader">
                              
                              
                                      <span style={{fontSize:"14px"}}> {'File Forwarded  '} </span>
                                      {subItem.status !== 'IN_PROCESS' ? (
                                        <span className="highlight-text" >
                                          {subItem.completedDate != null ? 
                                           <span >
                                           {/* {' on '}{' '} */}
                                           <>       {/\d{2}:\d{2}:\d{2}/.test(subItem.completedDate) == true ?
                                             new Date(subItem.completedDate)?.toLocaleString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })?.replace(/(am|pm)/i, '')
                                             : moment(subItem.completedDate)?.format(
                                               'DD-MM-YYYY'
                                             )
                                             } </>
                                         </span>
                                            : ("Not Yet Forwarded ")}
                                        </span>
                                      ) : (
                                        <span className="highlight-texted">{'Not Yet Forwarded'}</span>
                                      )}
                                    </span>
            }     
                      
                           
                        
                        </div>
                    
                          
                        

                        <div className="subItemAutoMargin">
                          {/* <div className="circle2">
                      <img alt="" src={require('../../assets/images/dd-arrow.svg')} />
                    </div> */}

                        </div>
                        {subItem.name   != "APPLICANT" &&     
                             <div className="subItemStatus">

                          <div
                            className={
                              'text-capitalize ' + getStatus(subItem.status)
                            }
                          >
                            <p>
                              {getStatusString(
                                subItem.status,
                                subItem.officerRole
                              )?.replace(/_/g,' ')}

                            </p>
                          </div>
                        </div>
                        } 
                      </div> 
                      <div className="subItemAssign">
                        {/* <div>
                  <img
                    src={require('../../assets/images/user.png')}
                    className="rounded-circle img-time"
                    alt="user"
                  />
                </div> */}



                      </div>

                    </div>
                  </div>
                  
                  }
                </>
              );
            })}
          {
            <div className="subItemAutoMargin">
              {/* <div className="circle2">
                <img alt="" src={require('../../assets/images/dd-arrow.svg')} />
              </div> */}
              <div className="subItemSubHeader">
                {
                  <>
                    {' '}
                    {'Applied Date'}{' '}
                    {officersApplication &&
                      (officersApplication.appliedOn ||
                        officersApplication.createdAt) && (
                        <span>
                          <span className="highlight-text">
                            {' '}
                            {officersApplication.appliedOn
                              ? moment(officersApplication.appliedOn).format(
                                'DD MMMM YYYY'
                              )
                              : moment(officersApplication.createdAt).format(
                                'DD MMMM YYYY'
                              )}
                          </span>
                        </span>
                      )}{' '}
                  </>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

StatusReviewModal.propTypes = {
  handleClose: PropTypes.string,
  id: PropTypes.string,
  status: PropTypes.string,
};

StatusReviewModal.defaultProps = {
  handleClose: () => { },
};

export default StatusReviewModal;
