import React, { useState, useContext, useEffect } from 'react';
import AuthLayout from '../components/layouts/AuthLayout';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LOGIN_OFFICER, OFFICER_OTP } from '../graphql/mutatution/loginOfficer';
import { saveLoginState } from '../graphql/cache';
import { AppContext } from '../AppContext';
import { FILTER_LIST_ENUM } from '../util/helperFunctions';
import Tooltip from '@mui/material/Tooltip';
import { onError } from "@apollo/client/link/error";
import { ToastContainer, toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
import 'react-toastify/dist/ReactToastify.css';
// import  VITE_CAPTCHA_SITEKEY ".../"
// import env from "react-dotenv"
// import env from "react-dotenv";
// import axios from 'axios';
import OtpInput from 'react-otp-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
function Login() {
  // const errorLink = onError(({ graphQLErrors, networkError }) => {
  //   if (graphQLErrors)
  //     graphQLErrors.map(({ message, locations, path }) =>
  //       console.log(
  //         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
  //       )
  //     );
  //   if (networkError) console.log(`[Network error]: ${networkError}`);
  // });

  const [otp, setOtp] = useState('');
  const [sendResend, setSendResend] = useState('')
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setvalidated] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const { setInnerListValue } = useContext(AppContext);
  // const [captcha, setCaptcha] = useState(false)
  // const [captchaKey, setCaptchaKey] = useState("")
  const [otpAttemptsLeft, setOtpAttemptsLeft] = useState(3)
  const [otpAttempt, setOtpAttempt] = useState(0)
  const [otpError, setOtpError] = useState('')
  const [currentTime, setCurrentTime] = useState(new Date());
  const [captchaError, setCaptchaError] = useState('')
  const [currentStep, setCurrentStep] = useState(1)
  const [mobileNum, setMobileNum] = useState('')
  const [Loading, setLoading] = useState(false)
  const [textMessg, setTextMessg] = useState('')
  const [isShowResend, setisShowResend] = useState(false)
  const [mutation, { loading, client, error }] = useMutation(LOGIN_OFFICER, {
    onError: (error) => {
      console.log(error?.graphQLErrors[0]?.message)
      toast.error(error?.graphQLErrors[0]?.message.includes('Officer is not found') ? "Username or Password are Incorrect" : error?.graphQLErrors[0]?.message)
    }
  });
  const [verifyOtp, { d, load, client2, e }] = useMutation(OFFICER_OTP, {
    onError: (error) => {
      setOtpAttempt(error?.graphQLErrors[0]?.extensions?.no_otp_attempt)
      toast.error(error?.graphQLErrors[0]?.extensions?.no_otp_attempt != 9 && (otpAttemptsLeft == 3 ? "Invalid OTP, Please try again" : otpAttemptsLeft == 2 ? `Invalid OTP, You have only 1 Attempt left` : "Invalid OTP, Please Click on Resend OTP and try again"))
    }
  });
  // const response = await fetch('http://localhost:3000/api/verify_captcha', {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify({captcha_response: recaptchaRef.current.getValue()})
  // })

  // const data = await response.json()

  const encrypts = (text) => {
    // const CryptoJS = require('crypto-js');
    class AESCrypt {
      constructor(key, iv) {
        this.key = key;
        this.iv = iv;
      }
      encrypt(plainText) {
        const cipherText = CryptoJS.AES.encrypt(plainText, this.key, { iv: this.iv });
        return cipherText.toString();
      }
    }
    // Use fixed key and IV
    const key = CryptoJS.enc.Base64.parse('6OZbK0FbuzUCG+XuXWuKTFSm3VIMKg0IWNnU/FKIraI=');
    const iv = CryptoJS.enc.Base64.parse('jKdOxAbYigXA1ls0dqqikQ==');
    console.log('Key:', key.toString(CryptoJS.enc.Base64));
    console.log('IV:', iv.toString(CryptoJS.enc.Base64));
    // Initialize the AESCrypt class
    const aesCrypt = new AESCrypt(key, iv);
    // Encrypt the text
    const plainText = text;
    const encryptedText = aesCrypt.encrypt(plainText);
    return encryptedText
    // Decrypt the text
    // const decryptedText = aesCrypt.decrypt(encryptedText);
    // console.log('Decrypted text:', decryptedText);
  }

  const calculateTimeAfter2Hours = () => {
    const timeAfter2Hours = new Date();
    timeAfter2Hours.setHours(timeAfter2Hours.getHours() + 2);

    return timeAfter2Hours.toLocaleTimeString();
  };

  const handleSubmit = (e, mutation, client) => {

    e.preventDefault();
    // const cryptuserName = CryptoJS.AES.encrypt(username,key).toString()
    // const cryptoPassword = CryptoJS.AES.encrypt(password,key).toString()

    // console.log(cryptuserName,'cryptuserName')
    // console.log(captcha);
    let loginInput = {
      officer: {
        mobile: username,
        password: encrypts(password),
      },
    };
    if (currentStep == 1) {
      const form = e.currentTarget;

      if (form.checkValidity() === false) {
        setvalidated(true);
      } else {
        mutation({
          variables: {
            mobile: '',
            password: loginInput.officer.password,
            username: loginInput.officer.mobile
          },
        })
          .then((data) => {
            console.log(data, 'data')
            if (!data.errors) {
              toast.success("An OTP has been successfully sent to your mobile number")
              setCurrentStep(2)
              setMobileNum(data.data.login.mobile)
            }

            // setInnerListValue(FILTER_LIST_ENUM.all);
            // saveLoginState(client, data.data.login);
          })
          .catch((e) => console.log(e));
      }

    } else if (currentStep == 2) {

      setLoading(true)
      verifyOtp({
        variables: {
          otpSecretKey: otp,
          username: loginInput.officer.mobile
        }
      }).then((data) => {
        // console.log(data, 'data');
        setCaptchaError('')
        setLoading(false)
        setInnerListValue(FILTER_LIST_ENUM.review);
        saveLoginState(client2, data.data.verifyOtp);

      }).catch((errors) => {
        setOtp("")
        setOtpAttemptsLeft(otpAttemptsLeft - 1)
        setLoading(false)

      })
    }
  };

  // useEffect(()=>{
  //   console.log(otpAttempt,'otpAttempt')
  // },[otpAttempt])


  const handleChangeOtp = (data) => {
    setOtp(data)
  }

  // function onChange(value) {
  //   axios.post("https://uat-backend.tsbpass.com/api/v1/users/verify_captcha",{captcha_response:value})
  //   .then((response)=>{
  //      if(response.data.success ==  "Captcha challenge is correctly solved"){
  //        setCaptcha(true)
  //      }
  //   }).catch((error)=>{setCaptchaError(error)})
  // }

  const toggleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };
  const handleChangeUserName = (event) => {
    setUsername(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };


  const handleResendOtp = () => {
    setOtp("")
    setOtpAttemptsLeft(3)
    setLoading(true)
    setOtpAttempt(0)
    // setisShowResend(true)
    let loginInput = {
      officer: {
        mobile: username,
        password: encrypts(password),
      },
    };

    mutation({
      variables: {
        mobile: '',
        password: loginInput.officer.password,
        username: loginInput.officer.mobile,
      },
    })
      .then((data) => {

        if (otpAttempt != 9) {
          console.log(data, 'data')
          toast("Otp Sent SuccessFully")
          setCurrentStep(2)
          setMobileNum(data.data.login.mobile)
          setLoading(false)
        } else {

        }
      })
      .catch((e) => setLoading(false), console.log(e));
  }

  const blinkAnimation = {
    animation: 'blink 1s infinite',
    color: '#1c9a5b',
    fontWeight: "bold"
  };

  const noBlinkOnHover = {
    animation: 'none',
  };


  const formText = {
    fontFamily: 'Neutra Demi',
    fontSize: '16px',
  };

  let submitButton;
  if (currentStep === 1) {
    if (!loading) {
      submitButton = (
        <>
          {/* <Button
            style={{ width: "170px" }}
            variant="secondary"
            type="submit"
            className="d-flex  align-items-center justify-content-between"
          >
            <span style={{ fontSize: "13px" }} className="text-capitalize">Generate OTP</span>
            <img src={require('../assets/images/arrow.svg')} alt="" />
          </Button> */}
          {/* <NewButton/> */}
          <button class="button">
            Generate Otp
            <svg fill="currentColor" viewBox="0 0 24 24" class="icon">
              <path clip-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fill-rule="evenodd"></path>
            </svg>
          </button>
        </>
      )
    } else {
      submitButton = (
        <button class="button">
          Loading...
        </button>
      )
    }
  } else if (currentStep === 2 && otpAttempt != 6 && otpAttempt != 3 && otpAttempt != 9) {
    if (!Loading) {
      submitButton = (
        <>
          <button
            disabled={otp.length < 4 ? true : false}
            class="button">
            Login
            <svg fill="currentColor" viewBox="0 0 24 24" class="icon">
              <path clip-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fill-rule="evenodd"></path>
            </svg>
          </button>

        </>
      )
    } else {
      submitButton = (
        <button class="button">
          Loading...
        </button>
      )
    }
  }


  return (
    <AuthLayout>
      <ToastContainer />
      <Form
        style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "15px 0 0" }}
        noValidate
        validated={validated}
        onSubmit={(e) => handleSubmit(e, mutation, client)}
      >
        <p className="mb-1">
          {currentStep == 1 ? <p style={{ fontSize: '16px', fontWeight: 'bold', color: "#1d9a5b" }} > Officer&#8217;s Login{' '} </p > : otpAttempt != 9 ? <p > Please enter the OTP sent to  </p> : null}
          {currentStep == 2 && <p className='d-flex aligin-items-center justify-content-center'>  <span style={{ ...blinkAnimation, ':hover': noBlinkOnHover }} >{mobileNum}</span>   </p>}
        </p>

        <Form.Group controlId="formBasicEmail">
          {currentStep == 1 &&
            <>
              <div style={{ position: 'relative', width: '300px', top: "10px" }}>
                <Form.Control
                  style={{ width: "100%", height: "45px", boxShadow: "#1fc897 0px 0px 4px" }}
                  required
                  type="text"
                  value={username}
                  placeholder="Enter Username"
                  onChange={handleChangeUserName}
                // isInvalid={/* your validation condition */}
                />
                <svg
                  style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 21C5 17.134 8.13401 14 12 14C15.866 14 19 17.134 19 21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Form.Control.Feedback type="invalid">
                  Please write a valid mobile number
                </Form.Control.Feedback>
              </div>
            </>

          }

        </Form.Group>
        <Form.Group controlId="formBasicPassword" className="password">
          {currentStep == 1 &&
            <div style={{ position: 'relative', width: '300px', top: "10px" }}>
              <Form.Control
                style={{ width: "100%", height: "45px", boxShadow: "#1fc897 0px 0px 4px" }}
                required
                type={isShowPassword ? 'text' : 'password'}
                value={password}
                placeholder="Enter password"
                onChange={handleChangePassword}
              // isInvalid={/* your validation condition */}
              />
              <FontAwesomeIcon
                // isabled={isShowPassword ==  ? true : false}
                icon={isShowPassword ? faEye : faEyeSlash}
                style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                onClick={() => toggleShowPassword()}
              />
              <Form.Control.Feedback type="invalid">
                Please choose a password
              </Form.Control.Feedback>
            </div>

          }
          {/* <ReCAPTCHA
    sitekey="6LfsRX8lAAAAAGde93_e-imj9fjsbN7oTbjyfmHe"
    onChange={onChange}
  /> */}
          {/* <Canvas 
          onChange={(e) => { setCaptcha(e) }}
          onRefresh={(r) => { console.log(r) }}
          placeholder="Insert captcha" // optional
          length={2} // default
        /> */}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>


            {currentStep === 2 && (otpAttempt % 3 !== 0 || otpAttempt == 0) && !error ? <div>

              <OtpInput
                onChange={handleChangeOtp}
                value={otp}
                numInputs={4}
                isInputNum="true"
                containerStyle={{
                  width: '224px',
                  justifyContent: 'space-between',
                }}
                inputStyle={{
                  height: '50px',
                  width: '50px',
                  outline: 'none',
                  border: 'none',
                  borderBottom: '2px solid #4CAF50',
                  // borderRadius: '4px',
                }}
              // inputFocusStyle={{
              //   // outline: '#4CAF50 solid 1px', // Remove border on focus
              // }}
              />
            </div> : null}

            {
              currentStep == 2 && otpAttempt % 3 == 0 &&
              <>

                {
                  (otpAttempt != 0 && otpAttempt != 9) ? <p > Otp not received ?  <span style={{ color: "green", fontWeight: "bold", cursor: "pointer" }} onClick={handleResendOtp}> RESEND</span> </p>

                    : otpAttempt == 9 ? <p style={{ color: "#fa4747" }}> {`Your account is temporarily blocked. Please contact TG-bPASS chasingcell at 6303067279, 6302311663, 8688781098 or try logging in after ${calculateTimeAfter2Hours()}.`}</p> : ""
                }

              </>
            }
          </div>



        </Form.Group>





        <div className="loginBtnContainer">
          {submitButton}
          <div className="loginBtnContainer1">



            {currentStep == 1 ? <p className="forgot-password">
              Forgot password?
              <Link to="/reset-password">Reset</Link>
            </p> : <Tooltip title="back to edit User details">
              <span className="hover-text" onClick={() => { setCurrentStep(1) }}> &nbsp;Back</span>
            </Tooltip>}
          </div>
        </div>
      </Form>
      {/* <a
        className={`p-1 m-1`}
        style={{ position: 'absolute', right: '14px', top: '12px' }}
        href="http://hyno-tsbpass-rails-uat-59-1379739758.ap-southeast-1.elb.amazonaws.com/old_applications"
      >
        Click here to process old files
      </a> */}

    </AuthLayout>
  );
}

export default Login;
