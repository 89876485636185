import React from 'react';
import { Tabs, Tab, Table, Dropdown, DropdownButton } from 'react-bootstrap';
import './index.scss';
export default function CmoTableView(props) {
  const data = [
    {
      AuthorityName: 'GHMC',
      TotalFiles: '100',
      REG: '1000000',
      SC: '1000000',
      SW: '1000000',
      Total: '1000000',
      LayoutApprovals: '100',
      Occupancy: '100',
      Enforcements: '100',
    },
    {
      AuthorityName: 'GHMC',
      TotalFiles: '100',
      REG: '100',
      SC: '100',
      SW: '100',
      Total: '100',
      LayoutApprovals: '100',
      Occupancy: '100',
      Enforcements: '100',
    },
    {
      AuthorityName: 'GHMC',
      TotalFiles: '10022',
      REG: '100',
      SC: '100',
      SW: '100',
      Total: '100',
      LayoutApprovals: '100',
      Occupancy: '100',
      Enforcements: '100',
    },
    {
      AuthorityName: 'GHMC',
      TotalFiles: '10022',
      REG: '100',
      SC: '100',
      SW: '100',
      Total: '100',
      LayoutApprovals: '100',
      Occupancy: '100',
      Enforcements: '100',
    },
    {
      AuthorityName: 'GHMC',
      TotalFiles: '10022',
      REG: '100',
      SC: '100',
      SW: '100',
      Total: '100',
      LayoutApprovals: '100',
      Occupancy: '100',
      Enforcements: '100',
    },
  ];

  const processDataList = (data) => {
    let tableList = data.map((t, index) => {
      return (
        <tr key={index}>
          <td>{t.AuthorityName}</td>
          <td className="total-files-td">
            {t.TotalFiles}
            <img src={require('../../assets/images/arrow-blue.svg')} />
          </td>
          <td className="d-flex justify-content-between ">
            <span className="sub-header-td">{t.REG}</span>
            <span className="sub-header-td">{t.SC}</span>
            <span className="sub-header-td">{t.SW}</span>
            <span className="sub-header-td green-td">{t.Total}</span>
          </td>

          <td>{t.LayoutApprovals}</td>
          <td>{t.Occupancy}</td>
          <td>{t.Enforcements}</td>
        </tr>
      );
    });
    return tableList;
  };
  const revenueDataList = (data) => {
    let tableList = data.map((t, index) => {
      return (
        <tr key={index}>
          <td>{t.AuthorityName}</td>
          <td className="total-files-td green-td">{t.TotalFiles} </td>
          <td className="d-flex justify-content-between ">
            <span className="sub-header-td">{t.REG}</span>
            <span className="sub-header-td">{t.SC}</span>
            <span className="sub-header-td">{t.SW}</span>
            <span className="sub-header-td green-td">{t.Total}</span>
          </td>
          <td>{t.LayoutApprovals}</td>
          <td>{t.Occupancy}</td>
          <td>{t.Enforcements}</td>
        </tr>
      );
    });
    return tableList;
  };
  const processData = processDataList(data);
  const revenueData = revenueDataList(data);
  return (
    <div className="cmoTable-container">
      <Tabs
        defaultActiveKey="processing-leaderboard"
        id="uncontrolled-tab-example"
      >
        <Tab
          eventKey="processing-leaderboard"
          title={
            <>
              <img src={require('../../assets/images/growth.svg')} />
              <span>Processing</span>
            </>
          }
        >
          <div className="d-flex flex-row justify-content-between mb-3 align-items-center">
            <p className="table-head">Processing LeaderBoard</p>
            <div className=" d-flex  flex-row align-self-end">
              <DropdownButton id="dropdown-basic-button" title="Select">
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </DropdownButton>
              <DropdownButton id="dropdown-basic-button" title="Status">
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className="cmoTable-view">
            <Table striped hover>
              <thead>
                <tr>
                  <th>Authority Name</th>
                  <th>Total Files</th>
                  <th className="sub-header-th">Building Permissions</th>
                  <th>Layout Approvals</th>
                  <th>Occupancy</th>
                  <th>Enforcements</th>
                </tr>
                <tr className="cmo-table-sub-header">
                  <th />
                  <th />
                  <th className="d-flex justify-content-between fs-12 ">
                    <span>REG</span>
                    <span>SC</span>
                    <span>SW</span>
                    <span>TOTAL</span>
                  </th>
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>{processData}</tbody>
            </Table>
          </div>
        </Tab>
        <Tab
          eventKey="revenue-leaderboard"
          title={
            <>
              <img src={require('../../assets/images/graph.svg')} />
              <span>Revenue</span>
            </>
          }
        >
          <div className="d-flex flex-row justify-content-between mb-3 align-items-center">
            <p className="table-head">Revenue LeaderBoard</p>
            <div className=" d-flex  flex-row align-self-end">
              <DropdownButton
                id="dropdown-basic-button"
                title="Select Authority"
              >
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className="cmoTable-view">
            <Table striped hover>
              <thead>
                <tr>
                  <th>Authority Name</th>
                  <th>Total Files</th>
                  <th className="sub-header-th">Building Permissions</th>
                  <th>Layout Approvals</th>
                  <th>Occupancy</th>
                  <th>Enforcements</th>
                </tr>
                <tr className="cmo-table-sub-header">
                  <th />

                  <th>
                    <img src={require('../../assets/images/graph.svg')} />
                  </th>
                  <th th className="d-flex justify-content-between fs-12 ">
                    <span>REG</span>
                    <span>SC</span>
                    <span>SW</span>
                    <span>TOTAL</span>
                  </th>
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>{revenueData}</tbody>
            </Table>
          </div>
          <div />
        </Tab>
      </Tabs>
    </div>
  );
}
