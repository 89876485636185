import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import environment from 'environment';
import { useParams } from 'react-router';
import { getAllLocalData } from '../../services/localStorage';
import PropTypes from 'prop-types';
import history from '../../services/history';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CryptoJS from "crypto-js"
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import {
    // isShowMortgageSection,
    PERMISSIONS_LEVELS,
} from '../../util/helperFunctions';
import { AppContext } from '../../AppContext';
import ViewMap from '../ApplicationDetailComponent/view-map';
import Loader from '../LoadingScreen/Loader';

export default function RightSideBarOCView(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [applicantData, setApplicantData] = useState({});
    const [plotData, setPlotData] = useState();
    const [buildingData, setBuildingData] = useState({});
    const [deviationData, setDeviationData] = useState({});
    const [mediaData, setMediaData] = useState([]);
    const [vicinityData, setVicinityData] = useState({});
    const [certificates, setCertificates] = useState({});
    const [viewMap, setViewMap] = useState(false);
    const [loading, setLoading] = useState(false)
    const [IsMapLoading , setIsMapLoading] = useState(false)
    const [updateApiCalls ,setUpdateApiCalls] = useState("")
    const [mediaLoading ,setMediaLoading] = useState(false)
    const { permissionLevel } = useContext(AppContext);
    const id = props.id
    const handleShowCitizenUploadedMedia = () => {


        const newColapse = document.getElementsByClassName('show')
    
        const authToken = sessionStorage.getItem('accessToken');
    
        if (newColapse.length == 0 && mediaData.length == 0) {
    
          setMediaLoading(true)
          axios.get(`${environment.baseApi}/applications/${id}/citizen_uploaded_documents`, {
            headers: {
              Authorization: 'Bearer ' + authToken,
              Accept: '*/*',
            },
          }).then((response) => {
    
            setMediaData(response.data.data.media.data);
            // console.log(docs, 'docs');
          }).catch((ErrorScreen) => {
            // console.log(ErrorScreen, 'ErrorScreen')
            setMediaLoading(false)
          })
        }
      }
    const handleShowCertificates = () => {
        const newColapse = document.getElementsByClassName('show')

        const authToken = sessionStorage.getItem('accessToken');
         console.log(newColapse.length, 'newColapse');
        if (newColapse?.length == 0 && Object.keys(certificates)?.length === 0 ) {

            setLoading(true)
            axios.get(`${environment.baseApi}/applications/${id}/certificates`, {
              headers: {
                Authorization: 'Bearer ' + authToken,
                Accept: '*/*',
              },
            }).then((response) => {
      
              setCertificates(response.data.data.certificates);
              setLoading(false)
              console.log(certificates, 'ErrorScreen')
      
            }).catch((ErrorScreen) => {
              console.log(ErrorScreen, 'ErrorScreen')
              setLoading(false)
            })
          }
    }

    const handleCloseViewMap = () => setViewMap(false);
    const handleShowViewMap = () => setViewMap(true);
    const handlecloseRightSideBar = () => {
        document.getElementsByTagName('body')[0].classList.add('Hide-RightSidebar');
        // document.getElementsByTagName('body')[0].classList.add('show-sidebar');
    }
    useEffect(() => {
        const authToken = getAllLocalData().accessToken;
        if (authToken) {
            setIsLoading(true)
            axios
                .get(`${environment.baseApi}/applications/${id}/occupancy_show`, {
                    headers: {
                        Authorization: 'Bearer ' + authToken,
                        Accept: '*/*',
                    },
                })
                .then(function (response) {
                    if (response.data.success) {
                        setApplicantData(response.data.data.applicant);
                        setPlotData(response.data.data.plot);
                        setBuildingData(response.data.data.building);
                        setDeviationData(response.data.data.deviation);
                        setVicinityData(response.data.data.deviation_questions);
                        setMediaData(response.data.data.media.data);
                        // setCertificates(response.data.data.certificates);
                    }
                    setIsLoading(false)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, []);
    const decryptedText = (cipherText) => {
        const key = CryptoJS.enc.Base64.parse('6OZbK0FbuzUCG+XuXWuKTFSm3VIMKg0IWNnU/FKIraI=');
        const iv = CryptoJS.enc.Base64.parse('jKdOxAbYigXA1ls0dqqikQ==');
        const bytes = CryptoJS.AES.decrypt(cipherText, key, { iv: iv });
        const plainText = bytes.toString(CryptoJS.enc.Utf8);
        return plainText;
    }

    let coordinates = [];
    let officer_geo_coordinates ;
    if(plotData?.latest_geo_coordinates?.officer_geo_coordinates){
       officer_geo_coordinates = plotData?.latest_geo_coordinates?.officer_geo_coordinates
    }
    if (plotData?.geo_coordinates) {
        let coordsArr = plotData.geo_coordinates.split(',');
        let lats = coordsArr.filter((e, i) => i % 2 === 0);
        let longs = coordsArr.filter((e, i) => i % 2 === 1);
        for (var i = 0; i < coordsArr.length / 2; i++) {
            coordinates.push({ lat: lats[i], lng: longs[i] });
        }
    }
    try {
        let totalDeviatedBuiltupArea = parseFloat(deviationData.front_total_deviated_builtup_area) + parseFloat(deviationData.rear_total_deviated_builtup_area) + parseFloat(deviationData.side_one_total_deviated_builtup_area) + parseFloat(deviationData.side_two_total_deviated_builtup_area);
        return (
            isLoading ?
                <h3>Loading...</h3>
                :
                <div>
                    <div
                        className={
                            props.showSidebar ? 'ad-container' : 'ad-container-collapsed'
                        }
                    >
                        {applicantData && (
                            <>
                                <div
                                    className="d-flex"
                                    style={{ marginLeft: -50, cursor: 'pointer' }}
                                // onClick={() =>
                                //     // props.showSidebarHandler(false)
                                //     history.push(`/application-details/occ/${id}`, {
                                //         from: 'verification-pages',
                                //     })
                                // }
                                >
                                    <img alt="" src={require('../../assets/images/closeIcon.svg')} />
                                    <p onClick={() =>
                                    // props.showSidebarHandler(false)
                                    {
                                        //  debugger
                                        if (props.ApprovalFor == "COMPOUNDWALL") {
                                            history.push(`/compoundWall-application-details/CW/${id}`, {
                                                from: 'verification-pages',
                                            })
                                        } else if (props.ApprovalFor == "OCCUPANCY") {
                                            history.push(`/application-details/occ/${id}`, {
                                                from: 'verification-pages',
                                            })
                                        } else {
                                            history.push(`/application-details/${id}`, {
                                                from: 'verification-pages',
                                            })

                                        }


                                    }

                                    } style={{ fontSize: 12, color: '#9eabb5', paddingLeft: 30 }}>
                                        Expand
                                    </p>

                                    <p onClick={handlecloseRightSideBar} style={{ fontSize: 12, position: 'relative', left: "60%" }}>
                                        <img width="13px" style={{ color: '#9eabb5' }} src={require('../../assets/images/close.svg')} />
                                    </p>
                                </div>
                                <Accordion
                                    eventKey="0"
                                    className="my-2 applicationDetails"
                                    style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
                                >
                                    <Card style={{ height: '80%', paddingLeft: "0px", paddingRight: "0px", margin: '0px', transitionDelay: '0.5s' }}>
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            eventKey="0"
                                            style={{ borderBottom: 'none', fontWeight: "500", paddingLeft: "10px", paddingRight: "10px", fontSize: '13px', cursor: 'pointer', }}
                                        >
                                            Application Details
                                            <img
                                                style={{
                                                    transform: 'rotate(0deg)',
                                                }}
                                                src={require('../../assets/images/dd-arrow.svg')}
                                                alt=""
                                            />
                                        </Accordion.Toggle>


                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <ul className="ad-list">
                                                    {/* {applicantData.id && (
              <li>
                <span>Application Id</span>
                <p>{applicantData.id}</p>
              </li>
            )} */}
                                                    {applicantData?.applicant_name && (
                                                        <li>
                                                            <span>Applicant Name</span>
                                                            <p>
                                                                {applicantData?.applicant_type}{' '}
                                                                {applicantData?.applicant_name}
                                                            </p>
                                                        </li>
                                                    )}
                                                    {applicantData?.relationship_type && (
                                                        <li>
                                                            <span className="detail-prop">
                                                                {applicantData.relationship_type}
                                                            </span>
                                                            <p className="detail-value">
                                                                {applicantData?.relationship_name}
                                                            </p>
                                                        </li>
                                                    )}
                                                    {/* {props.data.createdAt && (
              <li>
                <span>Received Date</span>
                <p>{formatDateToString(props.data.createdAt)}</p>
              </li>
            )}
            {applicantData.applicant_type && (
              <li>
                <span>Application Type</span>
                <p>{applicantData.applicant_type}</p>
              </li>
            )} */}
                                                    {applicantData?.aadhaar_number && (
                                                        <li>
                                                            <span>Aadhaar Number</span>
                                                            <p>{decryptedText(applicantData?.aadhaar_number)}</p>
                                                        </li>
                                                    )}
                                                    {[
                                                        PERMISSIONS_LEVELS.SITE_VERIFICATION,
                                                        PERMISSIONS_LEVELS.TPSO_OFFICER,
                                                        PERMISSIONS_LEVELS.ACP_OFFICER,
                                                        PERMISSIONS_LEVELS.SECTION_HEAD,
                                                        PERMISSIONS_LEVELS.SECTION_OFFICER,
                                                        PERMISSIONS_LEVELS.UDA_GP_SITE_OFFICER,
                                                        PERMISSIONS_LEVELS.CP_TECH_OFFICER,
                                                        PERMISSIONS_LEVELS.TECH_OFFICER,
                                                        PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION
                                                        
                                                    ].includes(permissionLevel) &&
                                                        applicantData?.mobile && (
                                                            <li>
                                                                <span>Mobile Number</span>
                                                                <p>{decryptedText(applicantData.mobile)}</p>
                                                            </li>
                                                        )}

                                                    {/* {applicantData.email && (
              <li>
              <span>E-mail ID</span>
              <p>{applicantData.email}</p>
            </li>
            )} */}

                                                    {applicantData?.contact_address && (
                                                        <li>
                                                            <span>Contact Address</span>
                                                            <p> {applicantData?.contact_address}</p>
                                                        </li>
                                                    )}
                                                </ul>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </>
                        )}
                        {plotData && (
                            <Accordion
                                eventKey="0"
                                className="my-2 applicationDetails"
                                style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
                            >
                                <Card style={{ height: '100%', paddingLeft: "0px", paddingRight: "0px", margin: '0px' }}>

                                    <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey="0"
                                        style={{ borderBottom: 'none', fontWeight: "500", paddingLeft: "10px", paddingRight: "10px", fontSize: '13px', cursor: 'pointer' }}
                                    >
                                        Plot Details
                                        <img
                                            style={{
                                                transform: 'rotate(0deg)',
                                            }}
                                            src={require('../../assets/images/dd-arrow.svg')}
                                            alt=""
                                        />
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <ul className="ad-list">
                                                {plotData && (

                                                    <li>
                                                        <span>Plot Address</span>
                                                        <p>{plotData.plot_address ? plotData.plot_address : '---'}</p>
                                                    </li>
                                                )}
                                                {plotData && (
                                                    <li>
                                                        <span>Geo-Coordinates</span>
                                                        <p>
                                                            <button
                                                                onClick={handleShowViewMap}
                                                                style={{ color: 'blue', textDecoration: 'underline' }}
                                                            >
                                                                View Map
                                                            </button>
                                                            <ViewMap
                                                            show={viewMap}
                                                            onHide={handleCloseViewMap}
                                                            coordinates={coordinates}
                                                            propertyID = {plotData.property_id}
                                                            id = {id}
                                                            permissionLevel={permissionLevel}
                                                            IsMapLoading={IsMapLoading}
                                                            setUpdateApiCalls={setUpdateApiCalls}
                                                            officer_geo_coordinates={officer_geo_coordinates}
                                                            />
                                                        </p>
                                                    </li>
                                                )}
                                            </ul>
                                            {plotData &&
                                                (plotData.plot_area_as_per_document_display ||
                                                    plotData.plot_area_as_per_ground_display) && (
                                                    <div className="ad-header">
                                                        <span>Plot registration details</span>
                                                    </div>
                                                )}
                                            <ul className="ad-list">
                                                {plotData && plotData.survey_no && (
                                                    <li>
                                                        <span>Survey No</span>
                                                        <p>{plotData.survey_no}</p>
                                                    </li>
                                                )}
                                                {plotData && plotData.plot_area_as_per_document && (
                                                    <li>
                                                        <span>Plot Area As per document</span>
                                                        <p>{plotData.plot_area_as_per_document + "Sq. Meters"} </p>
                                                    </li>
                                                )}
                                                {plotData && plotData.plot_area_as_per_ground && (
                                                    <li>
                                                        <span>Plot Area As per Ground</span>
                                                        <p>{plotData.plot_area_as_per_ground + "Sq. Meters"}</p>
                                                    </li>
                                                )}
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        )}
                        {vicinityData &&
                            vicinityData.vicinity_answers &&
                            !!vicinityData.vicinity_answers.length && (
                                <Accordion
                                    eventKey="0"
                                    className="my-2 applicationDetails"
                                    style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
                                >
                                    <Card style={{ height: '100%', paddingLeft: "0px", paddingRight: "0px", margin: '0px' }}>

                                        <Accordion.Toggle
                                            as={Card.Header}
                                            eventKey="0"
                                            style={{
                                                borderBottom: 'none',
                                                fontWeight: "500",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                fontSize: '13px',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            Application Checklist
                                            <img
                                                style={{
                                                    transform: 'rotate(0deg)',
                                                }}
                                                src={require('../../assets/images/dd-arrow.svg')}
                                                alt=""
                                            />

                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                {vicinityData?.vicinity_answers.map((item, id) => {
                                                    return (
                                                        <ul className="ad-list" key={id}>
                                                            <li>
                                                                <span className="detail-prop">{item.question}</span>
                                                                <p className="detail-value">
                                                                    {item.answer
                                                                        ? item.answer === 'TRUE' || item.answer === 't'
                                                                            ? 'Yes'
                                                                            : item.answer === 'FALSE' || item.answer === 'f'
                                                                                ? 'No'
                                                                                : item.answer
                                                                        : '---'}
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    );
                                                })}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            )}
                        <Accordion
                            eventKey="0"
                            className="my-2 applicationDetails"
                            style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
                        >
                            <Card style={{ height: '100%', paddingLeft: "0px", paddingRight: "0px", margin: '0px' }}>
                                <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey="0"
                                    style={{
                                        borderBottom: 'none',
                                        fontWeight: "500",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        fontSize: '13px',
                                        cursor: 'pointer'
                                    }}
                                >

                                    Plot Documents
                                    <img
                                        style={{
                                            transform: 'rotate(0deg)',
                                        }}
                                        src={require('../../assets/images/dd-arrow.svg')}
                                        alt=""
                                    />
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <ul className="plot-img-list">
                                            {mediaData.map((item, index) => {
                                                // getting download url type from the file extenstion and default is pdf
                                                let fileType = 'pdf';
                                                if (item.download_url?.includes('.pdf')) {
                                                    fileType = 'pdf';
                                                } else if (
                                                    item.download_url?.includes('.jpg') ||
                                                    item.download_url?.includes('.jpeg') ||
                                                    item.download_url?.includes('.png') ||
                                                    item.download_url?.includes('.svg')
                                                ) {
                                                    fileType = 'img';
                                                }
                                                if (fileType === 'pdf') {
                                                    return (
                                                        <>
                                                            {item.category && (
                                                                <li key={index}>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        className="plot-img"
                                                                    >
                                                                        <img
                                                                            alt=""
                                                                            onClick={() =>
                                                                                window.open(item.download_url, '_blank')
                                                                            }
                                                                            src={require('../../assets/images/pdf.svg')}
                                                                        />
                                                                    </div>
                                                                    <a href={item.download_url}>
                                                                        {item.category && item.category.replace(/_/g, ' ')}
                                                                    </a>
                                                                </li>
                                                            )}
                                                        </>
                                                    );
                                                }
                                                if (fileType === 'img') {
                                                    return (
                                                        <>
                                                            {item.category && (
                                                                <li key={index}>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        className="plot-img"
                                                                    >
                                                                        <img
                                                                            alt=""
                                                                            onClick={() =>
                                                                                window.open(item.download_url, '_blank')
                                                                            }
                                                                            src={require('../../assets/images/doc.svg')}
                                                                        />
                                                                    </div>
                                                                    <a
                                                                        href={item.download_url}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {item.category && item.category.replace(/_/g, ' ')}
                                                                    </a>
                                                                </li>
                                                            )}
                                                        </>
                                                    );
                                                }
                                                return (
                                                    <>
                                                        {item.category && (
                                                            <li key={index}>
                                                                <div className="plot-img">
                                                                    <img
                                                                        onClick={() =>
                                                                            window.open(
                                                                                `https://tsbpass.s3.ap-south-1.amazonaws.com/${item.s3FilePath}`,
                                                                                '_blank'
                                                                            )
                                                                        }
                                                                        src={`https://tsbpass.s3.ap-south-1.amazonaws.com/${item.s3FilePath}`}
                                                                        width="100%"
                                                                        height="100%"
                                                                        alt="plot"
                                                                    />
                                                                </div>
                                                                <a href={item.s3FilePath}>
                                                                    {item.category && item.category.replace(/_/g, ' ')}
                                                                </a>
                                                            </li>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>


                        <Accordion
                            eventKey="0"
                            className="my-2 applicationDetails"
                            style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
                        >
                            <Card style={{ height: '100%', paddingLeft: "0px", paddingRight: "0px", margin: '0px' }}>

                                {buildingData?.building_info && (
                                    <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey="0"
                                        style={{
                                            borderBottom: 'none',
                                            fontWeight: "500",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            fontSize: '13px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Building details
                                        <img
                                            style={{
                                                transform: 'rotate(0deg)',
                                            }}
                                            src={require('../../assets/images/dd-arrow.svg')}
                                            alt=""
                                        />
                                    </Accordion.Toggle>
                                )}
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        {buildingData.building_info ? (
                                            <ul className="ad-list">
                                                <li>
                                                    <span>Usage of Building</span>
                                                    <p>{buildingData.building_info.usage_building}</p>
                                                </li>
                                                <li>
                                                    <span>Net Plot Area</span>
                                                    <p>{buildingData.net_plot_area_display}</p>
                                                </li>
                                                <li>
                                                    <span>Building Permit Order Issued Date</span>
                                                    <p>{buildingData.building_info.building_permit_issued_date ?
                                                        new Date(buildingData.building_info
                                                            .building_permit_issued_date).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })
                                                        : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>Numeric Floors</span>
                                                    <p>{buildingData.building_info.proposed_no_of_floors_name ?
                                                        buildingData.building_info.proposed_no_of_floors_name
                                                        : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>Number of Floors as on Ground</span>
                                                    <p>{buildingData.building_info.proposed_no_of_floors_name ?
                                                        buildingData.building_info.proposed_no_of_floors_name
                                                        : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>File Number</span>
                                                    <p>{buildingData.building_info.file_number ?
                                                        buildingData.building_info.file_number
                                                        : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>Build Permit Number</span>
                                                    <p>{buildingData.building_info.permit_number ?
                                                        buildingData.building_info.permit_number
                                                        : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>Built up Area (Sq. Mts)</span>
                                                    <p>{buildingData.building_info.total_built_up_area ?
                                                        buildingData.building_info.total_built_up_area
                                                        : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>Road Affected Area if any ( In Sq .mts )</span>
                                                    <p>{buildingData.building_info.total_road_affected_area ?
                                                        buildingData.building_info.total_road_affected_area
                                                        : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>No of Rain water harvesting Pits</span>
                                                    <p>{buildingData.building_info.no_of_rain_water_harvesting_pits ?
                                                        buildingData.building_info.no_of_rain_water_harvesting_pits
                                                        : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>Rainwater Harvesting as on ground</span>
                                                    <p>{buildingData.building_info.no_of_rain_water_harvesting_as_on_ground ?
                                                        buildingData.building_info.no_of_rain_water_harvesting_as_on_ground
                                                        : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>Parking Space Provision</span>
                                                    <p>{buildingData.building_info.parking_space_provision ?
                                                        buildingData.building_info.parking_space_provision
                                                        : "N/A"}
                                                    </p>
                                                </li>
                                                {buildingData.building_info
                                                    .parking_space_provision && buildingData.building_info
                                                        .parking_space_provision === "Stilt" && <li>
                                                        <span>Stilt Area As On Ground</span>
                                                        <p>
                                                            {buildingData.building_info
                                                                .stilt_area_on_ground
                                                                ? buildingData.building_info
                                                                    .stilt_area_on_ground
                                                                : "N/A"}
                                                        </p>
                                                    </li>}
                                                {buildingData.building_info
                                                    .parking_space_provision && buildingData.building_info
                                                        .parking_space_provision === "Stilt" && <li>
                                                        <span>Stilt Area Area as per Sanctioned Plan</span>
                                                        <p>
                                                            {buildingData.building_info
                                                                .stilt_area_as_per_sanctioned_plan
                                                                ? buildingData.building_info
                                                                    .stilt_area_as_per_sanctioned_plan
                                                                : "N/A"}
                                                        </p>
                                                    </li>}
                                                <li>
                                                    <span>Work Commenced Date</span>
                                                    <p>
                                                        {buildingData.building_info
                                                            .work_commenced_date
                                                            ? new Date(buildingData.building_info
                                                                .work_commenced_date).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })
                                                            : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>Work Completion Date</span>
                                                    <p>
                                                        {buildingData.building_info
                                                            .work_completion_date
                                                            ?
                                                            new Date(buildingData.building_info
                                                                .work_completion_date).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })
                                                            : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>Date By Architect</span>
                                                    <p>
                                                        {buildingData.building_info
                                                            .date_by_architect
                                                            ?
                                                            new Date(buildingData.building_info
                                                                .date_by_architect).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })
                                                            : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>Whether the building completed within the stipulated time ?</span>
                                                    <p>
                                                        {deviationData ? deviationData.is_building_constructed_on_stipulated_time == true ? "Yes" : "No" : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>Due Date for Completion of Building as per Proceeding</span>
                                                    <p>
                                                        {deviationData.completion_date ? new Date(deviationData.completion_date).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' }) : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>No Of Days Deviated</span>
                                                    <p>
                                                        {deviationData.no_days_deviated || deviationData.no_days_deviated === 0 ? deviationData.no_days_deviated : "N/A"}
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>Total Build-Up Area as on Ground (in Sq. mts.)</span>
                                                    <p>
                                                        {deviationData && deviationData.total_built_up_area_as_on_ground ? deviationData.total_built_up_area_as_on_ground : "N/A"}
                                                    </p>
                                                </li>
                                            </ul>
                                        ) : <h3>Building Info Not found</h3>}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        {deviationData ? (
                            <Accordion
                                eventKey="0"
                                className="my-2 applicationDetails"
                                style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
                            >
                                <Card style={{ height: '100%', paddingLeft: "0px", paddingRight: "0px", margin: '0px' }}>
                                    <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey="0"
                                        style={{
                                            borderBottom: 'none',
                                            fontWeight: "500",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            fontSize: '13px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Deviation Table
                                        <img
                                            style={{
                                                transform: 'rotate(0deg)',
                                            }}
                                            src={require('../../assets/images/dd-arrow.svg')}
                                            alt=""
                                        />
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <div className="deviation-view" >
                                                <table bordered={"true"} className="devi-table">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Set Backs</th>
                                                            <th>As per Building Permit Order (in Mts)</th>
                                                            <th>As on ground (in Mts)</th>
                                                            <th>Deviation in Set Back (in mts)</th>
                                                            <th>% of Setback Deviated</th>
                                                            <th>Building Lengths (in Sq Mts)</th>
                                                            <th>Deviated Builtup Area per Floor (in Sq. mts)</th>
                                                            <th>No. of Floors</th>
                                                            <th>Total Deviated Builtup Area (in Sq. mts.)</th>
                                                            <th>Land Value per Sq. Yd as per Registration Dept., (In Rs.)</th>
                                                            <th>Total Compounding Fee (In Rs.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th>1</th>
                                                            <th>Front</th>
                                                            <th>
                                                                {deviationData.front_building_permit_order}
                                                            </th>
                                                            <th>
                                                                {deviationData.front_setback_as_on_ground}
                                                            </th>
                                                            <th>
                                                                {deviationData.front_setback_deviation_in_sq_mtrs}
                                                            </th>
                                                            <th>
                                                                {deviationData.front_setback_deviated_percentage}
                                                            </th>
                                                            <th>
                                                                {deviationData.front_building_lengths}
                                                            </th>
                                                            <th>
                                                                {deviationData.front_side_deviated_builtup_area_per_floor}
                                                            </th>
                                                            <th>
                                                                {buildingData.building_info ? buildingData.building_info.proposed_no_of_floors : 0}
                                                            </th>
                                                            <th>
                                                                {deviationData.front_total_deviated_builtup_area}
                                                            </th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                        <tr>
                                                            <th>2</th>
                                                            <th>Rear</th>
                                                            <th>
                                                                {deviationData.rear_building_permit_order}
                                                            </th>
                                                            <th>
                                                                {deviationData.rear_setback_as_on_ground}
                                                            </th>
                                                            <th>
                                                                {deviationData.rear_setback_deviation_in_sq_mtrs}
                                                            </th>
                                                            <th>
                                                                {deviationData.rear_setback_deviated_percentage}
                                                            </th>
                                                            <th>
                                                                {deviationData.rear_building_lengths}
                                                            </th>
                                                            <th>
                                                                {deviationData.rear_deviated_builtup_area_per_floor}
                                                            </th>
                                                            <th>
                                                                {buildingData.building_info ? buildingData.building_info.proposed_no_of_floors : 0}
                                                            </th>
                                                            <th>
                                                                {deviationData.rear_total_deviated_builtup_area}
                                                            </th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                        <tr>
                                                            <th>3</th>
                                                            <th>Side1</th>
                                                            <th>
                                                                {deviationData.side_one_building_permit_order}
                                                            </th>
                                                            <th>
                                                                {deviationData.side_one_setback_as_on_ground}
                                                            </th>
                                                            <th>
                                                                {deviationData.side_one_setback_deviation_in_sq_mtrs}
                                                            </th>
                                                            <th>
                                                                {deviationData.side_one_setback_deviated_percentage}
                                                            </th>
                                                            <th>
                                                                {deviationData.side_one_building_lengths}
                                                            </th>
                                                            <th>
                                                                {deviationData.side_one_deviated_builtup_area_per_floor}
                                                            </th>
                                                            <th>
                                                                {buildingData.building_info ? buildingData.building_info.proposed_no_of_floors : 0}
                                                            </th>
                                                            <th>
                                                                {deviationData.side_one_total_deviated_builtup_area}
                                                            </th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                        <tr>
                                                            <th>4</th>
                                                            <th>Side2</th>
                                                            <th>
                                                                {deviationData.side_two_building_permit_order}
                                                            </th>
                                                            <th>
                                                                {deviationData.side_two_setback_as_on_ground}
                                                            </th>
                                                            <th>
                                                                {deviationData.side_two__setback_deviation_in_sq_mtrs}
                                                            </th>
                                                            <th>
                                                                {deviationData.side_two_setback_deviated_percentage}
                                                            </th>
                                                            <th>
                                                                {deviationData.side_two_building_lengths}
                                                            </th>
                                                            <th>
                                                                {deviationData.side_two_deviated_builtup_area_per_floor}
                                                            </th>
                                                            <th>
                                                                {buildingData.building_info ? buildingData.building_info.proposed_no_of_floors : 0}
                                                            </th>
                                                            <th>
                                                                {deviationData.side_two_total_deviated_builtup_area}
                                                            </th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                        <tr>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th>
                                                                {totalDeviatedBuiltupArea.toFixed(2)}
                                                            </th>
                                                            <th>
                                                                {deviationData.land_value_per_registration_dep}
                                                            </th>
                                                            <th>
                                                                {deviationData.total_compounding_fee}
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        ) : <h3>Building Info Not found</h3>}
                        <Accordion
                            eventKey="0"
                            className="my-2 applicationDetails"
                            style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
                            onClick={handleShowCertificates}
                        >
                            <Card style={{ height: '100%', paddingLeft: "0px", paddingRight: "0px", margin: '0px' }}>
                                {/* {certificates && Object.keys(certificates).length > 0 && ( */}
                                    <section>
                                        {!loading ?
                                            <Accordion.Toggle
                                                as={Card.Header}
                                                eventKey="0"
                                                style={{
                                                    borderBottom: 'none',
                                                    fontWeight: "500",
                                                    paddingLeft: "10px",
                                                    paddingRight: "10px",
                                                    fontSize: '13px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Certificates
                                                <img
                                                    style={{
                                                        transform: 'rotate(0deg)',
                                                    }}
                                                    src={require('../../assets/images/dd-arrow.svg')}
                                                    alt=""
                                                />
                                            </Accordion.Toggle> : <Loader />
                                        }
                                        {certificates && (
                                            <Accordion.Collapse eventKey="0">
                                                {certificates.length != 0 &&
                                                    <Card.Body>
                                                        <ul className="ad-list">
                                                            {Object.keys(certificates).map((elKey) => {
                                                                return (
                                                                    elKey &&
                                                                    certificates[elKey] && (
                                                                        <li>
                                                                            <span>{elKey}</span>
                                                                            <br />
                                                                            <span>
                                                                                <a
                                                                                    href={certificates[elKey]}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        icon={faDownload}
                                                                                        style={{ color: 'black' }}
                                                                                    />
                                                                                </a>
                                                                            </span>
                                                                        </li>
                                                                    )
                                                                );
                                                            })}
                                                        </ul>
                                                    </Card.Body>}
                                            </Accordion.Collapse>
                                        )}
                                    </section>
                                {/* )} */}
                            </Card>
                        </Accordion>
                    </div>
                </div>
        );
    } catch (e) {
        console.log(e);
        return (
            <div
                className={
                    props.showSidebar ? 'ad-container' : 'ad-container-collapsed'
                }
                style={{
                    textAlign: 'center',
                    display: 'flex',
                    color: 'red',
                    alignItems: 'center',
                }}
            >
                Something went wrong!
            </div>
        );
    }
}

RightSideBarOCView.propTypes = {
    showSidebar: PropTypes.bool,
    applicationType: PropTypes.string,
};

RightSideBarOCView.defaultProps = {
    showSidebar: true,
    applicationType: 'SELF CERTIFICATION',
};
