import { gql } from '@apollo/client';

export const LOGIN_OFFICER = gql`
  mutation login($mobile: String!, $password: String!, $username: String!) {
    login(mobile: $mobile, password: $password, username: $username) {
      mobile
    }
   
  }
`;

export const  OFFICER_OTP = gql`
mutation verifyOtp( $otpSecretKey:String!, $username: String!) {
  verifyOtp( otpSecretKey: $otpSecretKey,username: $username) {
     id
  accessToken
  firstName
  lastName
  }
  saveLoginState @client
}
`
