export default {
  'heading.application': 'Application Details',
  'label.applicant_name': 'Applicant Full Name',
  'label.aadhaar_number': 'Aadhaar Number',
  'label.pan_number': 'Pancard Number',
  'label.mobile_number': 'Mobile Number',
  'label.email_id': 'E-mail ID',
  'label.contact_address': 'Contact Address',
  'heading.plot': 'Plot Details',
  'label.actual_plot_area': 'Plot Area as on Ground',
  'label.plot_area': 'Plot Area',
  'label.no_of_floors': 'No. of floors',
  'label.construction_type': 'Purpose of the Building',
  'label.land_type': 'Land Type',
  'label.construction_status': 'Construction Status',
  'label.lrs_number': 'LRS Number',

  'label.ap_number': 'Layout approved number',
  'label.ab_number': 'Building permit number',

  'label.file_number': 'Enter File No',
  'label.build_permit_number': 'Enter Build Permit No',

  'label.plot_no': 'Plot No./Door No./House No.',
  'heading.plot_regi_details': 'Plot registration details',
  'label.address': 'Address',
  'label.ulb_name': 'Municipality/Municipal Corporation',
  'label.mandal': 'Mandal',
  'label.village': 'Village',
  'label.revenue_village': 'Revenue Village',
  'label.optional': 'Optional',
  'label.existing_numeric_floors': 'Existing Number of Floors',
  'label.numeric_floors': 'Number of Floors',
  'label.built_up_area': 'Built up area(sq m) for Parking',
  'heading.documentation': 'Documentation',
  'heading.upload_documents': 'Upload Documents',
  'heading.other_documents': 'Other Documents',
  'label.file_upload_success': 'File uploaded succesfully',
  'label.file_upload_failed': 'File upload failed, choose another file',
  'error.upload_all_files': 'Please upload all files before continuing',
  'error.pending_files': 'Pending Files',
  'error.auto_dcr_invaild': 'Auto DCR is Invalid,Please Enter the fields',
  'error.auto_dcr_fields_incomplete':
    'Please Enter the Auto Dcr fields before continuing',
  'error.invalid_input': 'Invalid input. Please check your input data',
  'error.try_later':
    'Unable to process application now, please try again in later',
  'error.auto_dcr_upload_failed':
    'Auto Dcr Fields upload failed , Please try again',
  'heading.auto_dcr_details': 'Auto Dcr Details',
  'heading.review_your_details': 'Review your application details',
  'heading.uploaded_document': 'Uploaded Documents',
  'heading.paymentInfo': 'Payment Info',
  'heading.payment_status': 'Payment Status',
  'label.status': 'Status',
  'label.application_id': 'Application ID',
  'heading.applicant_details': 'Applicant Details',
  'heading.acknowledgment': 'Acknowledgment',
  'payment.amount': 'Amount',
  'payment.payment_mode': 'Mode',
  'payment.reference_no': 'Reference Number',
  'payment.dd_no': 'DD Number',
  'label.password': 'Password',
  'label.sale_deed_registration_date':
    'Current Registration date as per latest sales/link document',
  'label.floor_areas': 'Built up area per floor (sq. m)',
  'label.total_built_up_area':
    'Total Built up area (Excluding Stilt/Parking Area) sq. m',
  'label.net_plot_area': 'Net Plot Area (sq. m)',
  'label.market_value': 'Market Value',
  'label.market_value_title': 'Present Market Value (INR - per sq. yd)',
  'label.latest_market_value_title': 'Latest Market Value (INR - per sq. yd)',
  'label.compound_length': 'Length of the compound wall (m)',
  'label.construction_cost': 'Construction Cost (INR - per sq. ft)',
  'label.mortgage_area': 'Mortgage Area (sq. m)',
  'label.mortgage_floor': 'Mortgage Floor',
  'label.mortgage_document_number': 'Mortgage Document Number',
  'label.mortgage_date': 'Mortgage Date',
  'label.sro_location': 'SRO at',
  'label.auto_dcr_id': 'Auto DCR Id',
  'label.auto_dcr_secret_key': 'Auto DCR Secret Key',
  'label.roof_type': 'Type of Roof',
  'label.additional': 'Additional',
  'label.cellar': 'Cellar',
  'label.groundFloor': 'Built up area(sq. m) for Ground floor',
  'label.floorInput': 'Built up area(sq. m) for Floor',
  'label.stiltInput': 'Built up area(sq. m) for Parking',
  'label.plot_status':
    'Is Plot of Approved Layout /LRS Applied /Grama Kantam/Abadi?',
  'label.front_road_affected_area': 'Front Road affected area (feet)',
  'label.front_existing_road_width': 'Existing Front Road Width (feet)',
  'label.is_front_road_affected': 'Is Front road area affected?',
  'label.front_proposed_road_width': 'Proposed Front Road Width (feet)',
  'label.rear_road_affected_area': 'Side 3 Road affected area (feet)',
  'label.rear_existing_road_width': 'Existing Side 3 Road Width (feet)',
  'label.is_rear_road_affected': 'Is Side 3 road area affected?',
  'label.rear_proposed_road_width': 'Proposed Side 3 Road Width (feet)',
  'label.side1_road_affected_area': 'Side 1 Road affected area (feet)',
  'label.side1_existing_road_width': 'Existing Side 1 Road Width (feet)',
  'label.is_side1_road_affected': 'Is Side 1 road area affected?',
  'label.side1_proposed_road_width': 'Proposed Side 1 Road Width (feet)',
  'label.side2_road_affected_area': 'Side 2 Road affected area (feet)',
  'label.side2_existing_road_width': 'Existing Side 2 Road Width (feet)',
  'label.is_side2_road_affected': 'Is Side 2 road area affected?',
  'label.side2_proposed_road_width': 'Proposed Side 2 Road Width (feet)',
  'success.application_redirect': 'Redirecting you to your application',
  'heading.builder_details': 'Builder Details',
  'label.builder_name': 'Builder Name',
  'label.builder_license_number': 'Builder License Number',
  'label.builder_mobile_number': 'Builder Mobile Number',
  'label.builder_email_id': 'Builder Email ID',
  'label.builder_address': 'Builder Address',
  'heading.architect_details': 'Architect Details',
  'label.architect_name': 'Architect Name',
  'label.architect_license_number': 'Architect License Number',
  'label.architect_mobile_number': 'Architect Mobile Number',
  'label.architect_email_id': 'Architect Email ID',
  'label.architect_address': 'Architect Address',
  'heading.structural_engineer_details': 'Structural Engineer Details',
  'label.structural_engineer_name': 'Name',
  'label.structural_engineer_license_number': 'License Number',
  'label.structural_engineer_mobile_number': 'Mobile Number',
  'label.structural_engineer_email_id': 'Email ID',
  'label.structural_engineer_address': 'Address',
  'heading.road_details': 'Abutting Road Width Details',
  'heading.mortgage_details': 'Mortgage Details',
  'heading.retrieve_your_application': 'Continue your application',
  'label.reset_password': 'Reset password',
  'label.reset_application_password': 'Reset Application Password',
  'label.cancel': 'Cancel',
  'label.in_meters': 'in sq.meters',
  'label.in_yards': 'in sq.yards',
  'label.residential': 'Residential',
  'label.non_residential': 'Commercial',
  'heading.self_declaration': 'Self Declaration',
  'button.upload_continue': 'Upload and Continue',
  'button.proceed_to_pay': 'Proceed to pay',
  'heading.payment': 'Payment',
  'heading.pay_now': 'Pay now',
  'label.self_dec_text':
    'I/We certify that the particulars furnished in the Application form are true, correct and complete to the best of my / our knowledge and undertake to adhere to the declarations made there under.',
  'label.acknowledgement_letter': 'Acknowledgement Letter',
  'heading.congratulations': 'Congratulations',
  'button.download': 'Download Or Print',
  'button.back_to_home': 'Back to Home',
  'button.upload': 'Upload',
  'button.remove': 'Remove',
  'button.back': 'Back',
  'button.save_continue': 'Save & Continue',
  'button.agree_continue': 'Agree & Continue',
  'button.apply_new': 'Apply New',
  'button.uploading': 'Uploading',
  'button.submitting': 'Submitting',
  'button.goBack': 'Go back',
  'button.proceed': 'Proceed',
  'label.property_tax_text':
    'I/We Agree to include the registration fee of ONE RUPEE in my first property tax assessment.',
  'heading.citizenAction': 'Actions to be taken by applicant',
  'button.uploadAll': 'Upload all & Proceed',
  'button.add': 'Add',
  'label.street': 'Street / Road',
  'label.locality': 'Colony / Locality Name',
  'label.building_usage': 'Usage of Building',
  'label.building_type': 'Building Type',
  'label.building_subtype': 'Building Sub Type',
  'label.plot_area_as_per_document':
    'Plot Area as per Registered Document / Patta Certificate',
  'label.slum_area':
    'Whether your plot falls under any of the notified slum areas ?',
  'heading.fire_department_details': 'Fire Department Details',
  'label.father_name': "Father's Name",
  'label.age': 'Age',
  'label.owner_pincode': 'Pincode',
  'label.building_name': 'Building Name',
  'label.height_of_stilt_floor': 'Height of the Stilt in meters',
  'label.application_stages': 'Application Stages',
  'label.road_widening': 'Road Widening',
  'label.gov_building': 'Telangana Government Building ?',
  'label.fire_north': 'North side open space in meters',
  'label.fire_south': 'South side open space in meters',
  'label.fire_east': 'East side open space in meters',
  'label.fire_west': 'West side open space in meters',
  'label.abutting_road_width': 'Abutting Road Width (meters)',
  'label.front_side_dir': 'front side direction',
  'label.width_of_entry_gate': 'Entry Gate Width in meters',
  'label.width_of_exit_gate': 'Exit Gate Width in meters',
  'label.head_clearance': 'Entry Gate Head Clearance in meters',
  'label.head_exit': 'Exit Gate Head Clearance in meters',
  'label.corridor': 'Travel Distance From the dead end of the corridor',
  'label.farthest': 'Travel Distance from the Farthest point',
  'label.transformer_safety': 'Transformer Safety',
  'label.no_of_fire_lifts': 'Number of Fire Lifts',
  'label.car_parking': 'Car Parking Available?',
  'label.ramps_provides': 'Is Ramp Available',
  'label.generator_capacity': 'Generator Capacity',
  'label.air_conditioning_safety': 'Air Conditioner Safety',
  'label.site_elevation': 'Site elevation In meters',
  'label.building_height': 'Building Height in meters',
  'label.access_token': 'Access token',
  'label.tdr_cert': ',TDR certificate number',
  'label.department_type': 'Department Type',
  'label.building_year': 'Building construction completed year (Optional)',
  'label.occupancy_type': 'Occupancy Type',
  'label.questionnaire_id': 'Questionnaire Id',
  'label.entrepreneur_id': 'Entrepreneur Id',
  'label.stage_id': 'Stage Id',
  'label.rc_number': 'RC Number',
  'label.prov_noc_issue_date': 'Provisional NOC Issue Date',
  'label.name_of_block': 'Name of Block',
  'label.lp_number': 'LRS subdivisional number',
  'label.bps_number': 'Building permit number',
  'heading.co_applicant_details': 'Co-Applicant Details',
  'heading.vicinity_details': 'Checklist',
  'heading.approach_road_details': 'Approach Road Details',
  'heading.bg_fields': 'BG Fields',
  'heading.schedule_of_boundaries': 'Schedule of Boundaries',
  'heading.town_planner_details': 'Town Planner Details',
  'heading.surveyor_details': 'Surveyor Details',
  'heading.noc_processing': 'NOC Processing',
  'label.co_applicant_name': 'Co-Applicant Name',
  'label.co_applicant_aadhaar_number': 'Co-Applicant Aadhaar Number',
  'label.co_applicant_phone_number': 'Co-Applicant Mobile Number',
  'label.co_applicant_email_id': 'Co-Applicant E-mail ID',
  'label.proposed_site_area_for_approval': 'Proposed site area for approval',
  'label.layout_type': 'Layout Type',
  'label.layout_category': 'Layout Category',
  'label.site_area_as_per_document': 'Site area as per document',
  'label.site_area_proposed_for_approval': 'Site area proposed for approval',
  'label.application_type': 'Application Type',
  'label.municipality_corporation': 'Municipality/Corporation',
  'label.if_site_area_more_than_proposed_reason':
    'If Site area as per document is more than proposed - Reasons ?',
  'label.is_site_under_sanctioned_master_plan':
    'Is site under sanctioned master plan',
  'label.land_use_as_per_master_plan': 'Land Use as per Master Plan',
  'label.land_allotment_by_govt': 'Is land allotment done by  government?',
  'label.airport_vicinity': 'Is your land in the vicinity of the Airport?',
  'label.national_monument_vicinity':
    'Is your land is in the vicinity of National Monument Authority with in 100 - 200 mts?',
  'label.heritage_structure_vicinity':
    'Is your land is in the vicinity of Heritage Structure?',
  'label.oil_gas_pipeline_vicinity':
    'Is your land is in the vicinity or located in  Oil / Gas pipeline?',
  'label.religious_structure_vicinity':
    'Is your land is in the vicinity or Religious Structures?',
  'label.is_approach_road_existing': 'Is Approach Road existing on Site?',
  'label.road_width': 'Width of Road',
  'label.approach_road_connect_with_public_road':
    'Does Approach road connect with existing public road?',
  'label.status_of_road': 'Status of Road',
  'label.commencement_of_work_onsite': 'Commencement Of Work On Site',
  'label.schedule_of_boundaries': 'Schedule of Boundaries',
  'label.boundary_schedule_front': 'Front',
  'label.boundary_schedule_rear': 'Rear',
  'label.boundary_schedule_side_one': 'Side 1',
  'label.boundary_schedule_side_two': 'Side 2',
  'label.town_planner_license_number': 'Town Planner License number',
  'label.town_planner_name': 'Town Planner Name',
  'label.town_planner_mobile_number': 'Town Planner Phone number',
  'label.town_planner_email_id': 'Town Planner Email Id',
  'label.town_planner_address': 'Town Planner Address',
  'label.surveyor_license_number': 'Surveyor License number',
  'label.surveyor_name': 'Surveyor Name',
  'label.surveyor_mobile_number': 'Surveyor Phone number',
  'label.surveyor_email_id': 'Surveyor Email Id',
  'label.surveyor_address': 'Surveyor Address',
  'label.revenue_noc':
    'Revenue NOC - In case of allotment of govt. Land or if Govt. Land  is in Scheduled Boundries',
  'label.river_boundary':
    '50m from the boundary of the river shall be as fixed and certified by the Irrigation Department and Revenue Department.',
  'label.water_body_gt_10ha_boundary':
    '30m from the FTL boundary of Lakes/Tanks/Kuntas of area10Ha and above/ other than major rivers.',
  'label.water_body_lt_10ha_boundary':
    '9m from the FTL boundary of Lakes/ Tanks/Kuntas of area less than10Ha/ shikam lands;',
  'label.canal_gt_10m_boundary':
    '9m from the defined boundary of Canal, Vagu, Nala, Storm Water Drain of width more than 10m.',
  'label.canal_lt_10m_boundary':
    '2m from the defined boundary of Canal, Vagu, Nala, Storm Water Drain of width upto 10m.',
  'label.aai_land_layout_mapping':
    'The Land/Layout development in the vicinity of the Airport shall be guided by the Color Coded Zoning Map (CCZM) prepared by the Airport Authority of India, the guide lines issued along with the CCZM shall be followed.',
  'label.land_dev_gt_500000_or_area_gt_200000':
    'in case of land Development having an extent 50 Ha (500000 Sq Mts)  and above. OR in case of Built-up area above 200000 Sq Mts',
  'label.national_monument_authority':
    'National Monument Authority is required if the Site located within distance of above100m and upto 200m from the protected monuments.',
  'label.heritage_structure': 'Heritage Structure',
  'label.oil_gas_authority':
    'Prior clearance of Oil/Gas Authority is required if the site is located with in the vicinity of Oil/ Gas pipeline',
  'label.religious_structures': 'Religious Structures',
  'heading.setback_details': 'Setbacks Details',
  'label.setback_table_III': 'Setbacks as per Table-III G.O No 168',
  'label.front_setback': 'Setback Front',
  'label.other_setback': 'Set backs on Rear & Other sides',
  'label.rear_setback': 'Setback Rear ',
  'label.side_one_setback': 'Setback Side 1',
  'label.side_two_setback': 'Setback Side 2',
  'label.ground_floor_as_parking': 'Is your ground floor for parking?',
  'label.lrs_lp_input': 'Which approval document do you have?',
  'label.slum_name': 'Slum Area',
  'label.other_slum_name': 'Other Slum name',
  'heading.upload_site_photographs': 'Site photographs as on date',
  'header.home': 'HOME',
  'header.act': 'ABOUT TG-bPASS',
  'header.faq': 'FAQs',
  'header.autoDCR': 'DCR PORTAL',
  'header.contact': 'Contact Us',
  'header.information': 'INFORMATION',
  'header.resources': 'RESOURCES',
  'header.search': 'SEARCH',
  'header.citizen_search': 'Citizen Search',
  'header.applicant_search': 'Applicant Search',
  'header.flow_chart': 'Flow Chart',
  'header.timelines': 'Timelines',
  'header.gos': 'GOs and Acts',
  'header.departments': 'Departments',
  'header.manual': 'User manual',
  // 'header.search': 'Search',
  'header.apply': 'Apply Now',
  'header.logout': 'Logout',
  'header.building': 'Building Permission',
  'header.layout': 'Layout Permission',
  'header.tsbpass': 'TG-bPASS',
  'header.announcement': 'Announcements',
  'header.support': 'Support',
  'header.grievance': 'GRIEVANCE',
  'header.language': 'Language',
  'error.plot_area_message':
    'Plot area as on ground cannot be more than the plot area mentioned in patta certificate',
  'error.mortgage_date_message':
    'Mortgage date cannot be before 1st January 2020',
  'label.geo_coordinate_url': 'Geo-Coordinates Link',
  'error.total_built_up_area':
    'Total built-up area cannot be less than Plot area',
  'button.track_application_status': 'Click here to track application',
  'heading.search_application': 'Search application',
  'error.application_not_found': 'Application not found',
  'button.search_another_application': 'Search for another application',
  'heading.noc_processing_details': 'NOC Processing Details',

  'header.logo1': 'Telangana  Building Permission Approval',
  'header.logo2': 'Self Certification System (TG-bPASS)',
  'header.logo3': 'GOVERNMENT OF TELANGANA',

  // Login Page Lables
  'header.about': 'About',
  'header.community': 'Community',
  'header.blogs': 'Blogs',
  'heading.login_heading': 'Login to TG-bPass',
  'heading.login_info':
    'Please sign in and track your Application progress update the information.',
  'label.login': 'Login',
  'label.register': 'Register',
  'label.login_mobile_number': 'Registered Mobile Number',
  'label.firstname': 'First Name',
  'label.lastname': 'Last Name',
  'label.email_address': 'Email Address',
  'label.set_password': 'Set Password',
  'label.confirm_password': 'Confirm Password',
  'label.forgot_password': 'Forgot Password',
  'label.reset': 'Reset',
  'heading.reset_your_password': 'Reset Your Password',
  'heading.reset_your_password_info':
    'A 4 digit OTP will be sent via SMS to verify your mobile number',
  'label.send_otp': 'Send OTP',
  'label.mobile_number_verification': 'Mobile Number Verification',
  'label.mobile_number_verification_info': 'Please enter the OTP received on',
  'label.didnt_receive_code': 'Didn’t receive Code',

  'heading.building': 'Building Details',
  'heading.vicinity': 'Checklist',
  'heading.review': 'Review & Submit',

  'heading.personal_info': 'Personal Information',
  'heading.building_info': 'Building Information',
  'heading.petrol_bunk_info': 'Petrol Bunk  Details',
  'heading.layout_info': 'Layout Information',
  'heading.plot_info': 'Plot Details',
  'heading.site_info': 'Site Details',
  'heading.plot_address': 'Plot Address',

  'label.relationship': 'Relationship',

  'header.enforcement': 'ENFORCEMENT',
  'header.citizen_grievance': 'Citizen Grievance',
  'header.officer_login': 'Officer Login',
  'header.application_tracker': 'Application Tracker',

  /** *************************** */
  'heading.site_details': 'Site Details',
  'heading.clu_details': 'CLU Details',
  'heading.petrol_bunk_details': 'Petrol Bunk Details',
  'heading.purpose_heading': 'Purpose of Building',
  'heading.proposed_heading': 'Proposed Building Details',
  'heading.masterplan': 'Master Plan',
  'label.location_type': 'Location Type',
  'label.location_sub_type': 'Location Sub Type',
  'label.building_is': 'This building is',
  'label.mortagage_floors': 'Mortgage Floors',
  'title.builtup_area': 'Floor wise builtup area',
  'label.stilt': 'Stilt For Parking',
  'label.ground': 'Ground',
  'label.no_of_units': 'No of Dwelling units',
  'label.total_no_of_units': 'Total No of Dwelling units',
  'label.floor_1': 'Floor 1',
  'label.floor_2': 'Floor 2',
  'label.floor_3': 'Floor 3',
  'label.floor_4': 'Floor 4',
  'label.floor_5': 'Floor 5',
  'label.floor_6': 'Floor 6',
  'label.builtup_total': 'Total Builtup area',
  'title.abutting': 'Abutting Road Details ',
  'title.exiting': 'Approved Building Details ',
  'label.existing_floor': 'Existing Floor ',
  'label.existing_front_road_width': 'Front Road Width ',
  'label.existing_front_setback': ' Front Setback ',
  'label.existing_rear_setback': ' Rear Setback ',
  'label.existing_side_one_setback': ' Side 1 Setback ',
  'label.existing_side_two_setback': ' Side 2 Setback ',
  'label.existing_building_proceeding': 'Approved Building Permit Number',
  'label.existing_building_proceeding_date': 'Approved Building Permit Date ',
  'label.existing_building_proceeding_doc':
    'Approved Building Permit Document ',
  'label.existing_proceeding_doc': 'Approved Building Proceeding',
  'label.exising_file_number': ' Approved Building File Number ',
  'label.existing_net_plotarea': ' Approved Built up Area ',
  'label.building_road_width': 'Building Road Width',
  'label.existing_road_width': 'Approved Road Width',
  'label.proposed_road': 'Proposed Road Width',
  'label.affected_road': 'Width of Road Affected',
  'label.affected_area': 'Road Affected Area',
  'title.abutting_road_details': 'Abutting Road Details ',
  'label.approved_application_identifier': 'Approved Application Number',
  'label.building_proceeding': 'Building Proceeding',
  'label.building_proceeding_date': 'Building Proceeding Date',
  'label.affected_total': 'Total Road Affected Area',
  'label.masterplan_dec':
    'Opting Set back relaxations if in case of Masterplan road affected areas',
  'label.opting_dec':
    'Opting for TDR in case if in case of Masterplan road affected areas / As per G.O 30 MA',
  'label.built_dec':
    'Opting for Extra Built up area in case if in case of Masterplan road affected areas as per G.O 7 MA',
  'title.mortagage': 'Mortgage',
  'label.mortagage_area': 'Mortgage Area',
  'label.mortagage_reg_no': 'Mortgage Registartion Number',
  'heading.single_window_heading': 'Single Window',
  'label.single_name': 'Name',
  'label.single_mobile': 'Mobile',
  'label.single_licence_number': 'Licence Number',
  'label.single_email': 'Email',
  'label.builder_email': 'Email Address',
  'title.structural_details': 'Structural Engineer Details',
  'label.structural_name': 'Name',
  'label.structural_license_number': 'License Number',
  'label.structural_mobile_number': 'Mobile Number',
  'label.structural_email_address': 'Email Address',
  'label.village_circle': 'Village',
  'label.circle': 'Circle',
  'label.zone': 'Zone',
  'label.mandal_zone': 'Mandal',
  'heading.layout_details': 'Layout Details',
  'label.geo_coordinates': 'Geo-Coordinates',
  'label.survey_no': 'Survey No',
  'label.plot_area_document': 'Plot Area As per document',
  'label.plot_area_ground': 'Plot Area as on Ground',
  'heading.plot_checklist': 'Plot Checklist',
  'heading.technical_details': 'Technical Person Details',
  'heading.documents_tax_details': 'Documents',
  'heading.building_permission_type': 'Building Permission Type',
  'label.plot_parts': 'Is plot part of ?',
  'label.property_paid_recently': 'Is Property tax paid recently?',
  'label.yes_label': 'Yes',
  'label.no_label': 'No',
  'label.plot_label': 'Plot',
  'label.road_label': 'Road',
  'label.others': 'Others',
  'label.open_land': 'Open land',
  'label.existing_building': 'Existing Buidling',
  'label.continuously_years': 'Is VLT tax paid continuously for last 3 years?',
  'label.north_road_width': 'Existing Road Width',
  'label.north_plot_area': ' Details',
  'label.south_road_width': 'Existing Road Width',
  'label.south_plot_area': ' Details',
  'label.ease_road_width': 'Existing Road Width',
  'label.ease_plot_area': ' Details',
  'label.west_road_width': 'Existing Road Width',
  'label.west_plot_area': ' Details',
  'heading.vicinity_information': 'Application Checklist',
  'heading.document_checklist': 'Documents Checklist',
  'heading.vicinity_checklist_information': 'Application Checklist Information',
  'label.vicinity_dec': 'is site falls with in 200 Mts vicinity of waterbody?',
  'label.vicinity_dec1': 'is site falls under air funnel zone?',
  'label.vicinity_dec2':
    'is your land is in the vicinity of National Monument Authority with in 0 - 200 mts?',
  'label.vicinity_dec3':
    'is your land is in the vicinity or located in Water / Oil / Gas pipeline',
  'label.vicinity_dec4':
    'is any HT / LT or Tower line passing through / adjacent to the proposed site?',
  'label.vicinity_dec5':
    'whether sewerage system is available in the surroundings?',
  'heading.site-photographs': 'Site Photographs',
  'label.front': 'Front',
  'label.back': 'Back',
  'label.side': 'Side',
  'heading.case_type': 'Building Permission Type',
  'heading.building_plan': 'Building Plan',
  'label.building_plan_drawing': 'Building Plan Drawing',
  'label.auto_dcr': 'Auto DCR',
  'label.bua_with_parking_area': 'Bua With Parking Area',
  'label.bua_without_parking_area': 'Bua Without Parking Area',
  'label.auto_builtup_area': 'Builtup Area',
  'label.auto_message': 'Message',
  'label.auto_no_blocks': 'No Of Blocks',
  'label.auto_no_floors': 'No Of Floors',
  'label.auto_plot_depth': 'Plot Depth',
  'label.auto_plot_width': 'Plot Width',
  'label.auto_road_area': 'Road Area',
  'label.auto_status': 'Status',
  'label.tdr_area': 'TDR Area',
  'label.legal_document': 'Legally Issued Document with Land details',
  'label.encumbrance':
    'Encumbrance Certificate, List of Legal Transactions Related to the Property',
  'label.tslr_document': 'TSLR Document',
  'label.fee_details': 'Fee Details',
  'label.fee_desc': 'Fee Description',

  'header.kcr': 'Shri. K. Chandrashekar Rao',
  'header.kcr_role': 'Honourable Chief Minister of Telangana',
  'header.ktr': 'Shri.K.T.Rama Rao',
  'header.ktr_role':
    'Honourable Minister of Municipal Administration & UrbanDevelopment',

  'label.home_banner_title':
    'Telangana  Building Permission Approval and Self-Certification System',
  'label.home_banner1':
    'Registration for plot size up to 75 square yards  and the construction of ground or ground plus one floor (Residential) that are exempted from obtaining Building Permission Approval.',
  'label.home_banner2':
    'Instant online building permission approval for plot size up to 500 square meters and height up to 10 meters (Residential).',
  'label.home_banner3':
    'Single window building permission approval for plot size above 500 square meters and height above 10 meters (Residential & Non-Residential)',
  'label.home_banner4':
    'Occupancy certificate for individual residential buildings constructed in plots of above 240 sq yds (200 sq mts) and upto 600 sq yds (500 sq mts) and height upto 10mts (ground +2 floors).',

  'heading.welcome_tsbpass': 'Welcome to  TG-bPASS ',
  'label.welcome_tsbpass_tx':
    'Municipal Administration and Urban Development Department',
  'label.welcome_tsbpass_desc1':
    'Government of Telangana has enacted new Municipalities Act “Telangana Municipalities Act 2019” to improve compliance with laws by citizens, increase accountability among officials, eliminate corrupt practices with an overall aim of delivering effective services to people living in urban areas of the State by consolidating and providing for the constitution of Municipalities in accordance with part IX-A of the Indian Constitution. This act also undertakes random audits and any citizen or official deviating from the rules will be penalized.',
  'label.welcome_tsbpass_desc2':
    'Government of Telangana in tune with the Telangana Municipalities Act, 2019 has launched/introduced TG-bPASS, a single integrated platform for processing of various permissions required during development of land and construction of buildings through self-certification system. It is also designed to provide the services within stipulated timelines.',

  'heading.salient_features': 'Salient features',
  'label.salient_point1':
    'For plot size up to 75 square yards and the construction of ground or ground plus one floor (Residential) no building permission and occupancy certificate is required. Applicant is required to register with token of Rs.1.',
  'label.salient_point2':
    'For plot size up to 500 square meters and height up to 10 meters (Residential) instant building permission approval through online self-certification.',
  'label.salient_point3':
    'For plot size above 500 square meters and height above 10 meters (Residential & Non-Residential) there is single window system for obtaining multiple NOCs using Common Application Form and approval through online self-certification.',
  'label.salient_point4':
    'Processing of tentative layout approval applications through online self-certification.',
  'label.salient_point5':
    'Processing of Occupancy certificate for non-high-rise buildings constructed in plots above 200 square meters and less than 500 square meters & for high-rise buildings and buildings constructed in plots 500 square meters through online self-certification.',
  'label.salient_point6':
    'Processing of Land use certificates and Land conversion certificates.',
  'label.salient_point7': 'Post verification of the permissions issued',

  'heading.stand_out_features': 'Stand out features',
  'label.feature1_title': 'ONLINE APPLICATION',
  'label.feature1_desc':
    'Citizens can fill and submit the application online with required documents.',
  'label.feature2_title': 'SIMPLIFIED PROCESS',
  'label.feature2_desc':
    'Citizens can obtain NOC from all the line departments using single Common Application Form.',
  'label.feature3_title': 'SAFE & SECURE',
  'label.feature3_desc':
    'All transactions made on the website are encrypted and hence secure.',
  'label.feature4_title': '3-STEP PROCESS',
  'label.feature4_desc':
    'Citizens can obtain permissions in 3 easy steps - applying, uploading and paying.',
  'label.feature5_title': 'FIRST OF ITS KIND',
  'label.feature5_desc':
    'First government in the country to provide complete online services with no touchpoint.',
  'label.feature6_title': 'MOBILE RESPONSIVE',
  'label.feature6_desc':
    'Citizens can access services through desktop, tablet and mobile by having active internet connection.',

  // new labels added at 19-12-2020

  'label.applicant_annotation': 'Applicant Annotation',
  'label.relation_annotation': 'Relation Annotation',
  'heading.deviations': 'Deviations',
};
