import gql from 'graphql-tag';

export const GET_RECOMMENDATION_BUTTONS = gql`
query recommendations(
  $id: ID!
  $formInstanceId:[ID!]!
) {
  recommendations(
    id: $id,
    formInstanceId:$formInstanceId
  )
}
`;
