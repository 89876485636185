import { TbFileCertificate } from 'react-icons/tb'
import { FcBullish } from "react-icons/fc"
import { AiOutlineFileDone, AiOutlineFileSearch, AiOutlineFileText, AiOutlineMenuUnfold } from "react-icons/ai"
import { MdOutlineVerifiedUser, MdOutlineVerified } from "react-icons/md"
import { VscRequestChanges } from "react-icons/vsc"
import { BsCheck2Square } from 'react-icons/bs'
import { GrHome } from 'react-icons/gr'
import React, { useState, useContext } from 'react';
import './index.scss';
import history from '../../services/history';
import { AppContext } from './../../AppContext';
import { BsBuildings } from "react-icons/bs";
import {
  PERMISSIONS_LEVELS,
  FILTER_LIST_ENUM,
  LINE_DEPARTMENTS,
  submissionsLabel,
  reviewsLabel,
  FILTER_LIST_CORRECTION
} from '../../util/helperFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function Sidebar() {
  const [dropdown, setDropDown] = useState(true)
  const {
    currentFlow,
    setCurrentFlowGlobally,
    innerListValue,
    setInnerListValueGlobally,
    setInerCorrectionValueGlobally,
    setNewFlowGlobally,
    setInerCorrectionValue,
    innerCorrectionValue,
    data,
    newFlow
  } = useContext(AppContext);
  const { permissionLevel } = useContext(AppContext);
  console.log(permissionLevel, 'permissionLevel')
  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(
    history.location.state
      ? history.location.state.currentPage
      : currentFlow === 'single-window'
        ? 'pre-scrutiny'
        : currentFlow
  );
  const [postVerificationInnerList, setPostVerficationInnerList] = useState(
    currentFlow === 'post-verification'
  );
  // const [postVerificationInnerList, setPostVerficationInnerList] = useState(true)
  const [showCorrectionVerification, setShowCorrectionVerify] = useState(newFlow === 'Correction-verification')
  // const [correctionVerifyList, setCorrectionVerifyList] = useState(newFlow === 'Correction-verification')
  const [singleWindowInnerList, setSingleWindowInnerList] = useState(
    currentFlow === 'single-window'
  );
  const handleSideBarDropDown = () => {
    setDropDown(!dropdown)
  }
  const handleMenuClick = () => {
    document.getElementsByTagName('body')[0].classList.toggle('show-sidebar');
  };

  const isMcOrDyc = () => {
    if (permissionLevel == "COMMISSIONER" || permissionLevel == "DEPUTY_COMMISSIONER"  || permissionLevel === "FINAL APPROVING OFFICER"    ||permissionLevel === 'VICE CHAIRMAN') {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="sidebar-container">
      <div className="sidebarHeader">
        <div
          className="sidebarToggle"
          type="button"

        >
          {' '}
          <AiOutlineMenuUnfold onClick={handleMenuClick} style={{ fontSize: "21px", color: "#018749" }} />
        </div>
      </div>
      {permissionLevel === PERMISSIONS_LEVELS.CHASING_CELL ? (
        <ul className="sidebar-list">
          <li
            className={
              history.location.pathname.includes('dashboard') ? 'active' : ''
            }
            style={{ cursor: 'pointer' }}
          >
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: '/chasing-dashboard',
                  state: {
                    currentPage: 'chasing-dashboard',
                  },
                });
              }}
            >
              <img
                src={require('../../assets/images/dashboard.svg')}
                alt="dashboard"
              />
              Dashboard
            </a>
          </li>

          <li
            className={
              history.location.pathname.includes('action-panel') ? 'active' : ''
            }
            style={{ cursor: 'pointer' }}
          >
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: '/chasing-dashboard',
                  state: {
                    currentPage: 'chasing-dashboard',
                  },
                });
              }}
            >
              <img
                src={require('../../assets/images/dashboard.svg')}
                alt="dashboard"
              />
              Dashboard
            </a>
          </li>

          <li
            className={
              history.location.pathname.includes('action-panel') ? 'active' : ''
            }
            style={{ cursor: 'pointer' }}
          >
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: '/chasing-action-panel',
                  state: {
                    currentPage: 'chasing-actionpanel',
                  },
                });
              }}
            >
              <img
                src={require('../../assets/images/scrutinity.svg')}
                alt="scrutinity"
              />
              Action Panel
            </a>
          </li>
        </ul>
      ) : (
        <ul className="sidebar-list">
          {/* <li
            className={currentPage === 'dashboard' ? 'active' : ''}
            style={{ cursor: 'pointer' }}
          >
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: '/dashboard',
                  state: {
                    currentPage: 'dashboard',
                  },
                });
              }}
            >
              <img
                src={require('../../assets/images/dashboard.svg')}
                alt="dashboard"
              />
              Dashboard
            </a>
          </li> */}

          {/* <li
            // className={currentPage === 'pre-scrutiny' ? 'active' : ''}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSingleWindowInnerList(!singleWindowInnerList);
              setPostVerficationInnerList(false);
            }}
          >
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                setCurrentFlowGlobally('single-window');
                history.push({
                  pathname: '/pre-scrutiny',
                  state: {
                    currentPage: 'pre-scrutiny',
                  },
                });
              }}
            >
              <img
                src={require('../../assets/images/scrutinity.svg')}
                alt="scrutinity"
              />
              Single Window
            </a>
          </li>
          <ul
            style={{
              display: singleWindowInnerList ? '' : 'none',
            }}
          >
            <li
              className={
                innerListValue === FILTER_LIST_ENUM.all ? 'active' : ''
              }
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setInnerListValueGlobally(FILTER_LIST_ENUM.all);
              }}
            >
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentFlowGlobally('single-window');
                  history.push({
                    pathname: '/pre-scrutiny',
                    state: {
                      currentPage: 'pre-scrutiny',
                      filter: 'all',
                    },
                  });
                }}
                // style={{ justifyContent: 'center' }}
              >
                Received
              </a>
            </li>
            <li
              className={
                innerListValue === FILTER_LIST_ENUM.action_required
                  ? 'active'
                  : ''
              }
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setInnerListValueGlobally(FILTER_LIST_ENUM.action_required);
              }}
            >
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentFlowGlobally('single-window');
                  history.push({
                    pathname: '/pre-scrutiny',
                    state: {
                      currentPage: 'pre-scrutiny',
                      filter: 'action_required',
                    },
                  });
                }}
              >
                Action Required
              </a>
            </li>
            <li
              className={
                innerListValue === FILTER_LIST_ENUM.deemed ? 'active' : ''
              }
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setInnerListValueGlobally(FILTER_LIST_ENUM.deemed);
              }}
            >
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentFlowGlobally('single-window');
                  history.push({
                    pathname: '/pre-scrutiny',
                    state: {
                      currentPage: 'pre-scrutiny',
                      filter: 'deemed',
                    },
                  });
                }}
                // style={{ justifyContent: 'center' }}
              >
                Deemed
              </a>
            </li>
            <li
              className={
                innerListValue === FILTER_LIST_ENUM.completed ? 'active' : ''
              }
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setInnerListValueGlobally(FILTER_LIST_ENUM.completed);
              }}cor
            >
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentFlowGlobally('single-window');
                  history.push({
                    pathname: '/pre-scrutiny',
                    state: {
                      currentPage: 'pre-scrutiny',
                      filter: 'completed',
                    },
                  });
                }}
                // style={{ justifyContent: 'center' }}
              >
                Completed
              </a>
            </li>
          </ul> */}
          <div onClick={handleSideBarDropDown} className='SideBarList_1'>



            <li

              // className={currentPage === 'post-verification' ? 'active' : ''}
              style={{ cursor: 'pointer', alignItems: "center" }}
              onClick={() => {
                setPostVerficationInnerList(!postVerificationInnerList);
                setInnerListValueGlobally(FILTER_LIST_ENUM.all);
                setSingleWindowInnerList(false);
              }}
            >
              <a

                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentFlowGlobally('post-verification');
                  history.push({
                    pathname: '/post-verification',
                    state: {
                      currentPage: 'post-verification',
                    },
                  });
                }}
              >

                <MdOutlineVerified className='icons-react' style={{ fontSize: "20px", color: "#018749" }} />

                Post Verification  &nbsp; {dropdown == true ? <i class="arrow up"></i> : <i class="arrow down"></i>}
              </a>
            </li>
          </div>
          <div >


            <ul
              className='SideBarList_2'
              style={{
                display: postVerificationInnerList ? '' : 'none',

              }}
            >
              <li
                className={
                  innerListValue === FILTER_LIST_ENUM.all ? 'active' : ''
                }
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setInnerListValueGlobally(FILTER_LIST_ENUM.all);
                }}
              >
                <a
                  style={{ color: "#018749" }}
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentFlowGlobally('post-verification');
                    history.push({
                      pathname: '/post-verification',
                      state: {
                        currentPage: 'post-verification',
                        filter: FILTER_LIST_ENUM.all,
                      },
                    });
                  }}
                >
                  <TbFileCertificate className='icons-react' style={{ fontSize: '22px', background: "white", color: "#018749" }} />
                  {/* <img width="20px" src={require('../../assets/images/icons_56px/Received.png')} /> */}
                  Received
                </a>
              </li>


              {/* {[
              // PERMISSIONS_LEVELS.ACP_OFFICER,
              PERMISSIONS_LEVELS.TPSO_OFFICER,
            ].includes(permissionLevel) && (
                <li
                  className={
                    innerListValue === FILTER_LIST_ENUM.submissions
                      ? 'active'
                      : ''
                  }
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setInnerListValueGlobally(FILTER_LIST_ENUM.submissions);
                  }}
                >
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentFlowGlobally('post-verification');
                      history.push({
                        pathname: '/post-verification',under
                        state: {
                          currentPage: 'post-verification',
                          filter: FILTER_LIST_ENUM.submissions,
                        },
                      });
                    }}
                  >
                    <img
                      src={require('../../assets/images/verification.svg')}
                      alt="verification"
                    />
                    {submissionsLabel(PERMISSIONS_LEVELS, permissionLevel)}
                  </a>
                </li>
              )} */}
              {/* {[
              PERMISSIONS_LEVELS.ACP_OFFICER,
              PERMISSIONS_LEVELS.CP_OFFICER,
              PERMISSIONS_LEVELS.SITE_VERIFICATION,
              PERMISSIONS_LEVELS.TITLE_VERIFICATION,
              PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION,
              PERMISSIONS_LEVELS.COMMISSIONER,
              PERMISSIONS_LEVELS.PANCHAYAT_SECRETARY,
              PERMISSIONS_LEVELS.ZONAL_COMMISSIONER,
              PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER,
              PERMISSIONS_LEVELS.DTCP_DIRECTOR,
              PERMISSIONS_LEVELS.RDDTP_OFFICER,
              ...LINE_DEPARTMENTS,
            ].includes(permissionLevel) && ( */}
              <li
                className={
                  innerListValue === FILTER_LIST_ENUM.review ? 'active' : ''
                }
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setInnerListValueGlobally(FILTER_LIST_ENUM.review);
                }}
              >
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentFlowGlobally('post-verification');
                    history.push({
                      pathname: '/post-verification',
                      state: {
                        currentPage: 'post-verification',
                        filter: FILTER_LIST_ENUM.review,
                        enableNotes: true,
                      },
                    });
                  }}
                >
                  <FcBullish className='icons-react' style={{ fontSize: "20px", background: "white" }} />
                  {reviewsLabel(PERMISSIONS_LEVELS, permissionLevel)}
                </a>
              </li>
              {/* )} */}
              {/* {[
              PERMISSIONS_LEVELS.ACP_OFFICER,
              PERMISSIONS_LEVELS.CP_OFFICER,
              PERMISSIONS_LEVELS.TPSO_OFFICER,
              PERMISSIONS_LEVELS.DTCP_DIRECTOR,
              PERMISSIONS_LEVELS.RDDTP_OFFICER,
              PERMISSIONS_LEVELS.SITE_VERIFICATION,
              PERMISSIONS_LEVELS.TITLE_VERIFICATION,
              PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION,
            ].includes(permissionLevel) && ( */}
              <li
                className={
                  innerListValue === FILTER_LIST_ENUM.processed ? 'active' : ''
                }
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setInnerListValueGlobally(FILTER_LIST_ENUM.processed);
                }}
              >
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentFlowGlobally('post-verification');
                    history.push({
                      pathname: '/post-verification',
                      state: {
                        currentPage: 'post-verification',
                        filter: FILTER_LIST_ENUM.processed,
                      },
                    });
                  }}
                >
                  <BsCheck2Square className='icons-react' style={{ fontSize: "20px", background: "white", color: "#018749" }} />
                  {/* <img width="20px" src={require('../../assets/images/icons_56px/Processed.png')} /> */}
                  Processed
                </a>
              </li>

              <li
                className={
                  innerListValue === FILTER_LIST_ENUM.linedepartment ? 'active' : ''
                }
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setInnerListValueGlobally(FILTER_LIST_ENUM.linedepartment);
                }}
              >
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentFlowGlobally('post-verification');
                    history.push({
                      pathname: '/post-verification',
                      state: {
                        currentPage: 'post-verification',
                        filter: FILTER_LIST_ENUM.linedepartment,
                      },
                    });
                  }}
                >
                  <BsBuildings className='icons-react' style={{ fontSize: "20px", background: "white", color: "#018749" }} />
                  {/* <img width="20px" src={require('../../assets/images/icons_56px/Processed.png')} /> */}
                  Line Department
                </a>
              </li>
              {/* )} */}
              {/* {[
              PERMISSIONS_LEVELS.ACP_OFFICER,
              PERMISSIONS_LEVELS.CP_OFFICER,
              PERMISSIONS_LEVELS.TPSO_OFFICER,
              PERMISSIONS_LEVELS.SITE_VERIFICATION,
              PERMISSIONS_LEVELS.TITLE_VERIFICATION,
              PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION,
              PERMISSIONS_LEVELS.COMMISSIONER,
              PERMISSIONS_LEVELS.DTCP_DIRECTOR,
              PERMISSIONS_LEVELS.RDDTP_OFFICER,
              PERMISSIONS_LEVELS.PANCHAYAT_SECRETARY,
              PERMISSIONS_LEVELS.ZONAL_COMMISSIONER,
              PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER,
            ].includes(permissionLevel) && ( */}
              <li
                className={
                  innerListValue === FILTER_LIST_ENUM.deemed ? 'active' : ''
                }
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setInnerListValueGlobally(FILTER_LIST_ENUM.deemed);
                }}
              >
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentFlowGlobally('post-verification');
                    history.push({
                      pathname: '/post-verification',
                      state: {
                        currentPage: 'post-verification',
                        filter: FILTER_LIST_ENUM.deemed,
                      },
                    });
                  }}
                >
                  <AiOutlineFileText className='icons-react' style={{ fontSize: "20px", background: "white", color: "#018749" }} />
                  Deemed
                </a>
              </li>
              {/* )} */}
              <li
                className={
                  innerListValue === FILTER_LIST_ENUM.completed ? 'active' : ''
                }
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setInnerListValueGlobally(FILTER_LIST_ENUM.completed);
                }}
              >
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentFlowGlobally('post-verification');
                    history.push({
                      pathname: '/post-verification',
                      state: {
                        currentPage: 'post-verification',
                        filter: FILTER_LIST_ENUM.completed,
                      },
                    });
                  }}
                >
                  <MdOutlineVerifiedUser className='icons-react' style={{ fontSize: "20px", background: "white", color: "#018749" }} />
                  {/* <img width="20px" src={require('../../assets/images/icons_56px/Completed.png')} /> */}
                  Completed
                </a>
              </li>
            </ul>
          </div>
        </ul>


      )}

      <ul className="sidebar-list">


        <div className='SideBarList_1'>
          <li
            style={{ cursor: 'pointer', alignItems: "center" }}
            onClick={() => {
              setShowCorrectionVerify(!showCorrectionVerification)
            }}
          >
            <a
              href="#!"
              style={{ fontSize: "14px" }}
            // onClick={(e)=>{
            //   e.preventDefault();
            //   setNewFlowGlobally('Correction-verification');
            //   history.push('/correction-verification');
            // }}
            >
              <VscRequestChanges className='icons-react' style={{ fontSize: "22px", background: "white", color: "#018749" }} />
              {" Correction"}  &nbsp; {showCorrectionVerification == false ? <i class="arrow down"></i> : <i class="arrow up"></i>}
            </a>
          </li>

        </div>

        <ul
          className='SideBarList_3'
          style={{
            display: showCorrectionVerification ? ' ' : 'none',

          }}
        >
          {(permissionLevel == "COMMISSIONER" || permissionLevel == "DEPUTY_COMMISSIONER" || permissionLevel == 'FINAL APPROVING OFFICER') ?
            <li
              className={
                innerListValue === FILTER_LIST_CORRECTION.Submitted ? 'active' : ''
              }
              style={{ cursor: 'pointer' }}
              onClick={() => {
                //history.push('/correction-verification')
                // console.log(FILTER_LIST_CORRECTION.Submitted)
                setInnerListValueGlobally(FILTER_LIST_CORRECTION.Submitted)
                setInerCorrectionValueGlobally(FILTER_LIST_CORRECTION.Submitted);
              }}
            >
              <a
                className='DashbordLinks'
                style={{ fontSize: "13px", width: "100%" }}
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  setInerCorrectionValueGlobally('Correction-verification');
                  history.push('/correction-verification');
                }}
              >
                <AiOutlineFileSearch style={{ fontSize: "22px", background: "white", color: "#018749" }} />
                Correction Inprocess
              </a>
            </li> :

            <li
              className={

                innerListValue === FILTER_LIST_CORRECTION.correction ? 'active' : ''
              }
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setInnerListValueGlobally(FILTER_LIST_CORRECTION.correction)

                //history.push('/correction-verification')
                console.log(FILTER_LIST_CORRECTION.correction)
                setInerCorrectionValueGlobally(FILTER_LIST_CORRECTION.correction);
              }}
            >
              <a
                className='DashbordLinks'
                style={{ fontSize: "13px", width: "100%" }}
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  setInerCorrectionValueGlobally('Correction-verification');
                  history.push('/correction-verification');
                }}
              >
                <AiOutlineFileSearch style={{ fontSize: "22px", color: "#018749" }} />
                Correction Inprocess
              </a>

            </li>}
          <li
            className={
              innerListValue === FILTER_LIST_CORRECTION.completed ? 'active' : ''
            }
            style={{ cursor: 'pointer' }}
            onClick={() => {
              //  console.log(FILTER_LIST_CORRECTION.completed)
              //history.push('/correction-verification')
              setInnerListValueGlobally(FILTER_LIST_CORRECTION.completed)

              setInerCorrectionValue(FILTER_LIST_CORRECTION.completed);
            }}
          >
            <a
              className=''
              style={{ fontSize: "13px", width: "100%" }}
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                setNewFlowGlobally('Correction-verification');
                history.push('/correction-verification');
              }}
            >
              <AiOutlineFileDone style={{ fontSize: "22px", background: "white", color: "#018749" }} />
              Correction Processed
            </a>

          </li>


          <li>

          </li>
        </ul>

      </ul>


      <ul className="sidebar-list">
        <ul className='SideBarList_3'>

          <li>
            <a className='DashbordLinks'
              target='_blank'
              style={{ color: '#32de84' }}
              href='https://lookerstudio.google.com/u/0/reporting/0dfd0da1-2a33-4799-be19-154fbd9a3734/page/p_mapmg5px7c'>Application Dashboard
            </a>

          </li>

          <li>
            <a
              className='DashbordLinks'
              style={{ color: 'tomato' }}
              target='_blank'
              href={data?.me.ulbName === 'GHMC' ? "https://lookerstudio.google.com/u/0/reporting/98a72230-1590-4046-be02-e9bb4a2385db/page/p_djq19hkhhd" : "https://lookerstudio.google.com/u/0/reporting/3aea762a-b437-4a3f-aa1e-1cd98ff369e9/page/p_dj7o4ld86c"}> Pendency Dashboard
            </a>
          </li>

          {isMcOrDyc() && <li>
            <a
              className='DashbordLinks'
              style={{ color: '#880e4f' }}
              target='_blank'
              href='https://lookerstudio.google.com/u/0/reporting/c9d28a07-4a7b-4997-bb8d-6d046532ad73/page/am1yD'
            >
              Revenue Dashboard
            </a>
          </li>
          }

        </ul>
      </ul>
    </div>
  );
}

export default Sidebar;
