
import React from 'react';
import { Form } from 'react-bootstrap';
import {MultiSelect} from 'react-multi-select-component';
import { OCRejectOptions, rejectionOptions } from '../../util/mock';
import PropTypes from 'prop-types';
import history from '../../services/history';
function MultiSelectDropdown({
  selected,
  setSelected,
  otherRemarks,
  setOtherRemarks,
  hidePadding,
  recommendationStatus,
  formStatus
}) {
  
  const getOptions = () => {
    if (history.location.pathname.split('/').includes('occ')) {
      return OCRejectOptions['OCCUPANCY'];
    } else if (
      history.location.pathname.split('/').includes('site-verification')
    ) {
      return rejectionOptions['SITE_VERIFICATION'];
    } else if (
      history.location.pathname.split('/').includes('title-verification')
    ) {
      return rejectionOptions['TITLE_VERIFICATION'];
    } else if (
      history.location.pathname.split('/').includes('technical-verification')
    ) {
      return rejectionOptions['TECHNICAL_VERIFICATION'];
    } else if (
      history.location.pathname.split('/').includes('sw-verification')
    ){
      return rejectionOptions['SINGLEWINDOWVERIFICATION']
    }
    
    else {
      return rejectionOptions['COMMISSIONER'];
    }
  };
  const options = getOptions();
// console.log(formStatus,'formStatus');
  return (
    <div className='multiSelect__wrapper'  style={hidePadding ? { padding: '25px 0' } : { padding: '0px 0px 150px 0px' }}>

      <div className=''>

        <div style={{ color: '#0564AC' }} className="mb-1">
        Reason for {formStatus === "NOT_RECOMMENDED"  || formStatus == "NOT_RECOMMENDED_WITH_REFUND"? "Rejecting" : "Show cause"} : 
        </div>
        <MultiSelect
          className=""
          options={options}
          value={selected}
          onChange={setSelected}
          labelledBy={'Select'}
        />
      </div>
      {selected && selected.map((item) => item.label).includes('Other') && ( 
<div className='OtherReasonInput'>
              <Form>
            <Form.Group controlId="other">
              <Form.Label
                className="form-Label"
                style={{ fontSize: '13px', color: 'green' }}
              >
                Other Reason:
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="2"
                placeholder="Text here"
                value={otherRemarks}
                onChange={(event) => setOtherRemarks(event.target.value)}
              />
            </Form.Group>
          </Form>
          </div>
       )}
        
         {/* <div>
      <Form>
            <Form.Group controlId="other">
              <Form.Label
                className="form-Label"
                style={{ fontSize: '13px', color: 'green' }}
              >
                Other Reason:
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="2"
                placeholder="Text here"
                value={otherRemarks}
                onChange={(event) => setOtherRemarks(event.target.value)}
              />
            </Form.Group>
          </Form>
      </div> */}
    
          </div>
  );
}

export default MultiSelectDropdown;

MultiSelectDropdown.propTypes = {
  otherRemarks: PropTypes.string,
  selected: PropTypes.array,
  setOtherRemarks: PropTypes.func,
  setSelected: PropTypes.func,
  hidePadding: PropTypes.bool,
};

MultiSelectDropdown.defaultProps = {
  otherRemarks: '',
  selected: [],
  setOtherRemarks: () => {},
  setSelected: () => {},
  hidePadding: false,
};
