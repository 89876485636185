import React from 'react';
import { Drawer, } from 'antd';

const CustomRightDrawer = ({ title, placement, closable, onClose, visible, children }) => {
  return (
    <Drawer
      className="custom-antd-drawer"
      title={title || ""}
      placement={placement || 'right'}
      closable={closable || false}
      onClose={onClose}
      visible={visible}
      width={400}
      style={{ paddingTop: 83, }}
      drawerStyle={{
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "5px 0px 56px #0000000F"
      }}
    >
      {children}
    </Drawer>
  );
}

export default CustomRightDrawer;
