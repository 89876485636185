import React, { useContext, useState, useEffect } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';

import history from '../../services/history';
import PropTypes from 'prop-types';
import { AppContext } from './../../AppContext';
import StatusReviewModal from '../StatusReviewModal';
import {
  APPLICATION_STATUS_ENUM,
  PERMISSIONS_LEVELS,
  LINE_DEPARTMENTS,
  isStageRecommended as isRecommended,
  dayDifference,
} from '../../util/helperFunctions';
import { Button } from '@mui/material';


const classType = {
  red: 'rejected',
  green: 'approved',
  orange: 'inprogress',
  deemed: 'deemed',
};

function getClassFromDueDate(dueDate) {
  const dayDiff = dayDifference(dueDate);

  // if (dayDiff <= 0) return classType.deemed;
  // if (dayDiff <= 3) return classType.red;
  // if (dayDiff <= 6) return classType.orange;
  if (dayDiff < 0) return classType.red;
  return classType.green;
}


function ApplicationItem(props) {


  //  console.log(props.data.approvalFor,"identi---")
  // console.log(props.data?.stageStates[0]?.stageOfficers[0]?.officerRole,"props.data.stageStates.stageOfficers.officerRole")
  console.log(props, "application_type")
  const [showPopUpAfterSubmit, setShowPopUpAfterSubmit] = useState(false);
  const [submittedPopState, setSubmittedPopState] = useState(false);
  const handleClosePopUpAfterSubmit = () => setShowPopUpAfterSubmit(false);
  const handleShowAfterSubmit = () => setShowPopUpAfterSubmit(true);
  const { permissionLevel, currentFlow, setCurrentFlowGlobally, officerType } =
    useContext(AppContext);
  console.log(officerType, 'officerType');

  const isAllStagesRecommended = () => {
    return (
      isRecommended(
        'TECHNICAL VERIFICATION',
        props.data.stageStates,
        props.data.currentOfficerLevel
      ) &&
      isRecommended(
        'TITLE VERIFICATION',
        props.data.stageStates,
        props.data.currentOfficerLevel
      ) &&
      isRecommended(
        'SITE VERIFICATION',
        props.data.stageStates,
        props.data.currentOfficerLevel
      )
    );
  };


  function isOldFloworNot() {
    const curretDate = props.assignedDate;
    const givenDate = new Date(curretDate)
    const targetDate = new Date("2023-09-03");

    if (givenDate > targetDate) {
      return false
    } else {
      return true
    }
  }


  const [show, setShow] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [verifyButtonText, setVerifyButtonText] = useState('Verify');
  const targetStages = ['TITLE VERIFICATION'];
  useEffect(() => {
    // //debugger;
    if (permissionLevel === PERMISSIONS_LEVELS.PANCHAYAT_SECRETARY) {
      setVerifyButtonText("View")
    }

    if (
      permissionLevel === PERMISSIONS_LEVELS.TPSO_OFFICER ||
      permissionLevel === PERMISSIONS_LEVELS.ACP_OFFICER ||
      permissionLevel === PERMISSIONS_LEVELS.CP_OFFICER
    ) {
      if (currentFlow !== 'single-window') {
        if (
          (props.data.applicationType === 'SELF CERTIFICATION' ||
            props.data.approvalFor === 'OCCUPANCY') &&
          props.data.status === 'FEE_ADJUSTMENTS'
        ) {
          setShowDropDown(false);
        } else {
          setShowDropDown(true);
        }
      } else {
        if (props.data.currentOfficerLevel === 'L1') {
          setShowDropDown(true);
        } else {
          setShowDropDown(false);
        }
      }
    } else {
      setShowDropDown(false);
    }
    // console.log(props.data?.stageStates,"stageStates")

    if (permissionLevel === PERMISSIONS_LEVELS.TITLE_VERIFICATION) {
      let stageStatesObj = props.data?.stageStates.find(
        (item) => targetStages.includes(item.name)
      );
      if (stageStatesObj?.status !== APPLICATION_STATUS_ENUM.IN_PROCESS) {
        setVerifyButtonText(stageStatesObj?.status);
      } else {
        setVerifyButtonText('Verify');
      }
    }


    else if (permissionLevel === PERMISSIONS_LEVELS.SITE_VERIFICATION) {
      let stageStatesObj = props.data?.stageStates.find(
        (item) => item.name === 'SITE VERIFICATION'
      );
      if (stageStatesObj?.status !== APPLICATION_STATUS_ENUM.IN_PROCESS) {
        setVerifyButtonText(stageStatesObj?.status);
      } else {
        setVerifyButtonText('Verify');
      }
    } else if (permissionLevel === PERMISSIONS_LEVELS.TECHNICAL_SCRUTINY_OFFICER) {
      // ////debugger;
      let stageStatesObj = props.data?.stageStates?.find(
        (item) => item.name === 'LEVEL-1' || item.name === "TECHNICAL VERIFICATION"
      );
      if (stageStatesObj?.status !== APPLICATION_STATUS_ENUM.IN_PROCESS) {
        setVerifyButtonText(stageStatesObj?.status);
      } else {
        setVerifyButtonText('Verify');
      }
    }
    if (props.data.status === 'FEE_ADJUSTMENTS') {
      setVerifyButtonText(props.data.status);
    }
  }, [props]);


  // useEffect(()=>{
  // if (permissionLevel === PERMISSIONS_LEVELS.TITLE_VERIFICATION) {

  //     let stageStatesObj = props.data?.stageStates.find(
  //       (item) => item.name === 'LEVEL-1B' 
  //     );
  //     if (stageStatesObj?.status !== APPLICATION_STATUS_ENUM.IN_PROCESS) {
  //       setVerifyButtonText(stageStatesObj?.status);
  //     } else {
  //       setVerifyButtonText('Verify');
  //     }
  //   }
  //   else if (permissionLevel === PERMISSIONS_LEVELS.TITLE_VERIFICATION) {

  //       let stageStatesObj = props.data?.stageStates.find(
  //         (item) => item.name === 'LEVEL-1A' 
  //       );
  //       if (stageStatesObj?.status !== APPLICATION_STATUS_ENUM.IN_PROCESS) {
  //         setVerifyButtonText(stageStatesObj?.status);
  //       } else {
  //         setVerifyButtonText('Verify');
  //       }
  //     }
  // },[props])

  // console.log(props, 'props.data.currentOfficerLevel')
  const handleClose = () => setShow(false);
  // eslint-disable-next-line no-unused-vars
  const handleShow = () => setShow(true);
  function displayingTag() {
    if (props.data.approvalFor === 'OCCUPANCY' || props.data.revision === 'YES' || props.data.additional === 'YES') {
      return (
        <div className="addition-tag">
          <div className="btns-success">
            {props.data.approvalFor === 'OCCUPANCY' ? props.data.referenceApplicationType : props.data.additionalType}
          </div>
        </div>
      );
    }

    return null; // Or any default component to render when conditions are not met
  }

  const handleVerifyClick = (event) => {
    // console.log("clicked checking" ,props.data.currentOfficerLevel);
    // //debugger;

    event.stopPropagation();
    if (props.data.currentOfficerLevel === 'L1' && officerType === 'GHMC') {


      // history.push(`/iairVerification/${props.data.id}`, {
      //   pageType: props.pageType,
      //   applicationType: props.data.applicationType,
      // });


    }
    if (
      props.data.currentOfficerLevel === 'L2' &&
      officerType === 'GHMC' &&
      currentFlow === 'single-window'

    ) {
      history.push(`/commissioner-verification/${props.data.id}`, {
        pageType: props.pageType,
        status: props.data.status,
      });
      return;
    }
    if ((permissionLevel === PERMISSIONS_LEVELS.PANCHAYAT_SECRETARY)
    ) {
      history.push(`/application-details/${props.data.id}`)
      return;
    }
    if (permissionLevel === PERMISSIONS_LEVELS.ACP_OFFICER && officerType === 'GHMC') {
      if (props.data.status === 'FEE_ADJUSTMENTS') {
        history.push({
          pathname: `/fee-verification/${props.data.id}`,
          state: {
            applicationIdentifier: props.data.applicationIdentifier,
          },
        });
      } else {

        history.push(`/iairVerification/${props.data.id}`, {
          pageType: props.pageType,
          applicationType: props.data.applicationType,
        });

      }
    }
    if (props.data.applicationType === 'SINGLE WINDOW') {
      if(props.data.approvalFor == 'OCCUPANCY') {
        history.push(`/sw-verification/occ/${props.data.id}`);
 
      } else {
        history.push(`/sw-verification/${props.data.id}`);

      }
      
    }

    // if(props.data.applicationType !== 'SINGLE WINDOW' && props.data.status !== 'FEE_ADJUSTMENTS' &&  officerType === 'GHMC' ) {
    //   if (props.data.approvalFor === 'OCCUPANCY') {
    //     history.push(`/iairVerification/occ/${props.data.id}`, {
    //       applicationType: props.data.applicationType,
    //     });
    //   }else {
    //     history.push(`/iairVerification/${props.data.id}`, {
    //       applicationType: props.data.applicationType,
    //     });
    //   }
    //   return;
    // }
    // if (permissionLevel === PERMISSIONS_LEVELS.ACP_OFFICER && props.data.status === 'FEE_ADJUSTMENTS') {
    //   history.push(`/fee-verification/${props.data.id}`, {
    //     pageType: props.pageType,
    //     status: props.data.status,
    //   });
    //   return;
    // }




    if (permissionLevel === PERMISSIONS_LEVELS.ACP_OFFICER || permissionLevel === PERMISSIONS_LEVELS.TPSO_OFFICER && officerType === 'GHMC') {

      if (props.data.approvalFor === 'OCCUPANCY') {
        if (props.data.status === 'FEE_ADJUSTMENTS') {
          history.push({
            pathname: `/fee-verification/${props.data.id}`,
            state: {
              applicationIdentifier: props.data.applicationIdentifier,
            },
          });
        } else {

          history.push(`/iairVerification/occ/${props.data.id}`, {
            pageType: props.pageType,
            applicationType: props.data.applicationType,
          });

        }
      } else if (props.data.applicationType !== 'SINGLE WINDOW' && officerType === 'GHMC' && props.data.status === APPLICATION_STATUS_ENUM.FEE_ADJUSTMENTS) {
        history.push(`/fee-verification/${props.data.id}`, {
          pageType: props.pageType,
          status: props.data.status,
        })
      }
      else {
        history.push(`/iairVerification/${props.data.id}`, {
          pageType: props.pageType,
          applicationType: props.data.applicationType,
        });

      }
    } else {
      if (
        permissionLevel === PERMISSIONS_LEVELS.COMMISSIONER ||
        permissionLevel === PERMISSIONS_LEVELS.PANCHAYAT_SECRETARY ||
        permissionLevel === PERMISSIONS_LEVELS.ZONAL_COMMISSIONER ||
        permissionLevel === PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER ||
        permissionLevel === PERMISSIONS_LEVELS.FINAL_APPROVING_OFFICER
      ) {
        if (props.data.approvalFor === 'OCCUPANCY') {
          if (permissionLevel !== PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER) {
            if (isOldFloworNot()) {
              history.push(`/iair-CommisionerVerify/occ/${props.data.id}`, {
                pageType: props.pageType,
                applicationType: props.data.applicationType,
              });
            } else {
              history.push(`/iair-CommisionerVerify/occ/${props.data.id}`, {
                pageType: props.pageType,
                applicationType: props.data.applicationType,
              });
            }

          } else {
            if (permissionLevel == PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER) {
              history.push(`/iair-CommisionerVerify/occ/${props.data.id}`, {
                pageType: props.pageType,
                status: props.data.status,
              });
            }
          }
        } else  if (props.data.approvalFor === 'COMPOUNDWALL') {
          history.push(`/iair-CommisionerVerify/CW/${props.data.id}`, {
            pageType: props.pageType,
            applicationType: props.data.applicationType,
          });
        }

        else {

          history.push(`/iair-CommisionerVerify/${props.data.id}`, {
            pageType: props.pageType,
            status: props.data.status,
          });

        }
      }
      else if (


        permissionLevel === PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION &&
        props.data?.stageStates.find(

          (item) => item.name === 'TECHNICAL VERIFICATION'
        )?.status === APPLICATION_STATUS_ENUM.IN_PROCESS
      ) {

        if (props.data.approvalFor === 'OCCUPANCY') {

          if (isOldFloworNot()) {
            history.push(`/technical-verification/occ/${props.data.id}`, {
              pageType: props.pageType,
              applicationType: props.data.applicationType,
            });
          } else {
            history.push(`/iairVerification/occ/${props.data.id}`, {
              pageType: props.pageType,
              applicationType: props.data.applicationType,
            });
          }


        } else {

          history.push(`/iairVerification/${props.data.id}`, {
            applicationType: props.data.applicationType,
          });

        }


      }

      else if (
        permissionLevel === PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION &&
        props.data?.stageStates.find(
          (item) => item.name === 'LEVEL-1'
        )?.status === APPLICATION_STATUS_ENUM.IN_PROCESS
      ) {

        if (props.data.approvalFor === 'OCCUPANCY') {

          if (isOldFloworNot()) {
            history.push(`/technical-verification/occ/${props.data.id}`, {
              pageType: props.pageType,
              applicationType: props.data.applicationType,
            });
          } else {
            history.push(`/iairVerification/occ/${props.data.id}`, {
              pageType: props.pageType,
              applicationType: props.data.applicationType,
            })
          }


        } else {


          history.push(`/iairVerification/${props.data.id}`, {
            applicationType: props.data.applicationType,
          });

        }


      } else if (
        permissionLevel === PERMISSIONS_LEVELS.SECTION_OFFICER &&
        props.data?.stageStates.find(
          (item) => item.name === 'TECHNICAL VERIFICATION'
        )?.status === APPLICATION_STATUS_ENUM.IN_PROCESS
      ) {

        if (props.data.approvalFor === 'OCCUPANCY') {

          if (isOldFloworNot()) {
            history.push(`/technical-verification/occ/${props.data.id}`, {
              pageType: props.pageType,
              applicationType: props.data.applicationType,
            });
          } else {
            history.push(`/iairVerification/occ/${props.data.id}`, {
              pageType: props.pageType,
              applicationType: props.data.applicationType,
            });
          }


        } else {


          history.push(`/iairVerification/${props.data.id}`, {
            applicationType: props.data.applicationType,
          });

        }


      }
      else if (
        permissionLevel === PERMISSIONS_LEVELS.SECTION_OFFICER &&
        props.data?.stageStates.find(
          (item) => item.name === 'LEVEL-1A'
        )?.status === APPLICATION_STATUS_ENUM.IN_PROCESS
      ) {

        if (props.data.approvalFor === 'OCCUPANCY') {

          if (isOldFloworNot()) {
            history.push(`/technical-verification/occ/${props.data.id}`, {
              pageType: props.pageType,
              applicationType: props.data.applicationType,
            });
          } else {
            history.push(`/iairVerification/occ/${props.data.id}`, {
              pageType: props.pageType,
              applicationType: props.data.applicationType,
            });
          }


        } else {


          history.push(`/iairVerification/${props.data.id}`, {
            applicationType: props.data.applicationType,
          });

        }


      }
      else if (
        permissionLevel === PERMISSIONS_LEVELS.SECTION_OFFICER &&
        props.data?.stageStates.find(
          (item) => item.name === 'LEVEL-1'
        )?.status === APPLICATION_STATUS_ENUM.IN_PROCESS
      ) {

        if (props.data.approvalFor === 'OCCUPANCY') {

          if (isOldFloworNot()) {
            history.push(`/technical-verification/occ/${props.data.id}`, {
              pageType: props.pageType,
              applicationType: props.data.applicationType,
            });
          } else {
            history.push(`/iairVerification/occ/${props.data.id}`, {
              pageType: props.pageType,
              applicationType: props.data.applicationType,
            });
          }


        }
        else {


          history.push(`/iairVerification/${props.data.id}`, {
            applicationType: props.data.applicationType,
          });

        }


      }
      // FEE_ADJUSTMENTS
      else if (
        permissionLevel === PERMISSIONS_LEVELS.SECTION_OFFICER &&
        props.data.status === 'FEE_ADJUSTMENTS'
      ) {



        history.push({
          pathname: `/fee-verification/${props.data.id}`,
          state: {
            applicationIdentifier: props.data.applicationIdentifier,
          },
        });
      } else if (
        permissionLevel === PERMISSIONS_LEVELS.SECTION_OFFICER &&
        props.data?.stageStates.find(
          (item) => item.name === 'FEE_ADJUSTMENTS'
        )?.status === APPLICATION_STATUS_ENUM.IN_PROCESS
      ) {



        history.push({
          pathname: `/fee-verification/${props.data.id}`,
          state: {
            applicationIdentifier: props.data.applicationIdentifier,
          },
        });
      }



      else if (
        permissionLevel === PERMISSIONS_LEVELS.SECTION_OFFICER &&
        props.data?.stageStates.find(
          (item) => item.name === 'LEVEL-1B'
        )?.status === APPLICATION_STATUS_ENUM.IN_PROCESS
      ) {

        if (props.data.approvalFor === 'OCCUPANCY') {

          if (isOldFloworNot()) {
            history.push(`/technical-verification/occ/${props.data.id}`, {
              pageType: props.pageType,
              applicationType: props.data.applicationType,
            });
          } else {
            history.push(`/iairVerification/occ/${props.data.id}`, {
              pageType: props.pageType,
              applicationType: props.data.applicationType,
            });
          }


        } else {


          history.push(`/iairVerification/${props.data.id}`, {
            applicationType: props.data.applicationType,
          });

        }


      }
      else if (
        permissionLevel === PERMISSIONS_LEVELS.SECTION_HEAD &&
        props.data?.stageStates.find(
          (item) => item.name === 'LEVEL-2'
        )?.status === APPLICATION_STATUS_ENUM.IN_PROCESS
      ) {
        if (props.data.approvalFor === 'OCCUPANCY') {

          if (isOldFloworNot()) {
            history.push(`/technical-verification/occ/${props.data.id}`, {
              pageType: props.pageType,
              applicationType: props.data.applicationType,
            });
          } else {
            history.push(`/iairVerification/occ/${props.data.id}`, {
              pageType: props.pageType,
              applicationType: props.data.applicationType,
            });
          }


        } else {

          history.push(`/iairVerification/${props.data.id}`, {
            applicationType: props.data.applicationType,
          });

        }

      }

      else if (
        permissionLevel === PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION &&
        props.data.status === APPLICATION_STATUS_ENUM.FEE_ADJUSTMENTS
      ) {
        history.push({
          pathname: `/fee-verification/${props.data.id}`,
          state: {
            applicationIdentifier: props.data.applicationIdentifier,
          },
        });
      }

      else if (props.data.applicationType !== 'SINGLE WINDOW' && officerType === 'GHMC' && props.data.status === APPLICATION_STATUS_ENUM.FEE_ADJUSTMENTS) {
        history.push(`/fee-verification/${props.data.id}`, {
          pageType: props.pageType,
          status: props.data.status,
        });
      }





      else if (
        permissionLevel === PERMISSIONS_LEVELS.SITE_VERIFICATION &&
        props.data?.stageStates.find((item) => item.name === 'SITE VERIFICATION')
          .status === APPLICATION_STATUS_ENUM.IN_PROCESS
      ) {
        if (props.data.approvalFor === 'OCCUPANCY') {
          history.push(`/site-verification/occ/${props.data.id}`, {
            applicationType: props.data.applicationType,
          });
        } else {

          history.push(`/iairVerification/${props.data.id}`, {
            applicationType: props.data.applicationType,
          });

        }
      } else if (
        permissionLevel === PERMISSIONS_LEVELS.TITLE_VERIFICATION &&
        props.data?.stageStates.find((item) => item.name === 'TITLE VERIFICATION')
          ?.status === APPLICATION_STATUS_ENUM.IN_PROCESS
      ) {

        history.push(`/iairVerification/${props.data.id}`, {
          applicationType: props.data.applicationType,
        });


      }


      else if (
        permissionLevel === PERMISSIONS_LEVELS.TITLE_VERIFICATION &&
        props.data?.stageStates.find((item) => item.name === 'LEVEL-1B')
          ?.status === APPLICATION_STATUS_ENUM.IN_PROCESS
      ) {

        history.push(`/iairVerification/${props.data.id}`, {
          applicationType: props.data.applicationType,
        });


      }
      else if (LINE_DEPARTMENTS.includes(permissionLevel)) {
        if( props.data.applicationType == 'SINGLE WINDOW') {
          history.push(`/SW-line-verification/${props.data.id}`, {
            applicationType: props.data.applicationType,
          });
        }else {
          history.push(`/line-verification/${props.data.id}`, {
            applicationType: props.data.applicationType,
          });
        }
      
      }
    };
  }

  const formatDateToString = (date) => {
    date = date?.split(' ')[0];
    date = new Date(date);
    var dd = (date.getDate() < 10 ? '0' : '') + date?.getDate();
    var MM = (date.getMonth() + 1 < 10 ? '0' : '') + (date?.getMonth() + 1);
    var yyyy = date.getFullYear();
    return dd + '/' + MM + '/' + yyyy;
  };
  const getStatus = (str) => {
    let result = str.replace(/_/g, ' ');
    if (
      props?.data?.applicationType === 'SELF CERTIFICATION' ||
      props?.data?.applicationType === 'REGISTRATION'
    ) {
      if (result === 'REJECTED') {
        return 'REVOKED';
      }
    }
    if (result === 'PROCEEDINGS IN PROGRESS') {
      return 'FINAL PROCESSING';
    }
    if (result === 'DEEMED') {
      return 'DEEMED APPROVED';
    }
    return result;
  };
  const showVerify = () => {
    return (
      ![
        // PERMISSIONS_LEVELS.COMMISSIONER,
        // PERMISSIONS_LEVELS.ZONAL_COMMISSIONER,
        // PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER,
      ].includes(permissionLevel) ||
      getStatus(props.data.status).includes('PROCEEDINGS')
    );
  };

  const mapClassTypeFlag = {
    rejected: 'red',
    approved: 'green',
    inprogress: 'orange',
    deemed: 'blue',
  };

  const ModalComponent = () => {
    return (
      <Modal
        show={showPopUpAfterSubmit}
        onHide={handleClosePopUpAfterSubmit}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: '0 none', paddingBottom: '0px' }}
        />
        <Modal.Body style={{ paddingTop: '0px' }}>
          <div className="px-2">
            <p style={{ fontSize: '14px', color: 'red' }}>
              ** CLU Fee to be Incorporated Mandatorily where required Prior to
              &#34;Final Submit fee Button&#34; if Plot Falls Under as Follows
              **
            </p>
            <p style={{ fontSize: '12px', color: 'red' }}>
              1. where the applicants have submitted LRS applications under the
              scheme they have to submit the acknowledgment and in case land use
              is contrary to rules, then CLU charges shall be levied along with
              LRS charges as per GO. 131 &135.
            </p>
            <p style={{ fontSize: '12px', color: 'red' }}>
              2. Where the Applicants have not submitted the LRS
              acknowledgement, then the land use shall be verified and where the
              building permission proposal is in conformity with land use as per
              master plan, those applications can be considered in TSbPASS
              subject to collecting LRS charges if the plot is registered prior
              to 26.8.2020.
            </p>
            <p style={{ fontSize: '12px', color: 'red' }}>
              3. If the applicants have not submitted the application under LRS
              and if the proposal is contrary to land use as per master plan,
              those applications are liable for rejection.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: '0 none' }}
          className="d-flex justify-content-between"
        >
          <Button
            variant="outline-secondary"
            className="btn btn-secondary"
            onClick={() => handleClosePopUpAfterSubmit()}
            style={{ width: '40%' }}
          >
            Cancel
          </Button>
          <Button
            className="modal-button-sucess"
            variant="outline-success"
            onClick={(event) => {
              handleVerifyClick(event);
              handleClosePopUpAfterSubmit();
            }}
          // style={{ width: '50%',border:"1px solid green " }}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleFeeReview = (applicationStatus) => {
    if (permissionLevel === 'SITE_VERIFICATION') {
      console.log(applicationStatus);
      if (applicationStatus[1].status === 'IN_PROCESS') {
        console.log('Please verify Application First');
      } else {
        console.log('here ');
      }
    }
  };

  const submittedModal = () => {
    return (
      <Modal
        show={submittedPopState}
        onHide={() => setSubmittedPopState(false)}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: '0 none', paddingBottom: '0px' }}
        />
        <Modal.Body style={{ paddingTop: '0px' }}>
          <div className="px-2">Application has been already submitted.</div>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: '0 none' }}
          className="d-flex justify-content-between"
        >
          <Button
            className="btn btn-success"
            onClick={() => {
              setSubmittedPopState(false);
            }}
            style={{ width: '50%' }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>

      {ModalComponent()}
      {submittedModal()}
      {['DEEMED'].includes(props.filter) &&
        props?.data.deemedDueDate &&
        props.data.deemedDueDate < new Date() ? (
        <></>
      ) : (
        <tr className={getClassFromDueDate(props.data.dangerDate)}>
          {/* options :approved,notstarted,rejected,inhold,inprogress  */}
          {/* <td nowrap="true">
            <span className="flag">
              <img
                alt=""
                src={require(`../../assets/images/${mapClassTypeFlag[getClassFromDueDate(props.data.dangerDate)]
                  }.svg`)}
              />
            </span>
          </td> */}
          <td>{props.index}</td>
          <td
            nowrap="true"
            className="text-left"
            style={{ cursor: 'pointer', }}
            onClick={(event) => {
              event.stopPropagation();
              setCurrentFlowGlobally(props.pageType);
              if (props.data.approvalFor === 'OCCUPANCY') {
                history.push(`/application-details/occ/${props.data.id}`, {
                  applicationType: props.data.applicationType,
                  enableNotes: props.enableNotes,
                });
              } else  if(props.data.approvalFor === 'COMPOUNDWALL'){
                history.push(`/compoundWall-application-details/CW/${props.data.id}`, {
                  applicationType: props.data.applicationType,
                  enableNotes: props.enableNotes,
                });
              }else {
                history.push(`/application-details/${props.data.id}`, {
                  applicationType: props.data.applicationType,
                  enableNotes: props.enableNotes,
                });
              }
            }}
          >
            <div style={{ display: "flex" }}>
              {displayingTag()}
              <div className="addition-tag">
                {props.data.isShowCauseRaised === true ?

                  <div className="btns-success">
                    Show Cause
                  </div> : ""}
              </div>
            </div>
            <p>{props.data.applicationIdentifier}</p>
            <span>{props.data.applicant.applicantName}</span>
          </td>

          <td nowrap="true">
            {props.data.approvalFor === 'OCCUPANCY'
              ? formatDateToString(
                props.data.appliedOn
                  ? props.data.appliedOn
                  : props.data.createdAt
              )
              : props.data.applicationType === 'SELF CERTIFICATION'
                ? formatDateToString(
                  props.data.paymentCompletedAt
                    ? props.data.paymentCompletedAt
                    : props.data.createdAt
                )
                : props.data.applicationType === 'REGISTRATION'
                  ? formatDateToString(
                    props.data.appliedOn
                      ? props.data.appliedOn
                      : props.data.createdAt
                  )
                  : formatDateToString(props.data.createdAt)}
          </td>

          <td nowrap="true">
            {props.data.approvalFor === 'OCCUPANCY'
              ? formatDateToString(
                props.data.assignedDate
                && props.data.assignedDate

              )
              : props.data.applicationType === 'SELF CERTIFICATION'
                ? formatDateToString(
                  props.data.assignedDate
                  && props.data.assignedDate

                )
                : props.data.applicationType === 'REGISTRATION'
                  ? formatDateToString(
                    props.data.assignedDate
                    && props.data.assignedDate

                  )
                  : formatDateToString(props.data.assignedDate)}
          </td>


          <td nowrap="true">
            <p>
              {props.data.approvalFor === 'OCCUPANCY'
                ? 'OCCUPANCY'
                : props.data.additional === 'YES'
                  ? 'ADDITIONAL'
                  : props.data.revision === "YES" ? 'REVISION'
                    : props.data.applicationType === 'SELF CERTIFICATION'
                      ? 'INSTANT APPROVAL'
                      : props.data.applicationType === 'REGISTRATION'
                        ? 'INSTANT REGISTRATION'

                        : props.data.applicationType}
            </p>
          </td>

          <td nowrap="true">
            <p>{props.data.property.actualPlotArea + ' sq.m'}</p>
            <span>{props.data.property.floor?.noOfFloors}</span>
          </td>

          {['DEEMED'].includes(props.filter) ? (
            <>
              <td nowrap="true">
                <p>
                  {props?.data && props.data.deemedDate
                    ? formatDateToString(props.data.deemedDate)
                    : ''}
                  <br />
                </p>
              </td>
              <td nowrap="true">
                <p>
                  {props?.data && props.data.deemedDueDate
                    ? formatDateToString(props.data.deemedDueDate)
                    : ''}
                  <br />
                </p>
              </td>
            </>
          ) : ''}
          {/* <td nowrap="true">
              <p>
                {props?.data && props.data.dueDate
                  ? formatDateToString(props.data.dueDate)
                  : ''}
                <br />
              </p>
            </td> */}
          {/* <td>
          --
        </td> */}
          <td nowrap="true">
            {props.data.status !== null && (
              <buttion
                onClick={handleShow}
                style={{ whiteSpace: 'normal' }}
                //  className="newbutton"
                className={`btn ${getClassFromDueDate(props.data.dangerDate)}`}
              >
                {props.data.status && getStatus(props.data.status)}
              </buttion>
            )}
            <div className="date-container">
              {show ? (
                <StatusReviewModal
                  id={props.data.id}
                  handleClose={handleClose}
                  status={props.data.status && getStatus(props.data.status)}
                />
              ) : null}
            </div>
          </td>
          {props.removeActionInProcessd == false && !['COMPLETED', 'ALL',"LINE_DEPARTMENT_APPLICATION"].includes(props.filter) &&
            (['DEEMED'].includes(props.filter) ? (
              (permissionLevel === PERMISSIONS_LEVELS.COMMISSIONER ||
                permissionLevel === PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER) && (
                <td nowrap="true">
                  {showVerify() && (
                    <div className="application-list-item">
                      {!showDropDown && (
                        <button
                          onClick={(event) => {
                            if (verifyButtonText === 'FEE_ADJUSTMENTS') {
                              handleShowAfterSubmit();
                              return;
                            }
                            handleVerifyClick(event);
                          }}
                          className={'verify-btn'}
                          style={{
                            fontSize: '12px',
                            width: 'max-content',
                            textTransform: 'capitalize',
                          }}
                        >
                          {verifyButtonText === 'NOT_RECOMMENDED'
                            ? 'NOT  RECOMMENDED'
                            : verifyButtonText === 'FEE_ADJUSTMENTS'
                              ? 'FEE  ADJUSTMENTS'
                              : verifyButtonText}
                        </button>
                      )}
                      {showDropDown && (
                        <Dropdown
                          style={{ cursor: 'pointer', width: 'fit-content' }}
                        >
                          <Dropdown.Toggle>Verify</Dropdown.Toggle>
                          <Dropdown.Menu
                            style={{ width: 'fit-content' }}
                            flip={false}
                          >
                            <Dropdown.Item
                              style={{ cursor: 'pointer', paddingRight: '0px' }}
                              className={
                                isRecommended(
                                  'SITE VERIFICATION',
                                  props.data.stageStates,
                                  props.data.currentOfficerLevel
                                )
                                  ? 'stage-selected'
                                  : ''
                              }
                              onClick={(event) => {
                                event.stopPropagation();
                                if (
                                  !isRecommended(
                                    'SITE VERIFICATION',
                                    props.data.stageStates,
                                    props.data.currentOfficerLevel
                                  )
                                ) {
                                  if (props.data.approvalFor === 'OCCUPANCY') {
                                    history.push(
                                      `/iairVerification/${props.data.id}`,
                                      {
                                        applicationType:
                                          props.data.applicationType,
                                      }
                                    );
                                  } else {

                                    history.push(`/iairVerification/${props.data.id}`, {
                                      applicationType: props.data.applicationType,
                                    });

                                  }
                                }
                              }}
                            >
                              Site Verification
                              {isRecommended(
                                'SITE VERIFICATION',
                                props.data.stageStates,
                                props.data.currentOfficerLevel
                              ) && (
                                  <img
                                    alt=""
                                    style={{
                                      // right: 0,
                                      // position: 'absolute',
                                      paddingLeft: '5px',
                                    }}
                                    src={require('../../assets/images/success.svg')}
                                  />
                                )}
                            </Dropdown.Item>
                            {props.data.approvalFor !== 'OCCUPANCY' && (
                              <>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  style={{
                                    cursor: 'pointer',
                                    paddingRight: '0px',
                                  }}
                                  className={
                                    isRecommended(
                                      'TITLE VERIFICATION',
                                      props.data.stageStates,
                                      props.data.currentOfficerLevel
                                    )
                                      ? 'stage-selected'
                                      : ''
                                  }
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    if (
                                      !isRecommended(
                                        'TITLE VERIFICATION',
                                        props.data.stageStates,
                                        props.data.currentOfficerLevel
                                      )
                                    ) {

                                      history.push(`/iairVerification/${props.data.id}`, {
                                        applicationType: props.data.applicationType,
                                      });

                                    }
                                  }}
                                >
                                  {' '}
                                  Title Verification{' '}
                                  {isRecommended(
                                    'TITLE VERIFICATION',
                                    props.data.stageStates,
                                    props.data.currentOfficerLevel
                                  ) && (
                                      <img
                                        alt=""
                                        style={{
                                          // right: 0,
                                          // position: 'absolute',
                                          paddingLeft: '5px',
                                        }}
                                        src={require('../../assets/images/success.svg')}
                                      />
                                    )}
                                </Dropdown.Item>
                              </>
                            )}
                            <Dropdown.Divider />
                            <Dropdown.Item
                              style={{ cursor: 'pointer', paddingRight: '0px' }}
                              className={
                                isRecommended(
                                  'TECHNICAL VERIFICATION',
                                  props.data.stageStates,
                                  props.data.currentOfficerLevel
                                )
                                  ? 'stage-selected'
                                  : ''
                              }
                              onClick={(event) => {
                                event.stopPropagation();
                                if (
                                  !isRecommended(
                                    'TECHNICAL VERIFICATION',
                                    props.data.stageStates,
                                    props.data.currentOfficerLevel
                                  )
                                ) {
                                  if (props.data.approvalFor === 'OCCUPANCY') {
                                    history.push(
                                      `/iairVerification/${props.data.id}`,
                                      {
                                        applicationType:
                                          props.data.applicationType,
                                      }
                                    );
                                  } else {

                                    history.push(`/iairVerification/${props.data.id}`, {
                                      applicationType: props.data.applicationType,
                                    });

                                  }
                                }
                              }}
                            >
                              Technical Verification
                              {isRecommended(
                                'TECHNICAL VERIFICATION',
                                props.data.stageStates,
                                props.data.currentOfficerLevel
                              ) && (
                                  <img
                                    alt=""
                                    style={{
                                      // right: 0,
                                      // position: 'absolute',
                                      paddingLeft: '5px',
                                    }}
                                    src={require('../../assets/images/success.svg')}
                                  />
                                )}
                            </Dropdown.Item>

                            {props.data.currentOfficerLevel === 'L1' &&
                              (props.data.applicationType ===
                                'SELF CERTIFICATION' ||
                                props.data.approvalFor === 'OCCUPANCY') && (
                                <>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    style={{
                                      cursor: 'pointer',
                                      pointerEvents: !isAllStagesRecommended()
                                        ? 'none'
                                        : '',
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      history.push(
                                        `/fee-verification/${props.data.id}`
                                      );
                                    }}
                                  >
                                    Fee Adjustments
                                  </Dropdown.Item>
                                </>
                              )}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  )}
                </td>
              )
            ) : (
              <td nowrap="true">
                {showVerify() && (
                  <div className="application-list-item d-flex flex-column align-items-center">
                    {!showDropDown && (
                      <button
                        type="button"
                        onClick={(event) => {
                          if (
                            verifyButtonText === 'SUBMITTED' ||
                            verifyButtonText === 'RECOMMENDED' ||
                            verifyButtonText === 'NOT_RECOMMENDED'
                          ) {
                            setSubmittedPopState(true);
                            return;
                          }
                          if (verifyButtonText === 'FEE_ADJUSTMENTS') {
                            handleShowAfterSubmit();
                            return;
                          }
                          handleVerifyClick(event);
                        }}
                        className={'verify-btn w-100 txtcenter'}
                        style={{
                          fontSize: '12px',
                          width: 'max-content',
                          textTransform: 'capitalize',
                          marginBottom: '10px',
                        }}
                      >
                        {verifyButtonText === 'NOT_RECOMMENDED'
                          ? 'NOT  RECOMMENDED'
                          : verifyButtonText === 'FEE_ADJUSTMENTS'
                            ? 'FEE  ADJUSTMENTS'
                            : verifyButtonText}
                      </button>
                    )}


                    {showDropDown &&

                      (
                        <>

                          <button
                            className={'verify-btn w-100 txtcenter'}
                            style={{
                              fontSize: '12px',
                              width: 'max-content',
                              textTransform: 'capitalize',
                              marginBottom: '10px',
                            }}
                            onClick={handleVerifyClick}
                          >
                            verify
                          </button>

                        </>


                      )}
                  </div>
                )}
              </td>
            ))}
        </tr>
      )}
    </>
  );
}

ApplicationItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    applicationIdentifier: PropTypes.string,
    additional: PropTypes.string,
    additionalType: PropTypes.string,
    dueDate: PropTypes.string,
    dangerDate: PropTypes.string,
    deemedDate: PropTypes.string,
    deemedDueDate: PropTypes.string,
    createdAt: PropTypes.string,
    appliedOn: PropTypes.string,
    paymentCompletedAt: PropTypes.string,
    status: PropTypes.string,
    subState: PropTypes.string,
    stageStates: PropTypes.any,
    applicationType: PropTypes.string,
    applicant: PropTypes.shape({
      applicantName: PropTypes.string,
    }),
    currentOfficerLevel: PropTypes.string,
    property: PropTypes.shape({
      plotAddress: PropTypes.string,
      location: PropTypes.object,
      actualPlotArea: PropTypes.number,
      floor: PropTypes.object,
    }),
  }),
  filter: PropTypes.string,
  pageType: PropTypes.string,
  index: PropTypes.number,
  enableNotes: PropTypes.bool,
};

// Specifies the default values for props:
ApplicationItem.defaultProps = {
  pageType: 'post-verification',
  enableNotes: false,
};

export default ApplicationItem;
