export const answersData = [
  [
    {
      __typename: 'Field',
      id: '1240',
      title: 'Is the site in consideration covered in any of the following?',
      fieldType: 'DROP_DOWN',
      defaultValue: 'true',
      order: '1',
      dbField:
        'whether_approved_layout_plan___lrs_approved_plan__approved_building_plan_constructed_prior_to_1985_gramkantam_abadi_building_regularized_under_bps_document_is_tallying_with_the_submitted_proposal',
      options: [
        {
          __typename: 'FieldOption',
          value: 'Approved Layout',
        },
        {
          __typename: 'FieldOption',
          value: 'LRS Approved',
        },
        {
          __typename: 'FieldOption',
          value: 'Unapproved Layout',
        },
        {
          __typename: 'FieldOption',
          value: 'Piece of land',
        },
        {
          __typename: 'FieldOption',
          value: 'Approved Building',
        },
        {
          __typename: 'FieldOption',
          value: 'Building regularized under BPS',
        },
        {
          __typename: 'FieldOption',
          value: 'Construction Prior to 1985',
        },
        {
          __typename: 'FieldOption',
          value: 'Congested Area',
        },
        {
          __typename: 'FieldOption',
          value: 'Gramakantam',
        },
        {
          __typename: 'FieldOption',
          value: 'Abadi',
        },
      ],
      children: [],
    },
    {
      __typename: 'Field',
      id: '1363',
      title:
        'Whether the proposal is contrary to land use as per notified Master plan and whether the existing Road is affected in Road Widening for proposed Master plan road/ RDP/SRDP?',
      fieldType: 'CHECKBOX',
      defaultValue: '',
      order: '2',
      dbField: 'Road_widening_masterplan',
      options: [],
      children: [
        {
          __typename: 'Field',
          title: 'Road affected area',
          dbField: 'road_affected_area',
          fieldType: 'TEXT_SHORT',
          defaultValue: '',
          children: [],
        },
        {
          __typename: 'Field',
          title: 'Proposed road width ',
          dbField: 'proposed_road_width',
          fieldType: 'TEXT_SHORT',
          defaultValue: '',
          children: [],
        },
        {
          __typename: 'Field',
          title: 'Existing Road Width ',
          dbField: 'existing_road',
          fieldType: 'TEXT_SHORT',
          defaultValue: '',
          children: [],
        },
      ],
    },
    {
      __typename: 'Field',
      id: '1249',
      title:
        'Whether the minimum approach road is maintained for all abutting roads of the plot as per submitted plan?',
      fieldType: 'CHECKBOX',
      defaultValue: 'true',
      order: '3',
      dbField:
        'whether_the_minimum_approach_road_is_maintained_for_all_abutting_roads_of_the_plot_as_per_submitted_plan',
      options: [],
      children: [
        {
          __typename: 'Field',
          title: 'Remarks',
          dbField: 'remarks_minimum_road',
          fieldType: 'TEXT_SHORT',
          defaultValue: 'true',
          children: [],
        },
      ],
    },
  ],
  [
    {
      __typename: 'Field',
      id: '1255',
      title:
        'Whether the applicant has shown the road affected portion in the submitted plan?',
      fieldType: 'CHECKBOX',
      defaultValue: 'true',
      order: '4',
      dbField:
        'whether_the_road_effected_portion_has_been_shown_in_the_submitted_plan',
      options: [],
      children: [
        {
          __typename: 'Field',
          title:
            'Whether the total road affected area talling as per submitted plans?',
          dbField: 'road_affected_area_tallying',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          children: [
            {
              __typename: 'Field',
              title: 'Remarks ',
              fieldType: 'TEXT_SHORT',
              defaultValue: '',
              dbField: 'reamarks_road_affected_ghmc',
              children: [],
            },
          ],
        },
        {
          __typename: 'Field',
          title:
            'Whether Net Plot area(Gross plot area minus road affected area) is tallying?',
          dbField:
            'whether_net_plot_area_gross_plot_area_road_affected_area__is_tallying',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          children: [
            {
              __typename: 'Field',
              title: 'Remarks ',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              dbField: 'net_plot_area_remarks',
              children: [],
            },
          ],
        },
      ],
    },
    {
      __typename: 'Field',
      id: '1373',
      title: 'Final Review and Remarks',
      fieldType: 'TEXT_LONG',
      defaultValue: '',
      order: '5',
      dbField: 'final_remarks_ghmc_selfcertification',
      options: [],
      children: [],
    },
  ],
];

export const answersListData = {
  applicationForms: [
    {
      id: '3269',
      title: 'Technical form DTCP',
      status: 'IN_PROCESS',
      answer: null,
      fields: [
        {
          id: '523',
          title:
            'Whether the extent of the plot tallying with the document & plans Submitted?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '1',
          dbField:
            'whether_the_extent_of_the_plot_tallying_with_the_document___plans_submitted',
          options: [],
          children: [
            {
              title: 'Remark for not tallying',
              dbField: 'remark_for_not_tallying',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '525',
          title:
            'Whether the name of the applicants is tallying with the document uploaded?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '2',
          dbField:
            'whether_the_name_of_the_applicants_is_tallying_with_the_document_uploaded',
          options: [],
          children: [
            {
              title: 'Remark for not tallying',
              dbField: 'remark_for_not_tallying',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '527',
          title:
            'Whether the District/mandal/village is tallying with the Document?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '3',
          dbField:
            'whether_the_district_mandal_village_is_tallying_with_the_document',
          options: [],
          children: [
            {
              title: 'Remark for not tallying',
              dbField: 'remark_for_not_tallying',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '529',
          title:
            'Whether the Survey No/plot No/H.NO/Door.No is tallying with sale deed?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '4',
          dbField:
            'whether_the_survey_no_plot_no_h_no_door_no_is_tallying_with_sale_deed',
          options: [],
          children: [
            {
              title: 'Remark for not tallying',
              dbField: 'remark_for_not_tallying',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '531',
          title:
            'Whether Approved layout Plan / LRS Approved Plan/ Approved Building Plan/Constructed prior to 1985/Gramakantam/Abadi/Building Regularized under BPS Document Submitted are tallying?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '6',
          dbField:
            'whether_approved_layout_plan___lrs_approved_plan__approved_building_plan_constructed_prior_to_1985_gramakantam_abadi_building_regularized_under_bps_document_submitted_are_tallying',
          options: [],
          children: [
            {
              title: 'Remark for not tallying',
              dbField: 'remark_for_not_tallying',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '533',
          title:
            'Whether the proposal is contrary or Variation to land use as per notified Master plan for the proposed plot?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '7',
          dbField:
            'whether_the_land_use_as_per_notified_master_plan_for_the_proposed_plot',
          options: [],
          children: [
            {
              title: 'Remark for not tallying',
              dbField: 'remark_for_not_tallying',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '535',
          title: 'Whether the site is covered by any restricted zone?',
          fieldType: 'CHECKBOX',
          defaultValue: 'false',
          order: '8',
          dbField: 'whether_the_site_is_covered_by_any_restricted_zone',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '537',
          title:
            'Whether the Market Value (Rs per Sq.yds)as on date of application is Tallying?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '9',
          dbField:
            'whether_the_market_value__rs_per_sq_yds_as_on_date_of_application_is_tallying',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '539',
          title:
            'Whether Land Value (Rs per Sq.yds) tallying as per sale deed?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '10',
          dbField:
            'whether_land_value__rs_per_sq_yds__tallying_as_per_sale_deed',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '541',
          title:
            'Whether the Market Value mentioned by the applicant is tallying with market value Certificate issued by SRO?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '11',
          dbField:
            'whether_the_market_value_certificate_issued_date_verification_is_tallying',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '543',
          title:
            'Whether the plot area in (Sqmts/Sqyards) details mentioned in the application are tallying with the submitted plan?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '12',
          dbField: 'plot_area__sqm__verification',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '545',
          title:
            'Whether the existing Road is affected in proposed master plan road/ RDP/SRDP?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '13',
          dbField: 'whether_the_site_is_affected_by_masterplan_road',
          options: [],
          children: [
            {
              title:
                'Remarks (Mention extent area affected by master plan road).',
              dbField:
                'remarks__mention_extent_area_affected_by_master_plan_road',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '549',
          title:
            'Whether the net plot /site/Bulit up area (sqmts/Sqyards) mentioned in the application is tallying with the plans submitted?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '15',
          dbField: 'net_plot__site_area_verification',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '551',
          title:
            'Whether the plot is falling in restricted zone of Monument/airport/Heritage?',
          fieldType: 'CHECKBOX',
          defaultValue: 'false',
          order: '16',
          dbField: 'site_falls_under_verification_certify',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '555',
          title:
            'Whether the plot or layout is falling in slum area& Congested area?',
          fieldType: 'CHECKBOX',
          defaultValue: 'false',
          order: '18',
          dbField: 'whether_the_plot_or_layout_falling_slum_area',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '557',
          title:
            'Verify the land use in which proposed site is falling as per the approved master plan',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '19',
          dbField: 'upload_masterplan_extract_document',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '559',
          title:
            'Whether the schedule of boundaries tallying with the sale deed East, West, North, South?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '20',
          dbField:
            'is_the_schedule_of_boundaries_are_tallying_with_the_sale_deed_east__west__north__south',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '561',
          title:
            'Whether the required minimum approach road is maintained for all abutting roads of the plot?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '22',
          dbField:
            'whether_the_required_minimum_approach_road_is_maintained_for_all_abutting_roads_of_the_plot',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '563',
          title: 'Whether the road effected area has been handed over?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '23',
          dbField: 'whether_the_road_affected_portions_has_been_handed_over',
          options: [],
          children: [
            {
              title:
                'Remarks (mention date of handed over or undertaking Submittals)',
              dbField:
                'remarks__mention_date_of_handed_over_or_undertaking_submittals',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '565',
          title:
            'Whether the applicant is eligible to claim widening concessions /relaxations as per rule 16?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '24',
          dbField:
            'whether_the_applicant_is_eligible_to_claim_widening_concessions__relaxations_as_per_rule_16',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '567',
          title:
            'Whether the netplot area m^2/Road width/ No of floors/builtup area are tallying with the submitted plans?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '25',
          dbField:
            'whether_the_netplot_area_m_2_road_width__no_of_floors_are_tallying',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '569',
          title:
            'Whether the height of the building is permissible for applying under self-certification?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '26',
          dbField:
            'whether_the_height_is_permissible_for_applying_self_certification',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '571',
          title:
            'Whether the setbacks front, rear , side1 & side2 are in compliance with submitted plan?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '27',
          dbField:
            'whether_the_setbacks_front__rear___side1___side2_are_tallying',
          options: [],
          children: [
            {
              title: 'Remarks for Road width and Setbacks for all sides.',
              dbField: 'remarks_for_road_width_and_setbacks_for_all_sides',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '573',
          title:
            'Whether the proposed building is for individual residential building?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '28',
          dbField:
            'whether_the_proposed_building_is_for_individual_residential_building',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '575',
          title:
            'The proposed site is covered by notified master plan and zoning regulations for HUDA/HADA/CDA/ORRGC/MDP2031',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '29',
          dbField:
            'the_proposed_site_is_covered_by_notified_master_plan_and_zoning_regulations_for_huda_hada_cda_orrgc_mdp2031',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '577',
          title: 'Final Review & Remarks',
          fieldType: 'TEXT_SHORT',
          defaultValue: 'true',
          order: null,
          dbField: 'final_review___remarks',
          options: [],
          children: [],
          __typename: 'Field',
        },
        {
          id: '553',
          title: 'Mention the major land use zone of the proposed site?',
          fieldType: 'CHECKBOX',
          defaultValue: 'false',
          order: '17',
          dbField: 'certify_major_use_zone',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
      ],
      __typename: 'FormInstance',
    },
    {
      id: '3268',
      title: 'Site form DTCP',
      status: 'IN_PROCESS',
      answer: null,
      fields: [
        {
          id: '1386',
          title: 'Whether there is any Road encroachment?',
          fieldType: 'CHECKBOX',
          defaultValue: 'false',
          order: '6',
          dbField: 'road_ enchroachment ',
          options: [],
          children: [
            {
              title:
                'Mention particular in(encroachment in feet/m)   length x breadth = area in sq feet/Meters(M)',
              dbField: 'road_ enchroachment ',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false ',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '466',
          title:
            'Whether the approach road is connected to an existing public road?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '2',
          dbField:
            'whether_approach_road_connected_with_an_existing_public_road',
          options: [],
          children: [
            {
              title:
                'Approach Road Connection remarks ( road, drain ,electricity, water supply private or public)',
              dbField:
                'approach_road_connection_remarks___road__drain__electricity__water_supply_private_or_public',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '468',
          title:
            'Whether the existing road width is in  Confirmity  with the rules?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '3',
          dbField: 'whether_the_road_width_is_correct_as_per_rules',
          options: [],
          children: [
            {
              title:
                'Nature of Road (katcha or pukka road) OR Black Top/CC/ Katcha',
              dbField:
                'nature_of_road__katcha_or_pukka_road__or_black_top_cc__katcha',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '476',
          title: 'Whether the site is vacant?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '4',
          dbField: 'whether_the_site_is_vacant',
          options: [],
          children: [
            {
              title: 'Site vacancy Remarks',
              dbField: 'site_vacancy_remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '478',
          title: 'Whether the construction work is Commenced?',
          fieldType: 'CHECKBOX',
          defaultValue: 'false',
          order: '5',
          dbField: 'whether_the_construction_work_is_commenced',
          options: [],
          children: [
            {
              title: 'Side 2 Setback deviation (in feet)',
              dbField: 'side_2_setback_deviation__in_feet',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'true',
              children: [],
              __typename: 'Field',
            },
            {
              title: 'Side 2 Setbacks tallied on ground with plan',
              dbField: 'side_2_setbacks_tallied_on_ground_with_plan',
              fieldType: 'CHECKBOX',
              defaultValue: 'true',
              children: [],
              __typename: 'Field',
            },
            {
              title: 'Side 1 Setback deviation (in feet)',
              dbField: 'side_1_setback_deviation__in_feet',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'true',
              children: [],
              __typename: 'Field',
            },
            {
              title: 'Side 1 Setbacks tallied on ground with plan',
              dbField: 'side_1_setbacks_tallied_on_ground_with_plan',
              fieldType: 'CHECKBOX',
              defaultValue: 'true',
              children: [],
              __typename: 'Field',
            },
            {
              title: 'Rear Setback deviation (in feet)',
              dbField: 'rear_setback_deviation__in_feet',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'true',
              children: [],
              __typename: 'Field',
            },
            {
              title: 'Rear Setbacks tallied on ground with plan',
              dbField: 'rear_setbacks_tallied_on_ground_with_plan',
              fieldType: 'CHECKBOX',
              defaultValue: 'true',
              children: [],
              __typename: 'Field',
            },
            {
              title: 'Front Setback deviation (in feet)',
              dbField: 'front_setback_deviation__in_feet',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'true',
              children: [],
              __typename: 'Field',
            },
            {
              title: 'Front Setbacks tallied on ground with plan',
              dbField: 'front_setbacks_tallied_on_ground_with_plan',
              fieldType: 'CHECKBOX',
              defaultValue: 'true',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '487',
          title: 'Does plot boundaries tally with the site?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '8',
          dbField: 'does_plot_boundaries_tally_with_the_site',
          options: [],
          children: [
            {
              title: 'Plot Boundaries Remarks',
              dbField: 'plot_boundaries_remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '489',
          title:
            'Whether the plot is falling in restricted zone of Monument/airport/Heritage?',
          fieldType: 'CHECKBOX',
          defaultValue: 'false',
          order: '9',
          dbField:
            'whether_the_plot_is_falling_in_restricted_zone_of_monument_airport_heritage',
          options: [],
          children: [
            {
              title: 'Monument/airport/Heritage Details',
              dbField: 'monument_airport_heritage_details',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '491',
          title:
            'Whether the Plot is located within 200mts distance from the water bodies?',
          fieldType: 'CHECKBOX',
          defaultValue: 'false',
          order: '10',
          dbField:
            'whether_the_plot_is_located_within_200mts_distance_from_the_water_bodies',
          options: [],
          children: [
            {
              title: 'Water Body Details',
              dbField: 'water_bodies_remark',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '493',
          title: 'Whether any electrical line is passing through the site?',
          fieldType: 'CHECKBOX',
          defaultValue: 'false',
          order: '11',
          dbField: 'whether_high_tension_line_passing_over_the_site',
          options: [],
          children: [
            {
              title: 'High tension line details',
              dbField: 'high_tension_line_details',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '495',
          title: 'Whether any 132kv/33kv/11kv electric lines near the site?',
          fieldType: 'CHECKBOX',
          defaultValue: 'false',
          order: '12',
          dbField: 'any_132kv_33kv_11kv_electric_line_near_the_site',
          options: [],
          children: [
            {
              title:
                'Horizontal and vertical distance from 132kv/33kv/11kv electric line in meters',
              dbField:
                'horizontal_and_vertical_distance_from_132kv_33kv_11kv_electric_line_in_meters',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '497',
          title:
            'Whether the proposed building is for individual residential building?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '13',
          dbField:
            'whether_the_site_is_falling_in_approved_layout_or_lrs_approval',
          options: [],
          children: [
            {
              title: 'LRS/LP Number details and date',
              dbField: 'lrs_lp_number_details_and_date',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '499',
          title: 'Final Review & Remarks',
          fieldType: 'TEXT_SHORT',
          defaultValue: 'true',
          order: null,
          dbField: 'final_review___remarks',
          options: [],
          children: [],
          __typename: 'Field',
        },
        {
          id: '464',
          title: 'Whether there is any existing approach road to the plot?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '1',
          dbField: 'whether_the_approach_road_existing_to_the_plot',
          options: [],
          children: [
            {
              title: 'Approach Road remarks',
              dbField: 'approach_road_remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
      ],
      __typename: 'FormInstance',
    },
    {
      id: '3267',
      title: 'Title form DTCP',
      status: 'IN_PROCESS',
      answer: null,
      fields: [
        {
          id: '500',
          title:
            'Whether the name of the applicants is tallying with the document uploaded?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '1',
          dbField:
            'whether_the_name_of_the_applicants_is_tallying_with_the_document_uploaded',
          options: [],
          children: [
            {
              title: 'Remark for not tallying',
              dbField: 'remark_for_not_tallying',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '502',
          title:
            'Are there any legal disputes /complaints regarding the ownership?',
          fieldType: 'CHECKBOX',
          defaultValue: 'false',
          order: '2',
          dbField:
            'whether_any_legal_disputes__complaints_regarding_the_ownership',
          options: [],
          children: [
            {
              title:
                'Remarks about legal disputes /complaints regarding the ownership',
              dbField:
                'remarks_about_legal_disputes__complaints_regarding_the_ownership',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '504',
          title:
            'Whether the survey number and the location are tallying with the document and plan?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '3',
          dbField:
            'whether_the_survey_no_and_the_location_tallying_with_the_document_plans',
          options: [],
          children: [
            {
              title: 'Remark for not tallying',
              dbField: 'remark_for_not_tallying',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '506',
          title:
            'Whether the District/mandal/village is tallying with the Document?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '4',
          dbField:
            'whether_the_district_mandal_village_is_tallying_with_the_document',
          options: [],
          children: [
            {
              title: 'Remark for not tallying',
              dbField: 'remark_for_not_tallying',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '510',
          title:
            'Whether the ownership stands in the name of the applicant as per the latest encumbrance certificate (E.C)?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '6',
          dbField:
            'whether_the_ownership_stand_in_the_name_of_the_applicant_as_per_the_latest_encumbrance_certificate',
          options: [],
          children: [
            {
              title: 'Remark for not tallying',
              dbField: 'remark_for_not_tallying',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '512',
          title:
            'Whether the proposed is falling in any of the Prohibited land list?',
          fieldType: 'CHECKBOX',
          defaultValue: 'false',
          order: '7',
          dbField:
            'whether_the_proposed_site_falls_under_in_any_of_the_prohibited_land_list',
          options: [],
          children: [
            {
              title: 'Remarks about Prohibited land',
              dbField: 'remarks_about_prohibited_land',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '514',
          title:
            'Whether the proposed site is falling under consideration covered in any restricted zone or Vicinity areas as per rule G.O. 168 ?',
          fieldType: 'CHECKBOX',
          defaultValue: 'false',
          order: '8',
          dbField:
            'is_the_site_under_consideration_covered_in_any_of_the_following',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '516',
          title:
            'Whether the extent of plot tallying with documents & plans Submitted ?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '9',
          dbField:
            'whether_the_extent_of_plot_tallying_with_documents___plans_submitted',
          options: [],
          children: [
            {
              title: 'Remarks not tallying',
              dbField: 'remarks_not_tallying',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '518',
          title: 'Whether the plot is registered prior to 28/10/2015?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '10',
          dbField: 'whether_the_plot_registered_prior_to_28_10_2015_under_lrs',
          options: [],
          children: [
            {
              title: 'Remarks',
              dbField: 'remarks',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '520',
          title: 'Whether the plot number is tallying with Sale deed?',
          fieldType: 'CHECKBOX',
          defaultValue: 'true',
          order: '11',
          dbField:
            'whether_the_plot_number_is_tallying_with_sale_deed_if_yes_remarks_box_has_to_be_enabled',
          options: [],
          children: [
            {
              title: 'Remark for not tallying',
              dbField: 'remark_for_not_tallying',
              fieldType: 'TEXT_SHORT',
              defaultValue: 'false',
              children: [],
              __typename: 'Field',
            },
          ],
          __typename: 'Field',
        },
        {
          id: '522',
          title: 'Final Review & Remarks',
          fieldType: 'TEXT_SHORT',
          defaultValue: 'true',
          order: null,
          dbField: 'final_review___remarks',
          options: [],
          children: [],
          __typename: 'Field',
        },
      ],
      __typename: 'FormInstance',
    },
  ],
};

export const rejectionOptions = {
  TECHNICAL_VERIFICATION: [
    {
      id: '16',
      label: 'Other',
      value: '',
    },
    {
      id: '1',
      label:
        'The proposals are in contrary to the Land Use & Master Plan Zoning Regulations.',
      value:
        'The proposals are in contrary to the Land Use & Master Plan Zoning Regulations.',
    },
    {
      id: '2',
      label:
        'The proposals are not in accordance to the Telangana Building Rules issued vide G.O.Ms.No.168, dt.07.04.2012 and its time to time amendments.',
      value:
        'The proposals are not in accordance to the Telangana Building Rules issued vide G.O.Ms.No.168, dt.07.04.2012 and its time to time amendments.',
    },
    {
      id: '3',
      label:
        'The proposed plot is forming part of unauthorized Layout and not applied under LRS. The plot is registered after cut-off date i.e.,26-08-2020 and has no registered link document prior to the cut-off date.',
      value:
        'The proposed plot is forming part of unauthorized Layout and not applied under LRS. The plot is registered after cut-off date i.e.,26-08-2020 and has no registered link document prior to the cut-off date.',
    },
    {
      id: '4',
      label:
        'The proposed Master Plan road is passing through the site/plot under reference.',
      value:
        'The proposed Master Plan road is passing through the site/plot under reference.',
    },
    {
      id: '5',
      label:
        'The plot is sub-divided into smaller plots for the purpose of obtaining permission under Instant Registration. Hence, liable for rejection as per G.O.Ms.No.62, dt.21.03.2020.',
      value:
        'The plot is sub-divided into smaller plots for the purpose of obtaining permission under Instant Registration. Hence, liable for rejection as per G.O.Ms.No.62, dt.21.03.2020.',
    },
    {
      id: '6',
      label:
        'The site under reference is affected by the water body & buffer zone as per Master Plan.',
      value:
        'The site under reference is affected by the water body & buffer zone as per Master Plan.',
    },
    {
      id: '7',
      label:
        'The site under reference is falling within 500 Mtrs in the downstream of Osmansagar / Himayathsagar Lakes. Hence, liable for rejection as per Memo.No. 261/I1/2006, dt.16.07.2007.',
      value:
        'The site under reference is falling within 500 Mtrs in the downstream of Osmansagar / Himayathsagar Lakes. Hence, liable for rejection as per Memo.No. 261/I1/2006, dt.16.07.2007.',
    },
    {
      id: '8',
      label:
        'The applicant has not submitted the registered mortgage deed executed in favour of Municipal Commissioner.',
      value:
        'The applicant has not submitted the registered mortgage deed executed in favour of Municipal Commissioner.',
    },
    {
      id: '9',
      label:
        'The site is falling in Air Funnel zone. Prior NOC is required from Airport Authority.',
      value:
        'The site is falling in Air Funnel zone. Prior NOC is required from Airport Authority.',
    },
    {
      id: '10',
      label: 'The proposed village is covered in G.O.Ms.No.111, dt.08.03.1996.',
      value: 'The proposed village is covered in G.O.Ms.No.111, dt.08.03.1996.',
    },
    {
      id: '11',
      label: 'The mandatory documents are not uploaded by the applicant.',
      value: 'The mandatory documents are not uploaded by the applicant.',
    },
    {
      id: '12',
      label:
        'The Village / Mandal have been selected wrongly by the applicant.',
      value:
        'The Village / Mandal have been selected wrongly by the applicant.',
    },
    {
      id: '13',
      label:
        'The site under reference is falling in the Open Space Zone as per the Master Plan.',
      value:
        'The site under reference is falling in the Open Space Zone as per the Master Plan.',
    },
    {
      id: '14',
      label:
        'The applicant has not mentioned the road affected area in the application. ',
      value:
        'The applicant has not mentioned the road affected area in the application. ',
    },
    {
      id: '15',
      label:
        'Observed that the site under reference is covered under legal dispute.',
      value:
        'Observed that the site under reference is covered under legal dispute.',
    },

  ],
  SITE_VERIFICATION: [

    {
      id: '15',
      label: 'Other',
      value: '',
    },
    {

      id: '1',
      label:
        'The minimum width of the approach road is less than 9 Mtrs and the applicant has commenced the construction without leaving road affected portion.',
      value:
        'The minimum width of the approach road is less than 9 Mtrs and the applicant has commenced the construction without leaving road affected portion.',
    },
    {
      id: '2',
      label:
        'The minimum width of the approach road is 9 Mtrs and the applicant has not mentioned the road affected area in the application. ',
      value:
        'The minimum width of the approach road is 9 Mtrs and the applicant has not mentioned the road affected area in the application. ',
    },
    {
      id: '3',
      label:
        'There is existing Building on the ground which is constructed without leaving mandatory setbacks as per Building Rules.',
      value:
        'There is existing Building on the ground which is constructed without leaving mandatory setbacks as per Building Rules.',
    },
    {
      id: '4',
      label:
        'The site u/r is affected by the Water Body / Buffer Zone / Nala as per ground position.',
      value:
        'The site u/r is affected by the Water Body / Buffer Zone / Nala as per ground position.',
    },
    {
      id: '5',
      label:
        'The site u/r is falling within 500 Mtrs in the downstream of Osmansagar / Himayathsagar Lakes. Hence, liable for rejection as per Memo.No. 261/I1/2006, dt.16.07.2007.',
      value:
        'The site u/r is falling within 500 Mtrs in the downstream of Osmansagar / Himayathsagar Lakes. Hence, liable for rejection as per Memo.No. 261/I1/2006, dt.16.07.2007.',
    },
    {
      id: '6',
      label:
        'The site is falling within vicinity of HT tower lines and affected under proposed 10 Mtrs road which is to be provided on either sides of the HT Tower base as per Building rules.',
      value:
        'The site is falling within vicinity of HT tower lines and affected under proposed 10 Mtrs road which is to be provided on either sides of the HT Tower base as per Building rules.',
    },
    {
      id: '7',
      label:
        'If LT/HT electrical lines are passing through the proposed site. ',
      value:
        'If LT/HT electrical lines are passing through the proposed site. -',
    },
    {
      id: '8',
      label: 'The site is affected within the vicinity of Oil/Gas pipelines.',
      value: 'The site is affected within the vicinity of Oil/Gas pipelines.',
    },
    {
      id: '9',
      label:
        'The site is falling within 100 ft (30 Mtrs) from the Railway property boundary. ',
      value:
        'The site is falling within 100 ft (30 Mtrs) from the Railway property boundary. ',
    },
    {
      id: '10',
      label:
        'The plot dimensions / plot area are not tallying with the submitted ownership documents/as specified in the TG-bPASS application form.',
      value:
        'The plot dimensions / plot area are not tallying with the submitted ownership documents/as specified in the TG-bPASS application form.',
    },
    {
      id: '11',
      label:
        'The site is falling within the vicinity of Raw water channel. Hence, the proposal is liable for rejection as per G.O.Ms.No. 111, dt.08.03.1996.',
      value:
        'The site is falling within the vicinity of Raw water channel. Hence, the proposal is liable for rejection as per G.O.Ms.No. 111, dt.08.03.1996.',
    },
    {
      id: '12',
      label: 'The site is forming part of Layout Open space.',
      value: 'The site is forming part of Layout Open space.',
    },
    {
      id: '13',
      label:
        'The site u/r is falling within the vicinity of Heritage Monument.',
      value:
        'The site u/r is falling within the vicinity of Heritage Monument.',
    },
    {
      id: '14',
      label: 'The site u/r is affected by ORR buffer zone. ',
      value: 'The site u/r is affected by ORR buffer zone. ',
    },

  ],
  TITLE_VERIFICATION: [
    {
      id: '7',
      label: 'Other',
      value: '',
    },
    {
      id: '1',
      label: 'The site u/r is falling in ULC / Surplus Land / Government Land.',
      value: 'The site u/r is falling in ULC / Surplus Land / Government Land.',
    },
    {
      id: '2',
      label:
        'The mandatory documents (Ownership document/LRS document/ Required NOC) are not uploaded by the applicant.',
      value:
        'The mandatory documents (Ownership document/LRS document/ Required NOC) are not uploaded by the applicant.',
    },
    {
      id: '3',
      label:
        'The Village / Mandal have been selected wrongly by the applicant. ',
      value:
        'The Village / Mandal have been selected wrongly by the applicant. ',
    },
    {
      id: '4',
      label: 'The applicant does not have the prima facie title.',
      value: 'The applicant does not have the prima facie title.',
    },
    {
      id: '5',
      label:
        'The plot area is not tallying with the submitted ownership documents/as specified in the TG-bPASS application form.',
      value:
        'The plot area is not tallying with the submitted ownership documents/as specified in the TG-bPASS application form.',
    },
    {
      id: '6',
      label:
        'The Survey number entered in the application is not matching with the submitted Ownership document.',
      value:
        'The Survey number entered in the application is not matching with the submitted Ownership document.',
    },

  ],
  SINGLEWINDOWVERIFICATION: [
    {
      id: '31',
      label: 'Other',
      value: '',
    },
    {
      id: '1',
      label:
        'The proposals are contrary to the Master Plan/ Indicative Land Use Plan (ILUP).',
      value:
        'The proposals are contrary to the Master Plan/ Indicative Land Use Plan (ILUP).',
    },
    {
      id: '2',
      label:
        'The site under reference is falling in the Open Space Zone as per the Master Plan/ Indicative Land Use Plan (ILUP).',
      value:
        'The site under reference is falling in the Open Space Zone as per the Master Plan/ Indicative Land Use Plan (ILUP).',
    },
    {
      id: '3',
      label:
        'The applicant has not mentioned the road affected area in the application.',
      value:
        'The applicant has not mentioned the road affected area in the application.',
    },
    {
      id: '4',
      label:
        'The applicant has not mentioned the road affected area in the application.',
      value:
        'The applicant has not mentioned the road affected area in the application.',
    },
    {
      id: '5',
      label:
        'The proposals are not in accordance to the Telangana Layout Rules issued vide G.O.Ms.No.105 MA&UD Dept, dt.05.07.2021 and its amendments.',
      value:
        'The proposals are not in accordance to the Telangana Layout Rules issued vide G.O.Ms.No.105 MA&UD Dept, dt.05.07.2021 and its amendments.',
    },
    {
      id: '6',
      label:
        'The proposed Master Plan/Indicative Land Use Plan (ILUP) road is passing through the site under reference.',
      value:
        'The proposed Master Plan/Indicative Land Use Plan (ILUP) road is passing through the site under reference.',
    },
    {
      id: '7',
      label:
        'The site u/r is affected by water body & buffer zone as per Master Plan// Indicative Land Use Plan (ILUP).',
      value:
        'The site u/r is affected by water body & buffer zone as per Master Plan// Indicative Land Use Plan (ILUP).',
    },
    {
      id: '8',
      label:
        'The site is falling within 100 ft (30 Mtrs) from the Railway property boundary.',
      value:
        'The site is falling within 100 ft (30 Mtrs) from the Railway property boundary.',
    },
    {
      id: '9',
      label:
        'The site is falling in Air Funnel zone. Prior NOC is required from Airport Authority.',
      value:
        'The site is falling in Air Funnel zone. Prior NOC is required from Airport Authority.',
    },
    {
      id: '10',
      label:
        'The Village / Mandal have been selected wrongly.',
      value:
        'The Village / Mandal have been selected wrongly.',
    },
    {
      id: '11',
      label:
        'The minimum width of the approach road is less than 9 Mtrs(30Fts).',
      value:
        'The minimum width of the approach road is less than 9 Mtrs(30Fts).',
    },
    {
      id: '12',
      label:
        'The minimum width of the approach road is 9 Mtrs and the applicant has not mentioned the road affected area in the application.',
      value:
        'The minimum width of the approach road is 9 Mtrs and the applicant has not mentioned the road affected area in the application.',
    },
    {
      id: '13',
      label: 'The site u/r is affected by Water Body / Buffer Zone / Nala as per ground position.',
      value: 'The site u/r is affected by Water Body / Buffer Zone / Nala as per ground position.',
    },
    {
      id: '14',
      label: 'The site is falling within vicinity of HT tower lines and affected under proposed 10 Mtrs road which is to be provided on either sides of the HT Tower base as per layout rules.',
      value: 'The site is falling within vicinity of HT tower lines and affected under proposed 10 Mtrs road which is to be provided on either sides of the HT Tower base as per layout rules.',
    },
    {
      id: '15',
      label:
        'If LT/HT lines are passing through the site.',
      value:
        'If LT/HT lines are passing through the site.',
    },
    {
      id: '16',
      label:
        'The site is affected Oil/Gas pipelines.',
      value:
        'The site is affected Oil/Gas pipelines.',
    },
    {
      id: '17',
      label:
        'The Site dimensions are not tallying with the submitted ownership documents/as specified in the application form.',
      value:
        'The Site dimensions are not tallying with the submitted ownership documents/as specified in the application form.',
    },


  ],

  COMMISSIONER: [
    {
      id: '31',
      label: 'Other',
      value: '',
    },
    {
      id: '1',
      label:
        'The proposals are in contrary to the Land Use & Master Plan Zoning Regulations.',
      value:
        'The proposals are in contrary to the Land Use & Master Plan Zoning Regulations.',
    },
    {
      id: '2',
      label:
        'The site under reference is falling in the Open Space Zone as per the Master Plan. ',
      value:
        'The site under reference is falling in the Open Space Zone as per the Master Plan. ',
    },
    {
      id: '3',
      label:
        'The applicant has not mentioned the road affected area in the application. ',
      value:
        'The applicant has not mentioned the road affected area in the application. ',
    },
    {
      id: '4',
      label:
        'The proposals are not in accordance to the Telangana Building Rules issued vide G.O.Ms.No.168, dt.07.04.2012 and its amendments.',
      value:
        'The proposals are not in accordance to the Telangana Building Rules issued vide G.O.Ms.No.168, dt.07.04.2012 and its amendments.',
    },
    {
      id: '5',
      label:
        'The proposed plot is forming part of unauthorized Layout and not applied under LRS. The plot is registered after cut-off date i.e.,26-08-2020 and has no registered link document prior to the cut-off date. ',
      value:
        'The proposed plot is forming part of unauthorized Layout and not applied under LRS. The plot is registered after cut-off date i.e.,26-08-2020 and has no registered link document prior to the cut-off date. ',
    },
    {
      id: '6',
      label:
        'The proposed Master Plan road is passing through the site/plot under reference.',
      value:
        'The proposed Master Plan road is passing through the site/plot under reference.',
    },
    {
      id: '7',
      label:
        'The plot is sub-divided into smaller plots for the purpose of obtaining permission under Instant Registration. Hence, liable for rejection as per G.O.Ms.No.62, dt.21.03.2020.',
      value:
        'The plot is sub-divided into smaller plots for the purpose of obtaining permission under Instant Registration. Hence, liable for rejection as per G.O.Ms.No.62, dt.21.03.2020.',
    },
    {
      id: '8',
      label:
        'The site u/r is affected by water body & buffer zone as per Master Plan.',
      value:
        'The site u/r is affected by water body & buffer zone as per Master Plan.',
    },
    {
      id: '9',
      label:
        'The site u/r is falling within 500 Mtrs in the downstream of Osmansagar / Himayathsagar Lakes. Hence, liable for rejection as per Memo.No. 261/I1/2006, dt.16.07.2007.',
      value:
        'The site u/r is falling within 500 Mtrs in the downstream of Osmansagar / Himayathsagar Lakes. Hence, liable for rejection as per Memo.No. 261/I1/2006, dt.16.07.2007.',
    },
    {
      id: '10',
      label:
        'The site is falling within 100 ft (30 Mtrs) from the Railway property boundary.',
      value:
        'The site is falling within 100 ft (30 Mtrs) from the Railway property boundary.',
    },
    {
      id: '11',
      label:
        'Not submitted the registered mortgage deed executed in favour of Municipal Commissioner.',
      value:
        'Not submitted the registered mortgage deed executed in favour of Municipal Commissioner.',
    },
    {
      id: '12',
      label:
        'The site is falling in Air Funnel zone. Prior NOC is required from Airport Authority.',
      value:
        'The site is falling in Air Funnel zone. Prior NOC is required from Airport Authority.',
    },
    {
      id: '13',
      label: 'The proposed village is covered in G.O.Ms.No.111, dt.08.03.1996.',
      value: 'The proposed village is covered in G.O.Ms.No.111, dt.08.03.1996.',
    },
    {
      id: '14',
      label: 'The Village / Mandal have been selected wrongly.',
      value: 'The Village / Mandal have been selected wrongly.',
    },
    {
      id: '15',
      label:
        'The minimum width of the approach road is less than 9 Mtrs and the applicant has commenced the construction without leaving road affected portion.',
      value:
        'The minimum width of the approach road is less than 9 Mtrs and the applicant has commenced the construction without leaving road affected portion.',
    },
    {
      id: '16',
      label:
        'The minimum width of the approach road is 9 Mtrs and the applicant has not mentioned the road affected area in the application.',
      value:
        'The minimum width of the approach road is 9 Mtrs and the applicant has not mentioned the road affected area in the application.',
    },
    {
      id: '17',
      label:
        'There is existing Building on the ground which is constructed without leaving mandatory setbacks as per Building Rules.',
      value:
        'There is existing Building on the ground which is constructed without leaving mandatory setbacks as per Building Rules.',
    },
    {
      id: '18',
      label:
        'The site u/r is affected by Water Body / Buffer Zone / Nala as per ground position.',
      value:
        'The site u/r is affected by Water Body / Buffer Zone / Nala as per ground position.',
    },
    {
      id: '19',
      label:
        'The site is falling within vicinity of HT tower lines and affected under proposed 10 Mtrs road which is to be provided on either sides of the HT Tower base as per Building rules.',
      value:
        'The site is falling within vicinity of HT tower lines and affected under proposed 10 Mtrs road which is to be provided on either sides of the HT Tower base as per Building rules.',
    },
    {
      id: '20',
      label: 'If LT/HT lines are passing through the site. ',
      value: 'If LT/HT lines are passing through the site. ',
    },
    {
      id: '21',
      label: 'The site is affected Oil/Gas pipelines.',
      value: 'The site is affected Oil/Gas pipelines.',
    },
    {
      id: '22',
      label:
        'The plot dimensions are not tallying with the submitted ownership documents/as specified in the application form.',
      value:
        'The plot dimensions are not tallying with the submitted ownership documents/as specified in the application form.',
    },
    {
      id: '23',
      label:
        'The site is falling within the vicinity of Raw water channel. Hence, the proposal is liable for rejection as per G.O.Ms.No. 111, dt.08.03.1996.',
      value:
        'The site is falling within the vicinity of Raw water channel. Hence, the proposal is liable for rejection as per G.O.Ms.No. 111, dt.08.03.1996.',
    },
    {
      id: '24',
      label: 'The site is forming part of Layout Open space.',
      value: 'The site is forming part of Layout Open space.',
    },
    {
      id: '25',
      label:
        'The site u/r is falling within the vicinity of Heritage Monument.',
      value:
        'The site u/r is falling within the vicinity of Heritage Monument.',
    },
    {
      id: '26',
      label: 'The site u/r is affected by ORR buffer zone.',
      value: 'The site u/r is affected by ORR buffer zone.',
    },
    {
      id: '27',
      label: 'The site u/r is falling in ULC / Surplus Land / Government Land.',
      value: 'The site u/r is falling in ULC / Surplus Land / Government Land.',
    },
    {
      id: '28',
      label: 'The mandatory documents are not uploaded.',
      value: 'The mandatory documents are not uploaded.',
    },
    {
      id: '29',
      label:
        'The prima facie title does not exist in the name of the applicant.',
      value:
        'The prima facie title does not exist in the name of the applicant.',
    },
    {
      id: '30',
      label:
        'The Survey number entered in the application is not matching with submitted Ownership document.',
      value:
        'The Survey number entered in the application is not matching with submitted Ownership document.',
    },

  ],


  OCCUPANCY: [
    {
      id: '10',
      label: 'Other',
      value: '',
    },
    {
      id: '1',
      label:
        'The applicant has deviated the front setback approved in the sanctioned plan.',
      value:
        'The applicant has deviated the front setback approved in the sanctioned plan.',
    },
    {
      id: '2',
      label:
        'The percentage of setback deviation is more than 10%.',
      value:
        'The percentage of setback deviation is more than 10%.',
    },
    {
      id: '3',
      label: 'The applicant has constructed additional floors over and above the permitted floors.',
      value: 'The applicant has constructed additional floors over and above the permitted floors.',
    },
    {
      id: '4',
      label: 'The applicant has not completed the Building in all/partial aspects.',
      value: 'The applicant has not completed the Building in all/partial aspects.',
    },
    {
      id: '5',
      label: 'The applicant has not formed the existing road with BT / CC surface.',
      value: 'The applicant has not formed the existing road with BT / CC surface.',
    },
    {
      id: '6',
      label: 'The applicant has not maintained the Minimum road width required as per rules/Building permit order.',
      value: 'The applicant has not maintained the Minimum road width required as per rules/Building permit order.',
    },
    {
      id: '7',
      label: 'The usage of building is other than the usage mentioned in the sanctioned plan.',
      value: 'The usage of building is other than the usage mentioned in the sanctioned plan.',
    },
    {
      id: '8',
      label: 'The area earmarked for parking floor is converted to Residential/other purpose.',
      value: 'The area earmarked for parking floor is converted to Residential/other purpose.',
    },
    {
      id: '9',
      label: 'The applicant has not constructed the rain water harvesting structures as per the sanctioned plan.',
      value: 'The applicant has not constructed the rain water harvesting structures as per the sanctioned plan.',
    },

  ],
};


export const OCRejectOptions = {
  OCCUPANCY: [
    {
      id: '8',
      label: 'Other',
      value: ''
    },
    {
      id: '1',
      label: 'Usage of the building proposed is not same as on ground',
      value: 'Usage of the building proposed is not same as on ground'
    },
    {
      id: '2',
      label: 'No of Floors constructed are not same as per building permission',
      value: 'No of Floors constructed are not same as per building permission'
    },
    {
      id: '3',
      label: 'Set back violation is above 10%',
      value: 'Set back violation is above 10%'
    },
    {
      id: '4',
      label: 'Lift facility is not available',
      value: 'Lift facility is not available'
    },
    {
      id: '5',
      label: 'Number of lifts are not same as per building permission',
      value: 'Number of lifts are not same as per building permission'
    },
    {
      id: '6',
      label: 'Water supply is not available for the building',
      value: 'Water supply is not available for the building'
    },
    {
      id: '7',
      label: 'Green strip is not available',
      value: 'Green strip is not available'
    },

  ]
};


// Now 'data' array contains all the similar kind of data as provided.


export const optionalFeeHeads = [
  {
    id: '1',
    label:
      'Regularization charges with ref to the land value',
    value:
      'Regularization charges with ref to the land value',
  },
  {
    id: '2',
    label:
      '33% Compound Fee On Penal Charges',
    value:
      '33% Compound Fee On Penal Charges',
  },
  {
    id: '3',
    label:
      '14% Open Space Contribution Charges On Prevailing Market Value',
    value:
      '14% Open Space Contribution Charges On Prevailing Market Value',
  },
  {
    id: '4',
    label:
      'Development Charges on built up area(As per   G.O. Ms. No.225, dt: 30.08.2016 , G.O. Ms No. 226 and dt: 30.08.2016 ,G.O. Ms No. 223, dt: 30.08.2016)',
    value:
      'Development Charges on built up area(As per   G.O. Ms. No.225, dt: 30.08.2016 , G.O. Ms No. 226 and dt: 30.08.2016 ,G.O. Ms No. 223, dt: 30.08.2016)',
  }, {
    id: '5',
    label:
      'Betterment Charges',
    value:
      'Betterment Charges',
  },
  {
    id: '6',
    label:
      'External Betterment Charges',
    value:
      'External Betterment Charges',
  },
  {
    id: '7',
    label:
      'Building Permit Fee',
    value:
      'Building Permit Fee',
  },
  {
    id: '8',
    label:
      'Compound Wall Fee',
    value:
      'Compound Wall Fee',
  },
  {
    id: '9',
    label:
      'Compounding fees',
    value:
      'Compounding fees',
  },
  {
    id: '10',
    label:
      'Conversion Charges',
    value:
      'Conversion Charges',
  },
  {
    id: '11',
    label:
      'Debris Charges',
    value:
      'Debris Charges',
  },
  // {
  //   id: '12',
  //   label:
  //     'Development Charges on Built Up Area',
  //   value:
  //     'Development Charges on Built Up Area',
  // },
  {
    id: '13',
    label:
      'Development Charges on site area as per G.O.Ms No.223 MA, Dt:30-08-2016',
    value:
      'Development Charges on site area as per G.O.Ms No.223 MA, Dt:30-08-2016',
  },
  {
    id: '14',
    label:
      'Development charges on site area excluding coverage',
    value:
      'Development charges on site area excluding coverage',
  },
  {
    id: '15',
    label:
      'Labour cess',
    value:
      'Labour cess',
  },
  {
    id: '16',
    label:
      'Postage/ Advertisement Charges',
    value:
      'Postage/ Advertisement Charges',
  },
  {
    id: '17',
    label:
      'Rain Water Harvesting Charges',
    value:
      'Rain Water Harvesting Charges',
  },
  {
    id: '18',
    label:
      'Site Approval Charges',
    value:
      'Site Approval Charges',
  },
  {
    id: '19',
    label:
      'Sub Division Of Plot/ Amalgamation Of Plot',
    value:
      'Sub Division Of Plot/ Amalgamation Of Plot',
  },
  {
    id: '20',
    label:
      'TG-bPASS User Charges',
    value:
      'TG-bPASS User Charges',
  },
  {
    id: '21',
    label:
      'Vacant Land Tax',
    value:
      'Vacant Land Tax',
  },
  {
    id: '22',
    label:
      'Vacant Plot Charges',
    value:
      'Vacant Plot Charges',
  },
  {
    id: '23',
    label:
      'Value Additional Charges(CDA)',
    value:
      'Value Additional Charges(CDA)',
  },
  {
    id: '24',
    label:
      'External Betterment Charges(HADA)',
    value:
      'External Betterment Charges(HADA)',
  },
  {
    id: '25',
    label:
      'Special Impact Fee(ORRGC)',
    value:
      'Special Impact Fee(ORRGC)',
  },
  {
    id: '26',
    label:
      'Deferment Charges (ORRGC)',
    value:
      'Deferment Charges (ORRGC)',
  },
  {
    id: '27',
    label:
      'Change of Land use Charges',
    value:
      'Change of Land use Charges',
  },
  {
    id: '28',
    label:
      'Environmental Impact Fee',
    value:
      'Environmental Impact Fee',
  },
  {
    id: '29',
    label:
      'Proportionate Layout Charges',
    value:
      'Proportionate Layout Charges',
  },
  {
    id: '30',
    label:
      'Shortfall of Open Space Charges',
    value:
      'Shortfall of Open Space Charges',
  },
  {
    id: '31',
    label:
      'Document Verification Charges',
    value:
      'Document Verification Charges',
  },
  {
    id: '32',
    label:
      'Vacant Land Tax & Library Cess',
    value:
      'Vacant Land Tax & Library Cess',
  },
  {
    id: '33',
    label:
      'Permit for Openings (Gate, Door, Window, ventilators)',
    value:
      'Permit for Openings (Gate, Door, Window, ventilators)',
  },
  {
    id: '34',
    label:
      'Well/Septic tank/ WC/Staircase',
    value:
      'Well/Septic tank/ WC/Staircase',
  },
  {
    id: '35',
    label:
      'Deviation Charges',
    value:
      'Deviation Charges',
  },
  {
    id: '36',
    label:
      'Revalidation charges',
    value:
      'Revalidation charges',
  },
  {
    id: '37',
    label:
      'Revision charges',
    value:
      'Revision charges',
  },
  {
    id: '38',
    label:
      'Fixing Of Boundary Stones',
    value:
      'Fixing Of Boundary Stones',
  },
  {
    id: '39',
    label:
      'Encroachment fee',
    value:
      'Encroachment fee',
  },
  {
    id: '40',
    label:
      'Special Development Charges for CRMP',
    value:
      'Special Development Charges for CRMP',
  },
  {
    id: '41',
    label:
      'Open space contribution charges',
    value:
      'Open space contribution charges',
  },
  {
    id: '42',
    label:
      'Building permit fee for Proposed covered built up area',
    value:
      'Building permit fee for Proposed covered built up area',
  },
  {
    id: '43',
    label:
      'Betterment and External Betterment Charges on site area',
    value:
      'Betterment and External Betterment Charges on site area',
  },
  {
    id: '44',
    label:
      'Betterment and External Betterment Charges on built up area',
    value:
      'Betterment and External Betterment Charges on built up area',
  },
  {
    id: '45',
    label: 'Kokapet Betterment Charges',
    value: 'Kokapet Betterment Charges',
  }
]

export const FILEVALIDATIONS = [
  { dbField: "CC/BT_Roads" },
  { dbField: "Electrical_Poles_with_Street_Lights" },
  { dbField: "Water_Supply_Pipelines" },
  { dbField: "Transfarmer" },
  { dbField: "Plantation_and_Greenery_in_the_Openspace" },
  { dbField: "Rainwater_Harvesting_structure" },
  { dbField: "Septic_Tank" },
  { dbField: "site_inspection_Optional_1" },
  { dbField: "Storm_Water_Drain_wherever_necessary" },
  { dbField: "Avenue_Plantation" },
  { dbField: "Bore_Well" },
  { dbField: "site_inspection_Optional_2" },
  { dbField: "site_inspection_Optional_3" },
  { dbField: "site_inspection_Optional_4" },
  { dbField: "Footpaths_with_Storm_Water_(Saucer_Drain)" },
  { dbField: "Underground_Drainage" },
  { dbField: "Low_Hight_Compound_Wall_with_Ornamental_Grill_Around_the_Open_Space" },
]