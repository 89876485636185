import React from 'react';
import { Card, CardDeck } from 'react-bootstrap';
import './index.scss';
import CmoDashboardTable from '../CmoDashboardTable';

export default function CmoDashboardCardDeck(props) {
  return (
    <div className="cmo-content">
      <div className="d-flex flex-column pt-4 pb-3 padding-left-60">
        <div className="d-flex flex-column">
          <span className="fs-16 font-weight-bold pt-4 pb-4 cmo-dashboard-title1">
            <span className="cmo-dashboard-title">CMO Dashboard :</span>{' '}
            Insights
          </span>
        </div>
        <CardDeck style={{ width: 'max-content' }}>
          <Card className="text-center">
            <Card.Header
              className="d-flex"
              style={{ justifyContent: 'space-evenly' }}
            >
              <img
                src={require('../../assets/images/office-building.png')}
                alt=""
              />
              <span className="fs-14 font-weight-bold align-self-center">
                Revenue Department
              </span>
            </Card.Header>
            <Card.Body className="d-flex flex-column justify-content-between fs-12">
              <div className="d-flex justify-content-between">
                <span className="text-muted">Applied</span>
                <span className="font-weight-bold">2000</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted">Pending</span>
                <span className="font-weight-bold">1000</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted">Issued</span>
                <span className="font-weight-bold">100</span>
              </div>
            </Card.Body>
            <Card.Footer className="d-flex flex-column justify-content-between fs-12">
              <div className="d-flex justify-content-between">
                <span className="text-muted">
                  Deemed <span className="green-color pl-1"> 1%</span>
                </span>
                <span className="font-weight-bold green-color">1000</span>
              </div>
            </Card.Footer>
          </Card>
          <Card className="text-center" style={{ width: '18rem' }}>
            <Card.Header
              className="d-flex"
              style={{ justifyContent: 'space-evenly' }}
            >
              <img src={require('../../assets/images/irrigation.svg')} alt="" />
              <span className="fs-14 font-weight-bold align-self-center">
                Irrigation Department
              </span>
            </Card.Header>
            <Card.Body className="d-flex flex-column justify-content-between fs-12">
              <div className="d-flex justify-content-between">
                <span className="text-muted">Applied</span>
                <span className="font-weight-bold">2000</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted">Pending</span>
                <span className="font-weight-bold">1000</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted">Issued</span>
                <span className="font-weight-bold">100</span>
              </div>
            </Card.Body>
            <Card.Footer className="d-flex flex-column justify-content-between fs-12">
              <div className="d-flex justify-content-between">
                <span className="text-muted">
                  Deemed <span className="green-color pl-1"> 1%</span>
                </span>
                <span className="font-weight-bold green-color">1000</span>
              </div>
            </Card.Footer>
          </Card>
          <Card className="text-center" style={{ width: '18rem' }}>
            <Card.Header
              className="d-flex"
              style={{ justifyContent: 'space-evenly' }}
            >
              <img
                src={require('../../assets/images/fire-extinguisher.svg')}
                alt=""
              />
              <span className="fs-14 font-weight-bold align-self-center">
                Fire Department
              </span>
            </Card.Header>
            <Card.Body className="d-flex flex-column justify-content-between fs-12">
              <div className="d-flex justify-content-between">
                <span className="text-muted">Applied</span>
                <span className="font-weight-bold">2000</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted">Pending</span>
                <span className="font-weight-bold">1000</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted">Issued</span>
                <span className="font-weight-bold">100</span>
              </div>
            </Card.Body>
            <Card.Footer className="d-flex flex-column justify-content-between fs-12">
              <div className="d-flex justify-content-between">
                <span className="text-muted">
                  Deemed <span className="green-color pl-1"> 1%</span>
                </span>
                <span className="font-weight-bold green-color">1000</span>
              </div>
            </Card.Footer>
          </Card>
          <Card className="text-center" style={{ width: '18rem' }}>
            <Card.Header
              className="d-flex"
              style={{ justifyContent: 'space-evenly' }}
            >
              <img src={require('../../assets/images/police.svg')} alt="" />
              <span className="fs-14 font-weight-bold align-self-center">
                Police Department
              </span>
            </Card.Header>
            <Card.Body className="d-flex flex-column justify-content-between fs-12">
              <div className="d-flex justify-content-between">
                <span className="text-muted">Applied</span>
                <span className="font-weight-bold">2000</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted">Pending</span>
                <span className="font-weight-bold">1000</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted">Issued</span>
                <span className="font-weight-bold">100</span>
              </div>
            </Card.Body>
            <Card.Footer className="d-flex flex-column justify-content-between fs-12">
              <div className="d-flex justify-content-between">
                <span className="text-muted">
                  Deemed <span className="green-color pl-1"> 1%</span>
                </span>
                <span className="font-weight-bold green-color">1000</span>
              </div>
            </Card.Footer>
          </Card>
        </CardDeck>
      </div>
      <CmoDashboardTable />
    </div>
  );
}
