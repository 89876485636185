import React from 'react';

export const showFilePreview = (path) => {
  let fileType = 'pdf';
  if (path.toLowerCase().includes('.pdf')) {
    fileType = 'pdf';
  } else if (
    path.toLowerCase().includes('.jpg') ||
    path.toLowerCase().includes('.jpeg') ||
    path.toLowerCase().includes('.png') ||
    path.toLowerCase().includes('.svg')
  ) {
    fileType = 'img';
  }

  if (fileType === 'pdf') {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
            width:"22px"
          }}
          className="plot-img"
        >
          <img
          style={{ width :"22px"}}
            onClick={() => window.open(path, '_blank')}
            src={require('../assets/images/pdf.svg')}
          />
        </div>
      </>
    );
  }
  if (fileType === 'img') {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
            width:"22px"
          }}
          className="plot-img"
        >
          <img
             style={{ width :"22px"}}
            onClick={() => window.open(path, '_blank')}
            src={path}
        
          />
        </div>
      </>
    );
  }
};
