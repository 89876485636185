import environment from './base';

// const baseApi = 'http://hyno-external-rails-new-1353938241.ap-southeast-1.elb.amazon.com';
// const host = 'https://tsb-pass.herokua.com';
const host = 'https://uat-backend.ts-bpass.com';

const baseApi = `${host}/api/v1`;
const graphqlBaseUrl = `${host}/graphql`;

const env = environment(host, baseApi, graphqlBaseUrl);
export default {
  ...env,
  intervals: {
    ...env.intervals,
    logout: 300, // 5 min in seconds
  },
  isProduction: true,
  isDevelopment: false,
};
