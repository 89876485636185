import { gql } from '@apollo/client';

export const SELECT_CONDITION = gql`
mutation selectConditions( $applicationId:ID!,$conditionSelected:String!
    ){
        selectConditions(
            conditionSelected:$conditionSelected,
            applicationId: $applicationId
        )
     }
`


export const CUSTOM_CONDTIONS    = gql`
mutation customCondition( $typeOf: String!,
     $applicationId: ID!, 
     $newCondition: [String!]!
     ){
    customCondition(typeOf: $typeOf, 
        newCondition: $newCondition,
         applicationId: $applicationId)
  }
  
`
