import { gql } from '@apollo/client';

export const CHANGE_OFFICER_PASSWORD = gql`
  mutation changeOfficerPassword(
    $username: String!
    $password: String!
    $passwordConfirmation: String!
    $otpSecretKey: String!
  ) {
    changeOfficerPassword(
      username: $username
      password: $password
      passwordConfirmation: $passwordConfirmation
      otpSecretKey: $otpSecretKey
    ) {
      accessToken
      authority
      district
      email
      firstName
      id
      lastName
      lineDepartment
      mobile
      mobileVerified
      ulbName
      zone
    }
  }
`;
