import React, { useState, useEffect } from 'react';
// import ReviewSubmit from '../components/ApplicationDetailComponent/review.js'; import LoadingScreen from '../components/LoadingScreen';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ErrorScreen from '../components/ErrorScreen';
import LoadingScreen from '../components/LoadingScreen';
import PropTypes from 'prop-types';
import ApprovedAplicationTabs from '../components/ApprovedAplicationTabs';
import { useParams } from 'react-router';
import axios from 'axios';
import environment from 'environment';
import { toast } from 'react-toastify';
function CWApplicationDetails(props) {
  const { type, id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const [applicantData, setApplicantData] = useState();

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const authToken = sessionStorage.getItem('accessToken');

    if (authToken && id) {
      setIsLoading(true);
      axios
        .get(`${environment.baseApi}/applications/${id}/compound_wall_show`, {
          headers: {
            Authorization: 'Bearer ' + authToken,
            Accept: '*/*',
          },
        })
        .then((response) => {
          if (response.data.success) {
            setApplicantData(response.data.data);
            setIsLoading(false);
          } else {
            toast.error(response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
          }
        })
        .catch((error) => {
          console.log('erroreee', error);
          if (error.response) {
            setIsLoading(false);
            setError(error.response);
            // toast.error(error.response.data.message, {
            //   position: 'top-right',
            //   autoClose: 5000,
            //   hideProgressBar: false,
            // });
          }
        });
    }
  }, []);
  
  const FetchingOperation = () => {
    if (isLoading) return <LoadingScreen />;

    if (error) {
      const msgObj = {
        message: error.statusText,
      };
      return (
        <ErrorScreen
          message={msgObj}
          retry={console.log('write retry func to reload the page')}
        />
      );
    }
  };

  function checkUserLogin () {
    const loginTOken = sessionStorage.getItem('accessToken')
    if(loginTOken != null || loginTOken != "" || loginTOken != undefined) {
      return true
    }else {
      return false
    }
  }
  return (
    <>
   {/* <IdleTimer/>  */}
      <Header />
      <Sidebar />
      {FetchingOperation()}
      {applicantData && (
        <ApprovedAplicationTabs
          applicationIdentifier={applicantData.application_identifier}
          applicationType={props.location?.state?.applicationType}
          fromSummary={props.location?.state?.fromSummary}
        />
      )}
    </>
  );
}

CWApplicationDetails.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  match: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.shape({
      applicationType: PropTypes.string,
      fromSummary: PropTypes.bool,
    }),
  }),
};
CWApplicationDetails.defaultProps = {
  location: {
    state: {
      applicationType: 'SELF CERTIFICATION',
      fromSummary: false,
    },
  },
};

export default CWApplicationDetails;
