import { gql } from '@apollo/client';
export const APPLICATION_CORRECTION = gql`
query applicationsCorrectionRequest($page: Int, $status: String){
  applicationsCorrectionRequest(
    page: $page
    perPage: 10
    status: $status
  ){
    totalCount
    totalPages
    records {
      applicant {
        applicantName
        __typename
      }
      currentOfficerLevel
      finalRemark
      applicantListId
      applicationType
      referenceApplicationType
      applicationIdentifier
      approvalFor
      id
      createdAt
      appliedOn
      paymentCompletedAt
      dueDate
      dangerDate
      deemedDate
      deemedDueDate
      additional
      additionalType
      stageStates {
        name
        status
        dueDate
        receivedDate
        submittedDate
        stageOfficers {
          action
          forwardedDate
          id
          officerLevel
          officerName
          officerRole
          __typename
        }
        __typename
      }
      status
      property {
        actualPlotArea
        plotAddress
        location {
          village
          __typename
        }
        __typename
      }
      status
      __typename
    }
    __typename
  }
}
`;
