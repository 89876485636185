import React, { useEffect, useState } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import history from '../services/history';
import OtpInput from 'react-otp-input';
import { useMutation } from '@apollo/client';
import { CHANGE_OFFICER_PASSWORD } from '../graphql/mutatution/changeOfficerPassword';
import { RESET_OFFICER_PASSWORD } from '../graphql/mutatution/resetOfficerPassword';

function ResetPasswordNew(props) {
  // const [username, setUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [validated, setValidated] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [password, setPassword] = useState('');
  const [strength, setStrength] = useState('');
  const [showConfirmassword, setShowConfirmassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [isloading, setIsLoading] = useState(false)
  const [mutation, { called, error, changePasswordMutationLoading }] = useMutation(
    CHANGE_OFFICER_PASSWORD
  );
  // eslint-disable-next-line no-unused-vars
  const [mutationResetPassword, { caled, errer, resetPasswordMutationLoading }] = useMutation(
    RESET_OFFICER_PASSWORD
  );

  // const handleChangeUsername = (event) => {
  //   setUsername(event.target.value);
  // };
  const handleNewPassword = (event) => {

    setNewPassword(event.target.value);
    setPassword(event.target.value)
    // strongPassword()
  };
  useEffect(() => {
    if (password.length > 8) {
      // console.log(strongPassword(), "strongPassword")
      strongPassword()
    }
  }, [password])

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };
  function valiDateUser() {
    if (confirmPassword.length > 1 && newPassword !== confirmPassword) {
      return "Passwords do not match"
    } else {
      return true
    }
  }

  useEffect(() => {
    valiDateUser()
  }, [confirmPassword])
  const handleChangeOtp = (otp) => {
    setOtp(otp);
  };
  let errorMessage;
  if (otp.length < 4) {
    errorMessage = (
      <div style={{ color: '#DC3545' }} className="fs-12 my-1">
        Please fill the OTP
      </div>
    );
  } else {
    errorMessage = <div />;
  }
  function strongPassword() {
    if (password.length < 8) {
      // setStrength('Weak');
      return <p style={{ color: "red" }}>Password must be at least 8 characters long *</p>;
    }
    // Check for uppercase, lowercase, digit, and special character
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);

    if (!hasUppercase) {
      return <p style={{ color: "red" }}>Password must include uppercase* </p>
    }
 if(password != confirmPassword) {
  return <p style={{ color: "red" }}> Password does not match* </p>
 }
    if (!hasLowercase) {
      return <p style={{ color: "red" }}>Password must include lowercase* </p>;
    }
    if (!specialCharacters) {
      return <p style={{ color: "red" }}>Password must include special character* </p>;
    } if (!hasDigit) {

      return <p style={{ color: "red" }}>Password must include digit (1,2,3..) * </p>;
    } else {
      return true;
    }
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToogleConfirmPassword = () => {

    setShowConfirmassword(!showConfirmassword);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)
    const form = e.currentTarget;
    if (strongPassword() == true) {
      if (form.checkValidity() === false) {
        setValidated({ validated: true });
      } else if ( otp.length === 4) {
        mutation({
          variables: {
            username: props.location.state?.username,
            password: newPassword,
            passwordConfirmation: confirmPassword,
            otpSecretKey: otp,
          },
        })
          .then((data) => {
            setIsLoading(false)
            history.push('/password-success');
          })
          .catch((e) => {
            setIsLoading(false)
            setErrorMsg(e.message);
          });
      } else {
        setValidated({ validated: true });
      }
    } else {
      alert(strongPassword()?.props?.children)
      setIsLoading(false)
    }

  };
  const handleResendOtp = () => {
    mutationResetPassword({
      variables: {
        username: props.location.state?.username,
      },
    })
      .then(() => { })
      .catch((e) => {
        setErrorMsg(e.message);
      });
  };

  let saveButton;
  // Reset password mutation to be added
  if (!isloading) {
    saveButton = (
      <Button
        variant="primary"
        type="submit"
        className="d-flex align-items-center justify-content-around"
        disabled={props.location.state?.username ? false : true}
        style={{ width: '40%' }}
      >
        <span className="text-uppercase">Save</span>
        <img src={require('../assets/images/arrow.svg')} alt="" />
      </Button>
    );
  } else {
    saveButton = (
      <Button variant="secondary" style={{ width: '40%' }}>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Button>
    );
  }



  return (
    <div
      className="reset-pwd-container"
      style={{ overflow: 'auto',  padding:"20px" }}
    >
      <Container>
        <div style={{width:"40%" , borderRadius:"10px" , boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",margin :"auto", padding:"20px" ,backgroundColor:"white"}}   className=" d-flex flex-column justify-content-center align-items-baseline">
          <strong>
            <font size="5" face="NeutraTextTF-DemiAlt">
              Reset Password
            </font>
          </strong>
          <p>
            <strong>Choose a strong password policy by following :</strong>  <br />
            <ul>
              <li style={{ color: "#31EC56" }}>Password should be 8 characters long, contain alphanumeric characters, have one special character like (@,#,!,%,$ etc), and include at least one capital letter and one small letter</li>
            </ul>
          </p>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {/* <Form.Group controlId="formUsername">
              <Form.Control
                required
                type="text"
                placeholder="Enter username"
                value={props.location.state.username || username}
                onChange={handleChangeUsername}
              />
              <Form.Control.Feedback type="invalid">
                Please enter mobile number
              </Form.Control.Feedback>
            </Form.Group> */}
            <Form.Group controlId="formNewPassword" className="password">
              <Form.Control
                style={{ padding: "8px 8px" , maxWidth:"300px" }}
                required
                type={showPassword ? 'text' : 'password'}
                placeholder="New Password"
                value={newPassword}
                onChange={handleNewPassword}
              />
              <img
                alt=""
                style={{ cursor: 'pointer' }}
                onClick={() => handleTogglePassword()}
                className="password-img form-group px-2 mx-2"
                src={require('../assets/images/password.svg')}
              />


            </Form.Group>
            <Form.Group controlId="formConfirmPassword" className="password">
              <Form.Control
              style={{ padding: "8px 8px" , maxWidth:"300px" }}
                required
                type={showConfirmassword ? 'text' : 'password'}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleConfirmPassword}
                {...(newPassword === confirmPassword
                  ? {}
                  : { isInvalid: true })}
              />
              <img
                alt=""
                style={{ cursor: 'pointer' }}
                onClick={() => handleToogleConfirmPassword()}
                className="password-img form-group px-2 mx-2"
                src={require('../assets/images/password.svg')}
              />
              <Form.Control.Feedback type="invalid">

                <span style={{ marign: "50px" }}>{strongPassword() != true ? strongPassword() : valiDateUser() != true ? valiDateUser() : <span style={{ color: 'red' }}> Please enter confirm password </span>}
                </span>
              </Form.Control.Feedback>
            </Form.Group>
            <div>
              <OtpInput
                onChange={handleChangeOtp}
                value={otp}
                numInputs={4}
                isInputNum="true"
                containerStyle={{
                  width: '284px',
                  display :"flex",
                  justifyContent: 'space-between',
                  marginTop: '0px',
                  textAlign :"center",
                  alignItems:'center'
                }}
                inputStyle={{
                  display :"flex",
                  justifyContent: 'space-between',
                  marginTop: '0px',
                  alignItems:'center',
                  height: '50px',
                  width: '50px',
                  border: '1px solid #3F4A50',
                  borderRadius: '4px',
                }}
              />
              {errorMessage}
            </div>
            <div   style ={{width :"50%"}}className="d-flex justify-content-between  py-1">
              <button
                className="btn btn-light"
                onClick={(e) => {
                  history.push('/reset-password');
                }}
                style={{ width: '40%' }}
              >
                <img
                  src={require('../assets/images/arrowBackwards.svg')}
                  alt="previous"
                  style={{ float: 'left', marginTop: '7px' }}
                />
                BACK
              </button>
              {saveButton}
            </div>
          </Form>{' '}
          <p className="fs-12 my-1">
            Didn&apos;t receive Code{' '} ?
            <span
              className="ml-3 pointer"
              style={{ color: '#1C9A5B' }}
              onClick={handleResendOtp}
            >
              <strong>Resend</strong>
            </span>
          </p>
          <p className="fs-20" style={{ color: 'red' }}>
            {errorMsg}
          </p>
          <br />
          {/* <br /> */}
          <strong>Password strength:</strong>
          <p>
            Use atleast 8 characters. Don&apos;t use a password from another
            site, or something too obvious like your pet&apos;s name.{' '}
            <span style={{ color: '#1C9A5B' }} className="pointer">
              Why?
            </span>
          </p>
        </div>
      </Container>
    </div>
  );
}

ResetPasswordNew.propTypes = {
  changePin: PropTypes.func,
  showSpinner: PropTypes.bool,
  location: {
    state: {
      username: PropTypes.string,
    },
  },
};

ResetPasswordNew.defaultProps = {
  changePin: () => { },
  showSpinner: false,
  location: {
    state: {
      username: '',
    },
  },
};

export default ResetPasswordNew;