
import { useMutation, useQuery } from '@apollo/client';

import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, FormLabel, InputGroup, Modal, Tab,Card ,Nav} from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';
import CorrectionApprove from '.';
import { UPDATE_FINAL_CORRECTION } from '../../graphql/mutatution/updateCorrection';
import { APPLICATION_CORRECTION_DETAILS } from '../../graphql/query/applicationCorrectionData';
import { APPLICATION_CORRECTION } from '../../graphql/query/application_Correction';
import ErrorScreen from '../ErrorScreen/index';
import LoadingScreen from '../LoadingScreen/index';
import NotesModal from '../NotesModal';
import history from '../../services/history';
import CorrectionStatus from './coorectionStatus';
import "./index.scss"
function CommessionerCorrectionTabels(
    showSidebarHandler,
    showSidebar,
    appliIdentifier
) {
    const [Remarks, setRemarks] = useState('N/A');
    const [showing, setShowing] = useState(false);
    const [isDisabledNotes, setIsDisabledNotes] = useState(false);
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true);
    const [finalRemarks, setFinalRemarks] = useState("")
    const [updateFinalCorrection, { errors, called }] = useMutation(UPDATE_FINAL_CORRECTION)
   const [isloading,setLoading] = useState(false)
   const [isApproved,setIsApprove] = useState(null)
    const { id } = useParams();
 const [btnColor,setButtonColor] = useState('')


    const { loading, error, data: applicationCorrection, refetch } = useQuery(APPLICATION_CORRECTION_DETAILS, {
        fetchPolicy: 'network-only',
        variables: {
            applicationId: id,
            status:"SUBMITTED"
        }

    })
    let correctionReqId
    if (applicationCorrection != undefined) {
        correctionReqId = applicationCorrection?.applicationCorrectionRequest?.id
    }

    const handleChangeFinalRemarks = (e) => {
        setFinalRemarks(e.target.value)
    }

    // function  nonDisplayValues  (item) {
//         if(item.columnName == "mobile" && (item?.newValue !== item.oldValue)) {
//             if(item?.newValue !== ""){
                
//                     return true
//                 }else {
//                     return false
//                 }
            
//         }else {
//         if((item?.newValue != "")){
//         if(((item?.supportingDocUrl !== "") && (item?.newValue !== item.oldValue))  && item?.oldValue != null ) {
//             return true;
//         }else {
//             return false
//         }
//     }
// else {
//     return false
// }
//         }
    // }
    const handleApplicaitonDetails = () =>{
        document.getElementsByTagName('body')[0].classList.remove('show-sidebar');
        document.getElementsByTagName('body')[0].classList.remove('Hide-RightSidebar');
        document.getElementsByTagName('body')[0].classList.toggle('show-RightSidebar');
    
      }

    function isApproveOrRejectCheck () {
const isApprove = applicationCorrection?.applicationCorrectionRequest?.corrections?.some(item=>item.isApprove === false) 

if(isApprove){
    // setButtonColor('red')
    return true
}else {
    // setButtonColor("green")
    return false
}
    }

    const handleAccpet =() =>{
        setIsApprove(true)
    }

    const handleRejectButton = () => {
        setIsApprove(false)
    }
    const handleSubmitCorrection = () => {
        setLoading(true)
        updateFinalCorrection({
            variables: {
                correctionRequestId: correctionReqId,
                remark: finalRemarks,
                isApprove:isApproved
            }
        }).then(()=>{
            if(!called && !errors) {
                setLoading(false)
            history.push('/correction-verification')
            }
        })
        //  console.log(finalRemarks,"finalRemarks");
    }
    const ModelRemarksPopUp = () => {
        return (
            <Modal
                show={show}
                onHide={() => {

                    handleClose();
                }}
                centered
            >
                <Modal.Header
                    closeButton
                    style={{ borderBottom: '0 none', paddingBottom: '0px' }}
                />
                <Modal.Body style={{ paddingTop: '0px' }}>
                    <div className="px-2">Remarks</div>
                    <div className="p-2">
                        <div className="form-group">
                            <textarea
                                className="form-control"
                                rows="3"
                                placeholder="Enter text here"
                                value={Remarks}
                                onChange={(e) => {
                                    setRemarks(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer
                    style={{ justifyContent: 'flex-start', borderTop: '0 none' }}
                >
                    <Button
                        className="btn btn-success"

                        style={{ width: '120px' }}
                    >
                        ADD &nbsp; &nbsp;
                        <img src={require('../../assets/images/arrow.svg')} alt="next" />
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
    return (
        <>

<div className="tv-container">
<div
                className={
                  showSidebar ? 'tv-header sw-header' : 'tv-header-expanded'
                }
              >
                {/* <div></div> */}
                <ol className="breadcrumbs2">

                  <li>
                    <a href="#!">{applicationCorrection?.applicationCorrectionRequest?.applicationIdentifier}</a>
                  </li>
                </ol>


                <div style={{ display: 'flex' }}>

                  {show ? <NotesModal handleClose={handleClose} /> : null}
                  {showSidebar && (
                    <div className=" d-flex align-items-center ">
                      <span className="addnote_label_text">
                      Add Note Sheet:
                      </span>
                      <div className=" d-flex add_note_wrapper mr-3 ">
                        {/* <div
                          className="viewButton"
                          type="button"
                          onClick={() => {
                            setShow(true);
                          }}
                        >
                          VIEW
                        </div> */}
                        <div
                          className="addButton"
                          type="button"
                          onClick={() => {
                            setShow(true);
                          }}
                        >
                          ADD NOTE SHEET
                        </div>
                      </div>

                    </div>
                  )}
                  <div onClick={handleApplicaitonDetails} className="sidebarToggle" style={{ marginLeft: "130px" }}
                    type="button" >  <p className='application_Details_Button'> application Details </p>
                  </div>
                </div>

              </div>
            </div>
            <div>
                <Card style={{ height: `calc(100vh - 32vh)`, overflowY: "scroll" }} >
                    {ModelRemarksPopUp}
                    {/* {showSidebarHandler(true)} */}
                    {loading && <LoadingScreen />}
                    {error && <ErrorScreen message={error} />}
                    {/* <ol className="breadcrumbs">
                  <li
                    className="active"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      history.push('/post-verification');
                    }}
                  >
                    <a>Post Verification</a>
                  </li>
                  <li>
                    <a>{props.applicationIdentifier}</a>
                  </li>
                </ol> */}

                    <div className='correctionCards' >
                        <Tab.Container id="left-tabs-example" >
                            <div
                                className="al-header"
                            >

                            </div>
                            <div className="al-content">
                                <Table striped hover  >
                                    <Tab.Content >

                                        <thead>
                                            <tr >
                                                <th style={{ width: "40px" }}>S.No</th>
                                                <th style={{ width: "160px" }}  nowrap="true" > Column Name</th>
                                                <th style={{ width: "160px" }} nowrap="true" >Existing</th>
                                                <th style={{ width: "160px" }} nowrap="true" >Proposed </th>
                                                <th style={{ width: "160px" }} nowrap="true">Documents</th>
                                                <th  style={{ width: "160px" }} >Actions</th>

                                                <th style={{ width: "160px" }} >
                                                    Remarks
                                                </th>

                                            </tr>

                                        </thead>
                                        {applicationCorrection?.applicationCorrectionRequest != null
                                            && applicationCorrection?.applicationCorrectionRequest?.corrections.length > 0
                                            && applicationCorrection?.applicationCorrectionRequest?.corrections.map((item, index) => {
                                                console.log(item.supportingOptionalDocUrl,'item.supportingOptionalDocUrl')
                                                return <tbody>

                                                    <tr>
                                                        <td style={{ maxWidth: "100px" }}>
                                                            {index + 1}
                                                        </td>
                                                        <td style={{ maxWidth: "100px" }}>{item?.columnName?.replaceAll('_', ' ')}</td>
                                                        <td style={{ maxWidth: "100px" }}>{item?.oldValue}</td>
                                                        <td style={{ maxWidth: "100px" }} >{item?.newValue}</td>
                                                        <td style={{ width: "100px" }}>
                                                        {item.supportingDocUrl != "" ?  <a 
                                                            style={{color:"blue"}}
                                                            href={item.supportingDocUrl}
                                                            target="_blank"
                                                             alt="uploaded_docs"
                                                            >view doc</a> :"no document"}
                                                              <br/>
                                                           {  item?.supportingOptionalDocUrl !=null  ?  item?.supportingOptionalDocUrl.map((e,i)=>{
                                                           return <div>
                                                            &nbsp;
                                                            <a 
                                                              style={{color:"blue"}}
                                                              href={e}
                                                              target="_blank"
                                                               alt="uploaded_docs"
                                                            >
                                                                {"optionalDocs"}&nbsp;{i}
                                                            </a>
                                                            </div>
                                                           }) :""}
                                                        </td> 
                                                        <td style={{ maxWidth: "120px" }} >
                                                            <CorrectionStatus
                                                                index={index}
                                                                applicantype={item?.columnName}
                                                                correctionId={item.id}
                                                                isAproved={item.isApprove}
                                                            />
                                                        </td>

                                                        <td style={{ cursor: "pointer" }} onClick={() => { setShow(true) }}>
                                                            <p style={{ minWidth: "10px" }}>{item.remark == null ? "N/A" : item.remark == "" ? "------" : <> {item.remark} </>} </p>
                                                        </td>
                                                    </tr>
                                                        
                                                </tbody>
                                            })}

                                            <div style={{float:"right" ,margin:"20px"}}>
                                            {!isApproveOrRejectCheck() ? <><Button variant='outline-success' onClick={handleAccpet}>{"Approve"}
                                            &nbsp;  &nbsp; {isApproved === true ?<>   <img width="20px" src={require("../../assets/images/Post Verification.png")}/> </> :" "} 
                                            </Button> </> : <Button onClick={handleRejectButton} variant='outline-danger'>{"Reject"}
                                            &nbsp;  &nbsp;   {isApproved === false ? <>   <img width="20px" src={require("../../assets/images/Post Verification.png")}/> </>  :" "} 

                                            </Button>}
                                            </div>


                                    </Tab.Content>

                                </Table>

                            </div>
                        </Tab.Container>
                    </div>
             
                </Card>
                <div style={{ display: "flex", gap: '10px', padding: "5px" , marginTop:"20px"}}>
                    <div className='d-flex' style={{ alignItems: 'center', gap: "20px" }} >
                        <p>Enter Final Remarks:</p>
                        <TextArea onChange={handleChangeFinalRemarks} style={{ width: "300px" }} placeholder="Enter Final Remarks" />
                    </div>
                    <div style={{ fontSize: '10px', gap: "10px", alignItems: "center", color: "tomato" }} className='d-flex'>
                        {finalRemarks == null ? <p >add remarks</p> : ""}
                        <Button
                            style={{ width: "100px", height: "50px" }}
                             onClick={handleSubmitCorrection}
                           
                            disabled={(isApproved !== null && finalRemarks !== "") ? false : true} >
                         {isloading ?       <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div> :   <>  {" Submit"}  </>}
                        </Button>

                    </div>

                </div>
            </div>
        </>
    )
}

export default CommessionerCorrectionTabels