import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import CmoDashboardCardDeck from '../components/CmoDashboardCardDesk';

export default function CmoDashboard() {
  return (
    <div className="chasing-dashboard">
      <Header />
      <Sidebar />
      <CmoDashboardCardDeck />
    </div>
  );
}
