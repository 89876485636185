import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

function ErrorScreen(props) {
  const [logOffPopup,setLogOffPopUp] = useState(false)

const handlepopupOpen = () => setLogOffPopUp(true)
useEffect(()=>{
  if(props?.message?.message == 'You need to authenticate to perform this action' ){
    handlepopupOpen()
  }
},[props])


function logout () {
  
  window.sessionStorage.clear();
  window.location.reload()
}

function  lofOffPopup () {
  
  return (
  
  <Modal   centered show={logOffPopup}>
    <Modal.Header></Modal.Header>
      <Modal.Body>
        <p style={{fontSize:"16px", marignLeft:"30px"}}>
        session expired please login &nbsp;!

        </p>
        
        </Modal.Body>
      <Modal.Footer>

      <Button  style={{width:"140px" , height:"50px"}} variant="secondary" onClick={logout} >
           login here!
          </Button>
      </Modal.Footer>
   
  </Modal>
  )
}


  return (
<>
{lofOffPopup()}
    <div
      style={{
        height: '100%',
        display: 'flex',
        width: '100%',
      }}
    >
      
      <div style={{ margin: 'auto' }}>
        <div className={'text-center'}>
          <strong> {props.message.message}</strong>
          <br />
          <Button
            style={{ cursor: 'pointer' }}
            onClick={() => props.retry()}
          >
            RETRY
          </Button>
        </div>
      </div>
    </div>
    </>
  );
}

ErrorScreen.propTypes = {
  message: PropTypes.object.isRequired,
  retry: PropTypes.func,
};

ErrorScreen.defaultProps = {
  message: { message: 'Some error occured' },
  retry: () => {},
};

export default ErrorScreen;
