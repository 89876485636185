import React, { useState, useEffect, useContext } from 'react';
import { Form, Table, Button, Spinner, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { getAllLocalData } from '../../services/localStorage';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import environment from 'environment';
import { AppContext } from '../../AppContext';
import { PERMISSIONS_LEVELS } from '../../util/helperFunctions';


// export default function DeviationTable({ question, handleCurrentChange,isDeviationUpdated ,isDeviationUpdated}) {
export default function DeviationTable({ question, handleCurrentChange, isDeviationUpdated, isDeviationSubmitted }) {
    const { permissionLevel, currentFlow, officerType } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false)
    const [isDevLoading, setIsDevLoading] = useState(true)
    const [DeviDBData, setDeviDBData] = useState(false)
    const { id } = useParams();
    const [showPopUP, setShowPopUp] = useState(false)
    const [DisableDeviationOption, setDisableDeviationOption] = useState(false)
    const handleClose = () => {
        setShowPopUp(false)
    }

    console.log(question.order, "ORDERTHE")
    const renderButton = () => {
        if (isDeviationUpdated == true || isDeviationSubmitted == true) {
            return false
        } else {
            return true
        }
    }

    console.log(question.order, 'permissionLevel!2');
    useEffect(() => {
        const authToken = getAllLocalData().accessToken;
        if (authToken) {
            setIsDevLoading(true)
            axios
                .get(`${environment.baseApi}/applications/${id}/occupancy_show`, {
                    headers: {
                        Authorization: 'Bearer ' + authToken,
                        Accept: '*/*',
                    },
                })
                .then(async function (response) {
                    if (response.data.success) {
                        setIsDevLoading(false)
                        setShowPopUp(false)
                        const deviationData = response.data.data.deviation;
                        console.log(deviationData.l1_front_setback_as_on_ground, "deviationData.l1_front_setback_as_on_ground")
                        setDeviDBData(deviationData)

                        if (question.order == "LEVEL-1") {
                            if (deviationData.l1_front_building_permit_order !== null) {
                                await customHandleChange("frontBuildingPermitOrder", deviationData.l1_front_building_permit_order);
                                await customHandleChange("frontAsOnGround", deviationData.l1_front_setback_as_on_ground);
                                await customHandleChange("frontDeviationInSetBack", deviationData.l1_front_setback_deviation_in_sq_mtrs);
                                await customHandleChange("frontPerOfSetbackDeviated", deviationData.l1_front_setback_deviated_percentage);
                                await customHandleChange("frontBuildingLengths", deviationData.l1_front_building_lengths);
                                await customHandleChange("frontDeviatedBAPerFloor", deviationData.l1_front_side_deviated_builtup_area_per_floor);
                                await customHandleChange("frontNoOfFloors", deviationData.l1_front_no_of_floors);
                                await customHandleChange("frontTotalDeviatedBuiltupArea", deviationData.l1_front_total_deviated_builtup_area);
                                await customHandleChange("rearBuildingPermitOrder", deviationData.l1_rear_building_permit_order);
                                await customHandleChange("rearAsOnGround", deviationData.l1_rear_setback_as_on_ground);
                                await customHandleChange("rearDeviationInSetBack", deviationData.l1_rear_setback_deviation_in_sq_mtrs);
                                await customHandleChange("rearPerOfSetbackDeviated", deviationData.l1_rear_setback_deviated_percentage);
                                await customHandleChange("rearBuildingLengths", deviationData.l1_rear_building_lengths);
                                await customHandleChange("rearDeviatedBAPerFloor", deviationData.l1_rear_deviated_builtup_area_per_floor);
                                await customHandleChange("rearNoOfFloors", deviationData.l1_rear_no_of_floors);
                                await customHandleChange("rearTotalDeviatedBuiltupArea", deviationData.l1_rear_total_deviated_builtup_area);

                                await customHandleChange("sideOneBuildingPermitOrder", deviationData.l1_side_one_building_permit_order);
                                await customHandleChange("sideOneAsOnGround", deviationData.l1_side_one_setback_as_on_ground);
                                await customHandleChange("sideOneDeviationInSetBack", deviationData.l1_side_one_setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideOnePerOfSetbackDeviated", deviationData.l1_side_one_setback_deviated_percentage);
                                await customHandleChange("sideOneBuildingLengths", deviationData.l1_side_one_building_lengths);
                                await customHandleChange("sideOneDeviatedBAPerFloor", deviationData.l1_side_one_deviated_builtup_area_per_floor);
                                await customHandleChange("sideOneNoOfFloors", deviationData.l1_side_one_no_of_floors);
                                await customHandleChange("sideOneTotalDeviatedBuiltupArea", deviationData.l1_side_one_total_deviated_builtup_area);

                                await customHandleChange("sideTwoBuildingPermitOrder", deviationData.l1_side_two_building_permit_order);
                                await customHandleChange("sideTwoAsOnGround", deviationData.l1_side_two_setback_as_on_ground);
                                await customHandleChange("sideTwoDeviationInSetBack", deviationData.l1_side_two__setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideTwoPerOfSetbackDeviated", deviationData.l1_side_two_setback_deviated_percentage);
                                await customHandleChange("sideTwoBuildingLengths", deviationData.l1_side_two_building_lengths);
                                await customHandleChange("sideTwoDeviatedBAPerFloor", deviationData.l1_side_two_deviated_builtup_area_per_floor);
                                await customHandleChange("sideTwoNoOfFloors", deviationData.l1_side_two_no_of_floors);
                                await customHandleChange("sideTwoTotalDeviatedBuiltupArea", deviationData.l1_side_two_total_deviated_builtup_area);

                                await customHandleChange("sumOfTotalDeviatedBuiltupAreaInSqYards", 0);
                                await customHandleChange("landValuePerRegistrationDep", deviationData.l1_land_value_per_registration_dep);
                                await customHandleChange("totalCompoundingFee", deviationData.l1_total_compounding_fee);
                                await customHandleChange("totalBuiltUpAreaAsOnGround", deviationData.l1_total_built_up_area_as_on_ground);


                                let grandTotal = parseFloat(deviationData ? deviationData.l1_front_total_deviated_builtup_area : "0.0") + parseFloat(deviationData ? deviationData.l1_rear_total_deviated_builtup_area : "0.0") + parseFloat(deviationData.l1_side_one_total_deviated_builtup_area) + parseFloat(deviationData ? deviationData.l1_side_two_total_deviated_builtup_area : "0.0");
                                customHandleChange('sumOfTotalDeviatedBuiltupArea', grandTotal.toFixed(2))
                                customHandleChange('sumOfTotalDeviatedBuiltupAreaInSqYards', convertToSqYards(grandTotal.toFixed(2), 2))
                            } else {
                                await customHandleChange("frontBuildingPermitOrder", deviationData.front_building_permit_order);
                                await customHandleChange("frontAsOnGround", deviationData.front_setback_as_on_ground);
                                await customHandleChange("frontDeviationInSetBack", deviationData.front_setback_deviation_in_sq_mtrs);
                                await customHandleChange("frontPerOfSetbackDeviated", deviationData.front_setback_deviated_percentage);
                                await customHandleChange("frontBuildingLengths", deviationData.front_building_lengths);
                                await customHandleChange("frontDeviatedBAPerFloor", deviationData.front_side_deviated_builtup_area_per_floor);
                                await customHandleChange("frontNoOfFloors", deviationData.front_no_of_floors);

                                await customHandleChange("frontTotalDeviatedBuiltupArea", deviationData.front_total_deviated_builtup_area);

                                await customHandleChange("rearBuildingPermitOrder", deviationData.rear_building_permit_order);
                                await customHandleChange("rearAsOnGround", deviationData.rear_setback_as_on_ground);
                                await customHandleChange("rearDeviationInSetBack", deviationData.rear_setback_deviation_in_sq_mtrs);
                                await customHandleChange("rearPerOfSetbackDeviated", deviationData.rear_setback_deviated_percentage);
                                await customHandleChange("rearBuildingLengths", deviationData.rear_building_lengths);
                                await customHandleChange("rearDeviatedBAPerFloor", deviationData.rear_deviated_builtup_area_per_floor);
                                await customHandleChange("rearNoOfFloors", deviationData.rear_no_of_floors);
                                await customHandleChange("rearTotalDeviatedBuiltupArea", deviationData.rear_total_deviated_builtup_area);

                                await customHandleChange("sideOneBuildingPermitOrder", deviationData.side_one_building_permit_order);
                                await customHandleChange("sideOneAsOnGround", deviationData.side_one_setback_as_on_ground);
                                await customHandleChange("sideOneDeviationInSetBack", deviationData.side_one_setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideOnePerOfSetbackDeviated", deviationData.side_one_setback_deviated_percentage);
                                await customHandleChange("sideOneBuildingLengths", deviationData.side_one_building_lengths);
                                await customHandleChange("sideOneDeviatedBAPerFloor", deviationData.side_one_deviated_builtup_area_per_floor);
                                await customHandleChange("sideOneNoOfFloors", deviationData.side_one_no_of_floors);
                                await customHandleChange("sideOneTotalDeviatedBuiltupArea", deviationData.side_one_total_deviated_builtup_area);

                                await customHandleChange("sideTwoBuildingPermitOrder", deviationData.side_two_building_permit_order);
                                await customHandleChange("sideTwoAsOnGround", deviationData.side_two_setback_as_on_ground);
                                await customHandleChange("sideTwoDeviationInSetBack", deviationData.side_two__setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideTwoPerOfSetbackDeviated", deviationData.side_two_setback_deviated_percentage);
                                await customHandleChange("sideTwoBuildingLengths", deviationData.side_two_building_lengths);
                                await customHandleChange("sideTwoDeviatedBAPerFloor", deviationData.side_two_deviated_builtup_area_per_floor);
                                await customHandleChange("sideTwoNoOfFloors", deviationData.side_two_no_of_floors);
                                await customHandleChange("sideTwoTotalDeviatedBuiltupArea", deviationData.side_two_total_deviated_builtup_area);

                                await customHandleChange("sumOfTotalDeviatedBuiltupAreaInSqYards", 0);
                                await customHandleChange("landValuePerRegistrationDep", deviationData.land_value_per_registration_dep);
                                await customHandleChange("totalCompoundingFee", deviationData.total_compounding_fee);
                                await customHandleChange("totalBuiltUpAreaAsOnGround", deviationData.total_built_up_area_as_on_ground);

                                let grandTotal = parseFloat(deviationData ? deviationData.front_total_deviated_builtup_area : "0.0") + parseFloat(deviationData ? deviationData.rear_total_deviated_builtup_area : "0.0") + parseFloat(deviationData.side_one_total_deviated_builtup_area) + parseFloat(deviationData ? deviationData.side_two_total_deviated_builtup_area : "0.0");
                                customHandleChange('sumOfTotalDeviatedBuiltupArea', grandTotal.toFixed(2))
                                customHandleChange('sumOfTotalDeviatedBuiltupAreaInSqYards', convertToSqYards(grandTotal.toFixed(2), 2))
                            }

                        } else if (question.order == "LEVEL-2") {
                            if (deviationData.l2_front_building_permit_order !== null) {
                                await customHandleChange("frontBuildingPermitOrder", deviationData.l2_front_building_permit_order);
                                await customHandleChange("frontAsOnGround", deviationData.l2_front_setback_as_on_ground);
                                await customHandleChange("frontDeviationInSetBack", deviationData.l2_front_setback_deviation_in_sq_mtrs);
                                await customHandleChange("frontPerOfSetbackDeviated", deviationData.l2_front_setback_deviated_percentage);
                                await customHandleChange("frontBuildingLengths", deviationData.l2_front_building_lengths);
                                await customHandleChange("frontDeviatedBAPerFloor", deviationData.l2_front_side_deviated_builtup_area_per_floor);
                                await customHandleChange("frontNoOfFloors", deviationData.l2_front_no_of_floors);

                                await customHandleChange("frontTotalDeviatedBuiltupArea", deviationData.l2_front_total_deviated_builtup_area);

                                await customHandleChange("rearBuildingPermitOrder", deviationData.l2_rear_building_permit_order);
                                await customHandleChange("rearAsOnGround", deviationData.l2_rear_setback_as_on_ground);
                                await customHandleChange("rearDeviationInSetBack", deviationData.l2_rear_setback_deviation_in_sq_mtrs);
                                await customHandleChange("rearPerOfSetbackDeviated", deviationData.l2_rear_setback_deviated_percentage);
                                await customHandleChange("rearBuildingLengths", deviationData.l2_rear_building_lengths);
                                await customHandleChange("rearDeviatedBAPerFloor", deviationData.l2_rear_deviated_builtup_area_per_floor);
                                await customHandleChange("rearNoOfFloors", deviationData.l2_rear_no_of_floors);
                                await customHandleChange("rearTotalDeviatedBuiltupArea", deviationData.l2_rear_total_deviated_builtup_area);
                                await customHandleChange("sideOneBuildingPermitOrder", deviationData.l2_side_one_building_permit_order);
                                await customHandleChange("sideOneAsOnGround", deviationData.l2_side_one_setback_as_on_ground);
                                await customHandleChange("sideOneDeviationInSetBack", deviationData.l2_side_one_setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideOnePerOfSetbackDeviated", deviationData.l2_side_one_setback_deviated_percentage);
                                await customHandleChange("sideOneBuildingLengths", deviationData.l2_side_one_building_lengths);
                                await customHandleChange("sideOneDeviatedBAPerFloor", deviationData.l2_side_one_deviated_builtup_area_per_floor);
                                await customHandleChange("sideOneNoOfFloors", deviationData.l2_side_one_no_of_floors);
                                await customHandleChange("sideOneTotalDeviatedBuiltupArea", deviationData.l2_side_one_total_deviated_builtup_area);

                                await customHandleChange("sideTwoBuildingPermitOrder", deviationData.l2_side_two_building_permit_order);
                                await customHandleChange("sideTwoAsOnGround", deviationData.l2_side_two_setback_as_on_ground);
                                await customHandleChange("sideTwoDeviationInSetBack", deviationData.l2_side_two__setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideTwoPerOfSetbackDeviated", deviationData.l2_side_two_setback_deviated_percentage);
                                await customHandleChange("sideTwoBuildingLengths", deviationData.l2_side_two_building_lengths);
                                await customHandleChange("sideTwoDeviatedBAPerFloor", deviationData.l2_side_two_deviated_builtup_area_per_floor);
                                await customHandleChange("sideTwoNoOfFloors", deviationData.l2_side_two_no_of_floors);
                                await customHandleChange("sideTwoTotalDeviatedBuiltupArea", deviationData.l2_side_two_total_deviated_builtup_area);

                                await customHandleChange("sumOfTotalDeviatedBuiltupAreaInSqYards", 0);
                                await customHandleChange("landValuePerRegistrationDep", deviationData.l2_land_value_per_registration_dep);
                                await customHandleChange("totalCompoundingFee", deviationData.l2_total_compounding_fee);
                                await customHandleChange("totalBuiltUpAreaAsOnGround", deviationData.l2_total_built_up_area_as_on_ground);

                                let grandTotal = parseFloat(deviationData ? deviationData.l2_front_total_deviated_builtup_area : "0.0") + parseFloat(deviationData ? deviationData.l2_rear_total_deviated_builtup_area : "0.0") + parseFloat(deviationData.l2_side_one_total_deviated_builtup_area) + parseFloat(deviationData ? deviationData.l2_side_two_total_deviated_builtup_area : "0.0");
                                customHandleChange('sumOfTotalDeviatedBuiltupArea', grandTotal.toFixed(2))
                                customHandleChange('sumOfTotalDeviatedBuiltupAreaInSqYards', convertToSqYards(grandTotal.toFixed(2), 2))
                            } else if (deviationData.l1_front_building_permit_order != null) {
                                await customHandleChange("frontBuildingPermitOrder", deviationData.l1_front_building_permit_order);
                                await customHandleChange("frontAsOnGround", deviationData.l1_front_setback_as_on_ground);
                                await customHandleChange("frontDeviationInSetBack", deviationData.l1_front_setback_deviation_in_sq_mtrs);
                                await customHandleChange("frontPerOfSetbackDeviated", deviationData.l1_front_setback_deviated_percentage);
                                await customHandleChange("frontBuildingLengths", deviationData.l1_front_building_lengths);
                                await customHandleChange("frontDeviatedBAPerFloor", deviationData.l1_front_side_deviated_builtup_area_per_floor);
                                await customHandleChange("frontNoOfFloors", deviationData.l1_front_no_of_floors);
                                await customHandleChange("frontTotalDeviatedBuiltupArea", deviationData.l1_front_total_deviated_builtup_area);
                                await customHandleChange("rearBuildingPermitOrder", deviationData.l1_rear_building_permit_order);
                                await customHandleChange("rearAsOnGround", deviationData.l1_rear_setback_as_on_ground);
                                await customHandleChange("rearDeviationInSetBack", deviationData.l1_rear_setback_deviation_in_sq_mtrs);
                                await customHandleChange("rearPerOfSetbackDeviated", deviationData.l1_rear_setback_deviated_percentage);
                                await customHandleChange("rearBuildingLengths", deviationData.l1_rear_building_lengths);
                                await customHandleChange("rearDeviatedBAPerFloor", deviationData.l1_rear_deviated_builtup_area_per_floor);
                                await customHandleChange("rearNoOfFloors", deviationData.l1_rear_no_of_floors);
                                await customHandleChange("rearTotalDeviatedBuiltupArea", deviationData.l1_rear_total_deviated_builtup_area);

                                await customHandleChange("sideOneBuildingPermitOrder", deviationData.l1_side_one_building_permit_order);
                                await customHandleChange("sideOneAsOnGround", deviationData.l1_side_one_setback_as_on_ground);
                                await customHandleChange("sideOneDeviationInSetBack", deviationData.l1_side_one_setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideOnePerOfSetbackDeviated", deviationData.l1_side_one_setback_deviated_percentage);
                                await customHandleChange("sideOneBuildingLengths", deviationData.l1_side_one_building_lengths);
                                await customHandleChange("sideOneDeviatedBAPerFloor", deviationData.l1_side_one_deviated_builtup_area_per_floor);
                                await customHandleChange("sideOneNoOfFloors", deviationData.l1_side_one_no_of_floors);
                                await customHandleChange("sideOneTotalDeviatedBuiltupArea", deviationData.l1_side_one_total_deviated_builtup_area);

                                await customHandleChange("sideTwoBuildingPermitOrder", deviationData.l1_side_two_building_permit_order);
                                await customHandleChange("sideTwoAsOnGround", deviationData.l1_side_two_setback_as_on_ground);
                                await customHandleChange("sideTwoDeviationInSetBack", deviationData.l1_side_two__setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideTwoPerOfSetbackDeviated", deviationData.l1_side_two_setback_deviated_percentage);
                                await customHandleChange("sideTwoBuildingLengths", deviationData.l1_side_two_building_lengths);
                                await customHandleChange("sideTwoDeviatedBAPerFloor", deviationData.l1_side_two_deviated_builtup_area_per_floor);
                                await customHandleChange("sideTwoNoOfFloors", deviationData.l1_side_two_no_of_floors);
                                await customHandleChange("sideTwoTotalDeviatedBuiltupArea", deviationData.l1_side_two_total_deviated_builtup_area);

                                await customHandleChange("sumOfTotalDeviatedBuiltupAreaInSqYards", 0);
                                await customHandleChange("landValuePerRegistrationDep", deviationData.l1_land_value_per_registration_dep);
                                await customHandleChange("totalCompoundingFee", deviationData.l1_total_compounding_fee);
                                await customHandleChange("totalBuiltUpAreaAsOnGround", deviationData.l1_total_built_up_area_as_on_ground);


                                let grandTotal = parseFloat(deviationData ? deviationData.l1_front_total_deviated_builtup_area : "0.0") + parseFloat(deviationData ? deviationData.l1_rear_total_deviated_builtup_area : "0.0") + parseFloat(deviationData.l1_side_one_total_deviated_builtup_area) + parseFloat(deviationData ? deviationData.l1_side_two_total_deviated_builtup_area : "0.0");
                                customHandleChange('sumOfTotalDeviatedBuiltupArea', grandTotal.toFixed(2))
                                customHandleChange('sumOfTotalDeviatedBuiltupAreaInSqYards', convertToSqYards(grandTotal.toFixed(2), 2))
                            } else {
                                await customHandleChange("frontBuildingPermitOrder", deviationData.front_building_permit_order);
                                await customHandleChange("frontAsOnGround", deviationData.front_setback_as_on_ground);
                                await customHandleChange("frontDeviationInSetBack", deviationData.front_setback_deviation_in_sq_mtrs);
                                await customHandleChange("frontPerOfSetbackDeviated", deviationData.front_setback_deviated_percentage);
                                await customHandleChange("frontBuildingLengths", deviationData.front_building_lengths);
                                await customHandleChange("frontDeviatedBAPerFloor", deviationData.front_side_deviated_builtup_area_per_floor);
                                await customHandleChange("frontNoOfFloors", deviationData.front_no_of_floors);

                                await customHandleChange("frontTotalDeviatedBuiltupArea", deviationData.front_total_deviated_builtup_area);

                                await customHandleChange("rearBuildingPermitOrder", deviationData.rear_building_permit_order);
                                await customHandleChange("rearAsOnGround", deviationData.rear_setback_as_on_ground);
                                await customHandleChange("rearDeviationInSetBack", deviationData.rear_setback_deviation_in_sq_mtrs);
                                await customHandleChange("rearPerOfSetbackDeviated", deviationData.rear_setback_deviated_percentage);
                                await customHandleChange("rearBuildingLengths", deviationData.rear_building_lengths);
                                await customHandleChange("rearDeviatedBAPerFloor", deviationData.rear_deviated_builtup_area_per_floor);
                                await customHandleChange("rearNoOfFloors", deviationData.rear_no_of_floors);
                                await customHandleChange("rearTotalDeviatedBuiltupArea", deviationData.rear_total_deviated_builtup_area);

                                await customHandleChange("sideOneBuildingPermitOrder", deviationData.side_one_building_permit_order);
                                await customHandleChange("sideOneAsOnGround", deviationData.side_one_setback_as_on_ground);
                                await customHandleChange("sideOneDeviationInSetBack", deviationData.side_one_setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideOnePerOfSetbackDeviated", deviationData.side_one_setback_deviated_percentage);
                                await customHandleChange("sideOneBuildingLengths", deviationData.side_one_building_lengths);
                                await customHandleChange("sideOneDeviatedBAPerFloor", deviationData.side_one_deviated_builtup_area_per_floor);
                                await customHandleChange("sideOneNoOfFloors", deviationData.side_one_no_of_floors);
                                await customHandleChange("sideOneTotalDeviatedBuiltupArea", deviationData.side_one_total_deviated_builtup_area);

                                await customHandleChange("sideTwoBuildingPermitOrder", deviationData.side_two_building_permit_order);
                                await customHandleChange("sideTwoAsOnGround", deviationData.side_two_setback_as_on_ground);
                                await customHandleChange("sideTwoDeviationInSetBack", deviationData.side_two__setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideTwoPerOfSetbackDeviated", deviationData.side_two_setback_deviated_percentage);
                                await customHandleChange("sideTwoBuildingLengths", deviationData.side_two_building_lengths);
                                await customHandleChange("sideTwoDeviatedBAPerFloor", deviationData.side_two_deviated_builtup_area_per_floor);
                                await customHandleChange("sideTwoNoOfFloors", deviationData.side_two_no_of_floors);
                                await customHandleChange("sideTwoTotalDeviatedBuiltupArea", deviationData.side_two_total_deviated_builtup_area);

                                await customHandleChange("sumOfTotalDeviatedBuiltupAreaInSqYards", 0);
                                await customHandleChange("landValuePerRegistrationDep", deviationData.land_value_per_registration_dep);
                                await customHandleChange("totalCompoundingFee", deviationData.total_compounding_fee);
                                await customHandleChange("totalBuiltUpAreaAsOnGround", deviationData.total_built_up_area_as_on_ground);

                                let grandTotal = parseFloat(deviationData ? deviationData.front_total_deviated_builtup_area : "0.0") + parseFloat(deviationData ? deviationData.rear_total_deviated_builtup_area : "0.0") + parseFloat(deviationData.side_one_total_deviated_builtup_area) + parseFloat(deviationData ? deviationData.side_two_total_deviated_builtup_area : "0.0");
                                customHandleChange('sumOfTotalDeviatedBuiltupArea', grandTotal.toFixed(2))
                                customHandleChange('sumOfTotalDeviatedBuiltupAreaInSqYards', convertToSqYards(grandTotal.toFixed(2), 2))
                            }
                        }
                        else if (question.order == "LEVEL-3") {
                            if (deviationData.l3_front_building_permit_order != null) {
                                await customHandleChange("frontBuildingPermitOrder", deviationData.l3_front_building_permit_order);
                                await customHandleChange("frontAsOnGround", deviationData.l3_front_setback_as_on_ground);
                                await customHandleChange("frontDeviationInSetBack", deviationData.l3_front_setback_deviation_in_sq_mtrs);
                                await customHandleChange("frontPerOfSetbackDeviated", deviationData.l3_front_setback_deviated_percentage);
                                await customHandleChange("frontBuildingLengths", deviationData.l3_front_building_lengths);
                                await customHandleChange("frontDeviatedBAPerFloor", deviationData.l3_front_side_deviated_builtup_area_per_floor);
                                await customHandleChange("frontNoOfFloors", deviationData.l3_front_no_of_floors);
                                await customHandleChange("frontTotalDeviatedBuiltupArea", deviationData.l3_front_total_deviated_builtup_area);
                                await customHandleChange("rearBuildingPermitOrder", deviationData.l3_rear_building_permit_order);
                                await customHandleChange("rearAsOnGround", deviationData.l3_rear_setback_as_on_ground);
                                await customHandleChange("rearDeviationInSetBack", deviationData.l3_rear_setback_deviation_in_sq_mtrs);
                                await customHandleChange("rearPerOfSetbackDeviated", deviationData.l3_rear_setback_deviated_percentage);
                                await customHandleChange("rearBuildingLengths", deviationData.l3_rear_building_lengths);
                                await customHandleChange("rearDeviatedBAPerFloor", deviationData.l3_rear_deviated_builtup_area_per_floor);
                                await customHandleChange("rearNoOfFloors", deviationData.l3_rear_no_of_floors);
                                await customHandleChange("rearTotalDeviatedBuiltupArea", deviationData.l3_rear_total_deviated_builtup_area);
                                await customHandleChange("sideOneBuildingPermitOrder", deviationData.l3_side_one_building_permit_order);
                                await customHandleChange("sideOneAsOnGround", deviationData.l3_side_one_setback_as_on_ground);
                                await customHandleChange("sideOneDeviationInSetBack", deviationData.l3_side_one_setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideOnePerOfSetbackDeviated", deviationData.l3_side_one_setback_deviated_percentage);
                                await customHandleChange("sideOneBuildingLengths", deviationData.l3_side_one_building_lengths);
                                await customHandleChange("sideOneDeviatedBAPerFloor", deviationData.l3_side_one_deviated_builtup_area_per_floor);
                                await customHandleChange("sideOneNoOfFloors", deviationData.l3_side_one_no_of_floors);
                                await customHandleChange("sideOneTotalDeviatedBuiltupArea", deviationData.l3_side_one_total_deviated_builtup_area);
                                await customHandleChange("sideTwoBuildingPermitOrder", deviationData.l3_side_two_building_permit_order);
                                await customHandleChange("sideTwoAsOnGround", deviationData.l3_side_two_setback_as_on_ground);
                                await customHandleChange("sideTwoDeviationInSetBack", deviationData.l3_side_two__setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideTwoPerOfSetbackDeviated", deviationData.l3_side_two_setback_deviated_percentage);
                                await customHandleChange("sideTwoBuildingLengths", deviationData.l3_side_two_building_lengths);
                                await customHandleChange("sideTwoDeviatedBAPerFloor", deviationData.l3_side_two_deviated_builtup_area_per_floor);
                                await customHandleChange("sideTwoNoOfFloors", deviationData.l3_side_two_no_of_floors);
                                await customHandleChange("sideTwoTotalDeviatedBuiltupArea", deviationData.l3_side_two_total_deviated_builtup_area);
                                await customHandleChange("sumOfTotalDeviatedBuiltupAreaInSqYards", 0);
                                await customHandleChange("landValuePerRegistrationDep", deviationData.l3_land_value_per_registration_dep);
                                await customHandleChange("totalCompoundingFee", deviationData.l3_total_compounding_fee);
                                await customHandleChange("totalBuiltUpAreaAsOnGround", deviationData.l3_total_built_up_area_as_on_ground);
                                let grandTotal = parseFloat(deviationData ? deviationData.l3_front_total_deviated_builtup_area : "0.0") + parseFloat(deviationData ? deviationData.l3_rear_total_deviated_builtup_area : "0.0") + parseFloat(deviationData.l2_side_one_total_deviated_builtup_area) + parseFloat(deviationData ? deviationData.l2_side_two_total_deviated_builtup_area : "0.0");
                                customHandleChange('sumOfTotalDeviatedBuiltupArea', grandTotal.toFixed(2))
                                customHandleChange('sumOfTotalDeviatedBuiltupAreaInSqYards', convertToSqYards(grandTotal.toFixed(2), 2))
                            } else if (deviationData.l2_front_building_permit_order !== null) {
                                await customHandleChange("frontBuildingPermitOrder", deviationData.l2_front_building_permit_order);
                                await customHandleChange("frontAsOnGround", deviationData.l2_front_setback_as_on_ground);
                                await customHandleChange("frontDeviationInSetBack", deviationData.l2_front_setback_deviation_in_sq_mtrs);
                                await customHandleChange("frontPerOfSetbackDeviated", deviationData.l2_front_setback_deviated_percentage);
                                await customHandleChange("frontBuildingLengths", deviationData.l2_front_building_lengths);
                                await customHandleChange("frontDeviatedBAPerFloor", deviationData.l2_front_side_deviated_builtup_area_per_floor);
                                await customHandleChange("frontNoOfFloors", deviationData.l2_front_no_of_floors);

                                await customHandleChange("frontTotalDeviatedBuiltupArea", deviationData.l2_front_total_deviated_builtup_area);

                                await customHandleChange("rearBuildingPermitOrder", deviationData.l2_rear_building_permit_order);
                                await customHandleChange("rearAsOnGround", deviationData.l2_rear_setback_as_on_ground);
                                await customHandleChange("rearDeviationInSetBack", deviationData.l2_rear_setback_deviation_in_sq_mtrs);
                                await customHandleChange("rearPerOfSetbackDeviated", deviationData.l2_rear_setback_deviated_percentage);
                                await customHandleChange("rearBuildingLengths", deviationData.l2_rear_building_lengths);
                                await customHandleChange("rearDeviatedBAPerFloor", deviationData.l2_rear_deviated_builtup_area_per_floor);
                                await customHandleChange("rearNoOfFloors", deviationData.l2_rear_no_of_floors);
                                await customHandleChange("rearTotalDeviatedBuiltupArea", deviationData.l2_rear_total_deviated_builtup_area);
                                await customHandleChange("sideOneBuildingPermitOrder", deviationData.l2_side_one_building_permit_order);
                                await customHandleChange("sideOneAsOnGround", deviationData.l2_side_one_setback_as_on_ground);
                                await customHandleChange("sideOneDeviationInSetBack", deviationData.l2_side_one_setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideOnePerOfSetbackDeviated", deviationData.l2_side_one_setback_deviated_percentage);
                                await customHandleChange("sideOneBuildingLengths", deviationData.l2_side_one_building_lengths);
                                await customHandleChange("sideOneDeviatedBAPerFloor", deviationData.l2_side_one_deviated_builtup_area_per_floor);
                                await customHandleChange("sideOneNoOfFloors", deviationData.l2_side_one_no_of_floors);
                                await customHandleChange("sideOneTotalDeviatedBuiltupArea", deviationData.l2_side_one_total_deviated_builtup_area);

                                await customHandleChange("sideTwoBuildingPermitOrder", deviationData.l2_side_two_building_permit_order);
                                await customHandleChange("sideTwoAsOnGround", deviationData.l2_side_two_setback_as_on_ground);
                                await customHandleChange("sideTwoDeviationInSetBack", deviationData.l2_side_two__setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideTwoPerOfSetbackDeviated", deviationData.l2_side_two_setback_deviated_percentage);
                                await customHandleChange("sideTwoBuildingLengths", deviationData.l2_side_two_building_lengths);
                                await customHandleChange("sideTwoDeviatedBAPerFloor", deviationData.l2_side_two_deviated_builtup_area_per_floor);
                                await customHandleChange("sideTwoNoOfFloors", deviationData.l2_side_two_no_of_floors);
                                await customHandleChange("sideTwoTotalDeviatedBuiltupArea", deviationData.l2_side_two_total_deviated_builtup_area);

                                await customHandleChange("sumOfTotalDeviatedBuiltupAreaInSqYards", 0);
                                await customHandleChange("landValuePerRegistrationDep", deviationData.l2_land_value_per_registration_dep);
                                await customHandleChange("totalCompoundingFee", deviationData.l2_total_compounding_fee);
                                await customHandleChange("totalBuiltUpAreaAsOnGround", deviationData.l2_total_built_up_area_as_on_ground);

                                let grandTotal = parseFloat(deviationData ? deviationData.l2_front_total_deviated_builtup_area : "0.0") + parseFloat(deviationData ? deviationData.l2_rear_total_deviated_builtup_area : "0.0") + parseFloat(deviationData.l2_side_one_total_deviated_builtup_area) + parseFloat(deviationData ? deviationData.l2_side_two_total_deviated_builtup_area : "0.0");
                                customHandleChange('sumOfTotalDeviatedBuiltupArea', grandTotal.toFixed(2))
                                customHandleChange('sumOfTotalDeviatedBuiltupAreaInSqYards', convertToSqYards(grandTotal.toFixed(2), 2))
                            }
                            else {
                                await customHandleChange("frontBuildingPermitOrder", deviationData.front_building_permit_order);
                                await customHandleChange("frontAsOnGround", deviationData.front_setback_as_on_ground);
                                await customHandleChange("frontDeviationInSetBack", deviationData.front_setback_deviation_in_sq_mtrs);
                                await customHandleChange("frontPerOfSetbackDeviated", deviationData.front_setback_deviated_percentage);
                                await customHandleChange("frontBuildingLengths", deviationData.front_building_lengths);
                                await customHandleChange("frontDeviatedBAPerFloor", deviationData.front_side_deviated_builtup_area_per_floor);
                                await customHandleChange("frontNoOfFloors", deviationData.front_no_of_floors);

                                await customHandleChange("frontTotalDeviatedBuiltupArea", deviationData.front_total_deviated_builtup_area);

                                await customHandleChange("rearBuildingPermitOrder", deviationData.rear_building_permit_order);
                                await customHandleChange("rearAsOnGround", deviationData.rear_setback_as_on_ground);
                                await customHandleChange("rearDeviationInSetBack", deviationData.rear_setback_deviation_in_sq_mtrs);
                                await customHandleChange("rearPerOfSetbackDeviated", deviationData.rear_setback_deviated_percentage);
                                await customHandleChange("rearBuildingLengths", deviationData.rear_building_lengths);
                                await customHandleChange("rearDeviatedBAPerFloor", deviationData.rear_deviated_builtup_area_per_floor);
                                await customHandleChange("rearNoOfFloors", deviationData.rear_no_of_floors);
                                await customHandleChange("rearTotalDeviatedBuiltupArea", deviationData.rear_total_deviated_builtup_area);

                                await customHandleChange("sideOneBuildingPermitOrder", deviationData.side_one_building_permit_order);
                                await customHandleChange("sideOneAsOnGround", deviationData.side_one_setback_as_on_ground);
                                await customHandleChange("sideOneDeviationInSetBack", deviationData.side_one_setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideOnePerOfSetbackDeviated", deviationData.side_one_setback_deviated_percentage);
                                await customHandleChange("sideOneBuildingLengths", deviationData.side_one_building_lengths);
                                await customHandleChange("sideOneDeviatedBAPerFloor", deviationData.side_one_deviated_builtup_area_per_floor);
                                await customHandleChange("sideOneNoOfFloors", deviationData.side_one_no_of_floors);
                                await customHandleChange("sideOneTotalDeviatedBuiltupArea", deviationData.side_one_total_deviated_builtup_area);

                                await customHandleChange("sideTwoBuildingPermitOrder", deviationData.side_two_building_permit_order);
                                await customHandleChange("sideTwoAsOnGround", deviationData.side_two_setback_as_on_ground);
                                await customHandleChange("sideTwoDeviationInSetBack", deviationData.side_two__setback_deviation_in_sq_mtrs);
                                await customHandleChange("sideTwoPerOfSetbackDeviated", deviationData.side_two_setback_deviated_percentage);
                                await customHandleChange("sideTwoBuildingLengths", deviationData.side_two_building_lengths);
                                await customHandleChange("sideTwoDeviatedBAPerFloor", deviationData.side_two_deviated_builtup_area_per_floor);
                                await customHandleChange("sideTwoNoOfFloors", deviationData.side_two_no_of_floors);
                                await customHandleChange("sideTwoTotalDeviatedBuiltupArea", deviationData.side_two_total_deviated_builtup_area);

                                await customHandleChange("sumOfTotalDeviatedBuiltupAreaInSqYards", 0);
                                await customHandleChange("landValuePerRegistrationDep", deviationData.land_value_per_registration_dep);
                                await customHandleChange("totalCompoundingFee", deviationData.total_compounding_fee);
                                await customHandleChange("totalBuiltUpAreaAsOnGround", deviationData.total_built_up_area_as_on_ground);

                                let grandTotal = parseFloat(deviationData ? deviationData.front_total_deviated_builtup_area : "0.0") + parseFloat(deviationData ? deviationData.rear_total_deviated_builtup_area : "0.0") + parseFloat(deviationData.side_one_total_deviated_builtup_area) + parseFloat(deviationData ? deviationData.side_two_total_deviated_builtup_area : "0.0");
                                customHandleChange('sumOfTotalDeviatedBuiltupArea', grandTotal.toFixed(2))
                                customHandleChange('sumOfTotalDeviatedBuiltupAreaInSqYards', convertToSqYards(grandTotal.toFixed(2), 2))
                            }

                        }
                        else {
                            await customHandleChange("frontBuildingPermitOrder", deviationData.front_building_permit_order);
                            await customHandleChange("frontAsOnGround", deviationData.front_setback_as_on_ground);
                            await customHandleChange("frontDeviationInSetBack", deviationData.front_setback_deviation_in_sq_mtrs);
                            await customHandleChange("frontPerOfSetbackDeviated", deviationData.front_setback_deviated_percentage);
                            await customHandleChange("frontBuildingLengths", deviationData.front_building_lengths);
                            await customHandleChange("frontDeviatedBAPerFloor", deviationData.front_side_deviated_builtup_area_per_floor);
                            await customHandleChange("frontNoOfFloors", deviationData.front_no_of_floors);

                            await customHandleChange("frontTotalDeviatedBuiltupArea", deviationData.front_total_deviated_builtup_area);

                            await customHandleChange("rearBuildingPermitOrder", deviationData.rear_building_permit_order);
                            await customHandleChange("rearAsOnGround", deviationData.rear_setback_as_on_ground);
                            await customHandleChange("rearDeviationInSetBack", deviationData.rear_setback_deviation_in_sq_mtrs);
                            await customHandleChange("rearPerOfSetbackDeviated", deviationData.rear_setback_deviated_percentage);
                            await customHandleChange("rearBuildingLengths", deviationData.rear_building_lengths);
                            await customHandleChange("rearDeviatedBAPerFloor", deviationData.rear_deviated_builtup_area_per_floor);
                            await customHandleChange("rearNoOfFloors", deviationData.rear_no_of_floors);
                            await customHandleChange("rearTotalDeviatedBuiltupArea", deviationData.rear_total_deviated_builtup_area);

                            await customHandleChange("sideOneBuildingPermitOrder", deviationData.side_one_building_permit_order);
                            await customHandleChange("sideOneAsOnGround", deviationData.side_one_setback_as_on_ground);
                            await customHandleChange("sideOneDeviationInSetBack", deviationData.side_one_setback_deviation_in_sq_mtrs);
                            await customHandleChange("sideOnePerOfSetbackDeviated", deviationData.side_one_setback_deviated_percentage);
                            await customHandleChange("sideOneBuildingLengths", deviationData.side_one_building_lengths);
                            await customHandleChange("sideOneDeviatedBAPerFloor", deviationData.side_one_deviated_builtup_area_per_floor);
                            await customHandleChange("sideOneNoOfFloors", deviationData.side_one_no_of_floors);
                            await customHandleChange("sideOneTotalDeviatedBuiltupArea", deviationData.side_one_total_deviated_builtup_area);

                            await customHandleChange("sideTwoBuildingPermitOrder", deviationData.side_two_building_permit_order);
                            await customHandleChange("sideTwoAsOnGround", deviationData.side_two_setback_as_on_ground);
                            await customHandleChange("sideTwoDeviationInSetBack", deviationData.side_two__setback_deviation_in_sq_mtrs);
                            await customHandleChange("sideTwoPerOfSetbackDeviated", deviationData.side_two_setback_deviated_percentage);
                            await customHandleChange("sideTwoBuildingLengths", deviationData.side_two_building_lengths);
                            await customHandleChange("sideTwoDeviatedBAPerFloor", deviationData.side_two_deviated_builtup_area_per_floor);
                            await customHandleChange("sideTwoNoOfFloors", deviationData.side_two_no_of_floors);
                            await customHandleChange("sideTwoTotalDeviatedBuiltupArea", deviationData.side_two_total_deviated_builtup_area);

                            await customHandleChange("sumOfTotalDeviatedBuiltupAreaInSqYards", 0);
                            await customHandleChange("landValuePerRegistrationDep", deviationData.land_value_per_registration_dep);
                            await customHandleChange("totalCompoundingFee", deviationData.total_compounding_fee);
                            await customHandleChange("totalBuiltUpAreaAsOnGround", deviationData.total_built_up_area_as_on_ground);

                            let grandTotal = parseFloat(deviationData ? deviationData.front_total_deviated_builtup_area : "0.0") + parseFloat(deviationData ? deviationData.rear_total_deviated_builtup_area : "0.0") + parseFloat(deviationData.side_one_total_deviated_builtup_area) + parseFloat(deviationData ? deviationData.side_two_total_deviated_builtup_area : "0.0");
                            customHandleChange('sumOfTotalDeviatedBuiltupArea', grandTotal.toFixed(2))
                            customHandleChange('sumOfTotalDeviatedBuiltupAreaInSqYards', convertToSqYards(grandTotal.toFixed(2), 2))
                        }


                    }




                })
        }
    }, [])

    const validateSchema = Yup.object().shape({
        // frontPerOfSetbackDeviated: Yup.number().max(0, 'not valid'),
        // rearPerOfSetbackDeviated: Yup.number().max(10, 'not valid'),
        // sideOnePerOfSetbackDeviated: Yup.number().max(10, 'not valid'),
        // sideTwoPerOfSetbackDeviated: Yup.number().max(10, 'not valid'),
        rearBuildingLengths: Yup.number().min(1, 'required'),
        sideOneBuildingLengths: Yup.number().min(1, 'required'),
        sideTwoBuildingLengths: Yup.number().min(1, 'required'),
        frontNoOfFloors: Yup.number().min(1, 'required'),
        rearNoOfFloors: Yup.number().min(1, 'required'),
        sideOneNoOfFloors: Yup.number().min(1, 'required'),
        sideTwoNoOfFloors: Yup.number().min(1, 'required'),
        landValuePerRegistrationDep: Yup.number().min(1, 'required'),
        totalCompoundingFee: Yup.number()
            .test(
                'not-less-than-old-compounding-fee',
                'cannot be less than old fee',
                function (value) {
                    if (question.order === "L2") {
                        return value >= DeviDBData.l1_total_compounding_fee
                    } else if (question.order === "L1") {
                        return value >= DeviDBData.total_compounding_fee
                    } else {
                        return true;
                    }
                }
            ),
    })


    const formik = useFormik({
        initialValues: {},
        validationSchema: validateSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setIsLoading(true);
            // setIsDeviationUpdated(false)


            var data = {};
            if (question.order === "LEVEL-2") {
                
                data = {
                    "l2_front_building_permit_order": values.frontBuildingPermitOrder,
                    "l2_front_setback_as_on_ground": values.frontAsOnGround,
                    "l2_front_setback_deviation_in_sq_mtrs": values.frontDeviationInSetBack,
                    "l2_front_setback_deviated_percentage": values.frontPerOfSetbackDeviated,
                    "l2_front_building_lengths": values.frontBuildingLengths,
                    "l2_front_side_deviated_builtup_area_per_floor": values.frontDeviatedBAPerFloor,
                    "l2_front_no_of_floors": values.frontNoOfFloors,
                    "l2_front_total_deviated_builtup_area": values.frontTotalDeviatedBuiltupArea,
                    "l2_rear_building_permit_order": values.rearBuildingPermitOrder,
                    "l2_rear_setback_as_on_ground": values.rearAsOnGround,
                    "l2_rear_setback_deviation_in_sq_mtrs": values.rearDeviationInSetBack,
                    "l2_rear_setback_deviated_percentage": values.rearPerOfSetbackDeviated,
                    "l2_rear_building_lengths": values.rearBuildingLengths,
                    "l2_rear_deviated_builtup_area_per_floor": values.rearDeviatedBAPerFloor,
                    "l2_rear_no_of_floors": values.rearNoOfFloors,
                    "l2_rear_total_deviated_builtup_area": values.rearTotalDeviatedBuiltupArea,
                    "l2_side_one_building_permit_order": values.sideOneBuildingPermitOrder,
                    "l2_side_one_setback_as_on_ground": values.sideOneAsOnGround,
                    "l2_side_one_setback_deviation_in_sq_mtrs": values.sideOneDeviationInSetBack,
                    "l2_side_one_setback_deviated_percentage": values.sideOnePerOfSetbackDeviated,
                    "l2_side_one_building_lengths": values.sideOneBuildingLengths,
                    "l2_side_one_deviated_builtup_area_per_floor": values.sideOneDeviatedBAPerFloor,
                    "l2_side_one_no_of_floors": values.sideOneNoOfFloors,
                    "l2_side_one_total_deviated_builtup_area": values.sideOneTotalDeviatedBuiltupArea,
                    "l2_side_two_building_permit_order": values.sideTwoBuildingPermitOrder,
                    "l2_side_two_setback_as_on_ground": values.sideTwoAsOnGround,
                    "l2_side_two__setback_deviation_in_sq_mtrs": values.sideTwoDeviationInSetBack,
                    "l2_side_two_setback_deviated_percentage": values.sideTwoPerOfSetbackDeviated,
                    "l2_side_two_building_lengths": values.sideTwoBuildingLengths,
                    "l2_side_two_deviated_builtup_area_per_floor": values.sideTwoDeviatedBAPerFloor,
                    "l2_side_two_no_of_floors": values.sideTwoNoOfFloors,
                    "l2_side_two_total_deviated_builtup_area": values.sideTwoTotalDeviatedBuiltupArea,
                    "l2_land_value_per_registration_dep": values.landValuePerRegistrationDep,
                    "l2_total_compounding_fee": values.totalCompoundingFee,
                    "l2_total_built_up_area_as_on_ground": values.totalBuiltUpAreaAsOnGround,
                    'total_deviated_percentage': values.totalDeviationPercentage,
                    is_deviation_updated: values.totalCompoundingFee > 0 ? true : false,

                    is_deviation_submitted: true

                }
            } else if (question.order == "LEVEL-1") {
                data = {
                    "l1_front_building_permit_order": values.frontBuildingPermitOrder,
                    "l1_front_setback_as_on_ground": values.frontAsOnGround,
                    "l1_front_setback_deviation_in_sq_mtrs": values.frontDeviationInSetBack,
                    "l1_front_setback_deviated_percentage": values.frontPerOfSetbackDeviated,
                    "l1_front_building_lengths": values.frontBuildingLengths,
                    "l1_front_side_deviated_builtup_area_per_floor": values.frontDeviatedBAPerFloor,
                    "l1_front_no_of_floors": values.frontNoOfFloors,
                    "l1_front_total_deviated_builtup_area": values.frontTotalDeviatedBuiltupArea,
                    "l1_rear_building_permit_order": values.rearBuildingPermitOrder,
                    "l1_rear_setback_as_on_ground": values.rearAsOnGround,
                    "l1_rear_setback_deviation_in_sq_mtrs": values.rearDeviationInSetBack,
                    "l1_rear_setback_deviated_percentage": values.rearPerOfSetbackDeviated,
                    "l1_rear_building_lengths": values.rearBuildingLengths,
                    "l1_rear_deviated_builtup_area_per_floor": values.rearDeviatedBAPerFloor,
                    "l1_rear_no_of_floors": values.rearNoOfFloors,
                    "l1_rear_total_deviated_builtup_area": values.rearTotalDeviatedBuiltupArea,
                    "l1_side_one_building_permit_order": values.sideOneBuildingPermitOrder,
                    "l1_side_one_setback_as_on_ground": values.sideOneAsOnGround,
                    "l1_side_one_setback_deviation_in_sq_mtrs": values.sideOneDeviationInSetBack,
                    "l1_side_one_setback_deviated_percentage": values.sideOnePerOfSetbackDeviated,
                    "l1_side_one_building_lengths": values.sideOneBuildingLengths,
                    "l1_side_one_deviated_builtup_area_per_floor": values.sideOneDeviatedBAPerFloor,
                    "l1_side_one_no_of_floors": values.sideOneNoOfFloors,
                    "l1_side_one_total_deviated_builtup_area": values.sideOneTotalDeviatedBuiltupArea,
                    "l1_side_two_building_permit_order": values.sideTwoBuildingPermitOrder,
                    "l1_side_two_setback_as_on_ground": values.sideTwoAsOnGround,
                    "l1_side_two__setback_deviation_in_sq_mtrs": values.sideTwoDeviationInSetBack,
                    "l1_side_two_setback_deviated_percentage": values.sideTwoPerOfSetbackDeviated,
                    "l1_side_two_building_lengths": values.sideTwoBuildingLengths,
                    "l1_side_two_deviated_builtup_area_per_floor": values.sideTwoDeviatedBAPerFloor,
                    "l1_side_two_no_of_floors": values.sideTwoNoOfFloors,
                    "l1_side_two_total_deviated_builtup_area": values.sideTwoTotalDeviatedBuiltupArea,
                    "l1_land_value_per_registration_dep": values.landValuePerRegistrationDep,
                    "l1_total_compounding_fee": values.totalCompoundingFee,
                    "l1_total_built_up_area_as_on_ground": values.totalBuiltUpAreaAsOnGround,
                    "l1_side_two_total_deviated_builtup_area": values.sideTwoTotalDeviatedBuiltupArea,
                    "l1_land_value_per_registration_dep": values.landValuePerRegistrationDep,
                    "l1_total_compounding_fee": values.totalCompoundingFee,
                    "l1_total_built_up_area_as_on_ground": values.totalBuiltUpAreaAsOnGround,
                    'total_deviated_percentage': values.totalDeviationPercentage,
                    is_deviation_updated: values.totalCompoundingFee > 0 ? true : false,
                    is_deviation_submitted: true
                }
            }
            else {
                data = {
                    "l3_front_building_permit_order": values.frontBuildingPermitOrder,
                    "l3_front_setback_as_on_ground": values.frontAsOnGround,
                    "l3_front_setback_deviation_in_sq_mtrs": values.frontDeviationInSetBack,
                    "l3_front_setback_deviated_percentage": values.frontPerOfSetbackDeviated,
                    "l3_front_building_lengths": values.frontBuildingLengths,
                    "l3_front_side_deviated_builtup_area_per_floor": values.frontDeviatedBAPerFloor,
                    "l3_front_no_of_floors": values.frontNoOfFloors,
                    "l3_front_total_deviated_builtup_area": values.frontTotalDeviatedBuiltupArea,
                    "l3_rear_building_permit_order": values.rearBuildingPermitOrder,
                    "l3_rear_setback_as_on_ground": values.rearAsOnGround,
                    "l3_rear_setback_deviation_in_sq_mtrs": values.rearDeviationInSetBack,
                    "l3_rear_setback_deviated_percentage": values.rearPerOfSetbackDeviated,
                    "l3_rear_building_lengths": values.rearBuildingLengths,
                    "l3_rear_deviated_builtup_area_per_floor": values.rearDeviatedBAPerFloor,
                    "l3_rear_no_of_floors": values.rearNoOfFloors,
                    "l3_rear_total_deviated_builtup_area": values.rearTotalDeviatedBuiltupArea,
                    "l3_side_one_building_permit_order": values.sideOneBuildingPermitOrder,
                    "l3_side_one_setback_as_on_ground": values.sideOneAsOnGround,
                    "l3_side_one_setback_deviation_in_sq_mtrs": values.sideOneDeviationInSetBack,
                    "l3_side_one_setback_deviated_percentage": values.sideOnePerOfSetbackDeviated,
                    "l3_side_one_building_lengths": values.sideOneBuildingLengths,
                    "l3_side_one_deviated_builtup_area_per_floor": values.sideOneDeviatedBAPerFloor,
                    "l3_side_one_no_of_floors": values.sideOneNoOfFloors,
                    "l3_side_one_total_deviated_builtup_area": values.sideOneTotalDeviatedBuiltupArea,
                    "l3_side_two_building_permit_order": values.sideTwoBuildingPermitOrder,
                    "l3_side_two_setback_as_on_ground": values.sideTwoAsOnGround,
                    "l3_side_two__setback_deviation_in_sq_mtrs": values.sideTwoDeviationInSetBack,
                    "l3_side_two_setback_deviated_percentage": values.sideTwoPerOfSetbackDeviated,
                    "l3_side_two_building_lengths": values.sideTwoBuildingLengths,
                    "l3_side_two_deviated_builtup_area_per_floor": values.sideTwoDeviatedBAPerFloor,
                    "l3_side_two_no_of_floors": values.sideTwoNoOfFloors,
                    "l3_side_two_total_deviated_builtup_area": values.sideTwoTotalDeviatedBuiltupArea,
                    "l3_land_value_per_registration_dep": values.landValuePerRegistrationDep,
                    "l3_total_compounding_fee": values.totalCompoundingFee,
                    "l3_total_built_up_area_as_on_ground": values.totalBuiltUpAreaAsOnGround,
                    "l3_side_two_total_deviated_builtup_area": values.sideTwoTotalDeviatedBuiltupArea,
                    "l3_land_value_per_registration_dep": values.landValuePerRegistrationDep,
                    "l3_total_compounding_fee": values.totalCompoundingFee,
                    "l3_total_built_up_area_as_on_ground": values.totalBuiltUpAreaAsOnGround,
                    'total_deviated_percentage': values.totalDeviationPercentage,
                    is_deviation_updated: values.totalCompoundingFee > 0 ? true : false,
                    is_deviation_submitted: true
                }
            }

            const authToken = getAllLocalData().accessToken;
            if (authToken) {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                        'Accept': '*/*'
                    }
                };
                axios.put(`${environment.baseApi}/deviations/${id}`, data, config)
                    .then(async function (response) {

                        handleCurrentChange("directValue", "0000", '')
                        setIsLoading(false)
                        // window.location.reload()
                    })
            }
        }
    })


    const customHandleChange = (name, value) => {
        formik.setFieldValue(name, value);
    }

    const { values, errors, touched } = formik;
    console.log(errors, "errors");

    const convertToSqYards = (value, decimals = 3) => {
        if (value) return parseFloat(value * 1.196).toFixed(decimals);
        return 0;
    };

    const ModalComponent = () => {
        return (
            <Modal
                show={showPopUP}
                onHide={handleClose}
                centered
            >
                <Modal.Header
                    closeButton
                    style={{ borderBottom: '0 none', paddingBottom: '0px' }}
                />
                <Modal.Body style={{ paddingTop: '0px' }}>
                    <div className="px-2">Are you sure you want to make changes to the deviation table?</div>
                </Modal.Body>
                <Modal.Footer
                    style={{ borderTop: '0 none' }}
                    className="d-flex justify-content-between"
                >
                    <Button
                        className="btn btn-secondary"
                        onClick={() => setShowPopUp(false)}
                        style={{ width: '30%' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn-success"
                        onClick={() => {
                            formik.handleSubmit();
                            setShowPopUp(false);

                        }}
                        style={{ width: '60%' }}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    // console.log('valuesL2Cpmm', values)

    // Front
    useEffect(() => {
        const frontDeviationInSetBack = parseFloat(values.frontBuildingPermitOrder) - parseFloat(values.frontAsOnGround)
        customHandleChange('frontDeviationInSetBack', frontDeviationInSetBack > 0 ? frontDeviationInSetBack.toFixed(2) : "0.0")
        const perOfSetbackDeviated = frontDeviationInSetBack.toFixed(2) / parseFloat(values.frontBuildingPermitOrder) * 100;
        customHandleChange('frontPerOfSetbackDeviated', perOfSetbackDeviated > 0 ? perOfSetbackDeviated.toFixed(2) : "0.0")
    }, [values.frontBuildingPermitOrder, values.frontAsOnGround])

    useEffect(() => {
        const frontDeviatedBuildupArea = parseFloat(values.frontDeviationInSetBack) * parseFloat(values.frontBuildingLengths);
        customHandleChange('frontDeviatedBAPerFloor', frontDeviatedBuildupArea > 0 ? frontDeviatedBuildupArea.toFixed(2) : "0.0")
    }, [values.frontDeviationInSetBack, values.frontBuildingLengths])


    useEffect(() => {
        const frontTotalDeviatedBuildupArea = parseFloat(values.frontDeviatedBAPerFloor) * parseFloat(values.frontNoOfFloors);
        customHandleChange('frontTotalDeviatedBuiltupArea', frontTotalDeviatedBuildupArea > 0 ? frontTotalDeviatedBuildupArea.toFixed(2) : "0.0")
    }, [values.frontDeviatedBAPerFloor, values.frontNoOfFloors])
    useEffect(() => {
        // debugger
        const totalDeviationPercentage = parseFloat(values.sideOnePerOfSetbackDeviated) + parseFloat(values.rearPerOfSetbackDeviated) + parseFloat(values.frontPerOfSetbackDeviated) + parseFloat(values.sideTwoPerOfSetbackDeviated);
        customHandleChange('totalDeviationPercentage', totalDeviationPercentage.toFixed(2))
    }, [values.frontPerOfSetbackDeviated, values.sideOnePerOfSetbackDeviated, values.rearPerOfSetbackDeviated, values.sideTwoPerOfSetbackDeviated])
    // useEffect(() => {
    //     const totalDeviationPercentage = parseFloat(values.frontPerOfSetbackDeviated) + parseFloat(values.rearPerOfSetbackDeviated) + parseFloat(values.sideOnePerOfSetbackDeviated) + parseFloat(values.sideTwoPerOfSetbackDeviated) 
    // }, [values.frontPerOfSetbackDeviated,values.sideOnePerOfSetbackDeviated,values.rearPerOfSetbackDeviated,values.sideTwoPerOfSetbackDeviated])

    // Rear 
    useEffect(() => {
        const rearDeviationInSetBack = parseFloat(values.rearBuildingPermitOrder) - parseFloat(values.rearAsOnGround)
        customHandleChange('rearDeviationInSetBack', rearDeviationInSetBack > 0 ? rearDeviationInSetBack.toFixed(2) : "0.0")
        const perOfSetbackDeviated = rearDeviationInSetBack.toFixed(2) / parseFloat(values.rearBuildingPermitOrder) * 100;
        customHandleChange('rearPerOfSetbackDeviated', perOfSetbackDeviated > 0 ? perOfSetbackDeviated.toFixed(2) : "0.0")
    }, [values.rearBuildingPermitOrder, values.rearAsOnGround])

    useEffect(() => {
        const rearDeviatedBuildupArea = parseFloat(values.rearDeviationInSetBack) * parseFloat(values.rearBuildingLengths);
        customHandleChange('rearDeviatedBAPerFloor', rearDeviatedBuildupArea > 0 ? rearDeviatedBuildupArea.toFixed(2) : "0.0")
    }, [values.rearDeviationInSetBack, values.rearBuildingLengths])

    useEffect(() => {
        const rearTotalDeviatedBuildupArea = parseFloat(values.rearDeviatedBAPerFloor) * parseFloat(values.rearNoOfFloors);
        customHandleChange('rearTotalDeviatedBuiltupArea', rearTotalDeviatedBuildupArea > 0 ? rearTotalDeviatedBuildupArea.toFixed(2) : "0.0")
    }, [values.rearDeviatedBAPerFloor, values.rearNoOfFloors])

    // Side1
    useEffect(() => {
        const sideOneDeviationInSetBack = parseFloat(values.sideOneBuildingPermitOrder) - parseFloat(values.sideOneAsOnGround)
        customHandleChange('sideOneDeviationInSetBack', sideOneDeviationInSetBack > 0 ? sideOneDeviationInSetBack.toFixed(2) : "0.0")
        const perOfSetbackDeviated = sideOneDeviationInSetBack.toFixed(2) / parseFloat(values.sideOneBuildingPermitOrder) * 100;
        customHandleChange('sideOnePerOfSetbackDeviated', perOfSetbackDeviated > 0 ? perOfSetbackDeviated.toFixed(2) : "0.0")
    }, [values.sideOneBuildingPermitOrder, values.sideOneAsOnGround])

    useEffect(() => {
        const sideOneDeviatedBuildupArea = parseFloat(values.sideOneDeviationInSetBack) * parseFloat(values.sideOneBuildingLengths);
        customHandleChange('sideOneDeviatedBAPerFloor', sideOneDeviatedBuildupArea > 0 ? sideOneDeviatedBuildupArea.toFixed(2) : "0.0")
    }, [values.sideOneDeviationInSetBack, values.sideOneBuildingLengths])

    useEffect(() => {
        const sideOneTotalDeviatedBuildupArea = parseFloat(values.sideOneDeviatedBAPerFloor) * parseFloat(values.sideOneNoOfFloors);
        customHandleChange('sideOneTotalDeviatedBuiltupArea', sideOneTotalDeviatedBuildupArea > 0 ? sideOneTotalDeviatedBuildupArea.toFixed(2) : "0.0")
    }, [values.sideOneDeviatedBAPerFloor, values.sideOneNoOfFloors])

    // Side2
    useEffect(() => {
        const sideTwoDeviationInSetBack = parseFloat(values.sideTwoBuildingPermitOrder) - parseFloat(values.sideTwoAsOnGround)
        customHandleChange('sideTwoDeviationInSetBack', sideTwoDeviationInSetBack > 0 ? sideTwoDeviationInSetBack.toFixed(2) : "0.0")
        const perOfSetbackDeviated = sideTwoDeviationInSetBack.toFixed(2) / parseFloat(values.sideTwoBuildingPermitOrder) * 100;
        customHandleChange('sideTwoPerOfSetbackDeviated', perOfSetbackDeviated > 0 ? perOfSetbackDeviated.toFixed(2) : "0.0")
    }, [values.sideTwoBuildingPermitOrder, values.sideTwoAsOnGround])

    useEffect(() => {
        const sideTwoDeviatedBuildupArea = parseFloat(values.sideTwoDeviationInSetBack) * parseFloat(values.sideTwoBuildingLengths);
        customHandleChange('sideTwoDeviatedBAPerFloor', sideTwoDeviatedBuildupArea > 0 ? sideTwoDeviatedBuildupArea.toFixed(2) : "0.0")
    }, [values.sideTwoDeviationInSetBack, values.sideTwoBuildingLengths])

    useEffect(() => {
        const sideTwoTotalDeviatedBuildupArea = parseFloat(values.sideTwoDeviatedBAPerFloor) * parseFloat(values.sideTwoNoOfFloors);
        customHandleChange('sideTwoTotalDeviatedBuiltupArea', sideTwoTotalDeviatedBuildupArea > 0 ? sideTwoTotalDeviatedBuildupArea.toFixed(2) : "0.0")
    }, [values.sideTwoDeviatedBAPerFloor, values.sideTwoNoOfFloors])


    useEffect(() => {
        const grandTotal = parseFloat(values.frontTotalDeviatedBuiltupArea) + parseFloat(values.rearTotalDeviatedBuiltupArea) + parseFloat(values.sideOneTotalDeviatedBuiltupArea) + parseFloat(values.sideTwoTotalDeviatedBuiltupArea);
        customHandleChange('sumOfTotalDeviatedBuiltupArea', grandTotal.toFixed(2))
        customHandleChange('sumOfTotalDeviatedBuiltupAreaInSqYards', convertToSqYards(grandTotal.toFixed(2), 2))
    }, [values.frontTotalDeviatedBuiltupArea, values.rearTotalDeviatedBuiltupArea, values.sideOneTotalDeviatedBuiltupArea, values.sideTwoTotalDeviatedBuiltupArea])

    useEffect(() => {
        const totalCompoundingFee = parseFloat(values.sumOfTotalDeviatedBuiltupAreaInSqYards) * parseFloat(values.landValuePerRegistrationDep);
        console.log('fdsfsdfdstotalCompoundingFee', totalCompoundingFee)
        customHandleChange('totalCompoundingFee', totalCompoundingFee ? totalCompoundingFee.toFixed(2) : "0.0");
    }, [values.landValuePerRegistrationDep, values.sumOfTotalDeviatedBuiltupAreaInSqYards])

    const CheckPermissionLevel = () => {
        if (permissionLevel == PERMISSIONS_LEVELS.COMMISSIONER || permissionLevel == PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER || permissionLevel == PERMISSIONS_LEVELS.FINAL_APPROVING_OFFICER) {
            return false

        } else {
            return true
        }
    }
    const handleShowDeviationPopup = () => {
        setShowPopUp(true)
    }
    return (
        isDevLoading ?
            <h3>Loading...</h3> :
            <Form className="login-form-type" autoComplete="off">
                <div className="input-group" style={{ width: "900px !important" }}>
                    <Table bordered className="devi-table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Set Backs</th>
                                <th>As per Building Permit Order (in Mts)</th>
                                <th>As on ground (in Mts)</th>
                                <th>Deviation in Set Back (in mts)</th>
                                <th>% of Setback Deviated</th>
                                <th>Building Lengths (in Sq Mts)</th>
                                <th>Deviated Builtup Area per Floor (in Sq. mts)</th>
                                <th>No. of Floors</th>
                                <th>Total Deviated Builtup Area (in Sq. mts.)</th>
                                <th>Land Value per Sq. Yd as per Registration Dept., (In Rs.)</th>
                                <th>Total Compounding Fee (In Rs.)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>1</th>
                                <th>Front</th>
                                <th>
                                    <Form.Control
                                        type="number"
                                        name="frontBuildingPermitOrder"
                                        placeholder="XXX"
                                        readOnly={isDeviationUpdated}
                                        className={errors.frontBuildingPermitOrder && touched.frontBuildingPermitOrder && 'has-error'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.frontBuildingPermitOrder ? values.frontBuildingPermitOrder : ""} />
                                    {errors.frontBuildingPermitOrder && touched.frontBuildingPermitOrder && <p>{errors.frontBuildingPermitOrder}</p>}
                                </th>
                                <th   >
                                    <Form.Control
                                        type="number"
                                        readOnly={isDeviationUpdated}
                                        name="frontAsOnGround"
                                        className={errors.frontAsOnGround && touched.frontAsOnGround && 'has-error'}
                                        placeholder="XXX"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.frontAsOnGround ? values.frontAsOnGround : ""} />
                                    {errors.frontAsOnGround && touched.frontAsOnGround && <p>{errors.frontAsOnGround}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="number"
                                        name="frontDeviationInSetBack"
                                        className={errors.frontDeviationInSetBack && touched.frontDeviationInSetBack && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.frontDeviationInSetBack ? values.frontDeviationInSetBack : ""} />
                                    {errors.frontDeviationInSetBack && touched.frontDeviationInSetBack && <p>{errors.frontDeviationInSetBack}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="number"
                                        name="frontPerOfSetbackDeviated"
                                        className={errors.frontPerOfSetbackDeviated && touched.frontPerOfSetbackDeviated && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.frontPerOfSetbackDeviated ? values.frontPerOfSetbackDeviated : ""} />
                                    {errors.frontPerOfSetbackDeviated && touched.frontPerOfSetbackDeviated && <p>{errors.frontPerOfSetbackDeviated}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="frontBuildingLengths"
                                        className={errors.frontBuildingLengths && touched.frontBuildingLengths && 'has-error'}
                                        placeholder="XXX"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.frontBuildingLengths ? values.frontBuildingLengths : ""} />
                                    {errors.frontBuildingLengths && touched.frontBuildingLengths && <p>{errors.frontBuildingLengths}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="frontDeviatedBAPerFloor"
                                        className={errors.frontDeviatedBAPerFloor && touched.frontDeviatedBAPerFloor && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.frontDeviatedBAPerFloor ? values.frontDeviatedBAPerFloor : ""} />
                                    {errors.frontDeviatedBAPerFloor && touched.frontDeviatedBAPerFloor && <p>{errors.frontDeviatedBAPerFloor}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="frontNoOfFloors"
                                        className={errors.frontNoOfFloors && touched.frontNoOfFloors && 'has-error'}
                                        // placeholder="XXX" 
                                        onChange={formik.handleChange}
                                        readOnly
                                        onBlur={formik.handleBlur}
                                        value={values.frontNoOfFloors ? values.frontNoOfFloors : ""} />
                                    {errors.frontNoOfFloors && touched.frontNoOfFloors && <p>{errors.frontNoOfFloors}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="frontTotalDeviatedBuiltupArea"
                                        className={errors.frontTotalDeviatedBuiltupArea && touched.frontTotalDeviatedBuiltupArea && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.frontTotalDeviatedBuiltupArea ? values.frontTotalDeviatedBuiltupArea : ""} />
                                    {errors.frontTotalDeviatedBuiltupArea && touched.frontTotalDeviatedBuiltupArea && <p>{errors.frontTotalDeviatedBuiltupArea}</p>}
                                </th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>2</th>
                                <th>Rear</th>
                                <th>
                                    <Form.Control
                                        type="number"
                                        readOnly={isDeviationUpdated}
                                        name="rearBuildingPermitOrder"
                                        placeholder="XXX"
                                        className={errors.rearBuildingPermitOrder && touched.rearBuildingPermitOrder && 'has-error'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.rearBuildingPermitOrder ? values.rearBuildingPermitOrder : ""} />
                                    {errors.rearBuildingPermitOrder && touched.rearBuildingPermitOrder && <p>{errors.rearBuildingPermitOrder}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="number"
                                        name="rearAsOnGround"
                                        className={errors.rearAsOnGround && touched.rearAsOnGround && 'has-error'}
                                        readOnly={isDeviationUpdated}
                                        placeholder="XXX"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.rearAsOnGround ? values.rearAsOnGround : ""} />
                                    {errors.rearAsOnGround && touched.rearAsOnGround && <p>{errors.rearAsOnGround}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="rearDeviationInSetBack"
                                        className={errors.rearDeviationInSetBack && touched.rearDeviationInSetBack && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.rearDeviationInSetBack ? values.rearDeviationInSetBack : ""} />
                                    {errors.rearDeviationInSetBack && touched.rearDeviationInSetBack && <p>{errors.rearDeviationInSetBack}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="rearPerOfSetbackDeviated"
                                        className={errors.rearPerOfSetbackDeviated && touched.rearPerOfSetbackDeviated && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.rearPerOfSetbackDeviated ? values.rearPerOfSetbackDeviated : ""} />
                                    {errors.rearPerOfSetbackDeviated && touched.rearPerOfSetbackDeviated && <p>{errors.rearPerOfSetbackDeviated}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="rearBuildingLengths"
                                        className={errors.rearBuildingLengths && touched.rearBuildingLengths && 'has-error'}
                                        placeholder="XXX"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.rearBuildingLengths ? values.rearBuildingLengths : ""} />
                                    {errors.rearBuildingLengths && touched.rearBuildingLengths && <p>{errors.rearBuildingLengths}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="rearDeviatedBAPerFloor"
                                        className={errors.rearDeviatedBAPerFloor && touched.rearDeviatedBAPerFloor && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.rearDeviatedBAPerFloor ? values.rearDeviatedBAPerFloor : ""} />
                                    {errors.rearDeviatedBAPerFloor && touched.rearDeviatedBAPerFloor && <p>{errors.rearDeviatedBAPerFloor}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="rearNoOfFloors"
                                        className={errors.rearNoOfFloors && touched.rearNoOfFloors && 'has-error'}
                                        onChange={formik.handleChange}
                                        readOnly
                                        onBlur={formik.handleBlur}
                                        value={values.rearNoOfFloors ? values.rearNoOfFloors : ""} />
                                    {errors.rearNoOfFloors && touched.rearNoOfFloors && <p>{errors.rearNoOfFloors}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="rearTotalDeviatedBuiltupArea"
                                        className={errors.rearTotalDeviatedBuiltupArea && touched.rearTotalDeviatedBuiltupArea && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.rearTotalDeviatedBuiltupArea ? values.rearTotalDeviatedBuiltupArea : ""} />
                                    {errors.rearTotalDeviatedBuiltupArea && touched.rearTotalDeviatedBuiltupArea && <p>{errors.rearTotalDeviatedBuiltupArea}</p>}
                                </th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>3</th>
                                <th>Side1</th>
                                <th>
                                    <Form.Control
                                        readOnly={isDeviationUpdated}
                                        type="number"
                                        name="sideOneBuildingPermitOrder"
                                        placeholder="XXX"
                                        className={errors.sideOneBuildingPermitOrder && touched.sideOneBuildingPermitOrder && 'has-error'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sideOneBuildingPermitOrder ? values.sideOneBuildingPermitOrder : ""} />
                                    {errors.sideOneBuildingPermitOrder && touched.sideOneBuildingPermitOrder && <p>{errors.sideOneBuildingPermitOrder}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="number"
                                        name="sideOneAsOnGround"
                                        className={errors.sideOneAsOnGround && touched.sideOneAsOnGround && 'has-error'}
                                        placeholder="XXX"
                                        readOnly={isDeviationUpdated}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sideOneAsOnGround ? values.sideOneAsOnGround : ""} />
                                    {errors.sideOneAsOnGround && touched.sideOneAsOnGround && <p>{errors.sideOneAsOnGround}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="sideOneDeviationInSetBack"
                                        className={errors.sideOneDeviationInSetBack && touched.sideOneDeviationInSetBack && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sideOneDeviationInSetBack ? values.sideOneDeviationInSetBack : ""} />
                                    {errors.sideOneDeviationInSetBack && touched.sideOneDeviationInSetBack && <p>{errors.sideOneDeviationInSetBack}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="sideOnePerOfSetbackDeviated"
                                        className={errors.sideOnePerOfSetbackDeviated && touched.sideOnePerOfSetbackDeviated && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sideOnePerOfSetbackDeviated ? values.sideOnePerOfSetbackDeviated : ""} />
                                    {errors.sideOnePerOfSetbackDeviated && touched.sideOnePerOfSetbackDeviated && <p>{errors.sideOnePerOfSetbackDeviated}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="sideOneBuildingLengths"
                                        className={errors.sideOneBuildingLengths && touched.sideOneBuildingLengths && 'has-error'}
                                        placeholder="XXX"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sideOneBuildingLengths ? values.sideOneBuildingLengths : ""} />
                                    {errors.sideOneBuildingLengths && touched.sideOneBuildingLengths && <p>{errors.sideOneBuildingLengths}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="sideOneDeviatedBAPerFloor"
                                        className={errors.sideOneDeviatedBAPerFloor && touched.sideOneDeviatedBAPerFloor && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sideOneDeviatedBAPerFloor ? values.sideOneDeviatedBAPerFloor : ""} />
                                    {errors.sideOneDeviatedBAPerFloor && touched.sideOneDeviatedBAPerFloor && <p>{errors.sideOneDeviatedBAPerFloor}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="sideOneNoOfFloors"
                                        className={errors.sideOneNoOfFloors && touched.sideOneNoOfFloors && 'has-error'}
                                        onChange={formik.handleChange}
                                        readOnly
                                        onBlur={formik.handleBlur}
                                        value={values.sideOneNoOfFloors ? values.sideOneNoOfFloors : ""} />
                                    {errors.sideOneNoOfFloors && touched.sideOneNoOfFloors && <p>{errors.sideOneNoOfFloors}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="sideOneTotalDeviatedBuiltupArea"
                                        className={errors.sideOneTotalDeviatedBuiltupArea && touched.sideOneTotalDeviatedBuiltupArea && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sideOneTotalDeviatedBuiltupArea ? values.sideOneTotalDeviatedBuiltupArea : ""} />
                                    {errors.sideOneTotalDeviatedBuiltupArea && touched.sideOneTotalDeviatedBuiltupArea && <p>{errors.sideOneTotalDeviatedBuiltupArea}</p>}
                                </th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>4</th>
                                <th>Side2</th>
                                <th>
                                    <Form.Control
                                        type="number"
                                        name="sideTwoBuildingPermitOrder"
                                        readOnly={isDeviationUpdated}
                                        placeholder="XXX"
                                        className={errors.sideTwoBuildingPermitOrder && touched.sideTwoBuildingPermitOrder && 'has-error'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sideTwoBuildingPermitOrder ? values.sideTwoBuildingPermitOrder : ""} />
                                    {errors.sideTwoBuildingPermitOrder && touched.sideTwoBuildingPermitOrder && <p>{errors.sideTwoBuildingPermitOrder}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="number"
                                        name="sideTwoAsOnGround"

                                        className={errors.sideTwoAsOnGround && touched.sideTwoAsOnGround && 'has-error'}
                                        placeholder="XXX"
                                        readOnly={isDeviationUpdated}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sideTwoAsOnGround ? values.sideTwoAsOnGround : ""} />
                                    {errors.sideTwoAsOnGround && touched.sideTwoAsOnGround && <p>{errors.sideTwoAsOnGround}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="sideTwoDeviationInSetBack"
                                        className={errors.sideTwoDeviationInSetBack && touched.sideTwoDeviationInSetBack && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sideTwoDeviationInSetBack ? values.sideTwoDeviationInSetBack : ""} />
                                    {errors.sideTwoDeviationInSetBack && touched.sideTwoDeviationInSetBack && <p>{errors.sideTwoDeviationInSetBack}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="sideTwoPerOfSetbackDeviated"
                                        className={errors.sideTwoPerOfSetbackDeviated && touched.sideTwoPerOfSetbackDeviated && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sideTwoPerOfSetbackDeviated ? values.sideTwoPerOfSetbackDeviated : ""} />
                                    {errors.sideTwoPerOfSetbackDeviated && touched.sideTwoPerOfSetbackDeviated && <p>{errors.sideTwoPerOfSetbackDeviated}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        readOnly={isDeviationUpdated}
                                        name="sideTwoBuildingLengths"
                                        className={errors.sideTwoBuildingLengths && touched.sideTwoBuildingLengths && 'has-error'}
                                        placeholder="XXX"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sideTwoBuildingLengths ? values.sideTwoBuildingLengths : ""} />
                                    {errors.sideTwoBuildingLengths && touched.sideTwoBuildingLengths && <p>{errors.sideTwoBuildingLengths}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="sideTwoDeviatedBAPerFloor"
                                        className={errors.sideTwoDeviatedBAPerFloor && touched.sideTwoDeviatedBAPerFloor && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sideTwoDeviatedBAPerFloor ? values.sideTwoDeviatedBAPerFloor : ""} />
                                    {errors.sideTwoDeviatedBAPerFloor && touched.sideTwoDeviatedBAPerFloor && <p>{errors.sideTwoDeviatedBAPerFloor}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="sideTwoNoOfFloors"
                                        className={errors.sideTwoNoOfFloors && touched.sideTwoNoOfFloors && 'has-error'}
                                        onChange={formik.handleChange}
                                        readOnly
                                        onBlur={formik.handleBlur}
                                        value={values.sideTwoNoOfFloors ? values.sideTwoNoOfFloors : ""} />
                                    {errors.sideTwoNoOfFloors && touched.sideTwoNoOfFloors && <p>{errors.sideTwoNoOfFloors}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="sideTwoTotalDeviatedBuiltupArea"
                                        className={errors.sideTwoTotalDeviatedBuiltupArea && touched.sideTwoTotalDeviatedBuiltupArea && 'has-error'}
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sideTwoTotalDeviatedBuiltupArea ? values.sideTwoTotalDeviatedBuiltupArea : ""} />
                                    {errors.sideTwoTotalDeviatedBuiltupArea && touched.sideTwoTotalDeviatedBuiltupArea && <p>{errors.sideTwoTotalDeviatedBuiltupArea}</p>}
                                </th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>

                                <th></th>
                                {/* <th>
                                    <Form.Control
                                        type="type"
                                        name="sumOfTotalDeviatedBuiltupArea"
                                        // parseFloat(values.sideOnePerOfSetbackDeviated) + parseFloat(values.rearPerOfSetbackDeviated) + parseFloat(values.frontPerOfSetbackDeviated) + parseFloat(values.sideTwoPerOfSetbackDeviated);

                                        // className={errors.sumOfTotalDeviatedBuiltupArea && touched.sumOfTotalDeviatedBuiltupArea && 'has-error'}
                                        placeholder="XXXX"
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.totalDeviationPercentage ? values.totalDeviationPercentage : ""} />
                                    {values.totalDeviationPercentage && <span>{values.totalDeviationPercentage}  (%)</span>}
                                    {errors.sumOfTotalDeviatedBuiltupArea && touched.sumOfTotalDeviatedBuiltupArea && <p>{errors.sumOfTotalDeviatedBuiltupArea}</p>}
                                </th> */}
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="sumOfTotalDeviatedBuiltupArea"
                                        className={errors.sumOfTotalDeviatedBuiltupArea && touched.sumOfTotalDeviatedBuiltupArea && 'has-error'}
                                        placeholder="XXXX"
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.sumOfTotalDeviatedBuiltupArea ? values.sumOfTotalDeviatedBuiltupArea : ""} />
                                    {values.sumOfTotalDeviatedBuiltupAreaInSqYards && <span>{values.sumOfTotalDeviatedBuiltupAreaInSqYards} Sq.Yds</span>}
                                    {errors.sumOfTotalDeviatedBuiltupArea && touched.sumOfTotalDeviatedBuiltupArea && <p>{errors.sumOfTotalDeviatedBuiltupArea}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        readOnly={isDeviationUpdated}
                                        name="landValuePerRegistrationDep"
                                        className={errors.landValuePerRegistrationDep && touched.landValuePerRegistrationDep && 'has-error'}
                                        placeholder="XXXX"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.landValuePerRegistrationDep ? values.landValuePerRegistrationDep : ""} />
                                    {errors.landValuePerRegistrationDep && touched.landValuePerRegistrationDep && <p>{errors.landValuePerRegistrationDep}</p>}
                                </th>
                                <th>
                                    <Form.Control
                                        type="type"
                                        name="totalCompoundingFee"
                                        className={errors.totalCompoundingFee && touched.totalCompoundingFee && 'has-error'}
                                        placeholder="XXXX"
                                        readOnly
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.totalCompoundingFee ? values.totalCompoundingFee : ""} />
                                    {errors.totalCompoundingFee && touched.totalCompoundingFee && <p>{errors.totalCompoundingFee}</p>}
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div>
                    {renderButton() && (
                        <Button onClick={handleShowDeviationPopup} disabled={isLoading}>
                            Update Deviation
                            {isLoading && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </Button>
                    )}

                    {ModalComponent()}
                </div>
            </Form>
    )
}