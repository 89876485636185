import { gql } from '@apollo/client';

export const CREATE_COMMENT = gql`
mutation createComment(
  $applicationId: ID!
  $comment: String!
  $attachmentUrl: String
  $mediaId: ID

) {
  createComment(
    applicationId: $applicationId
    comment: $comment
    attachmentUrl: $attachmentUrl
    mediaId: $mediaId
  
  )
}
`;
