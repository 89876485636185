import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import index from "../LoadingScreen";

const CustomDonutChart = ({ title, data, colors }) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    setSeries(data.map(o => o.value));
    setOptions({
      chart: {
        width: 300,
        type: 'donut',
      },
      labels: data.map(o => o.label),
      colors,
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'gradient',
      },
      legend: {
        show: false,
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex]
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    });
  }, [])

  return (
    <Card className="custom-bar-container custom-card">
      <Card.Body>
        <Card.Title className="Neutra-Demi font-size-16">{title}</Card.Title>
        <div className="d-flex flex-row justify-content-between">
          <ReactApexChart style={{ alignSelf: "center", }} options={options} series={series} type="donut" width={190} />
          <div>
            {data.map((item, index) => (
              <ItemCard
                key={index}
                label={item.label}
                value={item.value}
                style={{ color: colors[index], fontSize: index == 0 ? 30 : 23 }}
              />
            ))}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export const ItemCard = (props) => {
  return (
    <div className="d-flex justify-content-between align-items-center mt-2 Neutra-Demi chart-item-card">
      <span className="chart-item-card-label">{props.label}</span>
      <span style={props.style}>{props.value}</span>
    </div>
  );
};

export default CustomDonutChart;
