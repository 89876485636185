import React from 'react';
import './App.scss';
import { Router } from 'react-router-dom';
import history from './services/history';
import Routes from './router/index';
import { ContextProvider } from './AppContext';

function App() {
  return (
    <ContextProvider>
      <Router history={history} className="App">
        <Routes />
      </Router>
    </ContextProvider>
  

  
  );
}

export default App;
