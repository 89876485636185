import React from "react";
import history from '../services/history';

const ChasingCellBackButton = () => {
  return (
    <div onClick={() => history.goBack()} className="d-inline-flex align-items-center cursor-pointer">
      <img src={require('../assets/images/dashboard-new/left-arrow.png')} />
      <span>
        {'Back'}
      </span>
    </div>
  );
}

export default ChasingCellBackButton;
