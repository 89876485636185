import React from 'react'
import "./loader.css"
function Loader() {
  return (
   
      <div class="loader"></div>
   
  )
}

export default Loader
