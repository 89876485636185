import React from 'react'
import { useState } from 'react'
import AdditionlConditions from './additionlConditions';
import CustomCondtions from './customCondtions';
import GeneralCondtions from './generalCondtions';
import "./index.scss"
function FeeConditions({ applicationIdentifier, setIsFeeConditionsSubmit }) {
  const [activeTab, setActiveTab] = useState('AdditionalConditions')

  const handleTabs = (type) => {
    setActiveTab(type);
  };
  return (
    <>
      <div className="tv-header-tabs1">
        {/* {(<div   style={{minWidth:"200px"}}
                    className={`tab-item ${activeTab === 'generalConditions' ? 'active' : ''
                      }`}
                    onClick={() => handleTabs('generalConditions')}
                  >
                    General Conditions   </div>) } */}
        {(<div style={{ minWidth: "200px" }}
          className={`tab-item ${activeTab === 'AdditionalConditions' ? 'active' : ''
            }`}
          onClick={() => handleTabs('AdditionalConditions')}
        >

          Additional Conditions   </div>)}
        {(<div style={{ minWidth: "200px" }}
          className={`tab-item ${activeTab === 'CustomConditions' ? 'active' : ''
            }`}
          onClick={() => handleTabs('CustomConditions')}
        >
          Custom Conditions   </div>)}

      </div>
      <div>
        {/* {activeTab === 'generalConditions' && (
<GeneralCondtions/>
        )} */}
        {activeTab === 'AdditionalConditions' && (
          <AdditionlConditions setIsFeeConditionsSubmit={setIsFeeConditionsSubmit} applicationIdentifier={applicationIdentifier} />
        )}
        {activeTab === 'CustomConditions' && (
          <CustomCondtions />
        )}
      </div>
    </>

  )
}

export default FeeConditions