import { gql } from '@apollo/client';

export const GET_OFFICERS_FORM = gql`
  query getFormQuestions($id: ID!) {
    officersForm(id: $id) {
      id
      title
      fields {
        id
        title
        fieldType
        order
        dbField
        defaultValue
        children {
          id
          title
          fieldType
          dbField
          defaultValue
          children {
            id
            title
            fieldType
            dbField
            defaultValue
            children {
              id
              title
              fieldType
              dbField
              defaultValue
              children {
                id
                title
                fieldType
                dbField
                defaultValue
              }
            }
          }
        }
      }
    }
  }
`;
