export default {
  FILE_UPLOAD: {
    USECASE: 'FILE_UPLOAD',
    URL: '/api/v1/media/s3_signed_url',
  },
  MEDIA: {
    USECASE: 'MEDIA',
    URL: '/api/v1/media',
  },
};
