/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Table,
  Form,
  FormControl,
  Accordion,
  Collapse,
  Card,
  Spinner,
  Carousel,
  Col,
  Button,
  InputGroup,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import './ApplicationDetails.scss';

const ApplicationDetails = (props) => {
  return (
    <div className="applicationDetails-container d-flex justify-content-center">
      <div className="personal-info">
        <h3 className="plot-info-title">Personal Information</h3>
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="formFirstName">
              <Form.Label>First name</Form.Label>
              <Form.Control type="text" placeholder="Your firstname" />
            </Form.Group>
            <Form.Group as={Col} controlId="formLastName">
              <Form.Label>Last name</Form.Label>
              <Form.Control type="text" placeholder="Your lastname" />
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="formRelaitonship">
            <Form.Label>Relaitonship</Form.Label>
            <Form.Control as="select">
              <option>Son of</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Your name" />
          </Form.Group>

          <Form.Group controlId="formAadhar">
            <Form.Label>Aadhar</Form.Label>
            <Form.Control placeholder="---- / ---- / ---- / ----" />
          </Form.Group>

          <Form.Group controlId="formMobile">
            <Form.Label>Mobile Number</Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>+91</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="mobileNumber"
                type="tel"
                placeholder="Your Mobile number"
                pattern="[0-9]{10}"
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>
              Email Address <span className="optional-text">(Optional)</span>{' '}
            </Form.Label>
            <Form.Control type="email" />
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Label>Set Password </Form.Label>
            <Form.Control type="password" />
          </Form.Group>

          <Form.Group controlId="formConfirmPassword">
            <Form.Label>Confirm Password </Form.Label>
            <Form.Control type="password" />
          </Form.Group>

          <Form.Group controlId="formAddress">
            <Form.Label>Contact Address</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Type your address"
            />
          </Form.Group>

          <Button className="submit-btn" variant="primary" type="submit">
            Save and Continue
            <img src={require('../../assets/images/arrow.svg')} />
          </Button>
        </Form>
      </div>
    </div>
  );
};

// ReviewSubmit.propTypes = {
//     id: PropTypes.string,
// };

export default ApplicationDetails;
