/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useContext } from 'react';
import { Table, Card, Button, Form, Modal, InputGroup } from 'react-bootstrap';
import './index.scss';
import axios from 'axios';
import environment from 'environment';
import { getAllLocalData } from '../../services/localStorage';
import LoadingScreen from '../LoadingScreen';
import { useParams } from 'react-router';
import { SUBMIT_FINAL_REMARK_L2 } from '../../graphql/mutatution/submitForm';
import { FaEdit } from "react-icons/fa";
import { GET_APPLICATION_QUESTIONS } from '../../graphql/query/applicationQuestions';
import {
  GET_OFFICERS_APPLICATION,
  GET_APPLICATION_OFFICER_LEVEL,
} from '../../graphql/query/officersApplication';
import { FEE_DETAILS } from '../../graphql/mutatution/feeDetails';
import { UPDATE_APPLICATION_STATUS } from '../../graphql/mutatution/updateApplicationStatus';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import history from '../../services/history';
import SWFeeDetails from './sw-fee-details';

import { PERMISSIONS_LEVELS } from '../../util/helperFunctions';
import { optionalFeeHeads } from '../../util/mock';
import { AppContext } from './../../AppContext';
import FeeAdjustmentDetailsSW from '../ApplicationDetailComponent/FeeAjustmentDetailsSW';

function RemarkComponent({
  initialBeforeRemarks,
  funcToUpdateRemark,
  index,
  remarkWidth,
  showValidations,
  showValidateFeeBreakUp,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [remarks, setRemarks] = useState(
    initialBeforeRemarks[index]?.remarks || ''
  );

  return (
    <>
      {!remarks || remarks.length < 1 ? (
        <div
          style={{
            fontSize: '12px',
            width: remarkWidth || '118%',
            cursor: 'pointer',
            color:
              showValidations ||
                (showValidateFeeBreakUp &&
                  initialBeforeRemarks[index].newAmount !=
                  initialBeforeRemarks[index].amount)
                ? 'red'
                : 'black',
          }}
          onClick={handleShow}
        >
          <FaEdit color={
            showValidations ||
              (showValidateFeeBreakUp &&
                initialBeforeRemarks[index].newAmount >
                initialBeforeRemarks[index].amount)
              ? 'red'
              : 'black'} fontSize={
                showValidations ||
                  (showValidateFeeBreakUp &&
                    initialBeforeRemarks[index].newAmount >
                    initialBeforeRemarks[index].amount)
                  ? '20px'
                  : '15px'} />
        </div>
      ) : (
        <div
          style={{
            fontSize: '12px',
            width: remarkWidth || '118%',
            cursor: 'pointer',
          }}
          onClick={handleShow}
        >
          <img src={require('../../assets/images/edit.svg')} />
          {/* Remarks */}
          &nbsp;  &nbsp;  {remarks}
        </div>
      )}
      {/* {
        <div
          style={{
            color: '#000000',
            fontSize: '12px',
            wordWrap: 'anywhere',
          }}
        >
          {remarks}
        </div>
      } */}

      <Modal
        show={show}
        onHide={() => {
          setRemarks(initialBeforeRemarks[index]?.remarks || '');
          handleClose();
        }}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: '0 none', paddingBottom: '0px' }}
        />
        <Modal.Body style={{ paddingTop: '0px' }}>
          <div className="px-2">Remarks</div>
          <div className="p-2">
            <div className="form-group">
              <textarea
                className="form-control"
                rows="3"
                placeholder="Enter text here"
                value={remarks}
                onChange={(e) => {
                  setRemarks(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ justifyContent: 'flex-start', borderTop: '0 none' }}
        >
          <Button
            className="btn btn-success"
            onClick={() => {
              let remarkIndex = index;
              let cloneRemarks = [...initialBeforeRemarks];
              if (initialBeforeRemarks.length === 0) {
                remarkIndex = 0;
                cloneRemarks = [{}];
              }
              cloneRemarks[remarkIndex]['remarks'] = remarks;
              funcToUpdateRemark(cloneRemarks);
              handleClose();
            }}
            style={{ width: '120px' }}
          >
            ADD &nbsp; &nbsp;
            <img src={require('../../assets/images/arrow.svg')} alt="next" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function AddjustFee({
  adjustFeeArr,
  setAdjustFeeArr,
  index,
  countAdjustFee,
  setCountAdjustFee,
  showValidations,
  modifiedOptionalFeeHeads,
}) {
  const currAdjustFee = [...adjustFeeArr];
  const newObj = {
    cost_head_name: '',
    newAmount: '',
    amount: 0,
    type: 'adjusted-fee',
  };

  return (
    <div className="d-flex align-items-center">
      <InputGroup style={{ width: '60%' }}>
        {/* <Form.Control
          type="text"
          placeholder="Enter the fee head name"
          className="w-25 m-2"
          value={adjustFeeArr[index]?.cost_head_name}
          onChange={(e) => {
            newObj.cost_head_name = e.target.value;
            currAdjustFee[index] = {
              ...newObj,
              newAmount: adjustFeeArr[index]?.newAmount,
            };
            setAdjustFeeArr(currAdjustFee);
          }}
        /> */}
        <Form.Control
          as="select"
          value={adjustFeeArr[index]?.cost_head_name}
          onChange={(e) => {
            newObj.cost_head_name = e.target.value;
            currAdjustFee[index] = {
              ...newObj,
              newAmount: adjustFeeArr[index]?.newAmount || 0,
              remarks: adjustFeeArr[index]?.remarks,
            };
            setAdjustFeeArr(currAdjustFee);
          }}
          className="w-25 m-2 fee-head-dropdown"
        >
          <option value="">Select Type</option>
          {modifiedOptionalFeeHeads
            .sort((a, b) =>
              a.value === b.value ? 0 : a.value < b.value ? -1 : 1
            )
            .map((newFeeHeads, ind) => (
              <option
                disabled={
                  adjustFeeArr.filter(
                    (e) => e.cost_head_name === newFeeHeads.value
                  ).length > 0
                }
                key={`new-fee-heads-${ind}`}
                value={newFeeHeads.value}
              >
                {newFeeHeads.value}
              </option>
            ))}
        </Form.Control>
        <InputGroup.Prepend className="ml-2 my-2">
          <InputGroup.Text style={{ color: 'green' }}>
            {' '}
            &#8377;{' '}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type="number"
          className="my-2 mr-5"
          style={{
            width: '20px',
            color: showValidations || currAdjustFee ? 'red' : 'black',
          }}
          placeholder="Enter Amount"
          value={JSON.stringify(adjustFeeArr[index]?.newAmount) || ''}
          onChange={(e) => {
            newObj.newAmount =
              e.target.value < 0 || isNaN(e.target.value)
                ? 0
                : parseFloat(e.target.value);
            currAdjustFee[index] = {
              ...newObj,
              cost_head_name: adjustFeeArr[index]?.cost_head_name,
              remarks: adjustFeeArr[index]?.remarks,
            };
            setAdjustFeeArr(currAdjustFee);
          }}
        />
      </InputGroup>
      <div className="d-flex justify-content-between" style={{ width: '40%' }}>
        {/* <div
          style={{ color: '#0564AC', cursor: 'pointer' }}
          onClick={() => {
            console.log('opne the remark modal for additional fee');
          }}
        >
          + Add Remarks
        </div> */}
        <RemarkComponent
          initialBeforeRemarks={adjustFeeArr}
          funcToUpdateRemark={setAdjustFeeArr}
          index={index}
          remarkWidth={'50%'}
          showValidations={showValidations}
        />
        <img
          className="pr-1"
          src={require('../../assets/images/close.svg')}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (adjustFeeArr.length) {
              let adjustFeeArrClone = [...adjustFeeArr];
              adjustFeeArrClone.splice(index, 1);
              setAdjustFeeArr(adjustFeeArrClone);
              let countAdjustClone = [...countAdjustFee];
              countAdjustClone.splice(index, 1);
              setCountAdjustFee(countAdjustClone);
            }
          }}
        />
      </div>
    </div>
  );
}

export default function ScrutinyTableView(props) {
  const { permissionLevel } = useContext(AppContext);

  const [initialFeeBreakup, setInitialFeeBreakup] = useState([]);
  const [updatedfeebreakup, setUpdatedfeebreakup] = useState([]);
  const [subTotal, setSubTotal] = useState(0.0);
  const [isShowRemark, setIsShowRemark] = useState(false);
  const [showValidations, setShowValidations] = useState(false);
  const [showValidateFeeBreakUp, setShowValidateFeeBreakUp] = useState(false);

  // const [additionalFeeArr, setAdditionalFeeArr] = useState([]);
  const [additionalFeeTotal, setAdditionalFeeTotal] = useState(0.0);
  const [adjustFeeArr, setAdjustFeeArr] = useState([]);
  const [adjustFeeTotal, setAdjustFeeTotal] = useState(0.0);
  const [initiallyPaid, setInitiallyPaid] = useState(0.0);
  const [isFeeDetailsSubmitted, setIsFeeDetailsSubmitted] = useState(false);
  const [isStatusUpdated, setIsStatusUpdated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [RoadEror, setRoadError] = useState('')
  const [totalFeeFromAPI, setTotalFeeFromAPI] = useState();
  const [addNote, setAddNote] = useState('');
  const authToken = getAllLocalData().accessToken;
  const { id } = useParams();
  const [submitForm, { called, error }] = useMutation(SUBMIT_FINAL_REMARK_L2);
  const [feeDetailsMutation, { calledInFeeDetails, errorInFeeDetails }] =
    useMutation(FEE_DETAILS);
  const [updateStatusMutation, { calledInUpdateStatus, errorInUpdateStatus }] =
    useMutation(UPDATE_APPLICATION_STATUS);

  const [currentOfficerLevel, setCurrentOfficerLevel] = useState('');
  const [feeAdjustmentDetails, setFeeAdjustmentDetails] = useState(false);
  console.log(initialFeeBreakup)
  // const [
  //   getOfficerLevel,
  //   { loading: officerLevelLoading, data: officerLevelLazy },
  // ] = useLazyQuery(GET_APPLICATION_OFFICER_LEVEL);

  // if (
  //   !officerLevelLoading &&Submit
  //   officerLevelLazy &&
  //   officerLevelLazy.officersApplication &&
  //   officerLevelLazy.officersApplication.currentOfficerLevel
  // ) {
  //   if (currentOfficerLevel === '') {
  //     setCurrentOfficerLevel(
  //       officerLevelLazy.officersApplication.currentOfficerLevel
  //     );
  //   }
  // }

  const [countAddFee, setCountAddFee] = useState(['']);
  const [countAdjustFee, setCountAdjustFee] = useState(['']);
  const [show, setShow] = useState(false);
  const [existingSubTotal, setExistingSubTotal] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modifiedOptionalFeeHeads, setModifiedOptionalFeeHeads] = useState([]);
  const [isFeeHeadsdLoading, setIsFeeHeadsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [appType, setAppType] = useState('');
  const [errorDispaly, setErrorDispaly] = useState(false)
  const [roadImpcatError, setRoadImpactError] = useState(true)

  // const [disables, setDisableds] = useState(false)
  useEffect(() => {
    handleService();
  }, []);


  useEffect(() => {
    if (currentOfficerLevel === 'L1' && isFeeDetailsSubmitted) {
      history.push('/post-verification');
    }
    if (isFeeDetailsSubmitted && isStatusUpdated) {
      if (props.viewPage === 'single_window') {
        //window.location.reload();
      } else {
        history.push('/post-verification');
      }
    }
  }, [isFeeDetailsSubmitted, isStatusUpdated]);

  useEffect(() => {
    udpateAdjustFeeTotal();

  }, [adjustFeeArr]);

  const updateSubTotal = (updatedArray) => {

    try {
      let sumArray = updatedArray.map((item) =>
        !item.newAmount ? item.amount : item.newAmount
      );
      let total = parseFloat(
        sumArray.reduce((a, b) => {
          return (
            (isNaN(parseFloat(a)) ? 0 : parseFloat(a)) +
            (isNaN(parseFloat(b)) ? 0 : parseFloat(b))
          );
        }, 0)
      ).toFixed(2);
      console.log(total, "sumArray")
      setSubTotal(isNaN(total) ? 0 : total);
    } catch (e) {
      console.log(`error updating the subtotal ${e}`);
    }
  };
  const totalAmountPaid = (updatedArray) => {
    // debugger
    try {
      let amountPaid = updatedArray.filter(item => item.cost_head_name == "DTCP Scrutiny Fee" || item.cost_head_name == "Initial Processing Fees").map(item => item.newAmount);
      let total = parseFloat(
        amountPaid.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      ).toFixed(2);
      setInitiallyPaid(isNaN(total) ? 0 : total)
    } catch (e) {
      console.log(`error updating the subtotal ${e}`);
    }
  }
  const udpateAdjustFeeTotal = () => {
    try {
      let sumArray = adjustFeeArr.map((item) => item.newAmount);

      let total = parseFloat(
        sumArray.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      ).toFixed(2);
      setAdjustFeeTotal(isNaN(total) ? 0 : total);
    } catch (e) {
      console.log(`error updating the subtotal ${e}`);
    }
  };

  const fetchTotalAmount = () => {

    try {
      let total = ((parseFloat(adjustFeeTotal) + parseFloat(subTotal)) - parseFloat(initiallyPaid)).toFixed(
        2
      );
      return total
    } catch (e) {
      console.log(`error while fetching total ammount, ${e}`);
      return subTotal
    }
  };

  const isRemarksValidated = () => {

    let isValidated = true;
    adjustFeeArr.forEach((el) => {

      if (!el.remarks || el.remarks == '') {
        isValidated = false;
      }
    });
    return isValidated;
  };

  const isAdjustedNewFeeValidated = () => {
    let isValidated = true;
    adjustFeeArr.forEach((el) => {
      if (el.newAmount === 0) {

        isValidated = false;
      }
    });
    return isValidated;
  };

  const isRemarksValidatedForFeeBreakUp = () => {
    let isValidated = true;
    updatedfeebreakup.forEach((el) => {
      if (el && !el.remarks && el.newAmount != el.amount) {
        isValidated = false;
      }
    });
    return isValidated;
  };
  const adjustAmountValidted = () => {
    let isValidated = true;
    updatedfeebreakup.forEach((el) => {
      if (el && (!el.type || (el.type && el.type !== 'adjusted-fee'))) {
        if (el.newAmount < el.amount) {
          isValidated = false;
        }
      }
    });
    return isValidated;
  };

  const getStatusToUpdate = () => {
    const isBreakupUpdatedVal = isBreakupUpdated();
    let statusToUpdate = isBreakupUpdatedVal
      ? 'FEE_ADJUSTMENTS_SUBMITTED'
      : 'PROCEEDINGS_IN_PROGRESS';

    if (
      !isBreakupUpdatedVal &&
      currentOfficerLevel === 'L2' &&
      [PERMISSIONS_LEVELS.CP_OFFICER, PERMISSIONS_LEVELS.ACP_OFFICER].includes(
        permissionLevel
      )
    ) {
      statusToUpdate = 'ACCEPTED';
    }
    return statusToUpdate;
  };

  const submitFeeApplicationData = () => {
    const newFeeBreakUpWithNoSubtotal = updatedfeebreakup.filter(
      (item) => item.cost_head_name !== undefined
    );
    const newAdjustFeeArr = [];
    adjustFeeArr.map((item) => {
      const cloneAmount = { ...item };
      cloneAmount.amount = 0;
      cloneAmount.type = 'adjusted-fee';
      newAdjustFeeArr.push(cloneAmount);
    });

    const feeBreakupObj = [
      ...newFeeBreakUpWithNoSubtotal,
      ...newAdjustFeeArr,
    ].map((el) => {
      if (el && !el.remarks) {
        el.remarks = '';
      }
      if (!el.newAmount) {
        el.newAmount = 0;
      }
      if (el && el.amount) {
        el.amount = parseFloat(parseFloat(el.amount).toFixed(2));
      }
      if (el && el.newAmount) {
        el.newAmount = parseFloat(parseFloat(el.newAmount).toFixed(2));
      }
      return el;
    });
    const OfficerId = window.sessionStorage.getItem('id');
    feeDetailsMutation({
      variables: {
        applicationId: id,
        feeBreakup: feeBreakupObj,
        officerId: OfficerId,
      },
    })
      .then((res) => {
        setIsFeeDetailsSubmitted(true);
        setIsLoading(false);
        window.location.reload()
        // if (!errorInFeeDetails & !calledInFeeDetails) {
        //   history.push('/post-verification');
        // }
      })
      .catch((e) => {
        setErrMsg(e.message);
        setShowError(true);
      });

    let statusToUpdate = getStatusToUpdate();
    // if (currentOfficerLevel !== 'L1'  ) {
    //   updateStatusMutation({
    //     variables: {
    //       applicationId: id,
    //       finalRemark: '',
    //       status: statusToUpdate,
    //       eligibilityForRefund: 'false',
    //     },
    //   })
    //     .then((res) => {
    //       setIsStatusUpdated(true);
    //       setIsLoading(false);
    //       if (!errorInUpdateStatus & !calledInUpdateStatus) {
    //         console.log('success in 2nd mutation');
    //       }
    //     })
    //     .catch((e) => {
    //       setErrMsg(e.message);
    //       setShowError(true);
    //     });
    // }
  };



  const submitNote = () => {
    submitForm({
      variables: {
        formInstanceId: feeForm.id,
        finalRemark: addNote,
        status: 'RECOMMENDED',
      },
    })
      .then(() => {
        setIsStatusUpdated(true);
        // if (!error & !called) {
        //   history.push('/post-verification');
        // }
      })
      .catch((e) => {
        setErrMsg(e.message);
        setShowError(true);
      });
  };

  const handleService = () => {
    axios
      .get(`${environment.baseApi}/fee_details/${id}`, {
        headers: {
          Authorization: 'Bearer ' + authToken,
        },
      })
      .then((res) => {
        console.log(res, "Response");

        const {
          data: { calculated_fee_breakup, updated_fee_breakup, status },
        } = res?.data;

        if (status === "FEE_VERIFICATION") {
          let fee_breakup = (updated_fee_breakup && updated_fee_breakup.length > 0) ? updated_fee_breakup : calculated_fee_breakup;
          setFeeAdjustmentDetails(fee_breakup);
        }

        const calculatedFeeBreakup = [];
        calculated_fee_breakup.map((item) => {
          const cloneAmount = { ...item };
          // cloneAmount.newAmount = cloneAmount.amount;
          cloneAmount.type = 'preexisting-fee';
          calculatedFeeBreakup.push(cloneAmount);
        });
        let feeBreakup =
          updated_fee_breakup !== null && updated_fee_breakup.length !== 0
            ? updated_fee_breakup
            : calculatedFeeBreakup;

        updateSubTotal(feeBreakup);
        totalAmountPaid(feeBreakup)
        setInitialFeeBreakup([...feeBreakup]);
        setUpdatedfeebreakup([...feeBreakup]);
        const existingSum = [...feeBreakup].reduce((acc, curr) => {
          return acc + curr.amount;
        }, 0);
        setExistingSubTotal(existingSum.toFixed(2));
        const filterFeeHeads = optionalFeeHeads.filter((el) => {
          return feeBreakup.every(
            (elm) =>
              el.value.toLocaleLowerCase() !==
              elm.cost_head_name.toLocaleLowerCase()
          );
        });
        setModifiedOptionalFeeHeads(filterFeeHeads);
        setIsFeeHeadsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsFeeHeadsLoading(false);
      });
  };



  //  / console.log("countAdjustFee", countAdjustFee);

  const checkingFeeheads = () => {
    const datas = initialFeeBreakup.filter(item => item?.formula_string)
    if (datas.length != 0) {
      return true;
    } else {
      return false
    }
  }
  useEffect(() => {
    console.log(checkingFeeheads())
  })

  const { data: answersData } = useQuery(GET_APPLICATION_QUESTIONS, {
    fetchPolicy: 'network-only',
    variables: { id: id },
  });
  const { data: data1, error1 } = useQuery(GET_OFFICERS_APPLICATION, {
    variables: { id: id },
  });

  useEffect(() => {
    if (data1 && data1.officersApplication) {
      setAppType(data1.officersApplication.applicationType);
    }
  }, [data1]);

  let feeForm;
  if (answersData) {
    feeForm = answersData.applicationForms.find((form) =>
      form.stage.includes('FEE CERTIFICATION')
    );
  }

  const updateFeeValue = (index, value) => {
    const itemType = updatedfeebreakup[index].type;
    if (itemType && itemType !== 'adjusted-fee') {
      const re = /^[0-9]*(?:\.[0-9]*)?$/;
      if (!re.test(value)) {
        return;
      }
    }

    const updatedArray = [];
    updatedfeebreakup.forEach((el, i) => {
      if (index === i) {
        const newVal = { ...el };
        let floatVal = parseFloat(value);
        if (isNaN(floatVal)) {
          floatVal = '';
        }
        newVal['newAmount'] = floatVal;
        updatedArray.push(newVal);
      } else {
        updatedArray.push(el);
      }
    });

    setUpdatedfeebreakup(updatedArray);
    updateSubTotal(updatedArray);
    totalAmountPaid(updatedArray)
  };
  // console.log(updatedfeebreakup, 'updatedfeebreakup345');


  useState(() => {

  }, [RoadEror])
  // const [notesTab, setNotesTab] = useState(true);
  const checkPage = () => {
    if (props.viewPage === 'single_view') return true;
    else return false;
  };




  const disabledCheck = (s) => {
    if (s.cost_head_name == "Initial Processing Fees" || s.cost_head_name == "DTCP Scrutiny Fee" || s.cost_head_name == "HMDA Scrutinee Charges") {
      return true
    } else {
      return false
    }
  }


  function shouldRenderButton(data) {
    // ////debugger;
    if (data?.officersApplication?.isFeeSubmitted == null && data?.officersApplication?.isFeeChanged == null) {
      return true
    } else {
      return false
    }
  }

  const ObjSec = () => {
    console.log(updatedfeebreakup, 'updatedfeebreakup');
    if (updatedfeebreakup.some(e => e.cost_head_name == 'Road Impact Fees')) {

      updatedfeebreakup.map((s) => {
        if (s.cost_head_name == 'Road Impact Fees') {
          if (s.newAmount <= 0) {
            setErrMsg('Road impact fees Should be greater than 0');

          } else {
            setErrMsg('')
            handleShow();
          }
        }
      })

    } else {
      setErrMsg('')
      handleShow();
    }

  }


  const isBreakupUpdated = () => {
    let returnValue = false;
    // if (currentOfficerLevel === 'L2') {
    //   let isFeeUpdated = false;
    //   updatedfeebreakup.forEach((el) => {
    //     if (el.amount !== el.newAmount) {
    //       isFeeUpdated = true;
    //     }
    //   });
    //   returnValue = isFeeUpdated;
    //   if (returnValue) {
    //     return returnValue;
    //   }
    // }
    // returnValue = !(
    //   JSON.stringify(updatedfeebreakup) === JSON.stringify(initialFeeBreakup) &&
    //   adjustFeeArr.length === 0
    // );
    // returnValue = !(existingSubTotal === fetchTotalAmount());
    returnValue = fetchTotalAmount() > 0;

    return returnValue;
  };
  // const handleRemarksViewToggle = () => {
  //   setNotesTab(!notesTab);
  // };
  const data = () => {
    return (
      <>
        {isFeeHeadsdLoading ? (
          <Card>
            <div className="fee-heads-loader">
              <LoadingScreen />
            </div>
          </Card>
        ) : (
          feeAdjustmentDetails ?
            <SWFeeDetails feeAdjustmentDetails={feeAdjustmentDetails} applicationStatus={props.applicationStatus} />
            // <FeeAdjustmentDetailsSW id={id}  />
            :
            <div className="sw-fee-table">
              <Card className='Expandation_Tabel'>
                <Table striped hover >
                  <thead>
                    <tr scope="col">
                      <th style={{ minWidth: "180px" }} className="left-th" >
                        Fee Structure
                        {/* {!checkPage() && (S
                      <span>&nbsp; Application: 1417/ALAI/0005/2020</span>
                    )} */}
                      </th>

                      {checkingFeeheads() ? <th>Formula</th> : ""}
                      {checkingFeeheads() ? <th>Formula Values</th> : ""}
                      <th
                        scope="col"
                        style={{
                          textAlign: 'left',
                          paddingLeft: '66px !important',
                          width: '1%',
                        }}
                        className="d-none"
                      >
                        Amount
                      </th>
                      <th scope="col" style={
                        {
                          minWidth: "100px"
                        }
                      }>Adjusted</th>
                      <th scope="col" >Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {initialFeeBreakup &&
                      [...initialFeeBreakup]
                        .filter((item) => item.cost_head_name !== undefined)
                        .map((item, index) => {
                          return (
                            <tr key={index} id="chasing-row">

                              <td
                                style={{ verticalAlign: 'middle', textTransform: 'capitalize', minWidth: "200px" }}
                              >
                                {item.cost_head_name}
                              </td>
                              {checkingFeeheads() ? <td style={{ verticalAlign: 'middle', textTransform: 'capitalize', maxWidth: '180px' }}>
                                {item?.formula_string?.replaceAll('_', '') === 'rate' ? 'lumpsum amount' : item?.formula_string?.replaceAll('_', '')}
                              </td> : ""}
                              {checkingFeeheads() ? <td style={{ verticalAlign: 'middle', textTransform: 'capitalize', maxWidth: '180px' }}>
                                {item?.formula_values === 'rate' ? item.amount : item.formula_values}</td> : ""}
                              <td
                                className="w-100 right-td d-none"
                                style={{ verticalAlign: 'middle' }}
                              >

                                <div className="d-flex">
                                  {' '}
                                  <p style={{ fontSize: '12px' }}>&#8377;</p>
                                  <span style={{}}>
                                    {item.amount || 0}
                                  </span>
                                </div>
                              </td>
                              <td
                                style={{ verticalAlign: 'middle', minWidth: "180px" }}
                              >
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text style={{ color: '#0564AC', height: '25px' }}>
                                      {' '}
                                      &#8377;{' '}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>

                                  <Form.Control
                                    type="number"
                                    // disabled=   {(index.cost_head_name == "Initial Processing Fees") ? (true):false}
                                    disabled={disabledCheck(item)}

                                    readOnly={
                                      appType == 'OCCUPANCY' &&
                                      item.cost_head_name == 'Compounding fee'
                                    }
                                    style={{
                                      height: "25px",
                                      color:
                                        updatedfeebreakup[index]?.newAmount <
                                          updatedfeebreakup[index]?.amount &&
                                          showError &&
                                          (!updatedfeebreakup[index] ||
                                            updatedfeebreakup[index].type !==
                                            'adjusted-fee')
                                          ? 'red'
                                          : '',
                                    }}
                                    value={
                                      updatedfeebreakup[index]?.newAmount ===
                                        undefined
                                        ? updatedfeebreakup[index]?.amount
                                        : updatedfeebreakup[index]?.newAmount
                                    }
                                    onChange={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      updateFeeValue(index, e.target.value);
                                    }}
                                    onBlur={(e) => {
                                      if (e.target.value === '') {
                                        updateFeeValue(index, '0');
                                      }
                                    }}
                                  />
                                  {errorDispaly == true ? "cannot access this fied" : ""}
                                </InputGroup>
                              </td>

                              {isShowRemark ? (
                                <td
                                  style={{
                                    color: '#2C3832',
                                    fontSize: '12px',
                                    width: '10%',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <div className="d-flex">
                                    <img
                                      src={require('../../assets/images/edit.svg')}
                                    />
                                    <p
                                      style={{
                                        fontSize: '12px',

                                      }}
                                    >
                                      Remarks
                                    </p>
                                  </div>
                                </td>
                              ) : (
                                <td
                                  style={{
                                    color: '#0564AC',
                                    width: '20%',
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  {' '}
                                  <p
                                    style={{
                                      fontSize: '12px',
                                      width: '118%',
                                      cursor: 'pointer',
                                    }}
                                    type="button"
                                  >
                                    {updatedfeebreakup &&
                                      updatedfeebreakup.length && (
                                        <RemarkComponent
                                          initialBeforeRemarks={updatedfeebreakup}
                                          index={index}
                                          funcToUpdateRemark={
                                            setUpdatedfeebreakup
                                          }
                                          showValidateFeeBreakUp={
                                            showValidateFeeBreakUp
                                          }
                                        />
                                      )}
                                  </p>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
              </Card>
              {/* <Card className="newCard">
            <div className="d-flex justify-content-between mx-3 py-3">
              <span className="text-left">
                <b> Additional Fee </b>{' '}
              </span>
              <span>
                {' '}
                <b> Amount </b>
              </span>
            </div>

            {countAddFee.map((item, index) => {
              return (
                <AdditionalFees
                  key={index}
                  index={index}
                  additionalFeeArr={additionalFeeArr}
                  setAdditionalFeeArr={setAdditionalFeeArr}
                  udpateAddtionalFeeTotal={udpateAddtionalFeeTotal}
                  setCountAddFee={setCountAddFee}
                  countAddFee={countAddFee}
                />
              );
            })}
            <div>
              <p
                className="ml-3 py-3"
                style={{ color: '#0564AC', cursor: 'pointer' }}
                onClick={() => setCountAddFee([...countAddFee, ''])}
              >
                + Add Additional Fee
              </p>
            </div>
          </Card> */}

              <Card className="newCard">
                <div className="d-flex justify-content-between mx-3 py-3">
                  <span className="text-left">
                    <b>Other Fee Heads if any </b>
                  </span>
                  <div>
                    <p
                      className="mr-3"
                      style={{ color: '#0564AC', cursor: 'pointer', fontSize: "16px" }}
                      onClick={() => setCountAdjustFee([...countAdjustFee, ''])}
                    >
                      + Add New Fee Head
                    </p>
                  </div>
                </div>

                {countAdjustFee.map((item, index) => {
                  return (
                    <AddjustFee
                      key={index}
                      index={index}
                      style={{ fontSize: "15px" }}
                      adjustFeeArr={adjustFeeArr}
                      setAdjustFeeArr={setAdjustFeeArr}
                      udpateAdjustFeeTotal={udpateAdjustFeeTotal}
                      modifiedOptionalFeeHeads={modifiedOptionalFeeHeads}
                      countAdjustFee={countAdjustFee}
                      setCountAdjustFee={setCountAdjustFee}
                      showValidations={showValidations}
                    />
                  );
                })}
              </Card>
              {/* <tr>
            <td>
              <b className="total-amt-title">Total Amount : </b>
              <b className="total-amt">Rs {totalFee}</b>
            </td>
            <p></p>
            <td className="raise-request-td">
              <div className="raise-request">
                <p className="icon">
                  <a
                    href="http://tsbpass.com/support/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Raise a Request for Change Fee
                  </a>
                </p>
                <span>Click to send your request</span>
              </div>
            </td>
          </tr> */}
              <div className="px-4 pt-3">
                {/* <div className="d-flex justify-content-between p-2 d-none">
              <p style={{ color: '#9EABB5', fontSize: '14px' }}>
                Existing Total :
              </p>
              <p style={{ color: '#040E19' }}> &#8377; {existingSubTotal}</p>
            </div> */}
                <div className="d-flex justify-content-between p-2">
                  <p style={{ color: '#5555555', fontSize: '16px' }}>
                    Adjusted Total Fee Heads Amount :
                  </p>

                  <p style={{ color: '#5555555', fontSize: '16px' }}>  {subTotal} &#8377;</p>
                </div>
                {/* <div className="d-flex justify-content-between p-2">
              <p style={{ color: '#9EABB5', fontSize: '14px' }}>
                Adjusted Difference :
              </p>
              <p style={{ color: '#040E19' }}>
                {' '}
                &#8377; {Math.abs(existingSubTotal - subTotal)}
              </p>
            </div> */}
                <div className="d-flex justify-content-between p-2">
                  <p style={{ color: '#5555555', fontSize: '16px' }}>
                    Adjusted Other Fee Heads Amount  :
                  </p>
                  <p style={{ color: '#040E19', fontSize: '16px' }}>
                    {parseFloat(adjustFeeTotal).toFixed(2)}  &#8377;
                  </p>
                </div>
                <div className="d-flex justify-content-between p-2">
                  <p style={{ color: '#5555555', fontSize: '16px' }}>
                    Initial Amount paid
                  </p>
                  <p style={{ color: '#040E19', fontSize: '16px' }}>
                    -  {parseFloat(initiallyPaid).toFixed(2)}  &#8377;
                  </p>
                </div>

                {/* <div className="d-flex justify-content-between p-2">
              <p style={{ color: '#9EABB5', fontSize: '14px' }}>
                Additional Fee :
              </p>
              <p style={{ color: '#040E19' }}>
                {' '}
               {parseFloat(additionalFeeTotal).toFixed(2)}
              </p>
            </div> */}
              </div>
              <hr />
              <div className="d-flex justify-content-between px-4">
                <p
                  style={{
                    color: '#040E19',
                    fontSize: '16px',
                  }}
                  className="px-2"
                >
                  Total Amount to be Paid :
                </p>
                <p style={{ color: '#1C9A5B', fontSize: '18px' }}>
                  {' '}
                  {fetchTotalAmount()}   &#8377;

                </p>
              </div>
              <hr />
              {/* {notesTab ? (
            <span>
              <div className="bottom-section">
                <div>
                  <h6 type="button" onClick={() => handleRemarksViewToggle()}>
                    Add Notes
                  </h6>
                  <span>Click to add your comments</span>
                </div>
                <Button
                  className="Pre-Proceeding-btn"
                  onClick={() => {
                    if (checkPage()) submitNote();
                  }}
                >
                  {checkPage() ? 'SUBMIT' : 'PRE PROCEEDING CONDITION'}
                  &nbsp; &nbsp;
                  <img src={require('../../assets/images/arrow.svg')} />
                </Button>
              </div>
              <span>{errMsg}</span>
            </span>
          ) : (
            <div className="bottom-section1">
              <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label className="form-Label">Add Notes</Form.Label>
                  <img
                    type="button"
                    onClick={() => handleRemarksViewToggle()}
                    src={require('../../assets/images/close.svg')}
                  />
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Text here"
                    value={addNote}
                    onChange={(event) => setAddNote(event.target.value)}
                  />
                </Form.Group>
              </Form>
              <Button
                className="Pre-Proceeding-btn"
                onClick={() => {
                  if (checkPage()) submitNote();
                }}
              >
                {checkPage() ? 'SUBMIT' : 'PRE PROCEEDING CONDITION'}
                &nbsp; &nbsp;
                <img src={require('../../assets/images/arrow.svg')} />
              </Button>
              <span>{errMsg}</span>
            </div>
          )} */}

              <div
                className="btn-container px-4 mb-2"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <button className="btn">
                  <img
                    src={require('../../assets/images/arrowBackwards.svg')}
                    alt="previous"
                  />
                  &nbsp; &nbsp; BACK
                </button>
                <div className="d-flex flex-column">
                  {(showValidateFeeBreakUp && !isRemarksValidatedForFeeBreakUp()) ||
                    (showValidations && !isRemarksValidated()) ||
                    !adjustAmountValidted() ||

                    showError ? (
                    <p style={{ color: 'red', textAlign: 'center' }}>{errMsg}</p>
                  ) : null}
                  {/* {showError && (
                <p style={{ color: 'red', textAlign: 'center' }}>{errMsg}</p>
              )} */}
                  {!isLoading && shouldRenderButton(data1) == true &&
                    (
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          if (!isRemarksValidated()) {
                            setShowValidations(true);
                            setErrMsg('Please enter remarks');
                            return;
                          }
                          if (!isAdjustedNewFeeValidated()) {
                            setShowError(true);
                            setShowValidations(true);
                            setErrMsg('Please enter Other Fee Heads Amount');
                            return;
                          }
                          if (!isRemarksValidatedForFeeBreakUp()) {
                            setShowValidateFeeBreakUp(true);
                            setErrMsg('Please enter remarks');
                            return;
                          }
                          // if (!adjustAmountValidted()) {
                          //   setErrMsg('Adjusted Amount to be more than Amount');
                          //   setShowError(true);
                          //   return;
                          // }
                          ObjSec()
                        }}
                        style={{ marginRight: "50px" }}
                      >
                        SUBMIT  &nbsp; &nbsp;
                        <img
                          src={require('../../assets/images/arrow.svg')}
                          alt="next"
                        />
                      </button>
                    )}

                  {<p style={{ color: 'red', textAlign: 'center' }}>{errMsg || null}</p>}
                  {isLoading && (
                    <Button variant="secondary">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </Button>
                  )}
                </div>
              </div>
            </div>
        )}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header
            closeButton
            style={{ borderBottom: '0 none', paddingBottom: '0px' }}
          />
          <Modal.Body style={{ paddingTop: '0px' }}>
            <div className="px-2">
              {isBreakupUpdated()
                ? 'Are you sure you want to submit the fee changes?'
                : 'There are no fee changes'}
            </div>
          </Modal.Body>
          <Modal.Footer
            style={{ borderTop: '0 none' }}
            className="d-flex justify-content-between"
          >
            <Button
              className="btn btn-secondary"
              onClick={() => handleClose()}
              style={{ width: '30%' }}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-success"
              onClick={() => {
                props.setIsSubmit(false)
                setIsLoading(true);
                submitFeeApplicationData();
                handleClose();
              }}
              style={{ width: '60%' }}
            >
              {isBreakupUpdated()
                ? 'Recommend with fee changes'
                : 'Recommend without Fee changes'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  return <div className="overflow-auto">{data()}</div>;
}
