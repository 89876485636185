import {
  setAllLocalData,
  removeAllLocalData,
} from '../../services/localStorage';
import { GET_OFFICER_PROFILE } from '../query/officerProfile';
import { GET_OFFICER_LOGIN_STATUS } from '../query/officerLoginStatus';
import history from '../../services/history';

export const saveLoginState = (client, data) => {
  setAllLocalData(data);
  history.push('/post-verification');
  client.writeQuery({ query: GET_OFFICER_PROFILE, data: { Officer: data } });
  client.writeQuery({
    query: GET_OFFICER_LOGIN_STATUS,
    data: { isLoggedIn: true },
  });
};

export const removeLoginState = (client) => {
  removeAllLocalData();
  history.push('/login');
  client
    .resetStore()
    .then((res) => console.log('cleared the store successfully'))
    .catch((error) =>
      console.log('error occured while clearing the store', error)
    );
  // need to uncomment, is causing another api call hit after logout which is failing
};
// this file is functionally equivalent to mutation resolvers
