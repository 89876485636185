import React, { useState, memo, useContext, useEffect } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import './AnswersListComponent/index.scss';
import { PropTypes } from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { GET_APPLICATION_QUESTIONS } from '../graphql/query/applicationQuestions';
import Answer from './AnswersListComponent/Answer';
import ShortFallToggle from './ShortFallToggle';
import AddRemark from './AddRemark';
import { AppContext } from './../AppContext';
import {
  PERMISSIONS_LEVELS,
  getTranslatedToList,
} from '../util/helperFunctions';
import { CREATE_SHORTFALL } from '../graphql/mutatution/createShortfall';
import history from '../services/history';
import { SUBMIT_FINAL_REMARK_L2 } from '../graphql/mutatution/submitForm';

const AnswerListCommisioner = memo((props) => {
  const { data: answersData, loading } = useQuery(GET_APPLICATION_QUESTIONS, {
    fetchPolicy: 'network-only',
    variables: { id: props.id },
  });
  useEffect(() => {
    if (answersData && getDataToDisplay(answersData)?.applicationForms) {
      shouldNotesBeDisabled(getDataToDisplay(answersData).applicationForms);
    }
  }, [answersData]);
  const [open, setOpen] = useState(-1);
  // state to collect all L1 remarks to send in the proceed button
  const [remarks, setRemarks] = useState({});
  // Have to make initial state dynamic somehow

  const [shortFall, setShotFall] = useState({});
  const [mutationCreateShortfall] = useMutation(CREATE_SHORTFALL);
  const [mutationSubmitForm] = useMutation(SUBMIT_FINAL_REMARK_L2);
  const formItemIdsForL1 = [];

  useEffect(() => {
    updateShowRecommend(shortFall, props);
  }, [shortFall]);
  useEffect(() => {
    if (Object.keys(remarks).length === 3) {
      props.setShowTextArea(false);
    }
  }, [remarks]);

  useEffect(() => {
    // when there is 1 or more shortfall remarks added
    if (props.submitStatus && Object.keys(remarks).length !== 0) {
      Object.keys(remarks).map((item) => {
        mutationCreateShortfall({
          variables: {
            formInstanceId: item,
            remark: remarks[item],
          },
        })
          .then((data) => {
            console.log(data);
          })
          .catch((e) => {
            console.log(e);
          });
      });
    }

    // when there are one or more shortfall, RECOMMENDED or Not Recommend needs to be posted
    if (props.submitStatus && Object.keys(remarks).length > 0) {
      var noFormItemIdsForL1 = [];
      Object.keys(remarks).map((item) => {
        var status = '';
        if (remarks[item].length > 0) {
          status = 'NOT_RECOMMENDED';
        } else {
          status = 'RECOMMENDED';
        }
        mutationSubmitForm({
          variables: {
            formInstanceId: item,
            status: status,
            finalRemark: remarks[item] || '',
          },
        })
          .then((data) => {
            console.log(data);
          })
          .catch((e) => {
            console.log(e);
          });
        formItemIdsForL1.map((itemInL1) => {
          if (itemInL1 === item) {
            noFormItemIdsForL1.push(itemInL1);
          }
        });
      });
      let difference = formItemIdsForL1.filter(
        (x) => !noFormItemIdsForL1.includes(x)
      );
      difference.map((item) => {
        if (item) {
          mutationSubmitForm({
            variables: {
              formInstanceId: parseInt(item),
              status: 'RECOMMENDED',
              finalRemark: props.finalRemarks,
            },
          })
            .then((data) => {
              console.log(data);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    }

    // when there is no shortfall and RECOMMENDED or Not Recommend is selected
    if (
      props.submitStatus &&
      (props.recommendationStatus === 'RECOMMENDED' ||
        props.recommendationStatus === 'NOT_RECOMMENDED')
    ) {
      formItemIdsForL1.map((item) => {
        mutationSubmitForm({
          variables: {
            formInstanceId: parseInt(item),
            status: props.recommendationStatus,
            finalRemark: props.finalRemarks,
          },
        })
          .then((data) => {
            console.log(data);
          })
          .catch((e) => {
            console.log(e);
          });
      });
    }
    if (props.submitStatus) {
      history.push('/pre-scrutiny');
    }
  }, [props.submitStatus]);

  const { permissionLevel } = useContext(AppContext);
  const chevronFlip = (e) => {
    console.log(e, open);
    setOpen(e);
  };
  const getDataToDisplay = (ansData) => {
    if (!props.currentVerification || !ansData) {
      return ansData;
    } else {
      const appsForm = [...ansData?.applicationForms];
      const newFormsData = appsForm.filter(
        (el) => el.stage === props.currentVerification
      );
      return { applicationForms: newFormsData };
    }
  };

  const sortCompare = (a, b) => {
    if (!b?.order || parseInt(a?.order, 10) < parseInt(b?.order, 10)) {
      return -1;
    }
    if (!a?.order || parseInt(a?.order, 10) > parseInt(b?.order, 10)) {
      return 1;
    }
    return 0;
  };

  const updateShowRecommend = (shortFall, props) => {
    let showRec = true;
    Object.entries(shortFall).forEach((key, val) => {
      if (key[1]) {
        showRec = false;
      }
    });
    props.setShowRecommend(showRec);
  };

  const shouldNotesBeDisabled = (data) => {
    if (!data) {
      props.setIsDisabledNotes(false); //  allow notes,
      return;
    }
    // if site, title or technical this is logic
    if (
      [
        PERMISSIONS_LEVELS.SITE_VERIFICATION,
        PERMISSIONS_LEVELS.TITLE_VERIFICATION,
        PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION,
      ].includes(permissionLevel)
    ) {
      const statusObj = data.find(
        (obj) => obj.stage === permissionLevel.replace('_', ' ')
      );
      const isDisabled = statusObj.status !== 'IN_PROCESS';
      props.setIsDisabledNotes(isDisabled); //  allow notes,
      return;
    }
    // if commissioner, zonal commissioner
    if (
      [
        PERMISSIONS_LEVELS.COMMISSIONER,
        PERMISSIONS_LEVELS.ZONAL_COMMISSIONER,
        PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER,
      ].includes(permissionLevel)
    ) {
      let flag = true; // dont allow notes,
      const filterData = data.filter(
        (el) => !el.stage.includes('FEE CERTIFICATION')
      );
      filterData.map((item) => {
        if (['IN_PROCESS'].includes(item.stageState.status)) {
          flag = false; //  allow notes,
        }
      });
      props.setIsDisabledNotes(flag);
      return;
    }
    // else if cp, acp e.t.c
    let flag = true; // dont allow notes,
    data.map((item) => {
      if (['IN_PROCESS'].includes(item.stageState.status)) {
        flag = false; //  allow notes,
      }
    });
    props.setIsDisabledNotes(flag);
  };

  return (
    <Accordion defaultActiveKey="-1" onSelect={(e) => chevronFlip(e)}>
      {loading && (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      {getDataToDisplay(answersData) &&
        getDataToDisplay(answersData).applicationForms && (
          <>
            {getDataToDisplay(answersData)?.applicationForms.map(
              (formItem, indexForm) => {
                return formItem.stageState?.stageOfficers
                  .filter((el) => el.officerLevel === 'L1')
                  .map((item, id) => {
                    formItemIdsForL1.push(formItem.id);
                    if (
                      (props.isVerification && item.officerLevel !== 'L2') ||
                      !props.isVerification ||
                      permissionLevel === PERMISSIONS_LEVELS.COMMISSIONER ||
                      permissionLevel ===
                      PERMISSIONS_LEVELS.ZONAL_COMMISSIONER ||
                      permissionLevel === PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER
                    ) {
                      console.log(item.officerLevel);
                      return (
                        <div
                          style={{
                            boxShadow: '0px 1px 8px 1px lightgrey',
                            padding: '10px',
                            margin: '15px',
                          }}
                        >
                          <Card
                            className="answer-card"
                            key={indexForm + ':' + id}
                            style={{ height: 'auto' }}
                          >
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey={indexForm + ':' + id}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100vw',
                                }}
                              >
                                <div>
                                  <b>{formItem.title}</b>
                                  <br />
                                  Status:
                                  {formItem.status
                                    ? ' ' + formItem.status
                                    : ' Not started'}
                                  <br />
                                  {item.officerLevel && 'Officer Level:'}
                                </div>
                                <div>
                                  <div>
                                    {item.officerName}
                                    <br />
                                    {item.officerRole}
                                    <br />
                                    {item.officerLevel}
                                    <br />
                                  </div>

                                  <br />
                                </div>
                                <img
                                  src={require('../assets/images/dd-arrow.svg')}
                                  className={
                                    open ===  indexForm ? 'arrowUP' : null
                                  }
                                />
                              </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={indexForm + ':' + id}>
                              <Card.Body>
                                {(item.officerLevel === 'L1' ||
                                  permissionLevel ===
                                  PERMISSIONS_LEVELS.COMMISSIONER ||
                                  permissionLevel ===
                                  PERMISSIONS_LEVELS.ZONAL_COMMISSIONER ||
                                  permissionLevel ===
                                  PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER ||
                                  permissionLevel ===
                                  PERMISSIONS_LEVELS.SITE_VERIFICATION ||
                                  permissionLevel ===
                                  PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION ||
                                  permissionLevel ===
                                  PERMISSIONS_LEVELS.TITLE_VERIFICATION) && (
                                    <div className="answer-list">
                                      {/* SPREADING AND MAKING A NEW COPY OF ARRAY BECAUSE SORTING NOT ALLOWED (ARRAY IS FROZEN IN STRICT MODE) */}
                                      {[...formItem.fields]
                                        .sort(sortCompare)
                                        .map((fieldItem, indexField) => {
                                          if (
                                            formItem.stage === 'FEE CERTIFICATION'
                                          )
                                            return (
                                              <div key={indexField}>
                                                <h6  style={{fontSize:"13px"}}>Final Remark : </h6>
                                                <p  style={{fontSize:"13px"}}>
                                                  {
                                                    formItem.answer.data
                                                      .finalremark
                                                  }
                                                </p>
                                              </div>
                                            );
                                          else
                                            return (
                                              <Answer
                                                field={fieldItem}
                                                answer={
                                                  formItem?.answer?.data || null
                                                }
                                                key={indexField}
                                              />
                                            );
                                        })}
                                    </div>
                                  )}
                                {item.officerLevel === 'L2' && (
                                  <div>
                                    <h6>
                                      Final Remarks :
                                      {formItem.stageState.finalRemark ===
                                        null ||
                                        formItem.stageState.finalRemark.length ===
                                        undefined
                                        ? ' not answered'
                                        : ''}
                                    </h6>
                                    <p>{formItem.stageState.finalRemark}</p>
                                  </div>
                                )}
                              </Card.Body>
                            </Accordion.Collapse>
                            {props.showShortFall && (
                              <div key={indexForm + ':' + id}>
                                <ShortFallToggle
                                  index={indexForm + ':' + id}
                                  onShortFall={(isShowShortFallOpen) => {
                                    const shortfallInstance = { ...shortFall };
                                    shortfallInstance[
                                      indexForm
                                    ] = isShowShortFallOpen;
                                    setShotFall(shortfallInstance);
                                  }}
                                />
                                {shortFall && shortFall[indexForm] && (
                                  <AddRemark
                                    setFinalRemark={(e) => {
                                      if (e) {
                                        const remarksInstance = { ...remarks };
                                        remarksInstance[formItem.id] = e;
                                        setRemarks(remarksInstance);
                                        props.setShowRecommend(false);
                                      }
                                    }}
                                    remarkHeadingText={'Shortfall Remarks'}
                                    formId={formItem.id}
                                    hideProceedButton={true}
                                  />
                                )}
                                {/* add the proceed to button which will submit the shortfall */}
                              </div>
                            )}
                          </Card>
                        </div>
                      );
                    }
                  });
              }
            )}
            <div className="mb-3 p-3">
              {answersData.officersApplication.finalRemark && (
                <p>
                  Municipal Commissioner Remarks :{' '}
                  {getTranslatedToList(
                    answersData.officersApplication.finalRemark
                  )}
                </p>
              )}
            </div>
          </>
        )}
    </Accordion>
  );
});

AnswerListCommisioner.propTypes = {
  id: PropTypes.string,
  showShortFall: PropTypes.bool,
  currentVerification: PropTypes.string,
  isVerification: PropTypes.string,
  setShowRecommend: PropTypes.func,
  submitStatus: PropTypes.bool,
  recommendationStatus: PropTypes.string,
  setShowTextArea: PropTypes.func,
  finalRemarks: PropTypes.string,
  setIsDisabledNotes: PropTypes.func,
};

AnswerListCommisioner.defaultProps = {
  showShortFall: false,
  currentVerification: null,
  isVerification: false,
  setShowRecommend: () => { },
  submitStatus: false,
  recommendationStatus: '',
  setShowTextArea: () => { },
  finalRemarks: '',
};

AnswerListCommisioner.displayName = 'Verify and Proceed';

export default AnswerListCommisioner;
