import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Card, Carousel, Button, Modal } from 'react-bootstrap';
import history from '../../services/history';
import '../VerificationCards/index.scss';
import { useMutation } from '@apollo/client';
import { SAVE_ANSWERS_FORM, SUBMIT_FORM, SUBMIT_FORM_SW } from '../../graphql/mutatution/submitForm';
import Question from '../QuestionCards/Question/index';
import { AppContext } from './../../AppContext';
import AnswerComponent from '../AnswersListComponent/Answer';
import ErrorScreen from '../ErrorScreen';

function IAIRCarosualQuestionsBeforeShowcause({
    id,
    questionsData,
    formId,
    applicationIdentifier,
    isVerification,
    applicationType

}) {
    console.log(questionsData, 'questionsData')
    const [appIdentifier] = useState(applicationIdentifier || '');
    const [carousalIndex, setCarousalIndex] = useState(0);
    // const [submitForm, { called, errors }] = useMutation(SUBMIT_FORM);
    const [submitFormAnswers, { called, errors }] =
        useMutation(SUBMIT_FORM);

    const [SaveFormAnswers, { caled, error }] = useMutation(SAVE_ANSWERS_FORM);
    const [isLoading, setIsLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);
    const { permissionLevel, currentFlow } = useContext(AppContext);
    const [showPopUpAfterSubmit, setShowPopUpAfterSubmit] = useState(false);
    const handleClosePopUpAfterSubmit = () => setShowPopUpAfterSubmit(false);
    const handleShowAfterSubmit = () => setShowPopUpAfterSubmit(true);
    const [showAnsers, SetShowAnswers] = useState(false);

    const handleClosePopUpAnswers = () => SetShowAnswers(false);
    const handleShowOpenPopup = () => SetShowAnswers(true);

    const sortCompare = (a, b) => {
        if (!b.order || parseInt(a.order, 10) < parseInt(b.order, 10)) {
            return -1;
        }
        if (!a.order || parseInt(a.order, 10) > parseInt(b.order, 10)) {
            return 1;
        }
        return 0;
    };

    var officersFormData = [...questionsData.fields];
    var officersFormDataSorted = officersFormData.sort(sortCompare);
    var officersFormDataGrouped = [];
    const size = officersFormDataSorted.length;
    while (officersFormDataSorted.length > 0) {
        officersFormDataGrouped.push(officersFormDataSorted.splice(0, size));
    }

    const handleSelect = (selectedIndex, e) => {
        setCarousalIndex(selectedIndex);
    };
    // console.log(officersFormDataGrouped,'officersFormDataGrouped')
    const submitRecommendationForm = () => {
        setIsLoading(true);
        let dataBeforeSubmittion = { ...ansState.current };
        const OfficerId = window.sessionStorage.getItem('id');
        submitFormAnswers({
            variables: {
                formInstanceId: formId,
                data: dataBeforeSubmittion,
                status: 'RECOMMENDED',
                officerId: OfficerId,
                finalRemark: '',
            },
        })

            .then(() => {

                if (!errors & !called) {

                    window.location.reload()

                }
            })
            .catch((e) => {
                setIsLoading(false);
                setErrMsg(e.message);
                console.error(e);
            });
    };


    const handleSaveFormAnswers = () => {
        setIsLoading(true);
        let dataBeforeSubmittion = { ...ansState.current };
        const OfficerId = window.sessionStorage.getItem('id');
        SaveFormAnswers({
            variables: {
                formInstanceId: formId,
                data: dataBeforeSubmittion,
                status: 'RECOMMENDED',
                officerId: OfficerId,
                finalRemark: '',
            },
        })

            .then(() => {

                if (!error & !caled) {

                    SetShowAnswers(true)

                }
            })
            .catch((e) => {
                setIsLoading(false);
                setErrMsg(e.message);
                console.error(e);
            });
    }

    const handleSaveFormAnswers1 = () => {
        let dataBeforeSubmittion = { ...ansState.current };
        const OfficerId = window.sessionStorage.getItem('id');
        SaveFormAnswers({
            variables: {
                formInstanceId: formId,
                data: dataBeforeSubmittion,
                status: 'RECOMMENDED',
                officerId: OfficerId,
                finalRemark: '',
            },
        })

            .then(() => {

                if (!error & !caled) {
                }
            })
            .catch((e) => {
                setIsLoading(false);
                setErrMsg(e.message);
                console.error(e);
            });
    }

    const ModalComponent = () => {
        return (
            <Modal
                show={showPopUpAfterSubmit}
                onHide={handleClosePopUpAfterSubmit}
                centered
            >
                <Modal.Header
                    closeButton
                    style={{ borderBottom: '0 none', paddingBottom: '0px' }}
                />
                <Modal.Body style={{ paddingTop: '0px' }}>
                    <div className="px-2">Are you sure you want to submit?</div>
                </Modal.Body>
                <Modal.Footer
                    style={{ borderTop: '0 none' }}
                    className="d-flex justify-content-between"
                >
                    <Button

                        className="btn btn-secondary"
                        onClick={() => handleClosePopUpAfterSubmit()}
                        style={{ width: '30%' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn-success"
                        onClick={() => {
                            submitRecommendationForm();
                            handleClosePopUpAfterSubmit();
                        }}
                        style={{ width: '60%' }}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const SaveAnsersPopUp = () => {
        return (
            <Modal
                show={showAnsers}
                onHide={handleClosePopUpAnswers}
                centered
            >
                <Modal.Header
                    closeButton
                    style={{ borderBottom: '0 none', paddingBottom: '0px' }}
                />
                <Modal.Body style={{ paddingTop: '0px' }}>
                    <div className="px-2">your Answers are Saved Sucessfully !</div>
                </Modal.Body>
                <Modal.Footer
                    style={{ borderTop: '0 none' }}
                    className="d-flex justify-content-between"
                >

                    <Button
                        className="btn btn-success"
                        onClick={() => {
                            window.location.reload()
                        }}
                        style={{ width: '60%' }}
                    >
                        ok
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    // const  handleNExtfun = () => {

    // }
    // console.log(officersFormDataGrouped,"officersFormDataGrouped");
    const nextSlide = () => {
        handleSaveFormAnswers1()
        let duplicateIndex = carousalIndex + 1;
        const data = ansState.current;
        // console.log(officersFormDataGrouped[0][0]);
        if (duplicateIndex == officersFormDataGrouped.length) {

            var isValid = true;

            for (
                let cardIndex = 0;
                cardIndex < officersFormDataGrouped.length;
                cardIndex++
            ) {
                for (
                    let relativeQIndex = 0;
                    relativeQIndex < officersFormDataGrouped[cardIndex].length;
                    relativeQIndex++
                ) {

                    if (
                        !(
                            officersFormDataGrouped[cardIndex][relativeQIndex].dbField in data
                        ) ||
                        !data[officersFormDataGrouped[cardIndex][relativeQIndex].dbField]
                            .parent_answer ||
                        data[officersFormDataGrouped[cardIndex][relativeQIndex].dbField]
                            .parent_answer === '' ||
                        data[officersFormDataGrouped[cardIndex][relativeQIndex].dbField]
                            .parent_answer === null
                    ) {

                        setIsSubmittedOnce(true);
                        isValid = false;
                        duplicateIndex = cardIndex;
                        break;
                    }
                }
                if (!isValid) break;
            }

            if (isValid) {
                duplicateIndex--;
                handleShowAfterSubmit();
            }
        }
        return duplicateIndex % Math.ceil(officersFormDataGrouped.length);
    };
    const prevSlide = () => {
        let duplicateIndex = carousalIndex - 1;
        if (duplicateIndex === -1) {
            duplicateIndex = officersFormDataGrouped.length - 1;
        }
        return duplicateIndex;
    };

    const remarksAndSubmit = (carousalIndex) => {
        return (
            <>
                <div
                    className="btn-container"
                    style={{ display: 'flex', marginLeft: "20%", marginTop: "30px" }}
                >
                    {/* <button
                       id='buttonback'
                        className={carousalIndex === 0 ? 'd-none' : 'btn btn-light '}
                        onClick={() => handleSelect(prevSlide())}
                    >
                        <img
                            src={require('../../assets/images/arrowBackwards.svg')}
                            alt="previous"
                        />
                        BACK
                    </button> */}
                    {!isLoading && (
                        <>
                            <div className="d-flex">
                                <Button
                                    variant="outline-success"
                                    onClick={handleSaveFormAnswers} style={{ width: "100px" }} className='btn btn-success'>
                                    {"Save"}

                                    <img
                                        src={require('../../assets/images/arrow.svg')}
                                        alt="next"
                                    />
                                </Button>
                                <Button
                                    variant="outline-success"
                                    style={{ width: "180px", marginLeft: "100px" }}
                                    id="buttonRight"
                                    className={
                                        carousalIndex === 0
                                            ? 'btn btn-success btn-firstPage'
                                            : 'btn btn-success '
                                    }
                                    onClick={() => {
                                        handleSelect(nextSlide());

                                    }}
                                >
                                    {/* {carousalIndex === officersFormDataGrouped.length - 1 */}
                                    {'SUBMIT'}
                                    {/* : 'Save & Continue'} */}
                                    <img
                                        src={require('../../assets/images/arrow.svg')}
                                        alt="next"
                                    />
                                </Button>
                            </div>
                        </>
                    )}
                    {isLoading && (
                        <Button variant="secondary">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </Button>
                    )}
                    <span>{errMsg == 'You need to authenticate to perform this action' ? <ErrorScreen message={error} /> : "" || null}</span>
                </div>
            </>
        );
    };

    const showIAIRCarosualQuestionsBeforeShowcause = () => {
        return (
            <Carousel
                className='Caurosle__hidding'
                activeIndex={carousalIndex}
                controls={false}
                onSelect={handleSelect}
                interval={null}
            >
                {officersFormDataGrouped.map((cardData, cardIndex) => {
                    //  console.log(officersFormDataGrouped,'officersFormDataGrouped@2');
                    return (
                        <Carousel.Item key={cardIndex}>

                            <>
                                <div style={{ width: "100%", whiteSpace: "normal" }} key={cardIndex}>
                                    <div>
                                        {cardData.map((question, relativeIndex) => {
                                            console.log(cardData, '')
                                            let Sno = (cardIndex * size) + (relativeIndex + 1)


                                            return (
                                                <span style={{ display: "flex" }} >
                                                    <Question
                                                        question={question}
                                                        key={relativeIndex}
                                                        onChange={memoizedHandleAnswerChange}
                                                        validationReview={isSubmittedOnce}
                                                        preFilledAnswers={questionsData?.oldAnswer?.data}
                                                        sno={Sno}
                                                        applicationType={applicationType}
                                                    />
                                                </span>

                                            );
                                        })}
                                    </div>
                                    {remarksAndSubmit(carousalIndex)}
                                </div>
                            </>
                        </Carousel.Item>
                    );
                })}
            </Carousel>
        );
    };

    const ansState = useRef({});

    const handleChildAnswerChange = (dbField, value) => {
        ansState.current[dbField] = value;
    };

    const memoizedHandleAnswerChange = useCallback(handleChildAnswerChange, [
        ansState,
    ]);

    useEffect(() => {
        if (questionsData?.oldAnswer?.data) {
            officersFormDataGrouped.map((cardData) => {
                cardData.map((question) => {
                    if (
                        questionsData?.oldAnswer?.data.hasOwnProperty(question['dbField']) &&
                        questionsData?.oldAnswer?.data[question['dbField']].parent_answer) {
                        handleChildAnswerChange(question['dbField'], questionsData?.oldAnswer?.data[question['dbField']])
                    }
                })
            })
        }
    }, []);


    console.log(questionsData?.answer?.data, "questionsData?.answer?.data ")
    // console.log("officersFormDataGrouped",officersFormDataGrouped);

    return (
        <>
            {/* {error.message == 'You need to authenticate to perform this action' ? <ErrorScreen message = {error}/> :""} */}
            {ModalComponent()}
            {SaveAnsersPopUp()}
            {questionsData?.oldAnswer?.action === 'view' ?

                <div

                    style={{
                        height: 'auto',
                        margin: '10px',
                    }}
                >
                    <Card.Body>
                        <div className="answer-list">
                            {officersFormDataGrouped.map((formData, index) => {

                                return (
                                    formData.map((question, relativeIndex) => {
                                        console.log(question, 'question')
                                        return (
                                            <span style={{ display: "flex", alignItems: "felx-start" }}>

                                                <p>{(index * size) + (relativeIndex + 1)}. </p> &nbsp;  &nbsp;
                                                <AnswerComponent
                                                    field={question}
                                                    answer={questionsData?.oldAnswer?.data || null}
                                                    key={relativeIndex}
                                                    index={relativeIndex}
                                                />
                                            </span>
                                        )
                                    }

                                    )
                                )
                            }
                            )
                            }
                        </div>
                    </Card.Body>
                </div>
                :
                <>
                    {officersFormDataGrouped?.length > size ? <div style={{ position: "fixed", left: "70%" }}>
                        <ul className="stepper-dots">
                            {officersFormDataGrouped.map((group, groupIndex) => (
                                <a href="#!" key={groupIndex}>
                                    <li
                                        className={
                                            carousalIndex === groupIndex ? 'active' : ''
                                        }
                                        onClick={(e) => handleSelect(groupIndex, e)}
                                    />
                                </a>
                            ))}
                        </ul>
                    </div> : null}
                    {showIAIRCarosualQuestionsBeforeShowcause()}
                </>
            }
        </>
    );
}

IAIRCarosualQuestionsBeforeShowcause.propTypes = {
    officersFormDataGrouped: PropTypes.array,

    formId: PropTypes.string,
    verificationType: PropTypes.string,
    applicationIdentifier: PropTypes.string,
    showSidebar: PropTypes.bool,
    showSidebarHandler: PropTypes.func,
};

export default IAIRCarosualQuestionsBeforeShowcause;
