import React, { useState, useEffect } from 'react';
import ReviewSubmit from '../ApplicationDetailComponent/review';
import OccupancyReview from '../ApplicationDetailComponent/occupancyView';
// import ReviewSubmit from '../ApplicationDetailsModified/Review';
import ReviewRemarks from '../ReviewRemarks';
import { Tabs, Tab } from 'react-bootstrap';
import { useParams } from 'react-router';
import './index.scss';
import NotesModal from '../NotesModal/index';
import PropTypes from 'prop-types';
import { GET_OFFICERS_APPLICATION } from '../../graphql/query/officersApplication';
import { useQuery } from '@apollo/client';
import ErrorScreen from '../ErrorScreen';
import CompoundWallShow from '../ApplicationDetailComponent/compoundWallShow';

export default function Index(props) {

  const [show, setShow] = useState(false);
  const [isDisabledNotes, setIsDisabledNotes] = useState(true);
  const handleClose = () => setShow(false);
  const { type, id } = useParams();
  const { data, error } = useQuery(GET_OFFICERS_APPLICATION, {
    fetchPolicy: 'cache-first',
    variables: {
      id: id
    }
  })

  console.log(type, "helfhaskj")
  useEffect(() => {
    if (isDisabledNotes) {
      let style = 'cursor: not-allowed; opacity: 0.5';
      document.getElementById('addButton').style.cssText = style;
    }
  }, [isDisabledNotes]);

  return (
    <>
      <div className="ApprovedAplicationTabs">
        <div style={{ marginLeft: "20px" }} className="d-flex align-items-center justify-content-end marginRight mb-3">
          <span className="addnote_label_text">Add Note Sheet :</span>
          <div className="d-flex add_note_wrapper ml-3 ">
            <div
              className="viewButton"
              type="button"
              onClick={() => {
                setShow(true);
              }}
              id="viewButton"
            >
              VIEW
            </div>
            <div
              className="addButton"
              type="button"
              onClick={() => {
                if (!isDisabledNotes) setShow(true);
              }}
              id="addButton"
            >
              ADD
            </div>
          </div>
          {show ? (
            <NotesModal
              handleClose={handleClose}
              isDisabledNotes={isDisabledNotes}
            />
          ) : null}

        </div>

        <Tabs
          // className="mb-3"
          // className="style1"  approvalFor
          style={{ boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          defaultActiveKey="home"
          id="justify-tab-example"
          className="mb-1 mr-5"

        >
          <Tab
            className="Tab"
            eventKey="home"
            // eslint-disable-next-line react/prop-types
            title={` ${props.applicationIdentifier}`}
          >
            {type && type === "occ" ?
              <OccupancyReview applicationType={props.applicationType} /> :
              type && type === "CW" ?
                <CompoundWallShow /> :
                <ReviewSubmit applicationType={props.applicationType} />}
          </Tab>
          <Tab className="Tab" eventKey="profile" title="Review Remarks">

            <ReviewRemarks
              setIsDisabledNotes={setIsDisabledNotes}
              fromSummary={props.fromSummary}
              status={data?.officersApplication?.applicationType}
            />
          </Tab>
        </Tabs>


      </div>
      {error && <ErrorScreen />}
    </>
  );
}

Index.propTypes = {
  applicationType: PropTypes.string,
  fromSummary: PropTypes.bool,
};

Index.defaultProps = {
  applicationType: 'SELF CERTIFICATION',
  fromSummary: false,
};
