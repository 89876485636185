import React, { useContext, useEffect, useState } from 'react';
import ApplicationListLayout from '../components/ApplicationList';
import { useQuery } from '@apollo/client';
import LoadingScreen from '../components/LoadingScreen';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ErrorScreen from '../components/ErrorScreen';
// import { GET_OFFICERS_APPLICATIONS } from '../graphql/query/officersApplications';
import { GET_FILTERED_APPLICATIONS } from '../graphql/query/officersFilteredApplications';
import history from '../services/history';
import { AppContext } from './../AppContext';
function PreScrutiny() {
  function checkUserLogin () {
    const loginTOken = sessionStorage.getItem('accessToken')
    if(loginTOken != null || loginTOken != "" || loginTOken != undefined) {
      return true
    }else {
      return false
    }
  }
  const { setCurrentFlowGlobally } = useContext(AppContext);
  useEffect(() => {
    setCurrentFlowGlobally('single-window');
  }, []);

  const filterValues = {
    all: 'ALL',
    deemed: 'DEEMED',
    completed: 'COMPLETED',
    action_required: 'ACTION_REQUIRED',
  };
  const [currentPage, setCurrentPage] = useState(1);

  const { loading, error, data, refetch } = useQuery(
    GET_FILTERED_APPLICATIONS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: {
        filter:
          filterValues[history.location.state?.filter] || filterValues.all,
        page: currentPage,
      },
    }
  );

  let filterdData;
  if (data && data.applicationsByDateAndTypeFilter[0]?.records.length) {
    const cloneOfficersApplications = [
      ...data.applicationsByDateAndTypeFilter[0]?.records,
    ];
    filterdData = cloneOfficersApplications.filter(
      (app) => app.applicationType === 'SINGLE WINDOW'
    );
  }
  useEffect(() => {
    if (history.location.state?.filter) {
      setCurrentPage(1);
    }
  }, [history.location.state?.filter]);
  return (
    <>
    {/* {checkUserLogin() &&   <IdleTimer/> } */}
      <Header />
      <Sidebar />
      {loading && <LoadingScreen />}
      {error && <ErrorScreen message={error} retry={refetch} />}
      {data && (
        <div className="main-content">
          <ApplicationListLayout
            data={filterdData}
            pageType={'single-window'}
            currentFilter={history.location.state?.filter || 'all'}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            pageCount={data?.applicationsByDateAndTypeFilter[0]?.totalPages || 1}
          />
        </div>
      )}
    </>
  );
}

export default PreScrutiny;
