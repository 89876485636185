import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: {
        labels: ['Received','Approved','Rejected','Deemed'],
        datasets: [
          {
            data: [
              this.props.data.received, 
              this.props.data.approved, 
              this.props.data.rejected, 
              this.props.data.deemed
            ],
            backgroundColor: [
              '#4A99D5',
              '#4CBC84',
              '#F97676',
              '#79C0F5',
            ],
          },
        ],
      },
    };
  }
  render() {
    return (
      <div className="chart">
        <Bar
          data={this.state.chartData}
          height={225}
          options={{
            title: {
              position: 'top',
              textAlign:'start',
              display: true,
              text: this.props.title,
            },
            layout: {
              padding: {
                  left: 20
              }
            },
            legend: {
              display: false,
            },
            scales: {
              yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    stepSize: 100,
                    suggestedMax: 600
                  },
                  gridLines: {
                    display: true,
                    drawBorder: false,
                    tickMarkLength: 20
                  },
                }],
              xAxes: [{
                gridLines: {
                  display: false,
                },
                ticks: {
                  display: false
                }
              }],
            },
            maintainAspectRatio: false
          }}
        />
      </div>
    );
  }
}

Chart.propTypes = {
  title: Chart.string,
};

export default Chart;
