/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import {
    Table,
    Accordion,
    Card,
    Row,
    Col,
} from 'react-bootstrap';
import './review.scss';
import { toast } from 'react-toastify';
import axios from 'axios';
import environment from 'environment';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import CryptoJS from 'crypto-js';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeeAdjustmentDetails from './FeeAdjustmentDetails';
import {
    PERMISSIONS_LEVELS,
    getTranslatedText,
} from '../../util/helperFunctions';
import { AppContext } from '../../AppContext';
import Loader from '../LoadingScreen/Loader';
const ReviewSubmit = (props) => {
    const [appData, setAppData] = useState(true);
    const [applicantData, setApplicantData] = useState();
    const [plotData, setPlotData] = useState();
    const [buildingData, setBuildingData] = useState();
    const [vicinityData, setVicinityData] = useState(false);
    const [deviationData, setDeviationData] = useState(false);
    const [mediaData, setMediaData] = useState();
    const [certificates, setCertificates] = useState();
    const [geoCoordinates, setGeoCoordinates] = useState([]);
    const [checkedValues, setCheckedValues] = useState([]);
    const [loading, setLoading] = useState(false)
    const [mediaLoading, setMediaLoading] = useState(false)
    const { id } = useParams();
    const history = useHistory();
    const { permissionLevel } = useContext(AppContext);
    const decryptedText = (cipherText) => {
        const key = CryptoJS.enc.Base64.parse('6OZbK0FbuzUCG+XuXWuKTFSm3VIMKg0IWNnU/FKIraI=');
        const iv = CryptoJS.enc.Base64.parse('jKdOxAbYigXA1ls0dqqikQ==');
        const bytes = CryptoJS.AES.decrypt(cipherText, key, { iv: iv });
        const plainText = bytes.toString(CryptoJS.enc.Utf8);
        return plainText;
    }
    const applicationDetails1 = () => {
        return (
            <>
                {history.location?.state?.from &&
                    history.location?.state?.from === 'verification-pages' ? (
                    <div
                        className="d-flex"
                        style={{ float: 'right', cursor: 'pointer' }}
                        onClick={() => history.goBack()}
                    >
                        <img src={require('../../assets/images/closeIcon.svg')} />
                        <p style={{ fontSize: 12, color: '#9eabb5', paddingLeft: 10 }}>
                            Minimize
                        </p>
                    </div>
                ) : null}

                <Accordion
                    defaultActiveKey="0"
                    className="my-5 applicationDetails"
                    style={{ border: '1px solid lightgray', borderRadius: '2px' }}
                >
                    {applicantData && (
                        <Card style={{ height: '100%' }}>
                            <Accordion.Toggle
                                as={Card.Header}
                                eventKey="0"
                                style={{ borderBottom: 'none', fontSize: '15px' }}
                            >
                                Application Details
                                <img
                                    style={{
                                        transform: 'rotate(0deg)',
                                    }}
                                    src={require('../../assets/images/dd-arrow.svg')}
                                    alt=""
                                />
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    {applicantData.applicant_type &&
                                        applicantData.applicant_name && (
                                            <Row>
                                                <Col className="detail-prop">Applicant Name</Col>
                                                <Col className="detail-value">
                                                    {applicantData?.applicant_type}{' '}
                                                    {applicantData.applicant_name}
                                                </Col>
                                            </Row>
                                        )}
                                    {applicantData.relationship_type && (
                                        <Row>
                                            <Col className="detail-prop">
                                                {applicantData.relationship_type}
                                            </Col>
                                            <Col className="detail-value">
                                                {applicantData.relationship_name}
                                            </Col>
                                        </Row>
                                    )}
                                    {applicantData.aadhaar_number && (
                                        <Row>
                                            <Col className="detail-prop">Aadhaar Number</Col>
                                            <Col className="detail-value">
                                                {decryptedText(applicantData.aadhaar_number)}
                                            </Col>
                                        </Row>
                                    )}
                                    {[
                                        PERMISSIONS_LEVELS.SITE_VERIFICATION,
                                        PERMISSIONS_LEVELS.TPSO_OFFICER,
                                        PERMISSIONS_LEVELS.ACP_OFFICER,
                                        PERMISSIONS_LEVELS.SECTION_HEAD,
                                        PERMISSIONS_LEVELS.SECTION_OFFICER,
                                        PERMISSIONS_LEVELS.UDA_GP_SITE_OFFICER,
                                        PERMISSIONS_LEVELS.CP_TECH_OFFICER,
                                        PERMISSIONS_LEVELS.TECH_OFFICER
                                    ].includes(permissionLevel) &&
                                        applicantData.mobile && (
                                            <Row>
                                                <Col className="detail-prop">Mobile Number</Col>
                                                <Col className="detail-value">
                                                    {decryptedText(applicantData.mobile)}
                                                </Col>
                                            </Row>
                                        )}
                                    {/* <Row>
                  <Col>E-Mail ID</Col>
                  <Col>{applicantData.email ? applicantData.email : '---'}</Col>
                </Row> */}
                                    {applicantData.contact_address && (
                                        <Row>
                                            <Col className="detail-prop">Contact Address</Col>
                                            <Col className="detail-value">
                                                {applicantData.contact_address}
                                            </Col>
                                        </Row>
                                    )}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )}
                </Accordion>
            </>
        );
    };

    const plotDetails1 = () => {
        let coordinates = [];
        if (plotData?.geo_coordinates) {
            let coordsArr = plotData.geo_coordinates.split(',');
            let lats = coordsArr.filter((e, i) => i % 2 === 0);
            let longs = coordsArr.filter((e, i) => i % 2 === 1);
            for (var i = 0; i < coordsArr.length / 2; i++) {
                coordinates.push({ lat: lats[i], lng: longs[i] });
            }
        }

        return (
            <Accordion
                defaultActiveKey="0"
                className="my-5"
                style={{ border: '1px solid lightgray', borderRadius: '2px' }}
            >
                {plotData && (
                    <Card style={{ height: '100%' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            style={{ borderBottom: 'none', fontSize: '15px' }}
                            eventKey="0"
                        >
                            Plot Details
                            <img
                                style={{
                                    transform: 'rotate(0deg)',
                                }}
                                src={require('../../assets/images/dd-arrow.svg')}
                                alt=""
                            />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <div className="review-submit-box applicant">
                                <div className="plot-deatils-form">
                                    {Object.keys(plotData).length > 0 ? (
                                        <Table responsive className="review-table">
                                            <tbody>
                                                <tr>
                                                    <td>{getTranslatedText('heading.plot_address')}</td>
                                                    <td>{plotData.plot_address}</td>
                                                </tr>
                                                <tr>
                                                    <td>{getTranslatedText('label.geo_coordinates')}</td>
                                                    <td>
                                                        {plotData.geo_coordinates}
                                                        {/* {geoCoordinates.length &&
                                                        <ul>
                                                        {geoCoordinates.map((coordinates, index) => {
                                                            return (
                                                                <li style={{ listStyle: 'disc', }} key={index}>{`Latitude: ${coordinates.lat}, Longitude: ${coordinates.lng}`}</li>
                                                            );
                                                        })}
                                                        </ul>
                                                    } */}
                                                        {/* <Link href="#!">
                                                            <a onClick={() => setModalShow(true)}>
                                                                View Map
                                                            </a>
                                                        </Link> */}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <h3>Plot Address Not found</h3>
                                    )}
                                </div>
                                <div className="plot-deatils-form">
                                    <h5>{getTranslatedText('heading.plot_regi_details')}</h5>
                                    <Table responsive className="review-table">
                                        <tbody>
                                            <tr>
                                                <td>{appData.plot_land_type}</td>
                                                <td>
                                                    {plotData.plot_land_value
                                                        ? plotData.plot_land_value
                                                        : "N/A"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{getTranslatedText('label.plot_area_document')}</td>
                                                <td>
                                                    {plotData.plot_area_as_per_document
                                                        ? plotData.plot_area_as_per_document +
                                                        " Sq. Meters"
                                                        : "N/A"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{getTranslatedText('label.plot_area_ground')}</td>
                                                <td>
                                                    {plotData.plot_area_as_per_ground
                                                        ? plotData.plot_area_as_per_ground + " Sq. Meters"
                                                        : "N/A"}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Accordion.Collapse>
                    </Card>
                )}
            </Accordion>
        );
    };

    const uploadedDocuments = () => {
        return (
            <Accordion
                className="my-5"
                style={{ border: '1px solid lightgray', borderRadius: '2px' }}
                onClick={handleShowCitizenUploadedMedia}
            >
                {/* {mediaData && ( */}
                    <Card style={{ height: '100%' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            style={{ borderBottom: 'none', fontSize: '15px' }}
                            eventKey="0"
                        >

                            {!mediaLoading ? <>
                                Uploaded Documents
                                <img
                                    style={{
                                        transform: 'rotate(0deg)',
                                    }}
                                    src={require('../../assets/images/dd-arrow.svg')}
                                    alt=""
                                />
                            </> : <Loader />}
                        </Accordion.Toggle>
                        {mediaData && (   
                              <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                {mediaData.map((item, id) => {
                                    return (
                                        <Row key={id}>
                                            <Col className="detail-prop">
                                                {item.category && item.category.replace(/_/g, ' ')}
                                            </Col>
                                            <Col className="detail-value">
                                                {item.category && (
                                                    <a
                                                        href={item.download_url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faDownload}
                                                            style={{ color: 'black' }}
                                                        />
                                                    </a>
                                                )}
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Card.Body>
                        </Accordion.Collapse>
                        )}
                    </Card>
               
            </Accordion>
        );
    };

    const Certificates = () => {
        return (
            <Accordion
                className="my-5"
                style={{ border: '1px solid lightgray', borderRadius: '2px' }}
                onClick={handleShowCertificates}
            >

                <Card style={{ height: '100%' }}>
                    <Accordion.Toggle
                        as={Card.Header}
                        style={{ borderBottom: 'none', fontSize: '15px' }}
                        eventKey="0"
                    >
                        {!loading ? <>     Certificates
                            <img
                                style={{
                                    transform: 'rotate(0deg)',
                                }}
                                src={require('../../assets/images/dd-arrow.svg')}
                                alt=""
                            /> </> : <Loader />}
                    </Accordion.Toggle>
                    {certificates && (<Accordion.Collapse eventKey="0">
                        <Card.Body>
                            {Object.keys(certificates)
                                .filter((key) => certificates[key])
                                .map((item, id) => {
                                    return (
                                        <Row key={id}>
                                            <Col className="detail-prop">
                                                {certificates[item] !== null ? item : '---'}
                                            </Col>
                                            <Col className="detail-value">
                                                <a
                                                    href={certificates[item]}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faDownload}
                                                        style={{ color: 'black' }}
                                                    />
                                                </a>
                                            </Col>
                                        </Row>
                                    );
                                })}
                        </Card.Body>
                    </Accordion.Collapse>)}
                </Card>


            </Accordion>

        );
    };

    const vicinityDetails1 = () => {
        if (
            vicinityData &&
            vicinityData.vicinity_answers &&
            vicinityData.vicinity_answers.length
        ) {
            return (
                <Accordion
                    className="my-5"
                    style={{ border: '1px solid lightgray', borderRadius: '2px' }}
                >
                    {vicinityData &&
                        vicinityData.vicinity_answers &&
                        vicinityData.vicinity_answers.length && (
                            <Card style={{ height: '100%' }}>
                                <Accordion.Toggle
                                    as={Card.Header}
                                    style={{ borderBottom: 'none', fontSize: '15px' }}
                                    eventKey="0"
                                >
                                    Application Checklist
                                    <img
                                        style={{
                                            transform: 'rotate(0deg)',
                                        }}
                                        src={require('../../assets/images/dd-arrow.svg')}
                                        alt=""
                                    />
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        {vicinityData?.vicinity_answers.map((item, id) => {
                                            return (
                                                <Row key={id}>
                                                    <Col className="detail-prop">{item.question}</Col>
                                                    <Col className="detail-value">
                                                        {item.answer
                                                            ? item.answer === 'TRUE' || item.answer === 't'
                                                                ? 'Yes'
                                                                : item.answer === 'FALSE' || item.answer === 'f'
                                                                    ? 'No'
                                                                    : item.answer
                                                            : '---'}
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )}
                </Accordion>
            );
        }
    };

    const buildingDetails = () => {
        return (
            <Accordion
                className="my-5"
                style={{ border: '1px solid lightgray', borderRadius: '2px' }}
            >
                {buildingData && (
                    <Card style={{ height: '100%' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            style={{ borderBottom: 'none', fontSize: '15px' }}
                            eventKey="0"
                        >
                            Building Details
                            <img
                                style={{
                                    transform: 'rotate(0deg)',
                                }}
                                src={require('../../assets/images/dd-arrow.svg')}
                                alt=""
                            />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <div className="review-submit-box applicant">
                                <div className="plot-deatils-form">
                                    {/* <h5></h5> */}
                                    {buildingData.building_info ? (
                                        <Table responsive className="review-table">
                                            <tbody>
                                                <tr>
                                                    <td>{getTranslatedText('label.building_usage')}</td>
                                                    <td>
                                                        {buildingData.building_info
                                                            .usage_building
                                                            ? buildingData.building_info
                                                                .usage_building
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Net Plot Area</td>
                                                    <td>
                                                        {buildingData.net_plot_area_display ?
                                                            buildingData.net_plot_area_display
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Building Permit Order Issued Date</td>
                                                    <td>
                                                        {buildingData.building_info.building_permit_issued_date ?
                                                            new Date(buildingData.building_info
                                                                .building_permit_issued_date).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })
                                                            : "N/A"}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>{getTranslatedText('label.numeric_floors')}</td>
                                                    <td>
                                                        {buildingData.building_info
                                                            .proposed_no_of_floors_name
                                                            ? buildingData.building_info
                                                                .proposed_no_of_floors_name
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Number of Floors as on Ground</td>
                                                    <td>
                                                        {buildingData.building_info
                                                            .proposed_no_of_floors_name
                                                            ? buildingData.building_info
                                                                .proposed_no_of_floors_name
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{getTranslatedText('label.exising_file_number')}</td>
                                                    <td>
                                                        {buildingData.building_info
                                                            .file_number
                                                            ? buildingData.building_info
                                                                .file_number
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{getTranslatedText('label.build_permit_number')}</td>
                                                    <td>
                                                        {buildingData.building_info
                                                            .permit_number
                                                            ? buildingData.building_info
                                                                .permit_number
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Built up Area (Sq. Mts)</td>
                                                    <td>
                                                        {buildingData.building_info
                                                            .total_built_up_area
                                                            ? buildingData.building_info
                                                                .total_built_up_area
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Road Affected Area if any ( In Sq .mts )</td>
                                                    <td>
                                                        {buildingData.building_info
                                                            .total_road_affected_area
                                                            ? buildingData.building_info
                                                                .total_road_affected_area
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>No of Rain water harvesting Pits</td>
                                                    <td>
                                                        {buildingData.building_info
                                                            .no_of_rain_water_harvesting_pits
                                                            ? buildingData.building_info
                                                                .no_of_rain_water_harvesting_pits
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Rainwater Harvesting as on ground</td>
                                                    <td>
                                                        {buildingData.building_info
                                                            .no_of_rain_water_harvesting_as_on_ground
                                                            ? buildingData.building_info
                                                                .no_of_rain_water_harvesting_as_on_ground
                                                            : "N/A"}
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td>Parking Space Provision</td>
                                                    <td>
                                                        {buildingData.building_info
                                                            .parking_space_provision
                                                            ? buildingData.building_info
                                                                .parking_space_provision
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                {buildingData.building_info
                                                    .parking_space_provision && buildingData.building_info
                                                        .parking_space_provision === "Stilt" && <tr>
                                                        <td>Stilt Area As On Ground</td>
                                                        <td>
                                                            {buildingData.building_info
                                                                .stilt_area_on_ground
                                                                ? buildingData.building_info
                                                                    .stilt_area_on_ground
                                                                : "N/A"}
                                                        </td>
                                                    </tr>}
                                                {buildingData.building_info
                                                    .parking_space_provision && buildingData.building_info
                                                        .parking_space_provision === "Stilt" && <tr>
                                                        <td>Stilt Area Area as per Sanctioned Plan</td>
                                                        <td>
                                                            {buildingData.building_info
                                                                .stilt_area_as_per_sanctioned_plan
                                                                ? buildingData.building_info
                                                                    .stilt_area_as_per_sanctioned_plan
                                                                : "N/A"}
                                                        </td>
                                                    </tr>}
                                                <tr>
                                                    <td>Work Commenced Date</td>
                                                    <td>
                                                        {buildingData.building_info
                                                            .work_commenced_date
                                                            ? new Date(buildingData.building_info
                                                                .work_commenced_date).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Work Completion Date</td>
                                                    <td>
                                                        {buildingData.building_info
                                                            .work_completion_date
                                                            ?
                                                            new Date(buildingData.building_info
                                                                .work_completion_date).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Date By Architect</td>
                                                    <td>
                                                        {buildingData.building_info
                                                            .date_by_architect
                                                            ?
                                                            new Date(buildingData.building_info
                                                                .date_by_architect).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })
                                                            : "N/A"}
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </Table>
                                    ) : <h3>Building Info Not found</h3>}
                                </div>
                            </div>
                        </Accordion.Collapse>
                    </Card>
                )}
            </Accordion>
        );
    };

    let totalDeviatedValue = parseInt(deviationData ? deviationData.front_total_deviated_builtup_area : 0) + parseInt(deviationData ? deviationData.rear_total_deviated_builtup_area : 0) + parseInt(deviationData?.side_one_total_deviated_builtup_area) + parseInt(deviationData ? deviationData?.side_two_total_deviated_builtup_area : 0);

    const deviatedDetails = () => {
        return (
            <Accordion
                className="my-5"
                style={{ border: '1px solid lightgray', borderRadius: '2px' }}
            >
                {buildingData && (
                    <Card style={{ height: '100%' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            style={{ borderBottom: 'none', fontSize: '15px' }}
                            eventKey="0"
                        >
                            Deviated Details
                            <img
                                style={{
                                    transform: 'rotate(0deg)',
                                }}
                                src={require('../../assets/images/dd-arrow.svg')}
                                alt=""
                            />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <div className="review-submit-box applicant">
                                <div className="plot-deatils-form">
                                    {deviationData ? (
                                        <Table responsive className="review-table">
                                            <tbody>

                                                <tr>
                                                    <td>Whether the building completed within the stipulated time ?</td>
                                                    <td>
                                                        {deviationData.is_building_constructed_on_stipulated_time == true ? "Yes" : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Due Date for Completion of Building as per Proceeding</td>
                                                    <td>
                                                        {
                                                            deviationData.proceeding_date
                                                                ?
                                                                //deviationData.proceeding_date
                                                                new Date(deviationData.proceeding_date).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })
                                                                : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Actual Date of Completion of the Building</td>
                                                    <td>
                                                        {
                                                            deviationData.completion_date
                                                                ?
                                                                //  deviationData.completion_date
                                                                new Date(deviationData.completion_date).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' })
                                                                : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>No Of Days Deviated</td>
                                                    <td>
                                                        {
                                                            deviationData.no_days_deviated || deviationData.no_days_deviated === 0
                                                                ?
                                                                deviationData.no_days_deviated
                                                                : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Total Build-Up Area as on Ground (in Sq. mts.)</td>
                                                    <td>
                                                        {
                                                            deviationData.total_built_up_area_as_on_ground
                                                                ?
                                                                deviationData.total_built_up_area_as_on_ground
                                                                : "N/A"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    ) : <h3>Building Info Not found</h3>}
                                    <br />
                                    <br />
                                    <Col sm={12} >
                                        <h4> Citizen updated DeviationTable</h4>
                                        <Table bordered className="devi-table">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Set Backs</th>
                                                    <th>As per Building Permit Order (in Mts)</th>
                                                    <th>As on ground (in Mts)</th>
                                                    <th>Deviation in Set Back (in mts)</th>
                                                    <th>% of Setback Deviated</th>
                                                    <th>Building Lengths (in Sq Mts)</th>
                                                    <th>Deviated Builtup Area per Floor (in Sq. mts)</th>
                                                    <th>No. of Floors</th>
                                                    <th>Total Deviated Builtup Area (in Sq. mts.)</th>
                                                    <th>Land Value per Sq. Yd as per Registration Dept., (In Rs.)</th>
                                                    <th>Total Compounding Fee (In Rs.)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>1</th>
                                                    <th>Front</th>
                                                    <th>
                                                        {deviationData ? deviationData.front_building_permit_order : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.front_setback_as_on_ground : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.front_setback_deviation_in_sq_mtrs : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.front_setback_deviated_percentage : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.front_building_lengths : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.front_side_deviated_builtup_area_per_floor : 0}
                                                    </th>
                                                    <th>

                                                        {deviationData ? deviationData.front_no_of_floors : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.front_total_deviated_builtup_area : 0}
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th>2</th>
                                                    <th>Rear</th>
                                                    <th>
                                                        {deviationData ? deviationData.rear_building_permit_order : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.rear_setback_as_on_ground : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.rear_setback_deviation_in_sq_mtrs : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.rear_setback_deviated_percentage : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.rear_building_lengths : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.rear_deviated_builtup_area_per_floor : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.rear_no_of_floors : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.rear_total_deviated_builtup_area : 0}
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th>3</th>
                                                    <th>Side1</th>
                                                    <th>
                                                        {deviationData ? deviationData.side_one_building_permit_order : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.side_one_setback_as_on_ground : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.side_one_setback_deviation_in_sq_mtrs : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.side_one_setback_deviated_percentage : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.side_one_building_lengths : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.side_one_deviated_builtup_area_per_floor : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.side_one_no_of_floors : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.side_one_total_deviated_builtup_area : 0}
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th>4</th>
                                                    <th>Side2</th>
                                                    <th>
                                                        {deviationData ? deviationData.side_two_building_permit_order : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.side_two_setback_as_on_ground : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.side_two__setback_deviation_in_sq_mtrs : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.side_two_setback_deviated_percentage : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.side_two_building_lengths : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.side_two_deviated_builtup_area_per_floor : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.side_two_no_of_floors : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.side_two_total_deviated_builtup_area : 0}
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th>
                                                        {totalDeviatedValue}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.land_value_per_registration_dep : 0}
                                                    </th>
                                                    <th>
                                                        {deviationData ? deviationData.total_compounding_fee : 0}
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                    {(appData.application_status == "PROCEEDINGS_IN_PROGRESS" || appData.application_status == "ACCEPTED" || appData.application_status == "REJECTED") &&
                                        deviationData && deviationData.l2_front_building_permit_order &&
                                        <Col sm={12} style={{ marginTop: '20px' }} >
                                            <h4>Officer updated DeviationTable</h4>
                                            <Table bordered className="devi-table">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Set Backs</th>
                                                        <th>As per Building Permit Order (in Mts)</th>
                                                        <th>As on ground (in Mts)</th>
                                                        <th>Deviation in Set Back (in mts)</th>
                                                        <th>% of Setback Deviated</th>
                                                        <th>Building Lengths (in Sq Mts)</th>
                                                        <th>Deviated Builtup Area per Floor (in Sq. mts)</th>
                                                        <th>No. of Floors</th>
                                                        <th>Total Deviated Builtup Area (in Sq. mts.)</th>
                                                        <th>Land Value per Sq. Yd as per Registration Dept., (In Rs.)</th>
                                                        <th>Total Compounding Fee (In Rs.)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>1</th>
                                                        <th>Front</th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_front_building_permit_order : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_front_setback_as_on_ground : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_front_setback_deviation_in_sq_mtrs : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_front_setback_deviated_percentage : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_front_building_lengths : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_front_side_deviated_builtup_area_per_floor : 0}
                                                        </th>
                                                        <th>

                                                            {deviationData ? deviationData.l2_front_no_of_floors : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_front_total_deviated_builtup_area : 0}
                                                        </th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <th>2</th>
                                                        <th>Rear</th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_rear_building_permit_order : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_rear_setback_as_on_ground : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_rear_setback_deviation_in_sq_mtrs : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_rear_setback_deviated_percentage : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_rear_building_lengths : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_rear_deviated_builtup_area_per_floor : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_rear_no_of_floors : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_rear_total_deviated_builtup_area : 0}
                                                        </th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <th>3</th>
                                                        <th>Side1</th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_one_building_permit_order : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_one_setback_as_on_ground : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_one_setback_deviation_in_sq_mtrs : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_one_setback_deviated_percentage : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_one_building_lengths : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_one_deviated_builtup_area_per_floor : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_one_no_of_floors : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_one_total_deviated_builtup_area : 0}
                                                        </th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <th>4</th>
                                                        <th>Side2</th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_two_building_permit_order : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_two_setback_as_on_ground : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_two__setback_deviation_in_sq_mtrs : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_two_setback_deviated_percentage : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_two_building_lengths : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_two_deviated_builtup_area_per_floor : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_two_no_of_floors : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_side_two_total_deviated_builtup_area : 0}
                                                        </th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>
                                                            {totalDeviatedValue.toFixed(2)}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_land_value_per_registration_dep : 0}
                                                        </th>
                                                        <th>
                                                            {deviationData ? deviationData.l2_total_compounding_fee : 0}
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>}
                                </div>
                            </div>
                        </Accordion.Collapse>
                    </Card>
                )}
            </Accordion>
        )
    }


    const review = () => {
        return (
            <>
                <div className="app-details-container1">
                    {applicationDetails1()}
                    {plotDetails1()}
                    {uploadedDocuments()}
                    {vicinityDetails1()}
                    {buildingDetails()}
                    {deviatedDetails()}
                    {Certificates()}
                    <FeeAdjustmentDetails id={id} />
                </div>
            </>
        );
    };
    useEffect(() => {
        // eslint-disable-next-line no-undef
        const authToken = sessionStorage.getItem('accessToken');

        if (authToken && id) {
            axios
                .get(`${environment.baseApi}/applications/${id}/compound_wall_show`, {
                    headers: {
                        Authorization: 'Bearer ' + authToken,
                        Accept: '*/*',
                    },
                })
                .then(function (response) {
                    console.log('response', response.data.data);
                    if (response.data.success) {
                        setAppData(response.data.data.app_data);
                        setApplicantData(response.data.data.applicant);
                        setPlotData(response.data.data.plot);
                        setBuildingData(response.data.data.building);
                        setDeviationData(response.data.data.deviation);
                        setVicinityData(response.data.data.deviation_questions);
                        // setMediaData(response.data.data.media.data);
                        // setCertificates(response.data.data.certificates);
                        stringToGeoCoords(response.data.data.plot.geo_coordinates);
                    } else {
                        toast.error(response.data.message, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                        });
                    }
                })
                .catch(function (error) {
                    console.log('erroreee', error);

                    if (error.response) {
                        toast.error(error.response.data.message, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                        });
                    }
                });
        }
    }, []);

    const handleShowCitizenUploadedMedia = () => {


        const newColapse = document.getElementsByClassName('show')

        const authToken = sessionStorage.getItem('accessToken');
        console.log(newColapse, 'newColapse');
        if (newColapse.length == 3 && mediaData.length == 0) {

            setMediaLoading(true)
            axios.get(`${environment.baseApi}/applications/${id}/citizen_uploaded_documents`, {
                headers: {
                    Authorization: 'Bearer ' + authToken,
                    Accept: '*/*',
                },
            }).then((response) => {

                setMediaLoading(false)
                setMediaData(response.data.data.media.data);

            }).catch((ErrorScreen) => {
                console.log(ErrorScreen, 'ErrorScreen')
                setMediaLoading(false)
            })
        }
    }

    const handleShowCertificates = () => {
        // debugger
        const newColapse = document.getElementsByClassName('show')
        const authToken = sessionStorage.getItem('accessToken');
        if (newColapse.length == 3 && certificates.length == 0) {

            setLoading(true)
            axios.get(`${environment.baseApi}/applications/${id}/certificates`, {
                headers: {
                    Authorization: 'Bearer ' + authToken,
                    Accept: '*/*',
                },
            }).then((response) => {

                setCertificates(response.data.data.certificates);
                setLoading(false)
            }).catch((ErrorScreen) => {
                console.log(ErrorScreen, 'ErrorScreen')
                setLoading(false)
            })
        }
    }

    const stringToGeoCoords = (geoCoords) => {
        var geoCoordValues = geoCoords.split(',');
        const rows = [...Array(Math.ceil(geoCoordValues.length / 2))];
        const devidedRows = rows.map((row, idx) =>
            geoCoordValues.slice(idx * 2, idx * 2 + 2)
        );

        const geoValues = [];
        devidedRows.map((row, i) => {
            geoValues.push({
                lat: row[0],
                lng: row[1],
            });
        });
        setGeoCoordinates(geoValues);
    };

    return <div className="appreview-carousel-container">{review()}</div>;
};

export default ReviewSubmit;
