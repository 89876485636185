import React from 'react'
import "./loader.css"
function MapLoader() {
  return (
   
    <div class="center-body">
    <div class="loader-shape-3"></div>
  </div>
  
   
  )
}

export default MapLoader