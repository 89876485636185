
import { gql } from '@apollo/client';

export const APPLICAITON_CONDTIONS = gql`
query applicationConditions( $typeOf: String!, $applicationId: ID!){
  applicationConditions(applicationId: $applicationId, typeOf: $typeOf){
    isSubmitted,
    conditions {
        id
        name,
        checked,
        isSystemCreated
    }
  }
}
`