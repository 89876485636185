import React, { useState } from 'react';
import Header from '../components/Header';
import { Tabs, Tab } from 'react-bootstrap';
import ScrutinyTableView from '../components/ScrutinyTableView';
import PreProceedingTableView from '../components/PreProceedingTableView';
import SubHeader from '../components/SubHeader';

export default function FeeVerificationAndPaymentReview() {
  const [tabkey, setTabKey] = useState('fee_certification');

  return (
    <div>
      <Header />
      <SubHeader
        level1="Single Window"
        level2="Scrutiny"
        level3="Payment review & Pre Proceeding Conditions"
      />
      <Tabs
        className="container"
        id="controlled-tab-example"
        activeKey={tabkey}
        onSelect={(k) => setTabKey(k)}
        justify={'center'}
        style={{ marginTop: '15px' }}
      >
        <Tab
          eventKey="fee_certification"
          title="Fee Certification"
          style={{ borderRight: '1px solid red' }}
        >
          <ScrutinyTableView viewPage={'single_page'} />
        </Tab>
        <Tab
          eventKey="pre_proceeding_conditions"
          title="Pre Proceeding Conditions"
        >
          <PreProceedingTableView />
        </Tab>
      </Tabs>
    </div>
  );
}
