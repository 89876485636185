import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { APPLICAITON_CONDTIONS } from "../../graphql/query/FeeCondtions"
import { SELECT_CONDITION } from "../../graphql/mutatution/feeConditions"
import LoadingScreen from "../LoadingScreen/index"
import { Button } from 'react-bootstrap';
import ErrorScreen from '../ErrorScreen';

function AdditionlConditions({ applicationIdentifier,setIsFeeConditionsSubmit }) {
  // let AdditionalConditionsList;
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const { data: AdditionalCondtions, error,loading,refetch } = useQuery(APPLICAITON_CONDTIONS, {
    // fetchPolicy: 'cache-first',
    variables: {
      applicationId: id,
      typeOf: "fee"
    }
  })

  const [selectConditions, { caled, errer }] = useMutation(SELECT_CONDITION);


  const [value, setValue] = useState([])

  let dataItem;

  if (AdditionalCondtions?.applicationConditions?.conditions !== null) {
    dataItem = AdditionalCondtions?.applicationConditions?.conditions?.filter((item) => item.checked == true).map((item) => item.id)

  }



  //let newArray =[]


  const handleChangeApplicationId = (e, item) => {
    //console.log(e.target.checked , item.id ,"checkiong")
    if (e.target.checked === true) {

      setValue((prev) => [...prev, item.id])

    } else if (e.target.checked == false) {
      if (dataItem?.includes(item.id)) {
        dataItem = dataItem?.filter(i => i != item.id)

      } else {
        let data1 = value.filter((ele) => ele != item.id)
        // console.log(data1);
        setValue(data1)
      }
    }

  }





  let newValue = []
  const handleSubmitSelectedCOndtions = () => {

    // console.log(value,'value')
    // console.log(dataItem,'newVAlue');

    newValue.push(...dataItem, ...value)
    setIsLoading(true)
    //console.log(value.toString())
    selectConditions({
      variables: {
        applicationId: id,
        conditionSelected: newValue.toString()
      }
    }).then(() => {
      if (!errer && !caled) {
        setIsLoading(false)
         window.location.reload()
      }

    }).catch(() => {
      console.log(errer, "check the Error")
    })
    //  console.log(value)
    //  const data =  users.filter((item)=>item.isChecked == true).map((items)=>items.Question)
    //   console.log(data  ,"checkingAdditional ")

  }

  //   const handeleChecking = (e) => {
  //     if(e.target.checked == true) { 
  // let allValues = checkedValue;
  // setValue(allValues)
  //     }else {
  //       setValue([])
  //     }

  // // console.log(value);
  // }

  // console.log(value)
  return (
    <>

      {loading && <LoadingScreen />}
      {error && <ErrorScreen message={error} retry={refetch} />}

      <div className='AdditionContainer'>
        <from>
          <div>
            {/* <label>
         <input 
          type="checkbox"
          name='Select_All' 
          onChange={handeleChecking}
           />
         <span>Select All</span>
         </label> */}
          </div>
          {AdditionalCondtions != null && (AdditionalCondtions.applicationConditions.conditions.map((item) => {
            return <>
              <label >
                <input type="checkbox"
                  name={item.Question}
                  defaultChecked={item.checked}
                  onChange={(e) => { handleChangeApplicationId(e, item) }}
                  disabled={item.checked}
                />
                <label>  {item.name}</label>

              </label>

            </>
          }))}
        </from>
        { !isLoading ?   <button  onClick={handleSubmitSelectedCOndtions}>{"Submit"} &nbsp;
          <img
            src={require('../../assets/images/arrow.svg')}
            alt="next"
          />
        </button>
            :   <Button variant="secondary">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </Button> }
      </div>
    </>

  )
}

export default AdditionlConditions