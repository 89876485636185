import { gql } from '@apollo/client';

export  const UPDATE_CORRECTION = gql`
mutation updateCorrection($isApprove: Boolean!, $correctionId: ID!, $remark:String!) {
    updateCorrection( isApprove: $isApprove, correctionId: $correctionId, remark: $remark ) 
  }
`

    export const UPDATE_FINAL_CORRECTION = gql`
    mutation updateFinalCorrection( $remark: String!, $correctionRequestId: ID!, $isApprove: Boolean){
      updateFinalCorrection(remark: $remark, correctionRequestId: $correctionRequestId, isApprove: $isApprove)
    }
    `