import { gql } from '@apollo/client';

export const SUBMIT_FORM = gql`
mutation submitFormAnswers(
  $formInstanceId: ID!
  $data: JSON
  $status: StageStatus!
  $finalRemark: String
  $officerId: ID!
) {
  submitFormAnswers(
    formInstanceId: $formInstanceId
    data: $data
    status: $status
    finalRemark: $finalRemark
    officerId: $officerId
  )
}
`;

export const SUBMIT_FORM_SW = gql`
  mutation submitFormAnswersSingleWindow(
    $formInstanceId: ID!
    $data: JSON = null
    $status: StageStatus!
    $finalRemark: String
    $officerId: ID!
  ) {
    submitFormAnswersSingleWindow(
      formInstanceId: $formInstanceId
      data: $data
      status: $status
      finalRemark: $finalRemark
      officerId: $officerId
    )
  }
`;


export const SAVE_ANSWERS_FORM = gql`
  mutation saveFormAnswersSingleWindow(
    $formInstanceId: ID!
    $data: JSON = null
    $status: StageStatus!
    $finalRemark: String
    $officerId: ID!
  ) {
    saveFormAnswersSingleWindow(
      formInstanceId: $formInstanceId
      data: $data
      status: $status
      finalRemark: $finalRemark
      officerId: $officerId
    )
  }
`;


export const SUBMIT_FINAL_REMARK_L2 = gql`
  mutation submitForm(
    $formInstanceId: ID!
    $finalRemark: String
    $status: StageStatus!
  ) {
    submitFormAnswers(
      formInstanceId: $formInstanceId
      finalRemark: $finalRemark
      status: $status
    )
  }
`;
