import React, { useState } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import PropTypes from 'prop-types';
import FileUpload from '../../FileUploadingComponent';
import DeviationTable from '../../DeviationTable';
import MultiSelectComponent from '../../MultiSelect';
import Button from 'react-bootstrap/Button';
import { useEffect } from 'react';
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Chip
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
const QUESTIONS_TYPE = {
  CHECKBOX: 'CHECKBOX', // we were considering radio buttons as a checkbox for now
  TEXT_LONG: 'TEXT_LONG',
  TEXT_SHORT: 'TEXT_SHORT',
  DROP_DOWN: 'DROP_DOWN',
  DROP_DOWN_MULTI_SELECT: 'DROP_DOWN_MULTI_SELECT',
  FILE: 'FILE',
  MULTI_SELECT: 'MULTI_SELECT',
  TABLE: 'TABLE',
};



const newArray = {};
export default function InputComponent({
  question,
  onChange,
  handleCurrentChange,
  handleNOCmodal,
  applicationType,
  preFilledAnswers = null,
  display, setDisplay
}) {

  // const newOptions = []
  //     for (let i = 0; i < options1?.length; i++) {
  //       newOptions.push({
  //         label: options1[i],
  //         value: options1[i] 
  //       });
  //     }
  //       console.log(newOptions,"options1");

  //const [initialOption, setInitialOption] = useState("true")
  // const [viwePdf,setViewPdf] = useState(false)
  //   const plugin = defaultLayoutPlugin()

  const [itemValue, setItemValue] = useState([])
  const [selectedNames, setSelectedNames] = useState([]);



  const handlePreFilledDropdown = () => {
    let val = "";
    if (preFilledAnswers !== null) {
      if (
        preFilledAnswers.hasOwnProperty(question['dbField']) &&
        preFilledAnswers[question['dbField']]
      ) {
        val = preFilledAnswers[question['dbField']].parent_answer;
        console.log(val, "val")
      }
    }

    return val?.split(',');
  };





  const handleSelectValue = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked == true) {
      newArray[e.target.name] = item.value
      var newResult = Object.values(newArray)
      setDropdownValue(newResult);
      setItemValue([...itemValue, item.value])
      // console.log(newResult, "newResult@123");
      handleCurrentChange(e, newResult, "", question)
    } else {
      setItemValue(itemValue.filter(option => item.value != option))
      newArray[e.target.name] = ""
    }
  }


  const handleChangeOptions = (e, event) => {
    console.log(event)
  }


  const handleDisplayDropDown = () => {
    if (display === false) {
      setDisplay(!display)
    }
  }

  const [selectedValue, setSelectedValue] = useState([])
  const handleSelectOption = (e) => {
    const value = e.target.value
    setDisplay(true)
    if (e.target.checked == true) {
      newArray[value] = value

      setSelectedValue((prev) => [...prev, value])
      // newResult = Object.values(newArray)


    } else {
      const newValue = selectedValue.filter(item => item != value)
      setSelectedValue(newValue)
      // newArray[value] = ""
      // newResult = Object.values(newArray)
    }
    // console.log(value)
    // const finalOptions = newResult.filter(item => item != "")
    // handleCurrentChange(e, finalOptions, '')
  }

  useEffect(() => {

    if (selectedValue.length !== 0) {
      const finalOptions = selectedValue.filter(item => item != "")
      handleCurrentChange("dropdown", finalOptions, '', question)
    }

  }, [selectedValue])

  const handleFilterDropdown = (value) => {
    const newValue = selectedValue.filter(item => item != value)
    setSelectedValue(newValue)
    // console.log(value, "value")
  }
  // console.log(applicationType, "applicaitonTYPE")
  const [dropdownValue, setDropdownValue] = useState(
    question.fieldType == "DROP_DOWN" &&

    handlePreFilledDropdown()
  );

  // const newDropDownValue = dropdownValue?.split(",")
  // console.log(typeof dropdownValue, 'dropdownValue');

  const multiSelectHandle = (e, val) => {
    handleNOCmodal(question.options, val);
    handleCurrentChange(e, val, '', question);
  };
  let InputBasedOnType = '';

  const handlePreFilledRadio = (type) => {
    let check =
      preFilledAnswers !== null
        ? preFilledAnswers[question['dbField']]
          ? preFilledAnswers[question['dbField']].parent_answer === type
          : null
        : false;

    return check;
  };

  const handlePreFilledFile = () => {
    if (preFilledAnswers !== null && preFilledAnswers[question['dbField']] && preFilledAnswers[question['dbField']].parent_answer ) {
      return <h6><a href={preFilledAnswers[question['dbField']].parent_answer} target="_blank" alt="uploaded_docs">View Uploaded Image</a></h6>
    }
  }

  const handlePreFilledVal = () => {
    let val = '';
    if (preFilledAnswers !== null) {
      if (
        question.hasOwnProperty('parent') &&
        preFilledAnswers[question['parent']['dbField']][question['dbField']]
      ) {
        val =
          preFilledAnswers[question['parent']['dbField']][question['dbField']]
            .parent_answer;
      } else if (preFilledAnswers.hasOwnProperty(question['dbField'])) {
        val = preFilledAnswers[question['dbField']].parent_answer;
      }
    }
    return val;
  };
  switch (question.fieldType) {
    case QUESTIONS_TYPE.CHECKBOX: {
      InputBasedOnType = (
        // <></>
        <div className="radio-group">
          <label className='Label'>
            <input
              id='myRadio'
              type="radio"
              name="answers"
              defaultChecked={handlePreFilledRadio('true')}
              onChange={(e) => handleCurrentChange(e, 'true', '', question)}
            />
            Yes
          </label>
          <label className='Label'>
            <input
              id='myRadio'
              type="radio"
              name="answers"
              defaultChecked={handlePreFilledRadio('false')}
              onChange={(e) => handleCurrentChange(e, 'false', '', question)}

            />
            No
          </label>
        </div>
      );
      break;
    }
    case QUESTIONS_TYPE.TEXT_LONG: {
      InputBasedOnType = (
        <div className="input-group">
          <label className="w-100 Label">
            <textarea
              className="w-100"
              style={{ height:"20px" }}
              name="long_text"
              onChange={handleCurrentChange}
            />
          </label>
        </div>
      );
      break;
    }

    case QUESTIONS_TYPE.FILE: {
      InputBasedOnType = (
        <>
          {handlePreFilledFile()}
          <div className="input-group">
            <FileUpload
              ID={question.dbField}
              Filename={question.dbField}
              FileType={question.dbField}
              question={question}

              // errors={formik.errors}
              // touched={formik.touched}
              // handleBlur={formik.handleBlur}
              handleCurrentChange={handleCurrentChange}
              customHandleChange={onChange}
            />
          </div>
        </>
      );
      break;
    }

    case QUESTIONS_TYPE.TEXT_SHORT: {
      InputBasedOnType = (
        <div className="input-group textFIeld">
          <label className="w-100 Label">
            <textarea
              required
              // contentEditable
              style={{
                width: "260px",
                 height:"35px",
                border: '1px solid grey',
                resize: "both",
                boxSizing: "border-box",
                wordBreak: "break-all",
                outline: 'none',
                overflow: 'auto',
                cursor: "pointer"

              }}
              name="short_text"
              // dangerouslySetInnerHTML={{ __html: handlePreFilledVal() }}
              //   value={answerResponses[question.id]}
              defaultValue={handlePreFilledVal()}
              onChange={handleCurrentChange}
            ></textarea>
          </label>
        </div>
      );

      break;
    }
    case QUESTIONS_TYPE.DROP_DOWN: {
      InputBasedOnType = (
        <>
          {applicationType != "SINGLE WINDOW" ?

            (
              <div>

                {/* <Dropdown  style={{maxWidth : "100px" }}>

          <Dropdown.Toggle style={{  wordWrap: "break-word"}} className='ButtonCheck' id="dropdown-basic" variant="success">
            {itemValue.length == 0 ? dropdownValue.length == 0 ? "select" :   dropdownValue  : `${" "} ${itemValue}`} 
          
          </Dropdown.Toggle>  
          <Dropdown.Menu style={{ maxWidth: "100px", height: "auto", overflowY: "scroll" }}  >

            {question.options.map(
              (item, id) =>
                item.value !== 'yet to verify' && (
                  <div style={{ display: 'flex', justifyContent: "space-around", alignItems: 'center', padding: '0px 10px'}}>
                    <input
                      //  checked={dropdownValue.includes(item.value)}
                      name= {id}
                      onChange={
                        (e) => {
                          handleSelectValue(e, item)
                    
                         // handleCurrentChange(e, [...item.value])
                        }
                      }
                    
                      value= {item.value}
                      type='checkBox' />
                    <Dropdown.Item
                      key={id}
                      style={{ width: "200px" }}
                    >
                      {item.value}
                    </Dropdown.Item>

                  </div>

                )

            )}
          </Dropdown.Menu>
        </Dropdown>   */}

                <div onClick={handleDisplayDropDown} className="display-btn">
                  {dropdownValue?.length == undefined && selectedValue?.length == 0 ? <>   {"Select"}  </> :
                    selectedValue.length != 0 ?
                      <div> {selectedValue.map(item => {
                        console.log(item, 'selectedValue');
                        return <div className='answer-button' style={{ margin: " 5px" }}> {item}
                        </div>
                      })}</div>
                      :
                      dropdownValue != false && dropdownValue?.length !== undefined && selectedValue.length == 0 ? <>{dropdownValue.map(item => {
                        console.log(item, 'dropdownValue');

                        return <div className='answer-button' style={{ margin: " 5px" }}>
                          {item}
                        </div>
                      })}
                      </>
                        : "Select"}
                  <img src={require("../../../assets/images/arrow-down.svg")} />

                </div>

                <div className={`custom-dropdown-container  ${display ? 'open' : ''}`}>

                  {question.options.map((item) => {
                    return <label class="newLabel">{item.value}
                      <input required value={item.value} onChange={handleSelectOption} type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                  })}
                </div>

              </div>

            )
            :
            (
              //             <Dropdown>
              //               {/* <Dropdown.Toggle variant="success" id="dropdown-basic">
              //    Dropdown Button
              //  </Dropdown.Toggle> */}
              //               <Dropdown.Toggle className='ButtonCheck' id="dropdown-basic" variant="success">
              //                 {dropdownValue === "" ? "select" : dropdownValue}
              //               </Dropdown.Toggle>
              //               <Dropdown.Menu style={{ width: "auto", height: "auto", padding: "15px 18px", marginLeft: "100px", overflowY: "scroll" }}  >

              //                 {question.options.map(
              //                   (item, id) =>
              //                     item.value !== 'yet to verify' && (
              //                       <Dropdown.Item
              //                         key={id}
              //                         onClick={(e) => {
              //                           handleCurrentChange(e, item.value);
              //                           setDropdownValue(item.value);
              //                         }}
              //                       >
              //                         {item.value}
              //                       </Dropdown.Item>

              //                     )

              //                 )}


              //               </Dropdown.Menu>
              //             </Dropdown>
              <div className='main-Dropdown-Container'>

                {/* <Dropdown  style={{maxWidth : "100px" }}>

        <Dropdown.Toggle style={{  wordWrap: "break-word"}} className='ButtonCheck' id="dropdown-basic" variant="success">
          {itemValue.length == 0 ? dropdownValue.length == 0 ? "select" :   dropdownValue  : `${" "} ${itemValue}`} 
        
        </Dropdown.Toggle>  
        <Dropdown.Menu style={{ maxWidth: "100px", height: "auto", overflowY: "scroll" }}  >

          {question.options.map(
            (item, id) =>
              item.value !== 'yet to verify' && (
                <div style={{ display: 'flex', justifyContent: "space-around", alignItems: 'center', padding: '0px 10px'}}>
                  <input
                    //  checked={dropdownValue.includes(item.value)}
                    name= {id}
                    onChange={
                      (e) => {
                        handleSelectValue(e, item)
                  
                       // handleCurrentChange(e, [...item.value])
                      }
                    }
                  
                    value= {item.value}
                    type='checkBox' />
                  <Dropdown.Item
                    key={id}
                    style={{ width: "200px" }}
                  >
                    {item.value}
                  </Dropdown.Item>

                </div>

              )

          )}
        </Dropdown.Menu>
      </Dropdown>   */}

                <div style={{ boxShadow: `${display ? " #83da83 0px 0px 5px 5px" : "rgb(233, 221, 221)  0px 0px 5px 5px"}` }} onClick={handleDisplayDropDown} className="display-btn">
                  {dropdownValue == null && selectedValue?.length == 0 ? <>   {"Select"}  </> :
                    selectedValue?.length != 0 ?
                      <div> {selectedValue?.map(item => {
                        return <div className='answer-button' style={{ margin: " 5px" }}> {item}
                        </div>
                      })}</div>
                      :
                      dropdownValue != "" && dropdownValue[0] !== "" && selectedValue?.length == 0 ? <>{dropdownValue?.map(item => {
                        return <div className='answer-button' style={{ margin: " 5px" }}>
                          {item}
                        </div>
                      })}
                      </>
                        : "Select"}
                  <img src={require("../../../assets/images/arrow-down.svg")} />

                </div>

                <div className={`custom-dropdown-container1 ${display ? 'open' : ''}`}>

                  {question.options.map((item) => {
                    return <label class="newLabel">{item.value}
                      <input value={item.value}

                        onChange={
                          (e) => {
                            handleSelectValue(e, item)
                            setDisplay(false)
                            // handleCurrentChange(e, [...item.value])
                          }
                        }
                        type="checkbox"


                        required />
                      {/* <span class="checkmark"></span> */}
                    </label>
                  })}
                </div>

              </div>

            )
          }
        </>

      );
      break;
    }
    case QUESTIONS_TYPE.DROP_DOWN_MULTI_SELECT: {
      InputBasedOnType = (
        <MultiSelectComponent
          options={question.options}
          handleCurrentChange={handleCurrentChange}
        />
      );
      break;
    }

    case QUESTIONS_TYPE.MULTI_SELECT: {
      InputBasedOnType = (
        <div className="radio-group">
          <label className='Label'>
            <input
              type="radio"
              name="answers"
              onChange={(e) => multiSelectHandle(e, 'true')}
              required
            />
            Yes
          </label>
          <label className='Label'>
            <input
              type="radio"
              name="answers"
              onChange={(e) => multiSelectHandle(e, 'false')}
              required
            />
            No
          </label>
        </div>
      );
      break;
    }

    case QUESTIONS_TYPE.TABLE: {
      InputBasedOnType = (
        <DeviationTable
          question={question}
          handleCurrentChange={handleCurrentChange}
        />
      );

      break;
    }

    default:
      InputBasedOnType = '';
  }

  return <Form>{InputBasedOnType}</Form>;
}

InputComponent.propTypes = {
  question: PropTypes.any,
  handleCurrentChange: PropTypes.func,
  onChange: PropTypes.func,
  handleNOCmodal: PropTypes.func,
  preFilledAnswers: PropTypes.any,
};

InputComponent.defaultProps = {
  handleNOCmodal: () => { },
};

