import React from 'react';
import Header from '../components/Header';
import ScrutinyTableView from '../components/ScrutinyTableView';
import SubHeader from '../components/SubHeader';

export default function FeeVerification(props) {

  return (
    <div>
      <Header />
      <SubHeader level1="Verification" level3="Fee Verification" />
      <ScrutinyTableView viewPage={'single_view'} />
    </div>
  );
}
