import React from 'react';
import Header from '../components/Header';
import ChasingSidebar from '../components/ChasingSidebar';
import { Card, CardDeck, Table } from 'react-bootstrap';
import BarGraph from '../components/BarGraph';
import { Link } from 'react-router-dom';
import ChasingDashboard from '../components/ChasingDashboard'

export default function ChasingDashboardPage() {
  return (
    <div style={{padding: '0', overflow: 'hidden', height: '100%'}}>
      <Header />
      <ChasingSidebar />
      <ChasingDashboard />
    </div>
  );
}
