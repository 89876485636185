import { gql } from '@apollo/client';

export const GET_FILTERED_APPLICATIONS = gql`
query applicationsByDateAndTypeFilter(
  $filter: ApplicationFilter!
  $searchParam: String
  $receivedDateParam: String
  $status: [String!]
  $applicationType: [String!]
  $page: Int
  $receivedDateSort: String
  $assignedDateSort: String
  $all:Boolean
) {
  applicationsByDateAndTypeFilter(
    filter: $filter
    searchParam: $searchParam
    receivedDateParam: $receivedDateParam
    status: $status
    applicationType: $applicationType
    page: $page
    perPage: 10
    receivedDateSort: $receivedDateSort
    assignedDateSort: $assignedDateSort
    all:$all
  ){
    totalCount
    totalPages
    records {
      applicant {
        applicantName
      }
      currentOfficerLevel
      finalRemark
      applicantListId
      applicationType
      referenceApplicationType
      applicationIdentifier
      approvalFor
      id
      createdAt
      appliedOn
      assignedDate
      isShowCauseRaised
      paymentCompletedAt
      dueDate
      dangerDate
      deemedDate
      deemedDueDate
      additional
      revision
      additionalType
      isCorrection
      correctionStatus
      isApprove
      stageStates {
        name
        status
        dueDate
        receivedDate
        submittedDate
        completedDate
        stageOfficers {
          action
          forwardedDate
          id
          officerLevel
          officerName
          officerRole
        }
      }
      status
      property {
        actualPlotArea
        plotAddress
        # following is commented as it causes graphql error
        # floor {
        #   noOfFloors
        # }
        location {
          village
        }
      }
      status
    }
  }
}


`;
