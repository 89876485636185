import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { typeDefs } from './schema';
import { getAllLocalData } from '../services/localStorage';
import environment from 'environment';
import { GET_OFFICER_LOGIN_STATUS } from './query/officerLoginStatus';
import { GET_OFFICER_PROFILE } from './query/officerProfile';
import { setContext } from '@apollo/client/link/context';

const cache = new InMemoryCache({
  typePolicies: {
    Application: {
      fields: {
        property: {
          merge(existing, incoming) {
            return {
              ...existing,
              ...incoming,
            }; // to remove unecessary refetches, and unneccessary cache table extentions & property has sub types
          },
        },
      },
    },
    Field: {
      keyFields: false,
    },
  },
});

const httpLink = createHttpLink({
  uri: environment.graphqlBaseUrl,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // eslint-disable-next-line no-undef
  const token = sessionStorage.getItem('accessToken');
  // console.log('using new headeers', token);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  typeDefs,
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

const initlocalData = getAllLocalData();

client.writeQuery({
  query: GET_OFFICER_PROFILE,
  data: { Officer: { __typename: 'Officer', ...initlocalData } },
});
client.writeQuery({
  query: GET_OFFICER_LOGIN_STATUS,
  data: { isLoggedIn: !!initlocalData.accessToken },
});

client.onResetStore(() => {
  const localData = getAllLocalData();
  client.writeQuery({
    query: GET_OFFICER_PROFILE,
    data: { Officer: { __typename: 'Officer', ...localData } },
  });
  client.writeQuery({
    query: GET_OFFICER_LOGIN_STATUS,
    data: { isLoggedIn: !!localData.accessToken },
  });
});
