import React from 'react'

function SpinLoader() {
  return (
  
      <div class="Spinloader"></div>
  )
}

export default SpinLoader
