import { useMutation } from '@apollo/client';
import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Item from 'antd/lib/list/Item';
import React from 'react'
import { useState } from 'react';
import { Form, FormLabel, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { UPDATE_CORRECTION } from '../../graphql/mutatution/updateCorrection';
import LoadingScreen from '../LoadingScreen/index';
import "./index.scss"
function CorrectionApprove(props) {
  console.log(props,"checking")
         const [Remarks,setRemarks] = useState("")
    const [show, setShow] = useState(false);
      const [loading,setLoading] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
const [updateCorrection,{errors,called,looading}] = useMutation(UPDATE_CORRECTION) 

    // const handleClickAprove = () => {

    // }

    // const handleApprove = () =>{
    //     setLoading(true)
    //     updateCorrection({
    //         variables:{
    //             isApprove:true,
    //             correctionId:props.correctionId,
    //             remark:""
    //         }

    //     }).then(()=>{
    //         if(!errors && !called ) {
    //             setLoading(false)
    //             window.location.reload()
    //         }
    //     }).catch((err)=>{
    //           console.log(err)
    //           setLoading(false)
    //     })
    //     //console.log(props.correctionId,'correctionId')
    // }
const handleChangeEvent = (e) => {
    const selectValue = e.target.value
 
    if(selectValue === "Approve"){
      setLoading(true)
        updateCorrection({
            variables:{
                isApprove:true,
                correctionId:props.correctionId,
                remark:""
            }

        }).then(()=>{
            if(!errors && !called && !looading ) {
                setLoading(false)
                window.location.reload()
            }
        }).catch((err)=>{
              console.log(err)
              setLoading(false)
        })
    }else if (selectValue === 'Reject') {
        // console.log("false");
        setShow(true)
    }
}

const handleChangeRejectForm = (e) => {
  // console.log(e.target.Reject.value , props.correctionId)
setRemarks(e.target.value)
}

const handleSubmitReject = () => {
  setLoading(true)
    updateCorrection({
        variables:{
            isApprove:false,
            correctionId:props.correctionId,
            remark:Remarks
        }

    }).then(()=>{
        if(!errors && !called && !looading) {
            setLoading(false)
            window.location.reload()
        }
    }).catch((err)=>{
          console.log(err)
          setLoading(false)
    })
}
    function Example() {

      
        return (
          <>
      
            <Modal show={show} centered onHide={handleClose}>

              <Modal.Header> Are you sure ! You want to Reject  {props.applicantype?.replaceAll("_"," ") }</Modal.Header>
              <Modal.Body>
                <FormLabel>Remarks</FormLabel>
              <TextArea  placeholder='Enter Remarks' type='text'  onChange={handleChangeRejectForm} />
              
          
                   
              </Modal.Body>
              <Modal.Footer>
                <Button width="30%" variant="secondary" onClick={handleClose}>
                  Close
                </Button>
           <Button width="30%"
                  variant="primary"
                   disabled={Remarks != "" ? false : true }
                   onClick={handleSubmitReject}
                   >
                                {loading ?  (
      <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) :
                 "Reject"}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      }
      

  return (
    <>
    {Example()}
  {loading &&   <LoadingScreen /> }

    <div className='d-flex'>
    <select onChange={handleChangeEvent}>
        <option > {props.isAproved === null ?  "Select" : props.isAproved === true ? "Aproved" : "Rejected"  }</option>
        <option value="Approve" >{props.isAproved == true && props.isAproved != false ? 'select' : "Aprove" }</option>
        <option value='Reject'>{props.isAproved == false && props.isAproved != true ? 'select' : "Reject"} </option>
    </select>
          {/* <button
          onClick={()=>handleShow()}
           style={{border:"none", color:"white", fontSize:"14px",borderRadius:"20px",height:"30px",backgroundColor:"rgb(28, 154, 91)"}}>Approve</button>  <span style={{fontSize:"30px"}}>/</span>
            <button style={{border:"none", color:"white", fontSize:"14px",borderRadius:"20px",height:"30px",backgroundColor:"#fa4747"}}>Reject</button> */}
    </div>
  
    </>
  )
}

export default CorrectionApprove