import React, { useState } from 'react';
import AuthLayout from '../components/layouts/AuthLayout';
import { Form, Button, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

function ChangePassword(props) {
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConf, setPasswordConf] = useState('');
  const [otp, setOtp] = useState('');
  const [validated, setValidated] = useState(false);

  const handleChangeMobileNumber = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangePasswordConf = (event) => {
    setPasswordConf(event.target.value);
  };

  const handleChangeOtp = (event) => {
    setOtp(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let inputData = {
        officer: {
          mobile: mobileNumber,
          password: password,
          password_confirmation: passwordConf,
          otp_secret_key: otp,
        },
      };
      props.changePin(inputData);
    }
  };

  let submitButton;
  if (!props.showSpinner) {
    submitButton = (
      <Button variant="primary" type="submit">
        Change Password{' '}
      </Button>
    );
  } else {
    submitButton = (
      <Button variant="primary">
        <div className="spinner-border" role="status">
          <span className="sr-only"> Loading... </span>{' '}
        </div>{' '}
      </Button>
    );
  }

  return (
    <AuthLayout>
      <h4>Officer&apos;s Password Change</h4>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicNumber">
          <Form.Label> Mobile Number </Form.Label>{' '}
          <Form.Control
            required
            type="number"
            placeholder="Enter mobile number"
            value={mobileNumber}
            onChange={handleChangeMobileNumber}
          />
          <Form.Control.Feedback type="invalid">
            Please choose a mobile number
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label> Enter Password </Form.Label>{' '}
          <Form.Control
            required
            type="password"
            placeholder="Enter Enter Password"
            value={password}
            onChange={handleChangePassword}
          />
          <Form.Control.Feedback type="invalid">
            Please choose a Password
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formBasicPasswordConf">
          <Form.Label> Renter Password </Form.Label>{' '}
          <Form.Control
            required
            type="password"
            placeholder="Re enter Password"
            value={passwordConf}
            onChange={handleChangePasswordConf}
          />
          <Form.Control.Feedback type="invalid">
            Please enter the same password
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formBasicOtp">
          <Form.Label> Enter OTP </Form.Label>{' '}
          <Form.Control
            required
            type="number"
            placeholder="Enter otp "
            value={otp}
            onChange={handleChangeOtp}
          />
          <Form.Control.Feedback type="invalid">
            Please enter the OTP
          </Form.Control.Feedback>
        </Form.Group>{' '}
        <Row> {submitButton} </Row>{' '}
      </Form>{' '}
    </AuthLayout>
  );
}

ChangePassword.propTypes = {
  changePin: PropTypes.func,
  showSpinner: PropTypes.bool,
};

export default ChangePassword;
