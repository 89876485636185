import React, { useState } from 'react';
import AuthLayout from '../components/layouts/AuthLayout';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import history from '../services/history';
import { RESET_OFFICER_PASSWORD } from '../graphql/mutatution/resetOfficerPassword';
import { useMutation } from '@apollo/client';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

function ResetPassword(props) {
  const [username, setUsername] = useState('');
  const [validated, setValidated] = useState(false);
  const [mutation, { loading, error }] = useMutation(RESET_OFFICER_PASSWORD);
  const [captcha, setCaptcha] = useState(false)
  const [captchaKey, setCaptchaKey] = useState("")
  const [captchaError, setCaptchaError] = useState('')
  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      setCaptchaError('')
      let inputData = {
        officer: {
          username: username,
        },
      };

      mutation({
        variables: {
          username: inputData.officer.username,
        },
      })
        .then((data) => {
          console.log(data.data.resetOfficerPassword);
          history.push('/reset-password-new', { username: username });
        })
        .catch((e) => {
          console.log(e);
        });
    }

  };

  function onChange(value) {
    axios.post("https://uat-backend.tsbpass.com/api/v1/users/verify_captcha", { captcha_response: value })
      .then((response) => {
        if (response?.data?.success == 'Captcha challenge is correctly solved') {
          setCaptcha(true)
        }
      }).catch((error) => { setCaptchaError(error) })
  }

  let resetButton;
  if (!loading) {
    resetButton = (
      <Button
      // style={{}}
        variant="primary"
        type="submit"
        style={{fontSize:"12px",width:"180px"}}
        className="d-flex align-items-center justify-content-between"
      >
        <span   className="text-uppercase">Reset Password</span>
        <img src={require('../assets/images/arrow.svg')} alt="" />
      </Button>
    );
  } else {
    resetButton = (
      <Button variant="secondary">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Button>
    );
  }

  return (
    <AuthLayout>
      <h4>Officer&apos;s Password Change</h4>
      <Form style={{width:"75%"}} noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Username</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Enter username"
            value={username}
            onChange={handleChangeUsername}
          />
          <Form.Control.Feedback type="invalid">
            Please enter the username
          </Form.Control.Feedback>
        </Form.Group>
        {/* <ReCAPTCHA
    sitekey= "6LfsRX8lAAAAAGde93_e-imj9fjsbN7oTbjyfmHe"
    onChange={onChange}
  /> */}
        <span>
          {error ? <span>{error.message}</span> : null} <br />
          {captchaError}
        </span>
        <Row className={' align-items-center '}>
          <Col>{resetButton}</Col>
          <Col >
            <p className="forgot-password text-right">
              <Link to="/login">Back</Link>
            </p>
          </Col>
        </Row>
      </Form>
    </AuthLayout>
  );
}

export default ResetPassword;
