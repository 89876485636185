import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import './index.scss';
import { Card, Form, Carousel, Button, Modal } from 'react-bootstrap';
import history from '../../services/history';
import { useParams } from 'react-router';
import '../VerificationCards/index.scss';
import { useMutation, useQuery } from '@apollo/client';
import {
  SUBMIT_FINAL_REMARK_L2,
  SUBMIT_FORM,
} from '../../graphql/mutatution/submitForm';
// import {CloseIcon} from '@mui/icons-material';

import { GET_RECOMMENDATION_BUTTONS } from '../../graphql/query/getRecommendations'
import { UPDATE_APPLICATION_STATUS } from '../../graphql/mutatution/updateApplicationStatus';
import { AppContext } from './../../AppContext';
import { GET_OFFICERS_APPLICATION } from '../../graphql/query/officersApplication';
import { APPLICATION_COMMENTS } from '../../graphql/query/applicationComments';
import AnswerListComponent from '../AnswersListComponent';
import ScrutinyTableView from '../../components/ScrutinyTableView/sw-fees';
import { GET_OFFICER_PROFILE } from '../../graphql/query/officerProfile';
import NotesModal from '../NotesModal';
import { toast } from 'react-toastify';
import {
  isStageRecommended,
} from '../../util/helperFunctions';
import MultiSelectDropdown from '../MultiSelectDropdown';

import CarousalQuestions from './carousalQuestions';
import { Field } from 'formik';
import FeeConditions from '../FeeCondtions/feeConditions';
import ProceedingCondtions from "../Proceeding Condtions/ProceedingCondtions"
import { APPLICAITON_CONDTIONS } from '../../graphql/query/FeeCondtions';
import SiteVerification from '../../pages/SiteVerification';
import { log } from '@craco/craco/lib/logger';
import SiteRemarks from '../ReviewCards/siteRemarks';
import { DialogContentText } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DeviationTable from '../DeviationTable';
import ReviewRemarksPopUp from '../IAIRQuestions/ReviewRemarks';




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}


function QuestionCards({
  officersFormDataGrouped,
  officerReviewRemarksData,
  formId,

  recomID,
  applicationIdentifier,
  isVerification,
  showSidebarHandler,
  showSidebar,
  stageState,
  formData,
  status,
  isFormSubmitOrNot
}) {

  // console.log(status,"checking");
  //  console.log(officersFormDataGrouped,"Question cards ")
  //answer[field.dbField]
  const [appIdentifier] = useState(applicationIdentifier || '');
  const { type, id } = useParams();
  const [carousalIndex, setCarousalIndex] = useState(0);
  const [remarksView, setRemarksView] = useState(true);
  const [submitFormAnswersSingleWindow, { called, errors }] =
    useMutation(SUBMIT_FORM);
  const [updateApplicationStatus, { called3, errors3 }] = useMutation(
    UPDATE_APPLICATION_STATUS
  );
  const [submitFormForL2, { calledL2, errorsL2 }] = useMutation(
    SUBMIT_FINAL_REMARK_L2
  );


  const { data: recommendationsButtons } = useQuery(GET_RECOMMENDATION_BUTTONS, {
    fetchPolicy: 'cache-first',
    variables: { id: id, formInstanceId: recomID, },
  });

  // console.log('responsegetRecommendationsButtons', recommendationsButtons)

  const [formStatus, setFormStatus] = useState('');
  const [recommendationStatus, setRecommendationStatus] =
    useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);
  const [applicationType, setApplicationType] = useState(null);
  const [currentOfficerLevel, setCurrentOfficerLevel] = useState(null);
  const [remarkValue, setRemarkValue] = useState('');
  const { permissionLevel, currentFlow, officerType } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showPopUpAfterSubmit, setShowPopUpAfterSubmit] = useState(false);
  const handleClosePopUpAfterSubmit = () => setShowPopUpAfterSubmit(false);
  const handleShowAfterSubmit = () => setShowPopUpAfterSubmit(true);
  const [recommendValidation, setRecommendValidation] = useState(false);
  const [showNocModal, setShowNocModal] = useState(false);
  const [sucessIcon, setSucessIcon] = useState(false)
  const [isOccL2UpdatedTable, setIsOccL2UpdatedTable] = useState(false);
  const [isFeeCondtionsSubmit, setIsFeeConditionsSubmit] = useState('')
  const [ShowReviewRemaks, setShowReviewRemaks] = useState(false)
  const handleShowReviewRemaks = () => {
    setShowReviewRemaks(false)
  }
  const {
    loading: loadingOfficerApplication,
    error: fetchApplicationError,
    data: applicationData,
  } = useQuery(GET_OFFICERS_APPLICATION, {
    fetchPolicy: 'no-cache',
    variables: { id: id },
  });
  let offcirdata = officerReviewRemarksData?.filter(item => { return item.title == "Site Inspection" && item.stageState.completedDate !== null })
  //  function  SiteOfficerRemarks  () {
  //  officerReviewRemarksData?.fitler((item)=>{
  // console.log(offcirdata,'offcirdata');

  //  })

  //console.log(offierRemarks,'offierRemarks');
  //  }


  // const { data: AdditionalCondtions, loading } = useQuery(APPLICAITON_CONDTIONS, {
  //   // fetchPolicy: 'cache-first',
  //   variables: {
  //     applicationId: id,
  //     typeOf: "fee"
  //   }
  // })


  // console.log(applicationData?.officersApplication?.feeIsSubmitted,'applicationData')
  const handlePopUp = () => {
    setShowReviewRemaks(true)
  }

  const { data: applicationComments } = useQuery(APPLICATION_COMMENTS, {
    fetchPolicy: 'cache-first',
    variables: { id: id },
  });
  const { data: officerData } = useQuery(GET_OFFICER_PROFILE);

  const [selected, setSelected] = useState([]);
  const [otherRemarks, setOtherRemarks] = useState('');
  const [NOCselected, setNOCselected] = useState([]);
  const [NOCoptions, setNOCoptions] = useState([]);
  const [NOCrequired, setNOCrequired] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true)

  const [ApprovalFor, setIsOccupancy] = useState(null)
  useEffect(() => {
    if (fetchApplicationError) {
    }
    setIsOccupancy(applicationData?.officersApplication?.approvalFor)
    setApplicationType(applicationData?.officersApplication?.applicationType);
    setCurrentOfficerLevel(
      applicationData?.officersApplication?.currentOfficerLevel
    );
  }, [applicationData, fetchApplicationError]);


  // function checkingFeeSubmit(bool) {tio
  //  if( officerData.me?.actions?.includes('fee')){
  //     return bool;
  //  }else {
  //   return false
  //  }
  //}

  function isQuestionSubmited() {
    //  let  isQuestionSubmited =    officersFormDataGrouped.filter((questions, i) =>   questions.answer.action == "view"
    //     )
    // console.log(isQuestionSubmited)
  }


  const isCurrentStageRecommended = () => {
    let currentStage;
    if (window.location.pathname.split('/').includes('site-verification')) {
      currentStage = 'SITE VERIFICATION';
    } else if (
      window.location.pathname.split('/').includes('title-verification')
    ) {
      currentStage = 'TITLE VERIFICATION';
    } else if (
      window.location.pathname.split('/').includes('technical-verification')
    ) {
      currentStage = 'TECHNICAL VERIFICATION';
    }
    if (!currentStage) return false;
    return isStageRecommended(currentStage, stageState, currentOfficerLevel);
  };

  const isRemarksAvailable = () => {
    let notRecommendedRemark = [...selected];
    if (NOCrequired) return true;

    if (formStatus === 'RECOMMENDED' && officerType === 'GHMC') return true;

    if (formStatus === 'NOT_RECOMMENDED') {
      if (selected.find((el) => el.label === 'Other')) {
        if (otherRemarks) {
          notRecommendedRemark = [
            ...selected.filter((item) => item.label !== 'Other'),
            { label: 'Other', value: otherRemarks },
          ].map((item) => item.value);
        } else {
          return false;
        }
      }
    }

    let finalRemark =
      formStatus === 'NOT_RECOMMENDED' ? notRecommendedRemark : remarkValue;

    let updatedFinalRemark =
      typeof finalRemark === 'string'
        ? finalRemark
        : JSON.stringify(finalRemark);

    if (updatedFinalRemark) {
      if (formStatus === 'NOT_RECOMMENDED') {
        return !(updatedFinalRemark === '[]');
      }
      return true; // remarks present
    }
    return false; // remarks empty
  };


  const submitRecommendationForm = () => {
    setIsLoading(true);

    let statusBeforeSubmittion = formStatus;

    let notRecommendedRemark = [
      ...selected.filter((item) => item.label !== 'Other'),
      { label: 'Other', value: otherRemarks },
    ].map((item) => item.value);

    let updatedFinalRemark =
      statusBeforeSubmittion === 'NOT_RECOMMENDED'
        ? notRecommendedRemark
        : remarkValue;

        console.log(typeof (updatedFinalRemark),'updatedFinalRemark')

    if (
      (applicationType === 'OCCUPANCY' ||
        applicationType === 'SELF CERTIFICATION' ||
        applicationType === 'REGISTRATION') &&
      currentOfficerLevel === 'L1'
    ) {
      statusBeforeSubmittion = 'SUBMITTED';
      setFormStatus(statusBeforeSubmittion);
    }
    if (applicationType === 'SINGLE WINDOW' && currentOfficerLevel === 'L1') {
      statusBeforeSubmittion = formStatus;
    }
    const data = ansState.current;
    let dataBeforeSubmittion = { ...data };
    // if (remarkValue) {
    //   dataBeforeSubmittion.finalRemark = remarkValue;
    // }
    const test = JSON.parse(JSON.stringify(dataBeforeSubmittion));

    if (dataBeforeSubmittion.requires_noc) {
      if (
        test.requires_noc.parent_answer === 'true' &&
        currentOfficerLevel === 'L1'
      ) {
        statusBeforeSubmittion = 'SUBMITTED';
      } else if (
        test.requires_noc.parent_answer === 'true' &&
        currentOfficerLevel !== 'L1'
      ) {
        statusBeforeSubmittion = 'RECOMMENDED';
      }
      let answer;
      if (dataBeforeSubmittion.requires_noc.parent_answer === 'true') {
        answer = NOCselected.map((el) => el.value);
      } else {
        answer = [];
      }

      answer = JSON.stringify(answer);
      dataBeforeSubmittion.requires_noc.parent_answer = answer;
    }
    // if (currentOfficerLevel === 'L2') {
    //   submitFormForL2({
    //     variables: {
    //       formInstanceId: formId,
    //       status: statusBeforeSubmittion,
    //       finalRemark:
    //         typeof updatedFinalRemark === 'string'
    //           ? updatedFinalRemark || 'N/A'
    //           : JSON.stringify(updatedFinalRemark),
    //     },
    //   })
    //     .then(() => {
    //       setIsLoading(false);
    //       if (!errorsL2 & !calledL2) {
    //         console.log('xyz')
    //         // redirectAfterSubmit();
    //       }
    //     })
    //     .catch((e) => {
    //       setIsLoading(false);
    //       setErrMsg(e.message);
    //       console.error(e);
    //     });
    // } else {
    const OfficerId = window.sessionStorage.getItem('id');
    // if (permissionLevel === 'PANCHAYAT SECRETARY') {
    //   updateApplicationStatus({
    //     variables: {
    //       applicationId: id,
    //       finalRemark:
    //         typeof updatedFinalRemark === 'string'
    //           ? updatedFinalRemark
    //           : JSON.stringify(updatedFinalRemark),
    //       status:
    //         statusBeforeSubmittion === 'RECOMMENDED' ||
    //           statusBeforeSubmittion === ''
    //           ? 'SHORTFALL'
    //           : 'REJECTED',
    //       eligibilityForRefund: 'false',
    //     },
    //   })
    //     .then(() => {
    //       setIsLoading(false);
    //       if (!errors3 & !called3) {
    //         console.log('123')
    //         // redirectAfterSubmit();
    //       }
    //     })
    //     .catch((e) => {
    //       setIsLoading(false);
    //       setErrMsg(e.message);
    //       console.error(e);
    //     });
    // } else {
    submitFormAnswersSingleWindow({
      variables: {
        formInstanceId: formId,
        data: dataBeforeSubmittion,
        status: statusBeforeSubmittion || 'RECOMMENDED',
        officerId: OfficerId,
        finalRemark:   hasSpecialCharacters(updatedFinalRemark)  ? JSON.stringify(updatedFinalRemark) : 
                    typeof updatedFinalRemark === 'string'
                        ? updatedFinalRemark
                        : JSON.stringify(updatedFinalRemark),
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (!errors & !called) {
          // toast.success(response?.data?.submitFormAnswersSingleWindow, {
          //   position: 'top-right',
          //   autoClose: 5000,
          //   hideProgressBar: false,
          // });
          history.push('/post-verification');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setErrMsg(e.message);
        // console.error(e);
      });
    // }
    // }
  };

  const officerCanRecommendOrNot = () => {
    return !(
      (applicationType === 'REGISTRATION' && currentOfficerLevel === 'L1') ||
      (applicationType === 'SELF CERTIFICATION' && currentOfficerLevel === 'L1')
    );
  };


  const ModalComponent = () => {
    return (
      <Modal
        show={showPopUpAfterSubmit}
        onHide={handleClosePopUpAfterSubmit}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: '0 none', paddingBottom: '0px' }}
        />
        <Modal.Body style={{ paddingTop: '0px' }}>
          <div className="px-2">
            {NOCrequired
              ? 'Are you sure you want to submit to NOC concerned departments'
              : officerCanRecommendOrNot()
                ? `Are you sure you want to  ${recommendationStatus &&
                  recommendationStatus === 'NOT_RECOMMENDED'
                  ? 'Not Recommend'
                  : 'Recommend'
                } and submit?`
                : `Are you sure you want to submit?`}
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: '0 none' }}
          className="d-flex justify-content-between"
        >
          <Button
            className="btn btn-secondary"
            onClick={() => handleClosePopUpAfterSubmit()}
            style={{ width: '30%' }}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-success"
            onClick={() => {
              submitRecommendationForm();
              handleClosePopUpAfterSubmit();
            }}
            style={{ width: '60%' }}
          >
            {NOCrequired
              ? 'Submit'
              : officerCanRecommendOrNot()
                ? recommendationStatus &&
                  recommendationStatus === 'NOT_RECOMMENDED'
                  ? 'Not Recommended'
                  : 'Recommended'
                : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const displayReviewRemarks = () => {
    return (
      <Modal
        size="xl"

        aria-labelledby="contained-modal-title-vcenter"
        show={ShowReviewRemaks}
        onHide={handleShowReviewRemaks}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: '0 none', paddingBottom: '0px' }}
        />
        <Modal.Body style={{ paddingTop: '0px' }}>
          <ReviewRemarksPopUp id={id} status={applicationData?.officersApplication?.applicationType} />
        </Modal.Body>

      </Modal>
    );
  };

  const [open, setOpen] = useState(false);



  const closeDialog = () => {
    setOpen(false);
  };



  const SiteOfficerRemarksPopUp = () => {
    return (
      <div style={{ display: 'block', position: 'initial' }}>
        <BootstrapDialog
          onClose={closeDialog}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle color="green" id="customized-dialog-title" onClose={closeDialog}>
            Site Inspection Remarks
          </BootstrapDialogTitle>
          <DialogContent>
            <DialogContentText>
              {offcirdata?.map((questions, i) =>
                <div style={{ width: "500px", height: "350px" }}>
                  <SiteRemarks
                    id={id}
                    questionsData={questions}
                    formId={questions.id}
                    applicationIdentifier={applicationIdentifier}
                    isVerification={isVerification}
                    applicationType={applicationType}
                  />
                </div>
              )}
            </DialogContentText>
          </DialogContent>

        </BootstrapDialog>
      </div>
    )
  }

  // useEffect(()=>{
  //   console.log(checkingFeeSubmit(true),"checkingFeeSubmit");
  // })

  // eslint-disable-next-line no-unused-vars
  const isFeeOrProceedingSubmitted = () => {
    return (
      applicationData?.officersApplication?.feeIsSubmitted === true ||
      applicationData?.officersApplication?.proceedingIsSubmitted === true
    );
  };

  const didOfficerNotComment = () => {
    // console.log(AdditionalCondtions?.applicationConditions?.isSubmitted ,'isFeeCondtionsSubmit');
    //   if (isQuestionSubmited()) {
    //     return false;

    // }

    // console.log(isQuestionSubmited(),'isQuestion')
    // debugger;
    if (isFormSubmitOrNot() == false) {
      if (
        !applicationComments ||
        applicationComments?.applicationComments.length === 0) {
        return true; // officer did not comment
      }
    } else if (isFormSubmitOrNot() == true) {
      return true
    }

    let isThisOfficerCommentPresent = false; // did not comment
    if ((applicationData?.officersApplication?.status != "FEE_VERIFICATION") &&
      isApprovalForOrNot()
    ) {
      if (officerData.me?.actions?.includes('fee') && applicationData?.officersApplication?.newWorkFlow == true) {
        if (isFeeOrProceedingSubmitted() || applicationData?.officersApplication?.isObjected == true) {
          applicationComments.applicationComments.forEach((el) => {
            // eslint-disable-next-line no-undef
            if (el.officer.id === sessionStorage.getItem('id') && el.active === true &&
              (applicationData?.officersApplication?.isObjected == true || applicationData?.officersApplication?.isFeeSubmitted == true)
            ) {
              isThisOfficerCommentPresent = true; // did commnet
            }
          });
        }
      } else {
        applicationComments.applicationComments.forEach((el) => {
          // eslint-disable-next-line no-undef
          if (el.officer.id === sessionStorage.getItem('id') && el.active === true) {
            isThisOfficerCommentPresent = true; // did commnet
          }
        });
      }
    } else {
      applicationComments.applicationComments.forEach((el) => {
        // eslint-disable-next-line no-undef
        if (el.officer.id === sessionStorage.getItem('id') && el.active === true) {
          isThisOfficerCommentPresent = true; // did commnet
        }
      });
    }


    return !isThisOfficerCommentPresent;


  };

  const showAnswersAndSubmit = () => {
    let currentVerification = 'TITLE VERIFICATION';
    const currentUrlPath = history.location.pathname;
    if (currentUrlPath.includes('site')) {
      currentVerification = 'SITE VERIFICATION';
    } else if (currentUrlPath.includes('title')) {
      currentVerification = 'TITLE VERIFICATION';
    } else if (currentUrlPath.includes('technical')) {
      currentVerification = 'TECHNICAL VERIFICATION';
    } else {
      currentVerification = 'TECHNICAL VERIFICATION';
    }
    return (
      <>
        <AnswerListComponent
          id={id}
          currentVerification={currentVerification}
          isVerification={isVerification}
        />
      </>
    );
  };

  function hasSpecialCharacters(text) {
    const specialCharactersRegex = /[^a-zA-Z0-9.,'"\s]/g;
    return specialCharactersRegex.test(text);
  }

  const RemarkRecommendationSection = () => {
    return (
      <div className="rec-wrapper">
        <div className="recommand-main-wrapper btn-recommend-notrecommend">
          <h2>Remarks & Recommendation</h2>
          {/* <div className="rec-buttons"> */}
          <p>What is your recommendation ? </p>
          {recommendationsButtons?.recommendations?.length > 0 &&
            recommendationsButtons?.recommendations.map((recomm, i) =>
              <button
                className={
                  recommendationStatus === recomm
                    ? 'btn btn-recmnd'
                    : 'btn btn-light btn-notrecmnd'
                }
                onClick={() => {
                  setFormStatus(recomm);
                  setRecommendationStatus(recomm);
                }}
                disabled={isCurrentStageRecommended()}
              >
                {/* <img src={require('../../assets/images/successLightGreen.svg')} />{' '} */}
                {recomm === "RECOMMENDED" ? 'RECOMMEND FOR APPROVAL' :
                  recomm === "NOT_RECOMMENDED" ? 'RECOMMEND FOR REJECTION' :
                    recomm === "SHORTFALL" ? 'RECOMMEND FOR SHORTFALL' :
                      recomm === 'MORTGAGE_SHORTFALL' ? "RECOMMEND FOR  MORTGAGE SHORTFALL" :
                        recomm === 'LINE_VERIFICATION' ? 'RECOMMEND FOR  LINE  DEPARTMENT ' :
                          recomm === 'FEE_INTIMATION' && "RAISE FEE INTIMATION"}

              </button>
            )}
          {/* <button
            className={
              recommendationStatus === 'RECOMMENDED'
                ? 'btn btn-recmnd'
                : 'btn btn-light btn-notrecmnd'
            }
            onClick={() => {
              setFormStatus('RECOMMENDED');
              setRecommendationStatus('RECOMMENDED');
            }}
            disabled={isCurrentStageRecommended()}
          >
            <img src={require('../../assets/images/successLightGreen.svg')} />{' '}
            RECOMMEND FOR APPROVAL{' '}
          </button>
          <button
            className={
              recommendationStatus === 'NOT_RECOMMENDED'
                ? 'btn btn-secondary'
                : 'btn btn-light btn-notrecmnd'
            }
            onClick={() => {
              setFormStatus('NOT_RECOMMENDED');
              setRecommendationStatus('NOT_RECOMMENDED');
              setRemarksView(true);
            }}
            disabled={isCurrentStageRecommended()}
          >
            <img src={require('../../assets/images/successLightGreen.svg')} />{' '}
            RECOMMEND FOR REJECTION{' '}
          </button> */}
          {/* </div> */}
          {formStatus === 'NOT_RECOMMENDED' ? (
            <div style={{ width: '100%' }}>
              <MultiSelectDropdown
                hidePadding={true}
                selected={selected}
                setSelected={setSelected}
                otherRemarks={otherRemarks}
                setOtherRemarks={setOtherRemarks}
                formStatus={formStatus}
              />
            </div>
          ) : (
            <div className="updated_bottom_section 2">
              <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label className="form-Label">Final Remarks</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Text here"
                    value={remarkValue}
                    onChange={(event) => setRemarkValue(event.target.value)}
                  />
                </Form.Group>
              </Form>
            </div>
          )}
          {!isRemarksAvailable() ? (
            <p style={{ color: 'red', display: 'block' }}>
              Please add remarks
            </p>) : ""}
          <div
            className="btn-container"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {!isLoading && (
              <>
                <div
                  className="d-flex flex-column"
                  style={{ width: '250px', alignItems: 'center' }}
                >
                  <button

                    className={
                      carousalIndex === 0
                        ? 'btn btn-success btn-firstPage'
                        : 'btn btn-success '
                    }
                    onClick={() =>
                    //  submitRecommendationForm()
                    {
                      if (
                        officerCanRecommendOrNot() &&
                        !recommendationStatus
                      ) {
                        setRecommendValidation(true);
                      } else {
                        setRecommendValidation(false);
                        handleShowAfterSubmit();
                      }
                    }
                    }
                    // Removed temporarily

                    disabled={!isRemarksAvailable()}
                  >
                    {'CONFIRM & SUBMIT'}
                    <img
                      src={require('../../assets/images/arrow.svg')}
                      alt="next"
                    />
                  </button>
                  <p onClick={() => setShowFinalRecommendation(false)}>
                    Back to Review
                  </p>
                </div>
                {/* Removed temporarily */}
                {recommendValidation ? (
                  <p style={{ color: 'red' }}>
                    Please select recommend or not recommend
                  </p>
                ) : null}
                {didOfficerNotComment() ? (
                  <p style={{ color: 'red', position: "relative", top: "-20px" }}>
                    Please add remarks in View Notes
                  </p>
                ) : null}
              </>
            )}
            {isLoading && (
              <Button variant="secondary">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </Button>
            )}
            <span>{errMsg || null}</span>
          </div>
        </div>
      </div>
    );
  };

  const handleApplicaitonDetails = () => {
    document.getElementsByTagName('body')[0].classList.remove('show-sidebar');
    document.getElementsByTagName('body')[0].classList.remove('Hide-RightSidebar');
    document.getElementsByTagName('body')[0].classList.toggle('show-RightSidebar');

  }

  const ansState = useRef({});
  const handleChildAnswerChange = (dbField, value) => {
    ansState.current[dbField] = value;
  };

  const memoizedHandleAnswerChange = useCallback(handleChildAnswerChange, [
    ansState,
  ]);
  useEffect(() => {
    if (formData.answer?.data) {
      Object.keys(formData.answer.data).forEach((key) => {
        memoizedHandleAnswerChange(key, formData.answer.data[key]);
      });
    }
  }, [formData]);

  // function checkingAction(){

  //  officersFormDataGrouped.filter((item)=>{item?.answer?.action == 'view'}) 


  // }

  const isApprovalForOrNot = () => {
    if (applicationData?.officersApplication?.approvalFor == "FINAL_LAYOUT") {
      return false
    } else if (applicationData?.officersApplication?.approvalFor == 'OCCUPANCY') {
      return false
    } else {
      return true
    }
  }
  const handleCurrentChange = () => {
    setIsOccL2UpdatedTable(true);
  };

  const [activeTab, setActiveTab] = useState(officersFormDataGrouped[0].id);
  const [showFinalRecommendation, setShowFinalRecommendation] = useState(false);
  const [showSiteRemarks, setSiteRemarks] = useState(false)
  const handelCloseRemarks = () => setSiteRemarks(false)
  const handleTabs = (type) => {
    setActiveTab(type);
  };

  return (
    <>
      {ModalComponent()}
      {SiteOfficerRemarksPopUp()}
      {displayReviewRemarks()}
      <div className="main-content">
        <div className="tv-container ">
          {showSidebarHandler(true)}
          {showFinalRecommendation ? (
            <div className={showSidebar ? 'tv-recommend-content' : 'tv-content-expanded'}>
              {RemarkRecommendationSection()}
            </div>
          ) : (
            <>

              {/* <div style={{display:"flex"}}> */}
              <div
                className={
                  showSidebar ? 'tv-header sw-header' : 'tv-header-expanded'
                }
              >
                {/* <div></div> */}
                <ol className="breadcrumbs2">

                  <li style={{ position: 'relative', left: "20px" }}>
                    <a href="#!">{appIdentifier}</a>
                  </li>
                </ol>
                {/* <ol  className="breadcrumbs">
                {offcirdata?.length > 0 && officerData?.me?.role?.name == "Technical Officer"  && offcirdata?.map((questions, i) =>
                  <div style={{fontSize:"12px", fontWeight:"bold", cursor:"pointer" , width:"130px"}} onClick={() =>  setOpen(true)}>
                    {'view site remarks'} {questions.answer != undefined && questions.answer.action == "view" ? <img width="15px" src={require("../../assets/images/icons_56px/Post Verification_active.png")} /> : " "}
                  </div>
                )}
                </ol> */}

                <div style={{ display: 'flex' }}>

                  {show ? <NotesModal handleClose={handleClose} /> : null}
                  {showSidebar && (
                    <div className=" d-flex align-items-center ">
                      <span className="addnote_label_text">
                        Add Note Sheet :
                      </span>
                      <div >
                        {/* <div
                          className="viewButton"
                          type="button"
                          onClick={() => {
                            setShow(true);
                          }}
                        >
                            ADD 
                        </div> */}
                        <div 
                        
                          className="addButton"
                          type="button"
                          onClick={() => {
                            setShow(true);
                          }}
                        >
                        ADD NOTE SHEET
                        </div>
                      </div>

                    </div>
                  )}
                  <div onClick={handlePopUp} className="sidebarToggle"
                    type="button" >  <p className='application_Details_Button'> Review Remarks </p>
                  </div>
                  <div onClick={handleApplicaitonDetails} className="sidebarToggle" style={{ marginLeft: "130px" }}
                    type="button" >  <p className='application_Details_Button'> Application Details </p>
                  </div>
                </div>

              </div>

              {/* </div> */}
              <div className="tv-header-tabs">
                {officersFormDataGrouped.map((questions, i) =>
                  <div className={`tab-item ${activeTab === questions.id ? 'active' : ''}`} onClick={() => handleTabs(questions.id)}>
                    {questions.title} {questions.answer != undefined && questions.answer.action == "view" ? <img width="25px" src={require("../../assets/images/icons_56px/Post Verification_active.png")} /> : " "}
                  </div>
                )}

                {/* {officerData.me?.actions?.includes('checklist') &&
                  <div
                    className={`tab-item ${activeTab === 'checklist' ? 'active' : ''
                      }`}
                    onClick={() => handleTabs('checklist')}
                  >
                    Checklist
                  </div>} */}
                {officerData.me?.actions?.includes('fee') && isApprovalForOrNot() &&
                  (
                    <div
                      className={`tab-item ${activeTab === 'fees' ? 'active' : ''
                        }`}
                      onClick={() => handleTabs('fees')}
                    >
                      Fees
                      {applicationData?.officersApplication?.isFeeSubmitted == true && <img width="25px" src={require("../../assets/images/icons_56px/Post Verification_active.png")} />}
                    </div>
                  )}
                {
                  applicationData?.officersApplication?.approvalFor == 'OCCUPANCY' &&
                  <div className={`tab-item ${activeTab === 123 ? 'active' : ""}`} onClick={() => handleTabs(123)}>Deviation
                    {applicationData?.officersApplication?.isDeviationSubmitted == true && <img width="25px" src={require("../../assets/images/icons_56px/Post Verification_active.png")} />}
                  </div>
                }
                {officerData.me?.actions?.includes('fee') && applicationData?.officersApplication?.newWorkFlow == true &&
                  isApprovalForOrNot() &&
                  <>
                    {status !== 'FEE_VERIFICATION' ?
                      <div
                        className={`tab-item ${activeTab === 'FeeConditions' ? 'active' : ''
                          }`}
                        onClick={() => handleTabs('FeeConditions')}
                      >
                        <div>
                          Fees Condtions  {applicationData?.officersApplication?.feeIsSubmitted && <img width="25px" src={require("../../assets/images/icons_56px/Post Verification_active.png")} />}
                        </div>


                      </div> :
                      <div
                        className={`tab-item ${activeTab === 'ProceedingCondtions' ? 'active' : ''
                          }`}
                        onClick={() => handleTabs('ProceedingCondtions')}
                      >
                        Proceeding Condtions    {applicationData?.officersApplication?.proceedingIsSubmitted && <img width="25px" src={require("../../assets/images/icons_56px/Post Verification_active.png")} />}
                      </div>} </>}

                {/* {officerData?.me?.role?.name == "Technical Officer" && <div
                  className={`tab-item ${activeTab === 'siteverification' ? 'active' : ''
                    }`}
                  onClick={() => handleTabs('siteverification')}
                >
                  Site Verification Remarks   </div> } */}



                {/* <div className={`tab-item ${activeTab === "documents" ? 'active' : ''}`} onClick={() => handleTabs("documents")}>Documents</div> */}




              </div>

              <div
                className={showSidebar ? 'tv-content' : 'tv-content-expanded'}
              >
                {/* {activeTab === 'checklist' ? (
                  isChecklistFilled ? (
                    showAnswersAndSubmit()
                  ) : ( */}
                {/* <>
                  {officerReviewRemarksData.filter(items=>items.title == "Site Inspection").map((questions, i) =>
                    <div className={activeTab === questions.id ? '' : 'hide'}>
                      <CarousalQuestions
                        id={id}
                        questionsData={questions}
                        formId={questions.id}
                        applicationIdentifier={applicationIdentifier}
                        isVerification={isVerification}
                        applicationType={applicationType}

                      />
                    </div>
                  )}
                </> */}

                <>
                  {officersFormDataGrouped?.map((questions, i) =>
                    <div style={{ margin: "20px" }} className={activeTab === questions.id ? '' : 'hide'}>
                      <CarousalQuestions
                        id={id}
                        questionsData={questions}
                        formId={questions.id}
                        applicationIdentifier={applicationIdentifier}
                        isVerification={isVerification}
                        applicationType={applicationData?.officersApplication?.applicationType}
                        applicationStatus={applicationData?.officersApplication?.applicationStatus}
                        formStatus={recommendationStatus}
                        ApprovalFor={ApprovalFor}
                      />
                    </div>
                  )}
                </>

                {/* <>
                  {offcirdata?.map((questions, i) =>
                    <div className={activeTab === questions.id ? '' : 'hide'}>
                      <CarousalQuestions
                        id={id}
                        questionsData={questions}
                        formId={questions.id}
                        applicationIdentifier={applicationIdentifier}
                        isVerification={isVerification}
                        applicationType={applicationType}
                      />
                    </div>
                  )}
                </> */}

                {status !== 'FEE_VERIFICATION' ?
                  <>
                    {activeTab === 'FeeConditions' && (<FeeConditions setIsFeeConditionsSubmit={setIsFeeConditionsSubmit} applicationIdentifier={applicationIdentifier} />)}
                  </> :
                  <>{activeTab === 'ProceedingCondtions' && (<ProceedingCondtions setIsFeeConditionsSubmit={setIsFeeConditionsSubmit} />)} </>
                }

                {activeTab === 'fees' && (
                  <ScrutinyTableView applicationStatus={applicationData?.officersApplication?.applicationStatus} setIsSubmit={setIsSubmit} viewPage="single_window" />
                )}

                {activeTab === 123 && (
                  <div style={{ width: '100%' }}>
                    <DeviationTable
                      question={{
                        order: 'L2',
                        title: 'Deviation Table',
                      }}
                      handleCurrentChange={handleCurrentChange}
                      isDeviationSubmitted={applicationData?.officersApplication?.isDeviationSubmitted}
                      isDeviationUpdated={applicationData?.officersApplication?.isDeviationUpdated}
                    // setIsDeviationUpdated ={setIsDeviationUpdated}
                    />
                  </div>
                )}
                {/* {
  activeTab === 'siteverification' && <>
   {officerReviewRemarksData.map((questions, i) =>
        
                      <CarousalQuestions
                        id={id}
                        questionsData={questions}
                        formId={questions.id}
                        applicationIdentifier={applicationIdentifier}
                        isVerification={isVerification}
                        applicationType={applicationType}

                      />
                    
                  )}
  </>
 } */}
                {/* {(officerData.me?.actions?.includes('fee') && (isChecklistFilled && isFeesCompleted)) || isChecklistFilled && */}

                {/* } */}
              </div>
            </>
          )}


        </div>
        {!showFinalRecommendation &&
          <div style={{ marginTop: "10px" }} className="d-flex flex-column align-items-center justify-content-center ">

     
            <div className="d-flex justify-content-space-between">
              <Button
                style={{ width: "auto", padding: "3px" }}
                className="btn  btn-sucess  comfirm-proceed"
                onClick={() => setShowFinalRecommendation(true)}
                disabled={didOfficerNotComment()}
                variant="outline-success"
              >
                Confirm and Proceed
                &nbsp;

                <img src={require('../../assets/images/arrow.svg')} alt="next" />
              </Button>
            </div>
            <div>
              {didOfficerNotComment() ? (
                <p style={{ color: 'red' }}> {applicationData?.officersApplication?.approvalFor == 'OCCUPANCY' ? '* Please add remarks in View/Add notes and update Deviation ' :
                  '* Please add Remarks in View Notes, Submit Fee, and conditions'
                } </p>
              ) : null}
            </div>
          </div>


        }
      </div>
    </>
  );
}

QuestionCards.propTypes = {
  officersFormDataGrouped: PropTypes.array,
  officersDocumentsDataSorted: PropTypes.array,
  formId: PropTypes.string,
  verificationType: PropTypes.string,
  applicationIdentifier: PropTypes.string,
  isChecklistFilled: PropTypes.bool,
  isVerification: PropTypes.string,
  showSidebar: PropTypes.bool,
  showSidebarHandler: PropTypes.func,
  stageState: PropTypes.any,
  formData: PropTypes.array,
};

export default QuestionCards;

