import React, { useState, useContext, useEffect } from 'react';
import history from '../../services/history';
import './index.scss';

function ChasingSidebar() {
  const [currentPage, setCurrentPage] = useState('summary');
  sessionStorage.setItem('currentChasingPage', 'summary');
  return (
    <div className="sidebar-container" style={{ top: '0px', zIndex: '9999' }}>
      <div
        className="d-flex align-items-center border-bottom"
        style={{ padding: '13px' }}
      >
        <img
          src={require('../../assets/images/logo.png').default}
          width="56"
          alt="logo here"
        />
        <strong style={{ fontSize: '18px' }} className="pl-2">
          TG-bPASS
        </strong>
      </div>
      <ul className="chasing-sidebar-list">
        <li
          className={currentPage === 'summary' ? 'active' : ''}
          style={{ cursor: 'pointer' }}
        >
          <a
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              setCurrentPage('summary');
              sessionStorage.setItem('currentChasingPage', 'summary');
            }}
          >
            Summary Report
            <img
              src={require('../../assets/images/v-down-inactive.svg')}
              alt="Summary Report"
            />
          </a>
        </li>
        <li
          className={currentPage === 'dashboard' ? 'active' : ''}
          style={{ cursor: 'pointer' }}
        >
          <a
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              setCurrentPage('dashboard');
              sessionStorage.setItem('currentChasingPage', 'dashboard');
            }}
          >
            Dashboard
            <img
              src={require('../../assets/images/v-down-inactive.svg')}
              alt="Dashboard"
            />
          </a>
        </li>
        <li
          className={
            currentPage === 'building plan applications' ? 'active' : ''
          }
          style={{ cursor: 'pointer' }}
        >
          <a
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              setCurrentPage('building plan applications');
            }}
          >
            Building Plan Applications
            <img
              src={require('../../assets/images/plus.svg')}
              alt="Building Plan Applications"
            />
          </a>
        </li>
        <li
          className={currentPage === 'building plans' ? 'active' : ''}
          style={{ cursor: 'pointer' }}
        >
          <a
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              setCurrentPage('building plans');
            }}
          >
            Building Plans
            <img
              src={require('../../assets/images/plus.svg')}
              alt="Building Plans"
            />
          </a>
        </li>
        <li
          className={currentPage === 'building plans sw' ? 'active' : ''}
          style={{ cursor: 'pointer' }}
        >
          <a
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              setCurrentPage('building plans sw');
            }}
          >
            Building Plans{' '}
            <span style={{ color: '#A2ADB2', fontSize: '8px' }}>
              (Single Window)
            </span>
            <img
              src={require('../../assets/images/plus.svg')}
              alt="Building Plans (Single Window)"
            />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default ChasingSidebar;
