import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './index.scss';
import Marquee from "react-fast-marquee";
const AuthLayout = (props) => {

  return (
    <div className="login-container">

      <div >
      </div>
      <Container>
        <div className="login-content">
          <div style={{ display: "flex", justifyContent: "space-bewtween", padding: "20px", flexDirection: "column", alignItems: "center", width: "100%", height: "450px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", backgroundColor: "white", borderRadius: "10px" }}>
            <span className='d-flex flex-column align-items-center'>
              <img width="40% " src={require('../../../assets/images/logo.png').default} alt="Logo" />
              <h1 style={{ fontSize: '1.25rem', textTransform: 'none' }}>
                Welcome to  TG-bPASS
              </h1></span>
            {props.children}
          </div>
        </div>

      </Container>

      <div style={{ background: '#023020', width: "100%", padding: "2px 0px 2px 0px", height: "70px", textAlign: "center", color: "white" ,   position: "fixed",
  left: 0,
  bottom: 0,
  width: "100%"}}>
        <p>COPYRIGHT ©2022 TELANGANA  BUILDING PERMISSION APPROVAL AND SELF CERTIFICATION SYSTEM. ALL RIGHTS RESERVED.</p>
        <p>Designed & Developed by : <a style={{ color: '#007bff' }} href='https://www.hyno.co/'>Hyno Technologies LLP</a> </p>
        <img style={{ width: "40px" }} src={require("../../../assets/images/Group 11654@2x.png")} />
      </div>



    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.any,
};

export default AuthLayout;
