import React, { useState, useEffect } from 'react';
import {MultiSelect} from 'react-multi-select-component';



function MultiSelectComponent({
    options,
    handleCurrentChange
}) {
    const [dropdownValue, setDropdownValue] = useState([])

    const updatedOptions = options.map((item, index) => {
        return {
            id: `${index + 1}`,
            label: item.value,
            value: item.value,
        };
    });

    useEffect(() => {
        
        if (dropdownValue.length > 0) {
            var ddd = Array.prototype.map.call(dropdownValue, s => s.value).toString();
            handleCurrentChange("directValue", ddd);
        }
    }, [dropdownValue])

    return (
        <MultiSelect
            className="rejection-multi-select"
            options={updatedOptions}
            value={dropdownValue}
            onChange={setDropdownValue}
            labelledBy={'Select'}
            aria-expanded="true"
        // shouldToggleOnHover={true}
        />
    )
}

export default MultiSelectComponent;
