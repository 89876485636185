import { gql } from '@apollo/client';

export const APPLICATION_COMMENTS = gql`
  query applicationComments($id: ID!) {
    applicationComments(applicationId: $id) {
      application {
        id
        createdAt
        isShowCauseRaised
        isShowCauseRaisedDocUpload
      }
      attachmentUrl
      comment
      active
      createdAt
      id
      officer {
        accessToken
        email
        firstName
        id
        lastName
        lineDepartment
        mobile
        mobileVerified
        role {
          id
          name
        }
        ulbName
        zone
      }
    }
  }
`

;
