import { gql } from '@apollo/client';

export const FEE_DETAILS = gql`
  mutation feeDetails(
    $applicationId: ID!
    $feeBreakup: JSON!
  ) {
    feeDetails(
      applicationId: $applicationId
      feeBreakup: $feeBreakup
    )
  }
`;
