import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import ErrorScreen from '../components/ErrorScreen';
import LoadingScreen from '../components/LoadingScreen';
import QuestionCards from '../components/QuestionCards/sw-card';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import RightSideBar from '../components/RightSideBar';
import PropTypes from 'prop-types';
import { GET_OFFICERS_APPLICATION } from '../graphql/query/officersApplication';
import { GET_APPLICATION_QUESTIONS } from '../graphql/query/applicationQuestions';
import history from '../services/history';
import { AppContext } from '../AppContext';
import { GET_REVIEW_REMARKS } from '../graphql/query/reviewRemarks';
import { PERMISSIONS_LEVELS } from '../util/helperFunctions';
import RightSideBarOCView from '../components/RightSideBar/oc-view';
import CWRightSideBar from '../components/RightSideBar/CWRightSideBar';

export default function SingleWindowVerification(props) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [status,setStuaus] = useState('')
  const showSidebarHandler = (value) => {
    setShowSidebar(value);
  };
  const { permissionLevel } = useContext(AppContext);

  const {
    loading: officersLoading,
    error: officersError,
    data: officersData,
    refetch: officersRefetch,
  } = useQuery(GET_APPLICATION_QUESTIONS, {
    variables: { id: props.match.params.id },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const { data: answersData, loading, error } = useQuery(
    GET_REVIEW_REMARKS,
    {
      fetchPolicy: 'no-cache',
      variables: {
         id: props.match.params.id,
         page:1,
         perPage:15
       },
    }
  );



  function isFormSubmitted () {
    let isAnswer = officersData?.applicationForms?.some(e=>e?.answer !== null)
    
    if(isAnswer) {
        if(permissionLevel ==  PERMISSIONS_LEVELS?.VICE_CHAIRMAN && applicationData?.officersApplication.status  == "FEE_VERIFICATION" ){
          return officersData?.applicationForms?.map(e=>e.answer)?.some(e=>e?.action !== 'view')
        } else {
          return officersData?.applicationForms?.map(e=>e.answer)?.some(e=>e?.action !== 'view')
        }
    }else {
      return  true;
    }
   }


  const sortCompare = (a, b) => {
    if (!b.order || parseInt(a.order, 10) < parseInt(b.order, 10)) {
      return -1;
    }
    if (!a.order || parseInt(a.order, 10) > parseInt(b.order, 10)) {
      return 1;
    }
    return 0;
  };

  // console.log(answersData?.reviewRemarks,'answersData');
  console.log(officersData?.applicationForms ,"applicationData");


  const QueryOfficersForm = () => {
    if (officersLoading) return <LoadingScreen />;
    if (!(officersData && officersData.applicationForms))
      return <LoadingScreen />;
    if (officersError) {
      // loading data from json for development
      return <ErrorScreen message={officersError} retry={officersRefetch} />;
    }

    let titleRelatedForm = officersData.applicationForms.find((form) =>
      form.title
        .toLowerCase()
      // .includes(permissionLevel === 'SITE_VERIFICATION' ? 'site' : 'tech')
    )
    // let titleRelatedForm = officersData.applicationForms;
    let FormInstanceId = officersData.applicationForms.filter((form) =>
    form.title
      .toLowerCase()
    // .includes(permissionLevel === 'SITE_VERIFICATION' ? 'site' : 'tech')
  ).map((item)=>item.id)

    const allStageStatus = officersData.applicationForms.map(
      (obj) => obj.stageState
    );
    if (!titleRelatedForm) {
      return (
        <ErrorScreen
          message={{ message: 'You only have view access for this application or File didnt reach you yet.' }}
          retry={officersRefetch}
        />
      );
    }
    var officersFormData = [...titleRelatedForm.fields];
    var officersFormDataSorted = officersFormData.sort(sortCompare);
    var officersDocumentsDataSorted = officersFormData
      .filter((item) => item.category === 'shortfall')
      .sort(sortCompare);
    var officersFormDataGrouped = [];
    const size = 5;
    while (officersFormDataSorted.length > 0) {
      officersFormDataGrouped.push(officersFormDataSorted.splice(0, size));
    }
    // console.log('fsdfofficersFormDataGrouped', officersFormDataGrouped.push(officersFormDataSorted))
    return (
      <div>
        <QuestionCards
          officersFormDataGrouped={officersData?.applicationForms}
           officerReviewRemarksData = {answersData?.reviewRemarks}
          officersDocumentsDataSorted={officersDocumentsDataSorted}
          formId={titleRelatedForm.id}
          recomID = {FormInstanceId}
          verificationType={'Technical Verification'}
          applicationIdentifier={
            applicationData?.officersApplication.applicationIdentifier
          }
          isChecklistFilled={!!titleRelatedForm.isChecklistFilled}
          isFeesCompleted={!!titleRelatedForm.isFeesCompleted}
          isVerification={"true"}
          showSidebarHandler={showSidebarHandler}
          showSidebar={showSidebar}
          stageState={allStageStatus}
          formData={titleRelatedForm}
          status= {applicationData?.officersApplication.status}
          isFormSubmitOrNot={isFormSubmitted}
        />
      </div>
    );
  };

  const {
    loading: applicationLoading,
    error: applicationError,
    data: applicationData,
    refetch: applicationRefetch,
  } = useQuery(GET_OFFICERS_APPLICATION, {
    fetchPolicy: 'cache-first',
    variables: { id: props.match.params.id },
    notifyOnNetworkStatusChange: true,
  });


  // console.log(applicationData?.officersApplication.status  == "FEE_VERIFICATION","applicationData")

  // eslint-disable-next-line no-unused-vars
  const QueryApplicationForm = () => {
    if (applicationLoading)
      return (
        <div className="ad-container">
          <LoadingScreen />
        </div>
      );
    if (applicationError)
      return (
        <div className="ad-container">
          <ErrorScreen message={applicationError} retry={applicationRefetch} />
        </div>
      );
    return <RightSideBar />;
  };
  function checkUserLogin () {
    const loginTOken = sessionStorage.getItem('accessToken')
    if(loginTOken != null || loginTOken != "" || loginTOken != undefined) {
      return true
    }else {
      return false
    }
  }
  return (
    <>
      <Header />
      <Sidebar />
        {/* <IdleTimer/>  */}
      {QueryOfficersForm()}
      {/* <div className="main-content">{QueryApplicationForm()}</div> */}
      <div className="main-content">
        { applicationData?.officersApplication?.approvalFor === 'OCCUPANCY'? (
          <RightSideBarOCView
            showSidebar={showSidebar}
            showSidebarHandler={showSidebarHandler}
            applicationType={history?.location?.state?.applicationType}
            ApprovalFor={applicationData?.officersApplication?.approvalFor}
            id = {props.match.params.id}
          />
        )

          : applicationData?.officersApplication?.approvalFor === 'COMPOUNDWALL' ? (
            <CWRightSideBar
              showSidebar={showSidebar}
              showSidebarHandler={showSidebarHandler}
              applicationType={history?.location?.state?.applicationType}
              ApprovalFor={applicationData?.officersApplication?.approvalFor}
              id = {props.match.params.id}
            />
          ) :( <RightSideBar
            showSidebar={showSidebar}
            showSidebarHandler={showSidebarHandler}
            applicationType={history?.location?.state?.applicationType}
            ApprovalFor={applicationData?.officersApplication?.approvalFor}
            id = {props.match.params.id}
          />)}
      </div>
    </>
  );
}

SingleWindowVerification.propTypes = {
  match: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.shape({
      applicationType: PropTypes.string,
    }),
  }),
};
SingleWindowVerification.defaultProps = {
  location: {
    state: {
      applicationType: 'SELF CERTIFICATION',
    },
  },
};
