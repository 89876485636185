export default function (host, baseApi, graphqlBaseUrl) {
  return {
    host,
    intervals: {
      logout: 3600, // 1 hour in seconds
    },
    graphqlBaseUrl,
    baseApi,
    api: {
      login: `${baseApi}/officers/login`,
      resetPin: `${baseApi}/officers/reset_password`,
      changePin: `${baseApi}/officers/change_password`,
    },
    token: {
      auth: process.env.AUTH_TOKEN,
    },
    isProduction: false,
    isDevelopment: true,
  };
}
