import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Card, Carousel, Button, Modal } from 'react-bootstrap';
import history from '../../services/history';
import '../VerificationCards/index.scss';
import { useMutation } from '@apollo/client';
import { SAVE_ANSWERS_FORM, SUBMIT_FORM } from '../../graphql/mutatution/submitForm';
import Question from './Question/index';
import { AppContext } from './../../AppContext';
import AnswerComponent from '../AnswersListComponent/Answer';
import ErrorScreen from '../ErrorScreen';
import { FILEVALIDATIONS } from "../../util/mock"
import DeviationTable from '../DeviationTable';
import ReviewRemarksPopUp from '../IAIRQuestions/ReviewRemarks';
function CarousalQuestions({
    id,
    questionsData,
    formId,
    applicationIdentifier,
    isVerification,
    applicationType,
    formStatus,
    ApprovalFor,
    applicationStatus

}) {
    //console.log(applicationType,'applicationType')
    const [appIdentifier] = useState(applicationIdentifier || '');
    const [carousalIndex, setCarousalIndex] = useState(0);
    // const [submitForm, { called, errors }] = useMutation(SUBMIT_FORM);
    const [submitFormAnswersSingleWindow, { called, errors }] =
        useMutation(SUBMIT_FORM);

    const [SaveFormAnswers, { caled, error }] = useMutation(SAVE_ANSWERS_FORM);
    const [isLoading, setIsLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);
    const { permissionLevel, currentFlow } = useContext(AppContext);
    const [showPopUpAfterSubmit, setShowPopUpAfterSubmit] = useState(false);
    const handleClosePopUpAfterSubmit = () => setShowPopUpAfterSubmit(false);
    const handleShowAfterSubmit = () => setShowPopUpAfterSubmit(true);
    const [isOccL2UpdatedTable, setIsOccL2UpdatedTable] = useState(false);
    const [showAnsers, SetShowAnswers] = useState(false);

    const handleClosePopUpAnswers = () => SetShowAnswers(false);
    const handleShowOpenPopup = () => SetShowAnswers(true);

    const sortCompare = (a, b) => {
        if (!b.order || parseInt(a.order, 10) < parseInt(b.order, 10)) {
            return -1;
        }
        if (!a.order || parseInt(a.order, 10) > parseInt(b.order, 10)) {
            return 1;
        }
        return 0;
    };

    var officersFormData = [...questionsData.fields];
    var officersFormDataSorted = officersFormData.sort(sortCompare);
    var officersFormDataGrouped = [];
    const size = officersFormDataSorted.length;
    while (officersFormDataSorted.length > 0) {
        officersFormDataGrouped.push(officersFormDataSorted.splice(0, size));
    }

    const handleSelect = (selectedIndex, e) => {
        setCarousalIndex(selectedIndex);
    };

  

    const submitRecommendationForm = () => {
        setIsLoading(true);
        let dataBeforeSubmittion = { ...ansState.current };
        const OfficerId = window.sessionStorage.getItem('id');
        submitFormAnswersSingleWindow({
            variables: {
                formInstanceId: formId,
                data: dataBeforeSubmittion,
                status: 'RECOMMENDED',
                officerId: OfficerId,
                finalRemark: '',
            },
        })

            .then(() => {

                if (!errors & !called) {

                    window.location.reload()

                }
            })
            .catch((e) => {
                setIsLoading(false);
                setErrMsg(e.message);
                console.error(e);
            });
    };


    const handleSaveFormAnswers = () => {
        setIsLoading(true);
        let dataBeforeSubmittion = { ...ansState.current };
        if (Object.keys(dataBeforeSubmittion).length !== 0) {
            const OfficerId = window.sessionStorage.getItem('id');
            SaveFormAnswers({
                variables: {
                    formInstanceId: formId,
                    data: dataBeforeSubmittion,
                    status: 'RECOMMENDED',
                    officerId: OfficerId,
                    finalRemark: '',
                },
            })
                .then(() => {
                    if (!error & !caled) {
                        SetShowAnswers(true)
                        //   window.location.reload()
                    }
                })
                .catch((e) => {
                    setIsLoading(false);
                    setErrMsg(e.message);
                    console.error(e);
                });
        }
        // debugger

    }

    const handleSaveFormAnswers1 = () => {

        let dataBeforeSubmittion = { ...ansState.current };
        const OfficerId = window.sessionStorage.getItem('id');
        SaveFormAnswers({
            variables: {
                formInstanceId: formId,
                data: dataBeforeSubmittion,
                status: 'RECOMMENDED',
                officerId: OfficerId,
                finalRemark: '',
            },
        })
            .then(() => {
                if (!error & !caled) {
                }
            })
            .catch((e) => {
                setIsLoading(false);
                setErrMsg(e.message);
                console.error(e);
            });
    }
 
    const ModalComponent = () => {
        return (
            <Modal
                show={showPopUpAfterSubmit}
                onHide={handleClosePopUpAfterSubmit}
                centered
            >
                <Modal.Header
                    closeButton
                    style={{ borderBottom: '0 none', paddingBottom: '0px' }}
                />
                <Modal.Body style={{ paddingTop: '0px' }}>
                    <div className="px-2">Are you sure you want to submit?</div>
                </Modal.Body>
                <Modal.Footer
                    style={{ borderTop: '0 none' }}
                    className="d-flex justify-content-between"
                >
                    <Button
                        className="btn btn-secondary"
                        onClick={() => handleClosePopUpAfterSubmit()}
                        style={{ width: '30%' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn-success"
                        onClick={() => {
                            submitRecommendationForm();
                            handleClosePopUpAfterSubmit();
                        }}
                        style={{ width: '60%' }}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const SaveAnsersPopUp = () => {
        return (
            <Modal
                show={showAnsers}
                onHide={handleClosePopUpAnswers}
                centered
            >
                <Modal.Header
                    closeButton
                    style={{ borderBottom: '0 none', paddingBottom: '0px' }}
                />
                <Modal.Body style={{ paddingTop: '0px' }}>
                    <div className="px-2">your Answers are Saved Sucessfully !</div>
                </Modal.Body>
                <Modal.Footer
                    style={{ borderTop: '0 none' }}
                    className="d-flex justify-content-between"
                >

                    <Button
                        className="btn btn-success"
                        onClick={() => {

                        }}
                        style={{ width: '60%' }}
                    >
                        ok
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    // const  handleNExtfun = () => {

    // }
    // console.log(officersFormDataGrouped,"officersFormDataGrouped");
    // const nextSlide = () => {
    //     //    handleSaveFormAnswers1()
    //     let duplicateIndex = carousalIndex + 1;
    //     const data = ansState.current;
    //     // console.log(officersFormDataGrouped[0][0]);
    //     if (duplicateIndex == officersFormDataGrouped.length) {

    //         var isValid = true;

    //         for (
    //             let cardIndex = 0;
    //             cardIndex < officersFormDataGrouped.length;
    //             cardIndex++
    //         ) {
    //             for (
    //                 let relativeQIndex = 0;
    //                 relativeQIndex < officersFormDataGrouped[cardIndex].length;
    //                 relativeQIndex++
    //             ) {

    //                 if (
    //                     !(
    //                         officersFormDataGrouped[cardIndex][relativeQIndex].dbField in data
    //                     ) ||
    //                     !data[officersFormDataGrouped[cardIndex][relativeQIndex].dbField]
    //                         .parent_answer ||
    //                     data[officersFormDataGrouped[cardIndex][relativeQIndex].dbField]
    //                         .parent_answer === '' ||
    //                     data[officersFormDataGrouped[cardIndex][relativeQIndex].dbField]
    //                         .parent_answer === null
    //                 ) {

    //                     setIsSubmittedOnce(true);
    //                     isValid = false;
    //                     duplicateIndex = cardIndex;
    //                     break;
    //                 }
    //             }
    //             if (!isValid) break;
    //         }

    //         if (isValid) {
    //             duplicateIndex--;
    //             handleShowAfterSubmit();
    //         }
    //     }
    //     return duplicateIndex % Math.ceil(officersFormDataGrouped.length);
    // };
    const nextSlide = () => {
        // handleSaveFormAnswers1()]


        //debugger;
        let duplicateIndex = carousalIndex + 1;
        const data = ansState.current
        // const newData = data.map(item=>item.dbField != )
        // console.log(data, "newAnseState")
        // console.log(data['Whether_the_Plot_falls_in_Non_agriculture_area_?'].parent_answer,"Whether_the_Plot_falls_in_Non_agriculture_area_")
        const filteredObject = Object.fromEntries(
            Object.entries(childAnswerState.current).filter(([key]) => key !== "undefined")
        );
        // console.log(childAnswerState, "childAnswerState");
        if (questionsData.title == "Line department triggering check list") {
            if (data['Does_this_appliction_is_multiplex'] && data['Does_this_appliction_is_multiplex']['parent_answer'] == "false") {
                // Delete the data inside Whether_NALA_Conversion_Proceeding_is_available_with_the_applicant
                delete data['Does_this_appliction_is_multiplex']['Do you have Necessary NOC from the Traffic department?']
                delete data['Does_this_appliction_is_multiplex']['Select_the_No_of_Screens']
                //   delete data['Whether_the_site_is_affected_by_any_of_the_Water_Bodies?']['Select_the_No_of_Screens']
                // console.log(data)
            } else if (data['Whether_the_proposed_site_falls_under_any_of_the_Prohibited_land_list_(Govt._lands,ULC,_endowment_lands,_Wakf_board_lands)_?']&&  data['Whether_the_proposed_site_falls_under_any_of_the_Prohibited_land_list_(Govt._lands,ULC,_endowment_lands,_Wakf_board_lands)_?']['parent_answer'] == "true") {
                delete data['Whether_the_proposed_site_falls_under_any_of_the_Prohibited_land_list_(Govt._lands,ULC,_endowment_lands,_Wakf_board_lands)_?']['Furnish_Remarks']
            } else if (data['Whether_the_site_is_affected_by_any_of_the_Water_Bodies?'] && data['Whether_the_site_is_affected_by_any_of_the_Water_Bodies?']['parent_answer'] == "false") {
                // Delete the data inside Whether_NALA_Conversion_Proceeding_is_available_with_the_applicant
                delete data['Whether_the_site_is_affected_by_any_of_the_Water_Bodies?']['Does_the_applicant_submit_the_necessary_NOC_from_the_Revenue_&_Irrigation_department.']
                delete data['Whether_the_site_is_affected_by_any_of_the_Water_Bodies?']['Select_type_of_water_body ']
                // console.log(data)
            } else if (data['Whether_the_Plot_falls_in_Non_agriculture_area_?'] && data['Whether_the_Plot_falls_in_Non_agriculture_area_?']['parent_answer'] == "false") {
                delete data['Whether_the_Plot_falls_in_Non_agriculture_area_?']['Whether_NALA_Conversion_Proceeding_is_available_with_the_applicant?']

            }
        }

        if (duplicateIndex == officersFormDataGrouped.length) {

            var isValid = true;

            for (
                let cardIndex = 0;
                cardIndex < officersFormDataGrouped.length;
                cardIndex++
            ) {
                for (
                    let relativeQIndex = 0;
                    relativeQIndex < officersFormDataGrouped[cardIndex].length;
                    relativeQIndex++
                ) {
                    const dbField = officersFormDataGrouped[cardIndex][relativeQIndex].dbField;
                    if(questionsData.title == "Site inspection review images" ){
                    if ( 
                        (dbField == "site visit photograph East:") ||
                        (dbField == "site visit photograph West") ||
                        (dbField == "site visit photograph North") ||
                        (dbField == "site visit photograph South") ||
                        (dbField == "Attach/Upload photographs of the infrastructural developments completed in the layout.")
                    ) {


                        if (

                            !(
                                dbField in data

                            ) ||
                            !data[dbField]
                                .parent_answer ||
                            data[dbField]
                                .parent_answer === '' ||
                            data[dbField]
                                .parent_answer === null
                            ||

                            (questionsData.answer == null && Object.values(filteredObject).some(value => value === ""))
                            // Object.keys(childAnswerState.current).filter(item => item !=undefined ).values(childAnswerState.current).some(value => value == "")
                        ) {

                            setIsSubmittedOnce(true);
                            isValid = false;
                            duplicateIndex = cardIndex;
                            break;
                        }
                    }
                    }else {
                        if (
                            !(
                                officersFormDataGrouped[cardIndex][relativeQIndex].dbField in data
                            ) ||
                            !data[officersFormDataGrouped[cardIndex][relativeQIndex].dbField]
                                .parent_answer ||
                            data[officersFormDataGrouped[cardIndex][relativeQIndex].dbField]
                                .parent_answer === '' ||
                            data[officersFormDataGrouped[cardIndex][relativeQIndex].dbField]
                                .parent_answer === null
                            ||
    
                            (questionsData.answer == null && Object.values(filteredObject).some(value => value === ""))
                            // Object.keys(childAnswerState.current).filter(item => item !=undefined ).values(childAnswerState.current).some(value => value == "")
                        ) {
    
                            setIsSubmittedOnce(true);
                            isValid = false;
                            duplicateIndex = cardIndex;
                            break;
                        }
                    }


                }
                if (!isValid) break;
            }

            if (isValid) {
                duplicateIndex--;
                handleShowAfterSubmit();
            }
        }
        return duplicateIndex % Math.ceil(officersFormDataGrouped.length);
    };
    const prevSlide = () => {
        let duplicateIndex = carousalIndex - 1;
        if (duplicateIndex === -1) {
            duplicateIndex = officersFormDataGrouped.length - 1;
        }
        return duplicateIndex;
    };

    const remarksAndSubmit = (carousalIndex) => {
        return (
            <>
                <div
                    className="btn-container"
                    style={{ display: 'flex', marginLeft: "20%" }}
                >
                    {/* <button
                       id='buttonback'
                        className={carousalIndex === 0 ? 'd-none' : 'btn btn-light '}
                        onClick={() => handleSelect(prevSlide())}
                    >
                        <img
                            src={require('../../assets/images/arrowBackwards.svg')}
                            alt="previous"
                        />
                        BACK
                    </button> */}

                    {!isLoading && (
                        <>
                            <div className="d-flex">

                                <Button variant="outline-success"  id='buttonback' onClick={handleSaveFormAnswers}  className='btn '>
                                    {"Save"} &nbsp;
                                    <img
                                        src={require('../../assets/images/arrow.svg')}
                                        alt="next"
                                    />
                                </Button>
                                <Button
                                    variant="outline-success"
                                    style={{ width: "180px", marginLeft: "300px" }}
                                    id="buttonRight"
                                    className={
                                        carousalIndex === 0
                                            ? 'btn  btn-firstPage'
                                            : 'btn btn-success '
                                    }
                                    onClick={() => {
                                        handleSelect(nextSlide());

                                    }}
                                >
                                    {/* {carousalIndex === officersFormDataGrouped.length - 1 */}
                                    {'SUBMIT'}
                                    {/* : 'Save & Continue'} */}
                                    &nbsp;
                                    <img
                                        src={require('../../assets/images/arrow.svg')}
                                        alt="next"
                                    />
                                </Button>
                            </div>
                        </>
                    )}
                    {isLoading && (
                        <Button variant="secondary">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </Button>
                    )}
                    <span>{errMsg == 'You need to authenticate to perform this action' ? <ErrorScreen message={error} /> : "" || null}</span>
                </div>
            </>
        );
    };
    const showCarousalQuestions = () => {
        return (
            <div
            //    className=''
            //     activeIndex={carousalIndex}
            //     controls={false}
            //     onSelect={handleSelect}
            //     interval={null}
            >
                {officersFormDataGrouped.map((cardData, cardIndex) => {

                    return (
                        <>

                            <div>
                                <div key={cardIndex}>
                                    <div>
                                        {cardData.map((question, relativeIndex) => {
                                            let sno = (cardIndex * size) + (relativeIndex + 1)
                                            return (
                                                <span style={{ display: "flex" }} >
                                                    <Question
                                                        question={question}
                                                        key={relativeIndex}
                                                        onChange={memoizedHandleAnswerChange}
                                                        validationReview={isSubmittedOnce}
                                                        preFilledAnswers={questionsData?.answer?.dataRevised}
                                                        sno={sno}
                                                        relativeIndex={relativeIndex}
                                                        applicationType={applicationType}
                                                        childAnswerState={childAnswerState}
                                                        ApprovalFor={ApprovalFor}
                                                    />
                                                </span>

                                            );
                                        })}
                                    </div>


                                </div>


                                {remarksAndSubmit(carousalIndex)}

                            </div>


                        </>
                    );
                })}
            </div>
        );
    };

    const ansState = useRef({});
    const childAnswerState = useRef({})
    // const handleChildAnswerChange = (dbField, value) => {
    const handleParentAnswerChange = (question, dbField, value, checkboxvalue) => {
        ansState.current[dbField] = value;
        //debugger;

        // if (value.parent_answer == "false") {
        //     if (dbField == "Whether_the_Plot_falls_in_Non_agriculture_area_?") {             
        //             // Delete the data inside Whether_NALA_Conversion_Proceeding_is_available_with_the_applicant
        //             delete data[dbField]['Whether_NALA_Conversion_Proceeding_is_available_with_the_applicant?']
        //             console.log(data)

        //     }

        //     // console.log( , "value")
        // }

        // console.log(question, dbField, value, checkboxvalue, childAnswerState, "sdfdsdfdsdsdsds")
        // //debugger;

        if (question.fieldType == "CHECKBOX") {
            if (question.dbField === dbField && question.options.length > 0) {
                for (var i = 0; i < question.options.length; i++) {
                    if (question.options[i].value === "Yes" && checkboxvalue == 'true' && question.options.length > 0) {
                        for (var j = 0; j < question.options[i].questions.length; j++) {
                            const childDbField = question.options[i].questions[j].dbField;
                            if (childDbField != undefined && dbField == question.dbField) {
                                childAnswerState.current[question.dbField] = checkboxvalue;

                                childAnswerState.current[childDbField] = ""



                            } else {
                                childAnswerState.current[childDbField] = ""
                            }
                            // handleParentAnswerChange(
                            //     question.options[i].questions[j],
                            //     childDbField,
                            //     value,
                            //     checkboxvalue
                            // );
                        }
                    } else if (question.options[i].value === "No" && checkboxvalue == 'false' && question.options.length > 0) {
                        for (var j = 0; j < question.options[i].questions.length; j++) {
                            const childDbField = question.options[i].questions[j].dbField;
                            if (childDbField != undefined && dbField == question.dbField) {
                                childAnswerState.current[question.dbField] = checkboxvalue;
                                childAnswerState.current[childDbField] = ""
                            } else {
                                childAnswerState.current[childDbField] = ""
                            }
                            // handleParentAnswerChange(
                            //     question.options[i].questions[j],
                            //     childDbField,
                            //     value,
                            //     checkboxvalue
                            // );
                        }
                    }

                    else if (checkboxvalue == "false" && question.options[i].value === "Yes") {
                        for (var j = 0; j < question.options[i].questions.length; j++) {
                            const childDbField = question.options[i].questions[j].dbField;
                            if (childDbField in childAnswerState.current) {
                                delete childAnswerState.current[childDbField]
                            }
                        }
                    }
                    else if (checkboxvalue == "true" && question.options[i].value === "No") {
                        for (var j = 0; j < question.options[i].questions.length; j++) {
                            const childDbField = question.options[i].questions[j].dbField;
                            if (childDbField in childAnswerState.current) {
                                delete childAnswerState.current[childDbField]
                            }
                        }
                    }
                    else {
                        childAnswerState.current[dbField] = checkboxvalue;
                    }


                }
            }
            else if (question.dbField === dbField && question.children.length > 0) {
                for (var i = 0; i < question.children.length; i++) {
                    if (question.defaultValue === "true" && checkboxvalue == 'false' && question.children.length > 0) {
                        for (var j = 0; j < question.children.length; j++) {
                            const childDbField = question.children[j].dbField;
                            if (childDbField != undefined && dbField == question.dbField) {
                                childAnswerState.current[question.dbField] = checkboxvalue;
                                childAnswerState.current[childDbField] = "";

                            } else {
                                childAnswerState.current[childDbField] = ""
                            }
                            // handleParentAnswerChange(
                            //     question.options[i].questions[j],
                            //     childDbField,
                            //     value,
                            //     checkboxvalue
                            // );
                        }
                    } else if (question.defaultValue === "false" && checkboxvalue == 'true' && question.children.length > 0) {
                        for (var j = 0; j < question.children.length; j++) {
                            const childDbField = question.children[j].dbField;
                            if (childDbField != undefined && dbField == question.dbField) {
                                childAnswerState.current[question.dbField] = checkboxvalue;
                                childAnswerState.current[childDbField] = ""
                            } else {
                                childAnswerState.current[childDbField] = ""
                            }
                            // handleParentAnswerChange(
                            //     question.options[i].questions[j],
                            //     childDbField,
                            //     value,
                            //     checkboxvalue
                            // );
                        }
                    }

                    else if (checkboxvalue == "false" && question.defaultValue === "false" && question.children.length > 0) {
                        for (var j = 0; j < question.children.length; j++) {
                            const childDbField = question.children[j].dbField;
                            if (childDbField in childAnswerState.current) {
                                delete childAnswerState.current[childDbField]
                            }
                        }
                    }
                    else if (checkboxvalue == "true" && question.defaultValue === "true" && question.children.length > 0) {
                        for (var j = 0; j < question.children.length; j++) {
                            const childDbField = question.children[j].dbField;
                            if (childDbField in childAnswerState.current) {
                                delete childAnswerState.current[childDbField]
                            }
                        }
                    }



                }

            }


        } else if (question.fieldType == "DROP_DOWN") {
            childAnswerState.current[dbField] = checkboxvalue
        }
        else if (question.fieldType == "FILE") {
            childAnswerState.current[dbField] = checkboxvalue
        }
        else if (question.fieldType == "TEXT_SHORT") {
            childAnswerState.current[dbField] = checkboxvalue
        }
    };


    const memoizedHandleAnswerChange = useCallback(handleParentAnswerChange, [
        ansState,
    ]);

    useEffect(() => {
        if (questionsData?.answer?.data) {
            officersFormDataGrouped.map((cardData) => {
                cardData.map((question) => {
                    if (
                        questionsData?.answer?.data.hasOwnProperty(question['dbField']) &&
                        questionsData?.answer?.data[question['dbField']].parent_answer) {
                        handleParentAnswerChange(question, question['dbField'], questionsData?.answer?.data[question['dbField']], "")
                    }
                })
            })
        }
    }, []);

    const handleCurrentChange = () => {
        setIsOccL2UpdatedTable(true);
    };

    console.log(questionsData?.answer?.data, "questionsData?.answer?.data ")
    // console.log("officersFormDataGrouped",officersFormDataGrouped);

    return (
        <>
            {/* {error.message == 'You need to authenticate to perform this action' ? <ErrorScreen message = {error}/> :""} */}
            {ModalComponent()}
            {SaveAnsersPopUp()}
           
            {/* <DeviationTable
                question={{
                  order: 'L2',
                  title: 'Deviation Table',
                }}
                handleCurrentChange={handleCurrentChange}
              /> */}
            {questionsData?.answer?.action === 'view' ?

                <Card
                    className="answer-card"
                    style={{
                        height: 'auto',
                        margin: '10px',
                    }}
                >
                    <Card.Body>
                        <div className="answer-list">
                            {officersFormDataGrouped.map((formData, index) =>

                                formData.map((question, relativeIndex) =>
                                    <span style={{ display: "flex", alignItems: "felx-start" }}>

                                        <p>{(index * 5) + (relativeIndex + 1)}. </p> &nbsp;  &nbsp;
                                        <AnswerComponent
                                            field={question}
                                            answer={questionsData?.answer?.data || null}
                                            key={relativeIndex}
                                            index={relativeIndex}


                                        />
                                    </span>

                                ))}
                        </div>
                    </Card.Body>
                </Card>
                :
                <>
                    {/* <div style={{ position:"fixed" ,padding:'20px' ,left:"60%", zIndex:"10000"}}>
                        <ul className="stepper-dots">
                            {officersFormDataGrouped.map((group, groupIndex) => (
                                <a href="#!" key={groupIndex}>
                                    <li
                                        className={
                                            carousalIndex === groupIndex ? 'active' : ''
                                        }
                                        onClick={(e) => handleSelect(groupIndex, e)}
                                    />
                                </a>
                            ))}
                        </ul>
                    </div> */}
                    <div style={{ width: "100%" }}>

                    </div>
                    {showCarousalQuestions()}
                </>
            }

        </>
    );
}

CarousalQuestions.propTypes = {
    officersFormDataGrouped: PropTypes.array,
    formId: PropTypes.string,
    verificationType: PropTypes.string,
    applicationIdentifier: PropTypes.string,
    showSidebar: PropTypes.bool,
    showSidebarHandler: PropTypes.func,
};

export default CarousalQuestions;
