/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Table,
  Form,
  FormControl,
  Accordion,
  Collapse,
  Card,
  Spinner,
  Carousel,
  Col,
  Button,
  InputGroup,
  ProgressBar,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import './VicinityDetails.scss';

const VicinityDetails = (props) => {
  const now = 60;
  return (
    <div className="VicinityDetails-container d-flex justify-content-center">
      <div className="plot-info">
        <h3 className="plot-info-title">Vicinity Information</h3>
        <Form>
          <div className="plot-address-section">
            <Form.Group controlId="form1">
              <Form.Label>
                is site falls with in 200 Mts vicinity of waterbody?
              </Form.Label>
              {['radio'].map((type, index) => (
                <div key={`inline-${type}`} className="mb-3 mt-3">
                  <Form.Check
                    inline
                    label="YES"
                    name="1"
                    type={type}
                    id={`inline-${type}${index} waterbody`}
                  />
                  <Form.Check
                    inline
                    label="NO"
                    name="1"
                    type={type}
                    id={`inline-${type}${index + 1} waterbody}`}
                  />
                </div>
              ))}
            </Form.Group>
            <Form.Group controlId="form2">
              <Form.Label>is site falls under air funnel zone?</Form.Label>
              {['radio'].map((type, index) => (
                <div key={`inline-${type}`} className="mb-3 mt-3">
                  <Form.Check
                    inline
                    label="YES"
                    name="2"
                    type={type}
                    id={`inline-${type}${index} funnel`}
                  />
                  <Form.Check
                    inline
                    label="NO"
                    name="2"
                    type={type}
                    id={`inline-${type}${index + 1} funnel`}
                  />
                </div>
              ))}
            </Form.Group>
            <Form.Group controlId="formProperty-tax">
              <Form.Label>
                is your land is in the vicinity of National Monument Authority
                with in 0 - 200 mts?
              </Form.Label>
              {['radio'].map((type, index) => (
                <div key={`inline-${type}`} className="mb-3 mt-3">
                  <Form.Check
                    inline
                    label="YES"
                    name="3"
                    type={type}
                    id={`inline-${type}${index} Monument`}
                  />
                  <Form.Check
                    inline
                    label="NO"
                    name="3"
                    type={type}
                    id={`inline-${type}${index + 1} Monument`}
                  />
                </div>
              ))}
            </Form.Group>
            <Form.Group controlId="formProperty-tax">
              <Form.Label>
                is your land is in the vicinity of Heritage Structure?
              </Form.Label>
              {['radio'].map((type, index) => (
                <div key={`inline-${type}`} className="mb-3 mt-3">
                  <Form.Check
                    inline
                    label="YES"
                    name="4"
                    type={type}
                    id={`inline-${type}${index}Heritage`}
                  />
                  <Form.Check
                    inline
                    label="NO"
                    name="4"
                    type={type}
                    id={`inline-${type}${index + 1}Heritage`}
                  />
                </div>
              ))}
            </Form.Group>
            <Form.Group controlId="formProperty-tax">
              <Form.Label>
                is your land is in the vicinity or located in Water / Oil / Gas
                pipeline
              </Form.Label>
              {['radio'].map((type, index) => (
                <div key={`inline-${type}`} className="mb-3 mt-3">
                  <Form.Check
                    inline
                    label="YES"
                    name="5"
                    type={type}
                    id={`inline-${type}${index} pipeline`}
                  />
                  <Form.Check
                    inline
                    label="NO"
                    name="5"
                    type={type}
                    id={`inline-${type}${index + 1}pipeline`}
                  />
                </div>
              ))}
            </Form.Group>
            <Form.Group controlId="formProperty-tax">
              <Form.Label>
                is your land is in the vicinity or Religious Structures?
              </Form.Label>
              {['radio'].map((type, index) => (
                <div key={`inline-${type}`} className="mb-3 mt-3">
                  <Form.Check
                    inline
                    label="YES"
                    name="6"
                    type={type}
                    id={`inline-${type}${index} Religious`}
                  />
                  <Form.Check
                    inline
                    label="NO"
                    name="6"
                    type={type}
                    id={`inline-${type}${index + 1}Religious`}
                  />
                </div>
              ))}
            </Form.Group>
            <Form.Group controlId="formProperty-tax">
              <Form.Label>
                is any HT / LT or Tower line passing through / adjacent to the
                proposed site?
              </Form.Label>
              {['radio'].map((type, index) => (
                <div key={`inline-${type}`} className="mb-3 mt-3">
                  <Form.Check
                    inline
                    label="YES"
                    name="7"
                    type={type}
                    id={`inline-${type}${index} Tower`}
                  />
                  <Form.Check
                    inline
                    label="NO"
                    name="7"
                    type={type}
                    id={`inline-${type}${index + 1} Tower`}
                  />
                </div>
              ))}
            </Form.Group>
            <Form.Group controlId="formProperty-tax">
              <Form.Label>
                whether sewerage system is available in the surroundings?
              </Form.Label>
              {['radio'].map((type, index) => (
                <div key={`inline-${type}`} className="mb-3 mt-3">
                  <Form.Check
                    inline
                    label="YES"
                    name="8"
                    type={type}
                    id={`inline-${type}${index} sewerage`}
                  />
                  <Form.Check
                    inline
                    label="NO"
                    name="8"
                    type={type}
                    id={`inline-${type}${index + 1} sewerage`}
                  />
                </div>
              ))}
            </Form.Group>
            <Form.Group controlId="formBuildingNumber1" className="upload-file">
              <Form.File id="formUpload-doc" custom="true">
                <span className="btn btn-file">
                  <img src={require('../../assets/images/graph.svg')} />
                  UPLOAD <span className="optional-text">NOC DOCUMENT</span>
                  <Form.File.Input type="file" />
                </span>
              </Form.File>
            </Form.Group>
            <div className="upload-doc-note">
              <p>
                {' '}
                Upload document and proceed with application or if you{' '}
                <b>don’t have the NOC document</b> Your application will be
                proceeded through Single Window.
              </p>
            </div>
            <Form.Group controlId="formProperty-tax">
              <Form.Label>
                whether sewerage system is available in the surroundings?
              </Form.Label>
              {['checkbox'].map((type, index) => (
                <div key={`inline-${type}`} className="mb-3 mt-3">
                  <Form.Check
                    inline
                    label="Continue my application with single window"
                    className="styled-checkbox"
                    name="8"
                    type={type}
                    id={`inline-${type}${index} surroundings`}
                  />
                </div>
              ))}
            </Form.Group>
          </div>
          <div className="d-flex justify-content-between ">
            <Button className="back-btn" variant="primary" type="button">
              <img src={require('../../assets/images/back-arrow.svg')} />
              back
            </Button>
            <Button className="submit-btn" variant="primary" type="submit">
              Save and Continue
              <img src={require('../../assets/images/arrow.svg')} />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

// ReviewSubmit.propTypes = {
//     id: PropTypes.string,
// };

export default VicinityDetails;
