import React, { useState, useRef, useCallback, useEffect } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { GET_OFFICERS_APPLICATION } from '../../graphql/query/officersApplication';
import { CREATE_COMMENT } from '../../graphql/mutatution/createComment';
import { useQuery, useMutation } from '@apollo/client';
import { APPLICATION_COMMENTS } from '../../graphql/query/applicationComments';
import moment from 'moment';
import { Form, Button } from 'react-bootstrap';
import { useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import FileUpload from '../FileUploadingComponent';
import environment from 'environment';
import { AppContext } from './../../AppContext';
import { useContext } from 'react';

import { CiEdit } from "react-icons/ci";

function NotesModal(props) {
  const ansState = useRef({});
  const handleChildAnswerChange = (question, dbField, value, fileType) => {
    // console.log('value is ', ansState.current[dbField]);
    ansState.current[dbField] = value;
    setAttachmentUrl(value.parent_answer);
  };
  const handleCurrentChange = () => {

  }
  const memoizedHandleAnswerChange = useCallback(handleChildAnswerChange, [
    ansState,
  ]);
  const feild = { fieldType: 'FILE' };
  const close = props.handleClose;
  const { id } = useParams();
  const downloadUrl = `${environment.host}/exports/comments/${id}.pdf`;
  console.log(downloadUrl, 'downloadUrl')
  const [comment, setComment] = useState('');
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const [isNotesButtonLoading, setIsNotesButtonLoading] = useState(false);
   const [value, setValue] = useState("");
  const [createComment, { loading }] = useMutation(CREATE_COMMENT);
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState('');
  const {
    mediaId,
    permissionLevel
  } = useContext(AppContext);

  const parseAndFormatDate = (createdAt) => {
    if (/\d{2}:\d{2}:\d{2}/.test(createdAt)) {
      const parts = createdAt.match(/(\d+)/g);
      return new Date(
        Date.UTC(
          parseInt(parts[0], 10),
          parseInt(parts[1], 10) - 1,
          parseInt(parts[2], 10),
          parseInt(parts[3], 10),
          parseInt(parts[4], 10),
          parseInt(parts[5], 10)
        )
      ).toLocaleString('en-IN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC',
      }).replace(/(am|pm)/i, '');
    } else {
      return moment(createdAt, 'YYYY-MM-DDTHH:mm:ss').format('DD-MM-YYYY');
    }
  }

  const { data: applicationComments, error: notesError, loading: Loading } = useQuery(
    APPLICATION_COMMENTS,
    {
      fetchPolicy: "no-cache",

      // nextFetchPolicy: "cache-first",
      variables: { id: id },
    }
  );

  useEffect(() => {
    if (!Loading) {
      setIsNotesButtonLoading(false);
    }
  }, [Loading, applicationComments])








  const { data: { officersApplication } = {} } = useQuery(
    GET_OFFICERS_APPLICATION,
    {
      fetchPolicy: 'network-first',
      variables: { id: props.id },
    }
  );
  const { stageStates = {} } = officersApplication || {};
const ID =sessionStorage.getItem('id');
  const addNote = () => {

    setIsNotesButtonLoading(true);

    createComment({
      variables: {
        applicationId: id,
        comment: comment,
        mediaId: mediaId,
        attachmentUrl:attachmentUrl,
      },
      refetchQueries: [
        {
          query: APPLICATION_COMMENTS,
          fetchPolicy: 'no-cache',
          variables: { id: id },
        },
      ],
    })
      .then(() => {
        setComment('')
        close()
      })
      .catch((e) => {
        setMessage(e.message);
      });

  };


  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text/plain");
    const newValue = value + pastedText;
    setComment(newValue);
  };

  console.log(applicationComments,'APPLICATION_COMMENTS')
  // eslint-disable-next-line no-unused-vars
  function getAllStagesData(item) {
    const ans = [];
    stageStates.forEach((state) => {
      state.stageOfficers.forEach((el) => {
        let stageOfficerInfo = {
          ...el,
          name: state.name,
          receivedDate:
            el.officerLevel === 'L1'
              ? state.receivedDate
              : state.stageOfficers.find((obj) => obj.officerLevel === 'L1')
                .forwardedDate,
          status: state.status,
        };
        ans.push(stageOfficerInfo);
      });
    });
    const sortCompare = (a, b) => {
      if (a.id > b.id) return false;
      return true;
    };
    const sortedAns = ans.sort(sortCompare);
    return sortedAns;
  }

  // eslint-disable-next-line no-unused-vars
  function getStatus(status) {
    if (status === 'IN_PROCESS' || status === 'In_process') return 'inProcess';
    if (status === 'SUBMITTED' || status === 'Submitted') return 'deemed';
    if (status === 'RECOMMENDED') return 'recommended';
    if (status === 'NOTRECOMMENDED') return 'notRecommend';
    return status;
  }

  // eslint-disable-next-line no-unused-vars
  let submitButton;

  if (!loading) {
    submitButton = (
      <Button
        onClick={() => {
          addNote();
        }}
      >
        {loading ? 'Loading...' : 'Submit123'}
        <img type="button" src={require('../../assets/images/arrow.svg')} />
      </Button>
    );
  } else {
    submitButton = (
      <Button variant="secondary">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Button>
    );
  }

  const handleEnterNote = (e) => {
    if (e.keyCode === 219 || e.keyCode === 221) {
      e.preventDefault();
    }
  }

  return (
    <div className="notes-modal-right overflow-auto">
      <div className="modalContent">
        <div className="modalHeader">
          <h5>Note Sheet</h5>
          <span className="d-flex align-items-center">
            <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faDownload} style={{ color: 'black' }} />
            </a>
            <img
              type="button"
              src={require('../../assets/images/close.svg')}
              onClick={close}
              className="pl-3"
            />
          </span>
        </div>
        {applicationComments &&
          applicationComments.applicationComments.map((item) =>
          // 
         {
        
           return (
     
            <>
              <div className="officerDetaisSection d-flex justify-content-between">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    style={{ color: '#1C9A5B' }}
                    className="fa-2x"
                  />
                  <div className="d-flex flex-column ml-2">
                    <p className="subItemHeader">Added by</p>
                    <p className="officerName">
                      {item.officer.firstName} {item.officer.lastName}{' '}
                      <span className="designation">
                        {item.officer.role.name}
                      </span>
                    </p>
                  </div>
                </div>
                <div>
                  <p className="subItemHeader">
                  
     {             parseAndFormatDate(item?.createdAt)
}       </p>
                </div>
              </div>
              <div className="notesSection" style={{ fontSize: 'small' }}>
                  <div>
                  <div dangerouslySetInnerHTML={{ __html: item.comment }} />
                  {/* {item.comment} */}
             
                  </div>
                
                  
                  <div>
                    {item.attachmentUrl && (
                      <a
                        href={item.attachmentUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View File
                      </a>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-end printSection">
                  <div className="d-flex mb-3 ">
                    {/* <p className="mr-2 printText ">Print</p>
                  <img
                    type="button"
                    src={require('../../assets/images/print.svg')}
                  /> */}
                  </div>
                </div>
              </>
            )
          }

          )}
        {applicationComments &&
          !applicationComments?.applicationComments?.length &&
          !notesError && (
            <div style={{ fontSize: 'small' }} className="py-3">
              There are no notes for this application
            </div>
          )}
        {notesError && (
          <div style={{ fontSize: 'small' }} className="py-3">
            {notesError.message}
          </div>
        )}
        {!props.isDisabledNotes && (
          <>
            <div className="addNotesSection 123 d-flex flex-column">
              <h5>Add Notes</h5>

              <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Control
                    as="textarea"
                    rows="5"
                    placeholder="Enter the text here"
                    value={comment}
                    onKeyDown={(event) => handleEnterNote(event)}
                    onChange={(event) => setComment(event.target.value)}
                  />

                </Form.Group>
              </Form>
            </div>
            <div>
              {/* <InputComponent
            question={feild}
            onChange={memoizedHandleAnswerChange}
            onClear={handleFileClear}
          /> */}
              <FileUpload
                question={feild}
                customHandleChange={memoizedHandleAnswerChange}
                isSubmit={true}
                onSubmitCallback={() => addNote()}
                isNotesButtonLoading={isNotesButtonLoading}
                hasComments={comment}
                handleCurrentChange={handleCurrentChange}
                FileType="NoteSheet File URL"
              // __typename={props?.__typename}
              // onClear={() => handleFileClear(true)}
              />
              {/* <button>Upload!</button> */}
            </div>
          </>
        )}
        {/* <div className="submitSection">{submitButton}</div> */}
        {/* <span>{message}</span> */}
      </div>
    </div>
  );
}

NotesModal.propTypes = {
  handleClose: PropTypes.string,
  id: PropTypes.string,
  isDisabledNotes: PropTypes.bool,
};

NotesModal.defaultProps = {
  handleClose: () => { },
  isDisabledNotes: false,
};

export default NotesModal;



