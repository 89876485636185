import React from 'react';
import ApplicationListLayout from '../components/ApplicationList';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

export default function Dashboard() {
  function checkUserLogin () {
    const loginTOken = sessionStorage.getItem('accessToken')
    if(loginTOken != null || loginTOken != "" || loginTOken != undefined) {
      return true
    }else {
      return false
    }
  }
  return (
    <div>
       {/* {checkUserLogin() &&   <IdleTimer/> } */}
      <Header />
      <Sidebar />
      <ApplicationListLayout />
    </div>
  );
}
