import { SyncLoader } from 'react-spinners';
import React from 'react';

export default function index() {
  // console.log('loading');
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        width: '100%',

      }}
    >
      <div style={{ margin: 'auto' }}>
        <SyncLoader size={10} color={'#1C915B'} loading={true} />
        <span style={{fontSize:"10px", color:"green", }}>Loading...</span>
      </div>
    </div>
  );
}

