import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import ApplicationItem from './ApplicationItem';
import { GoDesktopDownload } from 'react-icons/go'
import { BiSortAlt2 } from "react-icons/bi";
import * as XLSX from 'xlsx/xlsx.mjs';
import './index.scss';
import {
  Nav,
  Tab,
  Table,
  OverlayTrigger,
  Popover,
  Col,
  Row,
  Form,
  Dropdown,
  Modal,

} from 'react-bootstrap';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import history from '../../services/history';
import {
  FILTER_LIST_ENUM,
  dayDifference,
  PERMISSIONS_LEVELS,
  submissionsLabel,
  reviewsLabel,
} from '../../util/helperFunctions';
import Pagination from '@material-ui/lab/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from '../../AppContext';
// import { GET_FILTERED_APPLICATIONS_BY_DATE_AND_TYPE } from '../../graphql/query/OfficersFilteredByDateandType';
import { GET_OFFICER_PROFILE } from '../../graphql/query/officerProfile';
import { EXPORTFILTER } from "../../graphql/query/DowloadExcelQuery"
import { useQuery } from '@apollo/client';
import Marquee from 'react-fast-marquee';
import { styled } from '@mui/material/styles';
import SpinLoader from '../LoadingScreen/spinLoader';
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgb(28, 154, 91)"
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgb(28, 154, 91)",
  },
}));

function ApplicationList(props) {
  const { data, client, loading, error } = useQuery(GET_OFFICER_PROFILE, { fetchPolicy: 'network-only' });
  // const { data, client, loading, error } = useQuery(GET_OFFICER_PROFILE, { fetchPolicy: 'network-only' });
  const [filterdItem, setFilterItem] = useState([])
  const [ApplicatioType, setApplicationType] = useState([])
  const [showAnsers, SetShowAnswers] = useState(false);
  const handleClosePopUpAnswers = () => SetShowAnswers(false);
  const newStatusData = [
    { AppliType: 'VERIFICATION_UNDER_PROGRESS' },
    { AppliType: 'PROCEEDINGS_IN_PROGRESS' },
    { AppliType: 'FEE_ADJUSTMENTS_SUBMITTED' },
    { AppliType: 'FEE_ADJUSTMENTS' },
    { AppliType: 'REJECTED' },
    { AppliType: 'ACCEPTED' },
    { AppliType: 'FEE_VERIFICATION' },
    { AppliType: 'CLOSED' },
    { AppliType: 'DEEMED' },
    { AppliType: 'FEE_ADJUSTMENTS' },
    { AppliType: 'SHORTFALL' },
    { AppliType: 'SHORTFALL_SUBMITTED' },
    { AppliType: 'FINAL_DEEMED' },
    { AppliType: 'SHOW_CAUSE_RAISED' },
    { AppliType: 'PAYMENT_INTIATED' },
    { AppliType: "LINE_DEPARTMENT_HOLD" },
    { AppliType: "EXPIRED" },
    { AppliType: "MORTGAGE_SHORTFALL" },
    { AppliType: 'DRAWING_SHORTFALL' },
    { AppliType: "DRAWING_SHORTFALL_SUBMITTED" },
    { AppliType: "DRAWING_SHORTFALL" },
    { AppliType: "DRAWING_VERIFIED" },
    { AppliType: "DRAFT" }

  ]
  const [count, setCount] = useState({
    deemed: 0,
    completed: 0,
    all: 0,
    submissions: 0,
    review: 0,
    processed: 0,
  });


  //   let   props?.data = props?.data

  //  function SortingData (props) {
  //   props?.data =  props.data.sort(function(a,b){
  //     // Turn your strings into dates, and then subtract them
  //     // to get a value that is either negative, positive, or zero.
  //     return new Date(b.assignedDate) - new Date(a.assignedDate);
  //   });

  //   console.log(props?.data,"checkingnewDate")
  //  }      
  const ShowPopUpForDownloadExcel = () => {
    return (
      <Modal
        show={showAnsers}
        onHide={handleClosePopUpAnswers}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: '0 none', paddingBottom: '0px' }}
        />
        <Modal.Body style={{ paddingTop: '0px' }}>
          <div className="px-2">Are You Sure You want to Download all files</div>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: '0 none' }}
          className="d-flex justify-content-between"
        >

          <Button
            style={{ color: "green", border: "1px solid green", width: '40%' }}
            onClick={() => {
              downloadAsExcel()
            }}
          >
            download
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const { permissionLevel, innerListValue, currentFlow } =
    useContext(AppContext);
  // const { data, client, loading, error } = useQuery(GET_OFFICER_PROFILE , {fetchPolicy:'network-only'});

  // eslint-disable-next-line no-unused-vars
  const [filter, setFilter] = useState(props.currentFilter);
  // eslint-disable-next-line no-unused-vars 
  const [searchText, setSearchText] = useState('');
  const [dropDown, setDropdown] = useState(false)
  const [Statusdropdown, setStatusDropdown] = useState(false)
  const [subDropDown, setSubdropdown] = useState(false)
  const [ApplicationdropDown, setApplicationdropDown] = useState(false)
  const [AppliSubDropdown, setAppliSubDropdown] = useState(false)
  const [interFilterCount, setInternalFilterCount] = useState({
    green: 0,
    orange: 0,
    red: 0,
  });
  const [RecivedText, setRecivedText] = useState("Old - New")

  const filteredData = props?.data?.map((obj, index) => {
    const filteredObj = {};

    filteredObj.Sno = index + 1;

    if (obj.applicant && obj.applicant.applicantName) {
      filteredObj.applicantName = obj.applicant.applicantName;
    }

    if (obj.property && obj.property.actualPlotArea) {
      filteredObj.actualPlotArea = obj.property.actualPlotArea;
    }

    if (obj.applicantListId) {
      filteredObj.applicantListId = obj.applicantListId;
    }

    if (obj.applicationIdentifier) {
      filteredObj.applicationIdentifier = obj.applicationIdentifier;
    }

    if (obj.applicationType) {
      filteredObj.applicationType = obj.applicationType;
    }

    if (obj.status) {
      filteredObj.status = obj.status;
    }

    return filteredObj;
  });


  const allDataToExport = props?.ExcelDtataDownload?.map((obj, index) => {
    const filteredObj = {};

    filteredObj.Sno = index + 1;

    if (obj.applicant && obj.applicant.applicantName) {
      filteredObj.applicantName = obj.applicant.applicantName;
    }

    if (obj.property && obj.property.actualPlotArea) {
      filteredObj.actualPlotArea = obj.property.actualPlotArea;
    }

    if (obj.applicantListId) {
      filteredObj.applicantListId = obj.applicantListId;
    }

    if (obj.applicationIdentifier) {
      filteredObj.applicationIdentifier = obj.applicationIdentifier;
    }

    if (obj.applicationType) {
      filteredObj.applicationType = obj.applicationType;
    }

    if (obj.status) {
      filteredObj.status = obj.status;
    }

    return filteredObj;
  });

  // console.log(filteredData, 'filteredData')

  const [filterdate, setFilterDate] = useState('')
  const [internalFilter, setInternalFilter] = useState('');

  let searchQuery = searchText;

  const popoverGreen = (
    <Popover id="popover-basic">
      <Popover.Content>
        <div className="d-flex flex-column">
          <p className="title">Green:</p>
          <p className="mt-3 mb-3 sub-Title">
            {' '}
            Files Upto 14 days are displayed under this section.
          </p>

        </div>
      </Popover.Content>
    </Popover>
  );

  const popoverRed = (
    <Popover id="popover-basic">
      <Popover.Content>
        <div className="d-flex flex-column">
          <p className="title">Red:</p>
          <p className="mt-3 mb-3 sub-Title">
            {' '}
            crossed service level Agreement (SLA) Immediate action required.
          </p>
          {/* <Button
            variant="outline-success"
            className="got-it"
            onClick={() => {
              document.body.click();
            }}
          >
            Got it
          </Button> */}
        </div>
      </Popover.Content>
    </Popover>
  );

  const popoverOrange = (
    <Popover id="popover-basic">
      <Popover.Content>
        <div className="d-flex flex-column">
          <p className="title">Orange:</p>
          <p className="mt-3 mb-3 sub-Title">
            {' '}
            4 days left over for processing the file.
          </p>
          {/* <Button
            variant="outline-success"
            className="got-it"
            onClick={() => {
              document.body.click();
            }}
          >
            Got it
          </Button> */}
        </div>
      </Popover.Content>
    </Popover>
  );



  const downloadAsExcelPerPage = () => {
    // if(!props.excelLoading && props.ExcelDtataDownload != undefined ) {

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(dataBlob);
    downloadLink.download = 'filtered_data.xlsx';
    downloadLink.click();




  };
  const downloadAsExcel = () => {
    // if(!props.excelLoading && props.ExcelDtataDownload != undefined ) {

    // const DownloadData = props.ExcelDtataDownload
    if (allDataToExport != undefined) {
      const worksheet = XLSX.utils.json_to_sheet(allDataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(dataBlob);
      downloadLink.download = 'filtered_data.xlsx';
      downloadLink.click();
      handleClosePopUpAnswers()
    }



  };

  const isGHMCOrNot = () => {
    // const authorities = ['NIUDA', 'NUDA', 'MUDA', 'KUDA', 'SUDA', 'STUDA', 'VTADA', 'SIUDA', 'YTDA'];
    if (data?.me.ulbName == "GHMC") {
      return true
    } else {
      return false
    }

  }

  const updateInternalFilterCount = () => {
    const internalFilterCountClone = { green: 0, orange: 0, red: 0 };
    for (let i = 0; i < props?.data?.length; i++) {
      const dayDiff = dayDifference(props?.data[i].dueDate);
      if (dayDiff >= 0 && dayDiff <= 3) internalFilterCountClone['red']++;
      else if (dayDiff >= 4 && dayDiff <= 6)
        internalFilterCountClone['orange']++;
      else if (dayDiff > 6) internalFilterCountClone['green']++;
    }
    setInternalFilterCount(internalFilterCountClone);
  };
  useEffect(() => {
    updateInternalFilterCount();
    setInternalFilter('');
  }, [props.searchText]);

  const [FilterName, setFItlerName] = useState('Filter')

  useEffect(() => {
    setInternalFilter('');
    setFilter(navFilter());
    const count = {
      deemed: 0,
      completed: 0,
      all: 0,
      submissions: 0,
      review: 0,
      processed: 0,
    };

    if (props.currentFilter === FILTER_LIST_ENUM.all) {
      count.all = props?.data?.length || 0;
      updateInternalFilterCount();
    }
    if (props.currentFilter === FILTER_LIST_ENUM.submissions) {
      count.submissions = props?.data?.length || 0;
      updateInternalFilterCount();
    }
    if (props.currentFilter === FILTER_LIST_ENUM.review) {
      count.review = props?.data?.length || 0;
      updateInternalFilterCount();
    }
    if (props.currentFilter === FILTER_LIST_ENUM.processed) {
      count.processed = props?.data?.length || 0;
    }
    if (props.currentFilter === FILTER_LIST_ENUM.deemed) {
      count.deemed = props?.data?.length || 0;
    }
    if (props.currentFilter === FILTER_LIST_ENUM.completed) {
      count.completed = props?.data?.length || 0;
    }
    setCount(count);
  }, [props.currentFilter, props.currentPage]);

  const isInternalFilterEligible = (dueDate, filterVal) => {
    const dayDiff = dayDifference(dueDate);
    if (dayDiff >= 0 && dayDiff <= 3 && filterVal === 'red') return true;
    if (dayDiff <= 6 && dayDiff >= 4 && filterVal === 'orange') return true;
    if (dayDiff > 6 && filterVal === 'green') return true;
    return false;
  };

  const handleChangeCheckboxStatus = (e) => {

    if (e.target.checked === true) {
      props.AllfitlerItems.push(e.target.value)
      setFilterItem([...filterdItem, e.target.value])
    } else if (e.target.checked === false) {
      const newFitlerdITem = filterdItem.filter(item => item != e.target.value)
      setFilterItem(newFitlerdITem)
    }

  }

  const handleChangeCheckboxType = (e) => {
    const value = e.target.value
    if (e.target.checked == true) {
      props.AllfitlerItems.push(e.target.value)
      setApplicationType([...ApplicatioType, value])
    }
    else if (e.target.checked == false) {
      const newFilter = ApplicatioType.filter((item) => item != e.target.value)
      setApplicationType(newFilter)
    }

  }



  const handleDisplayDropDown = () => {
    if (props.fillterDisplayName?.length === 0) { setDropdown(!dropDown) }
  }


  const changeFilter = (color) => {
    setFilter(color);
    setInternalFilter('');
  };

  const setFilterheading = () => {
    if (ApplicatioType !== '') {
      setFItlerName(ApplicatioType)
    }

  }


  const handleFiltering = () => {
    //console.log(props.AllfitlerItems,'props.AllfitlerItems')
    props.setFilterDisplayName('')
    if (filterdItem != '') {
      props.setFilterDisplayName([...props.fillterDisplayName, filterdItem])

    }
    if (filterdate != '') {
      props.setFilterDisplayName([...props.fillterDisplayName, filterdate])

    }
    if (ApplicatioType != '') {
      props.setFilterDisplayName([...props.fillterDisplayName, ApplicatioType])

    }
    if ((filterdItem != '' && filterdate != '' && ApplicatioType != '') || (filterdItem != '' && filterdate != '') || (filterdate != '' && ApplicatioType != '') || (filterdItem != '' && ApplicatioType != '')) {
      props.setFilterDisplayName([...props.fillterDisplayName, props.AllfitlerItems])
    }

    props.setCurrentPage(1)
    props.setfilterSearch(filterdItem)
    props.setFilteringDate(filterdate)
    props.setFilteringType(ApplicatioType)

  }

  function SortReccivedData() {
    if (props.sortRecivedDate == "ASC") {

      props.setSortRecivedDate("DESC")

    } else if (props.sortRecivedDate == "DESC") {

      props.setSortRecivedDate("ASC")

    }

  }

  const handleShowAnswersPopUp = () => {
    props.setIsExcel(true)
    SetShowAnswers(true);

  }
  function SortAssignedDAte() {
    if (props.sortAssignedDate == "ASC") {

      props.setSortAssignedDate("DESC")

    } else if (props.sortAssignedDate == "DESC") {

      props.setSortAssignedDate("ASC")

    }

  }

  const changeInternalFilter = (color) => {
    setInternalFilter(color);
  };
  const navFilter = () => {
    let filter = innerListValue;
    if ([FILTER_LIST_ENUM.all].includes(filter)) return 'all';
    else if ([FILTER_LIST_ENUM.review].includes(filter))
      return reviewsLabel(PERMISSIONS_LEVELS, permissionLevel);
    else if ([FILTER_LIST_ENUM.deemed].includes(filter)) return 'deemed';
    else if ([FILTER_LIST_ENUM.submissions].includes(filter)) {
      return submissionsLabel(PERMISSIONS_LEVELS, permissionLevel);
    } else if ([FILTER_LIST_ENUM.processed].includes(filter))
      return 'processed';
    else if ([FILTER_LIST_ENUM.completed].includes(filter)) return 'completed';
    else return 'all';
  };

  const removeActionInProcessd = () => {
    if (props.currentFilter == 'PROCESSED' && props?.data != undefined) {
      return true
    } else {
      return false
    }

  }


  return (
    <div className="al-container filter-badge">
      {!props.excelLoading && ShowPopUpForDownloadExcel()}
      {isGHMCOrNot() == true && <Marquee style={{ position: "relative", top: "-5px" }} pauseOnHover={true} delay={5} gradient={true} gradientColor={[28, 154, 49]}>
        <p  > &nbsp;  &nbsp;  &nbsp;  &nbsp; &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;      The owner / developer of the site shall ensure that the C&D Waste generated id transferred only to the designated C&D Waste Collection point located in respective GHMC circle of the proposed site duly paying the requisite fee to the agency at the transfer as per the quantity arrived  &nbsp;  &nbsp;  &nbsp;  &nbsp; &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; </p>
      </Marquee>}
      <Tab.Container id="left-tabs-example" defaultActiveKey={navFilter()}>
        <div
          className="al-header"
          style={{
            width: '100%',

          }}
        >
          <Nav variant="tabs" style={{ display: 'flex', alignItems: "center", justifyContent: "space-between !important" }}>

            <div style={{ display: "flex", alignItems: "center", gap: "20px", position: 'relative' }}>
              <Nav.Item >

                <Form className="m-0">
                  <Form.Group
                    controlId="formBasicEmail"
                    className="searchBar m-1 d-flex "
                  >

                    <Form.Control
                      style={{ width: "350px" }}
                      type="search"
                      placeholder="Search by Application ID or Applicant Name"
                      onChange={(e) => (searchQuery = e.target.value)}
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          e.preventDefault();
                          props.setSearchText(searchQuery);
                        }
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faSearch}
                      onClick={(e) => {
                        e.preventDefault();
                        props.setSearchText(searchQuery);
                      }}
                      style={{
                        color: 'grey',
                        position: 'relative',
                        top: '12px',
                        right: "30px"
                      }}
                    />
                  </Form.Group>
                </Form>
              </Nav.Item>




            </div>
            <div style={{ display: 'flex', justifyContent: "baseline", alignItems: "baseline", position: 'relative', top: '-10px', left: "50%" }}>

              <div>
                <div onClick={handleDisplayDropDown}
                  className={props.fillterDisplayName?.length !== 0 ? 'FilterHeadder' : 'Filter_wrapper'}>

                  {props.fillterDisplayName?.length !== 0 ? <p onClick={() => { window.location.reload() }}> Clear Filter</p>
                    : <p > Filter </p>}{dropDown === false && props.fillterDisplayName?.length === 0 ? <img src={require("../../assets/images/sort (1).png")} /> : <div onClick={() => {
                      if (props.fillterDisplayName?.length != 0) {
                        window.location.reload()
                      }
                    }}> <img src={require("../../assets/images/wrong.png")} />  </div>}
                </div>

                {/* {console.log(items.applicationType)} */}
                {dropDown === true ? <div className='mainDropdownContainer '>
                  <nav>   <div >   <li style={{ fontSize: "13px" }} onClick={() => { setSubdropdown(!subDropDown) }}> Date {subDropDown == true ?
                    <img width="20px" src={require('../../assets/images/up-chevron (1).png')} />
                    : <img width="20px" src={require('../../assets/images/down.png')} />}
                  </li> <span >   {subDropDown == true ? <input onChange={(e) => { setFilterDate(e.target.value); props.AllfitlerItems.push(e.target.value) }} style={{ marginTop: "10px" }} type="date" /> : ""}
                    </span>
                  </div>

                    <div >
                      <li style={{ fontSize: "13px" }} onClick={() => { setStatusDropdown(!Statusdropdown) }}>Applicaiton Status {Statusdropdown == true ?
                        <img width="20px" src={require('../../assets/images/up-chevron (1).png')} />
                        : <img width="20px" src={require('../../assets/images/down.png')} />} </li>
                      <span>{Statusdropdown == true ? <div className='Status_Dropdown'>

                        {props != null && newStatusData.map((items, index) => {
                          return <label>
                            <input type='checkbox' name='type' onChange={(e) => { handleChangeCheckboxStatus(e) }} value={items.AppliType} />  &nbsp; &nbsp;{items.AppliType.replace(/_/g, ' ')}
                          </label>
                        })

                        }

                      </div> : ""}

                      </span>
                    </div>
                    <div>
                      <li style={{ fontSize: "14px" }} onClick={() => { setApplicationdropDown(!ApplicationdropDown) }}  >
                        Application Type {ApplicationdropDown == true ? <img width="20px" src={require('../../assets/images/up-chevron (1).png')} /> : <img width="20px" src={require('../../assets/images/down.png')} />} </li>
                      <span >{ApplicationdropDown == true ?
                        <div className='Status_Dropdown2'>
                          <label >
                            <input type='checkbox' name='status' onChange={(e) => { handleChangeCheckboxType(e) }} value='SC' /> &nbsp; &nbsp;    Instant Approval
                          </label>
                          <label >
                            <input type='checkbox' name='status' onChange={(e) => { handleChangeCheckboxType(e) }} value='REG' /> &nbsp; &nbsp;    Instant Register
                          </label>
                          <label >
                            <input type='checkbox' name='status' onChange={(e) => { handleChangeCheckboxType(e) }} value='SW' /> &nbsp; &nbsp;    Single Window

                          </label>
                          <label >
                            <input type='checkbox' name='status' onChange={(e) => { handleChangeCheckboxType(e) }} value='OC' /> &nbsp; &nbsp;  OCCUPANCY

                          </label>

                        </div>
                        : ""} </span>
                    </div>

                    <button className='Filter_button' onClick={handleFiltering} style={{ width: "80px", borderRadius: "none", letterSpacing: "1px", float: "right" }}>Filter</button>
                  </nav>

                </div> : ""}

                {/* setFilteringDate   props.setfilterSearch(filterdItem)} */}

              </div>

              <Nav.Item className="d-flex align-item-center">
                {(!history.location.state?.filter ||
                  [
                    FILTER_LIST_ENUM.all,
                    FILTER_LIST_ENUM.review,
                    FILTER_LIST_ENUM.submissions,
                  ].includes(history.location.state?.filter)) && (
                    <>
                      <Nav.Link
                        style={{ marginLeft: "20px" }}
                        eventKey={navFilter()}
                        onSelect={changeFilter}
                        active={true}
                      >
                        <span className="text-capitalize">{navFilter()}</span>
                        <span className="badge">
                          {currentFlow === 'post-verification'
                            ? props.totalCount
                            : navFilter().includes('all')
                              ? count.all
                              : count.review}
                        </span>
                      </Nav.Link>
                      {/* {(history.location.state?.filter || innerListValue) &&
                      innerListValue !== FILTER_LIST_ENUM.all && (
                        <>
                          <OverlayTrigger
                            trigger="hover"
                            rootClose
                            placement="bottom"
                            overlay={popoverGreen}
                          >
                            <Nav.Link
                              eventKey="green"
                              onSelect={changeInternalFilter}
                            >
                              Green{' '}
                              <span className="badge green">
                                {interFilterCount.green}
                              </span>
                            </Nav.Link>
                          </OverlayTrigger>
                          <OverlayTrigger
                            trigger="hover"
                            rootClose
                            placement="bottom"
                            overlay={popoverOrange}
                          >
                            <Nav.Link
                              eventKey="orange"
                              onSelect={changeInternalFilter}
                            >
                              Orange
                            <span className="badge orange">
                                {interFilterCount.orange}
                              </span>
                            </Nav.Link>
                          </OverlayTrigger>
                          <OverlayTrigger
                            trigger="hover"
                            rootClose
                            placement="bottom"
                            overlay={popoverRed}
                          >
                            <Nav.Link
                              eventKey="red"
                              onSelect={changeInternalFilter}
                            >
                              Red{' '}
                              <span className="badge red">
                                {interFilterCount.red}
                              </span>
                            </Nav.Link>
                          </OverlayTrigger>
                        </>
                      )} */}
                    </>
                  )}
                {history.location.state?.filter === FILTER_LIST_ENUM.deemed && (
                  <Nav.Link eventKey="deemed" onSelect={changeFilter}>
                    <span className="text-capitalize">{navFilter()}</span>
                    <span className="badge deemed">
                      {/* {count.deemed} */}
                      {currentFlow === 'post-verification'
                        ? props.totalCount
                        : count.deemed}
                    </span>
                  </Nav.Link>
                )}
                {history.location.state?.filter ===
                  FILTER_LIST_ENUM.completed && (
                    <Nav.Link eventKey="completed" onSelect={changeFilter}>
                      <span className="text-capitalize">{navFilter()}</span>
                      <span className="badge completed">
                        {/* {count.completed} */}
                        {currentFlow === 'post-verification'
                          ? props.totalCount
                          : count.completed}
                      </span>
                    </Nav.Link>
                  )}
                {/* {history.location.state?.filter ===
                FILTER_LIST_ENUM.submissions && (
                <Nav.Link eventKey="submissions" onSelect={changeFilter}>
                  {navFilter()}
                  <span
                    className="badge submissions"
                    style={{ right: '-20px' }}
                  >
                    {count.submissions}
                  </span>
                </Nav.Link>
              )} */}
                {history.location.state?.filter ===
                  FILTER_LIST_ENUM.processed && (
                    <Nav.Link eventKey="processed" onSelect={changeFilter}>
                      <span className="text-capitalize">{navFilter()}</span>
                      <span className="badge processed">
                        {/* {count.processed} */}
                        {currentFlow === 'post-verification'
                          ? props.totalCount
                          : count.processed}
                      </span>
                    </Nav.Link>
                  )}
              </Nav.Item>
            </div>
          </Nav>

          {/* <div className="d-flex align-items-center">
            <div className="search-container">
              <Form.Group controlId="formBasicEmail">
                <Form.Control type="text" placeholder="Search" />
              </Form.Group>
            </div>
            <div className="filter-container">
              <img
                src={require('../../assets/images/equalizer.svg')}
                alt="equalizer"
              />
              <p>Filter</p>
            </div>
          </div> */}
        </div>
        <div className="al-content">

          <Tab.Content>
            {/* <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"

                    buttonText="Download as XLS"/> */}

            {/* <CSVLink data={filteredData}>Download me</CSVLink>; */}
            <Table striped hover >
              <thead>
                <tr>

                  <th nowrap="true">S.No.</th>
                  <th className="text-left" nowrap="true">
                    Application ID <br /> Applicant Name
                  </th>
                  <th nowrap="true">
                    <p className='d-flex align-items-center justify-content-between' style={{ fontSize: "12px" }}>
                      <span>   Received Date </span>

                      {/* <button onClick={SortingData}>fi</button> */}
                      <HtmlTooltip title={props.sortRecivedDate == "ASC" ? "SORT-- New to Old" : "SORT-- Old to New"}>
                        <Button
                          // style={{height:"100%"}}
                          onClick={SortReccivedData}
                        >
                          <BiSortAlt2 />
                        </Button></HtmlTooltip>
                    </p>
                  </th>
                  <th nowrap="true">
                    <p className='d-flex align-items-center justify-content-between' style={{ fontSize: "12px" }}>
                      <span >   Assigned Date  </span>

                      {/* <button onClick={SortingData}>fi</button> */}
                      <HtmlTooltip title={props.sortAssignedDate == "ASC" ? `SORT --   ${" "}   New to Old ` : `SORT --     ${" "}  Old to New`}>
                        <Button
                          //  style={{height:""}}
                          onClick={SortAssignedDAte}
                        >
                          <BiSortAlt2 />
                        </Button></HtmlTooltip>
                    </p>
                  </th>
                  <th nowrap="true">
                    Application Type
                    {/* <div className="application-list-item filter-dropdown">
                      <Dropdown style={{ cursor: 'pointer', width: 'fit-content' }}>
                        <Dropdown.Toggle>Verify</Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{ width: 'fit-content' }}
                          flip={false}
                        >
                          <Dropdown.Item style={{ cursor: 'pointer', paddingRight: '0px' }}>ALL</Dropdown.Item>
                          <Dropdown.Item style={{ cursor: 'pointer', paddingRight: '0px' }}>INSTANT REGISTRATION</Dropdown.Item>
                          <Dropdown.Item style={{ cursor: 'pointer', paddingRight: '0px' }}>INSTANT APPROVAL</Dropdown.Item>
                          <Dropdown.Item style={{ cursor: 'pointer', paddingRight: '0px' }}>ADDITIONAL</Dropdown.Item>
                          <Dropdown.Item style={{ cursor: 'pointer', paddingRight: '0px' }}>OCCUPANCY</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div> */}
                  </th>
                  <th nowrap="true">Plot Area</th>

                  {['DEEMED'].includes(props.currentFilter) ? (
                    <>
                      <th nowrap="true">
                        Deemed Date <br /> DD/MM/YYYY
                      </th>
                      <th nowrap="true">
                        Deemed Action Due Date <br /> DD/MM/YYYY
                      </th>
                    </>
                  ) : (''
                    // <th nowrap="true">
                    //   Due Date <br />( DD/MM/YYYY )
                    // </th>
                  )}
                  {/* <th nowrap="true">(Penalty &#8377;)</th> */}
                  <th nowrap="true" style={{ zIndex: '1001 ' }}>
                    Status
                    {/* <div className="application-list-item filter-dropdown">
                      <Dropdown style={{ cursor: 'pointer' }}>
                        <Dropdown.Toggle>Verify</Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{ width: 'fit-content' }}
                          flip={false}
                        >
                          <Dropdown.Item>ALL</Dropdown.Item>
                          <Dropdown.Item>
                            VERIFICATION UNDER PROGRESS
                          </Dropdown.Item>
                          <Dropdown.Item>FEE ADJUSTMENTS</Dropdown.Item>
                          <Dropdown.Item>FINAL VERIFICATION</Dropdown.Item>
                          <Dropdown.Item>APPROVED</Dropdown.Item>
                          <Dropdown.Item>REVOKED</Dropdown.Item>
                          <Dropdown.Item>HOLD</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div> */}
                  </th>

                  {!['COMPLETED', 'ALL', 'LINE_DEPARTMENT_APPLICATION'].includes(props.currentFilter) && (removeActionInProcessd() == false
                  ) && (['DEEMED'].includes(props.currentFilter) ? (
                    (permissionLevel === PERMISSIONS_LEVELS.COMMISSIONER ||
                      permissionLevel ===
                      PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER) && (
                      <th nowrap="true" style={{ zIndex: '1001' }}>
                        Action
                      </th>
                    )
                  ) : (
                    <th nowrap="true" style={{ zIndex: '1001' }}>
                      Action
                    </th>
                  ))}
                </tr>
              </thead>
              {props?.data != undefined ? <tbody>
                {props?.data &&
                  props?.data?.length > 0 &&
                  props?.data
                    .filter((el) => {
                      if (internalFilter === '') {
                        return true;
                      } else {
                        return isInternalFilterEligible(
                          el.dueDate,
                          internalFilter
                        );
                      }
                    })
                    .map((item, index) => {
                      //  console.log(item.assignedDate,"itemkijyfds")
                      // {console.log(item.status,"allItems")}
                      // if (dueDateFilter(item.dueDate)) {
                      return (
                        <>
                          <ApplicationItem
                            removeActionInProcessd={removeActionInProcessd()}
                            key={index}
                            filter={props.currentFilter}
                            data={item}
                            pageType={props.pageType}
                            index={(props.currentPage - 1) * 10 + index + 1}
                            enableNotes={props.enableNotes}
                            status={item.status}
                            assignedDate={item.assignedDate}
                          />
                        </>
                      );
                      // }
                      // return null;
                    })}

              </tbody>
                :
                <>
                  <div className='ErroDataDisplay'>
                    {props.currentFilter === 'REVIEW' && props?.data == undefined ? <img width="380px" src={require("../../assets/images/Graphic 2.png").default} /> : ""}
                  </div>
                </>
              }
            </Table>
            {props?.data != undefined ? <div
              className="p-3"
              style={{ marginTop: '45px', marginBottom: '45px', display: "flex", alignItems: 'center', justifyContent: "space-around" }}
            >
              <Row>
                <Col md="12" className='PageNate' >
                  <Pagination
                    count={props.pageCount}
                    page={props.currentPage}
                    onChange={(event, value) => {
                      props.setCurrentPage(value);
                    }}
                  />
                </Col>
              </Row>
              <div style={{ display: "flex", gap: "15px" }}>
                <button className='export-btn' onClick={downloadAsExcelPerPage}>Export to Excel  per page  <GoDesktopDownload fontSize={"12px"} /> </button>
                {props.excelLoading ? <button className='export-btn'>
                  <SpinLoader />
                </button> : <button className='export-btn' onClick={handleShowAnswersPopUp}> export all Data  <GoDesktopDownload fontSize={"12px"} /> </button>}
              </div>
            </div> : " "}
          </Tab.Content>

        </div>
      </Tab.Container>
    </div>
  );
}

ApplicationList.propTypes = {
  data: PropTypes.array,
  pageType: PropTypes.string,
  currentFilter: PropTypes.string,
  setCurrentPage: PropTypes.func,
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  setSearchText: PropTypes.func,
  searchText: PropTypes.string,
  enableNotes: PropTypes.bool,
  setfilterSearch: PropTypes.array
};

ApplicationList.defaultProps = {
  setCurrentPage: () => { },
  pageCount: 1,
  totalCount: 0,
  setSearchText: () => { },
  searchText: '',
  enableNotes: false,
  filterSearch: []
};

export default ApplicationList;

