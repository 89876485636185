import React from 'react';
import moment from 'moment';
import en from './en';

export const updateModel = (oldObject, updatedValues) => {
  return {
    ...oldObject,
    ...updatedValues,
  };
}; // to reduce reducer cases cluttering

export const APPLICATION_STATUS_ENUM = Object.freeze({
  DRAFT: 'DRAFT',
  IN_PROCESS: 'IN_PROCESS',
  SUBMITTED: 'SUBMITTED',
  VERIFICATION_UNDER_PROGRESS: 'VERIFICATION_UNDER_PROGRESS',
  FEE_ADJUSTMENTS: 'FEE_ADJUSTMENTS',
  PROCEEDINGS_IN_PROGRESS: 'PROCEEDINGS_IN_PROGRESS',
});

export const APPLICATION_SUB_STATES_ENUM = Object.freeze({
  IN_PROCESS: 'IN_PROCESS',
  NOT_RECOMMENDED: 'NOT_RECOMMENDED',
  RECOMMENDED: 'RECOMMENDED',
});

export const PERMISSIONS_LEVELS = Object.freeze({
  TPSO_OFFICER: 'TPSO_OFFICER',
  ACP_OFFICER: 'ACP_OFFICER',
  CP_OFFICER: 'CP_OFFICER',
  RDDTP_OFFICER: 'RDDTP_OFFICER',
  COMMISSIONER: 'COMMISSIONER',
  PANCHAYAT_SECRETARY: 'PANCHAYAT SECRETARY',
  ZONAL_COMMISSIONER: 'ZONAL_COMMISSIONER',
  DEPUTY_COMMISSIONER: 'DEPUTY_COMMISSIONER',
  TITLE_VERIFICATION: 'TITLE_VERIFICATION',
  SITE_VERIFICATION: 'SITE_VERIFICATION',
  TECHNICAL_VERIFICATION: 'TECHNICAL_VERIFICATION',
  CHASING_CELL: 'CHASING_CELL',
  CIO_CHASING_CELL: 'CIO_CHASING_CELL',
  DISTRICT_COLLECTOR: 'DISTRICT_COLLECTOR',
  ADDITIONAL_COLLECTOR: 'ADDITIONAL_COLLECTOR',
  IRRIGATION_OFFICER: 'IRRIGATION OFFICER',
  FIRE_DEPARTMENT: 'FIRE DEPARTMENT',
  POLICE_DEPARTMENT: 'POLICE DEPARTMENT',
  REVENUE_DEPARTMENT: 'REVENUE DEPARTMENT',
  IRRIGATION_DEPARTMENT: 'IRRIGATION DEPARTMENT',
  RAILWAY_DEPARTMENT: 'RAILWAY DEPARTMENT',
  ELECTRICITY_DEPARTMENT: 'ELECTRICITY DEPARTMENT',
  AIRPORT_DEPARTMENT: 'AIRPORT DEPARTMENT',
  OIL_AND_GAS_DEPARTMENT: 'OIL AND GAS DEPARTMENT',
  NMA_DEPARTMENT: 'NMA DEPARTMENT',
  ENDOWMENT_DEPARTMENT: 'ENDOWMENT DEPARTMENT',
  FOREST_DEPARTMENT: 'FOREST DEPARTMENT',
  SEIAA_DEPARTMENT: 'SEIAA DEPARTMENT',
  R_AND_B_DEPARTMENT: 'R AND B DEPARTMENT',
  TAHSILDAR: 'TAHSILDAR',
  ASSISTANT_PLANNING_OFFICER: 'ASSISTANT_PLANNING_OFFICER',
  PLANNING_OFFICER: 'PLANNING_OFFICER',
  DIRECTOR_HMDA: 'DIRECTOR_HMDA',
  DTCP_DIRECTOR: 'DTCP_DIRECTOR',
  METROPOLITAN_COMMISSIONER: 'METROPOLITAN_COMMISSIONER',
  FINAL_APPROVING_OFFICER:'FINAL APPROVING OFFICER',
  PANCHAYAT_SECRETARY:"PANCHAYAT SECRETARY",
  VICE_CHAIRMAN : "VICE CHAIRMAN",
  TECHNICAL_SCRUTINY_OFFICER:"Technical Scrutiny Officer ",
  SECTION_HEAD:"SECTION HEAD",
  SECTION_OFFICER:"SECTION OFFICER",
  TECH_OFFICER :"Technical Officer",
  UDA_GP_SITE_OFFICER:"SITE OFFICER",
  CP_TECH_OFFICER:"Chief Planning Officer"
});

export const LINE_DEPARTMENTS = [
  PERMISSIONS_LEVELS.IRRIGATION_OFFICER,
  PERMISSIONS_LEVELS.FIRE_DEPARTMENT,
  PERMISSIONS_LEVELS.POLICE_DEPARTMENT,
  PERMISSIONS_LEVELS.REVENUE_DEPARTMENT,
  PERMISSIONS_LEVELS.IRRIGATION_DEPARTMENT,
  PERMISSIONS_LEVELS.RAILWAY_DEPARTMENT,
  PERMISSIONS_LEVELS.ELECTRICITY_DEPARTMENT,
  PERMISSIONS_LEVELS.AIRPORT_DEPARTMENT,
  PERMISSIONS_LEVELS.OIL_AND_GAS_DEPARTMENT,
  PERMISSIONS_LEVELS.NMA_DEPARTMENT,
  PERMISSIONS_LEVELS.ENDOWMENT_DEPARTMENT,
  PERMISSIONS_LEVELS.FOREST_DEPARTMENT,
  PERMISSIONS_LEVELS.SEIAA_DEPARTMENT,
  PERMISSIONS_LEVELS.R_AND_B_DEPARTMENT,
];

export const FILTER_LIST_ENUM = Object.freeze({
  all: 'ALL',
  deemed: 'DEEMED',
  completed: 'COMPLETED',
  submissions: 'SUBMISSIONS',
  review: 'REVIEW',
  processed: 'PROCESSED',
  linedepartment : 'LINE_DEPARTMENT_APPLICATION'

});

export const FILTER_LIST_CORRECTION = Object.freeze({
  correction:'CORRECTION_VERIFICATION',
  completed:"CORRECTION_COMPLETED",
  Submitted:"SUBMITTED"
})

export const formatDateToString = (date) => {
  date = date.split(' ')[0];
  date = new Date(date);
  var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
  var MM = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
  var yyyy = date.getFullYear();
  return dd + '/' + MM + '/' + yyyy;
};

export const isSuccessfullResponse = (response) => {
  if (
    response.status &&
    response.status === 200 &&
    response.statusText &&
    response.statusText === 'OK'
  )
    return true;

  return false;
}; // used to to determine for a success result commonly used by many functions

const getString = (str) => {
  let result = str.match(/\[(.*?)\]/);
  return result[1];
};

export const getQuestionsWithLink = (str) => {
  if (str.includes('[')) {
    let question = str.split('[')[0];
    let url = getString(str);
   
    return (
      <>
        {question}
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      </>
    );
  } else {
    return str;
  }
};

export const isStageRecommended = (
  verifiationName,
  statusObj,
  currentOfficerLevel


) => {

  try {

    if (!currentOfficerLevel ||  !statusObj || statusObj.length === 0) {
      return false;
    }
    let filteredStatusObj = statusObj.find(
      (obj) => obj.name === 'LEVEL-1'
    );
    console.log(filteredStatusObj,'statusObj')

    if (
      filteredStatusObj &&
      filteredStatusObj.status === APPLICATION_STATUS_ENUM.IN_PROCESS
    ) {
      return false;
    }
    if (
      currentOfficerLevel === "L2" &&
      filteredStatusObj &&
      filteredStatusObj.status === APPLICATION_STATUS_ENUM.SUBMITTED
    ) {
      return false;
    }
    return true;
  } catch (e) {
    console.log('error occorred while checking recommendation stage', e);
    return false;
  }
};

export const dayDifference = (dueDate) => {
  const curTime = new Date().getTime();
  const dayDiff = Math.floor(
    (new Date(dueDate).getTime() - curTime) / (1000 * 60 * 60 * 24)
  );
  return dayDiff;
};
export const submissionsLabel = (PERMISSIONS_LEVELS, permissionLevel) => {
  if ([PERMISSIONS_LEVELS.TPSO_OFFICER].includes(permissionLevel))
    return 'Under Process';
  else if ([PERMISSIONS_LEVELS.ACP_OFFICER].includes(permissionLevel)) {
    return 'Under Process Above 200 Sqm';
  } else return 'Submissions';
};

export const reviewsLabel = (PERMISSIONS_LEVELS, permissionLevel) => {
  if (
    [
      PERMISSIONS_LEVELS.CP_OFFICER,
      PERMISSIONS_LEVELS.ZONAL_COMMISSIONER,
      PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER,
      PERMISSIONS_LEVELS.DTCP_DIRECTOR,
      PERMISSIONS_LEVELS.RDDTP_OFFICER,
      PERMISSIONS_LEVELS.ASSISTANT_PLANNING_OFFICER,
      PERMISSIONS_LEVELS.TAHSILDAR,
      PERMISSIONS_LEVELS.METROPOLITAN_COMMISSIONER,
      PERMISSIONS_LEVELS.DIRECTOR_HMDA,
    ].includes(permissionLevel)
  )
    return 'Under Process';
  else if (
    [
      PERMISSIONS_LEVELS.SITE_VERIFICATION,
      PERMISSIONS_LEVELS.TITLE_VERIFICATION,
      PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION,
      PERMISSIONS_LEVELS.COMMISSIONER,
      PERMISSIONS_LEVELS.PANCHAYAT_SECRETARY,
      PERMISSIONS_LEVELS.TECHNICAL_SCRUTINY_OFFICER,
      PERMISSIONS_LEVELS.SECTION_HEAD,
      permissionLevel.SECTION_OFFICER

      // PERMISSIONS_LEVELS.FINAL_APPROVING_OFFICER
    ].includes(permissionLevel)
  )
    return 'Under Process';
  else if ([PERMISSIONS_LEVELS.ACP_OFFICER].includes(permissionLevel))
    return 'Under Process';
  else if (LINE_DEPARTMENTS.includes(permissionLevel)) return 'Under Process';
  else return 'Under Process';
  // else return 'Reviews';
};

export const isShowMortgageSection = (applicationType, buildingData) => {
  try {
    if (applicationType === 'REGISTRATION') {
      return false;
    }
    const netPlotArea = parseInt(
      buildingData.net_plot_area_display.split(' ')[0]
    );
    const noOfFloors = buildingData.building_info.proposed_no_of_floors_name
      .split(' ')
      .includes('Ground')
      ? (parseInt(
        buildingData?.building_info?.proposed_no_of_floors_name?.match(/\d+/)
      ) &&
        parseInt(
          buildingData?.building_info?.proposed_no_of_floors_name?.match(
            /\d+/
          )[0]
        )) ||
      0
      : 0;
    if (applicationType === 'SELF CERTIFICATION') {
      let minAreaForMotgage = 200; // 200 if the area is in Sq. Meters
      if (buildingData.net_plot_area_display.includes('Sq. Yards')) {
        minAreaForMotgage = 239.19; // 239.19 if the area is in Sq. Yards
      }
      if (netPlotArea > minAreaForMotgage || noOfFloors >= 2) {
        return true;
      }
    } else return false;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const roleBasedMenu = {
  'CIO-Chasing Cell': [],
  'Chief Minister Office': [],
  'PRINICIPAL SECRETARY': [],
  'Municipal Commissioner': ['ULB Report', 'District Report'],
  'DEPUTY COMMISSIONER': ['District Report'],
  'DISTRICT COLLECTOR': ['District Report'],
  'ADDITIONAL COLLECTOR': ['District Report'],
  'NODAL OFFICER': ['District Report'],
  'HMDA DIRECTOR': ['Authorities Report'],
  'GHMC DIRECTOR': ['Authorities Report'],
  'DTCP DIRECTOR': ['Authorities Report'],

};

export const hideChasingcellMenuItem = (data, name) => {
  if (!name) return data;
  return data.filter(
    (item) => !(roleBasedMenu[name] || []).includes(item.label)
  );
};

export const YearsData = () => {
  let years = [];
  Array.from({ length: 5 }).forEach((_, index) => {
    let year = moment().subtract(index, 'years').format('YYYY');
    years.push(year);
  });
  return years;
};

export function getTranslatedText(string) {
  return en[string] || string || '';
}

export const getTranslatedToList = (data) => {
  let items;
  try {
    items = JSON.parse(data);
  } catch (err) {
    return data;
  }
  return (
    <div>
      {items.map((el, index) => {
        return <li key={index}>{el}</li>;
      })}
    </div>
  );
};
