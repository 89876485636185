import React, { useState, memo, useContext, useEffect } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import './index.scss';
import { PropTypes } from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_APPLICATION_QUESTIONS } from '../../graphql/query/applicationQuestions';
import Answer from './Answer';
import ShortFallToggle from '../ShortFallToggle';
import AddRemark from '../AddRemark';
import { AppContext } from './../../AppContext';
import DeviationLevelTwo from '../ApplicationDetailComponent/deviationL2'
import DeviationLevelOne from '../ApplicationDetailComponent/deviationL1'
import {
  PERMISSIONS_LEVELS,
  getTranslatedToList,
} from '../../util/helperFunctions';
import history from '../../services/history';
import { GET_REVIEW_REMARKS } from '../../graphql/query/reviewRemarks';
import { green } from '@material-ui/core/colors';
import moment from 'moment';
import OldAnswer from './oldAnswer';
const IAIRAnswerList = memo((props) => {
  // const { data: answersData, loading, error } = {};
  console.log(props ,"checking props")
  const { data: answersData, loading, error } = useQuery(
    GET_REVIEW_REMARKS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: props.id,
        page: 1,
        perPage: 30
      },
    }
  );
  const [open, setOpen] = useState(-1);

  useEffect(() => {
    if (answersData && getDataToDisplay(answersData)?.reviewRemarks) {
      shouldNotesBeDisabled(getDataToDisplay(answersData).reviewRemarks);
    }
  }, [answersData]);

  // Have to make initial state dynamic somehow
  const [shortFall, setShotFall] = useState({
    0: false,
    1: false,
    2: false,
  });
  const { permissionLevel } = useContext(AppContext);
  // console.log(permissionLevel,'permissionLevelwerty');
  const chevronFlip = (e) => {
    console.log(e, open);
    setOpen(e);
  };
  const getDataToDisplay = (ansData) => {
    if (!props.currentVerification || !ansData) {
      return ansData;
    } else {
      const appsForm = [...ansData?.reviewRemarks];
      const newFormsData = appsForm.filter(
        (el) => el.stage === props.currentVerification && el.beforeShowcase == true
      );
      return { reviewRemarks: newFormsData };
    }
  };

  const sortCompare = (a, b) => {
    if (!b?.order || parseInt(a?.order, 10) < parseInt(b?.order, 10)) {
      return -1;
    }
    if (!a?.order || parseInt(a?.order, 10) > parseInt(b?.order, 10)) {
      return 1;
    }
    return 0;
  };
  const parseAndFormatDate = (createdAt) => {
    if (/\d{2}:\d{2}:\d{2}/.test(createdAt)) {
      const parts = createdAt.match(/(\d+)/g);
      return new Date(
        Date.UTC(
          parseInt(parts[0], 10),
          parseInt(parts[1], 10) - 1,
          parseInt(parts[2], 10),
          parseInt(parts[3], 10),
          parseInt(parts[4], 10),
          parseInt(parts[5], 10)
        )
      ).toLocaleString('en-IN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC', // Set timeZone to UTC to remove the time zone abbreviation
      }).replace(/(am|pm)/i, '');
    } else {
      return moment(createdAt, 'YYYY-MM-DDTHH:mm:ss').format('DD-MM-YYYY');
    }
  };

  const shouldNotesBeDisabled = (data) => {
    /** { This was the logic before for the notes to be disabled,
     * Now we are using the sidebar filter to make the notes disabled,
     * This is irrespective of the permissionLevel and statusObj.status,
     * Uncomment this if the previous logic is required back.
     * } */

    // if site, title or technical this is logic
    // if (
    //   [
    //     PERMISSIONS_LEVELS.SITE_VERIFICATION,
    //     PERMISSIONS_LEVELS.TITLE_VERIFICATION,
    //     PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION,
    //   ].includes(permissionLevel)
    // ) {
    //   const statusObj = data.find(
    //     (obj) => obj.stage === permissionLevel.replace('_', ' ')
    //   );
    //   const isDisabled = statusObj?.status !== 'IN_PROCESS';
    //   props.setIsDisabledNotes(isDisabled); //  allow notes,
    //   return;
    // }
    // if commissioner, zonal commissioner
    // if (
    //   [
    //     PERMISSIONS_LEVELS.COMMISSIONER,
    //     PERMISSIONS_LEVELS.ZONAL_COMMISSIONER,
    //     PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER,
    //   ].includes(permissionLevel)
    // ) {
    //   let flag = true; // dont allow notes,
    //   const filterData = data.filter(
    //     (el) => !el.stage.includes('FEE CERTIFICATION')
    //   );
    //   filterData.map((item) => {
    //     if (['IN_PROCESS'].includes(item.stageState.status)) {
    //       flag = false; //  allow notes,
    //     }
    //   });
    //   props.setIsDisabledNotes(flag);

    /** { --- Old logic end here --- } */

    // Using this when officer comes here from summary report console
    if (props.fromSummary) {
      props.setIsDisabledNotes(true); //  disable notes,
      return;
    }
    // Using this when officer comes here from post verification console and
    // enableNotes is currently true for 'Under Verification' sidebar filter
    if (history.location.state?.enableNotes) {
      props.setIsDisabledNotes(false); //  allow notes,
    }
  };

  const getOfficerFinalRemarks = (stageState, level) => {
    console.log(stageState, 'stageState')
    if (level === 'L1') {
      return getFinalRemarks(stageState?.l1Remarks);
    } else if (level === 'L2') {
      return getFinalRemarks(stageState?.l2Remarks);
    } else {
      if (!level && stageState.finalRemark)
        return getFinalRemarks(stageState.finalRemark);
      return <span>not reviewed</span>;
    }
  };
  const getcolorStatus = (status) => {
    if (status == 'RECOMMENDED') {
      return '#1c9a5b'
    } else if (status == 'NOT_RECOMMENDED') {
      return '#fa4747'
    }
  }

  const getFinalRemarks = (finalRemark) => {
    console.log(finalRemark, 'finalRemarks');
    let reasons;
    if (!finalRemark) return <span>not reviewed</span>;
    try {
      reasons = JSON.parse(finalRemark);
    } catch (e) {
      return <span>{finalRemark || 'not reviewed'}</span>;
    }
    return (
      <div>
        {reasons.map((el, index) => {
          return <li key={index}>{el}</li>;
        })}
      </div>
    );
  };

  let isRemarksEmpty = true;
  try {
    return (
      <div style={{display:"flex", flexDirection:"column", gap:"20px"}}>

        <div className='beforeShowCauseRemarks'>
       {props.status == "SINGLE WINDOW"  ? <h1> before shortFall</h1>  :  <h1> before Show cause</h1>  }  
      <Accordion   defaultActiveKey="-1" onSelect={(e) => chevronFlip(e)}>
        {loading && (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {getDataToDisplay(answersData) &&
          getDataToDisplay(answersData).reviewRemarks && (
            <Card>
              {getDataToDisplay(answersData)?.reviewRemarks.filter(item => item?.stageState?.completedDate !== null && item.beforeShowcase == true).map(
                (formItem, indexForm) => {
                
                  const newDAte = formItem.stageState.completedDate
                  return formItem.stageState?.stageOfficers.map((item, id) => {
                    if (
                      (props.isVerification && item.officerLevel !== 'L2') ||
                      !props.isVerification ||
                      [
                        PERMISSIONS_LEVELS.COMMISSIONER,
                        PERMISSIONS_LEVELS.ZONAL_COMMISSIONER,
                        PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER,
                      ].includes(permissionLevel)
                    ) {
                      isRemarksEmpty = false;
                      return permissionLevel ===
                        PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION &&
                        !(
                          (id === 0 &&
                            formItem.stageState.name ===
                            'TECHNICAL VERIFICATION') ||
                          formItem.stageState.name !== 'TECHNICAL VERIFICATION'
                        ) ? null : (
                        <div
                        className='Child-card_wrapper'
                          style={{
                            //boxShadow: '0px 1px 8px 1px lightgrey',
                            padding: '10px',
                            margin: '15px',
                            cursor:"pointer"
                          }}
                        >
                          <Card
                            className="answer-card"
                            key={indexForm + ':' + id}
                            style={{
                              height: 'auto',
                              margin: '10px',
                            }}
                          >
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey={indexForm + ':' + id}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <div style={{ width: "300px" }}>
                                  <b >{formItem.title?.replace('form', 'Incpection Report')}</b>
                                  <br />
                                  <div>
                                    <span style={{ fontWeight: "bold" }}>   Status:</span>

                                    &nbsp; <span style={{ color: getcolorStatus(formItem.status) }}>
                                      {formItem.status
                                        ? ' ' + formItem.status?.replace(/_/g, ' ')
                                        : ' Not started'}
                                    </span>
                                  </div>
                                  {/* <br/> */}
                                  <div>
                                    <span style={{ fontWeight: "bold" }}>
                                      Received Date:
                                    </span>

                                    &nbsp; {formItem?.stageState?.prevStartDate != null && formItem?.stageState?.receivedDate == null ? <span style={{ fontSize: "13px", fontWeight: "bold" }}>
                                      {/\d{2}:\d{2}:\d{2}/.test(formItem?.stageState?.prevStartDate) == true ?
                                        new Date(formItem?.stageState?.prevStartDate)?.toLocaleString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })?.replace(/(am|pm)/i, '')
                                        : moment(formItem?.stageState?.prevStartDate)?.format(
                                          'DD/MM/YYYY'
                                        )}
                                    </span>
                                      : <span>{moment(formItem?.stageState?.receivedDate)?.format(
                                        'DD/MM/YYYY'
                                      )}</span>}
                                  </div>

                                  {formItem?.stageState?.prevStartDate != null ? <div>
                                    <span style={{ fontWeight: "bold" }}>
                                      Received Date After show cause:
                                    </span>
                                    &nbsp;  <span>{moment(formItem?.stageState?.prevStartDate)?.format(
                                      'DD/MM/YYYY'
                                    )}</span>


                                  </div> : ""}


                                  <br />
                                  {item.officerLevel && 'Officer Level:'}
                                </div>
                                <div>
                                  <div style={{ width: "400px" }}>
                                    <span style={{ fontWeight: "bold" }}>
                                      {item.officerName} &nbsp;{`(${item.officerRole?.replace(/_/g, ' ')})`}
                                    </span>
                                    <br />
                                    <span style={{ fontWeight: "bold" }}>
                                      Forwarded Date:
                                    </span>


                                    &nbsp;  <span style={{ fontSize: "13px", fontWeight: "bold" }}>

                                      {formItem?.stageState?.completedDate !== null ?
                                        <>
                                         {parseAndFormatDate(formItem?.stageState?.completedDate)}
                                        </>
                                         : <>{"Not Yet Recevied"}</>}

                                    </span>
                                    <br />
                                    {item.officerLevel}
                                    {/* <br /> */}
                                    <span style={{ fontWeight: "bold" }}>  Final Remark: </span>

                                    <span style={{ fontWeight: "bold" }}>
                                      &nbsp;  {formItem?.stageState?.finalRemark?.replace(/[\[\]"]+/g, '')}

                                    </span>
                                  </div>

                                  <br />
                                </div>
                                <img
                                  src={require('../../assets/images/dd-arrow.svg')}
                                  className={
                                    open ===  indexForm  ? 'arrowUP' : null
                                  }
                                />
                              </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={indexForm + ':' + id}>

                              <Card>


                                <>

                                  {formItem?.oldAnswer != null ?
                                    <Accordion style={{
                                      boxShadow: '0px 1px 8px 1px lightgrey',
                                      padding: '10px',
                                      margin: '15px',
                                    }} >
                                      <Card className="answer-card"
                                        key={indexForm + ':' + id}
                                        style={{
                                          height: 'auto',
                                          margin: '10px',
                                        }}>

                                        <Accordion.Toggle as={Card.Header}
                                          eventKey={indexForm + ':' + id} >
                                          <span style={{ fontSize: "14px", color: "red" }}>  {"Before Showcause remarks "} </span>
                                          <img
                                            src={require('../../assets/images/dd-arrow.svg')}
                                            className={
                                              open ===  indexForm  ? 'arrowUP' : null
                                            }
                                          />
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={indexForm + ':' + id}>
                                          <Card.Body>
                                            <div className="answer-list">

                                              {/* SPREADING AND MAKING A NEW COPY OF ARRAY BECAUSE SORTING NOT ALLOWED (ARRAY IS FROZEN IN STRICT MODE) */}
                                              {[...formItem.fields]
                                                .sort(sortCompare)
                                                .map((fieldItem, indexField) => {
                                                  if (
                                                    formItem.stage === 'FEE CERTIFICATION'
                                                  )
                                                    return (
                                                      <div key={indexField}>
                                                        <h6 style={{ fontSize: "13px" }}>Final Remark : </h6>
                                                        <p style={{ fontSize: "13px" }}  >
                                                          {
                                                            formItem.oldAnswer.data
                                                              .finalremark
                                                          }
                                                        </p>
                                                      </div>
                                                    );
                                                  else
                                                    return (
                                                      <>
                                                        <Answer
                                                          field={fieldItem}
                                                          answer={
                                                            formItem?.oldAnswer?.data || null
                                                          }
                                                          // oldAnswer={formItem?.oldAnswer?.data || null}
                                                          key={indexField}
                                                        />
                                                        {/* <OldAnswer
                                            
                                            field={fieldItem}
                                              answer={
                                                formItem?.oldAnswer?.data || null
                                              }
                                              // oldAnswer={formItem?.oldAnswer?.data || null}
                                              key={indexField}
                                            /> */}
                                                      </>
                                                    );
                                                })}

                                              {item.officerLevel === 'L1' && formItem.title === "Site_OCCUPANCY_GHMC" && formItem.status !== "IN_PROCESS" &&
                                                <DeviationLevelOne />}
                                              {item.officerLevel === 'L2' && formItem.title === "Site_OCCUPANCY_GHMC" && formItem.status !== "IN_PROCESS" &&
                                                <DeviationLevelTwo />}
                                              {(formItem.title === "Site_OCCUPANCY_DTCP" || formItem.title === "SITE_OCCUPANCY_HMDA") && formItem.status !== "IN_PROCESS" &&
                                                <DeviationLevelOne />}
                                              {(formItem.title === "Technical_OCCUPANCY_DTCP" || formItem.title === "TECH_OCCUPANCY_HMDA") && formItem.status !== "IN_PROCESS" &&
                                                <DeviationLevelTwo />}

                                              <div className="my-2">
                                                <h6 style={{ fontSize: "13px" }}>Final Remarks :</h6>
                                                <p style={{ fontSize: "11px" }}>
                                                  {formItem?.oldAnswer?.data?.finalRemark}
                                                </p>
                                              </div>

                                            </div>

                                          </Card.Body>
                                        </Accordion.Collapse>

                                      </Card>
                                    </Accordion>
                                    : null}
                                  <Accordion style={{
                                    boxShadow: '0px 1px 8px 1px lightgrey',
                                    padding: '10px',
                                    margin: '15px',
                                  }} >
                                    <Card  style={{
                                      height: 'auto',
                                      margin: '10px',
                                    }} >

                                      <Accordion.Toggle as={Card.Header}
                                        eventKey={indexForm + ':' + id} >
                                        <span style={{ fontSize: "14px", color: "green" }} >  {" Remarks "} </span>
                                        <img
                                          src={require('../../assets/images/dd-arrow.svg')}
                                          className={
                                            open ===  indexForm  ? 'arrowUP' : null
                                          }
                                        />
                                      </Accordion.Toggle>
                                      {/* after showcause remarks */}
                                      <Accordion.Collapse eventKey={indexForm + ':' + id}>

                                        <div className="answer-list">
                                          {/* SPREADING AND MAKING A NEW COPY OF ARRAY BECAUSE SORTING NOT ALLOWED (ARRAY IS FROZEN IN STRICT MODE) */}
                                          {[...formItem.fields]
                                            .sort(sortCompare)
                                            .map((fieldItem, indexField) => {
                                              if (
                                                formItem.stage === 'FEE CERTIFICATION'
                                              )
                                                return (
                                                  <div key={indexField}>
                                                    <h6 style={{ fontSize: "13px" }}>Final Remark : </h6>
                                                    <p style={{ fontSize: "13px" }}  >
                                                      {
                                                        formItem.answer.data
                                                          .finalremark
                                                      }
                                                    </p>
                                                  </div>
                                                );
                                              else
                                                return (
                                                  <>
                                                    <Answer
                                                      field={fieldItem}
                                                      answer={
                                                        formItem?.answer?.data || null
                                                      }
                                                      // oldAnswer={formItem?.oldAnswer?.data || null}
                                                      key={indexField}
                                                    />
                                                    {/* <OldAnswer
                                            
                                            field={fieldItem}
                                              answer={
                                                formItem?.oldAnswer?.data || null
                                              }
                                              // oldAnswer={formItem?.oldAnswer?.data || null}
                                              key={indexField}
                                            /> */}
                                                  </>
                                                );
                                            })}

                                          {item.officerLevel === 'L1' && formItem.title === "Site_OCCUPANCY_GHMC" && formItem.status !== "IN_PROCESS" &&
                                            <DeviationLevelOne />}
                                          {item.officerLevel === 'L2' && formItem.title === "Site_OCCUPANCY_GHMC" && formItem.status !== "IN_PROCESS" &&
                                            <DeviationLevelTwo />}
                                          {(formItem.title === "Site_OCCUPANCY_DTCP" || formItem.title === "SITE_OCCUPANCY_HMDA") && formItem.status !== "IN_PROCESS" &&
                                            <DeviationLevelOne />}
                                          {(formItem.title === "Technical_OCCUPANCY_DTCP" || formItem.title === "TECH_OCCUPANCY_HMDA") && formItem.status !== "IN_PROCESS" &&
                                            <DeviationLevelTwo />}

                                          <div className="my-2">
                                            <h6 style={{ fontSize: "13px" }}>Final Remarks :</h6>
                                            <p style={{ fontSize: "11px" }}>
                                              {formItem?.answer?.data?.finalRemark}
                                            </p>
                                          </div>


                                        </div>

                                      </Accordion.Collapse>
                                    </Card>
                                  </Accordion>


                                </>




                              </Card>
                            </Accordion.Collapse>
                            {props.showShortFall && (
                              <div key={indexForm + ':' + id}>
                                <ShortFallToggle
                                  index={indexForm + ':' + id}
                                  onShortFall={() => {
                                    setShotFall({
                                      ...shortFall,
                                      [indexForm + ':' + id]: !shortFall[
                                        indexForm + ':' + id
                                      ],
                                    });
                                  }}
                                />
                                {shortFall && shortFall[indexForm + ':' + id] && (
                                  <AddRemark
                                    setFinalRemark={(e) => {
                                      console.log(e);
                                    }}
                                    formId={formItem.id}
                                  />
                                )}
                                {/* add the proceed to button which will submit the shortfall */}
                              </div>
                            )}
                          </Card>
                        </div>
                      );
                    }
                  });
                }
              )}
              <div className="mb-3 p-3">
                {answersData.officersApplication.finalRemark && (
                  <p>
                    {permissionLevel == "DEPUTY_COMMISSIONER" ? "DEPUTY COMMISSIONER REMARKS" : (permissionLevel == "FINAL APPROVING OFFICER" || permissionLevel == "PANCHAYAT SECRETARY") ? "FINAL APPROVING OFFICER REMARKS" : permissionLevel == "COMMISSIONER" ?"MUNCIPAL COMMISSIONER REMARKS" : "FINAL REMARKS"}  :{' '}
                    {getTranslatedToList(
                      answersData.officersApplication.finalRemark
                    )}
                  </p>
                )}
                {/* Showing Emtpy remarks error msg when there are not remkars by any officer */}
                {isRemarksEmpty &&
                  !answersData?.officersApplication?.finalRemark &&
                  !error && (
                    <div style={{ fontSize: 'smaller' }}>
                      There are no review remarks for this application at the
                      moment.
                    </div>
                  )}
                {error && (
                  <div style={{ fontSize: 'smaller' }}>{error.message}</div>
                )}
              </div>
            </Card>
          )}
      </Accordion>
      </div>
      <div  className='AfterShowcauseRemarks'>
      {props.status == "SINGLE WINDOW"  ? <h1> After shortFall</h1>  :  <h1> After Show cause</h1>  }
        <Accordion defaultActiveKey="-1" onSelect={(e) => chevronFlip(e)}>
 
 {getDataToDisplay(answersData) &&
   getDataToDisplay(answersData).reviewRemarks && (
     <>
       {getDataToDisplay(answersData)?.reviewRemarks.filter(item => item?.stageState?.completedDate !== null && item.beforeShowcase == false).map(
         (formItem, indexForm) => {
           console.log(formItem, 'formItem')
           const newDAte = formItem.stageState.completedDate
           return formItem.stageState?.stageOfficers.map((item, id) => {
             if (
               (props.isVerification && item.officerLevel !== 'L2') ||
               !props.isVerification ||
               [
                 PERMISSIONS_LEVELS.COMMISSIONER,
                 PERMISSIONS_LEVELS.ZONAL_COMMISSIONER,
                 PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER,
               ].includes(permissionLevel)
             ) {
               isRemarksEmpty = false;
               return permissionLevel ===
                 PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION &&
                 !(
                   (id === 0 &&
                     formItem.stageState.name ===
                     'TECHNICAL VERIFICATION') ||
                   formItem.stageState.name !== 'TECHNICAL VERIFICATION'
                 ) ? null : (
                 <div
                 className='Child-card_wrapper'
                   style={{
                     //boxShadow: '0px 1px 8px 1px lightgrey',
                     padding: '10px',
                     margin: '15px',
                     cursor:"pointer"
                   }}
                 >
                   <Card
                     className="answer-card"
                     key={indexForm + ':' + id}
                     style={{
                       height: 'auto',
                       margin: '10px',
                     }}
                   >
                     <Accordion.Toggle
                       as={Card.Header}
                       eventKey={indexForm + ':' + id}
                     >
                       <div
                         style={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           width: '100%',
                         }}
                       >
                         <div style={{ width: "300px" }}>
                           <b >{formItem.title?.replace('form', 'Incpection Report')}</b>
                           <br />
                           <div>
                             <span style={{ fontWeight: "bold" }}>   Status:</span>

                             &nbsp; <span style={{ color: getcolorStatus(formItem.status) }}>
                               {formItem.status
                                 ? ' ' + formItem.status?.replace(/_/g, ' ')
                                 : ' Not started'}
                             </span>
                           </div>
                           {/* <br/> */}
                           <div>
                             <span style={{ fontWeight: "bold" }}>
                               Received Date:
                             </span>

                             &nbsp; {formItem?.stageState?.prevStartDate != null && formItem?.stageState?.receivedDate == null ? <span style={{ fontSize: "13px", fontWeight: "bold" }}>
                               {/\d{2}:\d{2}:\d{2}/.test(formItem?.stageState?.prevStartDate) == true ?
                                 new Date(formItem?.stageState?.prevStartDate)?.toLocaleString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })?.replace(/(am|pm)/i, '')
                                 : moment(formItem?.stageState?.prevStartDate)?.format(
                                   'DD/MM/YYYY'
                                 )}
                             </span>
                               : <span>{moment(formItem?.stageState?.receivedDate)?.format(
                                 'DD/MM/YYYY'
                               )}</span>}
                           </div>

                           {formItem?.stageState?.prevStartDate != null ? <div>
                             <span style={{ fontWeight: "bold" }}>
                               Received Date After show cause:
                             </span>
                             &nbsp;  <span>{moment(formItem?.stageState?.prevStartDate)?.format(
                               'DD/MM/YYYY'
                             )}</span>


                           </div> : ""}


                           <br />
                           {item.officerLevel && 'Officer Level:'}
                         </div>
                         <div>
                           <div style={{ width: "400px" }}>
                             <span style={{ fontWeight: "bold" }}>
                               {item.officerName} &nbsp;{`(${item.officerRole?.replace(/_/g, ' ')})`}
                             </span>
                             <br />
                             <span style={{ fontWeight: "bold" }}>
                               Forwarded Date:
                             </span>


                             &nbsp;  <span style={{ fontSize: "13px", fontWeight: "bold" }}>

                               {formItem?.stageState?.completedDate !== null ?
                                 <>
                                     {parseAndFormatDate(formItem?.stageState?.completedDate)}
                                 </> 
                                 : <>{"Not Yet Recevied"}</>}

                             </span>
                             <br />
                             {item.officerLevel}
                             {/* <br /> */}
                             <span style={{ fontWeight: "bold" }}>  Final Remark: </span>

                             <span style={{ fontWeight: "bold" }}>
                               &nbsp;  {formItem?.stageState?.finalRemark?.replace(/[\[\]"]+/g, '')}

                             </span>
                           </div>

                           <br />
                         </div>
                         <img
                           src={require('../../assets/images/dd-arrow.svg')}
                           className={
                             open ===  indexForm  ? 'arrowUP' : null
                           }
                         />
                       </div>
                     </Accordion.Toggle>
                     <Accordion.Collapse eventKey={indexForm + ':' + id}>

                       <Card>


                         <>

                           {formItem?.oldAnswer != null ?
                             <Accordion style={{
                               boxShadow: '0px 1px 8px 1px lightgrey',
                               padding: '10px',
                               margin: '15px',
                             }} >
                               <Card className="answer-card"
                                 key={indexForm + ':' + id}
                                 style={{
                                   height: 'auto',
                                   margin: '10px',
                                 }}>

                                 <Accordion.Toggle as={Card.Header}
                                   eventKey={indexForm + ':' + id} >
                                   <span style={{ fontSize: "14px", color: "red" }}>  {"Before Showcause remarks "} </span>
                                   <img
                                     src={require('../../assets/images/dd-arrow.svg')}
                                     className={
                                       open ===  indexForm  ? 'arrowUP' : null
                                     }
                                   />
                                 </Accordion.Toggle>
                                 <Accordion.Collapse eventKey={indexForm + ':' + id}>
                                   <Card.Body>
                                     <div className="answer-list">

                                       {/* SPREADING AND MAKING A NEW COPY OF ARRAY BECAUSE SORTING NOT ALLOWED (ARRAY IS FROZEN IN STRICT MODE) */}
                                       {[...formItem.fields]
                                         .sort(sortCompare)
                                         .map((fieldItem, indexField) => {
                                           if (
                                             formItem.stage === 'FEE CERTIFICATION'
                                           )
                                             return (
                                               <div key={indexField}>
                                                 <h6 style={{ fontSize: "13px" }}>Final Remark : </h6>
                                                 <p style={{ fontSize: "13px" }}  >
                                                   {
                                                     formItem.oldAnswer.data
                                                       .finalremark
                                                   }
                                                 </p>
                                               </div>
                                             );
                                           else
                                             return (
                                               <>
                                                 <Answer
                                                   field={fieldItem}
                                                   answer={
                                                     formItem?.oldAnswer?.data || null
                                                   }
                                                   // oldAnswer={formItem?.oldAnswer?.data || null}
                                                   key={indexField}
                                                 />
                                                 {/* <OldAnswer
                                     
                                     field={fieldItem}
                                       answer={
                                         formItem?.oldAnswer?.data || null
                                       }
                                       // oldAnswer={formItem?.oldAnswer?.data || null}
                                       key={indexField}
                                     /> */}
                                               </>
                                             );
                                         })}

                                       {item.officerLevel === 'L1' && formItem.title === "Site_OCCUPANCY_GHMC" && formItem.status !== "IN_PROCESS" &&
                                         <DeviationLevelOne />}
                                       {item.officerLevel === 'L2' && formItem.title === "Site_OCCUPANCY_GHMC" && formItem.status !== "IN_PROCESS" &&
                                         <DeviationLevelTwo />}
                                       {(formItem.title === "Site_OCCUPANCY_DTCP" || formItem.title === "SITE_OCCUPANCY_HMDA") && formItem.status !== "IN_PROCESS" &&
                                         <DeviationLevelOne />}
                                       {(formItem.title === "Technical_OCCUPANCY_DTCP" || formItem.title === "TECH_OCCUPANCY_HMDA") && formItem.status !== "IN_PROCESS" &&
                                         <DeviationLevelTwo />}

                                       <div className="my-2">
                                         <h6 style={{ fontSize: "13px" }}>Final Remarks :</h6>
                                         <p style={{ fontSize: "11px" }}>
                                           {formItem?.oldAnswer?.data?.finalRemark}
                                         </p>
                                       </div>

                                     </div>

                                   </Card.Body>
                                 </Accordion.Collapse>

                               </Card>
                             </Accordion>
                             : null}
                           <Accordion style={{
                             boxShadow: '0px 1px 8px 1px lightgrey',
                             padding: '10px',
                             margin: '15px',
                           }} >
                             <Card  style={{
                               height: 'auto',
                               margin: '10px',
                             }} >

                               <Accordion.Toggle as={Card.Header}
                                 eventKey={indexForm + ':' + id} >
                                 <span style={{ fontSize: "14px", color: "green" }} >  {" Remarks "} </span>
                                 <img
                                   src={require('../../assets/images/dd-arrow.svg')}
                                   className={
                                     open ===  indexForm  ? 'arrowUP' : null
                                   }
                                 />
                               </Accordion.Toggle>
                               {/* after showcause remarks */}
                               <Accordion.Collapse eventKey={indexForm + ':' + id}>

                                 <div className="answer-list">
                                   {/* SPREADING AND MAKING A NEW COPY OF ARRAY BECAUSE SORTING NOT ALLOWED (ARRAY IS FROZEN IN STRICT MODE) */}
                                   {[...formItem.fields]
                                     .sort(sortCompare)
                                     .map((fieldItem, indexField) => {
                                       if (
                                         formItem.stage === 'FEE CERTIFICATION'
                                       )
                                         return (
                                           <div key={indexField}>
                                             <h6 style={{ fontSize: "13px" }}>Final Remark : </h6>
                                             <p style={{ fontSize: "13px" }}  >
                                               {
                                                 formItem.answer.data
                                                   .finalremark
                                               }
                                             </p>
                                           </div>
                                         );
                                       else
                                         return (
                                           <>
                                             <Answer
                                               field={fieldItem}
                                               answer={
                                                 formItem?.answer?.data || null
                                               }
                                               // oldAnswer={formItem?.oldAnswer?.data || null}
                                               key={indexField}
                                             />
                                             {/* <OldAnswer
                                     
                                     field={fieldItem}
                                       answer={
                                         formItem?.oldAnswer?.data || null
                                       }
                                       // oldAnswer={formItem?.oldAnswer?.data || null}
                                       key={indexField}
                                     /> */}
                                           </>
                                         );
                                     })}

                                   {item.officerLevel === 'L1' && formItem.title === "Site_OCCUPANCY_GHMC" && formItem.status !== "IN_PROCESS" &&
                                     <DeviationLevelOne />}
                                   {item.officerLevel === 'L2' && formItem.title === "Site_OCCUPANCY_GHMC" && formItem.status !== "IN_PROCESS" &&
                                     <DeviationLevelTwo />}
                                   {(formItem.title === "Site_OCCUPANCY_DTCP" || formItem.title === "SITE_OCCUPANCY_HMDA") && formItem.status !== "IN_PROCESS" &&
                                     <DeviationLevelOne />}
                                   {(formItem.title === "Technical_OCCUPANCY_DTCP" || formItem.title === "TECH_OCCUPANCY_HMDA") && formItem.status !== "IN_PROCESS" &&
                                     <DeviationLevelTwo />}

                                   <div className="my-2">
                                     <h6 style={{ fontSize: "13px" }}>Final Remarks :</h6>
                                     <p style={{ fontSize: "11px" }}>
                                       {formItem?.answer?.data?.finalRemark}
                                     </p>
                                   </div>


                                 </div>

                               </Accordion.Collapse>
                             </Card>
                           </Accordion>


                         </>




                       </Card>
                     </Accordion.Collapse>
                     {props.showShortFall && (
                       <div key={indexForm + ':' + id}>
                         <ShortFallToggle
                           index={indexForm + ':' + id}
                           onShortFall={() => {
                             setShotFall({
                               ...shortFall,
                               [indexForm + ':' + id]: !shortFall[
                                 indexForm + ':' + id
                               ],
                             });
                           }}
                         />
                         {shortFall && shortFall[indexForm + ':' + id] && (
                           <AddRemark
                             setFinalRemark={(e) => {
                               console.log(e);
                             }}
                             formId={formItem.id}
                           />
                         )}
                         {/* add the proceed to button which will submit the shortfall */}
                       </div>
                     )}
                   </Card>
                 </div>
               );
             }
           });
         }
       )}
       <div className="mb-3 p-3">
         {answersData.officersApplication.finalRemark && (
           <p>
             {permissionLevel == "DEPUTY_COMMISSIONER" ? "DEPUTY COMMISSIONER REMARKS" : (permissionLevel == "FINAL APPROVING OFFICER" || permissionLevel == "PANCHAYAT SECRETARY") ? "FINAL APPROVING OFFICER REMARKS" : permissionLevel == "COMMISSIONER" ?"MUNCIPAL COMMISSIONER REMARKS" : "FINAL REMARKS"}  :{' '}
             {getTranslatedToList(
               answersData.officersApplication.finalRemark
             )}
           </p>
         )}
         {/* Showing Emtpy remarks error msg when there are not remkars by any officer */}
         {isRemarksEmpty &&
           !answersData?.officersApplication?.finalRemark &&
           !error && (
             <div style={{ fontSize: 'smaller' }}>
               There are no review remarks for this application at the
               moment.
             </div>
           )}
         {error && (
           <div style={{ fontSize: 'smaller' }}>{error.message}</div>
         )}
       </div>
     </>
   )}
</Accordion>
      </div>
   
      </div>
    );
  } catch (e) {
    console.log(e);
    return (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          color: 'red',
          alignItems: 'center',
        }}
      >
        Something went wrong!
      </div>
    );
  }
});

IAIRAnswerList.propTypes = {
  id: PropTypes.string,
  showShortFall: PropTypes.bool,
  currentVerification: PropTypes.string,
  isVerification: PropTypes.string,
  setIsDisabledNotes: PropTypes.func,
  fromSummary: PropTypes.bool,
};

IAIRAnswerList.defaultProps = {
  showShortFall: false,
  currentVerification: null,
  isVerification: false,
  fromSummary: false,
  setIsDisabledNotes: () => { },
};

IAIRAnswerList.displayName = 'IAIRAnswerList';

export default IAIRAnswerList;
