/* eslint-disable no-constant-condition */
import React, { memo, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { getQuestionsWithLink } from '../../util/helperFunctions';
import { showFilePreview } from '../../util/filePreview';

const OldAnswer = memo(({ field, answer ,index}) => {
  const [hidding, setHidding] = useState(false)
  useEffect(() => {
    if(answer != null){
    
   if (answer[field.dbField] != undefined ){
    setHidding(true)
    
   }
  }  }, [answer])
  
  return (
    <div>
   {hidding == true ? 
    <div className='answersListWrapper'>
         <p>{getQuestionsWithLink(field.title)}</p>
     <span>
        {['FILE'].includes(field.fieldType)
          ? (answer != null &&
              answer[field.dbField] != null &&
              showFilePreview(
              answer[field.dbField].parent_answer
            )) ||
          'not answered'
          : ['TEXT_SHORT', 'DROP_DOWN', 'DROP_DOWN_MULTI_SELECT'].includes(field.fieldType, field.dbField)
            ? answer != null &&
            answer[field.dbField] != null &&
            answer[field.dbField] !=undefined &&
            answer[field.dbField].parent_answer 
            : answer != null &&
              answer[field.dbField] != null &&
              answer[field.dbField].parent_answer
              ? [true, 't', 'true'].includes(answer[field.dbField].parent_answer)
                ? 'YES'
                : 'NO'
              : 'not answered'}
      </span>
      <div  className={'offset-1'}>
        {field?.children?.map((fieldItem, index) => {

         if (
            answer != null &&
            answer[field.dbField] != null &&
            answer[field.dbField].parent_answer !== field.defaultValue
            && answer[field.dbField]  != undefined
          ) {
            return (
              <OldAnswer
                field={fieldItem}
                key={index}
            
                answer={
                  answer != null && answer[field.dbField] != null 
                    ? answer[field.dbField]
                    : null
                }
              />
            );
          }
          return '';
        })}
      </div>
    </div> 
    : ""} 
    </div>
    
  );
});

OldAnswer.propTypes = {
  field: PropTypes.any,
  answer: PropTypes.any,
};
OldAnswer.displayName = 'Answer';
export default OldAnswer;
