import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import environment from 'environment';
import { useParams } from 'react-router';
import { getAllLocalData } from '../../services/localStorage';
import PropTypes from 'prop-types';
import { CgMaximizeAlt } from "react-icons/cg"
import history from '../../services/history';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import {
  faArrowLeft,
  faArrowRight,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  isShowMortgageSection,
  PERMISSIONS_LEVELS,
} from '../../util/helperFunctions';
import { AppContext } from '../../AppContext';
import ViewMap from '../ApplicationDetailComponent/view-map';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import CryptoJS from "crypto-js"
import Loader from '../LoadingScreen/Loader';
export default function CWRightSideBar(props) {
  console.log(props, 'sideBarHide')
  const [appData, setAppData] = useState({});
  const [applicantData, setApplicantData] = useState({});
  const [plotData, setPlotData] = useState();
  const [loading, setLoading] = useState(false)
  const [buildingData, setBuildingData] = useState({});
  const [mediaData, setMediaData] = useState([]);
  const [showCauseRemarks, setShowCauseRemarks] = useState()
  const [shortfallData, setShortfallData] = useState([]);
  const [vicinityData, setVicinityData] = useState({});
  const [certificates, setCertificates] = useState({});
  const [ShowcauseDoc, setShowcauseDoc] = useState([])
  const [drawingMediaData, setDrawingMediaData] = useState(false);
  const [layoutDetails, setLayoutDetails] = useState(false)
  const [viewMap, setViewMap] = useState(false);
  const { permissionLevel } = useContext(AppContext);
  const [RightSideBar, setRightSidebar] = useState(false)
  const { id } = useParams();
  const [updateApiCalls ,setUpdateApiCalls] = useState("")
  const [mediaLoading, setMediaLoading] = useState(false)
  const [IsMapLoading , setIsMapLoading] = useState(false)
  const drawingDocsCategories = [
    'Proposed_Building_Plan(.PDF)_*',
    'Proposed_Building_Plan_(.dwg_drawing_to_be_in_Pre-DCR_format)_*',
    'Layout_Drawing_file_(.dwg_drawing_to_be_in_Pre-DCR_format)_*',
    'Layout_Drawing(PDF)_*',
    'scrutiny_pdf',
    'drawing_pdf'
  ]


  // console.log(showCauseRemarks ,"showCAuse")

  const handleCloseViewMap = () => setViewMap(false);
  const handleShowViewMap = () => setViewMap(true);

  const handlecloseRightSideBar = () => {
    document.getElementsByTagName('body')[0].classList.add('Hide-RightSidebar');
    // document.getElementsByTagName('body')[0].classList.add('show-sidebar');
  }

  const getFrontValue = () => {
    return 'Road Width: ' + plotData.front_road_width + ' Mtrs';
  };
  const getRearValue = () => {
    if (plotData.rear_open_land === true) {
      return 'Open Land';
    } else if (plotData.rear_plot_width) {
      return 'Plot Details: ' + plotData.rear_plot_width;
    } else if (plotData.rear_road_width) {
      return 'Road Width: ' + plotData.rear_road_width + ' Mtrs';
    } else if (plotData.rear_others) {
      return 'Others: ' + plotData.rear_others;
    } else if (plotData.rear_existing_building) {
      return 'Existing Building: ' + plotData.rear_existing_building;
    }
  };
  const getSide1Value = () => {
    if (plotData.side1_open_land === true) {
      return 'Open Land';
    } else if (plotData.side1_plot_width) {
      return 'Plot Details: ' + plotData.side1_plot_width;
    } else if (plotData.side1_road_width) {
      return 'Road Width: ' + plotData.side1_road_width + ' Mtrs';
    } else if (plotData.side1_others) {
      return 'Others: ' + plotData.side1_others;
    } else if (plotData.side1_existing_building) {
      return 'Existing Building: ' + plotData.side1_existing_building;
    }
  };
  const getSide2Value = () => {
    if (plotData.side2_open_land === true) {
      return 'Open Land';
    } else if (plotData.side2_plot_width) {
      return 'Plot Details: ' + plotData.side2_plot_width;
    } else if (plotData.side2_road_width) {
      return 'Road Width: ' + plotData.side2_road_width + ' Mtrs';
    } else if (plotData.side2_others) {
      return 'Others: ' + plotData.side2_others;
    } else if (plotData.side2_existing_building) {
      return 'Existing Building: ' + plotData.side2_existing_building;
    }
  };

  const handleShowCitizenUploadedMedia = () => {


    const newColapse = document.getElementsByClassName('show')

    const authToken = sessionStorage.getItem('accessToken');

    if (newColapse.length == 0 && mediaData.length == 0) {

      setMediaLoading(true)
      axios.get(`${environment.baseApi}/applications/${id}/citizen_uploaded_documents`, {
        headers: {
          Authorization: 'Bearer ' + authToken,
          Accept: '*/*',
        },
      }).then((response) => {

        const docs = response.data.data.media.data.filter(item => !drawingDocsCategories.includes(item.category))
        setMediaData(docs)
        const drawingDocs = response.data.data.media.data.filter(item => drawingDocsCategories.includes(item.category))
        setDrawingMediaData(drawingDocs)
        setMediaLoading(false)
        console.log(docs, 'docs');
      }).catch((ErrorScreen) => {
        console.log(ErrorScreen, 'ErrorScreen')
        setMediaLoading(false)
      })
    }
  }

  useEffect(() => {
    const authToken = getAllLocalData().accessToken;
    if (authToken) {
      axios
        .get(`${environment.baseApi}/applications/${id}/compound_wall_show`, {
          headers: {
            Authorization: 'Bearer ' + authToken,
            Accept: '*/*',
          },
        })
        .then(function (response) {

          if (response.data.success) {
            console.log(response, "Compound_Wall_Response")
            setAppData(response.data.data.app_data)
            setApplicantData(response.data.data.applicant);
            setPlotData(response.data.data.plot);
            setBuildingData(response.data.data.building);
            setLayoutDetails(response.data.data.layout_details);
            setMediaData(response.data.data.media.data);
            setVicinityData(response.data.data.vicinity);
            // setCertificates(response.data.data.certificates);
            setShowcauseDoc(response.data.data.signed_copy_medias)
            setShowCauseRemarks(response.data.data.show_cause_replied)
            props.setStuaus(response.data.data.application_status)

            const docs = response.data.data.media.data.filter(item => !drawingDocsCategories.includes(item.category))
            // setMediaData(docs)

            const drawingDocs = response.data.data.media.data.filter(item => drawingDocsCategories.includes(item.category))
            // setDrawingMediaData(drawingDocs)
            console.log(response, "response docs")
            // setEngineerData(response.data.data.engineers.data);
            // setSelfDeclaration(response.data.data.self_declaration);
            // stringToGeoCoords(response.data.data.plot.geo_coordinates);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (mediaData) {
        const shortFall = []
        const others = []
        await mediaData.map((media) => {
          if (media.category === 'shortfall_document') {
            shortFall.push(media)
          } else {
            others.push(media)
          }
        })
        setShortfallData(shortFall)
      }
    }
    fetchData();
  }, [mediaData])


  const handleShowCertificates = () => {
    const newColapse = document.getElementsByClassName('show')

    const authToken = sessionStorage.getItem('accessToken');


    if (newColapse.length == 0 && Object.keys(certificates).length === 0) {
      console.log(newColapse, 'newColapse');
      setLoading(true)
      axios.get(`${environment.baseApi}/applications/${id}/certificates`, {
        headers: {
          Authorization: 'Bearer ' + authToken,
          Accept: '*/*',
        },
      }).then((response) => {

        setCertificates(response.data.data.certificates);
        setLoading(false)
        console.log(certificates, 'ErrorScreen')

      }).catch((ErrorScreen) => {
        console.log(ErrorScreen, 'ErrorScreen')
        setLoading(false)
      })
    }
  }


  const decryptedText = (cipherText) => {
    const key = CryptoJS.enc.Base64.parse('6OZbK0FbuzUCG+XuXWuKTFSm3VIMKg0IWNnU/FKIraI=');
    const iv = CryptoJS.enc.Base64.parse('jKdOxAbYigXA1ls0dqqikQ==');
    const bytes = CryptoJS.AES.decrypt(cipherText, key, { iv: iv });
    const plainText = bytes.toString(CryptoJS.enc.Utf8);
    return plainText;
  }

  const stringReplaceUnderscore = (string) => {
    const arr = string.split('_');

    var result = [];

    for (const word of arr) {
      result.push(word.charAt(0).toUpperCase() + word.slice(1));
    }

    result = result.join(' ');
    if (result.slice(-4) == "Area") {
      result = result.concat(' (Sqmts)');
    }
    return result;
  }


  const RenderURLToImg = (item) => {
    let fileType = 'pdf';
    if (item?.includes('.pdf')) {
      fileType = 'pdf';
    } else if (
      item?.includes('.jpg') ||
      item?.includes('.jpeg') ||
      item?.includes('.png') ||
      item?.includes('.svg')
    ) {
      fileType = 'img';
    }
    if (fileType === 'pdf') {
      return (
        <>
          {/* {item.category && item.category !== 'shortfall_document' && ( */}
          <li>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              className="plot-img"
            >
              <img
                alt=""
                onClick={() =>
                  window.open(item, '_blank')
                }
                src={require('../../assets/images/pdf.svg')}
              />
            </div>

            {/* <a href={item}>
                {item.name != null ? <>     {"Revised"} {item.category && item.category.replace(/_/g, ' ')} </> : <>{item.category && item.category.replace(/_/g, ' ')}</>}
              </a> */}

          </li>
          {/* )} */}
        </>
      );
    }
    if (fileType === 'img') {
      return (
        <>
          {/* {item.category && ( */}
          <li >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              className="plot-img"
            >
              <img
                alt=""
                onClick={() =>
                  window.open(item, '_blank')
                }
                src={require('../../assets/images/doc.svg')}
              />
            </div>
            <a
              href={item}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.category && item.category.replace(/_/g, ' ')}
            </a>
          </li>
          {/* )} */}
        </>
      );
    }
    return (
      <>
        {/* {item.category && ( */}
        <li>
          <div className="plot-img">
            <img
              onClick={() =>
                window.open(
                  `https://tsbpass.s3.ap-south-1.amazonaws.com/${item.s3FilePath}`,
                  '_blank'
                )
              }
              src={`https://tsbpass.s3.ap-south-1.amazonaws.com/${item.s3FilePath}`}
              width="100%"
              height="100%"
              alt="plot"
            />
          </div>
          <a href={item}>
            {/* {item.category && item.category.replace(/_/g, ' ')} */}
          </a>
        </li>
        {/* )} */}
      </>
    );
  }

  const uploadedDocuments = (docs) => {
    console.log(docs, "checking the document");
    return (
      <ul className="plot-img-list">
        {docs.filter((item) => item.category != "showcause_document").map((item, index) => {
          // getting download url type from the file extenstion and default is pdf
          let fileType = 'pdf';
          if (item.download_url?.includes('.pdf')) {
            fileType = 'pdf';
          } else if (
            item.download_url?.includes('.jpg') ||
            item.download_url?.includes('.jpeg') ||
            item.download_url?.includes('.png') ||
            item.download_url?.includes('.svg')
          ) {
            fileType = 'img';
          }
          if (fileType === 'pdf') {
            return (
              <>
                {item.category && item.category !== 'shortfall_document' && (
                  <li key={index}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      className="plot-img"
                    >
                      <img
                        alt=""
                        onClick={() =>
                          window.open(item.download_url, '_blank')
                        }
                        src={require('../../assets/images/pdf.svg')}
                      />
                    </div>

                    <a href={item.download_url}>
                      {item.name != null ? <>     {"Revised"} {item.category && item.category.replace(/_/g, ' ')} </> : <>{item.category && item.category.replace(/_/g, ' ')}</>}
                    </a>

                  </li>
                )}
              </>
            );
          }
          if (fileType === 'img') {
            return (
              <>
                {item.category && (
                  <li key={index}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      className="plot-img"
                    >
                      <img
                        alt=""
                        onClick={() =>
                          window.open(item.download_url, '_blank')
                        }
                        src={require('../../assets/images/doc.svg')}
                      />
                    </div>
                    <a
                      href={item.download_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.category && item.category.replace(/_/g, ' ')}
                    </a>
                  </li>
                )}
              </>
            );
          }
          return (
            <>
              {item.category && (
                <li key={index}>
                  <div className="plot-img">
                    <img
                      onClick={() =>
                        window.open(
                          `https://tsbpass.s3.ap-south-1.amazonaws.com/${item.s3FilePath}`,
                          '_blank'
                        )
                      }
                      src={`https://tsbpass.s3.ap-south-1.amazonaws.com/${item.s3FilePath}`}
                      width="100%"
                      height="100%"
                      alt="plot"
                    />
                  </div>
                  <a href={item.s3FilePath}>
                    {item.category && item.category.replace(/_/g, ' ')}
                  </a>
                </li>
              )}
            </>
          );
        })}
      </ul>
    )
  }


  const ShowcauseDocuments = (docs) => {
    console.log(docs, "checking the document");
    return (
      <ul className="plot-img-list">
        {docs.filter((item) => item.category == "showcause_document").map((item, index) => {
          // getting download url type from the file extenstion and default is pdf
          let fileType = 'pdf';
          if (item.download_url?.includes('.pdf')) {
            fileType = 'pdf';
          } else if (
            item.download_url?.includes('.jpg') ||
            item.download_url?.includes('.jpeg') ||
            item.download_url?.includes('.png') ||
            item.download_url?.includes('.svg')
          ) {
            fileType = 'img';
          }
          if (fileType === 'pdf') {
            return (
              <>
                {item.category && item.category !== 'shortfall_document' && (
                  <li key={index}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      className="plot-img"
                    >
                      <img
                        alt=""
                        onClick={() =>
                          window.open(item.download_url, '_blank')
                        }
                        src={require('../../assets/images/pdf.svg')}
                      />
                    </div>

                    <a href={item.download_url}>
                      {item.name != null ? <>     {"Revised"} {item.category && item.category.replace(/_/g, ' ')} </> : <>{item.category && item.category.replace(/_/g, ' ')}</>}
                    </a>

                  </li>
                )}
              </>
            );
          }
          if (fileType === 'img') {
            return (
              <>
                {item.category && (
                  <li key={index}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      className="plot-img"
                    >
                      <img
                        alt=""
                        onClick={() =>
                          window.open(item.download_url, '_blank')
                        }
                        src={require('../../assets/images/doc.svg')}
                      />
                    </div>
                    <a
                      href={item.download_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.category && item.category.replace(/_/g, ' ')}
                    </a>
                  </li>
                )}
              </>
            );
          }
          return (
            <>
              {item.category && (
                <li key={index}>
                  <div className="plot-img">
                    <img
                      onClick={() =>
                        window.open(
                          `https://tsbpass.s3.ap-south-1.amazonaws.com/${item.s3FilePath}`,
                          '_blank'
                        )
                      }
                      src={`https://tsbpass.s3.ap-south-1.amazonaws.com/${item.s3FilePath}`}
                      width="100%"
                      height="100%"
                      alt="plot"
                    />
                  </div>
                  <a href={item.s3FilePath}>
                    {item.category && item.category.replace(/_/g, ' ')}
                  </a>
                </li>
              )}
            </>
          );
        })}
      </ul>
    )
  }

  let coordinates = [];
  let officer_geo_coordinates ;
  if(plotData?.latest_geo_coordinates?.officer_geo_coordinates){
     officer_geo_coordinates = plotData?.latest_geo_coordinates?.officer_geo_coordinates
  }
  if (plotData?.geo_coordinates) {
    let coordsArr = plotData.geo_coordinates.split(',');
    let lats = coordsArr.filter((e, i) => i % 2 === 0);
    let longs = coordsArr.filter((e, i) => i % 2 === 1);
    for (var i = 0; i < coordsArr.length / 2; i++) {
      coordinates.push({ lat: lats[i], lng: longs[i] });
    }
  }
  try {
    return (
      <div className='Rightsidebar-container'>
        <div>

          <div
            className={
              props.showSidebar
                ? 'ad-container'
                : 'ad-container ad-container-collapsed'
            }
          >

            <div
              className="toggleBtn"
              onClick={() => {
                props.showSidebarHandler(!props.showSidebar);
                console.log(!props.showSidebar);
              }}
            >
              {props.showSidebar ? (
                <FontAwesomeIcon icon={faArrowRight} style={{ color: 'black' }} />
              ) : (
                <FontAwesomeIcon icon={faArrowLeft} style={{ color: 'black' }} />
              )}
            </div>
            {applicantData && (
              <>
                <div
                  className="d-flex expandBtn sidebar-list"
                  style={{ marginLeft: -50, cursor: 'pointer' }}

                >

                  <p id='ExpandButton'
                    onClick={() =>
                    // props.showSidebarHandler(false)
                    {
                      //  debugger
                      if (props.ApprovalFor == "COMPOUNDWALL") {
                        history.push(`/compoundWall-application-details/CW/${id}`, {
                          from: 'verification-pages',
                        })
                      } else if (props.ApprovalFor == "OCCUPANCY") {
                        history.push(`/application-details/occ/${id}`, {
                          from: 'verification-pages',
                        })
                      } else {
                        history.push(`/application-details/${id}`, {
                          from: 'verification-pages',
                        })

                      }

                    }
                    } style={{ fontSize: '12px', color: '#28a745', paddingLeft: 30, fontWeight: "bold" }}>
                    Expand <span><CgMaximizeAlt /></span>
                  </p>

                  <p onClick={handlecloseRightSideBar} style={{ fontSize: 12, position: 'relative', left: "60%" }}>
                    <img width="13px" style={{ color: '#9eabb5' }} src={require('../../assets/images/close.svg')} />
                  </p>

                </div>

              </>
            )}

            <Accordion
              eventKey="0"
              className="my-2 applicationDetails"
              style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
            >
              <Card style={{ height: '80%', paddingLeft: "0px", paddingRight: "0px", margin: '0px', transitionDelay: '0.5s' }}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  style={{ borderBottom: 'none', fontWeight: "500", paddingLeft: "10px", paddingRight: "10px", fontSize: '13px', cursor: 'pointer', }}
                >
                  Application Details
                  <img
                    style={{
                      transform: 'rotate(0deg)',
                    }}
                    src={require('../../assets/images/dd-arrow.svg')}
                    alt=""
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <ul className="ad-list">
                      {/* {applicantData.id && (
              <li>
                <span>Application Id</span>
                <p>{applicantData.id}</p>
              </li>
            )} */}
                      {applicantData?.applicant_name && (
                        <li>
                          <span>Applicant Name</span>
                          <p>
                            {applicantData?.applicant_type}{' '}
                            {applicantData?.applicant_name}
                          </p>
                        </li>
                      )}
                      {applicantData?.relationship_type && (
                        <li>
                          <span className="detail-prop">
                            {applicantData.relationship_type}
                          </span>
                          <p className="detail-value">
                            {applicantData?.relationship_name}
                          </p>
                        </li>
                      )}
                      {/* {props.data.createdAt && (
              <li>
                <span>Received Date</span>
                <p>{formatDateToString(props.data.createdAt)}</p>
              </li>
            )}
            {applicantData.applicant_type && (
              <li>
                <span>Application Type</span>
                <p>{applicantData.applicant_type}</p>
              </li>
            )} */}
                      {applicantData?.aadhaar_number && (
                        <li>
                          <span>Aadhaar Number</span>
                          <p>{decryptedText(applicantData?.aadhaar_number)}</p>
                        </li>
                      )}
                      {[
                    PERMISSIONS_LEVELS.SITE_VERIFICATION,
                    PERMISSIONS_LEVELS.TPSO_OFFICER,
                    PERMISSIONS_LEVELS.ACP_OFFICER,
                    PERMISSIONS_LEVELS.SECTION_HEAD,
                    PERMISSIONS_LEVELS.SECTION_OFFICER,
                    PERMISSIONS_LEVELS.UDA_GP_SITE_OFFICER,
                    PERMISSIONS_LEVELS.CP_TECH_OFFICER,
                    PERMISSIONS_LEVELS.TECH_OFFICER,
                    PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION
                      ].includes(permissionLevel) &&
                        applicantData?.mobile && (
                          <li>
                            <span>Mobile Number</span>
                            <p>{decryptedText(applicantData.mobile)}</p>
                          </li>
                        )}

                      {/* {applicantData.email && (
              <li>
              <span>E-mail ID</span>
              <p>{applicantData.email}</p>
            </li>
            )} */}

                      {applicantData?.contact_address && (
                        <li>
                          <span>Contact Address</span>
                          <p> {applicantData?.contact_address}</p>
                        </li>
                      )}
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            {plotData && (<Accordion
              eventKey="0"
              className="my-2 applicationDetails"
              style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
            >
              <Card style={{ height: '100%', paddingLeft: "0px", paddingRight: "0px", margin: '0px' }}>
                {plotData && (
                  //  <Card style={{ height: '100%' }}></Card>

                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    style={{ borderBottom: 'none', fontWeight: "500", paddingLeft: "10px", paddingRight: "10px", fontSize: '13px', cursor: 'pointer' }}
                  >
                    Plot Details
                    <img
                      style={{
                        transform: 'rotate(0deg)',
                      }}
                      src={require('../../assets/images/dd-arrow.svg')}
                      alt=""
                    />
                  </Accordion.Toggle>

                )}
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <ul className="ad-list">
                      {plotData && (
                        <li>
                          <span>Plot Address</span>
                          <p>{plotData.plot_address ? plotData.plot_address : '---'}</p>
                        </li>
                      )}
                      {plotData && (
                        <li>
                          <span>Geo-Coordinates</span>
                          <p>
                            <button
                              onClick={handleShowViewMap}
                              style={{ color: 'blue', textDecoration: 'underline' }}
                            >
                              View Map
                            </button>
                            <ViewMap
                            show={viewMap}
                            onHide={handleCloseViewMap}
                            coordinates={coordinates}
                            propertyID = {plotData.property_id}
                            id = {id}
                            permissionLevel={permissionLevel}
                            IsMapLoading={IsMapLoading}
                            setUpdateApiCalls={setUpdateApiCalls}
                            officer_geo_coordinates={officer_geo_coordinates}
                            />
                          </p>
                        </li>
                      )}
                    </ul>
                    {plotData &&
                      (plotData.plot_area_as_per_document_display ||
                        plotData.plot_area_as_per_ground_display) && (
                        <div className="">
                          <span>Plot registration details</span>
                        </div>
                      )}
                    <ul className="ad-list">
                      {plotData && plotData.plot_land_value && (
                        <li>
                          <span>{appData.plot_land_type}</span>
                          <p>{plotData.plot_land_value ? plotData.plot_land_value : 'N/A'}</p>
                        </li>
                      )}
                      {plotData?.approval_for !== "LAYOUT" &&
                        <>
                          {plotData && plotData.plot_area_as_per_document_display && (
                            <li>
                              <span>Plot Area As per document</span>
                              <p>{plotData.plot_area_as_per_document_display}</p>
                            </li>
                          )}
                          {plotData && plotData.plot_area_as_per_ground_display && (
                            <li>
                              <span>Plot Area As per Ground</span>
                              <p>{plotData.plot_area_as_per_ground_display}</p>
                            </li>
                          )}
                        </>}
                    </ul>

                    {/* <div className="">
          <span>Documents &amp; TAX details</span>
        </div> */}
                    <ul className="ad-list">
                      {/* {plotData && plotData.market_value && (
            <li>
              <span>Market Value</span>
              <p>{plotData.market_value}</p>
            </li>
          )} */}
                      {plotData && plotData.sro && (
                        <li>
                          <span>SRO at</span>
                          <p>{plotData.sro}</p>
                        </li>
                      )}
                      {plotData &&
                        plotData.documents &&
                        plotData.documents.map((item, index) => {
                          // no
                          return (
                            <li key={index}>
                              <span>{item.question}</span>
                              <p>
                                {item.answer === 'TRUE'
                                  ? 'Yes'
                                  : item.answer === 'FALSE'
                                    ? 'No'
                                    : item.answer}
                              </p>
                            </li>
                          );
                        })}
                    </ul>


                    <div className="">
                      <span>Schedule of Boundaries</span>
                    </div>
                    {plotData && plotData.application_type === 'SC' && (
                      <div>
                        <ul className="ad-list border-0">
                          <li>
                            <span className="detail-prop">
                              Front ({plotData.front_facing})
                            </span>
                            <p className="detail-value">{getFrontValue()}</p>
                          </li>
                          <li>
                            <span className="detail-prop">
                              Rear ({plotData.rear_facing}){' '}
                            </span>
                            <p className="detail-value">{getRearValue()}</p>
                          </li>
                          <li>
                            <span className="detail-prop">
                              Side 1 ({plotData.side1_facing}){' '}
                            </span>
                            <p className="detail-value">{getSide1Value()}</p>
                          </li>
                          <li>
                            <span className="detail-prop">
                              Side 2 ({plotData.side2_facing}){' '}
                            </span>
                            <p className="detail-value">{getSide2Value()}</p>
                          </li>
                        </ul>
                      </div>
                    )}


                    <div className="">
                      <h5>Plot Checklist</h5>
                    </div>
                    {plotData && (
                      <ul className="ad-list">
                        {plotData.plot_answers.map((e, id) => {
                          return (
                            <li key={id}>
                              <span>{e.question}</span>
                              <p>
                                {e.answer === 'FALSE'
                                  ? 'NO'
                                  : e.answer === 'TRUE'
                                    ? 'YES'
                                    : e.answer}
                              </p>
                            </li>
                          );
                        })}
                        {plotData.is_plot_part_of.map((e, i) => {
                          if (i !== 0)
                            return (
                              <li key={i}>
                                <span>{e.question}</span>
                                <p>
                                  {e.answer === 'FALSE'
                                    ? 'NO'
                                    : e.answer === 'TRUE'
                                      ? 'YES'
                                      : e.answer.includes("https") ? RenderURLToImg(e.answer) : e.answer}
                                </p>
                              </li>
                            );
                          else return <div />;
                        })}
                        <li>
                          <span>
                            {'Whether your plot falls under any of the Slum Areas ?'}
                          </span>
                          <p>{plotData.slum}</p>
                        </li>
                      </ul>
                    )}
                  </Card.Body>

                </Accordion.Collapse>
              </Card>
            </Accordion>)
            }
            {/* {plotData?.approval_for === "COMPOUND WALL" && */}
            <>
              <Accordion
                eventKey="0"
                className="my-2 applicationDetails"
                style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
              >
                <Card style={{ height: '100%', paddingLeft: "0px", paddingRight: "0px", margin: '0px' }}>

                  {buildingData && (
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      style={{
                        borderBottom: 'none',
                        fontWeight: "500",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        fontSize: '13px',
                        cursor: 'pointer'
                      }}
                    >

                      Building Details

                      <img
                        style={{
                          transform: 'rotate(0deg)',
                        }}
                        src={require('../../assets/images/dd-arrow.svg')}
                        alt=""
                      />

                    </Accordion.Toggle>
                  )}
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ul className="ad-list">
                        {buildingData?.building_info &&
                          buildingData.building_info.purpose_of_building && (
                            <li>
                              <span>Usage of Building</span>
                              <p>{buildingData.building_info.purpose_of_building}</p>
                            </li>
                          )}
                        {buildingData?.building_info &&
                          buildingData?.building_info.is_building_new && (
                            <li>
                              <span className="detail-prop">Permission Type</span>
                              <p className="detail-value">
                                {buildingData?.building_info.is_building_new
                                  ? 'New'
                                  : 'Addition/Alteration'}
                              </p>
                            </li>
                          )}
                        {buildingData?.building_info &&
                          buildingData?.building_info.building_category && (
                            <li>
                              <span>Building Type</span>
                              <p>{buildingData?.building_info.building_category}</p>
                            </li>
                          )}
                        {buildingData?.building_info &&
                          buildingData?.building_info.building_subcategory && (
                            <li>
                              <span>Building Sub Type</span>
                              <p>{buildingData?.building_info.building_subcategory}</p>
                            </li>
                          )}

                        {buildingData && buildingData.net_plot_area_display && (
                          <li>
                            <span>Net Plot Area</span>
                            <p>{buildingData.net_plot_area_display}</p>
                          </li>
                        )}
                        {buildingData?.length_of_compound_wall &&

                          <li>
                            <span>Length Of compoundWall</span>
                            <p>
                              {buildingData?.length_of_compound_wall}
                            </p>
                          </li>
                        }

                        {buildingData?.height_of_compound_wall &&

                          <li>
                            <span>Height Of compoundWall</span>
                            <p>
                              {buildingData?.height_of_compound_wall}
                            </p>
                          </li>
                        }
                        {/* {buildingData.building_info && buildingData.building_info.roof_type && (
            <li>
              <span>Type Of Roof</span>
              <p>{buildingData.building_info.roof_type}</p>
            </li>
          )} */}
                      </ul>
                      {buildingData?.floor_wise_built_up_area &&
                        plotData &&
                        plotData.application_type === 'SC' && (
                          <div className="">
                            <span>Floor wise built-up area</span>
                          </div>
                        )}
                      {plotData &&
                        plotData.application_type === 'SC' &&
                        buildingData.floor_wise_built_up_area &&
                        buildingData.floor_wise_built_up_area.data.length > 0 ? (
                        <>
                          <ul className="ad-list">
                            {buildingData.floor_wise_built_up_area.data.map(
                              (builtup, i) => (
                                <li key={`builtup-area-${i}`}>
                                  <span>{builtup.floor_type}</span>
                                  <p>{builtup.area_display}</p>
                                </li>
                              )
                            )}
                            <li>
                              <span>Total Builtup area</span>
                              <p>
                                {
                                  buildingData.floor_wise_built_up_area
                                    .total_built_up_area_display
                                }
                              </p>
                            </li>
                            {parseFloat(plotData.actual_plot_area) > 200 && (
                              <li>
                                <span>No. Of Units</span>
                                <p>
                                  {buildingData.floor_wise_built_up_area.units_number
                                    ? buildingData.floor_wise_built_up_area.units_number
                                    : '0'}
                                </p>
                              </li>
                            )}
                          </ul>
                        </>
                      ) : (
                        <></>
                        // <p>Builtup Area Not found</p>
                      )}

                      {plotData && plotData.application_type === 'SC' && (
                        <section>
                          {buildingData.abutting_road_front && (
                            <div className="">
                              <span> Abutting Road Details (front)</span>
                            </div>
                          )}
                          <ul className="ad-list">
                            {buildingData.abutting_road_front &&
                              buildingData.abutting_road_front
                                .front_existing_road_width && (
                                <li>
                                  <span>Existing Road Width</span>
                                  <p>
                                    {
                                      buildingData.abutting_road_front
                                        .front_existing_road_width
                                    }{' '}
                                    Meters
                                  </p>
                                </li>
                              )}
                            {buildingData.abutting_road_front &&
                              buildingData.abutting_road_front
                                .front_proposed_road_width && (
                                <li>
                                  <span>Proposed Road Width</span>
                                  <p>
                                    {
                                      buildingData.abutting_road_front
                                        .front_proposed_road_width
                                    }{' '}
                                    Meters
                                  </p>
                                </li>
                              )}
                            {buildingData.abutting_road_front &&
                              buildingData.abutting_road_front.abutting_road_front && (
                                <li>
                                  <span>Depth Of Road Affected</span>
                                  <p>
                                    {
                                      buildingData.abutting_road_front
                                        .front_depth_road_affected
                                    }{' '}
                                    Meters
                                  </p>
                                </li>
                              )}
                            {buildingData.abutting_road_front &&
                              buildingData.abutting_road_front.front_road_affected_area && (
                                <li>
                                  <span>Road Affected Area</span>
                                  <p>
                                    {
                                      buildingData.abutting_road_front
                                        .front_road_affected_area
                                    }{' '}
                                    Sq. Meters
                                  </p>
                                </li>
                              )}
                          </ul>
                        </section>
                      )}
                      {buildingData &&
                        (buildingData.total_road_affected_area ||
                          buildingData.net_plot_area_display) &&
                        plotData &&
                        plotData.application_type === 'SC' && (
                          <section>
                            <div className="">
                              <span> Abutting Road Details (Total)</span>
                            </div>
                            <ul className="ad-list">
                              {buildingData && buildingData.total_road_affected_area && (
                                <li>
                                  <span>Total Road Affected</span>
                                  <p>{buildingData.total_road_affected_area}</p>
                                </li>
                              )}
                              {buildingData && buildingData.net_plot_area_display && (
                                <li>
                                  <span>Net Plot Area</span>
                                  <p>{buildingData.net_plot_area_display}</p>
                                </li>
                              )}
                            </ul>
                          </section>
                        )}
                      {buildingData && buildingData.setback_details && (
                        <div className="">
                          <span> Setback Details</span>
                        </div>
                      )}
                      {buildingData && buildingData.setback_details && (
                        <ul className="ad-list">
                          {buildingData.setback_details.front_setback && (
                            <li>
                              <span> Setback Front As Per Table-111 G.O No 168</span>
                              <p>{buildingData.setback_details.front_setback} Meters</p>
                            </li>
                          )}
                          {buildingData.setback_details.front_setback_manual && (
                            <li>
                              <span>Proposed Setback Front Manual</span>
                              <p>
                                {buildingData.setback_details.front_setback_manual} Meters
                              </p>
                            </li>
                          )}

                          {buildingData.setback_details.other_setback && (
                            <li>
                              <span>
                                Setback on Rear and Other Sides As Per Table-111 G.O No 168
                              </span>
                              <p>{buildingData.setback_details.other_setback} Meters</p>
                            </li>
                          )}
                          {buildingData.setback_details.rear_setback_manual && (
                            <li>
                              <span className="detail-prop">Proposed Setback Rear</span>
                              <p className="detail-value">
                                {buildingData.setback_details.rear_setback_manual} Meters
                              </p>
                            </li>
                          )}
                          {buildingData.setback_details.side1_setback_manual && (
                            <li>
                              <span className="detail-prop">Proposed Setbacks Side 1</span>
                              <p className="detail-value">
                                {buildingData.setback_details.side1_setback_manual} Meters
                              </p>
                            </li>
                          )}
                          {buildingData.setback_details.side2_setback_manual && (
                            <li>
                              <span className="detail-prop">Proposed Setbacks Side 2</span>
                              <p className="detail-value">
                                {buildingData.setback_details.side2_setback_manual} Meters
                              </p>
                            </li>
                          )}
                        </ul>
                      )}
                      {plotData &&
                        buildingData &&
                        buildingData.mortgage &&
                        isShowMortgageSection(props.applicationType, buildingData) &&
                        plotData.application_type === 'SC' && (
                          <section>
                            {buildingData.mortgage && (
                              <div className="">
                                <span> Mortgage</span>
                              </div>
                            )}
                            {buildingData.mortgage && (
                              <ul className="ad-list">
                                {buildingData.mortgage.mortgage_area && (
                                  <li>
                                    <span> Mortgage Area</span>
                                    <p>{buildingData.mortgage.mortgage_area}</p>
                                  </li>
                                )}
                                {buildingData.mortgage.proposed_no_of_floors_name && (
                                  <li>
                                    <span>Proposed Number of Floors</span>
                                    <p>
                                      {buildingData.mortgage.proposed_no_of_floors_name}
                                    </p>
                                  </li>
                                )}
                                {buildingData.mortgage.mortgage_reg_no && (
                                  <li>
                                    <span>Mortgage Registration Number</span>
                                    <p>{buildingData.mortgage.mortgage_reg_no}</p>
                                  </li>
                                )}
                                {buildingData.mortgage.mortgage_date && (
                                  <li>
                                    <span>Mortgage Date</span>
                                    <p>{buildingData.mortgage.mortgage_date}</p>
                                  </li>
                                )}
                              </ul>
                            )}
                          </section>
                        )}
                      <ul className="ad-list">
                        {plotData && plotData.market_value && (
                          <li>
                            <span>Market Value</span>
                            <p> &#8377; {plotData.market_value}</p>
                          </li>
                        )}
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </>
            {/* } */}


            <Accordion
              eventKey="0"
              className="my-2 applicationDetails"
              style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
              onClick={handleShowCitizenUploadedMedia}
            >
              <Card style={{ height: '100%', paddingLeft: "0px", paddingRight: "0px", margin: '0px' }}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  style={{
                    borderBottom: 'none',
                    fontWeight: "500",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    fontSize: '13px',
                    cursor: 'pointer'
                  }}
                >
                  {!mediaLoading ? <>  Documents
                    <img
                      style={{
                        transform: 'rotate(0deg)',
                      }}
                      src={require('../../assets/images/dd-arrow.svg')}
                      alt=""
                    />
                  </>
                    : <Loader />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {uploadedDocuments(mediaData)}

                    {drawingMediaData?.length > 0 &&
                      <>
                        <div className="">
                          <h5> Drawing Files</h5>
                        </div>
                        {uploadedDocuments(drawingMediaData)}
                      </>}
                  </Card.Body>
                </Accordion.Collapse>

              </Card>
            </Accordion>

            {vicinityData &&
              vicinityData.vicinity_answers &&
              !!vicinityData.vicinity_answers.length && (
                <Accordion

                  eventKey="0"
                  className="my-2 applicationDetails"
                  style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
                >
                  <Card style={{ height: '100%', paddingLeft: "0px", paddingRight: "0px", margin: '0px' }}>
                    <section>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        style={{
                          borderBottom: 'none',
                          fontWeight: "500",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          fontSize: '13px',
                          cursor: 'pointer'
                        }}
                      >

                        Application Checklist
                        <img
                          style={{
                            transform: 'rotate(0deg)',
                          }}
                          src={require('../../assets/images/dd-arrow.svg')}
                          alt=""
                        />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          {vicinityData?.vicinity_answers.map((item, id) => {
                            return (

                              <ul className="ad-list" key={id}>
                                <li>
                                  <span className="detail-prop">{item.question}</span>
                                  <p className="detail-value">
                                    {item.answer
                                      ? item.answer === 'TRUE' || item.answer === 't'
                                        ? 'Yes'
                                        : item.answer === 'FALSE' || item.answer === 'f'
                                          ? 'No'
                                          : item.answer
                                      : '---'}
                                  </p>
                                </li>
                              </ul>
                            );
                          })}
                        </Card.Body>

                      </Accordion.Collapse>

                    </section>
                  </Card>
                </Accordion>
              )}




            {shortfallData && shortfallData.length > 0 &&
              <Accordion

                eventKey="0"
                className="my-2 applicationDetails"
                style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
              >
                <Card style={{ height: '100%', paddingLeft: "0px", paddingRight: "0px", margin: '0px' }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    style={{
                      borderBottom: 'none',
                      fontWeight: "500",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      fontSize: '13px',
                      cursor: 'pointer'
                    }}
                  >
                    ShortFall Documents
                    <img
                      style={{
                        transform: 'rotate(0deg)',
                      }}
                      src={require('../../assets/images/dd-arrow.svg')}
                      alt=""
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ul className="plot-img-list">
                        {shortfallData.map((item, index) => {
                          // getting download url type from the file extenstion and default is pdf
                          let fileType = 'pdf';
                          if (item.download_url?.includes('.pdf')) {
                            fileType = 'pdf';
                          } else if (
                            item.download_url?.includes('.jpg') ||
                            item.download_url?.includes('.jpeg') ||
                            item.download_url?.includes('.png') ||
                            item.download_url?.includes('.svg')
                          ) {
                            fileType = 'img';
                          }

                          if (fileType === 'pdf') {
                            return (
                              <>
                                {item.category && (
                                  <li key={index}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                      }}
                                      className="plot-img"
                                    >
                                      <img
                                        alt=""
                                        onClick={() =>
                                          window.open(item.download_url, '_blank')
                                        }
                                        src={require('../../assets/images/pdf.svg')}
                                      />
                                    </div>
                                    <a href={item.download_url}>
                                      {item.category && item.category.replace(/_/g, ' ')}
                                    </a>
                                  </li>
                                )}
                              </>
                            );
                          }
                        })}
                      </ul>
                    </Card.Body>

                  </Accordion.Collapse>
                </Card>
              </Accordion>
            }

            {ShowcauseDoc && ShowcauseDoc.length > 0 &&
              <Accordion
                eventKey="0"
                className="my-2 applicationDetails"
                style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
              >
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    style={{
                      borderBottom: 'none',
                      fontWeight: "500",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      fontSize: '13px',
                      cursor: 'pointer'
                    }}
                  >
                    Signed-Qr Documents
                    <img
                      style={{
                        transform: 'rotate(0deg)',
                      }}
                      src={require('../../assets/images/dd-arrow.svg')}
                      alt=""
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {ShowcauseDoc.map((item) => {
                        return (
                          <Row key={id}>
                            <Col className="detail-prop">
                              {item.category && item.category.replace(/_/g, ' ')}
                            </Col>
                            <Col className="detail-value">
                              {item.download_url && (
                                <a
                                  href={item.download_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                    style={{ color: 'black' }}
                                  />
                                </a>
                              )}
                            </Col>
                          </Row>
                        );
                      })}
                    </Card.Body>
                  </Accordion.Collapse>

                </Card>
              </Accordion>
            }

            {mediaData.find((item) => item.category == "showcause_document") && <Accordion
              eventKey="0"
              className="my-2 applicationDetails"
              style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
            >
              <Card style={{ height: '100%', paddingLeft: "0px", paddingRight: "0px", margin: '0px' }}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  style={{
                    borderBottom: 'none',
                    fontWeight: "500",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    fontSize: '13px',
                    cursor: 'pointer'
                  }}
                >
                  {" Showcause Document"}
                  <img
                    style={{
                      transform: 'rotate(0deg)',
                    }}
                    src={require('../../assets/images/dd-arrow.svg')}
                    alt=""
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>


                    <Row>
                      <Col>
                        {ShowcauseDocuments(mediaData)}
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        Remarks :-      {showCauseRemarks}
                      </Col>
                    </Row>

                  </Card.Body>
                  {/* <Card.Body>
                  hello

                </Card.Body> */}
                </Accordion.Collapse>
              </Card>
            </Accordion>
            }



            {plotData?.approval_for === "LAYOUT" &&
              <>

                <Accordion
                  eventKey="0"
                  className="my-2 applicationDetails"
                  style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
                >
                  <Card style={{ height: '100%', paddingLeft: "0px", paddingRight: "0px", margin: '0px' }}>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      style={{
                        borderBottom: 'none',
                        fontWeight: "500",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        fontSize: '13px',
                        cursor: 'pointer'
                      }}
                    >
                      Layout Details
                      <img
                        style={{
                          transform: 'rotate(0deg)',
                        }}
                        src={require('../../assets/images/dd-arrow.svg')}
                        alt=""
                      />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">

                      <Card.Body>
                        <ul className="ad-list">
                          {layoutDetails && Object.entries(layoutDetails).length > 0 &&
                            Object.entries(layoutDetails).map(([key, value], i) => (
                              <li>
                                <span>{stringReplaceUnderscore(key).split('Temp').join("")}</span>
                                <p>{value || 'N/A'}</p>
                              </li>
                            ))}
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </>
            }
            {applicantData.policy_no &&
              <section>
                {applicantData.policy_no && (
                  <div className="">
                    <span> Insurance Policy</span>
                  </div>
                )}
                <ul className="ad-list">
                  <li>
                    <span> Insurance Policy Number</span>
                    <p>{applicantData.policy_no ? applicantData.policy_no : 'N/A'}</p>
                  </li>
                  <li>
                    <span> Policy Start Date</span>
                    <p>{applicantData.policy_start_date ? applicantData.policy_start_date : 'N/A'}</p>
                  </li>
                  <li>
                    <span>Policy Valid Upto</span>
                    <p>{applicantData.policy_valid_date ? applicantData.policy_valid_date : 'N/A'}</p>
                  </li>
                </ul>
              </section>
            }
            <Accordion
              eventKey="0"
              className="my-2 applicationDetails"
              style={{ borderBottom: '1px solid lightgray', borderRadius: '2px', width: "255px" }}
              onClick={handleShowCertificates}
            >
              <Card style={{ height: '100%', paddingLeft: "0px", paddingRight: "0px", margin: '0px' }}>
                {/* {certificates && Object.keys(certificates).length > 0 && ( */}
                <section>
                  {!loading ?
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      style={{
                        borderBottom: 'none',
                        fontWeight: "500",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        fontSize: '13px',
                        cursor: 'pointer'
                      }}
                    >
                      Certificates
                      <img
                        style={{
                          transform: 'rotate(0deg)',
                        }}
                        src={require('../../assets/images/dd-arrow.svg')}
                        alt=""
                      />
                    </Accordion.Toggle> : <Loader />
                  }
                  {certificates && (
                    <Accordion.Collapse eventKey="0">
                      {certificates.length != 0 &&
                        <Card.Body>
                          <ul className="ad-list">
                            {Object.keys(certificates).map((elKey) => {
                              return (
                                elKey &&
                                certificates[elKey] && (
                                  <li>
                                    <span>{elKey}</span>
                                    <br />
                                    <span>
                                      <a
                                        href={certificates[elKey]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <FontAwesomeIcon
                                          icon={faDownload}
                                          style={{ color: 'black' }}
                                        />
                                      </a>
                                    </span>
                                  </li>
                                )
                              );
                            })}
                          </ul>
                        </Card.Body>}
                    </Accordion.Collapse>
                  )}
                </section>
                {/* )} */}
              </Card>
            </Accordion>
          </div>
        </div>
      </div>
    );
  } catch (e) {
    console.log(e);
    return (
      <div
        className={
          props.showSidebar ? 'ad-container' : 'ad-container-collapsed'
        }
        style={{
          textAlign: 'center',
          display: 'flex',
          color: 'red',
          alignItems: 'center',
        }}
      >
        Something went wrong!
      </div>
      // 
    );
  }
}

CWRightSideBar.propTypes = {
  showSidebar: PropTypes.bool,
  applicationType: PropTypes.string,
};

CWRightSideBar.defaultProps = {
  showSidebar: true,
  applicationType: 'SELF CERTIFICATION',
  setShowSidebar: () => { },
};
