import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PERMISSIONS_LEVELS, FILTER_LIST_ENUM, FILTER_LIST_CORRECTION } from './util/helperFunctions';
import { useQuery } from '@apollo/client';
import { GET_OFFICER_PROFILE } from './graphql/query/officerProfile';

export const AppContext = createContext();

export const ContextProvider = (props) => {
  const [permissionLevel, setPermissionLevel] = useState(
    PERMISSIONS_LEVELS.TPSO_OFFICER
  );
  const existingFlow = window.sessionStorage.getItem('currentFlow')
    ? window.sessionStorage.getItem('currentFlow')
    : 'post-verification';

    const newExistingFlow = window.sessionStorage.getItem('newFlow')?window.sessionStorage.getItem('newFlow'):'correction-verification'

  const [currentFlow, setCurrentFlow] = useState(existingFlow);
   const [newFlow,setNewFlow] = useState(newExistingFlow)
  const [officerType, setOfficerType] = useState('DTCP');
  const [currentChasingFlow, setCurrentChasingFlow] = useState('summary');
  const [disableFileUpload, updateDisableFileUpload] = useState(false);
  const [innerListValue, setInnerListValue] = useState(FILTER_LIST_ENUM.review);
  const [mediaId,setMediaId] = useState('')
  const [innerCorrectionValue,setInerCorrectionValue] = useState( FILTER_LIST_CORRECTION.completed)
   const [errorScreen ,setErrorScreen]   = useState(null)
   const [logoutLoading , setLogoutLoading] = useState(null)
  useEffect(() => {
    setInnerListValue(FILTER_LIST_ENUM.review);
  //
  }, [currentFlow]);

  useEffect(()=>{
    setInerCorrectionValue(FILTER_LIST_CORRECTION.completed)
  },[newFlow])
  
const setNewFlowGlobally = (data) =>{
  window.sessionStorage.setItem('newFlow' ,data)
  setNewFlow(data)
}

const setInerCorrectionValueGlobally = (data) =>{
  setInerCorrectionValue(data)
}

  const setInnerListValueGlobally = (data) => {
    setInnerListValue(data);
  };
  const setCurrentFlowGlobally = (data) => {
    window.sessionStorage.setItem('currentFlow', data);
    setCurrentFlow(data);
  };

  const allPerssionLevels = [];
  Object.keys(PERMISSIONS_LEVELS).map((key, id) =>
    allPerssionLevels.push(PERMISSIONS_LEVELS[key])
  );
  const { data, client, loading, error } = useQuery(GET_OFFICER_PROFILE, { fetchPolicy: 'network-only' });

  const allCurrentFlows = ['post-verification', 'single-window'];
  const contextVariables = {
    permissionLevel,
    setPermissionLevel,
    allPerssionLevels,
    currentFlow,
    setCurrentFlow,
    allCurrentFlows,
    currentChasingFlow,
    setCurrentChasingFlow,
    disableFileUpload,
    updateDisableFileUpload,
    setMediaId,
    officerType,
    setOfficerType,
    setCurrentFlowGlobally,
    innerListValue,
    setInnerListValue,
    setInnerListValueGlobally,
    setInerCorrectionValue,
    innerCorrectionValue,
    setNewFlowGlobally,
    newFlow,
    setInerCorrectionValueGlobally,
    mediaId,
    setErrorScreen,
    errorScreen,
    logoutLoading,
    setLogoutLoading,
    data, client, loading, error 
  };

  return (
    <AppContext.Provider value={contextVariables}>
      {props.children}
    </AppContext.Provider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.any,
};
