import React, { Component, useContext, useState } from 'react';
import { compose, withProps } from 'recompose';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import Marquee from "react-fast-marquee";
import {
  PERMISSIONS_LEVELS,
} from '../../util/helperFunctions';
import environment from 'environment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Polygon,
//   KmlLayer,
// } from 'react-google-maps';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import MapLoader from '../LoadingScreen/mapLoading';
import { AppContext } from '../../AppContext';

// const { compose, withProps } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon,
  KmlLayer,
} = require("react-google-maps");
const { DrawingManager } = require("react-google-maps/lib/components/drawing/DrawingManager");
var coordinatesss
function getPaths(polygon) {
  var coordinates = (polygon.getPath().getArray());
  coordinatesss = coordinates;
  console.log(coordinatesss, "coordinatesss")
}


const authToken = sessionStorage.getItem('accessToken')

const isFunction = (value) => {
  if (typeof value.lat == "function" || typeof value.lng == "function") {
    return true
  } else {
    return false
  }
}

class GeoMap extends Component {
  // Always call super() to initialize the base class (Component)


  coord = []



  DeleteCoordinates = () => {
    const config = {
      headers: {
        Accept: '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken'),
      },
      data: {
        property_id: this.props.propertyID,
      },
    };

    axios
      .delete(
        `https://uat-backend.tsbpass.com/api/v1/properties/delete_officer_coordinates`,
        config
      )
      .then((response) => {
        // this.props.onHide(); // Close modal instead of reloading
        this.props.setUpdateApiCalls("false")
      })
      .catch((error) => {
        this.setState((prevState) => ({
          isDisabled: true,
        }));
        console.error('Error deleting data:', error);
      });
  };

  selectedMarks = () => {
    if (coordinatesss != undefined) {
      for (var i = 0; i < coordinatesss.length; i++) {
        if (isFunction(coordinatesss[i])) {
          this.coord.push({ lat: coordinatesss[i].lat(), lng: coordinatesss[i].lng() })
        } else {
          this.coord.push({ lat: coordinatesss[i].lat, lng: coordinatesss[i].lng })

        }
      }


      //  coord = coordinatesss.map((coordinate) => ({
      //   lat: coordinate.lat(),
      //   lng: coordinate.lng(),
      // }));
      //POST /api/v1/properties/update_geo_coordinates

      // const coordJSON = JSON.stringify(coord)    
      const OfficerId = window.sessionStorage.getItem('id');
      if (this.coord.length > 0) {
        const config = {
          headers: {
            Accept: '*/*',
            Authorization: 'Bearer ' + sessionStorage.getItem('accessToken'),
          },
        };
        axios
          .post(`https://uat-backend.tsbpass.com/api/v1/properties/update_geo_coordinates`, {
            officer_geo_coordinates: this.coord,
            property_id: this.props.propertyID,
            officer_id: OfficerId
          },
            config
          )
          .then((response) => {
            // this.viewApi()
            this.props.setUpdateApiCalls("true")
          })
          .catch((error) => {
            // setErrorHandling(true)
            console.error('Error posting data:', error);
          });

      }
    } else {

    }
    // console.log(coord ,this.props.propertyID, "coorfs")
    // this.props.getCoordinates(coord);
    // window.location.reload()
  }
  state = {
    mapLat: parseFloat(this.props.coordinates[0].lat),
    mapLng: parseFloat(this.props.coordinates[0].lng),
    isIntersecting: '',
  };
  MapsApi = [
    {
      url: 'https://googlemaps-kml.s3.ap-south-1.amazonaws.com/google-maps/water%2Bbodies.kml',
      message: 'The Citizen ploted area falls within  the water body limits',
    },
    {
      url: 'https://googlemaps-kml.s3.ap-south-1.amazonaws.com/google-maps/open+spaces-ghmc-f.kmz',
      message: 'The  area falls under goverment prohibited lands',
    },
    {
      url: 'https://googlemaps-kml.s3.ap-south-1.amazonaws.com/google-maps/open+plase-ghmc.kml',
      message:
        'The Citizen ploted area falls within  the goverment prohibited land parcels',
    },
    {
      url: 'https://googlemaps-kml.s3.ap-south-1.amazonaws.com/google-maps/ALL_HMDA_LAKES_FTL.kml',
      message: 'The Citizen ploted area falls within  the water body limits',
    },
    {
      url: 'https://googlemaps-kml.s3.ap-south-1.amazonaws.com/google-maps/ALL_HMDA_LAKES_FTL.kmz',
      message: 'The Citizen ploted area falls within  the water body limits',
    },

    {
      url: 'https://googlemaps-kml.s3.ap-south-1.amazonaws.com/google-maps/hmda+lakes+2892.kmz',
      message: 'The Citizen ploted area falls within  the water body limits',
    },
    //
  ];
  createJstsPolygon(geometryFactory, polygon) {
    var coordinates = polygon.map(function name(coord) {
      return new window.jsts.geom.Coordinate(coord.lat, coord.lng);
    });
    if (coordinates[0].compareTo(coordinates[coordinates.length - 1]) != 0)
      coordinates.push(coordinates[0]);
    var shell = geometryFactory.createLinearRing(coordinates);
    return geometryFactory.createPolygon(shell);
  }



  extractCoordinatesFromKML = (kmlData) => {
    const coordinatesRegex = /<coordinates>\s*([^<]+)\s*<\/coordinates>/g;
    const coordinatePairs = [];

    let match;
    while ((match = coordinatesRegex.exec(kmlData)) !== null) {
      const coords = match[1].trim().split(' ');
      const polygonCoords = coords.map((coord) => {
        const [lng, lat] = coord.split(',').map(parseFloat);
        return { lat, lng };
      });

      coordinatePairs.push(polygonCoords);
    }
    return coordinatePairs;
  };
  componentDidMount() {
    this.fetchKmlData();
  }

  handleContentChange = (index, field, e) => {
    const newValue = e.target.innerText.trim(); // Retrieve the updated value
    const updatedCoordinates = [...this.props.coordinates];
    updatedCoordinates[index][field] = newValue; // Update the specified field
    coordinatesss = updatedCoordinates
    // console.log(updatedCoordinates,coordinatesss,'updatedCoordinates')
  };


  fetchKmlData = async () => {
    try {
      const reversedCoords = this.props.coordinates.map((ll) => {
        return { lat: parseFloat(ll.lat), lng: parseFloat(ll.lng) };
      });
      var geometryFactory = new window.jsts.geom.GeometryFactory();
      const user_poly = this.createJstsPolygon(geometryFactory, reversedCoords);
      let intersectionResults = '';
      for (let kmlData of this.MapsApi) {
        let response = await axios.get(kmlData.url);
        let xmlData = response.data;

        let kmlCoords = this.extractCoordinatesFromKML(xmlData);
        // kmlCoords.map((each) => {f
        //   let kmlPoly = this.createJstsPolygon(geometryFactory, each);
        //   const intersects = kmlPoly.intersects(user_poly);
        //   if (intersects) {
        //     intersectionResults = kmlData.message;
        //   }
        // });
        for (let i = 0; i < kmlCoords.length; i++) {
          if (kmlCoords[i].length < 4) {
            continue;
          }
          let kmlPoly = this.createJstsPolygon(geometryFactory, kmlCoords[i]);
          const intersects = kmlPoly.intersects(user_poly);
          if (intersects) {
            intersectionResults = kmlData.message;
            break;
          }
        }
      }
      this.setState({ isIntersecting: intersectionResults });
    } catch (error) {
      console.error('Error fetching KML data:', error);
    }
  };
  handleClosePopUp = () => {
    // this.props.onHide()
     window.location.reload()
  }
  render() {
    const reversedCoords = this.props?.coordinates?.map((ll) => {
      return { lat: parseFloat(ll.lat), lng: parseFloat(ll.lng) };
    });
    const OfficerreversedCoords = this.props?.officer_geo_coordinates?.map((ll) => {
      return { lat: parseFloat(ll.latitude), lng: parseFloat(ll.longitude) };
    });

    const MyMapComponent = compose(
      withProps({
        googleMapURL:
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyBiLkEAQlxtAh2qloIX4gD9Wf8lG0HvhtU&libraries=drawing',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />,
      }),
      withScriptjs,
      withGoogleMap
    )((props) => (
      <GoogleMap
        defaultZoom={17}
        defaultCenter={
          new window.google.maps.LatLng(this.state.mapLat, this.state.mapLng)
        }
      >
        <DrawingManager
          defaultDrawingMode={window.google.maps.drawing.OverlayType.POLYGON}
          defaultOptions={{
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [
                // google.maps.drawing.OverlayType.CIRCLE,
                window.google.maps.drawing.OverlayType.POLYGON,
                // google.maps.drawing.OverlayType.POLYLINE,
                // google.maps.drawing.OverlayType.RECTANGLE,
              ],
            },

          }}
          onPolygonComplete={(value) => getPaths(value)}
        />
        <Polygon
          path={OfficerreversedCoords}
          // key={1}
          options={{
            fillColor: '#28a745',
            fillOpacity: 0.4,
            strokeColor: '#28a745',
            strokeOpacity: 1,
            strokeWeight: 1,
          }}
        />
        <Polygon
          path={reversedCoords}
          // key={1}
          options={{
            fillColor: '#000',
            fillOpacity: 0.4,
            strokeColor: '#000',
            strokeOpacity: 1,
            strokeWeight: 1,
          }}
        />
        {this.MapsApi.map((mapUrl, idx) => {
          return (
            <KmlLayer
              url={mapUrl.url}
              key={idx}
              options={{
                preserveViewport: true,
              }}
            />
          );
        })}
      </GoogleMap>
    ));
    return (
      <Modal
        className="map-modal"
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div style={{ height: '70vh', width: '100%' }}>
            <Marquee style={{ cursor: "context-menu", fontSize: '13px' }} pauseOnHover={true}>

              <li style={{ color: 'blue', listStyle: 'none' }}> {'-'}Blue colour outline indicates Govt waterbodies Area Limits in  GHMC</li> &nbsp; &nbsp;
              <li style={{ color: 'rgb(247,7,118)', listStyle: 'none' }}> {"-"}   Pink colour outline indicates  Govt prohibited Area</li> &nbsp; &nbsp;
              <li style={{ color: 'black', listStyle: 'none' }}> {"-"}  Black colour outline indicates  Citizen marked Area</li> &nbsp; &nbsp;
              <li style={{ color: 'red', listStyle: 'none' }}> {"-"}  Red colour outline indicates Govt waterbodies Area Limits in HMDA</li> &nbsp; &nbsp;
            </Marquee>

            {this.props.IsMapLoading ? <MapLoader /> : <MyMapComponent />}
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',

          }}
        >
          <div style={{ color: 'red', fontSize: '13px', fontWeight: "bo" }}>
            {this.state.isIntersecting && `Note: ${this.state.isIntersecting}`}
          </div>
          {!this.props.IsMapLoading &&
            <div style={{ width: "85%", display: "flex", justifyContent: "space-between" }}>
              {reversedCoords.length > 0 && <div>
                Citizen Updated Coordinates


                <table>
                  <tr style={{ borderBottom: "1px solid black" }}>
                    <th style={{ padding: "5px" }}>s.no</th>
                    <th style={{ padding: "5px" }}>Latitude</th>
                    <th style={{ padding: "5px" }}>Longitude</th>
                  </tr>

                  {reversedCoords.map((item, index) => {
                    return <tr style={{ borderBottom: "1px solid black" }}>
                      <td style={{ padding: "5px" }}>
                        {index + 1}</td>
                      <td
                        contentEditable={(OfficerreversedCoords != undefined && OfficerreversedCoords.length > 0) ? false : true}
                        onBlur={(e) => this.handleContentChange(index, 'lat', e)} // Use onBlur to update state
                        suppressContentEditableWarning={true} // Suppress React warnings for contentEditable
                      >
                        {item.lat}
                      </td>
                      <td
                        contentEditable={(OfficerreversedCoords != undefined && OfficerreversedCoords.length > 0) ? false : true}
                        onBlur={(e) => this.handleContentChange(index, 'lng', e)} // Use onBlur to update state
                        suppressContentEditableWarning={true} // Suppress React warnings for contentEditable
                        style={{ padding: "5px" }}>{item.lng}</td>
                    </tr>
                  })}


                </table>

              </div>}
              {(OfficerreversedCoords != undefined && OfficerreversedCoords.length > 0) && <div >
                Officer Updated Coordinates


                <table>
                  <tr style={{ borderBottom: "1px solid black" }}>
                    <th style={{ padding: "5px" }}>s.no</th>
                    <th style={{ padding: "5px" }}>Latitude</th>
                    <th style={{ padding: "5px" }}>Longitude</th>
                  </tr>

                  {OfficerreversedCoords != undefined && OfficerreversedCoords.map((item, index) => {
                    return <tr key={index} style={{ borderBottom: "1px solid black" }}>
                      <td style={{ padding: "5px" }}>{index + 1}</td>
                      <td style={{ padding: "5px" }}>{item.lat}</td>
                      <td style={{ padding: "5px" }}>{item.lng}</td>
                    </tr>
                  })}


                </table>

              </div>}
            </div>}
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          {[
            PERMISSIONS_LEVELS.SITE_VERIFICATION,
            PERMISSIONS_LEVELS.TPSO_OFFICER,
            PERMISSIONS_LEVELS.SECTION_OFFICER,
            PERMISSIONS_LEVELS.UDA_GP_SITE_OFFICER,
            PERMISSIONS_LEVELS.CP_TECH_OFFICER,
            PERMISSIONS_LEVELS.TECH_OFFICER,
            PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION
          ].includes(this.props.permissionLevel) && <div >

              {(OfficerreversedCoords != undefined && OfficerreversedCoords.length == 0) ? 
               <>

                {this.props.IsMapLoading ? <button disabled={true} class="button">
                  Loading...
                </button> : <Button disabled={this.state.isDisabled} className="area-btn" onClick={this.selectedMarks} >

                  Edit Area</Button>}</>
                :
                <>
                  {this.props.IsMapLoading ? <button disabled={true} class="button">
                    Loading...
                  </button> : <Button disabled={this.state.isDisabled} className="area-btn" onClick={this.DeleteCoordinates} >Delete Edited Area</Button>
                  } </>}
           
            </div>
          }
   <Button className="cancle-btn" onClick={this.handleClosePopUp}>
                close
              </Button>
              </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

GeoMap.propTypes = {
  onHide: PropTypes.func,
  coordinates: PropTypes.array,
};

export default GeoMap;