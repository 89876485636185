import React, { useState, memo, useContext, useEffect } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import './index.scss';
import { PropTypes } from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_APPLICATION_QUESTIONS } from '../../graphql/query/applicationQuestions';
import Answer from './Answer';
import ShortFallToggle from '../ShortFallToggle';
import AddRemark from '../AddRemark';
import { AppContext } from './../../AppContext';
import DeviationLevelTwo from '../ApplicationDetailComponent/deviationL2'
import DeviationLevelOne from '../ApplicationDetailComponent/deviationL1'
import {
  PERMISSIONS_LEVELS,
  getTranslatedToList,
} from '../../util/helperFunctions';
import history from '../../services/history';

const AnswerList = memo((props) => {
  // const { data: answersData, loading, error } = {};
  const { data: answersData, loading, error } = useQuery(
    GET_APPLICATION_QUESTIONS,
    {
      fetchPolicy: 'network-only',
      variables: { id: props.id },
    }
  );
  const [open, setOpen] = useState(-1);

  useEffect(() => {
    if (answersData && getDataToDisplay(answersData)?.applicationForms) {
      shouldNotesBeDisabled(getDataToDisplay(answersData).applicationForms);
    }
  }, [answersData]);

  // Have to make initial state dynamic somehow
  const [shortFall, setShotFall] = useState({
    0: false,
    1: false,
    2: false,
  });
  const { permissionLevel } = useContext(AppContext);
  const chevronFlip = (e) => {
    console.log(e, open);
    setOpen(e);
  };
  const getDataToDisplay = (ansData) => {
    if (!props.currentVerification || !ansData) {
      return ansData;
    } else {
      const appsForm = [...ansData?.applicationForms];
      const newFormsData = appsForm.filter(
        (el) => el.stage === props.currentVerification
      );
      return { applicationForms: newFormsData };
    }
  };

  const sortCompare = (a, b) => {
    if (!b?.order || parseInt(a?.order, 10) < parseInt(b?.order, 10)) {
      return -1;
    }
    if (!a?.order || parseInt(a?.order, 10) > parseInt(b?.order, 10)) {
      return 1;
    }
    return 0;
  };

  const shouldNotesBeDisabled = (data) => {
    /** { This was the logic before for the notes to be disabled,
     * Now we are using the sidebar filter to make the notes disabled,
     * This is irrespective of the permissionLevel and statusObj.status,
     * Uncomment this if the previous logic is required back.
     * } */

    // if site, title or technical this is logic
    // if (
    //   [
    //     PERMISSIONS_LEVELS.SITE_VERIFICATION,
    //     PERMISSIONS_LEVELS.TITLE_VERIFICATION,
    //     PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION,
    //   ].includes(permissionLevel)
    // ) {
    //   const statusObj = data.find(
    //     (obj) => obj.stage === permissionLevel.replace('_', ' ')
    //   );
    //   const isDisabled = statusObj?.status !== 'IN_PROCESS';
    //   props.setIsDisabledNotes(isDisabled); //  allow notes,
    //   return;
    // }
    // if commissioner, zonal commissioner
    // if (
    //   [
    //     PERMISSIONS_LEVELS.COMMISSIONER,
    //     PERMISSIONS_LEVELS.ZONAL_COMMISSIONER,
    //     PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER,
    //   ].includes(permissionLevel)
    // ) {
    //   let flag = true; // dont allow notes,
    //   const filterData = data.filter(
    //     (el) => !el.stage.includes('FEE CERTIFICATION')
    //   );
    //   filterData.map((item) => {
    //     if (['IN_PROCESS'].includes(item.stageState.status)) {
    //       flag = false; //  allow notes,
    //     }
    //   });
    //   props.setIsDisabledNotes(flag);

    /** { --- Old logic end here --- } */

    // Using this when officer comes here from summary report console
    if (props.fromSummary) {
      props.setIsDisabledNotes(true); //  disable notes,
      return;
    }
    // Using this when officer comes here from post verification console and
    // enableNotes is currently true for 'Under Verification' sidebar filter
    if (history.location.state?.enableNotes) {
      props.setIsDisabledNotes(false); //  allow notes,
    }
  };

  const getOfficerFinalRemarks = (stageState, level) => {
    if (level === 'L1') {
      return getFinalRemarks(stageState?.l1Remarks);
    } else if (level === 'L2') {
      return getFinalRemarks(stageState?.l2Remarks);
    } else {
      if (!level && stageState.finalRemark)
        return getFinalRemarks(stageState.finalRemark);
      return <span>not reviewed</span>;
    }
  };

  const getFinalRemarks = (finalRemark) => {
    let reasons;
    if (!finalRemark) return <span>not reviewed</span>;
    try {
      reasons = JSON.parse(finalRemark);
    } catch (e) {
      return <span>{finalRemark || 'not reviewed'}</span>;
    }
    return (
      <div>
        {reasons.map((el, index) => {
          return <li key={index}>{el}</li>;
        })}
      </div>
    );
  };

  let isRemarksEmpty = true;
  try {
    return (
      <Accordion defaultActiveKey="-1" onSelect={(e) => chevronFlip(e)}>
        {loading && (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {getDataToDisplay(answersData) &&
          getDataToDisplay(answersData).applicationForms && (
            <>
              {getDataToDisplay(answersData)?.applicationForms.map(
                (formItem, indexForm) => {
                  return formItem.stageState?.stageOfficers.map((item, id) => {
                    if (
                      (props.isVerification && item.officerLevel !== 'L2') ||
                      !props.isVerification ||
                      [
                        PERMISSIONS_LEVELS.COMMISSIONER,
                        PERMISSIONS_LEVELS.ZONAL_COMMISSIONER,
                        PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER,
                      ].includes(permissionLevel)
                    ) {
                      isRemarksEmpty = false;
                      return permissionLevel ===
                        PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION &&
                        !(
                          (id === 0 &&
                            formItem.stageState.name ===
                            'TECHNICAL VERIFICATION') ||
                          formItem.stageState.name !== 'TECHNICAL VERIFICATION'
                        ) ? null : (
                        <div
                          style={{
                            boxShadow: '0px 1px 8px 1px lightgrey',
                            padding: '10px',
                            margin: '15px',
                          }}
                        >
                          <Card
                            className="answer-card"
                            key={indexForm + ':' + id}
                            style={{
                              height: 'auto',
                              margin: '10px',
                            }}
                          >
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey={indexForm + ':' + id}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100vw',
                                }}
                              >
                                <div>
                                  <b>{formItem.title}</b>
                                  <br />
                                  Status:
                                  {formItem.status
                                    ? ' ' + formItem.status
                                    : ' Not started'}
                                  <br />
                                  {item.officerLevel && 'Officer Level:'}
                                </div>
                                <div>
                                  <div>
                                    {item.officerName}
                                    <br />
                                    {item.officerRole}
                                    <br />
                                    {item.officerLevel}
                                    <br />
                                  </div>

                                  <br />
                                </div>
                                <img
                                  src={require('../../assets/images/dd-arrow.svg')}
                                  className={
                                    open === { indexForm } ? 'arrowUP' : null
                                  }
                                />
                              </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={indexForm + ':' + id}>
                              <Card.Body>
                                {
                                  // (item.officerLevel === 'L1' ||
                                  //   [
                                  //     PERMISSIONS_LEVELS.COMMISSIONER,
                                  //     PERMISSIONS_LEVELS.ZONAL_COMMISSIONER,
                                  //     PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER,
                                  //     PERMISSIONS_LEVELS.SITE_VERIFICATION,
                                  //     PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION,
                                  //     PERMISSIONS_LEVELS.TITLE_VERIFICATION,
                                  //   ].includes(permissionLevel)) &&
                                  <div className="answer-list">
                                    {/* SPREADING AND MAKING A NEW COPY OF ARRAY BECAUSE SORTING NOT ALLOWED (ARRAY IS FROZEN IN STRICT MODE) */}
                                    {[...formItem.fields]
                                      .sort(sortCompare)
                                      .map((fieldItem, indexField) => {
                                        if (
                                          formItem.stage === 'FEE CERTIFICATION'
                                        )
                                          return (
                                            <div key={indexField}>
                                              <h6>Final Remark : </h6>
                                              <p>
                                                {
                                                  formItem.answer.data
                                                    .finalremark
                                                }
                                              </p>
                                            </div>
                                          );
                                        else
                                          return (
                                            <Answer
                                              field={fieldItem}
                                              answer={
                                                formItem?.answer?.data || null
                                              }
                                              key={indexField}
                                            />
                                          );
                                      })}
                                  </div>
                                }
                                {item.officerLevel === 'L1' && formItem.title === "Site_OCCUPANCY_GHMC" && formItem.status !== "IN_PROCESS" &&
                                  <DeviationLevelOne />}
                                {item.officerLevel === 'L2' && formItem.title === "Site_OCCUPANCY_GHMC" && formItem.status !== "IN_PROCESS" &&
                                  <DeviationLevelTwo />}
                                {(formItem.title === "Site_OCCUPANCY_DTCP" || formItem.title === "SITE_OCCUPANCY_HMDA") && formItem.status !== "IN_PROCESS" &&
                                  <DeviationLevelOne />}
                                {(formItem.title === "Technical_OCCUPANCY_DTCP" || formItem.title === "TECH_OCCUPANCY_HMDA") && formItem.status !== "IN_PROCESS" &&
                                  <DeviationLevelTwo />}
                                <div className="my-2">
                                  <h6>Final Remarks :</h6>
                                  <p>
                                    {getOfficerFinalRemarks(
                                      formItem?.stageState,
                                      item.officerLevel
                                    )}
                                  </p>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                            {props.showShortFall && (
                              <div key={indexForm + ':' + id}>
                                <ShortFallToggle
                                  index={indexForm + ':' + id}
                                  onShortFall={() => {
                                    setShotFall({
                                      ...shortFall,
                                      [indexForm + ':' + id]: !shortFall[
                                        indexForm + ':' + id
                                      ],
                                    });
                                  }}
                                />
                                {shortFall && shortFall[indexForm + ':' + id] && (
                                  <AddRemark
                                    setFinalRemark={(e) => {
                                      console.log(e);
                                    }}
                                    formId={formItem.id}
                                  />
                                )}
                                {/* add the proceed to button which will submit the shortfall */}
                              </div>
                            )}
                          </Card>
                        </div>
                      );
                    }
                  });
                }
              )}
              <div className="mb-3 p-3">
                {answersData.officersApplication.finalRemark && (
                  <p>
                     Commissioner Remarks :{' '}
                    {getTranslatedToList(
                      answersData.officersApplication.finalRemark
                    )}
                  </p>
                )}
                {/* Showing Emtpy remarks error msg when there are not remkars by any officer */}
                {isRemarksEmpty &&
                  !answersData?.officersApplication?.finalRemark &&
                  !error && (
                    <div style={{ fontSize: 'smaller' }}>
                      There are no review remarks for this application at the
                      moment.
                    </div>
                  )}
                {error && (
                  <div style={{ fontSize: 'smaller' }}>{error.message}</div>
                )}
              </div>
            </>
          )}
      </Accordion>
    );
  } catch (e) {
    console.log(e);
    return (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          color: 'red',
          alignItems: 'center',
        }}
      >
        Something went wrong!
      </div>
    );
  }
});

AnswerList.propTypes = {
  id: PropTypes.string,
  showShortFall: PropTypes.bool,
  currentVerification: PropTypes.string,
  isVerification: PropTypes.string,
  setIsDisabledNotes: PropTypes.func,
  fromSummary: PropTypes.bool,
};

AnswerList.defaultProps = {
  showShortFall: false,
  currentVerification: null,
  isVerification: false,
  fromSummary: false,
  setIsDisabledNotes: () => { },
};

AnswerList.displayName = 'AnswerList';

export default AnswerList;

