import { gql } from '@apollo/client';

export const UPDATE_APPLICATION_STATUS = gql`
  mutation updateApplicationStatus(
    $applicationId: ID!
    $finalRemark: String
    $status: Status!
    $eligibilityForRefund: String! 
  ) {
    updateApplicationStatus(
      applicationId: $applicationId
      finalRemark: $finalRemark
      status: $status
      eligibilityForRefund: $eligibilityForRefund
    )
  }
`;
