/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Table, Col } from 'react-bootstrap';
import './review.scss';
import { toast } from 'react-toastify';
import axios from 'axios';
import environment from 'environment';
import { useParams } from 'react-router';

const DeviationLevelTwo = (props) => {
    const [deviationData, setDeviationData] = useState(false);
    const { id } = useParams();

    let totalDeviatedValue = parseFloat(deviationData ? deviationData.l2_front_total_deviated_builtup_area : 0) + parseFloat(deviationData ? deviationData.l2_rear_total_deviated_builtup_area : 0) + parseFloat(deviationData.l2_side_one_total_deviated_builtup_area) + parseFloat(deviationData ? deviationData.l2_side_two_total_deviated_builtup_area : 0);

    const deviatedDetails = () => {
        return (
            deviationData ? (
                <Col sm={12} style={{ marginTop: '20px' }} >
                    <Table bordered className="devi-table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Set Backs</th>
                                <th>As per Building Permit Order (in Mts)</th>
                                <th>As on ground (in Mts)</th>
                                <th>Deviation in Set Back (in mts)</th>
                                <th>% of Setback Deviated</th>
                                <th>Building Lengths (in Sq Mts)</th>
                                <th>Deviated Builtup Area per Floor (in Sq. mts)</th>
                                <th>No. of Floors</th>
                                <th>Total Deviated Builtup Area (in Sq. mts.)</th>
                                <th>Land Value per Sq. Yd as per Registration Dept., (In Rs.)</th>
                                <th>Total Compounding Fee (In Rs.)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>1</th>
                                <th>Front</th>
                                <th>
                                    {deviationData ? deviationData.l2_front_building_permit_order : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_front_setback_as_on_ground : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_front_setback_deviation_in_sq_mtrs : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_front_setback_deviated_percentage : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_front_building_lengths : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_front_side_deviated_builtup_area_per_floor : 0}
                                </th>
                                <th>

                                    {deviationData ? deviationData.l2_front_no_of_floors : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_front_total_deviated_builtup_area : 0}
                                </th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>2</th>
                                <th>Rear</th>
                                <th>
                                    {deviationData ? deviationData.l2_rear_building_permit_order : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_rear_setback_as_on_ground : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_rear_setback_deviation_in_sq_mtrs : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_rear_setback_deviated_percentage : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_rear_building_lengths : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_rear_deviated_builtup_area_per_floor : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_rear_no_of_floors : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_rear_total_deviated_builtup_area : 0}
                                </th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>3</th>
                                <th>Side1</th>
                                <th>
                                    {deviationData ? deviationData.l2_side_one_building_permit_order : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_side_one_setback_as_on_ground : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_side_one_setback_deviation_in_sq_mtrs : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_side_one_setback_deviated_percentage : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_side_one_building_lengths : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_side_one_deviated_builtup_area_per_floor : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_side_one_no_of_floors : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_side_one_total_deviated_builtup_area : 0}
                                </th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>4</th>
                                <th>Side2</th>
                                <th>
                                    {deviationData ? deviationData.l2_side_two_building_permit_order : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_side_two_setback_as_on_ground : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_side_two__setback_deviation_in_sq_mtrs : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_side_two_setback_deviated_percentage : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_side_two_building_lengths : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_side_two_deviated_builtup_area_per_floor : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_side_two_no_of_floors : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_side_two_total_deviated_builtup_area : 0}
                                </th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>
                                    {totalDeviatedValue.toFixed(2)}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_land_value_per_registration_dep : 0}
                                </th>
                                <th>
                                    {deviationData ? deviationData.l2_total_compounding_fee : 0}
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                </Col>) : ""
        )
    }


    useEffect(() => {
        // eslint-disable-next-line no-undef
        const authToken = sessionStorage.getItem('accessToken');

        if (authToken && id) {
            axios
                .get(`${environment.baseApi}/applications/${id}/occupancy_show`, {
                    headers: {
                        Authorization: 'Bearer ' + authToken,
                        Accept: '*/*',
                    },
                })
                .then(function (response) {
                    console.log('response', response.data.data);
                    if (response.data.success) {
                        setDeviationData(response.data.data.deviation);
                    } else {
                        toast.error(response.data.message, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                        });
                    }
                })
                .catch(function (error) {
                    console.log('erroreee', error);

                    if (error.response) {
                        toast.error(error.response.data.message, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                        });
                    }
                });
        }
    }, []);

    return <div className="appreview-carousel-container">{deviatedDetails()}</div>;
};

export default DeviationLevelTwo;
