import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";

const CustomBarChart = ({ title, data, colors }) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    setSeries([{ name: title, data }]);
    setOptions({
      chart: {
        height: 300,
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      colors,
      plotOptions: {
        bar: {
          columnWidth: '50%',
          distributed: true
        }
      },
      dataLabels: {
        enabled: true
      },
      legend: {
        show: false
      },
      xaxis: {
        show: false,
        categories: ['Approved', 'Rejected', 'Verification Under Process', 'Deemed'],
        labels: {
          show: false,
        }
      }
    });
  }, [])

  return (
    <Card className="custom-bar-container custom-card">
      <Card.Body>
        <Card.Title className="Neutra-Demi font-size-16">{title}</Card.Title>
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </Card.Body>
    </Card>
  );
}

export default CustomBarChart;
