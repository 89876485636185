import PerformanceReport from '../pages/PerformanceReport';
import PreScrutiny from '../pages/PreScrutiny';
// import ApplicationDetailLayout from '../pages/ApplicationDetail';
import ApplicationDetailLayout from '../pages/ApplicationDetailRest';
import Dashboard from '../pages/Dashboard';
import PasswordSuccess from '../pages/PasswordSuccess';
import ResetPasswordNew from '../pages/ResetPasswordNew';
import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Routes';
import Login from '../pages/Login';
import ResetPassword from '../pages/ResetPassword';
import ChangePassword from '../pages/ChangePassword';
import ChangePasswordOTP from '../pages/ChangePasswordOTP';
import PostVerification from '../pages/PostVerification';
import TechnicalVerification from '../pages/TechnicalVerification';
import ChasingStats from '../pages/ChasingStats';
import CommissionerVerification from '../pages/CommissionerVerification';
import LineVerification from '../pages/LineVerification';
import SiteVerification from '../pages/SiteVerification';
import SingleWindowVerification from '../pages/swVerification';
import TitleVerification from '../pages/TitleVerification';
import ChasingDashboardPage from '../pages/ChasingDashboardNew';
import ChasingActionPanel from '../pages/ChasingActionPanel';
import ChasingCellApplications from '../pages/chasingCellApplications';
import FeeVerification from '../pages/FeeVerification';
import FeeVerificationAndPaymentReview from '../pages/FeeVerificationAndPaymentReview';
import FeeCalculator from '../pages/FeeCalculator';
import CmoDashboard from '../pages/CmoDashboard';
import CreateRemarks from '../pages/CreateRemarks';
import ReviewRemarks from '../components/ReviewRemarks';
import SummaryReport from '../pages/SummaryReport';
import Summary from '../pages/Summary';
import Dashboardnew from '../pages/Dashboardnew';
import FinancialYear from '../pages/FinancialYear';
import Shortfall from '../pages/Shortfall';
import Penalties from '../pages/Penalties';
import SearchChasingCell from '../pages/SearchChasingCell';
import CorrectionVerification from '../pages/CorrectionVerification';
import CorrectionTabel from '../pages/CorrectionTabel';
import CorrectionVerificationView from '../pages/CorrectionVerificationView'
import CommisionerCorrectionView from '../pages/CommisionerCorrectionView';
import IaIrVerification from '../pages/IaIrVerification';
import IaIrCommissioner from '../pages/IAIRCommisionerVerify';
import OcIAIRVerifications from '../components/IAIRQuestions/ocIAIRVerifications';
import OcIaIrVerification from '../pages/OcIAIRVerification';
import ReviewRemarksPopUp from '../components/IAIRQuestions/ReviewRemarks';
import CWApplicationDetails from '../pages/CWApplicationDetails';
export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={(props) => <Login {...props} />} />
      <Route path="/login" exact component={(props) => <Login {...props} />} />

      <Route
        path="/post-verification"
        isPrivate
        component={(props) => <PostVerification {...props} />}
      />
      <Route path="/commisioner-correction-view /:id"
        isPrivate
        component={(props) => <CommisionerCorrectionView {...props} />}
      />
      <Route path="/correction-verification"
        isPrivate
        component={(props) => <CorrectionVerification  {...props} />} />

      <Route path="/correction-view-/:id"
        isPrivate
        component={(props) => <CorrectionVerificationView  {...props} />} />

      <Route
        path="/correction-verification-/:id"
        isPrivate
        component={(props) => <CorrectionTabel  {...props} />}
      />

      <Route
        path="/Review-Remarks/:id"
        isPrivate
        component={(props) => <ReviewRemarksPopUp />}
      />
      <Route
        path="/reset-password"
        component={(props) => <ResetPassword {...props} />}
      />
      <Route
        path="/review/:id"
        component={(props) => <ReviewRemarks {...props} />}
      />
      <Route
        path="/reset-password-new"
        component={(props) => <ResetPasswordNew {...props} />}
      />
      <Route
        path="/change-password"
        component={(props) => <ChangePassword {...props} />}
      />
      <Route
        path="/password-success"
        component={(props) => <PasswordSuccess {...props} />}
      />
      <Route
        path="/change-password-OTP"
        component={(props) => <ChangePasswordOTP {...props} />}
      />

      <Route
        path="/dashboard"
        isPrivate
        component={(props) => <Dashboard {...props} />}
      />
      <Route
        path="/pre-scrutiny"
        isPrivate
        component={(props) => <PreScrutiny {...props} />}
      />
      <Route
        path="/fee-verification/:id"
        isPrivate
        component={(props) => <FeeVerification {...props} />}
      />

      <Route
        path="/fee-verification-review"
        isPrivate
        component={(props) => <FeeVerificationAndPaymentReview {...props} />}
      />

      <Route
        path="/iairVerification/:type/:id"
        isPrivate
        component={(props) => <IaIrVerification {...props} />}
      />
      <Route
        path="/iairVerification/:id"
        isPrivate
        component={(props) => <IaIrVerification {...props} />}
      />


      <Route
        path="/chasing-stats"
        isPrivate
        component={(props) => <ChasingStats {...props} />}
      />
      <Route
        path="/performance-report"
        isPrivate
        component={(props) => <PerformanceReport {...props} />}
      />
      <Route
        path="/application-details/:type/:id"
        isPrivate
        // exact // to ensure the id always passed
        component={(props) => <ApplicationDetailLayout {...props} />}
      />
      <Route
        path="/application-details/:id"
        isPrivate
        // exact // to ensure the id always passed
        component={(props) => <ApplicationDetailLayout {...props} />}
      />
      <Route
        path="/compoundWall-application-details/:type/:id"
        isPrivate
        // exact // to ensure the id always passed
        component={(props) => <CWApplicationDetails {...props} />}
      />
      <Route
        path="/iairVerification/:type/:id"
        // exact // to ensure the id always passed
        isPrivate
        component={(props) => <IaIrVerification {...props} />}
      />
      <Route
        path="/technical-verification/:type/:id"
        // exact // to ensure the id always passed
        isPrivate
        component={(props) => <TechnicalVerification {...props} />}
      />
      <Route
        path="/technical-verification/:id"
        // exact // to ensure the id always passed
        isPrivate
        component={(props) => <TechnicalVerification {...props} />}
      />


      <Route
        path="/title-verification/:id"
        // exact // to ensure the id always passed
        isPrivate
        component={(props) => <TitleVerification {...props} />}
      />
      <Route
        path="/site-verification/:type/:id"
        // exact // to ensure the id always passed
        isPrivate
        component={(props) => <SiteVerification {...props} />}
      />
      <Route
        path="/site-verification/:id"
        // exact // to ensure the id always passed
        isPrivate
        component={(props) => <SiteVerification {...props} />}
      />
      <Route
        path="/sw-verification/:type/:id"
        // exact // to ensure the id always passed
        isPrivate
        component={(props) => <SingleWindowVerification {...props} />}
      />
      <Route
        path="/sw-verification/:id"
        // exact // to ensure the id always passed
        isPrivate
        component={(props) => <SingleWindowVerification {...props} />}
      />

      <Route
        path="/sw-line-verification/:id"
        // exact // to ensure the id always passed
        isPrivate
        component={(props) => <SingleWindowVerification {...props} />}
      />

      <Route
        path="/iair-CommisionerVerify/:type/:id"
        isPrivate
        component={(props) => <IaIrCommissioner {...props} />}

      />
      <Route
        path="/iair-CommisionerVerify/:id"
        isPrivate
        component={(props) => <IaIrCommissioner {...props} />}

      />
      <Route
        path="/line-verification/:id"
        // exact // to ensure the id always passed
        isPrivate
        component={(props) => <IaIrVerification {...props} />}
      />
      <Route
        path="/commissioner-verification/:type/:id"
        // exact // to ensure the id always passed
        isPrivate
        component={(props) => <CommissionerVerification {...props} />}
      />
      <Route
        path="/commissioner-verification/:id"
        // exact // to ensure the id always passed
        isPrivate
        component={(props) => <CommissionerVerification {...props} />}
      />
      <Route
        path="/chasing-dashboard"
        isPrivate
        component={(props) => <ChasingDashboardPage {...props} />}
      />
      <Route
        path="/chasing-action-panel"
        isPrivate
        component={(props) => <ChasingActionPanel {...props} />}
      />
      <Route
        path="/chasing-applications"
        isPrivate
        component={(props) => <ChasingCellApplications {...props} />}
      />
      <Route
        path="/create-remarks/:id"
        // exact // to ensure the id always passed
        isPrivate
        component={(props) => <CreateRemarks {...props} />}
      />
      <Route
        path="/fee-calculator"
        // isPrivate
        component={(props) => <FeeCalculator {...props} />}
      />
      <Route
        path="/cmo-dashboard"
        isPrivate
        component={(props) => <CmoDashboard {...props} />}
      />
      <Route
        path="/summary-report"
        isPrivate
        component={(props) => <SummaryReport {...props} />}
      />
      <Route
        path="/summary/:label"
        isPrivate
        component={(props) => <Summary {...props} />}
      />
      <Route
        path="/dashboard-new"
        isPrivate
        component={(props) => <Dashboardnew {...props} />}
      />
      <Route
        path="/search"
        isPrivate
        component={(props) => <SearchChasingCell {...props} />}
      />
      <Route
        path="/building-permissions/annual-report"
        isPrivate
        component={(props) => <FinancialYear {...props} monthly={true} />}
      />
      <Route
        path="/building-permissions/monthly-report"
        isPrivate
        component={(props) => <FinancialYear {...props} monthly={true} />}
      />
      <Route
        path="/building-permissions/ulb-report"
        isPrivate
        component={(props) => <FinancialYear {...props} type={'ULB'} />}
      />
      <Route
        path="/building-permissions/district-report"
        isPrivate
        component={(props) => <FinancialYear {...props} type={'DISTRICT'} />}
      />
      <Route
        path="/building-permissions/authorities-report"
        isPrivate
        component={(props) => <FinancialYear {...props} type={'AUTHORITY'} />}
      />
      <Route
        path="/building-permissions/rejections-report"
        isPrivate
        component={(props) => <Summary {...props} type={'rejected'} />}
      />
      <Route
        path="/building-permissions/deemed-approval-report"
        isPrivate
        component={(props) => <Summary {...props} type={'deemed'} />}
      />
      <Route
        path="/building-permissions/post-verification-teams-report"
        isPrivate
        component={(props) => <Summary {...props} type={'approved'} />}
      />
      <Route
        path="/building-permissions/scrutiny-officers-report"
        isPrivate
        component={(props) => <Summary {...props} type={'approved'} />}
      />
      <Route
        path="/building-permissions/performance-report"
        isPrivate
        component={(props) => <Summary {...props} type={'approved'} />}
      />
      <Route
        path="/building-permissions/penalties-report"
        isPrivate
        component={(props) => <Summary {...props} type={'approved'} />}
      />
      <Route
        path="/building-permissions/grievance-report"
        isPrivate
        component={(props) => <Summary {...props} type={'approved'} />}
      />
      <Route
        path="/occupancy/annual-report"
        isPrivate
        component={(props) => <FinancialYear {...props} />}
      />
      <Route
        path="/month"
        isPrivate
        component={(props) => <FinancialYear {...props} />}
      />
      <Route
        path="/district-and-authority"
        isPrivate
        component={(props) => <FinancialYear {...props} />}
      />
      <Route
        path="/shortfall"
        isPrivate
        component={(props) => <Shortfall {...props} />}
      />
      <Route
        path="/shortfall-notices"
        isPrivate
        component={(props) => <Shortfall {...props} />}
      />
      <Route
        path="/scrutiny-officers"
        isPrivate
        component={(props) => <FinancialYear {...props} />}
      />
      <Route
        path="/penalties"
        isPrivate
        component={(props) => <Penalties {...props} />}
      />
      <Route
        path="/rejections"
        isPrivate
        component={(props) => <Penalties {...props} />}
      />
      <Route
        path="/under-process"
        isPrivate
        component={(props) => <Penalties {...props} />}
      />
      <Route
        path="/deemed-approval"
        isPrivate
        component={(props) => <Penalties {...props} />}
      />
      <Route
        path="/performance"
        isPrivate
        component={(props) => <Penalties {...props} />}
      />
      <Route
        path="/line-departments"
        isPrivate
        component={(props) => <Penalties {...props} />}
      />
      <Route
        path="/grievance-redressal-mechanism"
        isPrivate
        component={(props) => <Penalties {...props} />}
      />
      <Route
        path="/final-fee-intimation-letter"
        isPrivate
        component={(props) => <Penalties {...props} />}
      />
      {/* <Route path="/register" component={SignUp} /> */}
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Route component={(props) => <Login {...props} />} />
    </Switch>
  );
}
