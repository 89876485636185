import React, { useState, useEffect, useContext } from 'react';
import { Accordion, Card, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import environment from 'environment';
import { AppContext } from '../../AppContext';
import { PERMISSIONS_LEVELS } from '../../util/helperFunctions';
import { PropTypes } from 'prop-types';

const FeeAdjustmentDetails = ({ id ,setApprovalFor}) => {
  const { permissionLevel } = useContext(AppContext);

  const [feeAdjustmentDetails, setFeeAdjustmentDetails] = useState();
  const [applicaitonStatus, setApplicaitonStatus] = useState('')
  const [subTotal, setSubTotal] = useState({
    amount: 0,
    newAmount: 0,
  });

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const authToken = sessionStorage.getItem('accessToken');

    if (authToken && id) {
      axios
        .get(`${environment.baseApi}/fee_details/${id}`, {
          headers: {
            Authorization: 'Bearer ' + authToken,
            Accept: '*/*',
          },
        })
        .then(function (response) {
          if (response.data) {
            const {
              calculated_fee_breakup,
              updated_fee_breakup,
            } = response.data.data;
            let fee_breakup = (updated_fee_breakup && updated_fee_breakup.length > 0) ? updated_fee_breakup : calculated_fee_breakup;
            setFeeAdjustmentDetails(fee_breakup);
            setApplicaitonStatus(response.data.data.status)
            setApprovalFor(response.data.data.approval_for)
          }
        })
        .catch(function (error) {
          console.log('erroreee', error);
          setFeeAdjustmentDetails(false);
        });
    }
  }, []);

  useEffect(() => {
    if (feeAdjustmentDetails) {
      const totalAmount = calculateSubTotal(feeAdjustmentDetails, 'amount');
      const totalNewAmount = calculateSubTotal(
        feeAdjustmentDetails,
        'newAmount'
      );
      setSubTotal({
        amount: totalAmount,
        newAmount: totalNewAmount,
      });
    }
  }, [feeAdjustmentDetails]);

  const calculateSubTotal = (array, property) => {
    let sumArray;
    property.includes('newAmount')
      ? (sumArray = array.map((item) => item.newAmount))
      : (sumArray = array.map((item) => item.amount));
    let total = parseFloat(
      sumArray.reduce((a, b) => {
        return (
          (isNaN(parseFloat(a)) ? 0 : parseFloat(a)) +
          (isNaN(parseFloat(b)) ? 0 : parseFloat(b))
        );
      }, 0)
    ).toFixed(2);
    return isNaN(total) ? 0 : total;
  };

  return (
    <>
      {permissionLevel !== PERMISSIONS_LEVELS.SITE_VERIFICATION &&
        permissionLevel !== PERMISSIONS_LEVELS.TITLE_VERIFICATION &&
        feeAdjustmentDetails ? (
        <Accordion
          className="my-3"
          style={{ border: '1px solid lightgray', borderRadius: '2px', width: "100%", padding: "none" }}
        >
          <Card style={{ height: '100%' }}>
            <Accordion.Toggle
              as={Card.Header}
              style={{ borderBottom: 'none', fontSize: '20px' }}
              eventKey="0"
            >
              Fee Adjustment Details
              <img
                style={{
                  transform: 'rotate(0deg)',
                }}
                src={require('../../assets/images/dd-arrow.svg')}
                alt=""
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {feeAdjustmentDetails && feeAdjustmentDetails[0] && (
                  <Row>
                    {feeAdjustmentDetails[0].cost_head_name !== undefined ? (
                      <Col>Cost Head Name</Col>
                    ) : null}
                    {feeAdjustmentDetails[0].amount !== undefined ? (
                      <Col>System Generated Fee (&#8377;)</Col>
                    ) : null}
                    {feeAdjustmentDetails[0].newAmount !== undefined ? (
                      <Col>Officer Computed Fee (&#8377;)</Col>
                    ) : null}
                    {feeAdjustmentDetails[0].remarks !== undefined ? (
                      <Col>Remarks</Col>
                    ) : null}
                  </Row>
                )}

                {feeAdjustmentDetails.map((item, id) => {
                  return (
                    <Row key={id}>
                      {item.cost_head_name !== undefined ? (
                        <Col className="detail-prop">{item.cost_head_name}</Col>
                      ) : null}
                      {item.amount !== undefined ? (
                        <Col className="detail-value">{item.amount}</Col>
                      ) : null}
                      {item.newAmount !== undefined ? (
                        <Col className="detail-value">{item.newAmount}</Col>
                      ) : null}
                      {item.remarks !== undefined ? (
                        <Col className="detail-value">
                          {item.remarks ? item.remarks : '---'}
                        </Col>
                      ) : null}
                    </Row>
                  );
                })}
                {subTotal && (
                  <>
                    <hr />
                    <Row>
                      <Col className="detail-prop">SubTotal</Col>
                      <Col className="detail-value">{subTotal.amount}</Col>
                      <Col className="detail-value">{subTotal.newAmount}</Col>
                      <Col className="detail-value">{""}</Col>
                    </Row>
                    <Row>
                      <Col className="detail-prop">  {Math.abs(subTotal.newAmount - subTotal.amount) <= 0 ?
                        "Total Amount Paid " :
                        "Total Amount to be paid"} </Col>
                      <Col className="detail-value">
                        {parseFloat(subTotal.newAmount) - parseFloat(subTotal.amount) <= 0 ? Math.abs(subTotal.newAmount - subTotal.amount).toFixed(2) : "---"}
                      </Col>
                      <Col className="detail-value">
                        {/* {Math.abs(subTotal.amount - subTotal.newAmount)} */}
                        {parseFloat(subTotal.newAmount) - parseFloat(subTotal.amount) > 0 ? Math.abs(subTotal.newAmount - subTotal.amount).toFixed(2) : "---"}
                      </Col>

                      <Col className="detail-prop" />
                    </Row>
                  </>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ) : null}
    </>
  );
};
FeeAdjustmentDetails.propTypes = {
  id: PropTypes.string,
};
export default FeeAdjustmentDetails;