import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Card, Form, Carousel, Button, Modal } from 'react-bootstrap';
import history from '../../services/history';
import { useParams } from 'react-router';
import '../VerificationCards/index.scss';
import { useMutation, useQuery } from '@apollo/client';
import {
  SAVE_ANSWERS_FORM,
  SUBMIT_FORM,
  SUBMIT_FINAL_REMARK_L2,
} from '../../graphql/mutatution/submitForm';
import Question from './Question/index';
import DeviationTable from '../DeviationTable';
import { AppContext } from './../../AppContext';
import { GET_OFFICERS_APPLICATION } from '../../graphql/query/officersApplication';
import { APPLICATION_COMMENTS } from '../../graphql/query/applicationComments';
import AnswerListComponent from '../AnswersListComponent';
import NotesModal from '../NotesModal';
import {
  PERMISSIONS_LEVELS,
  isStageRecommended,
} from '../../util/helperFunctions';
import MultiSelectDropdown from '../MultiSelectDropdown';
import { MultiSelect } from 'react-multi-select-component';
import ErrorScreen from '../ErrorScreen';

function QuestionCards({
  officersFormDataGrouped,
  formId,
  verificationType,

  applicationIdentifier,
  isVerification,
  showSidebarHandler,
  showSidebar,
  stageState,
  formData,
  ApprovalFor

}) {
  console.log('stageState', stageState)
  const [appIdentifier] = useState(applicationIdentifier || '');
  const { type, id } = useParams();
  const [carousalIndex, setCarousalIndex] = useState(0);
  const [remarksView, setRemarksView] = useState(true);
  const [submitForm, { called, errors }] = useMutation(SUBMIT_FORM);
  const [submitFormForL2, { calledL2, errorsL2 }] = useMutation(
    SUBMIT_FINAL_REMARK_L2
  );
  const [SaveFormAnswers, { caled, error }] = useMutation(SAVE_ANSWERS_FORM)
  const [formStatus, setFormStatus] = useState('');
  const [recommendationStatus, setRecommendationStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);
  const [applicationType, setApplicationType] = useState(null);
  const [currentOfficerLevel, setCurrentOfficerLevel] = useState(null);
  const [remarkValue, setRemarkValue] = useState('');
  const { permissionLevel, currentFlow, officerType } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showPopUpAfterSubmit, setShowPopUpAfterSubmit] = useState(false);
  const handleClosePopUpAfterSubmit = () => setShowPopUpAfterSubmit(false);
  const handleShowAfterSubmit = () => setShowPopUpAfterSubmit(true);
  const [recommendValidation, setRecommendValidation] = useState(false);
  const [showNocModal, setShowNocModal] = useState(false);
  const [isOccL2UpdatedTable, setIsOccL2UpdatedTable] = useState(false);
  const [showAnsers, SetShowAnswers] = useState(false);

  const handleClosePopUpAnswers = () => SetShowAnswers(false);
  const handleShowOpenPopup = () => SetShowAnswers(true);
  const {
    loading: loadingOfficerApplication,
    error: fetchApplicationError,
    data: applicationData,
  } = useQuery(GET_OFFICERS_APPLICATION, {
    fetchPolicy: 'cache-first',
    variables: { id: id },
  });
  const { data: applicationComments } = useQuery(APPLICATION_COMMENTS, {
    fetchPolicy: 'cache-first',
    variables: { id: id },
  });
  console.log('permissionLevel', permissionLevel)
  const [selected, setSelected] = useState([]);
  const [otherRemarks, setOtherRemarks] = useState('');
  const [NOCselected, setNOCselected] = useState([]);
  const [NOCoptions, setNOCoptions] = useState([]);
  const [NOCrequired, setNOCrequired] = useState(false);

  const NOCoptionsEmpty = () => {
    if (NOCrequired) {
      return NOCselected.length === 0;
    } else return false;
  };

  const handleNOCmodal = (options, val) => {
    if (val === 'true') {
      let updatedOptions = options.map((item, index) => {
        return {
          id: `${index + 1}`,
          label: item.value,
          value: item.value,
        };
      });
      setNOCrequired(true);
      setNOCoptions(updatedOptions);
      setShowNocModal(true);
    } else {
      setNOCrequired(false);
    }//  console.log("handlePreFilledDropdown",preFilledAnswers.fieldType)

  };

  useEffect(() => {
    if (fetchApplicationError) {
      console.log('failed to fetch application details');
    }
    setApplicationType(applicationData?.officersApplication?.applicationType);
    setCurrentOfficerLevel(
      applicationData?.officersApplication?.currentOfficerLevel
    );
  }, [applicationData, fetchApplicationError]);

  const handleSelect = (selectedIndex, e) => {
    setCarousalIndex(selectedIndex);
  };

  const isCurrentStageRecommended = () => {
    let currentStage;
    if (window.location.pathname.split('/').includes('site-verification')) {
      currentStage = 'SITE VERIFICATION';
    } else if (
      window.location.pathname.split('/').includes('title-verification')
    ) {
      currentStage = 'TITLE VERIFICATION';
    } else if (
      window.location.pathname.split('/').includes('technical-verification')
    ) {
      currentStage = 'TECHNICAL VERIFICATION';
    }
    if (!currentStage) return false;
    return isStageRecommended(currentStage, stageState, currentOfficerLevel);
  };

  const isRemarksAvailable = () => {
    let notRecommendedRemark = [...selected];
    if (NOCrequired) return true;

    if (formStatus === 'RECOMMENDED' && officerType === 'GHMC') return true;

    if (formStatus === 'NOT_RECOMMENDED') {
      if (selected.find((el) => el.label === 'Other')) {
        if (otherRemarks) {
          notRecommendedRemark = [
            ...selected.filter((item) => item.label !== 'Other'),
            { label: 'Other', value: otherRemarks },
          ].map((item) => item.value);
        } else {
          return false;
        }
      }
    }

    let finalRemark =
      formStatus === 'NOT_RECOMMENDED' ? notRecommendedRemark : remarkValue;

    let updatedFinalRemark =
      typeof finalRemark === 'string'
        ? finalRemark
        : JSON.stringify(finalRemark);

    if (updatedFinalRemark) {
      if (formStatus === 'NOT_RECOMMENDED') {
        return !(updatedFinalRemark === '[]');
      }
      return true; // remarks present
    }
    return false; // remarks empty
  };

  const redirectAfterSubmit = () => {
    if (
      [
        PERMISSIONS_LEVELS.SITE_VERIFICATION,
        PERMISSIONS_LEVELS.TITLE_VERIFICATION,
        PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION,
      ].includes(permissionLevel)
    ) {
      history.push('/post-verification');
    }
    if (window.location.pathname.split('/').includes('site-verification')) {
      isStageRecommended('TITLE VERIFICATION', stageState, currentOfficerLevel)
        ? isStageRecommended(
          'TECHNICAL VERIFICATION',
          stageState,
          currentOfficerLevel
        )
          ? history.push('/post-verification')
          : type && type == 'occ'
            ? history.push(`/technical-verification/occ/${id}`)
            : history.push(`/technical-verification/${id}`)
        : type && type == 'occ'
          ? history.push(`/technical-verification/occ/${id}`)
          : history.push(`/title-verification/${id}`);
      return;
    }

    if (window.location.pathname.split('/').includes('title-verification')) {
      isStageRecommended(
        'TECHNICAL VERIFICATION',
        stageState,
        currentOfficerLevel
      )
        ? isStageRecommended(
          'SITE VERIFICATION',
          stageState,
          currentOfficerLevel
        )
          ? history.push('/post-verification')
          : type && type == 'occ'
            ? history.push(`/site-verification/occ/${id}`)
            : history.push(`/site-verification/${id}`)
        : type && type == 'occ'
          ? history.push(`/technical-verification/occ/${id}`)
          : history.push(`/technical-verification/${id}`);
      return;
    }

    if (
      window.location.pathname.split('/').includes('technical-verification')
    ) {
      isStageRecommended('SITE VERIFICATION', stageState, currentOfficerLevel)
        ? isStageRecommended(
          'TITLE VERIFICATION',
          stageState,
          currentOfficerLevel
        )
          ? history.push('/post-verification')
          : type && type == 'occ'
            ? history.push(`/site-verification/occ/${id}`)
            : history.push(`/title-verification/${id}`)
        : type && type == 'occ'
          ? history.push(`/site-verification/occ/${id}`)
          : history.push(`/site-verification/${id}`);
    }
  };
  const OfficerId = window.sessionStorage.getItem('id');
  const submitRecommendationForm = () => {
    setIsLoading(true);
    let statusBeforeSubmittion = formStatus;

    let notRecommendedRemark = [
      ...selected.filter((item) => item.label !== 'Other'),
      { label: 'Other', value: otherRemarks },
    ].map((item) => item.value);

    let updatedFinalRemark =
      statusBeforeSubmittion === 'NOT_RECOMMENDED'
        ? notRecommendedRemark
        : remarkValue;

    if (
      (applicationType === 'OCCUPANCY' ||
        applicationType === 'SELF CERTIFICATION' ||
        applicationType === 'REGISTRATION') &&
      currentOfficerLevel === 'L1'
    ) {
      statusBeforeSubmittion = 'SUBMITTED';
      setFormStatus(statusBeforeSubmittion);
    }
    if (applicationType === 'SINGLE WINDOW' && currentOfficerLevel === 'L1') {
      statusBeforeSubmittion = 'SUBMITTED';
    }
    const data = ansState.current;
    let dataBeforeSubmittion = { ...data };
    console.log('before-submittion', dataBeforeSubmittion);
    if (remarkValue) {
      dataBeforeSubmittion.finalRemark = remarkValue;
    }
    const test = JSON.parse(JSON.stringify(dataBeforeSubmittion));

    if (dataBeforeSubmittion.requires_noc) {
      if (
        test.requires_noc.parent_answer === 'true' &&
        currentOfficerLevel === 'L1'
      ) {
        statusBeforeSubmittion = 'SUBMITTED';
      } else if (
        test.requires_noc.parent_answer === 'true' &&
        currentOfficerLevel !== 'L1'
      ) {
        statusBeforeSubmittion = 'RECOMMENDED';
      }
      let answer;
      if (dataBeforeSubmittion.requires_noc.parent_answer === 'true') {
        answer = NOCselected.map((el) => el.value);
      } else {
        answer = [];
      }

      answer = JSON.stringify(answer);
      dataBeforeSubmittion.requires_noc.parent_answer = answer;
    }

    // if (currentOfficerLevel === 'L2') {
    //   submitFormForL2({
    //     variables: {
    //       formInstanceId: formId,
    //       status: statusBeforeSubmittion,
    //       finalRemark:
    //         typeof updatedFinalRemark === 'string'
    //           ? updatedFinalRemark || 'N/A'
    //           : JSON.stringify(updatedFinalRemark),
    //     },
    //   })
    //     .then(() => {
    //       setIsLoading(false);
    //       if (!errorsL2 & !calledL2) {
    //         redirectAfterSubmit();
    //       }
    //     })
    //     .catch((e) => {
    //       setIsLoading(false);
    //       setErrMsg(e.message);
    //       console.error(e);
    //     });
    // } else {
    submitForm({
      variables: {
        formInstanceId: formId,
        data: dataBeforeSubmittion,
        officerId: OfficerId,
        status: statusBeforeSubmittion || 'RECOMMENDED',
        finalRemark:
          typeof updatedFinalRemark === 'string'
            ? updatedFinalRemark
            : JSON.stringify(updatedFinalRemark),
      },
    })
      .then(() => {
        setIsLoading(false);
        if (!errors & !called) {
          redirectAfterSubmit();
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setErrMsg(e.message);
        console.error(e);
      });
  }


  const handleSaveFormAnswers = () => {
    setIsLoading(true);
    let dataBeforeSubmittion = { ...ansState.current };
    const OfficerId = window.sessionStorage.getItem('id');
    SaveFormAnswers({
      variables: {
        formInstanceId: formId,
        data: dataBeforeSubmittion,
        status: 'RECOMMENDED',
        officerId: OfficerId,
        finalRemark: '',
      },
    })

      .then(() => {

        if (!error & !caled) {

          SetShowAnswers(true)

        }
      })
      .catch((e) => {
        setIsLoading(false);
        setErrMsg(e.message);
        console.error(e);
      });
  }
  const handleSaveFormAnswers1 = () => {

    let dataBeforeSubmittion = { ...ansState.current };
    const OfficerId = window.sessionStorage.getItem('id');
    SaveFormAnswers({
      variables: {
        formInstanceId: formId,
        data: dataBeforeSubmittion,
        status: 'RECOMMENDED',
        officerId: OfficerId,
        finalRemark: '',
      },
    })

      .then(() => {

        if (!error & !caled) {



        }
      })
      .catch((e) => {
        setIsLoading(false);
        setErrMsg(e.message);
        console.error(e);
      });
  }
  const officerCanRecommendOrNot = () => {
    return !(
      (applicationType === 'REGISTRATION' && currentOfficerLevel === 'L1') ||
      (applicationType === 'SELF CERTIFICATION' && currentOfficerLevel === 'L1')
    );
  };

  const NocModal = () => {
    return (
      <Modal show={showNocModal} onHide={() => setShowNocModal(false)} centered>
        <Modal.Header style={{ borderBottom: '0 none', paddingBottom: '0px' }}>
          <Modal.Title>NOC concerned departments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Please select the departments that will need NOC confirmation
          </div>
          <div>
            <MultiSelect
              options={NOCoptions}
              value={NOCselected}
              onChange={setNOCselected}
              labelledBy={'Select'}
            />
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: '0 none' }}
          className="d-flex justify-content-between"
        >
          <Button
            variant="secondary"
            onClick={() => {
              setShowNocModal(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={() => setShowNocModal(false)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ModalComponent = () => {
    return (
      <Modal
        show={showPopUpAfterSubmit}
        onHide={handleClosePopUpAfterSubmit}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: '0 none', paddingBottom: '0px' }}
        />
        <Modal.Body style={{ paddingTop: '0px' }}>
          <div className="px-2">
            {NOCrequired
              ? 'Are you sure you want to submit to NOC concerned departments'
              : officerCanRecommendOrNot()
                ? `Are you sure you want to  ${recommendationStatus &&
                  recommendationStatus === 'NOT_RECOMMENDED'
                  ? 'Not Recommend'
                  : 'Recommend'
                } and submit?`
                : `Are you sure you want to submit?`}
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: '0 none' }}
          className="d-flex justify-content-between"
        >
          <Button
            className="btn btn-secondary"
            onClick={() => handleClosePopUpAfterSubmit()}
            style={{ width: '30%' }}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-success"
            onClick={() => {
              submitRecommendationForm();
              handleClosePopUpAfterSubmit();
            }}
            style={{ width: '60%' }}
          >
            {NOCrequired
              ? 'Submit'
              : officerCanRecommendOrNot()
                ? recommendationStatus &&
                  recommendationStatus === 'NOT_RECOMMENDED'
                  ? 'Not Recommended'
                  : 'Recommended'
                : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const nextSlide = () => {
    // handleSaveFormAnswers1()
    let duplicateIndex = carousalIndex + 1;

    // handle the form submission
    const data = ansState.current;
    if (duplicateIndex == officersFormDataGrouped.length) {
      // const status = formStatus;
      var isValid = true;

      for (
        let cardIndex = 0;
        cardIndex < officersFormDataGrouped.length;
        cardIndex++
      ) {
        for (
          let relativeQIndex = 0;
          relativeQIndex < officersFormDataGrouped[cardIndex].length;
          relativeQIndex++
        ) {
          if (
            !(
              officersFormDataGrouped[cardIndex][relativeQIndex].dbField in data
            ) ||
            !data[officersFormDataGrouped[cardIndex][relativeQIndex].dbField]
              .parent_answer ||
            data[officersFormDataGrouped[cardIndex][relativeQIndex].dbField]
              .parent_answer === '' ||
            data[officersFormDataGrouped[cardIndex][relativeQIndex].dbField]
              .parent_answer === null
          ) {
            setIsSubmittedOnce(true);
            isValid = false;
            duplicateIndex = cardIndex;
            break;
          }
        }
        if (!isValid) break;
      }

      if (isValid) {
        duplicateIndex--;
        // submitRecommendationForm();
        handleShowAfterSubmit();
      }
    }
    return duplicateIndex % officersFormDataGrouped.length;
  };
  const prevSlide = () => {
    let duplicateIndex = carousalIndex - 1;
    if (duplicateIndex === -1) {
      duplicateIndex = officersFormDataGrouped.length - 1;
    }
    return duplicateIndex;
  };
  const handleRemarksViewToggle = () => {
    setRemarksView(!remarksView);
  };

  const handleApplicaitonDetails = () => {
    document.getElementsByTagName('body')[0].classList.remove('show-sidebar');
    document.getElementsByTagName('body')[0].classList.remove('Hide-RightSidebar');
    document.getElementsByTagName('body')[0].classList.toggle('show-RightSidebar');

  }

  const selectRecommend = () => {
    return (
      <div className="btn-recommend-notrecommend">
        <button
          style={{ fontSize: "13px" }}
          className={
            recommendationStatus === 'NOT_RECOMMENDED'
              ? 'btn btn-secondary'
              : 'btn btn-light btn-notrecmnd'
          }
          onClick={() => {
            setFormStatus('NOT_RECOMMENDED');
            setRecommendationStatus('NOT_RECOMMENDED');
            setRemarksView(true);
          }}
          disabled={isCurrentStageRecommended()}
        >
          {/* {recommendationStatus === 'NOT_RECOMMENDED' ?
            <img src={require('../../assets/images/successDarkGreen.svg')} /> : */}
          <img src={require('../../assets/images/successLightGreen.svg')} />
          NOT RECOMMEND{' '}
        </button>

        <button
          className={
            recommendationStatus === 'RECOMMENDED'
              ? 'btn btn-recmnd'
              : 'btn btn-light btn-notrecmnd'
          }
          onClick={() => {
            setFormStatus('RECOMMENDED');
            setRecommendationStatus('RECOMMENDED');
          }}
          disabled={isCurrentStageRecommended()}
        >
          {/* {recommendationStatus === 'RECOMMENDED' ?
            <img src={require('../../assets/images/successDarkGreen.svg')} /> : */}
          <img src={require('../../assets/images/successLightGreen.svg')} />
          RECOMMEND{' '}
        </button>
      </div>
    );
  };
  // below are new states

  // eslint-disable-next-line no-unused-vars
  const didOfficerNotComment = () => {
    if (
      !applicationComments ||
      applicationComments?.applicationComments.length === 0
    ) {
      return true; // officer did not comment
    }

    let isThisOfficerCommentPresent = false; // did not comment
    applicationComments.applicationComments.forEach((el) => {
      // eslint-disable-next-line no-undef
      if (el.officer.id === sessionStorage.getItem('id') && el.active == true) {
        isThisOfficerCommentPresent = true; // did commnet
      }
    });

    return !isThisOfficerCommentPresent;
  };

  const remarksAndSubmit = (carousalIndex, forAnswers = false) => {
    if (forAnswers) {
      console.log(carousalIndex, 'carousalIndex');

      return (
        <>
          <div>
            {remarksView ? (
              <div>
                {([
                  PERMISSIONS_LEVELS.COMMISSIONER,
                  PERMISSIONS_LEVELS.CP_OFFICER,
                  PERMISSIONS_LEVELS.ZONAL_COMMISSIONER,
                  PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER,
                ].includes(permissionLevel) ||
                  currentOfficerLevel === 'L2') && (
                    <>
                      {currentFlow === 'single-window' &&
                        ['ACP_OFFICER'].includes(permissionLevel) ? (
                        <> </>
                      ) : (
                        selectRecommend()
                      )}
                      <div className="border-btm">
                        <hr />
                        <span>Choose an action and proceed</span>
                      </div>
                      {formStatus === 'NOT_RECOMMENDED' ? (
                        <MultiSelectDropdown
                          selected={selected}
                          setSelected={setSelected}
                          otherRemarks={otherRemarks}
                          setOtherRemarks={setOtherRemarks}
                        />
                      ) : (
                        <div className="bottom-section1 2">
                          <Form>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label className="form-Label">
                                Final Remarks
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows="3"
                                placeholder="Text here"
                                value={remarkValue}
                                onChange={(event) =>
                                  setRemarkValue(event.target.value)
                                }
                              />
                            </Form.Group>
                          </Form>
                        </div>
                      )}
                    </>
                  )}
              </div>
            ) : (
              <div className="bottom-section1">
                {selectRecommend()}
                <div className="border-btm">
                  <hr />
                  <span>Choose an action and proceed</span>
                </div>
                <Form>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label className="form-Label">
                      Final Remarks
                    </Form.Label>
                    <img
                      type="button"
                      onClick={() => handleRemarksViewToggle()}
                      src={require('../../assets/images/close.svg')}
                    />
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder="Text here"
                      value={remarkValue}
                      onChange={(event) => setRemarkValue(event.target.value)}
                    />
                  </Form.Group>
                </Form>
              </div>
            )}
          </div>
          <div
            className="btn-container flex-column"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {!isLoading && (
              <>
                <div className="d-flex flex-column ml-auto">
                  <button
                    className={
                      carousalIndex === 0
                        ? 'btn btn-success btn-firstPage'
                        : 'btn btn-success'
                    }
                    onClick={() =>
                    //  submitRecommendationForm()
                    {
                      if (
                        officerCanRecommendOrNot() &&
                        !recommendationStatus
                      ) {
                        setRecommendValidation(true);
                      } else {
                        setRecommendValidation(false);
                        handleShowAfterSubmit();
                      }
                    }
                    }
                    // Removed temporarily

                    disabled={
                      didOfficerNotComment() ||
                        !isRemarksAvailable() ||
                        (applicationType === 'OCCUPANCY' &&
                          currentOfficerLevel === 'L2' &&
                          window.location.pathname
                            .split('/')
                            .includes('site-verification'))
                        ? !isOccL2UpdatedTable
                        : false
                    }
                  >
                    {'SUBMIT'}
                    <img
                      src={require('../../assets/images/arrow.svg')}
                      alt="next"
                    />
                  </button>
                </div>
                {/* Removed temporarily */}
                {recommendValidation ? (
                  <p style={{ color: 'red', fontSize: "12px" }}>
                    Please select recommend or not recommend
                  </p>
                ) : null}
                {didOfficerNotComment() ? (
                  <p style={{ color: 'red', fontSize: "12px" }}>
                    Please add remarks in View Notes
                  </p>
                ) : null}
              </>
            )}
            {isLoading && (
              <Button variant="secondary">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </Button>
            )}
            <span>{errMsg || null}</span>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            {carousalIndex === officersFormDataGrouped.length - 1 ? (
              <>
                {NOCrequired && NOCselected.length !== 0 && (
                  <div className="mt-5">
                    <div>NOC concerned departments:</div>
                    {NOCselected.map((el, index) => {
                      return <li key={index}>{el.value}</li>;
                    })}
                  </div>
                )}
                {NOCrequired ? null : remarksView ? (
                  <div>
                    {currentFlow === 'single-window' &&
                      ['ACP_OFFICER'].includes(permissionLevel) ? (
                      <> </>
                    ) : (
                      selectRecommend()
                    )}
                    <div className="border-btm">
                      <hr />
                      <span>Choose an action and proceed</span>
                    </div>
                    {formStatus === 'NOT_RECOMMENDED' && (
                      <MultiSelectDropdown
                        selected={selected}
                        setSelected={setSelected}
                        otherRemarks={otherRemarks}
                        setOtherRemarks={setOtherRemarks}
                      />
                    )}
                    {formStatus === 'RECOMMENDED' && (
                      <div className="bottom-section1">
                        <Form>
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label className="form-Label">
                              Final Remarks
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows="3"
                              placeholder="Text here"
                              value={remarkValue}
                              onChange={(event) =>
                                setRemarkValue(event.target.value)
                              }
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="bottom-section1">
                    {selectRecommend()}
                    <div className="border-btm">
                      <hr />
                      <span>Choose an action and proceed</span>
                    </div>
                    <Form>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label className="form-Label">
                          Final Remarks
                        </Form.Label>
                        <img
                          type="button"
                          onClick={() => handleRemarksViewToggle()}
                          src={require('../../assets/images/close.svg')}
                        />
                        <Form.Control
                          as="textarea"
                          rows="3"
                          placeholder="Text here"
                          value={remarkValue}
                          onChange={(event) =>
                            setRemarkValue(event.target.value)
                          }
                        />
                      </Form.Group>
                    </Form>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          <div
            className="btn-container"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <button
              className={carousalIndex === 0 ? 'd-none' : 'btn '}
              onClick={() => handleSelect(prevSlide())}
            >
              <img
                src={require('../../assets/images/arrowBackwards.svg')}
                alt="previous"
              />
              BACK
            </button>
            {!isLoading && (
              <>
                <div className="d-flex">
                  <button
                    style={{ width: "100px" }}
                    className='btn btn-success'
                    onClick={handleSaveFormAnswers}
                  >{"Save"}
                    <img
                      src={require('../../assets/images/arrow.svg')}
                      alt="next"
                    />
                  </button>
                  <button
                    style={{ width: "180px" }}
                    className={
                      carousalIndex === 0
                        ? 'btn btn-success btn-firstPage'
                        : 'btn btn-success '
                    }
                    disabled={
                      carousalIndex === officersFormDataGrouped.length - 1 &&
                      (didOfficerNotComment() ||
                        !isRemarksAvailable() ||
                        NOCoptionsEmpty())
                    }
                    onClick={() => {
                      if (
                        carousalIndex ===
                        officersFormDataGrouped.length - 1
                      ) {
                        if (NOCrequired) {
                          setRecommendValidation(false);
                        } else {
                          if (
                            officerCanRecommendOrNot() &&
                            !recommendationStatus
                          ) {
                            setRecommendValidation(true);
                            return;
                          } else {
                            setRecommendValidation(false);
                          }
                        }
                      }
                      handleSelect(nextSlide());
                    }}
                  >
                    {carousalIndex === officersFormDataGrouped.length - 1
                      ? 'SUBMIT'
                      : 'Save & Continue'}
                    <img
                      src={require('../../assets/images/arrow.svg')}
                      alt="next"
                    />
                  </button>
                </div>
                {/* Removed temporarily */}
                {recommendValidation ? (
                  <p style={{ color: 'red' }}>
                    Please select recommend or not recommend
                  </p>
                ) : null}
                {NOCoptionsEmpty() && (
                  <p style={{ color: 'red' }}>Please select NOC options</p>
                )}
                {carousalIndex === officersFormDataGrouped.length - 1 &&
                  didOfficerNotComment() ? (
                  <p style={{ color: 'red', display: 'block', position: "relative", top: "-30px" }}>
                    Please add remarks in View Notes
                  </p>
                ) : null}
              </>
            )}
            {isLoading && (
              <Button variant="secondary">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </Button>
            )}
            <span>{errMsg == 'You need to authenticate to perform this action' ? <ErrorScreen message={error} /> : "" || null}</span>

          </div>
        </>
      );
    }
  };

  const SaveAnsersPopUp = () => {
    return (
      <Modal
        show={showAnsers}
        onHide={handleClosePopUpAnswers}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: '0 none', paddingBottom: '0px' }}
        />
        <Modal.Body style={{ paddingTop: '0px' }}>
          <div className="px-2">your Answers are Saved Sucessfully !</div>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: '0 none' }}
          className="d-flex justify-content-between"
        >

          <Button
            className="btn btn-success"
            onClick={() => {
              window.location.reload()
            }}
            style={{ width: '60%' }}
          >
            ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const showCarousalQuestions = () => {
    return (
      <Carousel
        activeIndex={carousalIndex}
        controls={false}
        onSelect={handleSelect}
        interval={null}
      >
        {officersFormDataGrouped.map((cardData, cardIndex) => {
          return (
            <Carousel.Item key={cardIndex}>
              <Card key={cardIndex}>
                <Card.Body key={cardIndex}>
                  <div>
                    {cardData.map((question, relativeIndex) => {
                      var hideRequiredQuestion = false;
                      if (question.id == 4992) {
                        hideRequiredQuestion = true;
                      }
                      return (

                        <Question
                          question={question}
                          key={relativeIndex}
                          onChange={memoizedHandleAnswerChange}
                          validationReview={
                            hideRequiredQuestion ? false : isSubmittedOnce
                          }
                          preFilledAnswers={formData?.answer?.dataRevised}
                          handleNOCmodal={handleNOCmodal}
                          applicationType={applicationType}
                          ApprovalFor={ApprovalFor}
                        // applicationType={applicationType}
                        />


                      );
                    })}
                    {NocModal()}
                    {remarksAndSubmit(carousalIndex)}
                  </div>
                </Card.Body>
              </Card>
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  };

  const showAnswersAndSubmit = () => {
    let currentVerification = 'TITLE VERIFICATION';
    const currentUrlPath = history.location.pathname;
    if (currentUrlPath.includes('site')) {
      currentVerification = 'SITE VERIFICATION';
    } else if (currentUrlPath.includes('title')) {
      currentVerification = 'TITLE VERIFICATION';
    } else if (currentUrlPath.includes('technical')) {
      currentVerification = 'TECHNICAL VERIFICATION';
    }
    return (
      <>
        <AnswerListComponent
          id={id}
          currentVerification={currentVerification}
          isVerification={isVerification}
        />
        {applicationType === 'OCCUPANCY' &&
          currentOfficerLevel === 'L2' &&
          currentVerification === 'SITE VERIFICATION' && (
            <div style={{ width: "100%" }}>
              <DeviationTable
                question={{
                  order: 'L2',
                  title: 'Deviation Table',
                }}
                handleCurrentChange={handleCurrentChange}
              />
            </div>
          )}

        {remarksAndSubmit(officersFormDataGrouped.length - 1, true)}
      </>
    );
  };

  const ansState = useRef({});
  const handleChildAnswerChange = (dbField, value) => {
    ansState.current[dbField] = value;
  };

  const memoizedHandleAnswerChange = useCallback(handleChildAnswerChange, [
    ansState,
  ]);
  useEffect(() => {
    if (formData?.answer?.data) {
      officersFormDataGrouped.map((cardData) => {
        cardData.map((question) => {
          if (
            formData?.answer?.data.hasOwnProperty(question['dbField']) &&
            formData?.answer?.data[question['dbField']].parent_answer) {
            handleChildAnswerChange(question['dbField'], formData?.answer?.data[question['dbField']])
          }
        })
      })
    }
  }, []);

  const handleCurrentChange = () => {
    setIsOccL2UpdatedTable(true);
  };

  return (
    <>
      {ModalComponent()}
      {SaveAnsersPopUp()}
      <div className="main-content">
        <div className="tv-container">

          <div
            className={
              showSidebar ? 'tv-header sw-header' : 'tv-header-expanded'
            }
          >
            {/* <div></div> */}
            <ol className="breadcrumbs2">

              <li>
                <a href="#!">{appIdentifier}</a>
              </li>
            </ol>
            <ol className="breadcrumbs">
              {/* <li
                    className="active"
                    style={{ cursor: 'pointer', width: "140px" }}
                    onClick={(e) => {
                      history.push('/post verification');
                    }}
                  >
                    <a href="#!">Post Verification</a>
                  </li> */}
            </ol>

            <div style={{ display: 'flex' }}>

              {show ? <NotesModal handleClose={handleClose} /> : null}
              {showSidebar && (
                <div className=" d-flex align-items-center ">
                  <span className="addnote_label_text">
                    Add Note Sheet :
                  </span>
                  <div className=" d-flex add_note_wrapper mr-3 ">
                    {/* <div
                      className="viewButton"
                      type="button"
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      ADD
                    </div> */}
                    <div
                      className="addButton"
                      type="button"
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                     ADD   NOTE SHEET
                    </div>
                  </div>

                </div>
              )}
              <div onClick={handleApplicaitonDetails} className="sidebarToggle" style={{ marginLeft: "130px" }}
                type="button" >  <p className='application_Details_Button' > Application Details </p>
              </div>
            </div>

          </div>

          {[
            PERMISSIONS_LEVELS.TPSO_OFFICER,
            PERMISSIONS_LEVELS.CP_OFFICER,
            PERMISSIONS_LEVELS.ACP_OFFICER,
          ].includes(permissionLevel) && (
              <div className="d-flex justify-content-evenly mx-3 px-3 verification-nav-buttons">
                {isStageRecommended(
                  'SITE VERIFICATION',
                  stageState,
                  currentOfficerLevel
                ) ? (
                  <>
                    <button
                      style={{ cursor: 'pointer', color: '#1c9a5b' }}
                    // onClick={() => history.push(`/site-verification/${id}`)}
                    >
                      Site Verification
                      <img
                        style={{ padding: '2px' }}
                        src={require('../../assets/images/success.svg')}
                      />
                    </button>
                  </>
                ) : type && type == 'occ' ? (
                  <>
                    <button
                      onClick={() => history.push(`/site-verification/occ/${id}`)}
                      className={
                        window.location.pathname.split('/')[1] ===
                          'site-verification'
                          ? 'btn-thispage'
                          : ''
                      }
                    >
                      Site Verification
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => history.push(`/site-verification/${id}`)}
                      className={
                        window.location.pathname.split('/')[1] ===
                          'site-verification'
                          ? 'btn-thispage'
                          : ''
                      }
                    >
                      Site Verification
                    </button>
                  </>
                )}

                {type && type == 'occ' ? (
                  <></>
                ) : isStageRecommended(
                  'TITLE VERIFICATION',
                  stageState,
                  currentOfficerLevel
                ) ? (
                  <>
                    <button
                      style={{ cursor: 'pointer', color: '#1c9a5b' }}
                    // onClick={() => history.push(`/title-verification/${id}`)}
                    >
                      Title Verification
                      <img
                        style={{ padding: '2px' }}
                        src={require('../../assets/images/success.svg')}
                      />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => history.push(`/title-verification/${id}`)}
                      className={
                        window.location.pathname.split('/')[1] ===
                          'title-verification'
                          ? 'btn-thispage'
                          : ''
                      }
                    >
                      Title Verification
                    </button>
                  </>
                )}

                {isStageRecommended(
                  'TECHNICAL VERIFICATION',
                  stageState,
                  currentOfficerLevel
                ) ? (
                  <>
                    <button
                      style={{ cursor: 'pointer', color: '#1c9a5b' }}
                    // onClick={() =>
                    //   history.push(`/technical-verification/${id}`)
                    // }
                    >
                      Technical Verification
                      <img
                        style={{ padding: '2px' }}
                        src={require('../../assets/images/success.svg')}
                      />
                    </button>
                  </>
                ) : type && type == 'occ' ? (
                  <>
                    <button
                      onClick={() =>
                        history.push(`/technical-verification/occ/${id}`)
                      }
                      className={
                        window.location.pathname.split('/')[1] ===
                          'technical-verification'
                          ? 'btn-thispage'
                          : ''
                      }
                    >
                      Technical Verification
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() =>
                        history.push(`/technical-verification/${id}`)
                      }
                      className={
                        window.location.pathname.split('/')[1] ===
                          'technical-verification'
                          ? 'btn-thispage'
                          : ''
                      }
                    >
                      Technical Verification
                    </button>
                  </>
                )}
              </div>
            )}
          <div
            className={
              showSidebar ? 'tv-content tv-content-kd' : 'tv-content-expanded'
            }
          >
            <div style={{ float: 'right' }}>
              <ul className="stepper-dots">
                {officersFormDataGrouped.map((group, groupIndex) => (
                  <a href="#!" key={groupIndex}>
                    <li
                      className={carousalIndex === groupIndex ? 'active' : ''}
                      onClick={(e) => handleSelect(groupIndex, e)}
                    />
                  </a>
                ))}
              </ul>
            </div>
            <h6>{verificationType}</h6>
            {/* REMOVE THIS */}
            {currentFlow === 'single-window' &&
              permissionLevel === 'CP_OFFICER' && (
                <button
                  className="primary"
                  onClick={() => {
                    history.push('/fee-verification-review');
                  }}
                >
                  NEXT
                </button>
              )}
            {/* REMOVE THIS */}
            {(currentOfficerLevel === 'L2' || loadingOfficerApplication) &&
              officerType === 'GHMC'
              ? showAnswersAndSubmit()
              : showCarousalQuestions()}
          </div>
        </div>
      </div>
    </>
  );
}

QuestionCards.propTypes = {
  officersFormDataGrouped: PropTypes.array,
  formId: PropTypes.string,
  verificationType: PropTypes.string,
  applicationIdentifier: PropTypes.string,
  isVerification: PropTypes.string,
  showSidebar: PropTypes.bool,
  showSidebarHandler: PropTypes.func,
  stageState: PropTypes.any,
};

export default QuestionCards;

