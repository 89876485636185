import { gql } from '@apollo/client';

export const REPORTS = gql`
  query getReports(
    $type: ReportFilter!
    $ulbs: String!
    $startDate: String!
    $endDate: String!
  ) {
    reports(type: $type, ulbs: $ulbs, startDate: $startDate, endDate: $endDate)
  }
`;

export const REPORT_APPLICATIONS = gql`
  query getReportApplications(
    $applicationType: ApplicationTypes!
    $applicationStatus: ApplicationStatus
    $page: Int
    $perPage: Int
    $authorities: String
    $districts: String
    $circles: String
    $ulbs: String
    $startDate: String
    $endDate: String
  ) {
    reportApplications(
      applicationType: $applicationType
      applicationStatus: $applicationStatus
      page: $page
      perPage: $perPage
      authorities: $authorities
      districts: $districts
      circles: $circles
      ulbs: $ulbs
      startDate: $startDate
      endDate: $endDate
    ) {
      records {
        id
        property {
          location {
            ulbName
            district
            circle
            districtAdministrationArea
          }
        }
        applicationIdentifier
        status
        applicationType
        officersRemarks
        appliedOn
        createdAt
        updatedAt
        officersRemarks
      }
      totalCount
      totalPages
    }
  }
`;
