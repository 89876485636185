import React from 'react';
import ApplicationListLayout from '../components/ApplicationList';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

export default function ChasingStats() {
  return (
    <div>
      <Header />
      <Sidebar />
      <ApplicationListLayout />
    </div>
  );
}
