/* eslint-disable no-undef */
export const getAllLocalData = () => {
  return {
    accessToken: sessionStorage.getItem('accessToken'),
    firstName: sessionStorage.getItem('firstName'),
    lastName: sessionStorage.getItem('lastName'),
    id: sessionStorage.getItem('id'),
  };
};

export const removeAllLocalData = () => {
  sessionStorage.removeItem('accessToken');
  sessionStorage.removeItem('firstName');
  sessionStorage.removeItem('lastName');
  sessionStorage.removeItem('id');
  sessionStorage.removeItem('currentFlow');
};

export const setAllLocalData = (data) => {
  sessionStorage.setItem('accessToken', data.accessToken);
  sessionStorage.setItem('firstName', data.firstName);
  sessionStorage.setItem('lastName', data.lastName);
  sessionStorage.setItem('id', data.id);
};
