import React, { useState } from 'react';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import './index.scss';




function AddConditionsModal(props) {
  const [conditions, setConditions] = useState("")

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="addConditionsModal"
    >

      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.conditionsShow ? <div className="d-flex flex-column mb-5 conditions-section">
          <h4 className="conditions-section-title mb-4 ">Verify once before going to add</h4>
          {conditions && <div className="d-flex flex-row justify-content-between mb-4">
            <h4><img className="pr-2 pb-2" src={require('../../assets/images/tick_1.svg')} />{conditions} </h4>
            <span onClick={() => setConditions('')} className="remove-text">remove</span>
          </div>}
          {/* <div className="d-flex flex-row justify-content-between mb-4">
            <h4><img className="pr-2 pb-2" src={require('../../assets/images/tick_1.svg')} />On Submission al Contractor's All Risk Policy for (6) V.rs. X</h4>
            <span className="remove-text" >remove</span>
          </div> */}
          <div className="d-flex flex-row  mb-4">
            <Button onClick={() => props.setModalShow(false)} >
              DONE
              <img src={require('../../assets/images/arrow.svg')} />
            </Button>
            <Button variant="outline" className="cancel-btn" onClick={() => props.setConditionsShow(false)}>CANCEL</Button>
          </div>
        </div> : null}
        <Form>
          <h4 className="modal-title">Add your Own Condition</h4>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Control onChange={(e) => setConditions(e.target.value)} as="textarea" rows="5" placeholder="Enter your conditions here" />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.setConditionsShow(true)} >
          <img src={require('../../assets/images/arrow.svg')} />
          ADD
 </Button>
        {!props.conditionsShow ? <Button variant="outline" className="cancel-btn" onClick={() => props.setModalShow(false)}>CANCEL</Button> : null
        }
      </Modal.Footer>
    </Modal>
  );
}


export default function PreProceedingTableView() {
  const [modalShow, setModalShow] = React.useState(false);
  const [conditionsShow, setConditionsShow] = React.useState(false);
  const [notesTab, setNotesTab] = useState(true);
  const handleRemarksViewToggle = () => {
    setNotesTab(!notesTab);
  };

  const onSetConditions = (e) => {
    console.log(e)
  }

  const generalConditionsData = [
    {
      generalCondition:
        'On submission of registered agreement between Builder Owner X',
    },
    {
      generalCondition:
        "On Submission al Contractor's All Risk Policy for (6) V.rs. X",
    },
    {
      generalCondition:
        'The Applicant shall pay DC, PC and other ether charges X',
    },
    {
      generalCondition:
        'The applicant shall comply Me conditions laid dawn in the G.O.Ms.No.168 dt 7-4- 2012 and NBC.',
    },
    {
      generalCondition:
        'The applicant shall foam BT road before rel.se of plans from OHNC. X',
    },
    {
      generalCondition:
        'The applicant shall follow the conditions mentioned in Rc. No. 6537/MSB/CR/MD1013 dt. O5 O8 12',
    },
    {
      generalCondition:
        'The applicant shall holdover 1096 of the built up area in the ground floor or first floor or the second floor, as the case may be to the sanctioning authority by the way of notarized affidavit ENTERED WITH REGISTRATION DEPARTMENT as per Fees Intimation Proceeding Drawing',
    },
  ];
  const specialConditionsData = [
    {
      specialCondition:
        'On submission of terms and service between Builder Owner X',
    },
    {
      specialCondition:
        "On submission of contractor's All risk policy for(6) Vs X",
    },
    {
      specialCondition:
        "On submission of contractor's All risk policy for(6) Vs X",
    },
    {
      specialCondition:
        "On submission of contractor's All risk policy for(6) Vs X",
    },
  ];
  const data = () => {
    var totalAmount = 0;
    console.log('totalAmount', totalAmount);

    return (
      <div className="container">
        <AddConditionsModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          setModalShow={setModalShow}
          setConditionsShow={setConditionsShow}
          conditionsShow={conditionsShow}
        />
        <div className="container1">
          <div className="container2">
            <Table className="no-border Table ">
              <thead className="thead">
                <tr>
                  <td colSpan="2" className="table-title">
                    <b>Pre Proceeding Conditions For</b> &nbsp; Application:
                    1417/ALAI/0005/2020
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="general-conditions" align="start" colSpan="3">
                    General Conditions
                  </td>
                </tr>
                {generalConditionsData.map((condition, index) => (
                  <tr key={index}>
                    <td className="special-conditions">
                      <input type="checkbox" />
                    </td>
                    <td className="border-none">
                      {condition.generalCondition}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="2" className="border-grey" />
                </tr>
                <tr>
                  <td align="start" colSpan="3" className="general-conditions">
                    Special Conditions
                  </td>
                </tr>
                {specialConditionsData.map((condition, index) => (
                  <tr key={index}>
                    <td className="special-conditions">
                      <input type="checkbox" />
                    </td>
                    <td className="border-none">
                      {condition.specialCondition}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {notesTab ? (
              <div className="bottom-section">
                <div onClick={() => setModalShow(true)}>
                  <h6 type="button"
                  //  onClick={() => handleRemarksViewToggle()}

                  >
                    Add Own conditions
                  </h6>
                  <span>Click to add your conditions</span>
                </div>
                <Button variant="success" style={{ cursor: 'pointer' }}>
                  Submit
                </Button>
              </div>
            ) : (
                <div className="bottom-section1">
                  <Form>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label className="form-Label">Add Notes</Form.Label>
                      <img
                        type="button"
                        onClick={() => handleRemarksViewToggle()}
                        src={require('../../assets/images/close.svg')}
                      />
                      <Form.Control
                        as="textarea"
                        rows="3"
                        placeholder="Text here"
                      />
                    </Form.Group>
                  </Form>
                  <Button variant="success" style={{ cursor: 'pointer' }}>
                    Submit
                </Button>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  };
  return data();
}
