import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function PasswordSuccess() {
  return (
    <div className="pwd-success-container">
      <Container>
        <div className="pwd-success-content">
          <img alt="" src={require('../assets/images/tick.svg')} />
          <br />
          <br />
          <strong>Successfully Changed</strong>
          <p className="fs-12">Your password has been changed successfully</p>
          <p className="fs-12">Now you can login with new password</p>
          <br />
          <Link to="/login">
            <Button
              variant="primary"
              className="d-flex align-items-center justify-content-around"
            >
              <span>LOGIN</span>
              <img src={require('../assets/images/arrow.svg')} alt="next" />
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
}
