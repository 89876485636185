import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import LoadingScreen from '../components/LoadingScreen';
import ErrorScreen from '../components/ErrorScreen';
import { useQuery } from '@apollo/client';
import { GET_OFFICERS_APPLICATION } from '../graphql/query/officersApplication';
import RightSideBar from '../components/RightSideBar';
import ReviewCard from '../components/ReviewCards';

const CreateRemarks = () => {
  const { loading, error, data, refetch } = useQuery(GET_OFFICERS_APPLICATION, {
    variables: { id: '206.244.40.116' },
    notifyOnNetworkStatusChange: true,
  });
  return (
    <>
      <Header />
      <Sidebar />
      <ReviewCard />
      <div className="main-content">
        {loading && <LoadingScreen />}
        {error && <ErrorScreen message={error} retry={refetch} />}
        {data && (
          <div className="main-content">
            <RightSideBar data={data.officersApplication} />
          </div>
        )}
      </div>
    </>
  );
};

export default CreateRemarks;
