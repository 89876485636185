/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import ViewMap from '../ApplicationDetailComponent/view-map';

const PlotView = ({ appData, plotData, geoCoordinates }) => {
  const [modalShow, setModalShow] = useState(false);

  const convertToSqYards = (plotArea, val) => {
    return plotArea;
  };

  return (
    <>
      <div className="plot-deatils-form">
        {plotData && Object.keys(plotData).length > 0 ? (
          <Table responsive className="review-table">
            <tbody>
              <tr>
                <td>Plot Address</td>
                <td>{plotData.plot_address}</td>
              </tr>
              <tr>
                <td>Geo-Coordinates</td>
                <td>
                  {plotData.geo_coordinates}
                  {/* {geoCoordinates.length &&
                                    <ul>
                                        {geoCoordinates.map((coordinates, index) => {
                                            return (
                                                <li style={{ listStyle: 'disc', }} key={index}>{`Latitude: ${coordinates.lat}, Longitude: ${coordinates.lng}`}</li>
                                            );
                                        })}
                                    </ul>
                                    } */}
                  <Link href="#!">
                    <a onClick={() => setModalShow(true)}> View Map </a>
                  </Link>
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <h3>Plot Address Not found</h3>
        )}
      </div>
      <div className="plot-deatils-form">
        <h5>Plot registration details</h5>
        <Table responsive className="review-table">
          <tbody>
            <tr>
              <td>{appData.plot_land_type}</td>
              <td>
                {plotData.plot_land_value ? plotData.plot_land_value : 'N/A'}
              </td>
            </tr>
            <tr>
              <td>Plot Area As per document</td>
              <td>{plotData.plot_area_as_per_document_display}</td>
            </tr>
            <tr>
              <td>Plot Area as on Ground</td>
              <td>{plotData.plot_area_as_per_ground_display}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      {plotData.location_type && (
        <div className="plot-deatils-form">
          <h5>Master Plan</h5>
          <Table responsive className="review-table">
            <tbody>
              <tr>
                <td>Location Type</td>
                <td>
                  {plotData.location_type ? plotData.location_type : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Location Sub Type</td>
                <td>
                  {plotData.location_sub_type
                    ? plotData.location_sub_type
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Plot Area As per document</td>
                <td>
                  {plotData.plot_area_as_per_document
                    ? plotData.plot_area_type === 'Sq. Yards'
                      ? convertToSqYards(
                        plotData.plot_area_as_per_document,
                        2
                      ) + ' Sq. Yards'
                      : plotData.plot_area_as_per_document +
                      ' ' +
                      plotData.plot_area_type
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Plot Area as on Ground</td>
                <td>
                  {plotData.plot_area_as_per_ground
                    ? plotData.plot_area_type === 'Sq. Yards'
                      ? convertToSqYards(plotData.plot_area_as_per_ground, 2) +
                      ' Sq. Yards'
                      : plotData.plot_area_as_per_ground +
                      ' ' +
                      plotData.plot_area_type
                    : 'N/A'}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
      <div className="plot-deatils-form">
        <Table responsive className="review-table">
          <tbody>
            <h5>Plot Checklist </h5>
            {plotData &&
              plotData.plot_answers &&
              plotData.plot_answers.length > 0 &&
              plotData.plot_answers.map((document, i) => {
                if (document.answer) {
                  return (
                    <tr key={`plot-document-${i}`}>
                      <td>{document.question}</td>
                      <td>
                        {document.answer === 'FALSE'
                          ? 'NO'
                          : document.answer === 'TRUE'
                            ? 'YES'
                            : document.answer.substr(0, 35)}
                      </td>
                    </tr>
                  );
                }
              })}

            {plotData.is_plot_part_of &&
              plotData.is_plot_part_of.length > 0 &&
              plotData.is_plot_part_of.map((part, i) => {
                if (part && part.answer) {
                  return (
                    <tr key={`plot-part-of-${i}`}>
                      <td>{part.question}</td>
                      <td>
                        {part.answer.length > 50 ? (
                          <a
                            href={part.answer}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <ul>{part.answer.substr(0, 35)}...</ul>
                          </a>
                        ) : (
                          part.answer
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            {plotData.slum && (
              <tr>
                <td>
                  Whether your plot falls under any of the notified slum areas ?
                </td>
                <td>{plotData.slum}</td>
              </tr>
            )}
            {plotData.application_type !== 'REG' &&
              plotData.application_type !== 'SW' && (
                <>
                  {plotData.sro_location && (
                    <tr>
                      <td>SRO at</td>
                      <td>{plotData.sro_location}</td>
                    </tr>
                  )}
                  {plotData.market_value && (
                    <tr>
                      <td>Market Value</td>
                      <td>{plotData.market_value}</td>
                    </tr>
                  )}
                </>
              )}
          </tbody>
        </Table>
      </div>

      {geoCoordinates && geoCoordinates.length > 0 && (
        <ViewMap
          show={modalShow}
          onHide={() => setModalShow(false)}
          coordinates={geoCoordinates}
        />
      )}
    </>
  );
};

export default PlotView;
