import { gql } from '@apollo/client';

export const GET_APPLICATION_OFFICER_LEVEL = gql`
  query OfficersApplicationLevel($id: ID!) {
    officersApplication(id: $id) {
      currentOfficerLevel
    }
  }
`;

export const GET_OFFICERS_APPLICATION = gql`
query OfficersApplication($id: ID!) {
  officersApplication(id: $id) {
    id
    applicantListId
    applicationType
    applicationIdentifier
    isShowCauseRaisedDocUpload
    isShowCauseRaised
    newWorkFlow
    isShowCauseSevenDaysElapsed
    feeIsSubmitted
    isFeeSubmitted
    internalStatus
    isFeeChangeDetect
    isFeeChanged
    isObjected
    isDeviationSubmitted
    proceedingIsSubmitted
    isDeviationUpdated
    finalRemark
    createdAt
    approvalFor
    appliedOn
    paymentCompletedAt
    currentOfficerLevel
    status
    answers {
      text
      question
    }
    stageStates {
      name
      eligibleStatus
      status
      dueDate
      formInstances{
          id
          title
          formStatus
          updatedAt
      }
      receivedDate
      submittedDate
      dueDays
      completedDate
      stageOfficers {
        action
        forwardedDate
        
        id
        officerLevel
        officerName
        officerRole
      }
    }
    applicant {
      applicantName
      aadhaarNumber
      contactAddress
    }
    property {
      plotAddress
      geoCoordinates
      marketValue
      rearPlotWidth
      rearRoadWidth
      side1PlotWidth
      side1RoadWidth
      side2PlotWidth
      side2RoadWidth
      frontPlotWidth
      frontRoadWidth
      actualPlotArea
      plotAreaAsPerDocument
      # eastRoadWidth
    }
    documents {
      category
      s3FilePath
    }
    # survey number
  }
}`;
