import React from 'react';
import PropTypes from 'prop-types';
import history from './../../services/history';

function SubHeader(props) {
  return (
    <>
      <hr />
      <div
        className="container"
        style={{ marginTop: '40px', display: 'flex', height: '13px' }}
      >
        <ul style={{ display: 'flex' }}>
          <li style={{ color: '#84c7a6', cursor: 'pointer' }}>
            {props.level1}
          </li>
          {props.level2 && <li>&nbsp; &gt; &nbsp;</li>}
          {props.level2 && <li style={{ color: '#84c7a6' }}>{props.level2}</li>}
          {props.level3 && <li>&nbsp; &gt; &nbsp;</li>}
          {props.level3 && (
            <>
              <li
                style={{
                  color: 'lightgray',
                  fontWeight: 'normal',
                }}
              >
                {props.level3}
              </li>
              <li>&nbsp; &gt; &nbsp;</li>
              <li>{history.location.state?.applicationIdentifier}</li>
            </>
          )}
        </ul>
        <ul
          style={{
            display: 'flex',
            position: 'absolute',
            right: '180px',
            cursor: 'pointer',
          }}
          onClick={() => history.goBack()}
        >
          <li style={{ paddingRight: '10px' }}>&lt;-</li>
          <li>BACK</li>
        </ul>
      </div>
      <hr style={{ marginBottom: '0px' }} />
    </>
  );
}

SubHeader.propTypes = {
  level1: PropTypes.string,
  level2: PropTypes.string,
  level3: PropTypes.string,
};

export default SubHeader;
