import React from 'react';
import './index.scss';
import { useParams } from 'react-router';
import AnswerListComponent from '../AnswersListComponent';
import { GET_OFFICERS_APPLICATION } from '../../graphql/query/officersApplication';
import { useQuery } from '@apollo/client';
import IAIRAnswerList from '../AnswersListComponent/IAIRReviewRemarks';

export default function ReviewRemarks(props) {
  const { id } = useParams();


  return (
    <div
      className="rev-container"
      // style={{ boxShadow: '0px 2px 9px 3px lightgrey' }}
    >
      <div className="d-flex flex-column">
        {/* <span className="title">Submitted Remarks form</span>
        <span className="sub-title">
          Title, Technical and Site Verification departments
        </span> */}
        <br />
      </div>
 <IAIRAnswerList id={id} {...props}/>

      {/* <hr />
      <Accordion>
        <Card>
          <Card.Header className="pr-0">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              className="d-flex justify-content-between pr-0 w-100"
            >
              <span className="title">Title Officer Remarks</span>
              <img
                style={{
                  transform: 'rotate(0deg)',
                }}
                src={require('../../assets/images/dd-arrow.svg')}
                alt=""
              />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="d-flex flex-column">
              <span className="question">
                Whether the land use as per notified master plan for the
                proposed plot?
              </span>
              <span className="remark">Remark</span>
              <span className="answer">
                Master plan document for the proposed plot is incorrect, please
                resubmit it once again and check once before submission
              </span>
              <br />
              <span className="question">
                Whether the land use as per notified master plan for the
                proposed plot?
              </span>
              <span className="remark">Remark</span>
              <span className="answer">
                Master plan document for the proposed plot is incorrect, please
                resubmit it once again and check once before submission
              </span>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <hr />

      <Accordion>
        <Card>
          <Card.Header className="pr-0">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              className="d-flex justify-content-between pr-0 w-100"
            >
              <span className="title">Technical Officer Remarks</span>
              <img
                style={{
                  transform: 'rotate(0deg)',
                }}
                src={require('../../assets/images/dd-arrow.svg')}
                alt=""
              />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="d-flex flex-column">
              <span className="question">
                Whether the land use as per notified master plan for the
                proposed plot?
              </span>
              <span className="remark">Remark</span>
              <span className="answer">
                Master plan document for the proposed plot is incorrect, please
                resubmit it once again and check once before submission
              </span>
              <br />
              <br />
              <span className="question">
                Whether the land use as per notified master plan for the
                proposed plot?
              </span>
              <span className="remark">Remark</span>
              <span className="answer">
                Master plan document for the proposed plot is incorrect, please
                resubmit it once again and check once before submission
              </span>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion> */}
    </div>
  );
}
