import React, {
    useState,
    useRef,
    useCallback,
    useContext,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Card, Form, Carousel, Button, Modal } from 'react-bootstrap';
import history from '../../services/history';
import { useParams } from 'react-router';
import '../VerificationCards/index.scss';
import { useMutation, useQuery } from '@apollo/client';
import {
    SUBMIT_FINAL_REMARK_L2,
    SUBMIT_FORM,
    SUBMIT_FORM_SW,
} from '../../graphql/mutatution/submitForm';
import { GET_RECOMMENDATION_BUTTONS } from '../../graphql/query/getRecommendations'
import { UPDATE_APPLICATION_STATUS } from '../../graphql/mutatution/updateApplicationStatus';
import { AppContext } from './../../AppContext';
import { GET_OFFICERS_APPLICATION } from '../../graphql/query/officersApplication';
import { APPLICATION_COMMENTS } from '../../graphql/query/applicationComments';
import AnswerListComponent from '../AnswersListComponent';
import ScrutinyTableView from '../../components/ScrutinyTableView/new_IAFee';
import { GET_OFFICER_PROFILE } from '../../graphql/query/officerProfile';
import NotesModal from '../NotesModal';
import { toast } from 'react-toastify';
import {
    isStageRecommended,
} from '../../util/helperFunctions';
import MultiSelectDropdown from '../MultiSelectDropdown';

// import CarousalQuestions from '../QuestionCards/carousalQuestions';
import { Field } from 'formik';
import FeeConditions from '../FeeCondtions/feeConditions';
import ProceedingCondtions from "../Proceeding Condtions/ProceedingCondtions"
import { APPLICAITON_CONDTIONS } from '../../graphql/query/FeeCondtions';
import IAIRCarosualQuestions from './IAIRCarosualQuestions';
import MultiSelectDropdown1 from '../MultiSelectDropdown/ShowCause';
import DeviationTable from '../DeviationTable';

function IAIRCards({
    officersFormDataGrouped,
    formId,
    recomID,
    applicationIdentifier,
    isVerification,
    showSidebarHandler,
    showSidebar,
    stageState,
    isFormSubmitOrNot,
    formData,
    status
}) {

    // console.log(status,"checking");
    // console.log(officersFormDataGrouped.filter(item=>item.title === "Title form").map(item=>item.id),"Question-cards")
    //answer[field.dbField]
    const [appIdentifier] = useState(applicationIdentifier || '');
    const { type, id } = useParams();
    const [carousalIndex, setCarousalIndex] = useState(0);
    const [remarksView, setRemarksView] = useState(true);
    const [UpdateFinalSubmit, { called, errors }] =
        useMutation(UPDATE_APPLICATION_STATUS);
    // const [updateApplicationStatus, { called3, errors3 }] = useMutation(
    //   UPDATE_APPLICATION_STATUS
    // );
    const [submitFormForL2, { calledL2, errorsL2 }] = useMutation(
        SUBMIT_FINAL_REMARK_L2
    );

    const [submitFormAnswers, { caled, erors }] =
        useMutation(SUBMIT_FORM);

    const { data: recommendationsButtons } = useQuery(GET_RECOMMENDATION_BUTTONS, {
        fetchPolicy: 'cache-first',
        variables: { id: id, formInstanceId: recomID, },
    });

    // console.log('responsegetRecommendationsButtons', recommendationsButtons)
    // const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [formStatus, setFormStatus] = useState('');
    const [recommendationStatus, setRecommendationStatus] =
        useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);
    const [applicationType, setApplicationType] = useState(null);
    const [ApprovalFor, setIsOccupancy] = useState(null)
    const [currentOfficerLevel, setCurrentOfficerLevel] = useState(null);
    const [remarkValue, setRemarkValue] = useState('');
    const { permissionLevel, currentFlow, officerType } = useContext(AppContext);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [showPopUpAfterSubmit, setShowPopUpAfterSubmit] = useState(false);
    const handleClosePopUpAfterSubmit = () => setShowPopUpAfterSubmit(false);
    const handleShowAfterSubmit = () => setShowPopUpAfterSubmit(true);
    const [recommendValidation, setRecommendValidation] = useState(false);
    const [showNocModal, setShowNocModal] = useState(false);
    const [sucessIcon, setSucessIcon] = useState(false)
    const [isOccL2UpdatedTable, setIsOccL2UpdatedTable] = useState(false);
    const [isFeeCondtionsSubmit, setIsFeeConditionsSubmit] = useState('')
    const [isHovered, setIsHovered] = useState(false);
    const {
        loading: loadingOfficerApplication,
        error: fetchApplicationError,
        data: applicationData,
    } = useQuery(GET_OFFICERS_APPLICATION, {
        fetchPolicy: 'cache-first',
        variables: { id: id },
    });
    const [showFeeDetect, setShowFeeDetect] = useState(false)
    const handleCloseFeeDetect = () => {
        setShowFeeDetect(false)
    }
    // console.log(applicationData.stageStates ,'eligibleStatus')
    // console.log(applicationData?.officersApplication?.newWorkFlow, 'applicationData')

    // const { data: AdditionalCondtions, loading } = useQuery(APPLICAITON_CONDTIONS, {
    //   // fetchPolicy: 'cache-first',
    //   variables: {
    //     applicationId: id,
    //     typeOf: "fee"
    //   }
    // })


    // console.log(applicationData?.officersApplication?.feeIsSubmitted,'applicationData')


    const { data: applicationComments } = useQuery(APPLICATION_COMMENTS, {
        fetchPolicy: 'network-only',
        variables: { id: id },
    });
    const { data: officerData } = useQuery(GET_OFFICER_PROFILE);

    const [selected, setSelected] = useState([]);
    const [otherRemarks, setOtherRemarks] = useState('');
    const [NOCselected, setNOCselected] = useState([]);
    const [NOCoptions, setNOCoptions] = useState([]);
    const [NOCrequired, setNOCrequired] = useState(false);
    const [isSubmit, setIsSubmit] = useState(true)


    useEffect(() => {
        if (fetchApplicationError) {
        }
        setApplicationType(applicationData?.officersApplication?.applicationType);
        setCurrentOfficerLevel(
            applicationData?.officersApplication?.currentOfficerLevel
        );
        setIsOccupancy(applicationData?.officersApplication?.approvalFor)
    }, [applicationData, fetchApplicationError]);


    // function checkingFeeSubmit(bool) {tio
    //  if( officerData.me?.actions?.includes('fee')){
    //     return bool;
    //  }else {
    //   return false
    //  }
    //}
    // console.log(officersFormDataGrouped?.filter(item => item.title === "Site form").map(item => item?.answer?.data)[0], 'formId')
    function isQuestionSubmited() {
        //  let  isQuestionSubmited =    officersFormDataGrouped.filter((questions, i) =>   questions.answer.action == "view"
        //     )
        // console.log(isQuestionSubmited)
    }


    const isCurrentStageRecommended = () => {
        let currentStage;
        if (window.location.pathname.split('/').includes('site-verification')) {
            currentStage = 'SITE VERIFICATION';
        } else if (
            window.location.pathname.split('/').includes('title-verification')
        ) {
            currentStage = 'TITLE VERIFICATION';
        } else if (
            window.location.pathname.split('/').includes('technical-verification')
        ) {
            currentStage = 'TECHNICAL VERIFICATION';
        } else if (
            window.location.pathname.split('/').includes('iair-CommisionerVerify')
        ) {
            currentStage = 'TECHNICAL VERIFICATION';
        }
        if (!currentStage && recommendationStatus != "") return false;
        return isStageRecommended(currentStage, stageState, currentOfficerLevel);
    };

    const isRemarksAvailable = () => {
        // debugger
        let notRecommendedRemark = [...selected];
        if (NOCrequired) return true;

        // if ((formStatus === 'RECOMMENDED' || formStatus === 'NOT_RECOMMENDED_WITH_REFUND' || formStatus === 'RECOMMENDED' || formStatus == "SHOWCAUSE") && officerType === 'GHMC') return true;

        if (formStatus === 'NOT_RECOMMENDED' || formStatus === 'NOT_RECOMMENDED_WITH_REFUND') {
            if (selected.find((el) => el.label === 'Other')) {
                if (otherRemarks) {
                    notRecommendedRemark = [
                        ...selected.filter((item) => item.label !== 'Other'),
                        { label: 'Other', value: otherRemarks },
                    ].map((item) => item.value);
                } else {
                    return false;
                }
            }
        }

        let finalRemark =
            (formStatus === 'NOT_RECOMMENDED' || formStatus === 'NOT_RECOMMENDED_WITH_REFUND') ? notRecommendedRemark : remarkValue;

        let updatedFinalRemark =
            typeof finalRemark === 'string'
                ? finalRemark
                : JSON.stringify(finalRemark);

        if (updatedFinalRemark) {
            if (formStatus === 'NOT_RECOMMENDED' || formStatus === 'NOT_RECOMMENDED_WITH_REFUND' || formStatus === "SHOWCAUSE") {
                return !(updatedFinalRemark === '[]');
            }
            return true; // remarks present
        }
        return false; // remarks empty
    };


    function hasSpecialCharacters(text) {
        const specialCharactersRegex = /[^a-zA-Z0-9.,'"\s]/g;
        return specialCharactersRegex.test(text);
      }
    const submitRecommendationForm = () => {
        setIsLoading(true);
        // //debugger;

        let statusBeforeSubmittion = formStatus;

        let notRecommendedRemark = [
            ...selected.filter((item) => item.label !== 'Other'),
            { label: 'Other', value: otherRemarks },
        ].map((item) => item.value);

        let updatedFinalRemark =
            statusBeforeSubmittion === 'NOT_RECOMMENDED' || formStatus === 'NOT_RECOMMENDED_WITH_REFUND'
                ? notRecommendedRemark
                : remarkValue;
                console.log(typeof (updatedFinalRemark),'updatedFinalRemark')
        if (
            (applicationType === 'OCCUPANCY' ||
                applicationType === 'SELF CERTIFICATION' ||
                applicationType === 'REGISTRATION') &&
            currentOfficerLevel === 'L1'
        ) {
            statusBeforeSubmittion = 'SUBMITTED';
            setFormStatus(statusBeforeSubmittion);
        }
        if (applicationType === 'SINGLE WINDOW' && currentOfficerLevel === 'L1') {
            statusBeforeSubmittion = formStatus;
        }
        const data = ansState.current;
        let dataBeforeSubmittion = { ...data };
        if (remarkValue) {
            dataBeforeSubmittion.finalRemark = remarkValue;
        }
        const test = JSON.parse(JSON.stringify(dataBeforeSubmittion));

        if (dataBeforeSubmittion.requires_noc) {
            if (
                test.requires_noc.parent_answer === 'true' &&
                currentOfficerLevel === 'L1'
            ) {
                statusBeforeSubmittion = 'SUBMITTED';
            } else if (
                test.requires_noc.parent_answer === 'true' &&
                currentOfficerLevel !== 'L1'
            ) {
                statusBeforeSubmittion = 'RECOMMENDED';
            }
            let answer;
            if (dataBeforeSubmittion.requires_noc.parent_answer === 'true') {
                answer = NOCselected.map((el) => el.value);
            } else {
                answer = [];
            }

            answer = JSON.stringify(answer);
            dataBeforeSubmittion.requires_noc.parent_answer = answer;
        }
        // if (currentOfficerLevel === 'L2') {
        //   submitFormForL2({
        //     variables: {
        //       formInstanceId: formId,
        //       status: statusBeforeSubmittion,
        //       finalRemark:
        //         typeof updatedFinalRemark === 'string'
        //           ? updatedFinalRemark || 'N/A'
        //           : JSON.stringify(updatedFinalRemark),
        //     },
        //   })
        //     .then(() => {
        //       setIsLoading(false);
        //       if (!errorsL2 & !calledL2) {
        //         console.log('xyz')
        //         // redirectAfterSubmit();
        //       }
        //     })
        //     .catch((e) => {
        //       setIsLoading(false);
        //       setErrMsg(e.message);
        //       console.error(e);
        //     });
        // } else {
        const OfficerId = window.sessionStorage.getItem('id');
        // if (permissionLevel === 'PANCHAYAT SECRETARY') {
        //   updateApplicationStatus({
        //     variables: {
        //       applicationId: id,
        //       finalRemark:
        //         typeof updatedFinalRemark === 'string'
        //           ? updatedFinalRemark
        //           : JSON.stringify(updatedFinalRemark),
        //       status:
        //         statusBeforeSubmittion === 'RECOMMENDED' ||
        //           statusBeforeSubmittion === ''
        //           ? 'SHORTFALL'
        //           : 'REJECTED',
        //       eligibilityForRefund: 'false',
        //     },
        //   })
        //     .then(() => {
        //       setIsLoading(false);
        //       if (!errors3 & !called3) {
        //         console.log('123')
        //         // redirectAfterSubmit();
        //       }
        //     })
        //     .catch((e) => {
        //       setIsLoading(false);
        //       setErrMsg(e.message);
        //       console.error(e);
        //     });
        // } else {


        /// ---  title  Submit form answers ---- //// 
        {
            submitFormAnswers({
                variables: {
                    formInstanceId: officersFormDataGrouped?.map(item => item.id)[0],
                    data: officersFormDataGrouped?.map(item => item?.answer?.data)[0],
                    status: statusBeforeSubmittion === "SHOWCAUSE" ? 'SHOW_CAUSE' :
                        statusBeforeSubmittion === "RECOMMENDED" ? "RECOMMENDED" :
                            statusBeforeSubmittion === "NOT_RECOMMENDED" ? "NOT_RECOMMENDED" :
                                statusBeforeSubmittion === "NOT_RECOMMENDED_WITH_REFUND" ? "NOT_RECOMMENDED" :

                                    statusBeforeSubmittion || "RECOMMENDED",
                    officerId: OfficerId,
                    finalRemark:   hasSpecialCharacters(updatedFinalRemark)  ? JSON.stringify(updatedFinalRemark) : 
                    typeof updatedFinalRemark === 'string'
                        ? updatedFinalRemark
                        : JSON.stringify(updatedFinalRemark),
                },
            })

                .then(() => {

                    if (!errors & !called) {
                        history.push('/post-verification');
                        //    

                    }
                })
                .catch((e) => {
                    setIsLoading(false);
                    setErrMsg(e.message);
                    console.error(e);
                });
        }
        // ------------------   Tech form anwers -------------

        // submitFormAnswers({
        //     variables: {
        //         formInstanceId: officersFormDataGrouped?.filter(item => item.title === "Technical form").map(item => item.id)[0],
        //         data: officersFormDataGrouped?.filter(item => item.title === "Technical form").map(item => item?.answer?.data)[0],
        //         status: 'RECOMMENDED',
        //         officerId: OfficerId,
        //         finalRemark: '',
        //     },
        // })

        //     .then(() => {

        //         if (!errors & !called) {

        //             //    

        //         }
        //     })
        //     .catch((e) => {
        //         setIsLoading(false);
        //         setErrMsg(e.message);
        //         console.error(e);
        //     });

        // ---------------------------  Site Form Answers  --------------------

        // submitFormAnswers({
        //     variables: {
        //         formInstanceId: officersFormDataGrouped?.filter(item => item.title === "Site form").map(item => item.id)[0],
        //         data: officersFormDataGrouped?.filter(item => item.title === "Site form").map(item => item?.answer?.data)[0],
        //         status: 'RECOMMENDED',
        //         officerId: OfficerId,
        //         finalRemark: '',
        //     },
        // })

        //     .then(() => {

        //         if (!errors & !called) {

        //             //    

        //         }
        //     })
        //     .catch((e) => {
        //         setIsLoading(false);
        //         setErrMsg(e.message);
        //         console.error(e);
        //     });



        // ------------------  Final Update Submits forms  ---------------


        // UpdateFinalSubmit({
        //     variables: {
        //         status: statusBeforeSubmittion === "SHOWCAUSE" ? 'SHOW_CAUSE_RAISED' :
        //             statusBeforeSubmittion === "RECOMMENDED" ? "ACCEPTED" :
        //                 statusBeforeSubmittion === "NOT_RECOMMENDED" ? "REJECTED" :
        //                     statusBeforeSubmittion === "NOT_RECOMMENDED_WITH_REFUND" ? "REJECTED" : statusBeforeSubmittion || "ACCEPTED",
        //         eligibilityForRefund: "false",
        //         applicationId: id,
        //         finalRemark:
        //             typeof updatedFinalRemark === 'string'
        //                 ? updatedFinalRemark
        //                 : JSON.stringify(updatedFinalRemark),

        //     },
        // })
        //     .then((response) => {
        //         setIsLoading(false);
        //         if (!errors & !called) {
        //             toast.success(response?.data?.submitFormAnswersSingleWindow, {
        //                 position: 'top-right',
        //                 autoClose: 5000,
        //                 hideProgressBar: false,
        //             });
        //             history.push('/post-verification');
        //         }
        //     })
        //     .catch((e) => {
        //         setIsLoading(false);
        //         setErrMsg(e.message);
        //         console.error(e);
        //     });
    };

    const handleApplicaitonDetails = () => {
        document.getElementsByTagName('body')[0].classList.remove('show-sidebar');
        document.getElementsByTagName('body')[0].classList.remove('Hide-RightSidebar');
        document.getElementsByTagName('body')[0].classList.toggle('show-RightSidebar');

    }


    const officerCanRecommendOrNot = () => {
        return !(
            (applicationType === 'REGISTRATION' && currentOfficerLevel === 'L1') ||
            (applicationType === 'SELF CERTIFICATION' && currentOfficerLevel === 'L1')
        );
    };

    const modalFeeDetectPopUp = () => {
        return (
            <Modal
                show={showFeeDetect}
                onHide={handleCloseFeeDetect}

            >
                <Modal.Header
                    closeButton
                    style={{ borderBottom: '0 none', paddingBottom: '0px' }}
                />
                <Modal.Body style={{ paddingTop: '0px' }}>
                    <div className='blinking-text' style={{ color: "red", fontWeight: "bold" }} >
                        Their is a  Difference in Fee  , please Review the Fee while Submitting
                    </div>
                </Modal.Body>
                <Modal.Footer
                    style={{ borderTop: '0 none' }}
                    className="d-flex justify-content-center"
                >

                    <Button
                        className="btn btn-success"
                        onClick={() => {

                            setActiveTab('fees')
                            handleCloseFeeDetect()
                        }}
                        style={{ width: '30%' }}
                    >
                        Open Fee Tab
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    const ModalComponent = () => {
        return (
            <Modal
                show={showPopUpAfterSubmit}
                onHide={handleClosePopUpAfterSubmit}
                centered
            >
                <Modal.Header
                    closeButton
                    style={{ borderBottom: '0 none', paddingBottom: '0px' }}
                />
                <Modal.Body style={{ paddingTop: '0px' }}>
                    <div className="px-2">
                        {NOCrequired
                            ? 'Are you sure you want to submit to NOC concerned departments'
                            : officerCanRecommendOrNot()
                                ? `Are you sure you want to  ${recommendationStatus &&
                                    recommendationStatus === 'NOT_RECOMMENDED'
                                    ? 'Not Recommend' : recommendationStatus == "SHOWCAUSE" ? 'Show cause'
                                        : recommendationStatus == "NOT_RECOMMENDED_WITH_REFUND" ? "REVOKE WITH REFUND" :
                                            recommendationStatus == "NOT_RECOMMENDED" ? "REJECTED"
                                                : 'Recommend'
                                } and submit?`
                                : `Are you sure you want to submit?`}
                    </div>
                </Modal.Body>
                <Modal.Footer
                    style={{ borderTop: '0 none' }}
                    className="d-flex justify-content-between"
                >
                    <Button
                        className="btn btn-secondary"
                        onClick={() => handleClosePopUpAfterSubmit()}
                        style={{ width: '30%' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn-success"
                        onClick={() => {
                            submitRecommendationForm();
                            handleClosePopUpAfterSubmit();
                        }}
                        style={{ width: '60%' }}
                    >
                        {NOCrequired
                            ? 'Submit'
                            : officerCanRecommendOrNot()
                                ? recommendationStatus &&
                                    recommendationStatus === 'NOT_RECOMMENDED'
                                    ? 'Not Recommended' :
                                    recommendationStatus && recommendationStatus == 'SHOWCAUSE' ? " Show cause" : 'Recommended'
                                : 'Submit'}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    function shouldRenderButton(data) {
        // ////debugger;
        if (data?.officersApplication?.isFeeSubmitted == null && data?.officersApplication?.isFeeChanged == null) {
            return true
        } else {
            return false
        }
    }

    // useEffect(()=>{
    //   console.log(checkingFeeSubmit(true),"checkingFeeSubmit");
    // })

    // eslint-disable-next-line no-unused-vars
    const didOfficerNotComment = () => {
        // //debugger;

        if (isFormSubmitOrNot() == false) {
            if (
                !applicationComments ||
                applicationComments?.applicationComments.length === 0) {
                return true; // officer did not comment
            }
        } else if (isFormSubmitOrNot() == true) {
            return true
        }

        let isThisOfficerCommentPresent = false; // did not comment
        applicationComments.applicationComments.forEach((el) => {
            // eslint-disable-next-line no-undef
            // console.log(el,'officersApplication')

            if (applicationData?.officersApplication?.approvalFor == "OCCUPANCY") {
                // return isOccL2UpdatedTable
                if (el.officer.id === sessionStorage.getItem('id') && el.active === true
                    && (applicationData?.officersApplication?.isDeviationUpdated == true || applicationData?.officersApplication?.isDeviationSubmitted == true)

                ) {
                    ////debugger;
                    isThisOfficerCommentPresent = true; // did commnet
                }

            } else if (applicationData?.officersApplication?.applicationType !== "REGISTRATION" && officerData.me?.actions?.includes('fee') && applicationData?.officersApplication?.oldWorkFlow !== true) {

                if (el.officer.id === sessionStorage.getItem('id') && el.active === true
                    && (applicationData?.officersApplication?.isFeeChanged == true || applicationData?.officersApplication?.isFeeSubmitted !== null)
                ) {
                    isThisOfficerCommentPresent = true; // did commnet
                }
            } else {
                if (el.officer.id === sessionStorage.getItem('id') && el.active === true) {
                    isThisOfficerCommentPresent = true; // did commnet
                }
            }
        });

        return !isThisOfficerCommentPresent
    };


    const showAnswersAndSubmit = () => {
        let currentVerification = 'TITLE VERIFICATION';
        const currentUrlPath = history.location.pathname;
        if (currentUrlPath.includes('site')) {
            currentVerification = 'SITE VERIFICATION';
        } else if (currentUrlPath.includes('title')) {
            currentVerification = 'TITLE VERIFICATION';
        } else if (currentUrlPath.includes('technical')) {
            currentVerification = 'TECHNICAL VERIFICATION';
        } else {
            currentVerification = 'TECHNICAL VERIFICATION';
        }
        return (
            <>
                <AnswerListComponent
                    id={id}
                    currentVerification={currentVerification}
                    isVerification={isVerification}
                />
            </>
        );
    };

    const RemarkRecommendationSection = () => {
        return (
            <div className="rec-wrapper">
                <div className="recommand-main-wrapper btn-recommend-notrecommend">
                    <h2>Remarks & Recommendation</h2>
                    {/* <div className="rec-buttons"> */}
                    <p>What is your recommendation ? </p>
                    {recommendationsButtons?.recommendations?.length > 0 &&
                        recommendationsButtons?.recommendations.map((recomm, i) =>
                            <button
                                className={
                                    recommendationStatus === recomm
                                        ? 'btn btn-recmnd'
                                        : 'btn btn-light btn-notrecmnd'
                                }
                                onClick={() => {
                                    setFormStatus(recomm);
                                    setRecommendationStatus(recomm);
                                }}
                                disabled={isCurrentStageRecommended()}
                            >
                                {/* <img src={require('../../assets/images/successLightGreen.svg')} />{' '}  
                    {recomm === "RECOMMENDED" ? 'RECOMMEND FOR APPROVAL' :
                                    recomm === "NOT_RECOMMENDED" ? 'RECOMMEND FOR REJECTION' :
                                        recomm === "SHOWCAUSE" ? 'RECOMMEND FOR SHOWCAUSE' :
                                            recomm === "NOT_RECOMMENDED_WITH_REFUND" ? "REVOKE WITH REFUND" :
                                                recomm === 'FEE_ADJUSTMENTS' ? 'SEND BACK TO SECTION HEAD/ACP/TECHNICAL' :
                                                    recomm === "FEE_INTIMATION" && 'RAISE FEE INTIMATION'

                                }

                    */}
                                {recomm === "RECOMMENDED" ? 'RECOMMEND FOR APPROVAL' :
                                    recomm === "NOT_RECOMMENDED" ? 'RECOMMEND FOR REJECTION' :
                                        recomm === "SHOWCAUSE" ? 'RECOMMEND FOR SHOWCAUSE' :
                                            recomm === 'MORTGAGE_SHORTFALL' ? "RECOMMEND FOR  MORTGAGE SHORTFALL" :
                                                recomm === 'LINE_VERIFICATION' ? "RECOMMEND FOR  LINE  DEPARTMENT " :
                                                    recomm === 'NOT_RECOMMENDED_WITH_REFUND' ? 'REVOKE WITH REFUND' :
                                                        recomm === 'FEE_INTIMATION' && "RAISE FEE INTIMATION"}


                            </button>
                        )}
                    {/* <button
                className={
                  recommendationStatus === 'RECOMMENDED'
                    ? 'btn btn-recmnd'
                    : 'btn btn-light btn-notrecmnd'
                }
                onClick={() => {
                  setFormStatus('RECOMMENDED');
                  setRecommendationStatus('RECOMMENDED');
                }}
                disabled={isCurrentStageRecommended()}
              >
                <img src={require('../../assets/images/successLightGreen.svg')} />{' '}
                RECOMMEND FOR APPROVAL{' '}
              </button>
              <button
                className={
                  recommendationStatus === 'NOT_RECOMMENDED'
                    ? 'btn btn-secondary'
                    : 'btn btn-light btn-notrecmnd'
                }
                onClick={() => {
                  setFormStatus('NOT_RECOMMENDED');
                  setRecommendationStatus('NOT_RECOMMENDED');
                  setRemarksView(true);
                }}
                disabled={isCurrentStageRecommended()}
              >
                <img src={require('../../assets/images/successLightGreen.svg')} />{' '}
                RECOMMEND FOR REJECTION{' '}
              </button> */}
                    {/* </div> */}
                    {formStatus === 'NOT_RECOMMENDED' || formStatus === 'NOT_RECOMMENDED_WITH_REFUND' ? (
                        <div style={{ width: '100%' }}>
                            <MultiSelectDropdown
                                hidePadding={true}
                                selected={selected}
                                setSelected={setSelected}
                                otherRemarks={otherRemarks}
                                setOtherRemarks={setOtherRemarks}
                                formStatus={formStatus}
                            />
                        </div>
                    ) : (
                        <div className="updated_bottom_section 2">
                            <Form>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label className="form-Label">Final Remarks  :- {formStatus == 'SHOWCAUSE' && <p style={{ color: "red" }}> * Please review the remarks carefully before finalizing them, as they will be printed on certificates and shown to applicants </p>}</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows="3"
                                        placeholder="Text here"
                                        value={remarkValue}
                                        onChange={(event) => setRemarkValue(event.target.value)}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                    )}
                    {!isRemarksAvailable() ? (
                        <p style={{ color: 'red', display: 'block' }}>
                            Please add remarks
                        </p>) : ""}
                    <div
                        className="btn-container"
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                        {!isLoading && (
                            <>
                                <div
                                    className="d-flex flex-column"
                                    style={{ width: '250px', alignItems: 'center' }}
                                >
                                    <button
                                        className={
                                            carousalIndex === 0
                                                ? 'btn btn-success btn-firstPage'
                                                : 'btn btn-success '
                                        }
                                        onClick={() =>
                                        //  submitRecommendationForm()
                                        {
                                            if (
                                                officerCanRecommendOrNot() &&
                                                !recommendationStatus
                                            ) {
                                                setRecommendValidation(true);
                                            } else {
                                                setRecommendValidation(false);
                                                handleShowAfterSubmit();
                                            }
                                        }
                                        }
                                        // Removed temporarily

                                        disabled={!isRemarksAvailable()}
                                    >
                                        {'CONFIRM & SUBMIT'}
                                        <img
                                            src={require('../../assets/images/arrow.svg')}
                                            alt="next"
                                        />
                                    </button>
                                    <p onClick={() => setShowFinalRecommendation(false)}>
                                        Back to Review
                                    </p>
                                </div>
                                {/* Removed temporarily */}
                                {recommendValidation ? (
                                    <p style={{ color: 'red' }}>
                                        Please select recommend or not recommend
                                    </p>
                                ) : null}
                                {didOfficerNotComment() ? (
                                    <p style={{ color: 'red', position: "relative", top: "-20px" }}>
                                        Please add remarks in View Notes
                                    </p>
                                ) : null}
                            </>
                        )}
                        {isLoading && (
                            <Button variant="secondary">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </Button>
                        )}
                        <span>{errMsg || null}</span>
                    </div>
                </div>
            </div>
        );
    };

    const ansState = useRef({});
    const handleChildAnswerChange = (dbField, value) => {
        ansState.current[dbField] = value;
    };

    const memoizedHandleAnswerChange = useCallback(handleChildAnswerChange, [
        ansState,
    ]);
    useEffect(() => {
        if (formData.answer?.data) {
            Object.keys(formData.answer.data).forEach((key) => {
                memoizedHandleAnswerChange(key, formData.answer.data[key]);
            });
        }
    }, [formData]);

    // function checkingAction(){

    //  officersFormDataGrouped.filter((item)=>{item?.answer?.action == 'view'}) 


    // }
    function isNoteSheetAdded() {
        // ////debugger;
        return applicationComments?.applicationComments?.some((el) => el.active == true)
    }


    const handleCurrentChange = () => {
        setIsOccL2UpdatedTable(true);
    };

    const [activeTab, setActiveTab] = useState(officersFormDataGrouped[0].id);
    const [showFinalRecommendation, setShowFinalRecommendation] = useState(false);

    const handleTabs = (type) => {
        if (type != 'fees') {
            setActiveTab(type);
        } else {
            if (applicationData?.officersApplication?.isFeeChangeDetect == true) {
                setShowFeeDetect(true)
            }
            setActiveTab('fees')
        }
    };

    return (
        <>
            {ModalComponent()}
            {modalFeeDetectPopUp()}
            <div className="main-content">
                <div className="tv-container ">
                    {showSidebarHandler(true)}
                    {showFinalRecommendation ? (
                        <div className={showSidebar ? 'tv-recommend-content' : 'tv-content-expanded'}>
                            {RemarkRecommendationSection()}
                        </div>
                    ) : (
                        <>

                            <div
                                className={
                                    showSidebar ? 'tv-header sw-header' : 'tv-header-expanded'
                                }
                            >
                                {/* <div></div> */}
                                <ol className="breadcrumbs2">

                                    <li>
                                        <a href="#!">{appIdentifier}</a>
                                    </li>
                                </ol>


                                <div style={{ display: 'flex' }}>

                                    {show ? <NotesModal handleClose={handleClose} /> : null}
                                    {showSidebar && (
                                        <div className=" d-flex align-items-center ">
                                            <span className="addnote_label_text">
                                            Add Note Sheet:
                                            </span>
                                            <div className=" d-flex add_note_wrapper mr-3 ">
                                                {/* <div
                                                    className="viewButton"
                                                    type="button"
                                                    onClick={() => {
                                                        setShow(true);
                                                    }}
                                                >
                                                    VIEW
                                                </div> */}
                                                <div
                                                    className="addButton"
                                                    type="button"
                                                    onClick={() => {
                                                        setShow(true);
                                                    }}
                                                >
                                                    ADD NOTE SHEET
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                    <div onClick={handleApplicaitonDetails} className="sidebarToggle" style={{ marginLeft: "130px" }}
                                        type="button" >  <p className='application_Details_Button' > Application Details </p>
                                    </div>
                                </div>

                            </div>

                            <div className="tv-header-tabs">
                                {officersFormDataGrouped.map((questions, i) =>
                                    <div className={`tab-item ${activeTab === questions.id ? 'active' : ''}`} onClick={() => handleTabs(questions.id)}>
                                        {questions.title} {questions.answer != undefined && questions.answer.action == "view" ? <img width="25px" src={require("../../assets/images/icons_56px/Post Verification_active.png")} /> : " "}
                                    </div>
                                )}
                                {/* {officerData.me?.actions?.includes('checklist') &&
                    <div
                      className={`tab-item ${activeTab === 'checklist' ? 'active' : ''      
                        }`}
                      onClick={() => handleTabs('checklist')}
                    >
                      Checklist
                    </div>} */}

                                {
                                    applicationData?.officersApplication?.approvalFor == 'OCCUPANCY' &&
                                    <div className={`tab-item ${activeTab === 123 ? 'active' : ""}`} onClick={() => handleTabs(123)}>Deviation
                                        {(applicationData?.officersApplication?.isDeviationUpdated === true || applicationData?.officersApplication?.isDeviationSubmitted === true) && (
                                            <img width="25px" src={require("../../assets/images/icons_56px/Post Verification_active.png")} alt="Verification Active" />
                                        )}
                                    </div>
                                }

                                {officerData.me?.actions?.includes('fee') && applicationType !== 'REGISTRATION' && applicationData?.officersApplication?.approvalFor !== "OCCUPANCY" && (
                                    <div
                                        className={`tab-item ${activeTab === 'fees' ? 'active' : ''
                                            }`}
                                        onClick={() => handleTabs('fees')}
                                    >
                                        Fees
                                        {(applicationData?.officersApplication?.isFeeChanged == true || applicationData?.officersApplication?.isFeeSubmitted == true) ? <img width="25px" src={require("../../assets/images/icons_56px/Post Verification_active.png")} /> : " "}

                                    </div>
                                )}

                                {officerData.me?.actions?.includes('fee') && applicationData?.officersApplication?.applicationType === "SINGLE WINDOW" &&
                                    <>
                                        {status !== 'FEE_VERIFICATION' ? <div
                                            className={`tab-item ${activeTab === 'FeeConditions' ? 'active' : ''
                                                }`}
                                            onClick={() => handleTabs('FeeConditions')}
                                        >
                                            Fees Condtions   </div> :
                                            <div
                                                className={`tab-item ${activeTab === 'ProceedingCondtions' ? 'active' : ''
                                                    }`}
                                                onClick={() => handleTabs('ProceedingCondtions')}
                                            >
                                                Proceeding Condtions
                                            </div>}
                                    </>
                                }




                                {/* <div className={`tab-item ${activeTab === "documents" ? 'active' : ''}`} onClick={() => handleTabs("documents")}>Documents</div> */}




                            </div>

                            <div
                                className={showSidebar ? 'tv-content' : 'tv-content-expanded'}
                            >
                                {/* {activeTab === 'checklist' ? (
                    isChecklistFilled ? (
                      showAnswersAndSubmit()
                    ) : ( */}
                                <>
                                    {officersFormDataGrouped.map((questions, i) =>
                                        <div className={activeTab === questions.id ? '' : 'hide'}>
                                            <IAIRCarosualQuestions
                                                id={id}
                                                questionsData={questions}
                                                formId={questions.id}
                                                applicationIdentifier={applicationIdentifier}
                                                isVerification={isVerification}
                                                ApprovalFor={ApprovalFor}
                                            />
                                        </div>
                                    )}
                                </>
                                {status !== 'FEE_VERIFICATION' ?
                                    <>
                                        {activeTab === 'FeeConditions' && (<FeeConditions setIsFeeConditionsSubmit={setIsFeeConditionsSubmit} applicationIdentifier={applicationIdentifier} />)}
                                    </> :
                                    <>{activeTab === 'ProceedingCondtions' && (<ProceedingCondtions setIsFeeConditionsSubmit={setIsFeeConditionsSubmit} />)} </>
                                }

                                {activeTab === 'fees' && applicationData?.officersApplication?.applicationType !== "OCCUPANCY" && (
                                    <ScrutinyTableView setIsSubmit={setIsSubmit} viewPage="single_window" />
                                )}
                                {activeTab === 123 && (
                                    <div style={{ width: '100%' }}>
                                        <DeviationTable
                                            question={{
                                                order: applicationData?.officersApplication?.internalStatus,
                                                title: 'Deviation Table',
                                            }}
                                            handleCurrentChange={handleCurrentChange}
                                            isDeviationSubmitted={applicationData?.officersApplication?.isDeviationSubmitted}
                                            isDeviationUpdated={applicationData?.officersApplication?.isDeviationUpdated}
                                        // setIsDeviationUpdated ={setIsDeviationUpdated}
                                        />
                                    </div>
                                )}
                                {/* {activeTab === 123 && (
     <div style={{ width: '100%'}}>
     <DeviationTable
       question={{
         order: 'L2',
         title: 'Deviation Table',
       }}
       handleCurrentChange={handleCurrentChange}
     />
   </div>
)} */}
                                {/* {(officerData.me?.actions?.includes('fee') && (isChecklistFilled && isFeesCompleted)) || isChecklistFilled && */}

                                {/* } */}
                            </div>
                        </>
                    )}
                    {!showFinalRecommendation && <div className="d-flex flex-column align-items-center justify-content-center">
                        <div onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className="d-flex justify-content-space-between">
                            <Button
                                style={{ width: "100px", padding: "3px" }}
                                className={'btn  btn-sucess comfirm-proceed'}
                                onClick={() => setShowFinalRecommendation(true)}
                                disabled={didOfficerNotComment()}
                                variant="outline-success"

                            >
                                {'Confirm and Proceed'}
                                &nbsp;
                                <img
                                    src={require('../../assets/images/arrow.svg')}
                                    alt="next"
                                />
                            </Button>
                        </div>
                        {isHovered == true && didOfficerNotComment() &&
                            <> {!isNoteSheetAdded() && isFormSubmitOrNot() && applicationData?.officersApplication?.approvalFor !== 'OCCUPANCY' ? (
                                <p style={{ color: 'red' }}>
                                    *  Please add remarks in View Notes and Submit Forms
                                </p>
                            ) : isFormSubmitOrNot() ? <p style={{ color: 'red' }}>
                                *  Please Submit Forms
                            </p>
                                : !isNoteSheetAdded() ? <p style={{ color: 'red' }}>
                                    *  Please add remarks in View Notes
                                </p> : officerData.me?.actions?.includes('fee') && shouldRenderButton(applicationData) == true && applicationType === 'SELF CERTIFICATION' && applicationData?.officersApplication?.oldWorkFlow !== true ? <p style={{ color: 'red' }}>
                                    *  Please submit fee
                                </p> : <p style={{ color: 'red' }}>
                                    *  Please add remarks in View Notes
                                </p>}</>}
                    </div>}
                </div>
            </div>
        </>
    );
}

IAIRCards.propTypes = {
    officersFormDataGrouped: PropTypes.array,
    officersDocumentsDataSorted: PropTypes.array,
    formId: PropTypes.string,
    verificationType: PropTypes.string,
    applicationIdentifier: PropTypes.string,
    isChecklistFilled: PropTypes.bool,
    isVerification: PropTypes.string,
    showSidebar: PropTypes.bool,
    showSidebarHandler: PropTypes.func,
    stageState: PropTypes.any,
    formData: PropTypes.array,
};

export default IAIRCards;
