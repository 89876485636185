import React, { useContext, useEffect, useState } from 'react';
import ApplicationListLayout from '../components/ApplicationList';
import { useLazyQuery, useQuery } from '@apollo/client';
import LoadingScreen from '../components/LoadingScreen';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ErrorScreen from '../components/ErrorScreen';
import { GET_FILTERED_APPLICATIONS } from '../graphql/query/officersFilteredApplications';

import { Modal, Button } from 'react-bootstrap';
import { AppContext } from './../AppContext';
import { PERMISSIONS_LEVELS, FILTER_LIST_ENUM } from '../util/helperFunctions';
import history from '../services/history';
import { Redirect } from 'react-router-dom';
import { GET_OFFICER_PROFILE } from '../graphql/query/officerProfile';
import { DOWNLOAD_EXCEL_QUERY, EXPORTFILTER } from '../graphql/query/DowloadExcelQuery';

function PostVerification() {
  // const { data: officerData } = useQuery(GET_OFFICER_PROFILE);
  const userRoleCollector = ""
  //   officerData?.me?.role?.name === 'CIO-Chasing Cell' ||
  //   officerData?.me?.role?.name === 'Chief Minister Office' ||
  //   officerData?.me?.role?.name === 'PRINICIPAL SECRETARY' ||
  //   officerData?.me?.role?.name === 'DISTRICT COLLECTOR' ||
  //   officerData?.me?.role?.name === 'ADDITIONAL COLLECTOR' ||
  //   officerData?.me?.role?.name === 'HMDA DIRECTOR' ||
  //   officerData?.me?.role?.name === 'GHMC DIRECTOR' ||
  //   officerData?.me?.role?.name === 'DTCP DIRECTOR';
  const AllfitlerItems = []

  const { permissionLevel, innerListValue, errorScreen,logoutLoading } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [showNotify, setShowNotify] = useState(false);
  const [filterSearch, setfilterSearch] = useState([])
  const [filteringDate, setFilteringDate] = useState('')
  const [filteringType, setFilteringType] = useState([])
  const [RecivedText  , setRecivedText] = useState("Old - New")
  const [fillterDisplayName,setFilterDisplayName] = useState(AllfitlerItems)
  // const  [SortData , setSortedDAta] = useState(false)
  const  [sortRecivedDate , setSortRecivedDate]  = useState('ASC')
  const  [sortAssignedDate , setSortAssignedDate ]  = useState('')
  const  [isExcel , setIsExcel ]  = useState(false)
  const [actualFilter, setActualFilter] = useState(
    innerListValue || FILTER_LIST_ENUM.all
  );
  const { loading, error, data, refetch } = useQuery(
    GET_FILTERED_APPLICATIONS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: {
        filter: actualFilter,
        searchParam: searchText,
        receivedDateParam: filteringDate,
        status: filterSearch,
        applicationType: filteringType,
        page: currentPage,
        receivedDateSort: sortRecivedDate,
        assignedDateSort: sortAssignedDate,
        //  all:isExcel
      },
    }
  );

  const [fetchExcelData, { data: ExcelData, loading: excelLoading, error: excelError }] = useLazyQuery(
    DOWNLOAD_EXCEL_QUERY,
    {
      fetchPolicy: 'no-cache',
    }
  );
  
  const handleExcelFetch = () => {
    fetchExcelData({
      variables: {
        filter: actualFilter,
        searchParam: searchText,
        receivedDateParam: filteringDate,
        status: filterSearch,
        applicationType: filteringType,
        page: currentPage,
        receivedDateSort: sortRecivedDate,
        assignedDateSort: sortAssignedDate,
        all: true,
      },
    });
  };
  
  useEffect(() => {
    if (isExcel) {
      handleExcelFetch();
    }
  }, [isExcel]);


  useEffect(() => {
    if (history.location.state?.filter) {
      setCurrentPage(1);
      setSearchText('');
      setActualFilter(innerListValue);
    }
  }, [innerListValue]);
  useEffect(() => {
    setCurrentPage(1);
  }, [searchText]);
  useEffect(() => {
    if (
      [
        PERMISSIONS_LEVELS.CIO_CHASING_CELL,
      ].includes(permissionLevel)
    ) {
      history.push(`/summary-report`);
    }
  });

  if (userRoleCollector) {
    // return <Redirect to="/summary-report" />;
  }

  /* eslint-disable no-unused-vars */
  const sortCompare = (a, b) => {
    if (parseInt(a.applicantListId, 10) < parseInt(b.applicantListId, 10)) {
      return 1;
    }
    if (parseInt(a.applicantListId, 10) > parseInt(b.applicantListId, 10)) {
      return -1;
    }
    return 0;
  };

  let sortedData;
  if (data && data.applicationsByDateAndTypeFilter?.[0]?.records.length) {
    const cloneOfficersApplications = [
      ...data.applicationsByDateAndTypeFilter?.[0]?.records,
    ];

    sortedData = cloneOfficersApplications;
    if (
      permissionLevel === PERMISSIONS_LEVELS.SITE_VERIFICATION ||
      permissionLevel === PERMISSIONS_LEVELS.TITLE_VERIFICATION
    ) {
      sortedData = sortedData.filter(
        (app) => app.status !== 'FEE_VERIFICATION' // update this if fee-verification status is comming in another form
      );
    }
  }

  let ExcelDtataDownload;
  if (ExcelData && ExcelData.applicationsByDateAndTypeFilter?.[0]?.records.length) {
    const cloneOfficersApplications = [
      ...ExcelData.applicationsByDateAndTypeFilter?.[0]?.records,
    ];

    ExcelDtataDownload = cloneOfficersApplications;
    if (
      permissionLevel === PERMISSIONS_LEVELS.SITE_VERIFICATION ||
      permissionLevel === PERMISSIONS_LEVELS.TITLE_VERIFICATION
    ) {
      ExcelDtataDownload = ExcelDtataDownload.filter(
        (app) => app.status !== 'FEE_VERIFICATION' // update this if fee-verification status is comming in another form
      );
    }
  }

  // console.log(ExcelDtataDownload,"ExcelData")

  // console.log(sortedData,'sortedData')
  // function SortingData () {
  //   sortedData = sortedData?.sort((a, b) => new Date(b.assignedDate) - new Date(a.assignedDate))
     
  // }


  // function checkUserLogin () {
  //   const loginTOken = sessionStorage.getItem('accessToken')
  //   if(loginTOken != null || loginTOken != "" || loginTOken != undefined) {
  //     return true
  //   }else {
  //     return false
  //   }
  // }
// useEffect(()=>{
//   if(error) {
//     window.sessionStorage.clear();
//   }
// },[])



  //status
  // console.log(error?.message,'ero123456r');

  return (
    <>
{/* <IdleTimer/>  */}
      <Header  />
      <Sidebar
     
      />
      {loading && <LoadingScreen />}
      {logoutLoading && <LoadingScreen />}
      {error && <ErrorScreen message={error} retry={refetch} />}
    {errorScreen && <ErrorScreen message={errorScreen} retry={refetch} />}
      {data && (
        <div className="main-content">
          <ApplicationListLayout
            data={sortedData}
            // SortingData={SortingData}
            setIsExcel={setIsExcel}
            ExcelDtataDownload={ExcelDtataDownload}
            excelLoading={excelLoading}
            pageType={'post-verification'}
            currentFilter={actualFilter}
            setCurrentPage={setCurrentPage}
            isExcel={isExcel}
            currentPage={currentPage}
            pageCount={data?.applicationsByDateAndTypeFilter?.[0]?.totalPages || 1}
            totalCount={data?.applicationsByDateAndTypeFilter?.[0]?.totalCount}
            setSearchText={setSearchText}
            setfilterSearch={setfilterSearch}
            filterSearch={filterSearch}
            searchText={searchText}
            setFilteringType={setFilteringType}
            setFilteringDate={setFilteringDate}
            enableNotes={history.location.state?.enableNotes}
            fillterDisplayName={fillterDisplayName}
            setFilterDisplayName={setFilterDisplayName}
            AllfitlerItems={AllfitlerItems}
            actualFilter={actualFilter}
            setSortRecivedDate={setSortRecivedDate}
            sortRecivedDate ={sortRecivedDate}
            AprovalFor={data?.applicationsByDateAndTypeFilter?.[0]?.records}
            sortAssignedDate = {sortAssignedDate} 
             setSortAssignedDate  = {setSortAssignedDate}
            //  Refetching={Refetching}
            
          />
        </div>
      )}
      <Modal show={showNotify} onHide={() => setShowNotify(false)} centered>
        <Modal.Header closeButton style={{ color: 'red' }}>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: '20vh', width: '100%' }}>
            Penalty for delay in post verification scrutiny to the concerned
            officers will be levied as per rules. This comes into force shortly.
            Hence, are cautioned to dispose of the applications within time to
            avoid penalty.
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PostVerification;

