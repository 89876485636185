import React, { useContext, useState, useEffect } from 'react';
import { Dropdown, Tabs, Tab, Table, InputGroup, FormControl, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './ChasingCellAppTable.scss';
import TableContent from './ChasingCellAppTableContent';

export default function ChasingCellAppTable() {

    return (
        <div className="ChasingCellAppTable">
            <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
                <Tab eventKey="home" title={<div className="navHeading1"><div className="navHeading2"><p className="navHeadingText">Instant Registration</p><p className="navHeadingVal">500</p></div><p className="navsubTitle">Approved</p></div>}>
                    <div className="tableOptions">
                        <div className="d-flex">
                            <p className=" app-list">List of Applications</p>
                            <img
                                style={{ left: 10, position: 'relative' }}
                                src={require('../../assets/images/leftarrow.svg')}
                            />
                            <p className="pl-3 summart-rpt">Summary Report</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <Button className="sortby-btn">
                                <img
                                    style={{ position: 'relative' }}
                                    src={require('../../assets/images/equalizer.svg')}
                                />
                                <span className="pl-2 " style={{ fontSize: "12px" }} >sort by</span>
                                <img
                                    style={{ position: 'relative', left: 10, transform: "rotate(180deg)" }}
                                    src={require('../../assets/images/leftarrow.svg')}
                                />
                            </Button>
                            <InputGroup className="ml-3 search-input-field">
                                <FormControl
                                    placeholder="Search"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">
                                        <img
                                            style={{ right: 10, position: 'relative' }}
                                            src={require('../../assets/images/search.svg')}
                                        />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                    </div>
                    <TableContent />
                </Tab>
                <Tab eventKey="profile" title={<div className="navHeading1"><div className="navHeading2"><p className="navHeadingText">Instant Approval</p><p className="navHeadingVal">500</p></div><p className="navsubTitle">Approved</p></div>}>
                    <div className="tableOptions">
                        <div className="d-flex">
                            <p className=" app-list">List of Applications</p>
                            <img
                                style={{ left: 10, position: 'relative' }}
                                src={require('../../assets/images/leftarrow.svg')}
                            />
                            <p className="pl-3 summart-rpt">Summary Report</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <Button className="sortby-btn">
                                <img
                                    style={{ position: 'relative' }}
                                    src={require('../../assets/images/equalizer.svg')}
                                />
                                <span className="pl-2 " style={{ fontSize: "12px" }} >sort by</span>
                                <img
                                    style={{ position: 'relative', left: 10, transform: "rotate(180deg)" }}
                                    src={require('../../assets/images/leftarrow.svg')}
                                />
                            </Button>
                            <InputGroup className="ml-3 search-input-field">
                                <FormControl
                                    placeholder="Search"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">
                                        <img
                                            style={{ right: 10, position: 'relative' }}
                                            src={require('../../assets/images/search.svg')}
                                        />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                    </div>
                    <TableContent />
                </Tab>
                <Tab eventKey="contact" title={<div className="navHeading1"><div className="navHeading2"><p className="navHeadingText">Single Window</p><p className="navHeadingVal">500</p></div><p className="navsubTitle">Approved</p></div>} >
                    <div className="tableOptions">
                        <div className="d-flex">
                            <p className=" app-list">List of Applications</p>
                            <img
                                style={{ left: 10, position: 'relative' }}
                                src={require('../../assets/images/leftarrow.svg')}
                            />
                            <p className="pl-3 summart-rpt">Summary Report</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <Button className="sortby-btn">
                                <img
                                    style={{ position: 'relative' }}
                                    src={require('../../assets/images/equalizer.svg')}
                                />
                                <span className="pl-2 " style={{ fontSize: "12px" }} >sort by</span>
                                <img
                                    style={{ position: 'relative', left: 10, transform: "rotate(180deg)" }}
                                    src={require('../../assets/images/leftarrow.svg')}
                                />
                            </Button>
                            <InputGroup className="ml-3 search-input-field">
                                <FormControl
                                    placeholder="Search"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">
                                        <img
                                            style={{ right: 10, position: 'relative' }}
                                            src={require('../../assets/images/search.svg')}
                                        />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                    </div>
                    <TableContent />
                </Tab>
            </Tabs>

        </div>
    )

};
