import React from 'react';
import { Card, Form, Col } from 'react-bootstrap';
import history from '../../services/history';
import { useParams } from 'react-router';

export default function ReviewCard() {
  const { id } = useParams();

  return (
    <div className="main-content">
      <div className="tv-container ">
        <div className="tv-header">
          <ol className="breadcrumbs">
            {/* <li
              className="active"
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                history.push('/post-verification');
              }}
            >
              <a href="#!">Post Verification</a>
            </li> */}
            <li>
              <a href="#!">{id}</a>
            </li>
          </ol>
        </div>
        <div className="tv-content">
          <div className="d-flex justify-content-between">
            <span className="fs-15 font-weight-bold">Remarks added</span>
            <span style={{ color: 'grey' }} className="fs-12">
              Selected for shortfall
            </span>
          </div>
          <Card>
            <Card.Body>
              <span className="fs-15">
                Please verify your added remarks once before you confirm to
                raise shortfall
              </span>
              <br />
              <br />
              <br />
              <span className="fs-13">
                Whether the land use as per the notified master plan for the
                proposed plot?
              </span>
              <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label className="form-Label fs-11">
                    <span style={{ color: '#1c9a5b' }}>Remark: </span>
                    <span style={{ color: 'grey' }}>
                      You can edit by clicking on it
                    </span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Text here"
                  />
                </Form.Group>
                <Form.Group id="formGridCheckbox">
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      controlId="formGridEmail"
                      className="fs-11"
                    >
                      <Form.Check
                        type="radio"
                        label="File upload"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="formGridEmail"
                      className="fs-11"
                    >
                      <Form.Check
                        type="radio"
                        label="Field Modification"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <div className="btn-recommend-notrecommend">
                        <button className="btn btn-light btn-recmnd">
                          <img
                            src={require('../../assets/images/successDarkGreen.svg')}
                          />
                          SHORTFALL{' '}
                        </button>
                      </div>
                    </Form.Group>
                  </Form.Row>
                </Form.Group>
              </Form>
              <hr />
              <span className="fs-13">
                Whether the site is covered by any restricted zone?
              </span>
              <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label className="form-Label fs-11">
                    <span style={{ color: '#1c9a5b' }}>Remark: </span>
                    <span style={{ color: 'grey' }}>
                      You can edit by clicking on it
                    </span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Text here"
                  />
                </Form.Group>
                <Form.Group id="formGridCheckbox">
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      controlId="formGridEmail"
                      className="fs-11"
                    >
                      <Form.Check
                        type="radio"
                        label="File upload"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="formGridEmail"
                      className="fs-11"
                    >
                      <Form.Check
                        type="radio"
                        label="Field Modification"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <div className="btn-recommend-notrecommend">
                        <button className="btn btn-light btn-recmnd">
                          <img
                            src={require('../../assets/images/successDarkGreen.svg')}
                          />
                          SHORTFALL{' '}
                        </button>
                      </div>
                    </Form.Group>
                  </Form.Row>
                </Form.Group>
              </Form>
              <div className="border-btm">
                <hr />
                <span>Selected for shortfall </span>
              </div>{' '}
              <div className="btn-container">
                <button className="btn btn-light ">
                  <img
                    src={require('../../assets/images/arrowBackwards.svg')}
                    alt="previous"
                  />
                  BACK
                </button>
                <button className="btn btn-success">
                  Raise Shortfall
                  <img
                    src={require('../../assets/images/arrow.svg')}
                    alt="next"
                  />
                </button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}
