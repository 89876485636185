import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import history from '../services/history';

function ChangePasswordOTP() {
  const [otp, setOtp] = useState('');
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(true);

  console.log(errorMsg);
  const handleChange = (otp) => {
    setOtp(otp);
  };

  const handleVerify = () => {
    if (otp.length === 4) {
      setLoading(false);
      history.push('/reset-password-new');
    } else {
      setErrorMsg(true);
    }
  };

  let errorMessage;
  if (otp.length < 4) {
    errorMessage = (
      <div style={{ color: '#DC3545' }} className="fs-12 mt-1">
        Please fill the OTP
      </div>
    );
  } else {
    errorMessage = <div />;
  }

  let verifyButton;
  // Verify Otp mutation to be added
  if (loading) {
    verifyButton = (
      <Button
        variant="primary"
        type="submit"
        className="d-flex align-items-center justify-content-around"
        onClick={handleVerify}
      >
        <span className="text-uppercase">Verify</span>
        <img src={require('../assets/images/arrow.svg')} alt="" />
      </Button>
    );
  } else {
    verifyButton = (
      <Button variant="secondary">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Button>
    );
  }

  return (
    <div className="change-pwd-otp-container">
      <Container>
        <div className="change-pwd-otp-content">
          <strong>OTP Verification</strong>
          <p className="fs-12">Please enter the OTP received to your email</p>
          <br />
          <p className="fs-12">One Time Password</p>
          <div>
            <OtpInput
              onChange={handleChange}
              value={otp}
              numInputs={4}
              isInputNum="true"
              containerStyle={{
                width: '224px',
                justifyContent: 'space-between',
              }}
              inputStyle={{
                height: '50px',
                width: '50px',
                border: '1px solid #3F4A50',
                borderRadius: '4px',
              }}
            />
            {errorMessage}
          </div>
          <br />
          {verifyButton}
          <br />
          <p className="fs-12">
            Didn&apos;t receive Code{' '}
            <span className="ml-3 pointer" style={{ color: '#1C9A5B' }}>
              <strong>Resend</strong>
            </span>
          </p>
        </div>
      </Container>
    </div>
  );
}

export default ChangePasswordOTP;
