import { gql } from "@apollo/client";
//import { APPLICATION_CORRECTION } from "./application_Correction";

 export const  APPLICATION_CORRECTION_DETAILS = gql`
 query  applicationCorrectionRequest($applicationId: ID!, $status:String){
    applicationCorrectionRequest(
        applicationId: $applicationId
      status:$status
        ){
        id
        applicationIdentifier
        correctionRequestCompleted
        corrections {
            id
            columnName,
            newValue,
            oldValue,
            isApprove,
            remark,
            supportingDocUrl,
            supportingOptionalDocUrl
        }
      }
      }
  

`