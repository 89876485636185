import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import Header from '../components/Header';
import CustomSidebar from '../components/CustomSidebar';
import CustomRightDrawer from '../components/CustomRightDrawer';
import history from '../services/history';
import { Collapse, Checkbox, Pagination } from 'antd';
import { REPORTS, REPORT_APPLICATIONS } from '../graphql/query/reports';
import { useLazyQuery, useQuery } from '@apollo/client';
import LoadingScreen from '../components/LoadingScreen';
import ErrorScreen from '../components/ErrorScreen';
import { slugToTitle } from './SummaryReport';
import moment from 'moment';
import { useParams } from 'react-router';
import axios from 'axios';
import environment from 'environment';
import { toast } from 'react-toastify';
import ChasingCellBackButton from '../components/ChasingCellBackButton';

const { Panel } = Collapse;

const SubHeader = ({
  index,
  title,
  value,
  subText,
  slug,
  applicationType,
  onClick,
}) => {
  return (
    <div
      onClick={() => onClick(slug)}
      className={`p-2 px-4 ${index !== 0 ? 'border-left' : ''} ${
        slug === applicationType ? 'sub-header-active' : ''
      }`}
      style={
        slug == 'Total'
          ? {
              cursor: 'default',
              background: '#f4f5f6 0% 0% no-repeat padding-box',
              borderRadius: 50,
              marginLeft: 20,
            }
          : {
              cursor: 'pointer',
              minWidth: '250px',
              background: '#f4f5f6 0% 0% no-repeat padding-box',
            }
      }
    >
      <div className="d-flex align-items-center">
        <span style={{ color: '#3F4A50' }}>{title}</span>
        <span className="Neutra-Demi ml-2" style={{ color: '#1C9A5B' }}>
          {value}
        </span>
      </div>
      {/* <span
        style={{  color: '#3F4A50', fontSize: 11 }}
      >
        {subText}
      </span> */}
    </div>
  );
};

const SortTitle = ({ setSelectedSortItemList, apply }) => {
  return (
    <div className="d-flex align-items-start justify-content-between mt-5 mx-3">
      <div>
        <div className="sort-by-title">Filter by:</div>
        <span
          onClick={() => setSelectedSortItemList({})}
          className="sort-by-clear"
        >
          Clear
        </span>
      </div>
      <div onClick={apply} className="sort-by-apply-container">
        <span className="sort-by-apply">Apply</span>
        <img src={require('../assets/images/dashboard-new/long-arrow.svg')} />
      </div>
    </div>
  );
};

const labelToSlug = {
  ULB: 'ulbs',
  DISTRICT: 'districts',
  AUTHORITY: 'authorities',
};

const labelToApplicationStatus = {
  verification: 'VERIFICATION_UNDER_PROGRESS',
  approved: 'APPROVED',
  rejected: 'REJECTED',
  deemed: 'DEEMED',
  with_citizen: 'WITH_CITIZEN',
};

const Summary = (props) => {
  const { state } = history.location;
  const {
    loading: reportsLoading,
    error: reportsError,
    data: reportsData,
    refetch: reportsRefetch,
  } = useQuery(REPORTS, {
    fetchPolicy: 'cache-first',
    variables: {
      type: 'CONSOLIDATED',
      ulbs: (state && state.ULB) || '',
      startDate: (state && state.startDate) || '2020-01-01',
      endDate: (state && state.endDate) || moment().format('YYYY-MM-DD'),
    },
  });

  const [searchText, setSearchText] = useState('');
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSortItemList, setSelectedSortItemList] = useState({});
  const [reports, setReports] = useState(null);
  const [records, setRecords] = useState([]);
  const [recordsToDisplay, setRecordsToDisplay] = useState([]);
  const [applicationType, setApplicationType] = useState('REG');
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [ulb, setUlb] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [authority, setAuthority] = useState(['DTCP', 'GHMC', 'HMDA']);
  const [sortData, setSortData] = useState({});
  const { label } = useParams();

  const [getReportApplications, { called, loading, data }] =
    useLazyQuery(REPORT_APPLICATIONS);

  useEffect(() => {
    if ((state && state.applicationType) || '') {
      setApplicationType(state.applicationType);
    }
  }, []);

  useEffect(() => {
    let variables = {
      applicationType,
      applicationStatus:
        (label && labelToApplicationStatus[label]) || props.type.toUpperCase(),
      page,
      perPage: 10,
      authorities: (state && state.AUTHORITY) || '',
      districts: (state && state.DISTRICT) || '',
      ulbs: (state && state.ULB) || '',
      circles: '',
    };
    if (state && state.startDate) {
      variables['startDate'] = state && state.startDate;
    }
    if (state && state.endDate) {
      variables['endDate'] = state && state.endDate;
    }
    if (
      Object.keys(selectedSortItemList).length &&
      Object.keys(selectedSortItemList).some(
        (o) => selectedSortItemList[o].value
      )
    ) {
      let group =
        selectedSortItemList[
          Object.keys(selectedSortItemList).find(
            (o) => selectedSortItemList[o].value
          )
        ].group;
      variables[labelToSlug[group]] = Object.keys(selectedSortItemList)
        .filter((o) => selectedSortItemList[o].value)
        .join();
    }
    getReportApplications({ variables });
  }, [page, applicationType]);

  useEffect(() => {
    console.log(data);
    if (data) {
      let arr =
        (data.reportApplications &&
          data.reportApplications.length &&
          data.reportApplications[0] &&
          data.reportApplications[0].records) ||
        [];
      let total =
        (data.reportApplications &&
          data.reportApplications.length &&
          data.reportApplications[0] &&
          data.reportApplications[0].totalCount) ||
        0;
      setTotalCount(total);
      if (searchText) {
        arr = arr.filter(
          (o) =>
            o.applicationIdentifier
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            o.property.location.district
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            o.property.location.districtAdministrationArea
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            o.property.location.ulbName
              .toLowerCase()
              .includes(searchText.toLowerCase())
        );
      }
      setRecords(arr);

      let arrToDisplay = arr.map((obj) => {
        let remarks = '';
        let officerremarks = JSON.parse(obj.officersRemarks);
        if (
          typeof officerremarks == 'object' &&
          Object.keys(officerremarks || {}).length
        ) {
          Object.keys(officerremarks).forEach((o) => {
            remarks = `${remarks ? `${remarks},` : ''} ${o.replaceAll(
              '_',
              ' '
            )}: ${officerremarks[o]}`;
          });
        }
        return [
          obj.applicationIdentifier,
          obj.property.location.circle,
          obj.property.location.ulbName,
          obj.property.location.district,
          obj.property.location.districtAdministrationArea,
          obj.applicationType,
          moment(obj.createdAt.split(' ')[0]).format('DD-MM-YYYY') ||
            moment(obj.appliedAt.split(' ')[0]).format('DD-MM-YYYY'),
          moment(obj.updatedAt.split(' ')[0]).format('DD-MM-YYYY'),
          obj.status,
          remarks,
          obj.id,
        ];
      });
      setRecordsToDisplay(arrToDisplay);
    }
  }, [data, applicationType, searchText]);

  useEffect(() => {
    if (reportsData) {
      let reports = {
        ...reportsData.reports,
        Total: {
          received: Object.keys(reportsData.reports)
            .map((o) => reportsData.reports[o].received)
            .reduce((a, c) => a + c, 0),
          approved: Object.keys(reportsData.reports)
            .map((o) => reportsData.reports[o].approved)
            .reduce((a, c) => a + c, 0),
          rejected: Object.keys(reportsData.reports)
            .map((o) => reportsData.reports[o].rejected)
            .reduce((a, c) => a + c, 0),
          deemed: Object.keys(reportsData.reports)
            .map((o) => reportsData.reports[o].deemed)
            .reduce((a, c) => a + c, 0),
          under_process: Object.keys(reportsData.reports)
            .map((o) => reportsData.reports[o].under_process)
            .reduce((a, c) => a + c, 0),
        },
      };
      setReports(reports);
    }
  }, [reportsData]);

  useEffect(() => {
    const authToken = sessionStorage.getItem('accessToken');
    if (authToken) {
      axios
        .get(`${environment.baseApi}/villages/ulb_names`, {
          headers: {
            Authorization: 'Bearer ' + authToken,
            Accept: '*/*',
          },
        })
        .then((response) => {
          if (response.data.success) {
            setUlb(response.data.data.ulb_names);
          } else {
            toast.error(response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
          }
        })
        .catch(function (error) {
          if (error.response) {
            toast.error(error.response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
          }
        });
    }
  }, []);

  useEffect(() => {
    const authToken = sessionStorage.getItem('accessToken');
    if (authToken) {
      axios
        .get(`${environment.baseApi}/villages/districts`, {
          headers: {
            Authorization: 'Bearer ' + authToken,
            Accept: '*/*',
          },
        })
        .then((response) => {
          if (response.data.success) {
            setDistricts(response.data.data.districts);
          } else {
            toast.error(response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
          }
        })
        .catch(function (error) {
          if (error.response) {
            toast.error(error.response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
          }
        });
    }
  }, []);

  useEffect(() => {
    setSortData({
      ...sortData,
      AUTHORITY: authority,
      DISTRICT: districts,
      ULB: ulb,
    });
  }, [ulb, districts]);

  const selectSortItem = (e, sortItem, sortKey) => {
    setSelectedSortItemList({
      ...selectedSortItemList,
      [sortItem]: {
        value: e.target.checked,
        group: sortKey,
      },
    });
  };

  const searchApplications = (e) => {
    setSearchText(e.target.value);
  };

  const handleGoBackToOrigin = () => {
    if (state && state.originComponent) {
      history.goBack();
    } else {
      history.replace('/summary-report');
    }
  };

  if (reportsLoading || called & loading) return <LoadingScreen />;

  if (reportsError) {
    return <ErrorScreen message={reportsError} retry={reportsRefetch} />;
  }

  if (!reports) return null;

  let sortItemListCheck =
    Object.keys(selectedSortItemList).length &&
    Object.keys(selectedSortItemList).some(
      (o) => selectedSortItemList[o].value
    );
  let labelToShow = label || props.type;
  let filterToShow = state && state.filter;

  return (
    <div className="dashboard-new-wrapper">
      <CustomSidebar
        noOverlay={false}
        isOpen={openSidebar}
        onClose={() => setOpenSidebar(false)}
      />
      <CustomRightDrawer
        title={
          <SortTitle
            setSelectedSortItemList={setSelectedSortItemList}
            apply={() => {
              if (sortItemListCheck) {
                setPage(1);

                let variables = {
                  applicationType,
                  applicationStatus:
                    (label && labelToApplicationStatus[label]) ||
                    labelToShow.toUpperCase(),
                  page,
                  perPage: 10,
                  authorities: '',
                  districts: '',
                  ulbs: '',
                  circles: '',
                };
                let group =
                  selectedSortItemList[
                    Object.keys(selectedSortItemList).find(
                      (o) => selectedSortItemList[o].value
                    )
                  ].group;
                variables[labelToSlug[group]] = Object.keys(
                  selectedSortItemList
                )
                  .filter((o) => selectedSortItemList[o].value)
                  .join();
                getReportApplications({ variables });

                setOpenSort(false);
              }
            }}
          />
        }
        closable={true}
        visible={openSort}
        onClose={() => setOpenSort(false)}
      >
        <Collapse
          ghost
          defaultActiveKey={['0']}
          onChange={() => {}}
          expandIconPosition={'right'}
          accordion={true}
        >
          {Object.keys(sortData)
            .filter((o) => (filterToShow ? o == filterToShow : true))
            .map((sortKey, index) => {
              let disabled = false;
              if (sortItemListCheck) {
                let group =
                  selectedSortItemList[
                    Object.keys(selectedSortItemList).find(
                      (o) => selectedSortItemList[o].value
                    )
                  ].group;
                if (group != sortKey) {
                  disabled = true;
                }
              }
              return (
                <Panel disabled={disabled} header={sortKey} key={index}>
                  <div
                    style={{
                      overflowY: 'scroll',
                      maxHeight: 300,
                      paddingTop: 5,
                    }}
                  >
                    {sortData[sortKey].map((sortItem, ind) => (
                      <div
                        key={ind}
                        className="d-flex justify-content-between align-items-center sort-item-container mb-4"
                      >
                        {sortItem}
                        <Checkbox
                          checked={
                            !!(
                              selectedSortItemList &&
                              selectedSortItemList[sortItem] &&
                              selectedSortItemList[sortItem].value
                            )
                          }
                          onChange={(e) => selectSortItem(e, sortItem, sortKey)}
                          className="custom-antd-checkbox"
                        />
                      </div>
                    ))}
                  </div>
                </Panel>
              );
            })}
        </Collapse>
      </CustomRightDrawer>
      <div className="summary-report-applications-wrapper">
        <Header
          showMenu={true}
          menuOnclick={() => setOpenSidebar(!openSidebar)}
        />
        <Container className="custom-container my-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="summary-dashboard-title ml-3">
                {`${labelToShow.toUpperCase()} APPLICATIONS`}
              </div>
              <div className="d-flex mt-3">
                {Object.keys(reports).map((item, index) => {
                  return (
                    <SubHeader
                      key={index}
                      index={index}
                      title={slugToTitle[item]}
                      value={
                        reports[item][
                          labelToShow == 'verification'
                            ? 'under_process'
                            : labelToShow
                        ]
                      }
                      subText={`${labelToShow
                        .slice(0, 1)
                        .toUpperCase()}${labelToShow.slice(1)}`}
                      slug={item}
                      applicationType={applicationType}
                      onClick={(slug) => {
                        if (slug != 'Total') {
                          setPage(1);
                          setApplicationType(slug);
                          setSearchText('');
                        }
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div>
              <ChasingCellBackButton />
              <div className="d-flex align-items-center mt-3">
                {sortItemListCheck ? (
                  <div
                    onClick={() => {
                      setPage(1);
                      setSelectedSortItemList({});
                      let variables = {
                        applicationType,
                        applicationStatus:
                          (label && labelToApplicationStatus[label]) ||
                          labelToShow.toUpperCase(),
                        page,
                        perPage: 10,
                        authorities: '',
                        districts: '',
                        ulbs: '',
                        circles: '',
                      };
                      getReportApplications({ variables });
                    }}
                    className="chasing-cell-applications-clear-container mr-4"
                  >
                    <img
                      src={require('../assets/images/dashboard-new/filter-clear.svg')}
                    />
                    <span className="ml-2">Clear</span>
                  </div>
                ) : null}
                <div
                  onClick={() => setOpenSort(true)}
                  className="chasing-cell-applications-sort-container mr-2"
                >
                  <img
                    src={require('../assets/images/dashboard-new/equalizer.png')}
                  />
                  <span className="chasing-cell-applications-sort-text mx-2">
                    Filter by
                  </span>
                  <img
                    src={require('../assets/images/dashboard-new/arrow.png')}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-flex justify-content-between mt-5">
            <div className="d-flex align-items-center">
              <span className="chasing-cell-applications-heading">
                List of Applications
              </span>
              <img
                src={require('../assets/images/dashboard-new/left-arrow.png')}
              />
              <span onClick={handleGoBackToOrigin} className="chasing-cell-applications-sub-heading cursor-pointer">
                {state && state.originComponent || 'Summary Report'}
              </span>
            </div>
            <div className="d-flex align-items-center">
              {sortItemListCheck ? (
                <div
                  onClick={() => {
                    setPage(1);
                    setSelectedSortItemList({});
                    let variables = {
                      applicationType,
                      applicationStatus: labelToShow.toUpperCase(),
                      page,
                      perPage: 10,
                      authorities: '',
                      districts: '',
                      ulbs: '',
                    };
                    getReportApplications({ variables });
                  }}
                  className="chasing-cell-applications-clear-container mr-4"
                >
                  <img
                    src={require('../assets/images/dashboard-new/filter-clear.svg')}
                  />
                  <span className="ml-2">Clear</span>
                </div>
              ) : null}
              <div
                onClick={() => setOpenSort(true)}
                className="chasing-cell-applications-sort-container mr-2"
              >
                <img
                  src={require('../assets/images/dashboard-new/equalizer.png')}
                />
                <span className="chasing-cell-applications-sort-text mx-2">
                  Filter by
                </span>
                <img
                  src={require('../assets/images/dashboard-new/arrow.png')}
                />
              </div>
              <input
                className="chasing-cell-applications-search-input"
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={searchApplications}
              />
            </div>
          </div> */}
          <div
            style={{
              overflowX: 'scroll',
              overflowY: 'visible',
            }}
          >
            <Table
              id="summary-table-to-xls"
              borderless
              className="Neutra-Demi custom-table"
            >
              <thead>
                <tr>
                  {[
                    'Sl.no',
                    'Application Id',
                    'Circle',
                    'ULB',
                    'District',
                    'Authority',
                    'ApplicationType',
                    'Applied Date',
                    'Approval Date',
                    'Status',
                    'Remarks',
                    '',
                  ].map((title, index) => {
                    return (
                      <th
                        key={index}
                        className="mt-3"
                        style={{
                          minWidth: index == 1 ? 210 : 120,
                          textAlign: 'center',
                        }}
                      >
                        <div
                          style={{
                            fontWeight: '600',
                            fontSize: '12px',
                            color: '#040E19',
                          }}
                        >
                          {title}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="text-center">
                {recordsToDisplay.map((record, index) => (
                  <tr
                    className="dashboard-table-row"
                    key={index}
                    style={{
                      backgroundColor: '#F9FAFB',
                      boxShadow: 'none',
                      textAlign: 'center',
                      border: '1px solid rgba(112, 112, 112, 0.09)',
                      borderTop: 'none',
                      borderRadius: 0,
                      height: 80,
                    }}
                  >
                    <td style={{ minWidth: 120 }}>
                      <div>{(page - 1) * 10 + (index + 1)}</div>
                    </td>
                    {[...record].map((item, ind) => {
                      return (
                        <td
                          key={ind}
                          style={{ minWidth: ind == 0 ? 210 : 120 }}
                        >
                          {ind === 10 ? (
                            <div
                              onClick={() =>
                                history.push(`/application-details/${item}`, {
                                  id: item,
                                  fromSummary: true,
                                })
                              }
                              className="chasing-cell-applications-view-btn"
                              style={{ fontSize: '12px', color: '#040E19' }}
                            >
                              {'View'}
                            </div>
                          ) : (
                            <div style={{ fontSize: '12px', color: '#040E19' }}>
                              {item}
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {/* <div className="d-flex flex-row justify-content-between mt-4"> */}
          <Pagination
            current={page}
            className="text-center"
            onChange={(selectedPage) => {
              if (page != selectedPage) {
                setPage(selectedPage);
              }
            }}
            total={totalCount}
            showSizeChanger={false}
          />
          {/* <ReactHTMLTableToExcel
              id="summary-table-xls-button"
              className="download-table-xls-button"
              table="summary-table-to-xls"
              filename="summary-reports"
              sheet="summary-reports"
              buttonText="Download as XLS"
            /> */}
          {/* </div> */}
        </Container>
      </div>
    </div>
  );
};

export default Summary;
