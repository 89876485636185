import React from 'react'
import CorrectionTabels from '../components/CorrectionTabels/CorrectionTabels'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import RightSideBar from '../components/RightSideBar/index';
import { useState } from 'react';
import { GET_OFFICERS_APPLICATION } from '../graphql/query/officersApplication';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { APPLICATION_CORRECTION } from '../graphql/query/application_Correction';

function CorrectionTabel(props) {
 
  const [showSidebar, setShowSidebar] = useState(false);
  const showSidebarHandler = (value) => {
    setShowSidebar(value);
  };
  const { id } = useParams();

  return (
    <>
    <div>
                  <Header />
      <Sidebar />
     
      <div className="main-content">
      <CorrectionTabels
                showSidebarHandler={showSidebarHandler}
                showSidebar={showSidebar}
               // appliIdentifier={applicationData?.officersApplication.applicationIdentifier}
      />
    

      </div>
     

    </div>
    <div >
    <RightSideBar/>

    </div>
    </>
  )
}

export default CorrectionTabel