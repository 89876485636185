import { gql } from '@apollo/client';

export const GET_OFFICER_PROFILE = gql`
  query {
    Officer @client {
      id
      __typename
      accessToken
      firstName
      lastName
    }
    
      me {
        id
        ulbName
        district
        authority
        firstName
        lastName
        isMandalOfficer
        mandalName
        actions
        role {
          name
        }
      }
  }
`;
