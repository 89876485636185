import React, { useState } from 'react';
import { Form, Button, Table } from 'react-bootstrap';
import axios from 'axios';
import environment from 'environment';

export default function FeeCalculator() {
  const [applicationType, setApplicationType] = useState('SW');
  const [buildingPurpose, setBuildingPurpose] = useState('Residential');
  const [authority, setAuthority] = useState('DTCP');
  const [ulbGrade, setUlbGrade] = useState('1');
  const [plotArea, setPlotArea] = useState('');
  const [height, setHeight] = useState('');
  const [approvalType, setApprovalType] = useState('Approved Layout Plan');
  const [netPlotArea, setNetPlotArea] = useState('');
  const [builtupArea, setBuiltupArea] = useState('');
  const [floorBuiltupArea, setFloorBuiltupArea] = useState('');
  const [marketValue, setMarketValue] = useState('');
  const [vltTax, setVltTax] = useState('');
  const [plotDivision, setPlotDivision] = useState('');
  const [ghmcZoneArea, setGhmcZoneArea] = useState('');
  const [validated, setvalidated] = useState(false);
  const [fees, setFees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [totalFee, setTotalFee] = useState('');

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setvalidated(true);
    } else {
      let feeInput = {
        Plot_area: plotArea,
        Height: height,
        Application_type: applicationType,
        Building_purpose: buildingPurpose,
        Authority: authority,
        Ulb_grade: ulbGrade,
        Approval_type: approvalType,
        net_plot_area: netPlotArea,
        floor_built_up_area: floorBuiltupArea,
        total_builtup_area: builtupArea,
        vlt_tax: vltTax,
        market_value: marketValue,
        ghmc_zone_area: ghmcZoneArea,
        sub_div_of_plot: plotDivision,
      };
      console.log(feeInput);
      handleService();
    }
  };

  const handleService = () => {
    axios
      .get(`${environment.baseApi}/fee_calculator/get_total_fee_breakup`, {
        params: {
          application_type: applicationType,
          building_purpose: buildingPurpose,
          authority: authority,
          ulb_grade: ulbGrade,
          plot_area: plotArea,
          height: height,
          net_plot_area: netPlotArea,
          total_built_up_area: builtupArea,
          floor_built_up_area: floorBuiltupArea,
          approval_type: approvalType,
          vlt_paid: vltTax,
          market_value: marketValue,
          ghmc_zone_area: ghmcZoneArea,
          sub_div_of_plot: plotDivision,
        },
      })
      .then((res) => {
        setTotalFee(res.data['total_fee']);
        delete res.data['total_fee'];
        setFees(res.data);
        setIsLoading(false);
        setShowTable(true);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <img
          src={require('../assets/images/logo.png')}
          width="56"
          alt="logo here"
        />
        <h3>Fee Calculator</h3>
      </div>
      <div className=" px-5 pb-5" style={{ overflow: 'auto', height: '100%' }}>
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => handleSubmit(e)}
          className="mb-5"
        >
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Application Type</Form.Label>
            <Form.Control
              as="select"
              value={applicationType}
              onChange={(e) => setApplicationType(e.target.value)}
            >
              <option value={'SW'}>Single Window</option>
              <option value={'SC'}>INSTANT APPROVAL</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Building Purpose</Form.Label>
            <Form.Control
              as="select"
              value={buildingPurpose}
              onChange={(e) => setBuildingPurpose(e.target.value)}
            >
              <option>Residential</option>
              <option>Non Residential</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Authority</Form.Label>
            <Form.Control
              as="select"
              value={authority}
              onChange={(e) => setAuthority(e.target.value)}
            >
              <option>DTCP</option>
              <option>HMDA</option>
              <option>GHMC</option>
              <option>OTHERS</option>
            </Form.Control>
          </Form.Group>
          {authority === 'GHMC' ? null : (
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Ulb Grade</Form.Label>
              <Form.Control
                as="select"
                value={ulbGrade}
                onChange={(e) => setUlbGrade(e.target.value)}
              >
                <option value={1}>GRADE 1</option>
                <option value={2}>GRADE 2</option>
                <option value={3}>GRADE 3</option>
                <option value={4}>MUNICIPAL CORPORATION</option>
                <option value={5}>SPECIAL GRADE</option>
                <option value={6}>UDA</option>
                <option value={7}>GWMC</option>
              </Form.Control>
            </Form.Group>
          )}

          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Plot Area (Sqmts)</Form.Label>
            <Form.Control
              type="number"
              value={plotArea}
              onChange={(e) => setPlotArea(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Height (Mtrs)</Form.Label>
            <Form.Control
              type="number"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              {...(height <= 10 ? {} : { isInvalid: true })}
            />
            <Form.Control.Feedback type="invalid">
              Please choose height less than 10
            </Form.Control.Feedback>
          </Form.Group>
          {/* <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>No of floors</Form.Label>
            <Form.Control
              type="number"
              value={floors}
              onChange={(e) => setFloors(e.target.value)}
            />
          </Form.Group> */}
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Approval Type</Form.Label>
            <Form.Control
              as="select"
              value={approvalType}
              onChange={(e) => setApprovalType(e.target.value)}
            >
              <option>Approved Layout Plan</option>
              <option>Approved Building Plan</option>
              <option>Building Under BPS</option>
              <option>LRS Approved</option>
              <option>Gramkantam/Abadi</option>
              <option>Before 1985</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Net Plot Area (Sqmtrs)</Form.Label>
            <Form.Control
              type="number"
              value={netPlotArea}
              onChange={(e) => setNetPlotArea(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Total Builtup Area (Sqmtrs)</Form.Label>
            <Form.Control
              type="number"
              value={builtupArea}
              onChange={(e) => setBuiltupArea(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>First Floor Builtup Area (Sqmtrs)</Form.Label>
            <Form.Control
              type="number"
              value={floorBuiltupArea}
              onChange={(e) => setFloorBuiltupArea(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Is VLT tax paid?</Form.Label>
            <Form.Check
              type="radio"
              label="Yes"
              name="vltTaxRadio"
              onChange={(e) => setVltTax(e.target.value)}
              value={'true'}
            />
            <Form.Check
              type="radio"
              label="No"
              name="vltTaxRadio"
              onChange={(e) => setVltTax(e.target.value)}
              value={'false'}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Market value</Form.Label>
            <Form.Control
              type="number"
              value={marketValue}
              onChange={(e) => setMarketValue(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Does your plot falls under SUB DIVISION OF PLOT / AMALGAMATION OF
              PLOT?
            </Form.Label>
            <Form.Check
              type="radio"
              label="Yes"
              name="plotDivisionRadio"
              onChange={(e) => setPlotDivision(e.target.value)}
              value={'true'}
            />
            <Form.Check
              type="radio"
              label="No"
              name="plotDivisionRadio"
              onChange={(e) => setPlotDivision(e.target.value)}
              value={'false'}
            />
          </Form.Group>
          {authority === 'GHMC' ? (
            <Form.Group>
              <Form.Label>
                Does your plot located in Jubliee Hills/ Banjara Hills?
              </Form.Label>
              <Form.Check
                type="radio"
                label="Yes"
                name="plotLocationRadio"
                onChange={(e) => setGhmcZoneArea(e.target.value)}
                value={'JublieeHills/BanjaraHills'}
              />
              <Form.Check
                type="radio"
                label="No"
                name="plotLocationRadio"
                onChange={(e) => setGhmcZoneArea(e.target.value)}
                value={'Other Areas'}
              />
            </Form.Group>
          ) : null}
          {isLoading && (
            <Button variant="secondary">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </Button>
          )}
          {!isLoading && (
            <Button variant="primary" type="submit">
              Submit
            </Button>
          )}
        </Form>
        <div id="fee-calculator">
          {showTable && (
            <Table className="">
              <thead>
                <tr>
                  {Object.entries(fees).map(([key, value, index]) => {
                    if (value > 0) {
                      return <th key={index}>{key}</th>;
                    }
                  })}
                  <th>TOTAL FEE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {Object.entries(fees).map(([key, value, index]) => {
                    if (value > 0) {
                      return <td key={index}>{value}</td>;
                    }
                  })}
                  <td>{totalFee}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </>
  );
}
