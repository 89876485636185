import React from 'react';
import Header from '../components/Header';
import ChasingCellTable from '../components/ChasingcellAplications/ChasingCellAppTable'

export default function chasingCellApplications() {
  return (
    <div>
      <Header />
      <div style={{ backgroundColor: '#F8F8F8', paddingTop: "83px" }} >

        <ChasingCellTable />

      </div>
    </div>
  );
}
