import { gql } from '@apollo/client';

export const GET_REVIEW_REMARKS = gql`
query getQuestionsAndAnswers($id: ID!, $perPage: Int , $page: Int) {
  officersApplication(id: $id) {
    finalRemark
  }
  reviewRemarks(id: $id  , page : $page ,  perPage: $perPage) {
    id
    title
    status
    stage
    municipalFinalRemark
    stageState {
      dueDate
      completedDate
      finalRemark
      l1Remarks
      l2Remarks
      id
      name
      stageOfficers {
        action
        forwardedDate
        id
        officerLevel
        officerName
        officerRole
      }
      parentStageName
      receivedDate
      prevStartDate
      prevEndDate
      status
      submittedDate
    }
    answer {
      data
      dataRevised
      isFeesCompleted
      isChecklistFilled
      action
    }
    oldAnswer {
      data
      dataRevised
      isFeesCompleted
      isChecklistFilled
      action
    }
    beforeShowcase
    fields {
      id
      title

      fieldType
      defaultValue
      order
      dbField
      options {
        id
        value
        questions {
          id
          title
          fieldType
          defaultValue
          order
          dbField
          options {
            id
            value
            questions {
              id
              title
              fieldType
              defaultValue
              order
              dbField
              options{
                id
                value
                questions{
                  id
                }
              }
            }
          }
        }
      }
      children {
        title
        dbField
        fieldType
        defaultValue
        children {
          title
          fieldType
          defaultValue
          dbField
          children {
            title
            dbField
            fieldType
            defaultValue
          }
        }
      }
    }
  }
}
 
`;
