import React, { useState, useContext, useEffect, useRef } from 'react';
import './index.scss';
import { Card, Button, Form, Collapse, Modal } from 'react-bootstrap';
import history from '../../services/history';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import AnswerListComponent from '../AnswersListComponent';
import { UPDATE_APPLICATION_STATUS } from '../../graphql/mutatution/updateApplicationStatus';
import { GET_APPLICATION_QUESTIONS } from '../../graphql/query/applicationQuestions';
import { useMutation, useQuery } from '@apollo/client';
import { AppContext } from './../../AppContext';
import { APPLICATION_STATUS_ENUM } from '../../util/helperFunctions';
import NotesModal from '../NotesModal';
import AnswerListCommisioner from '../AnswerListCommisioner';
import MultiSelectDropdown from '../MultiSelectDropdown';
import { APPLICATION_COMMENTS } from '../../graphql/query/applicationComments';
import MultiSelectDropdown1 from '../MultiSelectDropdown/ShowCause';
import { GET_FILTERED_APPLICATIONS } from '../../graphql/query/officersFilteredApplications';
import FileUpload from '../FileUploadingComponent';
import { useCallback } from 'react';
import { CREATE_COMMENT } from '../../graphql/mutatution/createComment';

const verificationItemsList = [
  {
    title: 'Title Verification',
    subTitle: 'Final Remarks :',
    description:
      'Inspection And Test Procedures (And Their Associated Checklists) Should Clearly Specify Who Will Witness What (And Associated Hold Points), And Should Ideally Indicate When This Approximately Expected To Be.',
  },
  {
    title: 'Technical Verification',
    subTitle: 'Final Remarks :',
    description:
      'Inspection And Test Procedures (And Their Associated Checklists) Should Clearly Specify Who Will Witness What (And Associated Hold Points), And Should Ideally Indicate When This Approximately Expected To Be.',
  },
  {
    title: 'Site Verification',
    subTitle: 'Final Remarks :',
    description:
      'Inspection And Test Procedures (And Their Associated Checklists) Should Clearly Specify Who Will Witness What (And Associated Hold Points), And Should Ideally Indicate When This Approximately Expected To Be.',
  },
];

const verificationItemsGen = (verificationItemsList) => {
  return verificationItemsList.map((i, index) => {
    return (
      <div key={index} className="card-section">
        <h6>{i.title}</h6>
        <span>{i.subTitle}</span>
        <p>{i.description}</p>
      </div>
    );
  });
};




const verifcationCardFooterForPostVerification = (
  remarksView,
  setRemarksView,
  handleRemarksViewToggle,
  recommendationStatus,
  setRecommendationStatus,
  isLoading,
  submitApplication,
  finalRemark,
  setFinalRemark,
  applicationStatus,
  handleShowAfterSubmit,
  approveValidation,
  setApproveValidation,
  selected,
  setSelected,
  otherRemarks,
  setOtherRemarks,
  onApproveSubmit,
  onRevokeSubmit,
  applicationComments,
  handleshowCause,

  feild,
  attachmentUrl,
  memoizedHandleAnswerChange,
  newAddNote,
  createComment,
  CheckingFileUpload,
  isShowCauseRaisedDocUpload,
  applicationType,
  isShowCauseRaised,
  applicationDataStatus,
  isShowCauseSevenDaysElapsed,
  setButtonColor,
  buttonColor,

) => {

  console.log(isShowCauseSevenDaysElapsed, "isShowCauseSevenDaysElapsed")
  const didOfficerNotComment = () => {
    if (
      !applicationComments ||
      applicationComments?.applicationComments.length === 0
    ) {
      return true; // officer did not comment
    }
    let isThisOfficerCommentPresent = false; // did not comment
    applicationComments.applicationComments.forEach((el) => {
      // eslint-disable-next-line no-undef
      if (el.officer.id === sessionStorage.getItem('id')) {
        isThisOfficerCommentPresent = true; // did commnet
      }
    });

    return !isThisOfficerCommentPresent;


  };


  return (
    <>
      <div className="btn-grp">
        {/* {newsortedData == false ? :""} */}
        {applicationStatus !== 'DEEMED' &&
          <Button
            style={{ border: "1px solid grey", background: { buttonColor } }}
            variant={
              recommendationStatus === 'ACCEPTED'
                ? 'btn btn-success'
                : 'btn btn-light btn-recmnd'
            }
            onClick={() => {
              setRecommendationStatus('ACCEPTED');
              setRemarksView(true);
              // setButtonColor('green')
            }}
          >
            {/* <img alt="" src={require('../../assets/images/success-grey.svg')} /> */}
            APPROVE
          </Button>
        }

        {applicationType !== "OCCUPANCY" ? <Button
          style={{ border: "1px solid grey" }}
          disabled={(isShowCauseRaised == true) ? true : false}
          variant={
            recommendationStatus === 'SHOW_CAUSE_RAISED'
              ? 'btn btn-secondary'
              : 'btn btn-light btn-recmnd'
          }
          onClick={() => {
            setRecommendationStatus('SHOW_CAUSE_RAISED');
            setRemarksView(false);
            setFinalRemark('');
          }}
        >
          {/* <img alt="" src={require('../../assets/images/success-grey.svg')} /> */}
          Show Cause
        </Button> : " "}

        <Button
          style={{ border: "1px solid grey" }}
          disabled={applicationType !== "OCCUPANCY" && isShowCauseRaised == false ? true : false}
          variant={
            recommendationStatus === 'REJECTED'
              ? 'btn btn-danger'
              : 'btn btn-light btn-recmnd'
          }
          onClick={() => {
            setRecommendationStatus('REJECTED');
            setRemarksView(false);
            setFinalRemark('');
          }}
        >
          REVOKE
        </Button>


      </div>

      {/* 
      <div className="border-btm">
        <hr />
        <span>Choose an action and proceed</span>
      </div> */}



      {remarksView ? (
        <>
          {approveValidation ? (
            <div style={{ color: 'red', textAlign: 'right', fontSize: '14px' }}>
              Please select approved or revoked
            </div>
          ) : null}

          <div className="bottom-section1">
            <Form>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="form-Label">Add Remarks</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder="Text here"
                  onChange={(event) => setFinalRemark(event.target.value)}
                />
              </Form.Group>
            </Form>
            {/* {console.log(applicationStatusList[applicationStatus])} */}
            <>
              {!isLoading && (
                <Button
                  // eslint-disable-next-line no-unneeded-ternary
                  disabled={onApproveSubmit() || didOfficerNotComment()}
                  variant="success"
                  onClick={() => {
                    if (!recommendationStatus) {
                      setApproveValidation(true);
                    } else {
                      setApproveValidation(false);
                      handleShowAfterSubmit();
                    }
                  }}
                  style={{ height: 'fit-content' }}
                >
                  SUBMIT<img alt="" src={require('../../assets/images/arrow.svg')} />
                </Button>
              )}
              {isLoading && (
                <Button variant="secondary">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </Button>
              )}
            </>
          </div>
          {didOfficerNotComment() ? (
            <p style={{ color: 'red' }}>
              Please add remarks in View Notes
            </p>
          ) : null}
        </>
      ) : (
        <>
          {approveValidation ? (
            <div style={{ color: 'red', textAlign: 'right', fontSize: '14px' }}>
              Please select approved or revoked
            </div>
          ) : null}
          <div className="bottom-section1">
            {recommendationStatus == 'REJECTED' ? <MultiSelectDropdown
              selected={selected}
              setSelected={setSelected}
              otherRemarks={otherRemarks}
              setOtherRemarks={setOtherRemarks}
              hidePadding={true}
              formStatus={recommendationStatus === 'REJECTED' && "NOT_RECOMMENDED"}

            /> : recommendationStatus == 'SHOW_CAUSE_RAISED' && applicationType !== "OCCUPANCY" ?
              <MultiSelectDropdown1
                selected={selected}
                setSelected={setSelected}
                otherRemarks={otherRemarks}
                setOtherRemarks={setOtherRemarks}
                hidePadding={true}
                recommendationStatus={recommendationStatus}
              /> : ""}

            {recommendationStatus == 'REJECTED' ? <>         {!isLoading && (
              <Button
                disabled={onRevokeSubmit()}
                variant="success"
                onClick={() => {
                  if (!recommendationStatus) {
                    setApproveValidation(true);
                  } else {
                    setApproveValidation(false);
                    handleShowAfterSubmit();
                  }
                }}
              >
                SUBMIT <img alt="" src={require('../../assets/images/arrow.svg')} />
              </Button>
            )}
            </>
              :
              // <></>             (isShowCauseRaised !== false && (isShowCauseRaisedDocUpload == false && isShowCauseSevenDaysElapsed == false))   &&

              <>         {!(isShowCauseRaised !== false && (isShowCauseRaisedDocUpload == false && isShowCauseSevenDaysElapsed == false)) && !isLoading && (
                <Button
                  disabled={onRevokeSubmit()}
                  variant="success"
                  onClick={() => {

                    handleshowCause();

                  }}
                >
                  SUBMIT <img alt="" src={require('../../assets/images/arrow.svg')} />
                </Button>
              )} </>
            }
            {!(isShowCauseRaised !== false && (isShowCauseRaisedDocUpload == false && isShowCauseSevenDaysElapsed == false)) && isLoading && (
              <Button variant="secondary">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
};
// eslint-disable-next-line no-unused-vars
const verifcationCardFooterForSingleWindow = (
  remarksView,
  handleRemarksViewToggle,
  applicationId,
  setFinalRemark,
  applicationStatus,
  showRecommend,
  handleSubmitStatus,
  recommendationStatus,
  setRecommendationStatus,
  showTextArea,
  isShowCase,
  isShowCauseRaisedDocUpload,
  applicationType

) => {

  return (
    <>
      {' '}
      {showTextArea && (
        <div>
          <Form>
            <Form.Label className="form-Label">Final Remarks</Form.Label>

            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Text here"
              onChange={(event) => setFinalRemark(event.target.value)}
            />
          </Form>
        </div>
      )}
      {showRecommend && (
        <div className="bottom-section d-flex flex-row justify-content-around p-2">
          <Button
            variant="outline-success"
            className={
              recommendationStatus === 'NOT_RECOMMENDED'
                ? 'btn btn-secondary'
                : 'btn btn-light btn-notrecmnd'
            }
            onClick={() => {
              setRecommendationStatus('NOT_RECOMMENDED');
            }}
            style={{
              cursor: 'pointer',
              backgroundColor: 'transparent',
              color: '#499761',
              display: 'inline-block',
            }}
          >
            NOT RECOMMEND
          </Button>
          <Button
            variant="outline-success"
            className={
              recommendationStatus === 'RECOMMENDED'
                ? 'btn btn-secondary'
                : 'btn btn-light btn-recmnd'
            }
            onClick={() => {
              setRecommendationStatus('RECOMMENDED');
            }}
            style={{
              cursor: 'pointer',
              backgroundColor: 'transparent',
              color: '#499761',
              float: 'right',
            }}
          >
            RECOMMEND
          </Button>
        </div>
      )}
      <div className="bottom-section">
        <button
          className="btn btn-light"
          onClick={(e) => {
            history.push('/pre-scrutiny');
          }}
        >
          <img
            src={require('../../assets/images/arrowBackwards.svg')}
            alt="previous"
            style={{ float: 'left', marginTop: '4px' }}
          />
          BACK
        </button>
        <Button
          variant="success"
          style={{ cursor: 'pointer' }}
          onClick={handleSubmitStatus}
        >
          SUBMIT <img alt="" src={require('../../assets/images/arrow.svg')} />
        </Button>
      </div>
    </>
  );
};

function VerificationCards(props) {
  const [buttonColor, setButtonColor] = useState("")
  const feild = { fieldType: 'FILE' };
  const ansState = useRef({});
  const handleChildAnswerChange = (dbField, value) => {
    // console.log('value is ', ansState.current[dbField]);
    ansState.current[dbField] = value;
    setAttachmentUrl(value.parent_answer);
  };

  const memoizedHandleAnswerChange = useCallback(handleChildAnswerChange, [
    ansState,
  ]);
  const [attachmentUrl, setAttachmentUrl] = useState('');

  const [createComment, { loadingss }] = useMutation(CREATE_COMMENT);


  //  console.log(props.isShowCase);
  const [submitStatus, setSubmitStatus] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [applicationStatus, setApplicationStatus] = useState(
    props?.applicationStatus
  );
  const [open, setOpen] = useState(true);
  const [showRecommend, setShowRecommend] = useState(true);
  const [showTextArea, setShowTextArea] = useState(true);
  const [isEnabledRefundButton, setIsEnabledRefundButton] = useState(false)

  const [recommendationStatus, setRecommendationStatus] = useState('');
  // eslint-disable-next-line no-unused-vars
  const { currentFlow } = useContext(AppContext);

  const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const verificationItems = verificationItemsGen(verificationItemsList);
  const [remarksView, setRemarksView] = useState('');
  const handleRemarksViewToggle = () => {
    setRemarksView(!remarksView);
  };
  const handleSubmitStatus = () => {
    setSubmitStatus(true);
  };



  const { data: applicationComments, loading1 } = useQuery(APPLICATION_COMMENTS, {
    fetchPolicy: 'cache-first',
    variables: { id: id },
  });

  // let isShowCauseRaised;d
  let CheckingFileUpload;


  // console.log(applicationComments?.applicationComments[0].application.isShowCauseRaised,'applicationCommentschewcking');
  if (applicationComments?.applicationComments !== null) {
    // isShowCauseRaised = applicationComments?.applicationComments[0]?.application?.isShowCauseRaised
    // CheckingFileUpload= applicationComments?.applicationComments[0]?.application?.isShowCauseRaisedDocUpload
  }




  const { data: answersData, loading, error } = useQuery(
    GET_APPLICATION_QUESTIONS,
    {
      fetchPolicy: 'network-only',
      variables: { id: id },
    }
  );

  useEffect(() => {
    if (props?.applicationStatus === 'DEEMED' && props?.applicationType === "SELF CERTIFICATION") {
      console.log('deemed-self');
      setIsEnabledRefundButton(true)
    }
  }, [props?.applicationStatus, props?.applicationType])

  useEffect(() => {
    if (answersData && props.applicationType === "SELF CERTIFICATION") {
      if (props?.applicationStatus !== 'DEEMED') {
        answersData.applicationForms.map((appForm) => {
          console.log('answers', appForm)


          if (appForm.stage === "SITE VERIFICATION" && appForm.answer) {

            if (appForm.answer.data['Whether_ the_ construction_ work_ is_ Commenced']) {
              if (appForm.answer.data['Whether_ the_ construction_ work_ is_ Commenced'].parent_answer === 'false') {
                setIsEnabledRefundButton(true);
              }
            } else if (appForm.answer.data['whether_the_construction_work_is_commenced']) {
              if (appForm.answer.data['whether_the_construction_work_is_commenced'].parent_answer === 'false') {
                setIsEnabledRefundButton(true);
              }
            } else if (appForm.answer.data['whether_the_site_is_vacant']) {
              if (appForm.answer.data['whether_the_site_is_vacant'].parent_answer === 'false') {
                if (appForm.answer.data['whether_the_site_is_vacant']["work_commenced_or_not"] && appForm.answer.data['whether_the_site_is_vacant']["work_commenced_or_not"].parent_answer === 'false') {
                  setIsEnabledRefundButton(true);
                }
              } else if (appForm.answer.data['whether_the_site_is_vacant'].parent_answer === 'true') {

                setIsEnabledRefundButton(true);
              }
            }
          }
          return 0;
        })
      }
    }
  }, [answersData]);

  // eslint-disable-next-line no-unused-vars
  const [submitForm, { called, errors }] = useMutation(
    UPDATE_APPLICATION_STATUS
  );
  const [isLoading, setIsLoading] = useState(false);
  const [finalRemark, setFinalRemark] = useState('');
  const [show, setShow] = useState(false);
  const [isDisabledNotes, setIsDisabledNotes] = useState(false);
  const handleClose = () => setShow(false);
  const [showPopUpAfterSubmit, setShowPopUpAfterSubmit] = useState(false);
  const handleClosePopUpAfterSubmit = () => setShowPopUpAfterSubmit(false);
  const handleShowAfterSubmit = () => setShowPopUpAfterSubmit(true);
  const [approveValidation, setApproveValidation] = useState(false);
  const [selected, setSelected] = useState([]);
  const [otherRemarks, setOtherRemarks] = useState('');


  const [showCause, setShowCause] = useState(false);

  const handleCloseshowCause = () => setShowCause(false);
  const handleshowCause = () => setShowCause(true);

  const onApproveSubmit = () => {
    if (recommendationStatus) {
      if (finalRemark) return false;
      return true;
    }
    return false;
  };

  const onRevokeSubmit = () => {
    if (!selected || !selected.length) {
      return true;
    }
    const otherSelection = selected.find((el) => el.value === 'Other');
    if (otherSelection && !otherRemarks) {
      return true;
    }
    return false;
  };

  const submitApplication = (doRefund = false) => {
    if (
      applicationStatus === APPLICATION_STATUS_ENUM.VERIFICATION_UNDER_PROGRESS
    ) {
      // eslint-disable-next-line no-undef
      alert(
        'Cannot submit for the applications which are in verification under progress state'
      );
      return;
    }
    if (!recommendationStatus) {
      // eslint-disable-next-line no-undef
      alert('Status is Mandatory');
      return;
    }
    let notRecommendedRemark = [
      ...selected.filter((item) => item.label !== 'Other'),
      { label: 'Other', value: otherRemarks },
    ].map((item) => item.value);

    let updatedFinalRemark =
      recommendationStatus === 'REJECTED' ? notRecommendedRemark : finalRemark;
    if (!updatedFinalRemark) {
      // eslint-disable-next-line no-undef
      alert('Remark is Mandatory Please add One');
      return;
    }
    setIsLoading(true);
    submitForm({
      variables: {
        applicationId: props.applicationId,
        finalRemark:
          typeof updatedFinalRemark === 'string'
            ? updatedFinalRemark
            : JSON.stringify(updatedFinalRemark),
        status: recommendationStatus,
        eligibilityForRefund: doRefund ? 'true' : 'false'
      },
    })
      .then(({ data: { updateApplicationStatus } = {} }) => {
        setIsLoading(false);
        if (updateApplicationStatus) {
          history.push('/post-verification');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        // eslint-disable-next-line no-undef
        alert(e);
      });
  };

  const SubmitShowCause = () => {

    if (!recommendationStatus) {
      // eslint-disable-next-line no-undef
      alert('Status is Mandatory');
      return;
    }
    let notRecommendedRemark = [
      ...selected.filter((item) => item.label !== 'Other'),
      { label: 'Other', value: otherRemarks },
    ].map((item) => item.value);
    let updatedFinalRemark =
      recommendationStatus === 'SHOW_CAUSE_RAISED' ? notRecommendedRemark : finalRemark;
    if (!updatedFinalRemark) {
      // eslint-disable-next-line no-undef
      alert('Remark is Mandatory Please add One');
      return;
    }
    setIsLoading(true);

    submitForm({
      variables: {
        applicationId: props.applicationId,
        finalRemark:
          typeof updatedFinalRemark === 'string'
            ? updatedFinalRemark
            : JSON.stringify(updatedFinalRemark),
        status: recommendationStatus,
        eligibilityForRefund: 'false'
      },
    })
      .then(({ data: { updateApplicationStatus } = {} }) => {
        setIsLoading(false);
        if (updateApplicationStatus) {
          history.push('/post-verification');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        // eslint-disable-next-line no-undef
        alert(e);
      });
  }

  const newAddNote = () => {
    setRecommendationStatus('PROCEEDINGS_IN_PROGRESS');
    setRemarksView(false);
    setFinalRemark('');
    //setIsNotesButtonLoading(true);
    // if (!recommendationStatus) {
    //   // eslint-disable-next-line no-undef
    //   alert('Status is Mandatory');
    //   return;
    // }
    setIsLoading(true);

    submitForm({
      variables: {
        applicationId: props.applicationId,
        finalRemark: finalRemark,
        status: "PROCEEDINGS_IN_PROGRESS",
        eligibilityForRefund: 'false'
      },
    })
      .then(() => {
        setIsLoading(false);

        history.push('/post-verification');

      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        // eslint-disable-next-line no-undef
        alert(e);
      });


  };

  const ModalComponent = () => {
    return (
      <Modal
        show={showPopUpAfterSubmit}
        onHide={handleClosePopUpAfterSubmit}
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: '0 none', paddingBottom: '0px' }}
        />
        <Modal.Body style={{ paddingTop: '0px' }}>
          <div className="px-2">
            {`Are you sure you want to  ${recommendationStatus === 'ACCEPTED' ? 'Approve' : 'Revoke'
              } and submit?`}
          </div>
          {props.applicationType === "SELF CERTIFICATION" && props?.applicationStatus !== 'DEEMED' ?
            isEnabledRefundButton ?
              <span className="px-2 rejected-note"><b>Note : </b> Construction Work Not Commenced (Site Verified)</span> :
              <span className="px-2 rejected-note"><b>Note : </b> Revoked without refund as the work has commenced</span>
            : ""}
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: '0 none' }}
          className="d-flex justify-content-between"
        >
          <Button
            className="btn btn-secondary"
            onClick={() => handleClosePopUpAfterSubmit()}
            style={{ width: isEnabledRefundButton ? '20%' : '30%' }}
          >
            Cancel
          </Button>
          {!(props.applicationStatus === 'DEEMED' && props.applicationType === "SELF CERTIFICATION") &&
            <Button
              className="btn btn-success"
              onClick={() => {
                submitApplication();
                handleClosePopUpAfterSubmit();
              }}
              style={{ width: isEnabledRefundButton ? '30%' : '60%' }}
            >
              {recommendationStatus === 'ACCEPTED' ? 'Approved' : 'Revoke'}
            </Button>}
          {(recommendationStatus === 'REJECTED' && isEnabledRefundButton) &&
            <Button
              className="btn btn-success"
              onClick={() => {
                submitApplication(true);
                handleClosePopUpAfterSubmit();
              }}
              style={{ width: '35%' }}
            >
              Revoke With Refund
            </Button>}
        </Modal.Footer>
      </Modal>
    );
  };

  const ShowCausePopup = () => {
    return (
      <Modal
        show={showCause}
        onHide={handleCloseshowCause}
        centered>
        <Modal.Header
          style={{ borderBottom: '0 none', paddingBottom: '0px' }}
          closeButton>

        </Modal.Header>
        <Modal.Body>Are you sure you want to issue show cause and submit?</Modal.Body>
        <Modal.Footer>
          <Button style={{ width: '30%' }} variant="secondary" onClick={handleCloseshowCause}>
            Close
          </Button>
          <Button style={{ width: '44%' }} variant="primary" onClick={() => {
            SubmitShowCause();
            handleCloseshowCause();
          }}>
            show Cause
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  useEffect(() => {
    if (recommendationStatus) {
      setApproveValidation(false);
    }
  }, [recommendationStatus]);
  useEffect(() => {
    if (isDisabledNotes) {
      let style = 'cursor: not-allowed; opacity: 0.5';
      document.getElementById('viewButton').style.cssText = style;
      document.getElementById('addButton').style.cssText = style;
    }
  }, [isDisabledNotes]);

  return (
    <>
      {ModalComponent()}
      {ShowCausePopup()}
      <div className="main-content">
        <div className="tv-container ">
          <div className="tv-header">
            <ol className="breadcrumbs">
              {/* <li
                className="active"
                style={{ cursor: 'pointer',width:"100px" }}
                onClick={(e) => {
                  history.push('/post-verification');
                }}
              >
                <a>Post Verification</a>
              </li> */}
              <li >
                <a>{props.applicationIdentifier}</a>
              </li>
            </ol>
            {show ? <NotesModal handleClose={handleClose} /> : null}
            <div style={{ marginLeft: "20px" }} className=" d-flex align-items-center ">
              <span className="addnote_label_text">Add Note Sheet :</span>
              <div className=" d-flex add_note_wrapper ml-3  ">
                <div
                  className="viewButton"
                  type="button"
                  onClick={() => {
                    if (!isDisabledNotes) setShow(true);
                  }}
                  id="viewButton"
                >
                  VIEW
                </div>
                <div
                  className="addButton"
                  type="button"
                  onClick={() => {
                    if (!isDisabledNotes) setShow(true);
                  }}
                  id="addButton"
                >
                  ADD
                </div>
              </div>
            </div>
          </div>
          {props.pageType === 'single-window' && (
            <div className="tv-content">
              <div className="ad-header-answerList">
                <h5
                  type="button"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  Verify and Proceed with the remarks
                  {open}
                </h5>
                <AnswerListCommisioner
                  id={id}
                  showShortFall={true}
                  setShowRecommend={setShowRecommend}
                  submitStatus={submitStatus}
                  recommendationStatus={recommendationStatus}
                  finalRemarks={finalRemark}
                  setShowTextArea={setShowTextArea}
                  setIsDisabledNotes={setIsDisabledNotes}
                />
              </div>

              {props.pageType === 'single-window' &&
                verifcationCardFooterForSingleWindow(
                  remarksView,
                  handleRemarksViewToggle,
                  id,
                  setFinalRemark,
                  applicationStatus,
                  showRecommend,
                  handleSubmitStatus,
                  recommendationStatus,
                  setRecommendationStatus,
                  showTextArea,
                  props.isShowCauseRaised,
                  props.applicationType,
                  props.isShowCauseSevenDaysElapsed,
                  props.isloading
                )}
            </div>
          )}

          {props.pageType === 'post-verification' && (
            <div className="tv-content">
              <h6>Verify</h6>
              <Card>
                <Card.Body>
                  {/* <Card.Title className="title">
                      Verify and proceed with the remarks of
                    </Card.Title>
                    <Card.Subtitle className="subTitle">
                      Title, Technical and Site Verifications
                    </Card.Subtitle>
                    {verificationItems} */}

                  {/* <div className="d-flex pb-5 pt-5 shortfall-toggle justify-content-between align-items-center">
                      <div className="d-flex flex-column">
                        <h4>Do you like to Raise Shortfall </h4>
                        <span>Turn on to yes to raise</span>
                      </div>
                      <div>
                        <Form>
                          <input type="checkbox" id="test1" />
                          <label htmlFor="test1" />
                        </Form>
                      </div>
                    </div> */}
                  <div className="ad-header-answerList">
                    <h5
                      type="button"
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                    >
                      Answers
                      {open}
                    </h5>
                    <Collapse in={open}>
                      <div id="example-collapse-text">
                        <AnswerListComponent id={id} showShortFall={false} />
                      </div>
                    </Collapse>
                  </div>
                  {props.pageType === 'post-verification' &&
                    verifcationCardFooterForPostVerification(
                      remarksView,
                      setRemarksView,
                      handleRemarksViewToggle,
                      recommendationStatus,
                      setRecommendationStatus,
                      isLoading,
                      submitApplication,
                      finalRemark,
                      setFinalRemark,
                      applicationStatus,
                      handleShowAfterSubmit,
                      approveValidation,
                      setApproveValidation,
                      selected,
                      setSelected,
                      otherRemarks,
                      setOtherRemarks,
                      onApproveSubmit,
                      onRevokeSubmit,
                      applicationComments,
                      handleshowCause,
                      feild,
                      attachmentUrl,
                      memoizedHandleAnswerChange,
                      newAddNote,
                      createComment,
                      CheckingFileUpload,
                      props.isShowCauseRaisedDocUpload,
                      props.applicationType,
                      props.isShowCauseRaised,
                      props.applicationDataStatus,
                      props.isShowCauseSevenDaysElapsed,
                      props.isloading,
                      setButtonColor,
                      buttonColor

                    )}
                </Card.Body>
              </Card>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

VerificationCards.propTypes = {
  pageType: PropTypes.string,
  applicationId: PropTypes.string,
  applicationStatus: PropTypes.string,
  applicationIdentifier: PropTypes.string,
};

VerificationCards.defaultProps = {
  pageType: 'post-verification',
};

export default VerificationCards;



