
// import { useQuery } from '@apollo/client';
// import React, { useEffect, useState } from 'react'
// import { useContext } from 'react';
// import { useParams } from 'react-router-dom';
// import { AppContext } from '../AppContext';
// import CorrectionView from '../components/CorrectionView'
// import Header from '../components/Header'
// import Sidebar from '../components/Sidebar'
// import { APPLICATION_CORRECTION_DETAILS } from '../graphql/query/applicationCorrectionData';
// import { FILTER_LIST_CORRECTION, FILTER_LIST_ENUM } from '../util/helperFunctions';

// function CommisionerCorrectionView() {
//     const { permissionLevel, innerCorrectionValue } = useContext(AppContext);
//     const [actualFilter, setActualFilter] = useState(
//         innerCorrectionValue || FILTER_LIST_CORRECTION.correction
//       );
      
//        useEffect(()=>{
       
//         setActualFilter(innerCorrectionValue)
        
//        },[innerCorrectionValue])

//   return (
//     <div>
//                     <Header />
//       <Sidebar />
//             <CorrectionTabels
//                 showSidebarHandler={showSidebarHandler}
//                 showSidebar={showSidebar}
//                // appliIdentifier={applicationData?.officersApplication.applicationIdentifier}
//       />
    
//     </div>
//   )
// }

// export default CommisionerCorrectionView

import React from 'react'
import CorrectionTabels from '../components/CorrectionTabels/CorrectionTabels'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import RightSideBar from '../components/RightSideBar/index';
import { useState } from 'react';
import { GET_OFFICERS_APPLICATION } from '../graphql/query/officersApplication';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { APPLICATION_CORRECTION } from '../graphql/query/application_Correction';
import CommessionerCorrectionTabels from '../components/CommissionerCorrectionTabel.js';

function CommisionerCorrectionView(props) {
  const [showSidebar, setShowSidebar] = useState(false);
  const showSidebarHandler = (value) => {
    setShowSidebar(value);
  };
  const { id } = useParams();

  return (
    <>
    <div>
                  <Header />
      <Sidebar />
     
      <div className="main-content">
      <CommessionerCorrectionTabels
                showSidebarHandler={showSidebarHandler}
                showSidebar={showSidebar}
               // appliIdentifier={applicationData?.officersApplication.applicationIdentifier}
      />
    

      </div>
     

    </div>
    <div >
    <RightSideBar/>

    </div>
    </>
  )
}

export default CommisionerCorrectionView