import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, Card, Form, Tab, Button } from 'react-bootstrap';
import Header from '../components/Header';
import CustomSidebar from '../components/CustomSidebar';
import history from '../services/history';
import { useLazyQuery } from '@apollo/client';
import LoadingScreen from '../components/LoadingScreen';
import ErrorScreen from '../components/ErrorScreen';
import ChasingCellBackButton from '../components/ChasingCellBackButton';
import { GET_FILTERED_APPLICATIONS } from '../graphql/query/officersFilteredApplications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { PERMISSIONS_LEVELS, dayDifference } from '../util/helperFunctions';
import { AppContext } from '../AppContext';
import StatusReviewModal from '../components/StatusReviewModal';

const SearchChasingCell = (props) => {
  let searchText = '';
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false);
  
  const { permissionLevel } = useContext(AppContext);
  const [getSearchResults, { loading, error, data, refetch }] = useLazyQuery(
    GET_FILTERED_APPLICATIONS
  );

  const handleClose = () => {
    setShow(false)
  }
  useEffect(() => {
    if (search) {
      getSearchResults({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
          filter: 'ALL',
          searchParam: search,
          page: 1,
        },
      });
    }
  }, [search]);
  
   const handleShowPropsalFLow = () => {
    setShow(true)
   }

  //  const getStatus = (str) => {
  //   let result = str.replace(/_/g, ' ');
  //   if (
  //     props?.data?.applicationType === 'SELF CERTIFICATION' ||
  //     props?.data?.applicationType === 'REGISTRATION'
  //   ) {
  //     if (result === 'REJECTED') {
  //       return 'REVOKED';
  //     }
  //   }
  //   if (result === 'PROCEEDINGS IN PROGRESS') {
  //     return 'FINAL PROCESSING';
  //   }
  //   if (result === 'DEEMED') {
  //     return 'DEEMED APPROVED';
  //   }
  //   return result;
  // };

  let sortedData;
  if (data && data.applicationsByDateAndTypeFilter[0]?.records.length) {
    const cloneOfficersApplications = [
      ...data.applicationsByDateAndTypeFilter[0]?.records,
    ];
    sortedData = cloneOfficersApplications
    // .sort(sortCompare)
    //  .filter((app) => app.applicationType !== 'SINGLE WINDOW');
    if (
      permissionLevel === PERMISSIONS_LEVELS.SITE_VERIFICATION ||
      permissionLevel === PERMISSIONS_LEVELS.TITLE_VERIFICATION
    ) {
      sortedData = sortedData.filter(
        (app) => app.status !== 'FEE_VERIFICATION' // update this if fee-verification status is comming in another form
      );
    }
  }

  console.log(sortedData, 'sortedData')
  // eslint-disable-next-line no-unused-vars
  const classType = {
    red: 'rejected',
    green: 'approved',
    orange: 'inprogress',
    deemed: 'deemed',
  };
  const mapClassTypeFlag = {
    rejected: 'red',
    approved: 'green',
    inprogress: 'orange',
    deemed: 'blue',
  };
  const formatDateToString = (date) => {
    date = date.split(' ')[0];
    date = new Date(date);
    var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
    var MM = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
    var yyyy = date.getFullYear();
    return dd + '/' + MM + '/' + yyyy;
  };
  function getClassFromDueDate(dueDate) {
    const dayDiff = dayDifference(dueDate);
    if (dayDiff <= 0) return classType.red;
    return classType.green;
  }

  const Innerbody = ({ data, index }) => {
    const getStatus = (str) => {
      let result = str.replace(/_/g, ' ');
      if (
        data.applicationType === 'SELF CERTIFICATION' ||
        data.applicationType === 'REGISTRATION'
      ) {
        if (result === 'REJECTED') {
          return 'REVOKED';
        }
      }
      if (result === 'PROCEEDINGS IN PROGRESS') {
        return 'FINAL PROCESSING';
      }
      if (result === 'DEEMED') {
        return 'DEEMED APPROVED';
      }
      return result;
    };
    return (
      <tr className={getClassFromDueDate(data.dueDate)}>
        <td nowrap="true">
          <span className="flag">
            <img
              src={require(`../assets/images/${mapClassTypeFlag[getClassFromDueDate(data.dueDate)]
                }.svg`)}
            />
          </span>
        </td>
        <td>{index}</td>
        <td nowrap="true" className="text-left">
          <p>{data.applicationIdentifier}</p>
          <span>{data.applicant.applicantName}</span>
        </td>
        <td nowrap="true">
          {formatDateToString(data.appliedOn ? data.appliedOn : data.createdAt)}
        </td>
        <td nowrap="true">
          <p>
            {data.applicationType === 'SELF CERTIFICATION'
              ? 'INSTANT APPROVAL'
              : data.applicationType === 'REGISTRATION'
                ? 'INSTANT REGISTRATION'
                : data.applicationType}
          </p>
        </td>

        <td nowrap="true">
          <p>{data.property.actualPlotArea + ' sqm'}</p>
          <span>{data.property.floor?.noOfFloors}</span>
        </td>

        <td>--</td>
        <td nowrap="true">{data.isCorrection == true ? "Yes" :"No"}</td>
        {data.isCorrection && <td nowrap="true" >{data?.correctionStatus?.replace(/_/g, ' ')}</td>}
    
        {/* <td onClick={handleShowPropsalFLow} nowrap="true">
          {data.status !== null && (
            <p style={{ whiteSpace: 'normal' }}>
              {data.status && getStatus(data.status)}
            </p>
          )}

<div className="date-container">
              {show ? (
                <StatusReviewModal
                  id={data.id}
                  handleClose={handleClose}
                  status={data.status && getStatus(data.data.status)}
                />
              ) : null}
            </div>
        </td> */}
        <td nowrap="true">
            {data.status !== null && (
              <buttion 
                onClick={handleShowPropsalFLow}
                style={{ whiteSpace: 'normal' }}
                //  className="newbutton"
                   className={`btn ${getClassFromDueDate(data.dangerDate)}`}
              >
                {data.status && getStatus(data.status)}
              </buttion>
            )}
            <div className="date-container">
              {show ? (
                <StatusReviewModal
                  id={data.id}
                  handleClose={handleClose}
                  status={data.status && getStatus(data.status)}
                />
              ) : null}
            </div>
          </td>
        <td>
          <Button
            style={{ cursor: 'pointer' }}
            onClick={(event) => {
              event.stopPropagation();
              history.push(`/application-details/${data.id}`, {
                applicationType: data.applicationType,
                // enableNotes: props.enableNotes,
              });
            }}
          >
            View
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <div className="dashboard-new-wrapper">
      <Header />
      <div className="row m-0">
        <CustomSidebar noOverlay={true} isOpen={true} />
        <Container className="custom-container my-4 col-9">
          <ChasingCellBackButton />
          <div className="my-3">
            <Form className="my-1">
              <Form.Group
                controlId="formBasicEmail"
                className="searchBar m-0 d-flex"
              >
                <Form.Control
                  type="search"
                  placeholder="Search by application file number (or) applicant name"
                  onChange={(e) => (searchText = e.target.value)}
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      e.preventDefault();
                      setSearch(searchText);
                    }
                  }}
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  onClick={(e) => {
                    e.preventDefault();
                    setSearch(searchText);
                  }}
                  style={{
                    color: 'black',
                    marginLeft: '-28px',
                    marginTop: '8px',
                    fontSize: '24px',
                  }}
                />
              </Form.Group>
            </Form>
            {loading && <LoadingScreen />}
            {error && <ErrorScreen message={error} retry={refetch} />}
            {sortedData && sortedData.length > 0 ? (
              <div className="al-content">
                <Tab.Content>
                  <Table
                    striped
                    hover
                    style={{ textAlign: 'center', width: '100%' }}
                  >
                    <thead>
                      <tr>
                        <th className="" nowrap="true" />
                        <th nowrap="true">S.No.</th>
                        <th className="text-left" nowrap="true">
                          Application ID <br /> Applicant Name
                        </th>
                        <th nowrap="true">
                          Received Date <br />( DD/MM/YYYY )
                        </th>
                        <th nowrap="true">Application Type</th>
                        <th nowrap="true">Plot Area</th>
                        {/* <th nowrap="true">
                    Due Date <br />( DD/MM/YYYY )
                  </th> */}
                        <th nowrap="true">(Penalty &#8377;)</th>
                       
                    <th nowrap="true"> correction raised</th>
                    {sortedData.find(item => item.isCorrection == true) && <th nowrap="true"> correction status</th>}
                        <th nowrap="true" style={{ zIndex: '1001 ' }}>
                          Status
                        </th>
                        <th nowrap="true"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedData &&
                        sortedData.length > 0 &&
                        sortedData.map((item, index) => {
                          return (
                            <>
                              <Innerbody
                                key={index}
                                filter={'ALL'}
                                data={item}
                                index={index + 1}
                              // enableNotes={props.enableNotes}
                              />
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                </Tab.Content>
              </div>
            ) : (
              !error &&
              !loading && (
                <div className="m-3 pl-5">
                  <p style={{ fontSize: '24px' }}>
                    No results to display, try a different keyword for
                    searching.
                  </p>
                </div>
              )
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};
export default SearchChasingCell;
