import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import './index.scss';
import PropTypes from 'prop-types';
import { CREATE_SHORTFALL } from '../../graphql/mutatution/createShortfall';
import { useMutation } from '@apollo/client';

function AddRemark({
  setFinalRemark,
  formId,
  hideProceedButton,
  remarkHeadingText,
}) {
  const [remarksView, setRemarksView] = useState(false);
  const [mutation, { loading, error, data }] = useMutation(CREATE_SHORTFALL);
  const [remarks, setRemarks] = useState('');
  const handleRemarksViewToggle = () => {
    setRemarksView(!remarksView);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation({
      variables: {
        formInstanceId: formId,
        remark: remarks,
      },
    })
      .then((data) => {
        console.log(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  let proceed;
  if (loading) {
    proceed = (
      <Button variant="secondary">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Button>
    );
  } else if (data && !error && !loading) {
    proceed = (
      <Button variant="success">
        <span className="text-uppercase">Shortfall Submitted</span>
      </Button>
    );
  } else {
    proceed = (
      <Button
        variant="success"
        type="submit"
        className="d-flex align-items-center justify-content-between"
      >
        <span className="text-uppercase">Proceed</span>
        <img src={require('../../assets/images/arrow.svg')} alt="" />
      </Button>
    );
  }

  return (
    <>
      {remarksView ? (
        <div className="bottom-section">
          <div>
            <h6 type="button" onClick={() => handleRemarksViewToggle()}>
              {remarkHeadingText}
            </h6>
            <span>Click to add your remarks</span>
          </div>
        </div>
      ) : (
        <div
          style={{
            padding: '0 25px',
          }}
          className="remarks-section d-flex flex-column"
        >
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                }}
              >
                <Form.Label className="form-Label">
                  {remarkHeadingText}
                </Form.Label>
                <div onClick={() => handleRemarksViewToggle}>X</div>
              </div>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="Text here"
                onChange={(event) => {
                  setRemarks(event.target.value);
                  setFinalRemark(event.target.value);
                }}
              />
            </Form.Group>
            {!hideProceedButton && (
              <Row className={' align-items-center '}>
                <Col className="d-flex flex-row-reverse">{proceed}</Col>
              </Row>
            )}
          </Form>
          <span>{error?.message}</span>
        </div>
      )}
    </>
  );
}

AddRemark.propTypes = {
  setFinalRemark: PropTypes.func,
  formId: PropTypes.any,
  hideProceedButton: PropTypes.bool,
  remarkHeadingText: PropTypes.string,
};

AddRemark.defaultProps = {
  setFinalRemark: () => {},
  hideProceedButton: false,
  remarkHeadingText: 'Final Remarks',
};

export default AddRemark;
