import React, { useState} from 'react';
import Header from '../Header';
import ChasingSidebar from '../ChasingSidebar';
import { Card, CardDeck, Table } from 'react-bootstrap';
import BarGraph from '../BarGraph';
import { Link } from 'react-router-dom';

export default function ChasingDashboard() {
  const data = {
    head: [
      'Categories',
      'Received',
      'Approved',
      'Rejected',
      'Deemed',
      'Efficiency',
    ],
    body: [
      {
        label: 'Instant Registration',
        received: '500',
        approved: '200',
        rejected: '100',
        deemed: '100',
        efficiency: '95%',
      },
      {
        label: 'Instant Approval',
        received: '200',
        approved: '150',
        rejected: '50',
        deemed: '50',
        efficiency: '100%',
      },
      {
        label: 'Single Window',
        received: '600',
        approved: '300',
        rejected: '200',
        deemed: '100',
        efficiency: '83%',
      },
      {
        label: 'Total',
        received: '1300',
        approved: '650',
        rejected: '350',
        deemed: '250',
        efficiency: '77%',
      },
    ],
    R1: [
      'Categories',
      'Received',
      'Approved',
      'Rejected',
      'Deemed',
      'Efficiency'
    ],
    R2: [
      'Categories',
      'Received',
      'Approved',
      'Rejected',
      'Deemed',
      'Efficiency',
      'Completed',
      'Green',
      'Orange',
      'Red'
    ]
  };
  const tableDataList = (data) => {
    let tableList = data.body.map((t, index) => {
      return (
        <tr key={index}>
          {index === 3 ? (
            <td>
              <strong>{t.label}</strong>
            </td>
          ) : (
            <td>{t.label}</td>
          )}
          <td className="text-center">

            <strong>{t.received}</strong>
          </td>
          <td className="text-center">
            <strong>{t.approved}</strong>
          </td>
          <td className="text-center">
            <strong>{t.rejected}</strong>
          </td>
          <td className="text-center">
            <strong>{t.deemed}</strong>
          </td>
          <td className="text-center">
            <strong>{t.efficiency}</strong>
          </td>
        </tr>
      );
    });
    return tableList;
  };
  const tableData = tableDataList(data);

  const tableHeadList = (data) => {
    let tableList = data.R1.map((t, index) => {
      console.log(t)
      return (
          <th key={index}>
            {t}
          </th>
      );
    });
    return tableList;
  };
  const tableHeadData = tableHeadList(data);
  console.log(sessionStorage.getItem('currentChasingPage'))
  return (
    <div className="chasing-dashboard-new">
      {/* <Header /> */}
      {/* <ChasingSidebar /> */}
      <div className="content">
        <div className="d-flex justify-content-between pt-4 pb-3">
          <div className="fs-14 font-weight-bold">
            Summary of TG-bPASS (Building Plans)
          </div>
          <div className="fs-11 d-flex justify-content-between" style={{width: '35%'}}>
            <Link to="/reset-password" style={{color: 'black'}}>
              <img
                src={require('../../assets/images/received.svg')}
                alt="Received"
                className="pb-1 pr-1"
              />
              <span>Received</span>
            </Link>
            <Link to="/reset-password" style={{color: 'black'}}>
              <img
                src={require('../../assets/images/approved.svg')}
                alt="Approved"
                className="pb-1 pr-1"
              />
              Approved
            </Link>
            <Link to="/reset-password" style={{color: 'black'}}>
              <img
                src={require('../../assets/images/rejected.svg')}
                alt="Rejected"
                className="pb-1 pr-1"
              />
              Rejected
            </Link>
            <Link to="/reset-password" style={{color: 'black'}}>
              <img
                src={require('../../assets/images/deemed.svg')}
                alt="Deemed"
                className="pb-1 pr-1"
              />
              Deemed
            </Link>
          </div>
        </div>
        <CardDeck style={{ width: 'max-content' }}>
          <Card className="text-center">
            <BarGraph title={'Instant Registration'} data={data.body[0]}/>
          </Card>
          <Card className="text-center">
            <BarGraph title={'Instant Approval'}  data={data.body[1]}/>
          </Card>
          <Card className="text-center mr-n3">
            <BarGraph title={'Single Window'}  data={data.body[2]}/>
          </Card>
        </CardDeck>
        <div className="pt-4 pb-3 fs-12">
          <strong>Summary Report</strong>
        </div>
        <Table style={{ width: 'fitContent' }} id="dash">
          <thead>
            <tr>
              <th style={{cursor: 'default'}}>Categories</th>
              <th><img
                src={require('../../assets/images/received.svg')}
                alt="Received"
                className="pr-1 pb-1"
              />Received</th>
              <th>
              <img
                src={require('../../assets/images/approved.svg')}
                alt="Received"
                className="pr-1 pb-1"

              />Approved</th>
              <th><img
                src={require('../../assets/images/rejected.svg')}
                alt="Received"
                className="pr-1 pb-1"

              />Rejected</th>
              <th><img
                src={require('../../assets/images/deemed.svg')}
                alt="Received"
                className="pr-1 pb-1"

              />Deemed</th>
              <th><img
                src={require('../../assets/images/efficency_circle.svg')}
                alt="Received"
                className="pr-1 pb-1"

              />Efficiency</th>
              {/* {tableHeadData} */}
            </tr>
          </thead>
          <tbody style={{ backgroundColor: 'white' }}>{tableData}</tbody>
        </Table>
      </div>
    </div>
  );
}
