import React, { useEffect, useState } from 'react';
import './index.scss';
import { Select } from 'antd';
import axios from 'axios';
import environment from 'environment';
import { toast } from 'react-toastify';
import { Card } from 'react-bootstrap';

const { Option } = Select;

const ChasingCellFilter = ({
  filterAuthority,
  setFilterAuthority,
  filterDistricts,
  setFilterDistricts,
  filterULBS,
  setFilterULBS,
  applyFilter,
  disable,
  disableDistrict,
}) => {
  const [authority, setAuthority] = useState(['DTCP', 'GHMC', 'HMDA']);
  const [districts, setDistricts] = useState([]);
  const [ulbs, setUlbs] = useState([]);

  useEffect(() => {
    const authToken = sessionStorage.getItem('accessToken');
    if (authToken && filterAuthority.length) {
      let authorities = filterAuthority.join(",");
      axios
        .get(`${environment.baseApi}/villages/districts_for_authorites?authorities=${authorities}`, {
          headers: {
            Authorization: 'Bearer ' + authToken,
            Accept: '*/*',
          },
        })
        .then((response) => {
          if (response.data.success) {
            setDistricts(response.data.data.districts);
            if (disable) {
              setFilterDistricts(response.data.data.districts);
              applyFilter(true);
            }
          } else {
            setDistricts([]);
            toast.error(response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
          }
        })
        .catch(function (error) {
          setDistricts([]);
          if (error.response) {
            toast.error(error.response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
          }
        });
    }
  }, [filterAuthority.length]);

  useEffect(() => {
    const authToken = sessionStorage.getItem('accessToken');
    if (authToken && filterDistricts.length) {
      let authorities = filterAuthority.join(",");
      let districts = filterDistricts.join(",");
      axios
        .get(`${environment.baseApi}/villages/ulb_names_for_districts?authorities=${authorities}&districts=${districts}`, {
          headers: {
            Authorization: 'Bearer ' + authToken,
            Accept: '*/*',
          },
        })
        .then((response) => {
          if (response.data.success) {
            setUlbs(response.data.data.ulb_names);
            if (disable || disableDistrict) {
              setFilterULBS(response.data.data.ulb_names);
              applyFilter(true);
            }
          } else {
            setUlbs([]);
            toast.error(response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
          }
        })
        .catch(function (error) {
          setUlbs([]);
          if (error.response) {
            toast.error(error.response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
          }
        });
    }
  }, [filterDistricts.length]);

  return (
    <Card className="chasing-cell-filter-container d-flex flex-row justify-content-between align-items-center ml-3 my-3">
      <div className="d-flex flex-row align-items-center w-100">
        <Select
          showSearch
          style={{ width: '30%' }}
          value={filterAuthority}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          placeholder="Select Authority(ies)"
          allowClear
          mode="multiple"
          onChange={(value) => {
            if (!value.length) {
              setFilterDistricts([]);
              setFilterULBS([]);
            }
            setFilterAuthority(value);
          }}
          disabled={disable}
          maxTagCount={3}
        >
          <Option disabled={filterAuthority.length ? (authority.join() !== filterAuthority.join()) : false} key={'authority-0'} value={authority.join()}>All</Option>
          {
            authority.map((item, index) => {
              return (
                <Option
                  key={`authority-${index + 1}`}
                  value={item}
                  disabled={authority.join() === filterAuthority.join()}
                >
                  {item}
                </Option>
              )
            })
          }
        </Select>
        <Select
          showSearch
          style={{ width: '30%' }}
          value={filterDistricts}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          placeholder="Select District(s)"
          allowClear
          mode="multiple"
          onChange={(value) => {
            if (!value.length) {
              setFilterULBS([]);
            }
            setFilterDistricts(value);
          }}
          disabled={disableDistrict || !filterAuthority.length}
          maxTagCount={2}
        >
          <Option disabled={filterDistricts.length ? (districts.join() !== filterDistricts.join()) : false} key={'district-0'} value={districts.join()}>All</Option>
          {districts.map((item, index) => {
            return (
              <Option
                key={`district-${index + 1}`}
                value={item}
                disabled={districts.join() === filterDistricts.join()}
              >
                {item}
              </Option>
            )
          })}
        </Select>
        <Select
          showSearch
          style={{ width: '30%' }}
          value={filterULBS}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          placeholder="Select ULB(s)"
          allowClear
          mode="multiple"
          onChange={setFilterULBS}
          disabled={!filterDistricts.length}
          maxTagCount={2}
        >
          <Option disabled={filterULBS.length ? (ulbs.join() !== filterULBS.join()) : false} key={'ulbs-0'} value={ulbs.join()}>All</Option>
          {ulbs.map((item, index) => {
            return (
              <Option
                key={`ulbs-${index + 1}`}
                value={item}
                disabled={ulbs.join() === filterULBS.join()}
              >
                {item}
              </Option>
            )
          })}
        </Select>
      </div>
      <div
        className="apply-container"
        onClick={() => {
          applyFilter(true)
        }}
      >
        <span className="apply">Apply</span>
        <img src={require('../../assets/images/dashboard-new/long-arrow.svg')} />
      </div>
    </Card >
  );
}

export default ChasingCellFilter;
