import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import history from '../../services/history';
import { useQuery } from '@apollo/client';
import { GET_OFFICER_PROFILE } from '../../graphql/query/officerProfile';
import { hideChasingcellMenuItem } from '../../util/helperFunctions';

const CustomSidebar = ({ noOverlay, isOpen, onClose }) => {
  const path = history.location.pathname.slice(1);

  const { data } = useQuery(GET_OFFICER_PROFILE);

  return (
    <Menu
      class="col-3"
      styles={styles(noOverlay)}
      noOverlay={noOverlay}
      isOpen={isOpen}
      onClose={onClose ? onClose : () => { }}
      width={340}
      style={noOverlay ? { position: 'relative !important' } : {}}
    >
      {data1.map((item) => (
        <div
          key={item.id}
          className={`menu-item ${path === item.id ? 'active' : ''}`}
          onClick={() => history.push(`/${item.id}`)}
        >
          {item.label}
        </div>
      ))}
      <div className="menu-border" />
      <div>
        <div className="menu-sub-heading">Dashboard Links</div>
        {hideChasingcellMenuItem(data2, data?.me?.role?.name).map((item) => (
          <div
            key={item.id}
            style={{color : getColor(item.label)}}
            className={`menu-item small ${path === item.id ? 'active' : ''}`}
            onClick={()=>{
              window.open(item.url, '_blank');
            }}
          >
         {item.label}
          </div>
        ))}
      </div>
      <div className="menu-border" />
      {/* <div>
        <div className="menu-sub-heading">Occupancy</div>
        {data3.map((item) => (
          <div
            key={item.id}
            className={`menu-item small ${path === item.id ? 'active' : ''}`}
            onClick={() => history.push(`/${item.id}`)}
          >
            {item.label}
          </div>
        ))}
      </div> */}
    </Menu>
  );
};

export default CustomSidebar;
const getColor = (label) => {
  switch (label) {
    case 'Pendency Dashboard':
      return 'red';
    case 'Applicaiton Dashboard':
      return 'green';
    case 'Tickets Dashboard':
      return '#1a73e8';
    default:
      return 'black';
  }
};
const styles = (noOverlay) => ({
  bmMenuWrap: {
    position: noOverlay ? 'relative !important' : 'fixed',
  },
});

const data1 = [
  {
    id: 'summary-report',
    label: 'Summary Report',
  },
  {
    id: 'dashboard-new',
    label: 'Dashboard',
  },
  {
    id: 'search',
    label: 'Search',
  },
];

// below code is commented because  this details are not reuqired  to chasing cell Officers
// const data2 = [
//   {
//     id: 'building-permissions/annual-report',
//     label: 'Annual report',
//   },
//   {
//     id: 'building-permissions/monthly-report',
//     label: 'Monthly report',
//   },
//   {
//     id: 'building-permissions/ulb-report',
//     label: 'ULB Report',
//   },
//   {
//     id: 'building-permissions/district-report',
//     label: 'District Report',
//   },
//   {
//     id: 'building-permissions/authorities-report',
//     label: 'Authorities Report',
//   },
//   {
//     id: 'building-permissions/rejections-report',
//     label: 'Rejections Report',
//   },
//   {
//     id: 'building-permissions/deemed-approval-report',
//     label: 'Deemed Approval Report',
//   },
//   {
//     id: 'building-permissions/post-verification-teams-report',
//     label: 'Post Verification Teams Report',
//   },
//   {
//     id: 'building-permissions/scrutiny-officers-report',
//     label: 'Scrutiny Officers Report',
//   },
//   {
//     id: 'building-permissions/performance-report',
//     label: 'Performance Report',
//   },
//   {
//     id: 'building-permissions/penalties-report',
//     label: 'Penalties Report',
//   },
//   {
//     id: 'building-permissions/grievance-report',
//     label: 'Grievance Report',
//   },
// ];
// const data3 = [
//   {
//     id: 'occupancy/annual-report',
//     label: 'Annual report',
//   },
// ];

const data2 = [
  {
    id: 1,
    url : "https://lookerstudio.google.com/u/0/reporting/3aea762a-b437-4a3f-aa1e-1cd98ff369e9/page/p_dj7o4ld86c",
    label: 'Pendency Dashboard',
  },
  {
    id: 2,
    url : "https://lookerstudio.google.com/u/0/reporting/0dfd0da1-2a33-4799-be19-154fbd9a3734/page/p_mapmg5px7c",
    label: 'Applicaiton Dashboard',
  },
  {
    id: 3,
    url: 'https://lookerstudio.google.com/u/0/reporting/5f405e98-3a3c-4075-aefd-6f6aeb328129/page/ipIaD',
    label: 'Tickets Dashboard',
  },
]
