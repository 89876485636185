import React, { useEffect, useState } from 'react';
import { Container, Table, Card } from 'react-bootstrap';
import Header from '../components/Header';
import CustomDonutChart, { ItemCard } from '../components/CustomDonutChart';
import CustomSidebar from '../components/CustomSidebar';
import history from '../services/history';
import { REPORTS } from '../graphql/query/reports';
import { useLazyQuery, useQuery } from '@apollo/client';
import LoadingScreen from '../components/LoadingScreen';
import ErrorScreen from '../components/ErrorScreen';
import moment from 'moment';
import { Menu, Dropdown, Checkbox, Modal } from 'antd';
import ChasingCellFilter from '../components/ChasingCellFilter';
import { GET_OFFICER_PROFILE } from '../graphql/query/officerProfile';
import ChasingCellBackButton from '../components/ChasingCellBackButton';
import { YearsData } from '../util/helperFunctions';

const statusLabels = [
  {
    label: 'Received',
    color: '#0564AC',
    showOnlyInTable: true,
  },
  {
    label: 'Rejected',
    slug: 'rejected',
    color: '#FF4E4E',
    showOnlyInTable: false,
    showBtn: true,
  },
  {
    label: 'Approved',
    slug: 'approved',
    color: '#1C9A5B',
    showOnlyInTable: false,
    showBtn: true,
  },
  {
    label: 'Total',
    showOnlyInTable: false,
  },
  {
    label: 'Verification Under Process',
    slug: 'verification',
    color: '#0564AC',
    showOnlyInTable: false,
    showBtn: true,
  },
  // {
  //   label: 'Green',
  //   color: '#1C9A5B',
  //   completed: false,
  //   showOnlyInTable: true,
  // },
  // {
  //   label: 'Orange',
  //   color: '#FA7847',
  //   completed: false,
  //   showOnlyInTable: true,
  // },
  // {
  //   label: 'Red',
  //   color: '#FA4747',
  //   completed: false,
  //   showOnlyInTable: true,
  // },
  // {
  //   label: 'Total',
  //   color: '#FA4747',
  //   showOnlyInTable: true,
  // },
  {
    label: 'With Citizen',
    slug: 'with_citizen',
    color: '#FFD700',
    showOnlyInTable: true,
    showBtn: true,
  },
];

const verificationData = [
  { label: 'Green', value: 483, color: '#4CAF7E' },
  { label: 'Orange', value: 242, color: '#FA9547' },
  { label: 'Red', value: 575, color: '#FF7258' },
];

const slugToLabel = (year) => ({
  jan: { label: 'January', startDate: `${year}-01-01`, endDate: `${year}-01-31` },
  feb: { label: 'February', startDate: `${year}-02-01`, endDate: `${year}-02-${(year % 4) ? "28" : "29"}` },
  mar: { label: 'March', startDate: `${year}-03-01`, endDate: `${year}-03-31` },
  apr: { label: 'April', startDate: `${year}-04-01`, endDate: `${year}-04-30` },
  may: { label: 'May', startDate: `${year}-05-01`, endDate: `${year}-05-31` },
  jun: { label: 'June', startDate: `${year}-06-01`, endDate: `${year}-06-30` },
  jul: { label: 'July', startDate: `${year}-07-01`, endDate: `${year}-07-31` },
  aug: { label: 'August', startDate: `${year}-08-01`, endDate: `${year}-08-31` },
  sep: { label: 'September', startDate: `${year}-09-01`, endDate: `${year}-09-30` },
  oct: { label: 'October', startDate: `${year}-10-01`, endDate: `${year}-10-31` },
  nov: { label: 'November', startDate: `${year}-11-01`, endDate: `${year}-11-30` },
  dec: { label: 'December', startDate: `${year}-12-01`, endDate: `${year}-12-31` },
});

const TableCellOnclick = (monthly, showFilterBar, key, state, ulbState) => {
  if (showFilterBar || monthly) {
    history.push(`/summary/${key}`, state)
  } else {
    history.push(`/summary/${key}`, ulbState)
  }
};

const TableData = ({
  num,
  type,
  slug,
  data,
  selectMonth,
  selectedMonthList,
  openCompareModal,
  showFilterBar,
  officerDataULB,
  filterULBS,
  year,
  monthly,
}) => {
  const { received, approved, rejected, with_citizen, under_process, } = data;
  let state = {
    [type]: slug === 'Grand Total' ? '' : slug,
  };
  if ((type !== 'ULB') || (type === 'ULB' && slug === 'Grand Total')) {
    state['ULB'] = officerDataULB || filterULBS.join();
  };
  if (monthly && slug !== 'Grand Total') {
    state["startDate"] = slugToLabel(year)[slug.slice(0, 3)].startDate;
    state["endDate"] = slugToLabel(year)[slug.slice(0, 3)].endDate;
  };

  return (
    <tr className="dashboard-table-row">
      <td>{slug != 'Grand Total' ? (num + 1) : ''}</td>
      <td
        className="d-flex justify-content-between px-2 text-left"
        style={{ color: '#3F4A50', opacity: slug == 'Grand Total' ? 1 : 0.73 }}
      >
        {type ? slug : slug == 'Grand Total' ? 'Grand Total' : slugToLabel(year)[slug.slice(0, 3)].label}
        {(!openCompareModal && slug != 'Grand Total') ? (
          <Checkbox
            checked={!!(selectedMonthList && selectedMonthList[slug])}
            onChange={(e) => selectMonth(e, slug)}
            className="custom-antd-checkbox"
          />
        ) : null}
      </td>
      <td>{received}</td>
      <td
        className={`cursor-pointer`}
        onClick={() => TableCellOnclick(monthly, showFilterBar, "rejected", state, { ULB: officerDataULB || filterULBS.join(), })}
      >
        {rejected}
      </td>
      <td
        className={`cursor-pointer`}
        onClick={() => TableCellOnclick(monthly, showFilterBar, "approved", state, { ULB: officerDataULB || filterULBS.join(), })}
      >
        {approved}
      </td>
      <td>{rejected + approved}</td>
      {/* <td>50</td>
      <td>50</td>
      <td>50</td>
      <td>150</td> */}
      <td
        className={`cursor-pointer`}
        onClick={() => TableCellOnclick(monthly, showFilterBar, "verification", state, { ULB: officerDataULB || filterULBS.join(), })}
      >
        {under_process}
      </td>
      <td
        className={`cursor-pointer`}
        onClick={() => TableCellOnclick(monthly, showFilterBar, "with_citizen", state, { ULB: officerDataULB || filterULBS.join(), })}
      >
        {with_citizen}
      </td>
    </tr>
  );
};

const YearsList = (props) => (
  <Menu className="text-center p-3 select-year-menu">
    <div className="select-year-title">Select Year</div>
    {YearsData().map((year, index) => {
      return (
        <Menu.Item key={index} className="my-2">
          <div
            style={{
              fontFamily: 'Neutra Demi',
              color: props.presentYear == year ? '#40AA75' : '#040E19',
            }}
            onClick={() => props.setYear(year)}
          >
            {year}
          </div>
        </Menu.Item>
      );
    })}
  </Menu>
);

const FinancialYear = ({ type, monthly, }) => {
  const [reports, setReports] = useState(null);
  const [year, setYear] = useState(moment().format('YYYY'));
  const [tableData, setTableData] = useState([]);
  const [selectedMonthList, setSelectedMonthList] = useState({});
  const [openCompareModal, setOpenCompareModal] = useState(false);
  const [filterAuthority, setFilterAuthority] = useState([]);
  const [filterDistricts, setFilterDistricts] = useState([]);
  const [filterULBS, setFilterULBS] = useState([]);
  const [applyFilter, setApplyFilter] = useState(false);

  const { data: officerData, } = useQuery(GET_OFFICER_PROFILE);
  const [getReports, { loading, error, data, refetch }] = useLazyQuery(REPORTS);
  const [
    getMonthlyReports,
    { loading: monthlyLoading, data: monthlyData },
  ] = useLazyQuery(REPORTS);

  useEffect(() => {
    getReports({
      fetchPolicy: 'network-first',
      variables: {
        type: 'CONSOLIDATED',
        ulbs: officerData?.me?.ulbName || filterULBS.join(),
        startDate: `${year}-01-01`,
        endDate: moment(`${year}-12-31`).isAfter(moment().format('YYYY-MM-DD'))
          ? moment().format('YYYY-MM-DD')
          : `${year}-12-31`,
      },
    });
    getMonthlyReports({
      fetchPolicy: 'network-first',
      variables: {
        type: type || 'MONTHLY',
        ulbs: officerData?.me?.ulbName || filterULBS.join(),
        startDate: `${year}-01-01`,
        endDate: moment(`${year}-12-31`).isAfter(moment().format('YYYY-MM-DD'))
          ? moment().format('YYYY-MM-DD')
          : `${year}-12-31`,
      },
    });
    setApplyFilter(false);
    if (officerData?.me?.authority) {
      setFilterAuthority([officerData?.me?.authority || []]);
    }
    if (officerData?.me?.district) {
      setFilterDistricts([officerData?.me?.district || []]);
    }
  }, [year, applyFilter, officerData]);

  useEffect(() => {
    if (data) {
      let reports = null;
      if (Object.keys(data.reports).length) {
        reports = {
          received: Object.keys(data.reports)
            .map((o) => data.reports[o].received)
            .reduce((a, c) => a + c),
          approved: Object.keys(data.reports)
            .map((o) => data.reports[o].approved)
            .reduce((a, c) => a + c),
          rejected: Object.keys(data.reports)
            .map((o) => data.reports[o].rejected)
            .reduce((a, c) => a + c),
          with_citizen: Object.keys(data.reports)
            .map((o) => data.reports[o].with_citizen)
            .reduce((a, c) => a + c),
          under_process: Object.keys(data.reports)
            .map((o) => data.reports[o].under_process)
            .reduce((a, c) => a + c),
        };
      }
      setReports(reports);
    }
  }, [data]);

  useEffect(() => {
    if (monthlyData) {
      let tableData = [];
      if (Object.keys(monthlyData.reports).length) {
        tableData = Object.keys(monthlyData.reports).map((month) => {
          let monthData = monthlyData.reports[month];
          return {
            label: month,
            data: {
              received: Object.keys(monthData)
                .map((o) => monthData[o].received)
                .reduce((a, c) => a + c),
              approved: Object.keys(monthData)
                .map((o) => monthData[o].approved)
                .reduce((a, c) => a + c),
              rejected: Object.keys(monthData)
                .map((o) => monthData[o].rejected)
                .reduce((a, c) => a + c),
              with_citizen: Object.keys(monthData)
                .map((o) => monthData[o].with_citizen)
                .reduce((a, c) => a + c),
              under_process: Object.keys(monthData)
                .map((o) => monthData[o].under_process)
                .reduce((a, c) => a + c),
            },
          };
        });
        tableData.push({
          label: "Grand Total",
          data: {
            received: tableData.map(o => o.data.received).reduce((a, c) => a + c, 0),
            approved: tableData.map(o => o.data.approved).reduce((a, c) => a + c, 0),
            rejected: tableData.map(o => o.data.rejected).reduce((a, c) => a + c, 0),
            with_citizen: tableData.map(o => o.data.with_citizen).reduce((a, c) => a + c, 0),
            under_process: tableData.map(o => o.data.under_process).reduce((a, c) => a + c, 0),
          }
        });
      };
      setTableData(tableData);
    }
  }, [monthlyData]);

  const handleViewAll = (label) => {
    let pathName = history.location.pathname;
    history.push(`/summary/${label}`, {
      filter: type || null,
      originComponent:
        pathName.includes('annual-report') ? 'Annual Report' :
          pathName.includes('monthly-report') ? 'Monthly Report' : '',
      ULB: officerData?.me?.ulbName || filterULBS.join(),
    });
  };

  const selectMonth = (e, monthSlug) => {
    setSelectedMonthList({
      ...selectedMonthList,
      [monthSlug]: e.target.checked,
    });
  };

  if (loading) return <LoadingScreen />;

  if (error) {
    return <ErrorScreen message={error} retry={refetch} />;
  }

  var compareCheck =
    Object.keys(selectedMonthList).filter((o) => selectedMonthList[o]).length >
    1;
  let tableDataInCompare = tableData.filter((o) => selectedMonthList[o.label]);
  let showFilterBar = (type === 'ULB' || type === 'DISTRICT' || type === 'AUTHORITY');

  return (
    <div className="dashboard-new-wrapper">
      <Header />
      <div className="row m-0">
        <CustomSidebar noOverlay={true} isOpen={true} />
        <Container className="custom-container my-4 col-9">
          <ChasingCellBackButton />
          <div className="row justify-content-between">
            <div className="col-5 summary-dashboard-title ml-3">
              {`Financial Year ${year} - Building Plan Applications`}
            </div>
            <Dropdown
              overlay={() => <YearsList presentYear={year} setYear={setYear} />}
              trigger={['click']}
            >
              <div className="d-flex flex-row align-items-center year-select">
                <div style={{ marginRight: 10 }}>Select another Year</div>
                <img
                  src={require('../assets/images/dashboard-new/arrow.png')}
                />
              </div>
            </Dropdown>
          </div>
          <div className="col-lg-12 mt-4">
            {reports ? (
              <div className="row">
                <div className="col-md-6">
                  <CustomDonutChart
                    title={'Verification'}
                    data={[
                      {
                        label: 'Verification Under Process',
                        value: Number(`${reports.under_process}`),
                      },
                      {
                        label: 'Approved',
                        value: Number(`${reports.approved}`),
                      },
                      {
                        label: 'Rejected',
                        value: Number(`${reports.rejected}`),
                      },
                      { label: 'With Citizen', value: Number(`${reports.with_citizen}`) },
                    ]}
                    colors={['#0564AC', '#1C9A5B', '#FF4E4E', '#FFD700']}
                  />
                </div>
                <div className="col-md-6">
                  {/* <Card className="custom-bar-container custom-card">
                    <Card.Body>
                      <Card.Title className="Neutra-Demi font-size-16">
                        Verification Under Process
                      </Card.Title>
                      <div>
                        {verificationData.map((item, index) => (
                          <ItemCard
                            key={index}
                            label={item.label}
                            value={item.value}
                            style={{ color: item.color, fontSize: 23 }}
                          />
                        ))}
                      </div>
                    </Card.Body>
                  </Card> */}
                </div>
              </div>
            ) : (
                <div>No Data Found</div>
              )}
          </div>
          <div className="mt-5">
            <div className="summary-dashboard-title ml-3">Summary</div>
            {!(officerData?.me?.ulbName) ?
              <ChasingCellFilter
                filterAuthority={filterAuthority}
                setFilterAuthority={setFilterAuthority}
                filterDistricts={filterDistricts}
                setFilterDistricts={setFilterDistricts}
                filterULBS={filterULBS}
                setFilterULBS={setFilterULBS}
                applyFilter={setApplyFilter}
                disable={officerData?.me?.authority ? true : false}
                disableDistrict={officerData?.me?.district ? true : false}
              /> : null}
            <Card
              className="col m-3 mt-4 px-5 pb-5 pt-0 custom-card"
              style={{
                borderRadius: 10,
                overflowX: 'scroll',
                overflowY: 'visible',
              }}
            >
              <Table borderless className="Neutra-Demi custom-table width-auto">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th
                      style={{
                        color: '#3F4A50',
                        lineHeight: '30px',
                        borderTop: '3px solid #3FA974',
                        borderRight: '1px solid #ddd',
                      }}
                      className="Neutra-Demi font-size-15 text-center p-3"
                      colSpan="3"
                    >
                      Verification completed
                    </th>
                    {/* <th
                      style={{
                        color: '#3F4A50',
                        lineHeight: '30px',
                        borderTop: '3px solid #2680EB',
                      }}
                      className="Neutra-Demi font-size-15 text-center p-3"
                      colSpan="3"
                    >
                      Verification under process
                    </th> */}
                    <th></th>
                  </tr>
                  <tr>
                    <th className="Neutra-Demi font-size-16">Sl.No</th>
                    <th className="Neutra-Demi font-size-16 min-width-200 d-flex justify-content-between px-2">
                      <div>{`${year}`}</div>
                      <div>
                        <div
                          onClick={() => {
                            if (compareCheck) {
                              setOpenCompareModal(!openCompareModal);
                            }
                          }}
                          className={`compare-months Neutra-Demi${compareCheck ? ' compare-months-active' : ''
                            }`}
                        >
                          Compare
                        </div>
                        <div className="compare-months-sub-text">
                          Select the months to compare
                        </div>
                      </div>
                    </th>
                    {statusLabels.map((item, index) => {
                      return (
                        <th
                          key={index}
                          className="flex-column text-center min-width-160"
                        >
                          <div className="mb-2">
                            {item.color ? (
                              <span
                                className="d-inline-block circle-dot mr-1"
                                style={{ borderColor: item.color }}
                              />
                            ) : null}
                            <span
                              className="Neutra-Demi font-size-12"
                              style={{ color: '#3F4A50' }}
                            >
                              {item.label}
                            </span>
                          </div>
                          {item.showBtn ? (
                            <div
                              onClick={() => handleViewAll(item.slug)}
                              className="chasing-cell-applications-view-btn"
                            >
                              View All
                            </div>
                          ) : null}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                {tableData.length ? (
                  <tbody className="text-center">
                    {tableData.map((item, index) => {
                      return (
                        <TableData
                          key={index}
                          num={index}
                          type={type}
                          slug={item.label}
                          data={item.data}
                          selectMonth={selectMonth}
                          selectedMonthList={selectedMonthList}
                          openCompareModal={openCompareModal}
                          showFilterBar={showFilterBar}
                          officerDataULB={officerData?.me?.ulbName}
                          filterULBS={filterULBS}
                          year={year}
                          monthly={monthly}
                        />
                      );
                    })}
                  </tbody>
                ) : (
                    <tbody className="text-left">
                      <div className="p-3 pb-0">No Data Found</div>
                    </tbody>
                  )}
              </Table>
            </Card>
          </div>
        </Container>
      </div>
      <Modal
        centered
        className="compare-monthly-container"
        title="Compare the summary of selected months"
        visible={openCompareModal}
        footer={null}
        onCancel={(e) => {
          setOpenCompareModal(!openCompareModal);
        }}
        width={'90%'}
      >
        <Card
          className="col m-3 mt-4 px-5 pb-5 pt-0 custom-card"
          style={{
            borderRadius: 10,
            overflowX: 'scroll',
            overflowY: 'visible',
          }}
        >
          <Table borderless className="Neutra-Demi custom-table width-auto">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th
                  style={{
                    color: '#3F4A50',
                    lineHeight: '30px',
                    borderTop: '3px solid #3FA974',
                    borderRight: '1px solid #ddd',
                  }}
                  className="Neutra-Demi font-size-15 text-center p-3"
                  colSpan="3"
                >
                  Verification completed
                </th>
                <th
                  style={{
                    color: '#3F4A50',
                    lineHeight: '30px',
                    borderTop: '3px solid #2680EB',
                  }}
                  className="Neutra-Demi font-size-15 text-center p-3"
                  colSpan="3"
                >
                  Verification under process
                </th>
                <th></th>
              </tr>
              <tr>
                <th className="Neutra-Demi font-size-16">Sl.No</th>
                <th className="Neutra-Demi font-size-16 min-width-200 d-flex justify-content-between px-2">
                  <div>{`${year}`}</div>
                </th>
                {statusLabels.map((item, index) => {
                  return (
                    <th
                      key={index}
                      className="flex-column text-center min-width-160"
                    >
                      <div className="mb-2">
                        {item.color ? (
                          <span
                            className="d-inline-block circle-dot mr-1"
                            style={{ borderColor: item.color }}
                          />
                        ) : null}
                        <span
                          className="Neutra-Demi font-size-12"
                          style={{ color: '#3F4A50' }}
                        >
                          {item.label}
                        </span>
                      </div>
                      {item.showBtn ? (
                        <div
                          onClick={() => handleViewAll(item.slug)}
                          className="chasing-cell-applications-view-btn"
                        >
                          View All
                        </div>
                      ) : null}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {tableDataInCompare.length ? (
              <tbody className="text-center">
                {tableDataInCompare.map((item, index) => {
                  return (
                    <TableData
                      key={index}
                      num={index}
                      type={type}
                      slug={item.label}
                      data={item.data}
                      selectMonth={selectMonth}
                      selectedMonthList={selectedMonthList}
                      openCompareModal={openCompareModal}
                      showFilterBar={showFilterBar}
                      officerDataULB={officerData?.me?.ulbName}
                      filterULBS={filterULBS}
                      year={year}
                      monthly={monthly}
                    />
                  );
                })}
              </tbody>
            ) : (
                <tbody className="text-left">
                  <div className="p-3 pb-0">No Data Found</div>
                </tbody>
              )}
          </Table>
        </Card>
        <div className="d-flex justify-content-between align-items-center p-4">
          <div
            onClick={() => setOpenCompareModal(!openCompareModal)}
            className="compare-monthly-done-btn"
          >
            Done
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FinancialYear;
