/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  Form,
  FormControl,
  Accordion,
  Collapse,
  Card,
  Spinner,
  Carousel,
  Row,
  Col,
} from 'react-bootstrap';
import { AngleRight, AngleBottom, AngleTop, MapIcon, AngleLeft } from './icons';
import './review.scss';
import ApplicationDetails from './ApplicationDetails';
import PlotDetails from './PlotDetails';
import AnswerListComponent from '../AnswersListComponent';
import VicinityDetails from './VicinityDetails';
import ViewMap from './view-map';
import { toast } from 'react-toastify';
import axios from 'axios';
import environment from 'environment';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import CryptoJS from 'crypto-js';

import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PlotView from '../ApplicationDetailsModified/plot';
import BuildingView from '../ApplicationDetailsModified/building';
import FeeAdjustmentDetails from './FeeAdjustmentDetails';
import PropTypes from 'prop-types';
import {
  isShowMortgageSection,
  PERMISSIONS_LEVELS,
  getTranslatedText,
} from '../../util/helperFunctions';
import { AppContext } from '../../AppContext';
import FeeAdjustmentDetailsSW from './FeeAjustmentDetailsSW';
import Loader from '../LoadingScreen/Loader';

const ReviewSubmit = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [applicantOpen, setApplicantOpen] = useState(true);
  const [plotOpen, setPlotOpen] = useState(true);
  const [mediaOpen, setMediaOpen] = useState(true);
  const [vicinityOpen, setVicinityOpen] = useState(true);
  const [buildingOpen, setBuildingOpen] = useState(true);
  const [swOpen, setSWOpen] = useState(true);
  const [selfOpen, setSelfOpen] = useState(true);
  const [appData, setAppData] = useState(true);
  const [applicantData, setApplicantData] = useState();
  const [signedCopy, setSignedCopy] = useState([])
  const [plotData, setPlotData] = useState();
  const [buildingData, setBuildingData] = useState();
  const [ApprovalFor, setApprovalFor] = useState()

  const [vicinityData, setVicinityData] = useState(false);
  const [engineerData, setEngineerData] = useState(false);
  const [shortfallData, setShortfallData] = useState(false);
  const [mediaDocs, setMediaDocs] = useState(false);
  const [drawingMediaData, setDrawingMediaData] = useState([]);
  const [layoutDetails, setLayoutDetails] = useState(false)
  const [selfDeclaration, setSelfDeclaration] = useState();
  const [applicationType, setApplicationType] = useState()
  const [mediaData, setMediaData] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [geoCoordinates, setGeoCoordinates] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  const [showcauseRemark, setShowcauseRemak] = useState('')
  const [correctionAssignes, setCorrectionAssignes] = useState('')
  const [correctionDetails, setCorrectionDetails] = useState('')
  const { id } = useParams();
  const [loading, setLoading] = useState(false)
  const [mediaLoading, setMediaLoading] = useState(false)
  const history = useHistory();
  const { permissionLevel } = useContext(AppContext);

  const drawingDocsCategories = [
    'Proposed_Building_Plan(.PDF)_*',
    'Proposed_Building_Plan_(.dwg_drawing_to_be_in_Pre-DCR_format)_*',
    'Layout_Drawing_file_(.dwg_drawing_to_be_in_Pre-DCR_format)_*',
    'Layout_Drawing(PDF)_*',
    'scrutiny_pdf',
    'drawing_pdf',
    'updated_drawing_pdf',
    'updated_scrutiny_pdf',
  ]

  const decryptedText = (cipherText) => {
    const key = CryptoJS.enc.Base64.parse('6OZbK0FbuzUCG+XuXWuKTFSm3VIMKg0IWNnU/FKIraI=');
    const iv = CryptoJS.enc.Base64.parse('jKdOxAbYigXA1ls0dqqikQ==');
    const bytes = CryptoJS.AES.decrypt(cipherText, key, { iv: iv });
    const plainText = bytes.toString(CryptoJS.enc.Utf8);
    return plainText;
  }
  useEffect(() => {
    console.log(correctionDetails, "correctionDetails")

  }, [correctionDetails])
  const stringReplaceUnderscore = (string) => {
    const arr = string.split('_');

    var result = [];

    for (const word of arr) {
      result.push(word.charAt(0).toUpperCase() + word.slice(1));
    }

    result = result.join(' ');
    if (result.slice(-4) == "Area") {
      result = result.concat(' (Sqmts)');
    }
    return result;
  }

  const applicationDetails1 = () => {
    return (
      <>
        {history.location?.state?.from &&
          history.location?.state?.from === 'verification-pages' ? (
          <div
            className="d-flex"
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => history.goBack()}
          >
            <img src={require('../../assets/images/closeIcon.svg')} />
            <p style={{ fontSize: 12, color: '#9eabb5', paddingLeft: 10 }}>
              Minimize
            </p>
          </div>
        ) : null}

        <Accordion

          className="my-3 applicationDetails"
          style={{ border: '1px solid lightgray', borderRadius: '2px' }}
        >
          {applicantData && (
            <Card style={{ height: '100%' }}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                style={{ borderBottom: 'none', fontSize: '23px' }}
              >
                Application Details
                <img
                  style={{
                    transform: 'rotate(0deg)',
                  }}
                  src={require('../../assets/images/dd-arrow.svg')}
                  alt=""
                />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {applicantData.applicant_type &&
                    applicantData.applicant_name && (
                      <Row>
                        <Col className="detail-prop">Applicant Name</Col>
                        <Col className="detail-value">
                          {applicantData?.applicant_type}{' '}
                          {applicantData.applicant_name}
                        </Col>
                      </Row>
                    )}
                  {applicantData.relationship_type && (
                    <Row>
                      <Col className="detail-prop">
                        {applicantData.relationship_type}
                      </Col>
                      <Col className="detail-value">
                        {applicantData.relationship_name}
                      </Col>
                    </Row>
                  )}
                  {applicantData.aadhaar_number && (
                    <Row>
                      <Col className="detail-prop">Aadhaar Number</Col>
                      <Col className="detail-value">
                        {decryptedText(applicantData.aadhaar_number)}
                      </Col>
                    </Row>
                  )}
                  {[
                   PERMISSIONS_LEVELS.SITE_VERIFICATION,
                   PERMISSIONS_LEVELS.TPSO_OFFICER,
                   PERMISSIONS_LEVELS.ACP_OFFICER,
                   PERMISSIONS_LEVELS.SECTION_HEAD,
                   PERMISSIONS_LEVELS.SECTION_OFFICER,
                   PERMISSIONS_LEVELS.UDA_GP_SITE_OFFICER,
                   PERMISSIONS_LEVELS.CP_TECH_OFFICER,
                   PERMISSIONS_LEVELS.TECH_OFFICER
                  ].includes(permissionLevel) &&
                    applicantData.mobile && (
                      <Row>
                        <Col className="detail-prop">Mobile Number</Col>
                        <Col className="detail-value">
                          {decryptedText(applicantData.mobile)}
                        </Col>
                      </Row>
                    )}
                  {/* <Row>
                  <Col>E-Mail ID</Col>
                  <Col>{applicantData.email ? applicantData.email : '---'}</Col>
                </Row> */}
                  {applicantData.contact_address && (
                    <Row>
                      <Col className="detail-prop">Contact Address</Col>
                      <Col className="detail-value">
                        {applicantData.contact_address}
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )}
        </Accordion>
      </>
    );
  };

  const plotDetails1 = () => {
    let coordinates = [];
    if (plotData?.geo_coordinates) {
      let coordsArr = plotData.geo_coordinates.split(',');
      let lats = coordsArr.filter((e, i) => i % 2 === 0);
      let longs = coordsArr.filter((e, i) => i % 2 === 1);
      for (var i = 0; i < coordsArr.length / 2; i++) {
        coordinates.push({ lat: lats[i], lng: longs[i] });
      }
    }

    return (
      <Accordion

        className="my-3"
        style={{ border: '1px solid lightgray', borderRadius: '2px' }}
      >
        {plotData && (
          <Card style={{ height: '100%' }}>
            <Accordion.Toggle
              as={Card.Header}
              style={{ borderBottom: 'none', fontSize: '23px' }}
              eventKey="0"
            >
              Plot Details
              <img
                style={{
                  transform: 'rotate(0deg)',
                }}
                src={require('../../assets/images/dd-arrow.svg')}
                alt=""
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <PlotView
                appData={appData}
                plotData={plotData}
                geoCoordinates={geoCoordinates}
              />
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    );
  };

  const uploadedDocuments = (docs, type) => {

    return (
      <Accordion
        className="my-3"
        style={{ border: '1px solid lightgray', borderRadius: '2px' }}
        onClick={handleShowCitizenUploadedMedia}
      >

        <Card style={{ height: '100%' }}>
          <Accordion.Toggle
            as={Card.Header}
            style={{ borderBottom: 'none', fontSize: '23px' }}
            eventKey="0"
          >


            {!mediaLoading ? <>
              {type === 'shortfall' ?
                'Shortfall Documents'
                : type === 'drawing' ?
                  'Drawing Files'
                  :
                  'Uploaded Documents'}
              <img
                style={{
                  transform: 'rotate(0deg)',
                }}
                src={require('../../assets/images/dd-arrow.svg')}
                alt=""
              />
            </> : <Loader />}
          </Accordion.Toggle>
          {docs && (
            <Accordion.Collapse eventKey="0">
             {docs != undefined ?   <Card.Body>
                {docs.filter((item) => item.category !== "showcause_document").map((item, id) => {
                  return (
                    <Row key={id}>
                      <Col className="detail-prop">
                        {item.category && item.category.replace(/_/g, ' ')}{item.category == "shortfall_document" && <>{"-"} {id + 1} </>}
                      </Col>
                      <Col className="detail-value">
                        {item.category && (
                          <a
                            href={item.download_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              style={{ color: 'black' }}
                            />
                          </a>
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </Card.Body> : <Card.Body>
                No documents available for this application
              </Card.Body> }
            </Accordion.Collapse>)}
        </Card>

      </Accordion>
    );
  };

  const showcauseDocument = (docs, showcauseRemark, type) => {
    return (
      <Accordion
        className="my-3"
        style={{ border: '1px solid lightgray', borderRadius: '2px' }}
      >
        {docs && (
          <Card style={{ height: '100%' }}>
            <Accordion.Toggle
              as={Card.Header}
              style={{ borderBottom: 'none', fontSize: '23px' }}
              eventKey="0"
            >
              {/* {type === 'shortfall' ?
                'Shortfall Documents'
                : type === 'drawing' ?
                  'Drawing Files'
                  :
                  'Uploaded Documents'} */}
              Showcause Documents
              <img
                style={{
                  transform: 'rotate(0deg)',
                }}
                src={require('../../assets/images/dd-arrow.svg')}
                alt=""
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {docs.filter((item) => item.category === "showcause_document").map((item, id) => {
                  return (
                    <>
                      <Row key={id}>
                        <Col className="detail-prop">
                          {item.category && item.category.replace(/_/g, ' ')}
                        </Col>
                        <Col className="detail-value">
                          {item.category && (
                            <a
                              href={item.download_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon
                                icon={faDownload}
                                style={{ color: 'black' }}
                              />
                            </a>
                          )}
                        </Col>
                      </Row>

                    </>
                  );
                })}
                <br />

                <hr />
                <Row>
                  <Col className="detail-prop">
                    {" Showcause Reply :-"}
                    {/* {item.category && item.category.replace(/_/g, ' ')} */}
                  </Col>
                  <Col>
                    {showcauseRemark}
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    );
  }

  const signedCopyMediaFiles = (signedCopy) => {
    return (
      <Accordion
        className="my-3"
        style={{ border: '1px solid lightgray', borderRadius: '2px' }}
      >
        {signedCopy && signedCopy.length != 0 ? (
          <Card style={{ height: '100%' }}>
            <Accordion.Toggle
              as={Card.Header}
              style={{ borderBottom: 'none', fontSize: '23px' }}
              eventKey="0"
            >
              {'Signed-QR Documents'}
              <img
                style={{
                  transform: 'rotate(0deg)',
                }}
                src={require('../../assets/images/dd-arrow.svg')}
                alt=""
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {signedCopy.map((item, id) => {
                  return (
                    <Row key={id}>
                      <Col className="detail-prop">
                        {item.category && item.category.replace(/_/g, ' ')}
                      </Col>
                      <Col className="detail-value">
                        {item.download_url && (
                          <a
                            href={item.download_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              style={{ color: 'black' }}
                            />
                          </a>
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ) : ""}
      </Accordion>
    );
  }

  const Certificates = () => {
    return (
      <Accordion
        className="my-3"
        style={{ border: '1px solid lightgray', borderRadius: '2px' }}
        onClick={handleShowCertificates}
      >

        <Card style={{ height: '100%' }}>
          <Accordion.Toggle
            as={Card.Header}
            style={{ borderBottom: 'none', fontSize: '23px' }}
            eventKey="0"
          >
            {!loading ? <>     Certificates
              <img
                style={{
                  transform: 'rotate(0deg)',
                }}
                src={require('../../assets/images/dd-arrow.svg')}
                alt=""
              /> </> : <Loader />}
          </Accordion.Toggle>
          {certificates && (
            <Accordion.Collapse eventKey="0">
              {certificates.length != 0 ?
                <Card.Body>
                  {Object.keys(certificates)
                    .filter((key) => certificates[key])
                    .map((item, id) => {
                      //  console.log(certificates[item] ,'certificates[item] ')
                      return (
                        <>
                          {item != "signed_copy_medias" ?
                            <Row key={id}>
                              <Col className="detail-prop">
                                {certificates[item] !== null ? item : '---'}
                              </Col>

                              <Col className="detail-value">
                                <a
                                  href={certificates[item]}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                    style={{ color: 'black' }}
                                  />
                                </a>
                              </Col>
                            </Row>
                            :
                            <>

                              {/* <div style={{display:"flex", flexDirection:"column"}}> */}
                              <br />
                              {(certificates.hasOwnProperty('signed_copy_medias') && certificates[item].length > 0) ? <h5>Signed Copy Medias</h5> : " "}
                              <br />
                              {certificates[item] !== null ? certificates[item].map((items) => {
                                return <Row key={id}>

                                  <Col className="detail-prop">    {items.category.replaceAll('_', ' ')}  </Col>
                                  <Col className="detail-value">
                                    <a
                                      href={items.download_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FontAwesomeIcon
                                        icon={faDownload}
                                        style={{ color: 'black' }}
                                      />
                                    </a>
                                  </Col>

                                </Row>
                              })


                                : '---'}

                              {/* </div> */}

                            </>

                          }
                        </>
                      );
                    })}

                </Card.Body> :
                <Card.Body>
                   No Certificates Available for this Application
                </Card.Body>}
            </Accordion.Collapse>
          )}
        </Card>

      </Accordion>
    );
  };


  const vicinityDetails1 = () => {
    if (
      vicinityData &&
      vicinityData.vicinity_answers &&
      vicinityData.vicinity_answers.length
    ) {
      return (
        <Accordion
          className="my-3"
          style={{ border: '1px solid lightgray', borderRadius: '2px' }}
        >
          {vicinityData &&
            vicinityData.vicinity_answers &&
            vicinityData.vicinity_answers.length && (
              <Card style={{ height: '100%' }}>
                <Accordion.Toggle
                  as={Card.Header}
                  style={{ borderBottom: 'none', fontSize: '23px' }}
                  eventKey="0"
                >
                  Application Checklist
                  <img
                    style={{
                      transform: 'rotate(0deg)',
                    }}
                    src={require('../../assets/images/dd-arrow.svg')}
                    alt=""
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {vicinityData?.vicinity_answers.map((item, id) => {
                      return (
                        <Row key={id}>
                          <Col className="detail-prop">{item.question}</Col>
                          <Col className="detail-value">
                            {item.answer
                              ? item.answer === 'TRUE' || item.answer === 't'
                                ? 'Yes'
                                : item.answer === 'FALSE' || item.answer === 'f'
                                  ? 'No'
                                  : item.answer
                              : '---'}
                          </Col>
                        </Row>
                      );
                    })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
        </Accordion>
      );
    }
  };

  // const buildingDetails = () => {
  //   return (
  //     <Accordion
  //       defaultActiveKey="0"
  //       className="my-5"
  //       style={{ border: '1px solid lightgray', borderRadius: '2px' }}
  //     >
  //       {buildingData && (
  //         <Card style={{ height: '100%' }}>
  //           <Accordion.Toggle
  //             as={Card.Header}
  //             style={{ borderBottom: 'none', fontSize: '23px' }}
  //             eventKey="0"
  //           >
  //             Building Details
  //             <img
  //               style={{
  //                 transform: 'rotate(0deg)',
  //               }}
  //               src={require('../../assets/images/dd-arrow.svg')}
  //               alt=""
  //             />
  //           </Accordion.Toggle>
  //           <Accordion.Collapse eventKey="0">
  //             <Card.Body>
  //               {buildingData.building_info && (
  //                 <div>
  //                   <Row
  //                     className="text-muted"
  //                     style={{ margin: '30px 0px', fontWeight: 'bold' }}
  //                   >
  //                     Building Details
  //                   </Row>
  //                   {buildingData.building_info.is_building_new && (
  //                     <Row>
  //                       <Col className="detail-prop">Permission Type</Col>
  //                       <Col className="detail-value">
  //                         {buildingData.building_info.is_building_new
  //                           ? 'New'
  //                           : 'Addition/Alteration'}
  //                       </Col>
  //                     </Row>
  //                   )}
  //                   {buildingData.building_info.purpose_of_building && (
  //                     <Row>
  //                       <Col className="detail-prop">Usage of Building</Col>
  //                       <Col className="detail-value">
  //                         {buildingData.building_info.purpose_of_building}
  //                       </Col>
  //                     </Row>
  //                   )}
  //                   {buildingData.building_info.building_category && (
  //                     <Row>
  //                       <Col className="detail-prop">Building Type</Col>
  //                       <Col className="detail-value">
  //                         {buildingData.building_info.building_category}
  //                       </Col>
  //                     </Row>
  //                   )}
  //                   {buildingData.building_info.building_subcategory && (
  //                     <Row>
  //                       <Col className="detail-prop">Building Sub Type</Col>
  //                       <Col className="detail-value">
  //                         {buildingData.building_info.building_subcategory}
  //                       </Col>
  //                     </Row>
  //                   )}
  //                   {buildingData.building_info.proposed_no_of_floors_name && (
  //                     <Row>
  //                       <Col className="detail-prop">
  //                         Proposed Number of Floors
  //                       </Col>
  //                       <Col className="detail-value">
  //                         {
  //                           buildingData.building_info
  //                             .proposed_no_of_floors_name
  //                         }
  //                       </Col>
  //                     </Row>
  //                   )}
  //                   {/* {buildingData.building_info.roof_type && (
  //                     <Row>
  //                       <Col className="detail-prop">Type Of Roof</Col>
  //                       <Col className="detail-value">
  //                         {buildingData.building_info.roof_type}
  //                       </Col>
  //                     </Row>
  //                   )} */}
  //                 </div>
  //               )}

  //               {buildingData.floor_wise_built_up_area &&
  //                 plotData &&
  //                 plotData.application_type === 'SC' && (
  //                   <div>
  //                     <hr />
  //                     <Row
  //                       className="text-muted"
  //                       style={{ margin: '30px 0px', fontWeight: 'bold' }}
  //                     >
  //                       Floor wise built-up area
  //                     </Row>
  //                     {buildingData.floor_wise_built_up_area &&
  //                       buildingData.floor_wise_built_up_area.data.length > 0 ? (
  //                         <>
  //                           {buildingData.floor_wise_built_up_area.data.map(
  //                             (builtup, i) => (
  //                               <Row key={`builtup-area-${i}`}>
  //                                 <Col className="detail-prop">
  //                                   {builtup.floor_type}
  //                                 </Col>

  //                                 <Col className="detail-value">
  //                                   {builtup.area_display}
  //                                 </Col>
  //                               </Row>
  //                             )
  //                           )}
  //                           <Row>
  //                             <Col className="detail-prop">
  //                               Total Builtup area
  //                           </Col>
  //                             <Col className="detail-value">
  //                               {
  //                                 buildingData.floor_wise_built_up_area
  //                                   .total_built_up_area_display
  //                               }
  //                             </Col>
  //                           </Row>
  //                           {parseFloat(plotData.actual_plot_area) > 200 && (
  //                             <Row>
  //                               <Col className="detail-prop">No. Of Units</Col>
  //                               <Col className="detail-value">
  //                                 {buildingData.floor_wise_built_up_area
  //                                   .units_number
  //                                   ? buildingData.floor_wise_built_up_area
  //                                     .units_number
  //                                   : '0'}
  //                               </Col>
  //                             </Row>
  //                           )}
  //                         </>
  //                       ) : (
  //                         <h3>Builtup Area Not found</h3>
  //                       )}
  //                   </div>
  //                 )}

  //               {buildingData.abutting_road_front &&
  //                 plotData &&
  //                 plotData.application_type === 'SC' && (
  //                   <div>
  //                     <hr />
  //                     <Row
  //                       className="text-muted"
  //                       style={{ margin: '30px 0px', fontWeight: 'bold' }}
  //                     >
  //                       Abutting Road Details (front)
  //                     </Row>
  //                     {buildingData.abutting_road_front
  //                       .front_existing_road_width && (
  //                         <Row>
  //                           <Col className="detail-prop">Existing Road Width</Col>
  //                           <Col className="detail-value">
  //                             {
  //                               buildingData.abutting_road_front
  //                                 .front_existing_road_width
  //                             }{' '}
  //                           Meters
  //                         </Col>
  //                         </Row>
  //                       )}
  //                     {buildingData.abutting_road_front
  //                       .front_proposed_road_width && (
  //                         <Row>
  //                           <Col className="detail-prop">Proposed Road Width</Col>
  //                           <Col className="detail-value">
  //                             {
  //                               buildingData.abutting_road_front
  //                                 .front_proposed_road_width
  //                             }{' '}
  //                           Meters
  //                         </Col>
  //                         </Row>
  //                       )}
  //                     {buildingData.abutting_road_front
  //                       .front_depth_road_affected && (
  //                         <Row>
  //                           <Col className="detail-prop">
  //                             Depth Of Road Affected
  //                         </Col>
  //                           <Col className="detail-value">
  //                             {
  //                               buildingData.abutting_road_front
  //                                 .front_depth_road_affected
  //                             }{' '}
  //                           Meters
  //                         </Col>
  //                         </Row>
  //                       )}
  //                     {buildingData.abutting_road_front
  //                       .front_road_affected_area && (
  //                         <Row>
  //                           <Col className="detail-prop">Road Affected Area</Col>
  //                           <Col className="detail-value">
  //                             {
  //                               buildingData.abutting_road_front
  //                                 .front_road_affected_area
  //                             }{' '}
  //                           Sq. Meters
  //                         </Col>
  //                         </Row>
  //                       )}
  //                   </div>
  //                 )}

  //               {(buildingData.total_road_affected_area ||
  //                 buildingData.net_plot_area_display) &&
  //                 plotData &&
  //                 plotData.application_type === 'SC' && (
  //                   <div>
  //                     <hr />
  //                     <Row
  //                       className="text-muted"
  //                       style={{ margin: '30px 0px', fontWeight: 'bold' }}
  //                     >
  //                       Abutting Road Details (Total)
  //                     </Row>
  //                     {buildingData.total_road_affected_area !== 0 && (
  //                       <Row>
  //                         <Col className="detail-prop">Total Road Affected</Col>
  //                         <Col className="detail-value">
  //                           {buildingData.total_road_affected_area}
  //                         </Col>
  //                       </Row>
  //                     )}
  //                     {buildingData.net_plot_area_display && (
  //                       <Row>
  //                         <Col className="detail-prop">Net Plot Area</Col>
  //                         <Col className="detail-value">
  //                           {buildingData.net_plot_area_display}
  //                         </Col>
  //                       </Row>
  //                     )}
  //                   </div>
  //                 )}

  //               {buildingData.setback_details && (
  //                 <div>
  //                   <hr />
  //                   <Row
  //                     className="text-muted"
  //                     style={{ margin: '30px 0px', fontWeight: 'bold' }}
  //                   >
  //                     Setback Details
  //                   </Row>
  //                   {buildingData.setback_details.front_setback && (
  //                     <Row>
  //                       <Col className="detail-prop">
  //                         Setback Front As Per Table-111 G.O No 168
  //                       </Col>
  //                       <Col className="detail-value">
  //                         {buildingData.setback_details.front_setback} Meters
  //                       </Col>
  //                     </Row>
  //                   )}

  //                   {buildingData.setback_details.front_setback_manual && (
  //                     <Row>
  //                       <Col className="detail-prop">
  //                         Proposed Setback Front Manual
  //                       </Col>
  //                       <Col className="detail-value">
  //                         {buildingData.setback_details.front_setback_manual}{' '}
  //                         Meters
  //                       </Col>
  //                     </Row>
  //                   )}
  //                   {buildingData.setback_details.other_setback && (
  //                     <Row>
  //                       <Col className="detail-prop">
  //                         Setback on Rear and Other Sides As Per Table-111 G.O
  //                         No 168
  //                       </Col>
  //                       <Col className="detail-value">
  //                         {buildingData.setback_details.other_setback} Meters
  //                       </Col>
  //                     </Row>
  //                   )}
  //                   {buildingData.setback_details.rear_setback_manual && (
  //                     <Row>
  //                       <Col className="detail-prop">Proposed Setback Rear</Col>
  //                       <Col className="detail-value">
  //                         {buildingData.setback_details.rear_setback_manual}{' '}
  //                         Meters
  //                       </Col>
  //                     </Row>
  //                   )}
  //                   {buildingData.setback_details.side1_setback_manual && (
  //                     <Row>
  //                       <Col className="detail-prop">
  //                         Proposed Setbacks Side 1
  //                       </Col>
  //                       <Col className="detail-value">
  //                         {buildingData.setback_details.side1_setback_manual}{' '}
  //                         Meters
  //                       </Col>
  //                     </Row>
  //                   )}
  //                   {buildingData.setback_details.side2_setback_manual && (
  //                     <Row>
  //                       <Col className="detail-prop">
  //                         Proposed Setbacks Side 2
  //                       </Col>
  //                       <Col className="detail-value">
  //                         {buildingData.setback_details.side2_setback_manual}{' '}
  //                         Meters
  //                       </Col>
  //                     </Row>
  //                   )}
  //                 </div>
  //               )}

  //               {plotData &&
  //                 buildingData &&
  //                 buildingData.mortgage &&
  //                 isShowMortgageSection(props.applicationType, buildingData) &&
  //                 plotData.application_type === 'SC' && (
  //                   <div>
  //                     <hr />
  //                     <Row
  //                       className="text-muted"
  //                       style={{ margin: '30px 0px', fontWeight: 'bold' }}
  //                     >
  //                       Mortgage
  //                     </Row>
  //                     {buildingData.mortgage.mortgage_area && (
  //                       <Row>
  //                         <Col className="detail-prop">Mortgage Area</Col>
  //                         <Col className="detail-value">
  //                           {buildingData.mortgage.mortgage_area} Sq. Meters
  //                         </Col>
  //                       </Row>
  //                     )}
  //                     {buildingData.mortgage.mortgage_floor && (
  //                       <Row>
  //                         <Col className="detail-prop">Mortgage Floors</Col>
  //                         <Col className="detail-value">
  //                           {buildingData.mortgage.mortgage_floor}
  //                         </Col>
  //                       </Row>
  //                     )}
  //                     {buildingData.mortgage.mortgage_reg_no && (
  //                       <Row>
  //                         <Col className="detail-prop">
  //                           Mortgage Registration Number
  //                         </Col>
  //                         <Col className="detail-value">
  //                           {buildingData.mortgage.mortgage_reg_no}
  //                         </Col>
  //                       </Row>
  //                     )}
  //                     {buildingData.mortgage.mortgage_date && (
  //                       <Row>
  //                         <Col className="detail-prop">Mortgage Date</Col>
  //                         <Col className="detail-value">
  //                           {buildingData.mortgage.mortgage_date}
  //                         </Col>
  //                       </Row>
  //                     )}
  //                   </div>
  //                 )}
  //             </Card.Body>
  //           </Accordion.Collapse>
  //         </Card>
  //       )}
  //     </Accordion>
  //   );
  // };

  const buildingDetails = () => {
    return (
      <Accordion
        className="my-3"
        style={{ border: '1px solid lightgray', borderRadius: '2px' }}
      >
        {buildingData && (
          <Card style={{ height: '100%' }}>
            <Accordion.Toggle
              as={Card.Header}
              style={{ borderBottom: 'none', fontSize: '23px' }}
              eventKey="0"
            >
              Building Details
              <img
                style={{
                  transform: 'rotate(0deg)',
                }}
                src={require('../../assets/images/dd-arrow.svg')}
                alt=""
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <>
                <BuildingView
                  appData={appData}
                  buildingData={buildingData}
                  plotData={plotData}
                />
                {appData && appData.parent_application_details && (
                  <div className="plot-deatils-form">
                    <Table responsive className="review-table">
                      <tbody>
                        <tr>
                          <td>
                            Whether the construction commenced on-ground ?
                          </td>
                          <td>
                            {appData.additional_floors_commenced_on_ground
                              ? 'YES'
                              : 'NO'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Whether proposed additional floors construction
                            commenced on ground ?
                          </td>
                          <td>
                            {appData.whether_construction_commenced_on_ground
                              ? 'YES'
                              : 'NO'}
                          </td>
                        </tr>
                        {appData.constructed_built_up_area_sqmtrs && (
                          <tr>
                            <td>constructed built up area in sqmtrs</td>
                            <td>{appData.constructed_built_up_area_sqmtrs}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    );
  };

  const layoutSection = () => {
    return (
      <Accordion
        className="my-3"
        style={{ border: '1px solid lightgray', borderRadius: '2px' }}
      >
        <Card style={{ height: '100%' }}>
          <Accordion.Toggle
            as={Card.Header}
            style={{ borderBottom: 'none', fontSize: '23px' }}
            eventKey="0"
          >
            {ApprovalFor == 'FINAL_LAYOUT' ? "Approved Layout Detials" : "Layout Details"}
            <img
              style={{
                transform: 'rotate(0deg)',
              }}
              src={require('../../assets/images/dd-arrow.svg')}
              alt=""
            />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              <div className="plot-deatils-form">
                <Table responsive className="review-table">
                  <tbody>
                    {layoutDetails && Object.entries(layoutDetails).length > 0 &&
                      Object.entries(layoutDetails).map(([key, value], i) => (

                        <tr key={`plot-document-${i}`}>
                          <td>{stringReplaceUnderscore(key).split('Temp').join("")}</td>
                          <td>
                            {value || "N/A"}
                          </td>
                        </tr>
                      ))}

                    {plotData.is_plot_part_of && plotData.is_plot_part_of.length > 0 &&
                      plotData.is_plot_part_of.map((part, i) => (
                        <tr key={`plot-part-of-${i}`}>
                          <td>{part.question}</td>
                          <td>
                            {part.answer.length > 50 ? (
                              <a href={part.answer} target="_blank">
                                <ul>{part.answer.substr(0, 35)}...</ul>
                              </a>
                            ) : (
                              part.answer
                            )}
                          </td>
                        </tr>
                      ))}
                    {plotData.slum && (
                      <tr>
                        <td>
                          {getTranslatedText('label.slum_area')}
                        </td>
                        <td>{plotData.slum}</td>
                      </tr>
                    )}
                    {plotData.application_type !== "REG" &&
                      plotData.application_type !== "SW" && (
                        <>
                          {plotData.sro_location && (
                            <tr>
                              <td>{getTranslatedText('label.sro_location')}</td>
                              <td>{plotData.sro_location}</td>
                            </tr>
                          )}
                          {plotData.market_value && (
                            <tr>
                              <td>{getTranslatedText('label.market_value')}</td>
                              <td>{plotData.market_value}</td>
                            </tr>
                          )}
                        </>
                      )}
                  </tbody>
                </Table>
              </div>
            </>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  const engineerDetails = () => {
    return (
      <Accordion
        className="my-3"
        style={{ border: '1px solid lightgray', borderRadius: '2px' }}
      >
        {engineerData && (
          <Card style={{ height: '100%' }}>
            <Accordion.Toggle
              as={Card.Header}
              style={{ borderBottom: 'none', fontSize: '23px' }}
              eventKey="0"
            >
              Engineer Details
              <img
                style={{
                  transform: 'rotate(0deg)',
                }}
                src={require('../../assets/images/dd-arrow.svg')}
                alt=""
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="plot-deatils-form">
                  {engineerData ?
                    <Table responsive className="review-table">
                      {engineerData.map((eng, index) => (
                        eng.name &&
                        <>
                          <h5>
                            <b>
                              {eng.engineer_type
                                ? eng.engineer_type
                                : "N/A"}
                            </b>
                          </h5>
                          <tbody>
                            <tr>
                              <td>Name</td>
                              <td>{eng.name ? eng.name : "N/A"}</td>
                            </tr>
                            {eng.mobile &&
                              <tr>
                                <td>Mobile</td>
                                <td>{eng.mobile ? eng.mobile : "N/A"}</td>
                              </tr>}
                            {eng.license_number &&
                              <tr>
                                <td>Licence Number</td>
                                <td>
                                  {eng.license_number
                                    ? eng.license_number
                                    : "N/A"}
                                </td>
                              </tr>}
                            {eng.email &&
                              <tr>
                                <td>Email</td>
                                <td>{eng.email ? eng.email : "N/A"}</td>
                              </tr>}
                          </tbody>
                        </>
                      ))}
                    </Table>
                    : <h3>Single Window Not found</h3>}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    );
  }

  const selfDeclarationDetails = () => {
    return (
      <Accordion
        className="my-3"
        style={{ border: '1px solid lightgray', borderRadius: '2px' }}
      >
        {engineerData && (
          <Card style={{ height: '100%' }}>
            <Accordion.Toggle
              as={Card.Header}
              style={{ borderBottom: 'none', fontSize: '23px' }}
              eventKey="0"
            >
              Self Decleration
              <img
                style={{
                  transform: 'rotate(0deg)',
                }}
                src={require('../../assets/images/dd-arrow.svg')}
                alt=""
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="plot-deatils-form">
                  {selfDeclaration ? <ul>
                    {selfDeclaration.map((declaration, index) => (
                    <ul >
                      <li style={{width:"95%",display:"flex", gap:"20px", alignItems:"center" , fontSize:"14px"}} key={index}>
                        <input
                          type="checkbox"
                          // id={`declarationCheckbox-${index}`}
                          disabled
                          checked="true"
                          // style={{height:"20px" ,  width:"20px"}}
                        />
                        <label htmlFor={`declarationCheckbox-${index}`} dangerouslySetInnerHTML={{ __html: declaration }} />
                      </li>
                    </ul>  
                    ))}
                  </ul>
                    : <h3>Single Window Not found</h3>}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    );
  }

  const CorrectionDetails = () => {
    // console.log(correctionAssignes,"correctionAssignes")
    return (
      <Accordion
        className="my-3"
        style={{ border: '1px solid lightgray', borderRadius: '2px' }}
      >
        {correctionAssignes && (
          <Card style={{ height: '100%' }}>
            <Accordion.Toggle
              as={Card.Header}
              style={{ borderBottom: 'none', fontSize: '23px' }}
              eventKey="0"
            >
              Correction Status
              <img
                style={{
                  transform: 'rotate(0deg)',
                }}
                src={require('../../assets/images/dd-arrow.svg')}
                alt=""
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <>

                {correctionAssignes.map(item => {
                  console.log(item, "correctionAssignes")
                  return <div className="plot-deatils-form">
                    <Table responsive className="review-table">
                      <tbody>
                        <tr>
                          <td>
                            {item.officer.replace(/_/g, ' ')}
                          </td>
                          <td>
                            {item.is_approve == null ? "In Process" : item.is_approve == false ? "Rejected" : "Approved"}
                          </td>
                        </tr>


                      </tbody>
                    </Table>

                  </div>
                })}

                <Table responsive className="review-table">
                  <tbody>
                    <tr>
                      <td>
                        final remark
                      </td>
                      <td>
                        {correctionDetails.final_remark}
                      </td>
                    </tr>



                  </tbody>

                </Table>
                <Table responsive className="review-table">
                  <tbody>
                    <tr>
                      <td>
                        Final Status
                      </td>
                      <td>
                        {correctionDetails.is_approve === null ? "In Process" : correctionDetails.is_approve == false ? "Rejected" : "Approved"}
                      </td>
                    </tr>




                  </tbody>

                </Table>
              </>

            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    );
  };


  const parentBuildingDetails = () => {
    return (
      <Accordion
        className="my-3"
        style={{ border: '1px solid lightgray', borderRadius: '2px' }}
      >
        {appData && appData.parent_application_details && (
          <Card style={{ height: '100%' }}>
            <Accordion.Toggle
              as={Card.Header}
              style={{ borderBottom: 'none', fontSize: '23px' }}
              eventKey="0"
            >
              {ApprovalFor == 'FINAL_LAYOUT' ? "Approved Layout  Details" : " Approved Building Details"}
              <img
                style={{
                  transform: 'rotate(0deg)',
                }}
                src={require('../../assets/images/dd-arrow.svg')}
                alt=""
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <div className="review-submit-box applicant">
                {appData.parent_application_details && (
                  <div className="plot-deatils-form">
                    {/* <h5><b>{getTranslatedText('title.exiting')}</b></h5> */}
                    <Table responsive className="review-table">
                      <tbody>
                        {appData.parent_application_details
                          .existing_proposed_no_of_floors_name && (
                            <tr>
                              <td>
                                {getTranslatedText(
                                  'label.existing_numeric_floors'
                                )}
                              </td>
                              <td>
                                {
                                  appData.parent_application_details
                                    .existing_proposed_no_of_floors_name
                                }
                              </td>
                            </tr>
                          )}
                        {appData.parent_application_details.floor_wise_built_up_area.data.map(
                          (builtup, i) => (
                            <tr key={`builtup-area-${i}`}>
                              <td>{builtup.existing_floor_type}</td>
                              <td>{builtup.existing_area_display}</td>
                              {builtup.existing_units && (
                                <td>{builtup.existing_units} Units</td>
                              )}
                            </tr>
                          )
                        )}
                        <tr>
                          <td>{getTranslatedText('label.builtup_total')}</td>
                          <td>
                            {
                              appData.parent_application_details
                                .floor_wise_built_up_area
                                .existing_total_built_up_area_display
                            }
                          </td>
                        </tr>
                        {plotData &&
                          parseFloat(plotData.actual_plot_area) > 200 && (
                            <tr>
                              <td>{getTranslatedText('label.no_of_units')}</td>
                              <td>
                                {appData.parent_application_details
                                  .floor_wise_built_up_area
                                  .existing_units_number
                                  ? appData.parent_application_details
                                    .floor_wise_built_up_area
                                    .existing_units_number + ' Units'
                                  : '0'}
                              </td>
                            </tr>
                          )}
                        {appData.parent_application_details
                          .existing_sro_location && (
                            <tr>
                              <td>{getTranslatedText('label.sro_location')}</td>
                              <td>
                                {
                                  appData.parent_application_details
                                    .existing_sro_location
                                }
                              </td>
                            </tr>
                          )}
                        {appData.parent_application_details
                          .existing_mortgage_reg_no && (
                            <tr>
                              <td>{getTranslatedText('label.mortgage_area')}</td>
                              <td>
                                {
                                  appData.parent_application_details
                                    .existing_mortgage_area
                                }
                              </td>
                            </tr>
                          )}
                        {appData.parent_application_details
                          .existing_mortgage_floor && (
                            <tr>
                              <td>{getTranslatedText('label.mortgage_floor')}</td>
                              <td>
                                {
                                  appData.parent_application_details
                                    .existing_mortgage_floor
                                }
                              </td>
                            </tr>
                          )}
                        {appData.parent_application_details
                          .existing_mortgage_reg_no && (
                            <tr>
                              <td>
                                {getTranslatedText(
                                  'label.mortgage_document_number'
                                )}
                              </td>
                              <td>
                                {
                                  appData.parent_application_details
                                    .existing_mortgage_reg_no
                                }
                              </td>
                            </tr>
                          )}
                        {appData.parent_application_details
                          .existing_mortgage_date && (
                            <tr>
                              <td>{getTranslatedText('label.mortgage_date')}</td>
                              <td>
                                {
                                  appData.parent_application_details
                                    .existing_mortgage_date
                                }
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </div>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    );
  };




  const review = () => {
    return (
      <>
        <div className="app-details-container1">
          {applicationDetails1()}
          {plotDetails1()}
          {appData &&
            appData.parent_application_details &&
            ApprovalFor !== 'FINAL_LAYOUT' &&
            parentBuildingDetails()}
          {plotData?.approval_for === "BUILDING" && buildingDetails()}
          {(plotData?.approval_for === "LAYOUT") || (plotData?.approval_for == "FINAL_LAYOUT")
            && layoutSection()}
          {shortfallData && uploadedDocuments(shortfallData, 'shortfall')}
          {uploadedDocuments(mediaDocs, 'others')}
          {showcauseDocument(mediaDocs, showcauseRemark, 'others')}
          {signedCopyMediaFiles(signedCopy, 'QR')}
          {CorrectionDetails()}
          {/* {drawingMediaData && drawingMediaData.length > 0 && showcauseDocument(drawingMediaData, 'drawing')} */}

          {drawingMediaData && drawingMediaData.length > 0 && uploadedDocuments(drawingMediaData, 'drawing')}

          {/* {plotCheckList()} */}
          {vicinityDetails1()}
          {selfDeclaration && selfDeclarationDetails()}

          {applicationType == "SW" && engineerDetails()}
          {Certificates()}
          {applicationType !== "SW" ? <FeeAdjustmentDetails id={id} setApprovalFor={setApprovalFor} /> :
            <FeeAdjustmentDetailsSW id={id} applicationType setApprovalFor={setApprovalFor} />
          }
        </div>
      </>
    );
  };
  const handleShowCertificates = () => {
    // debugger
    const newColapse = document.getElementsByClassName('show')
    const authToken = sessionStorage.getItem('accessToken');
    console.log(newColapse, 'newColapse');
    if (newColapse.length == 1 && certificates.length === 0) {

      setLoading(true)
      axios.get(`${environment.baseApi}/applications/${id}/certificates`, {
        headers: {
          Authorization: 'Bearer ' + authToken,
          Accept: '*/*',
        },
      }).then((response) => {

        setCertificates(response.data.data.certificates);
        setLoading(false)
        console.log(certificates, 'ErrorScreen')

      }).catch((ErrorScreen) => {
        console.log(ErrorScreen, 'ErrorScreen')
        setLoading(false)
      })
    }
  }

  const handleShowCitizenUploadedMedia = () => {


    const newColapse = document.getElementsByClassName('show')

    const authToken = sessionStorage.getItem('accessToken');
    console.log(newColapse, 'newColapse');
    if (newColapse.length == 1 && mediaData.length == 0) {

      setMediaLoading(true)
      axios.get(`${environment.baseApi}/applications/${id}/citizen_uploaded_documents`, {
        headers: {
          Authorization: 'Bearer ' + authToken,
          Accept: '*/*',
        },
      }).then((response) => {

        const docs = response.data.data.media.data.filter(item => !drawingDocsCategories.includes(item.category))
        setMediaData(docs)
        // const drawingDocs = response.data.data.media.data.filter(item => drawingDocsCategories.includes(item.category))
        // setDrawingMediaData(drawingDocs)
        setMediaLoading(false)
        // console.log(drawingDocs, 'drawingDocs');
      }).catch((ErrorScreen) => {
        console.log(ErrorScreen, 'ErrorScreen')
        setMediaLoading(false)
      })
    }
  }


  useEffect(() => {
    // eslint-disable-next-line no-undef
    const authToken = sessionStorage.getItem('accessToken');

    if (authToken && id) {
      setIsLoading(true);
      axios
        .get(`${environment.baseApi}/applications/${id}/view`, {
          headers: {
            Authorization: 'Bearer ' + authToken,
            Accept: '*/*',
          },
        })
        .then(function (response) {
          console.log('response', response.data.data);
          if (response.data.success) {
            setAppData(response.data.data.app_data);
            setApplicantData(response.data.data.applicant);
            setPlotData(response.data.data.plot);
            setBuildingData(response.data.data.building);

            setLayoutDetails(response.data.data.layout_details);
            setVicinityData(response.data.data.vicinity);
            setApplicationType(response.data.data.application_type)
            setShowcauseRemak(response?.data?.data?.show_cause_replied)
            // setMediaData(response.data.data.media.data);
            // setCertificates(response.data.data.certificates);
            setEngineerData(response.data.data.engineers.data);
            setSelfDeclaration(response.data.data.self_declaration);
            stringToGeoCoords(response.data.data.plot.geo_coordinates);
            setSignedCopy(response.data.data.signed_copy_medias)

            const docs = response.data.data.media.data.filter(item => !drawingDocsCategories.includes(item.category))
            setMediaData(docs)
             const singedCopyMedias= response.data.data.signed_copy_medias
            const drawingDocs = response.data.data.media.data.filter(item => drawingDocsCategories.includes(item.category))
            setDrawingMediaData(drawingDocs)
            setCorrectionAssignes(response?.data?.data?.correction_assignees)
            setCorrectionDetails(response?.data?.data?.correction_details[0])
            setIsLoading(false);
          } else {
            toast.error(response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
          }
        })
        .catch(function (error) {
          console.log('erroreee', error);

          if (error.response) {
            toast.error(error.response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
            });
          }
        });
    }
  }, []);


  useEffect(() => {
    async function fetchData() {
      if (mediaData) {
        const shortFall = []
        const others = []
        await mediaData.map((media) => {
          if (media.category === 'shortfall_document') {
            shortFall.push(media)
          } else {
            others.push(media)
          }
        })
        setShortfallData(shortFall.length > 0 ? shortFall : false)
        setMediaDocs(others)
      }
    }
    fetchData();
  }, [mediaData])

  const stringToGeoCoords = (geoCoords) => {
    var geoCoordValues = geoCoords.split(',');
    const rows = [...Array(Math.ceil(geoCoordValues.length / 2))];
    const devidedRows = rows.map((row, idx) =>
      geoCoordValues.slice(idx * 2, idx * 2 + 2)
    );

    const geoValues = [];
    devidedRows.map((row, i) => {
      geoValues.push({
        lat: row[0],
        lng: row[1],
      });
    });
    setGeoCoordinates(geoValues);
  };

  const handleCheck = (index) => {
    if (checkedValues.includes(index)) {
      const checked = checkedValues.filter((item) => item !== index);
      setCheckedValues(checked);
    } else {
      setCheckedValues((prevState) => [...prevState, index]);
    }
  };

  const getNorthValue = () => {
    if (plotData.north_open_land === true) {
      return 'Open Land';
    } else if (parseInt(plotData.north_plot_width) !== 0) {
      return plotData.north_plot_width;
    } else if (parseInt(plotData.north_road_width) !== 0) {
      return plotData.north_road_width;
    }
  };

  const getEastValue = () => {
    if (plotData.east_open_land === true) {
      return 'Open Land';
    } else if (parseInt(plotData.east_plot_width) !== 0) {
      return plotData.east_plot_width;
    } else if (parseInt(plotData.east_road_width) !== 0) {
      return plotData.east_road_width;
    }
  };

  const getSouthValue = () => {
    if (plotData.south_open_land === true) {
      return 'Open Land';
    } else if (parseInt(plotData.south_plot_width) !== 0) {
      return plotData.south_plot_width;
    } else if (parseInt(plotData.south_road_width) !== 0) {
      return plotData.south_road_width;
    }
  };

  const getWestValue = () => {
    if (plotData.west_open_land === true) {
      return 'Open Land';
    } else if (parseInt(plotData.west_plot_width) !== 0) {
      return plotData.west_plot_width;
    } else if (parseInt(plotData.west_road_width) !== 0) {
      return plotData.west_road_width;
    }
  };

  return <div className="appreview-carousel-container">{review()}</div>;
};

// ReviewSubmit.defaultProps = {
//   applicationType: 'SELF CERTIFICATION',
// };

// ReviewSubmit.propTypes = {
//   applicationType: PropTypes.string,
// };

export default ReviewSubmit;
