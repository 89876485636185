import React, { useContext, useState, useEffect } from 'react';
import { Dropdown, Tabs, Tab, Table, InputGroup, FormControl, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './ChasingCellAppTable.scss';

export default function ChasingCellAppTableContent() {

    return (

                    <div className="instant-table">
                        <Table hover >
                            <thead>
                                <tr>
                                    <th>Application Id</th>
                                    <th>ULB</th>
                                    <th>District</th>
                                    <th>Authority</th>
                                    <th>Application Date</th>
                                    <th>Approval Date</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>

                                </tr>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>

                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>0576/GHMC/01835/2020</td>
                                    <td>Ankapur</td>
                                    <td>Adilabad</td>
                                    <td>Muncipal</td>
                                    <td>5/08/2020</td>
                                    <td className="approval-date">5/08/2020</td>
                                    <td className="view-btn-td">
                                        <Button className="view-btn">View</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

              
    )

};
