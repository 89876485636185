import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom';
import { CUSTOM_CONDTIONS } from '../../graphql/mutatution/feeConditions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function CustomCondtions() {
  const [value, setValue] = useState([null])
  const [CustomeConditions,{caled,errer,loading} ] = useMutation(CUSTOM_CONDTIONS);
  const {  id } = useParams();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleAddTextBoxes = () => {
    const newTextBox = [...value, []]
    setValue(newTextBox)
  }
  const handleChange = (val, i) => {
   // setNewData(val.target.value)
    const InputData = [...value]
    InputData[i] = val.target.value
    setValue(InputData)
  }

  const handleDeleteTextBoxes = (i) => {
    const deletbox = [...value]
    deletbox.splice(i, 1)
    setValue(deletbox)
  }


 const handleSubmitCustom = () => {
  CustomeConditions({
    variables:{
      typeOf:"proceeding",
      newCondition:value,
      applicationId:id
    }
  }).then(()=>{
    if(!caled,!errer) {
      setShow(true);
    }
  }).catch((err)=>{
    console.log(err)
  })
 }

 function Example() {
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
const handleConforming = () => {
  window.location.reload()
}
  return (
    <>


      <Modal show={show} onHide={handleClose}   centered >
        <Modal.Header>
        
        </Modal.Header>
        <Modal.Body>Your Conditions are Addded Sucessfully !</Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleConforming}>
            ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


  return (
    <>
    {Example()}
    <div style={{ padding: "30px 0px 0px 50px" }} >
      {value.map((item, i) => {
        return <div style={{ display: 'flex', gap: "20px" }}>
          <div>
            <textarea placeholder='Add your Condtions here' value={item} maxLength ='500' style={{ minWidth: "500px", minHeight: "100px" }} onChange={e => handleChange(e, i)} ></textarea>
          </div>
          {i > 0 ? <button className='buttonStyle' onClick={() => handleDeleteTextBoxes(i)}> Remove  </button> : ""}
        </div>

      })}
      <button className='Add-Button' onClick={handleAddTextBoxes}>
        {"Add Textfeild"} &nbsp;
          <img
        src={require('../../assets/images/arrow.svg')}
        alt="next"
      />
      </button>
      {!loading ?    <button onClick={handleSubmitCustom} className='Add-Button'  >{"Submit"} &nbsp;
        <img
          src={require('../../assets/images/arrow.svg')}
          alt="next"
        />
      </button>

    :  <Button variant="secondary">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </Button>}
    </div>
    </>
  )
}

export default CustomCondtions