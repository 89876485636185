import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import ErrorScreen from '../components/ErrorScreen';
import LoadingScreen from '../components/LoadingScreen';
import QuestionCards from '../components/QuestionCards/sw-card';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import RightSideBar from '../components/RightSideBar';
import PropTypes from 'prop-types';
import { GET_OFFICERS_APPLICATION } from '../graphql/query/officersApplication';
import { GET_APPLICATION_QUESTIONS } from '../graphql/query/applicationQuestions';
import history from '../services/history';
import { AppContext } from '../AppContext';
import IAIRDisplayCards from '../components/IAIRQuestions/IAIRDisplayCards';
import { useParams } from 'react-router-dom';
import RightSideBarOCView from '../components/RightSideBar/oc-view';
import CWRightSideBar from '../components/RightSideBar/CWRightSideBar';

export default function IaIrVerification(props) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [status, setStuaus] = useState('')
  const showSidebarHandler = (value) => {
    setShowSidebar(value);
  };
  const { id } = useParams();
  const { permissionLevel } = useContext(AppContext);
  // console.log(props,"props.match.params.id")
  const {
    loading: officersLoading,
    error: officersError,
    data: officersData,
    refetch: officersRefetch,
  } = useQuery(GET_APPLICATION_QUESTIONS, {
    variables: { id: props.match.params.id },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  // console.log(officersDocumentsDataSorted,'officersDocumentsDataSorted')
  function isFormSubmitted() {
    let isAnswer = officersData?.applicationForms?.some(e => e?.answer !== null)

    if (isAnswer) {
      return officersData?.applicationForms?.map(e => e.answer)?.some(e => e?.action !== 'view')
    } else {
      return true;
    }
  }






  const sortCompare = (a, b) => {
    if (!b.order || parseInt(a.order, 10) < parseInt(b.order, 10)) {
      return -1;
    }
    if (!a.order || parseInt(a.order, 10) > parseInt(b.order, 10)) {
      return 1;
    }
    return 0;
  };

  const QueryOfficersForm = () => {
    if (officersLoading) return <LoadingScreen />;
    if (!(officersData && officersData.applicationForms))
      return <LoadingScreen />;
    if (officersError) {
      // loading data from json for development
      return <ErrorScreen message={officersError} retry={officersRefetch} />;
    }

    let titleRelatedForm = officersData.applicationForms.find((form) =>
      form.title
        .toLowerCase()
      // .includes(permissionLevel === 'SITE_VERIFICATION' ? 'site' : 'tech')
    );
    // let titleRelatedForm = officersData.applicationForms;
    let FormInstanceId = officersData.applicationForms.filter((form) =>
      form.title
        .toLowerCase()
      // .includes(permissionLevel === 'SITE_VERIFICATION' ? 'site' : 'tech')
    ).map((item) => item.id)
    const allStageStatus = officersData.applicationForms.map(
      (obj) => obj.stageState
    );
    if (!titleRelatedForm) {
      return (
        <ErrorScreen
          message={{ message: 'You only have view access for this application or File didnt reach you yet.' }}
          retry={officersRefetch}
        />
      );
    }
    var officersFormData = [...titleRelatedForm.fields];
    var officersFormDataSorted = officersFormData.sort(sortCompare);
    if (props.match.params.type === 'occ') {
      officersFormDataSorted.push({
        children: [],
        dbField: 'deviation_table',
        defaultValue: 'true',
        fieldType: 'TABLE',
        id: '0000',
        options: [],
        order: 'L1',
        title: 'Deviation Table',
      });
    }

    var officersDocumentsDataSorted = officersFormData
      .filter((item) => item.category === 'shortfall')
      .sort(sortCompare);
    var officersFormDataGrouped = [];
    const size = 5;
    while (officersFormDataSorted.length > 0) {
      officersFormDataGrouped.push(officersFormDataSorted.splice(0, size));
    }


    // console.log(applicationData,'applicationData')

    return (
      <div>
        <IAIRDisplayCards
          officersFormDataGrouped={officersData.applicationForms}
          officersDocumentsDataSorted={officersDocumentsDataSorted}
          formId={titleRelatedForm.id}
          recomID={FormInstanceId}
          verificationType={'Technical Verification'}
          applicationIdentifier={
            applicationData?.officersApplication.applicationIdentifier
          }
          isChecklistFilled={!!titleRelatedForm.isChecklistFilled}
          isFeesCompleted={!!titleRelatedForm.isFeesCompleted}
          isVerification={"true"}
          showSidebarHandler={showSidebarHandler}
          showSidebar={showSidebar}
          stageState={allStageStatus}
          formData={titleRelatedForm}
          status={status}
          isFormSubmitOrNot={isFormSubmitted}
        />
      </div>
    );
  };

  const {
    loading: applicationLoading,
    error: applicationError,
    data: applicationData,
    refetch: applicationRefetch,
  } = useQuery(GET_OFFICERS_APPLICATION, {
    fetchPolicy: 'cache-first',
    variables: { id: props.match.params.id },
    notifyOnNetworkStatusChange: true,
  });

  // eslint-disable-next-line no-unused-vars
  const QueryApplicationForm = () => {
    if (applicationLoading)
      return (
        <div className="ad-container">
          <LoadingScreen />
        </div>
      );
    if (applicationError)
      return (
        <div className="ad-container">
          <ErrorScreen message={applicationError} retry={applicationRefetch} />
        </div>
      );
    return <RightSideBar />;
  };
  function checkUserLogin() {
    const loginTOken = sessionStorage.getItem('accessToken')
    if (loginTOken != null || loginTOken != "" || loginTOken != undefined) {
      return true
    } else {
      return false
    }
  }
  return (
    <>
      <Header />
      <Sidebar />
      {/* <IdleTimer/>  */}
      {QueryOfficersForm()}
      {/* <div className="main-content">{QueryApplicationForm()}</div> */}
      <div className="main-content">
        {props.match.params.type === 'occ' ? (
          <RightSideBarOCView
            showSidebar={showSidebar}
            showSidebarHandler={showSidebarHandler}
            applicationType={history?.location?.state?.applicationType}
            ApprovalFor={applicationData?.officersApplication?.approvalFor}
            id = {props.match.params.id}
          />
        )

          : applicationData?.officersApplication?.approvalFor === 'COMPOUNDWALL' ? (
            <CWRightSideBar
              showSidebar={showSidebar}
              showSidebarHandler={showSidebarHandler}
              applicationType={history?.location?.state?.applicationType}
              ApprovalFor={applicationData?.officersApplication?.approvalFor}
              id = {props.match.params.id}
            />
          ) :( <RightSideBar
            showSidebar={showSidebar}
            showSidebarHandler={showSidebarHandler}
            applicationType={history?.location?.state?.applicationType}
            ApprovalFor={applicationData?.officersApplication?.approvalFor}
            id = {props.match.params.id}
          />)}
      </div>
    </>
  );
}

IaIrVerification.propTypes = {
  match: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.shape({
      applicationType: PropTypes.string,
    }),
  }),
};
IaIrVerification.defaultProps = {
  location: {
    state: {
      applicationType: 'SELF CERTIFICATION',
    },
  },
};
