import React, { useContext, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './index.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import history from '../../services/history';
import { useMutation, useQuery } from '@apollo/client';
import { GET_OFFICER_PROFILE } from '../../graphql/query/officerProfile';
import { removeLoginState } from '../../graphql/cache';
import { AppContext } from './../../AppContext';
import PropTypes from 'prop-types';
import { PERMISSIONS_LEVELS } from '../../util/helperFunctions';
import { LOGOUT_OFFICER } from '../../graphql/mutatution/logout';
import { ErrorMessage } from 'formik';
const Header = ({ showMenu, menuOnclick}) => {
  const {
    permissionLevel,
    setPermissionLevel,
    setOfficerType,
    setCurrentFlowGlobally,
    setErrorScreen,
    logoutLoading,
    setLogoutLoading,
  } = useContext(AppContext);

 
  const [signout, { loadinng, eror }] = useMutation(LOGOUT_OFFICER)

  // const [Loading, setLoading] = useState(false)

  const { data, client, loading, error } = useQuery(GET_OFFICER_PROFILE, { fetchPolicy: 'network-only' });

  const isUDAorNot = (data) => {
    const authorities = ['NIUDA', 'NUDA', 'MUDA', 'KUDA', 'SUDA', 'STUDA', 'VTADA', 'SIUDA', 'YTDA'];
    if (authorities.includes(data.me.authority)) {
      return true
    } else {
      return false
    }

  }

  const loadUserInfo = () => {
    if (loading || logoutLoading) return <a>Loading...</a>;
    if (error) {
      console.log(error);
      return '';
    }
    if (data?.me?.role?.name === 'Technical Scrutiny Officer') {
      setPermissionLevel(PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION);
    } else if (data?.me?.role?.name === 'Municipal Commissioner') {
      setPermissionLevel(PERMISSIONS_LEVELS.COMMISSIONER);
    } else if (data?.me?.role?.name === 'Zonal Commissioner') {
      setPermissionLevel(PERMISSIONS_LEVELS.ZONAL_COMMISSIONER);
    } else if (data?.me?.role?.name === 'DEPUTY COMMISSIONER') {
      setPermissionLevel(PERMISSIONS_LEVELS.DEPUTY_COMMISSIONER);
    } else if (data?.me?.role?.name === 'Title Inspector') {
      setPermissionLevel(PERMISSIONS_LEVELS.TITLE_VERIFICATION);
    } else if (data?.me?.role?.name === 'Site Inspector') {
      setPermissionLevel(PERMISSIONS_LEVELS.SITE_VERIFICATION);
    } else if (data?.me?.role?.name === 'ACP OFFICER') {
      setPermissionLevel(PERMISSIONS_LEVELS.ACP_OFFICER);
    } else if (data?.me?.role?.name === 'CP OFFICER') {
      setPermissionLevel(PERMISSIONS_LEVELS.CP_OFFICER);
    } else if (data?.me?.role?.name === 'TPSO OFFICER') {
      setPermissionLevel(PERMISSIONS_LEVELS.TPSO_OFFICER);
    } else if (data?.me?.role?.name === 'CIO-Chasing Cell') {
      setPermissionLevel(PERMISSIONS_LEVELS.CIO_CHASING_CELL);
    } else if (data?.me?.role?.name === 'DISTRICT COLLECTOR') {
      setPermissionLevel(PERMISSIONS_LEVELS.DISTRICT_COLLECTOR);
    } else if (data?.me?.role?.name === 'ADDITIONAL COLLECTOR') {
      setPermissionLevel(PERMISSIONS_LEVELS.ADDITIONAL_COLLECTOR);
    } else if (data?.me?.role?.name === 'Irrigation Officer') {
      setPermissionLevel(PERMISSIONS_LEVELS.IRRIGATION_OFFICER);
    } else if (data?.me?.role?.name === 'FIRE_DEPARTMENT') {
      setPermissionLevel(PERMISSIONS_LEVELS.FIRE_DEPARTMENT);
    } else if (data?.me?.role?.name === 'POLICE_DEPARTMENT') {
      setPermissionLevel(PERMISSIONS_LEVELS.POLICE_DEPARTMENT);
    } else if (data?.me?.role?.name === 'REVENUE_DEPARTMENT') {
      setPermissionLevel(PERMISSIONS_LEVELS.REVENUE_DEPARTMENT);
    } else if (data?.me?.role?.name === 'IRRIGATION_DEPARTMENT') {
      setPermissionLevel(PERMISSIONS_LEVELS.IRRIGATION_DEPARTMENT);
    } else if (data?.me?.role?.name === 'RAILWAY_DEPARTMENT') {
      setPermissionLevel(PERMISSIONS_LEVELS.RAILWAY_DEPARTMENT);
    } else if (data?.me?.role?.name === 'ELECTRICITY_DEPARTMENT') {
      setPermissionLevel(PERMISSIONS_LEVELS.ELECTRICITY_DEPARTMENT);
    } else if (data?.me?.role?.name === 'AIRPORT_DEPARTMENT') {
      setPermissionLevel(PERMISSIONS_LEVELS.AIRPORT_DEPARTMENT);
    } else if (data?.me?.role?.name === 'OIL_AND_GAS_DEPARTMENT') {
      setPermissionLevel(PERMISSIONS_LEVELS.OIL_AND_GAS_DEPARTMENT);
    } else if (data?.me?.role?.name === 'NMA_DEPARTMENT') {
      setPermissionLevel(PERMISSIONS_LEVELS.NMA_DEPARTMENT);
    } else if (data?.me?.role?.name === 'ENDOWMENT_DEPARTMENT') {
      setPermissionLevel(PERMISSIONS_LEVELS.ENDOWMENT_DEPARTMENT);
    } else if (data?.me?.role?.name === 'FOREST_DEPARTMENT') {
      setPermissionLevel(PERMISSIONS_LEVELS.FOREST_DEPARTMENT);
    } else if (data?.me?.role?.name === 'SEIAA_DEPARTMENT') {
      setPermissionLevel(PERMISSIONS_LEVELS.SEIAA_DEPARTMENT);
    } else if (data?.me?.role?.name === 'R_AND_B_DEPARTMENTr') {
      setPermissionLevel(PERMISSIONS_LEVELS.R_AND_B_DEPARTMENT);
    } else if (data?.me?.role?.name === 'DTCP DIRECTOR') {
      setPermissionLevel(PERMISSIONS_LEVELS.DTCP_DIRECTOR);
    } else if (data?.me?.role?.name === 'SECTION OFFICER' && isUDAorNot(data) == true) {
      setPermissionLevel(PERMISSIONS_LEVELS.TECHNICAL_VERIFICATION);
    } else {
      setPermissionLevel(data?.me?.role?.name);
    }
    if (data?.me.ulbName === 'GHMC' || data?.me.ulbName === 'HDMA') {

      setOfficerType(data?.me.ulbName);
    } else {
      setOfficerType('DTCP');
    }
    return (
      <div className="user-details">
        <p >{data?.Officer?.position}</p>
        <p > Role:&nbsp;  {permissionLevel?.replace(/_/g, ' ')?.toLowerCase()}</p>

        <br />
        <p> {data?.me?.ulbName}</p>
        <p style={{ fontWeight: "bold", fontSize: "16px" }}>{data?.me?.firstName + ' ' + data?.me?.lastName} &nbsp;
          <img width="40px" src={require("../../assets/images/icons_56px/shutdown.png").default} /></p>
      </div>
    );
  };


  const logoutUser = () => {
    setLogoutLoading(true)
    signout({
      variables: {
        signout: ""
      }
    }).then(() => {
      setLogoutLoading(false)
      setPermissionLevel(PERMISSIONS_LEVELS.TPSO_OFFICER);
      removeLoginState(client);
      setCurrentFlowGlobally('post-verification');
      window.sessionStorage.clear()
      history.push("/login")
    })
      .catch((e) => {
        setLogoutLoading(false)
        setErrorScreen(e)
        console.log(e);
      });


  };

  const notChasing = !history.location.pathname.includes('chasing');

  return (
    <>

      {eror && <ErrorMessage />}
      <Navbar
        fixed="top"
        style={{
          backgroundColor: notChasing ? '#1c9a5b' : '#f2f3f5',
          padding: '0.5rem 5.5rem 0.5rem 3.5rem',
        }}
      >
        <div
          style={{

            height: '40px',
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            alignItems: "center",

          }}
        >
          <div
            style={{ marginRight: "60%" }}
            className=" d-flex align-items-center">
            {notChasing && (
              <Navbar.Brand
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setCurrentFlowGlobally('post-verification');
                  history.push('/');
                }}
              >
                <div className=" d-flex align-items-center">
                  <div>
                    <img
                      src={require('../../assets/images/logo.png').default}
                      width="46"
                      alt="logo here"
                    />
                  </div>
                  <h3 style={{ textTransform: 'none', marginTop: "3%", color: "White" }}>TG-bPASS</h3>
                </div>
              </Navbar.Brand>
            )}
            {showMenu ? (
              <img
                onClick={menuOnclick}
                className="ml-5"
                style={{ cursor: 'pointer' }}
                src={require('../../assets/images/dashboard-new/menu.svg')}
              />
            ) : null}
          </div>
          <Nav>
            {/* <Nav.Link>
            <img
              src={require('../../assets/images/bell-icon.svg')}
              alt="bellIcon"
            />{' '}
            <span className="badge badge-warning" />
          </Nav.Link> */}
            <Dropdown>
              <Dropdown.Toggle>
                <div className="p-2 d-flex align-items-center"
                // style={{marginRight:"5%"}}
                >
                  {/* <img
                  src={require('../../assets/images/user.png')}
                  className="rounded-circle"
                  alt="user"
                /> */}

                  {loadUserInfo()}
                  {/* <img
                  src={require('../../assets/images/dd-arrow.svg')}
                  className="arrow-icon mt-2 ml-2"
                  alt="Arrow"
                /> */}
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu  >
                {/* <Dropdown.Itestm href="#/action-1">View Profile</Dropdown.Item>
              <Dropdown.Divider /> */}
                {/* Change Password dropdown item is commented for now */}
                {/* <Dropdown.Item
                onClick={() => {
                  history.push('/reset-password');
                }}
              >
                <img
                  src={require('../../assets/images/change-password.svg')}
                  alt="change-password"
                />
                Change Password
              </Dropdown.Item>
              <Dropdown.Divider /> */}
                <Dropdown.Item
                  style={{ color: "black" }}
                  onClick={() => {
                    logoutUser();
                  }}
                >
                  <img
                    src={require('../../assets/images/logout.svg')}
                    alt="logout"
                  />
                  Logout
                </Dropdown.Item>
                {/* adding temporary permission level change */}
                {/* <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => {
                  setPermissionLevel('technical-verification');
                  history.push('/post-verification');
                }}
              >
                Techincal Verification Officer
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => {
                  setPermissionLevel('tpso-officer');
                  history.push('/post-verification');
                }}
              >
                TPSO officer
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => {
                  setPermissionLevel('acp-officer');
                  history.push('/post-verification');
                }}
              >
                ACP officer
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => {
                  setPermissionLevel('chasing-cell');
                  history.push('/chasing-action-panel');
                }}
              >
                Chasing Cell
              </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Navbar>
    </>
  );
};

Header.propTypes = {
  showMenu: PropTypes.any,
  menuOnclick: PropTypes.any,
};

export default Header;
