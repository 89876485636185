import React, { useEffect, useState } from 'react';
import { Container, Table, Card } from 'react-bootstrap';
import Header from '../components/Header';
import CustomBarChart from '../components/CustomBarChart';
import CustomSidebar from '../components/CustomSidebar';
import history from '../services/history';
import { REPORTS } from '../graphql/query/reports';
import { useLazyQuery, useQuery } from '@apollo/client';
import LoadingScreen from '../components/LoadingScreen';
import ErrorScreen from '../components/ErrorScreen';
import moment from 'moment';
import { faBookmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { slugToTitle } from './SummaryReport';
import ChasingCellFilter from '../components/ChasingCellFilter';
import { GET_OFFICER_PROFILE } from '../graphql/query/officerProfile';
import ChasingCellBackButton from '../components/ChasingCellBackButton';

const statusLabels = [
  {
    label: 'Received',
    // color: '#0564AC',
    showOnlyInTable: true,
    showBtn: false,
  },
  {
    label: 'Approved',
    slug: 'approved',
    color: '#1C9A5B',
    showOnlyInTable: false,
    showBtn: true,
  },
  {
    label: 'Rejected',
    slug: 'rejected',
    color: '#FF4E4E',
    showOnlyInTable: false,
    showBtn: true,
  },
  {
    label: 'Completed',
    showOnlyInTable: true,
    vertical: true,
    customComponent: () => (
      <div className="d-flex flex-row align-items-center">
        <HorizontalLabel label={'R'} color={'#FF4E4E'} />
        <FontAwesomeIcon
          icon={faPlus}
          style={{ color: '#3F4A50', fontSize: 8 }}
          className="mx-3"
        />
        <HorizontalLabel label={'A'} color={'#1C9A5B'} />
      </div>
    ),
  },
  {
    label: 'Verification Under Process',
    slug: 'verification',
    color: '#0564AC',
    showOnlyInTable: false,
    showBtn: true,
  },
  // {
  //   label: 'Green',
  //   color: '#1C9A5B',
  //   showOnlyInTable: true,
  //   vertical: true,
  // },
  // {
  //   label: 'Orange',
  //   color: '#FA7847',
  //   showOnlyInTable: true,
  //   vertical: true,
  // },
  // {
  //   label: 'Red',
  //   color: '#FA4747',
  //   showOnlyInTable: true,
  //   vertical: true,
  // },
  // {
  //   label: 'Total U/P',
  //   showOnlyInTable: true,
  //   vertical: true,
  //   customComponent: () => (
  //     <div className="d-flex flex-row align-items-top">
  //       <VerticalLabel label={'Green'} color={'#1C9A5B'} />
  //       <FontAwesomeIcon
  //         icon={faPlus}
  //         style={{ color: '#3F4A50', fontSize: 8 }}
  //         className="mx-1 mt-1"
  //       />
  //       <VerticalLabel label={'Orange'} color={'#FA7847'} />
  //       <FontAwesomeIcon
  //         icon={faSlash}
  //         style={{ color: '#3F4A50', transform: 'rotate(90deg)', fontSize: 8 }}
  //         className="mx-1 mt-1"
  //       />
  //       <VerticalLabel label={'Red'} color={'#FA4747'} />
  //     </div>
  //   ),
  // },
  {
    label: 'With Citizen',
    slug: 'with_citizen',
    color: '#FFD700',
    showOnlyInTable: false,
    showBtn: true,
  },
];

const VerticalLabel = (props) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="Neutra-Demi font-size-12" style={{ color: '#3F4A50' }}>
        {props.label}
      </div>
      {props.color ? (
        <FontAwesomeIcon
          icon={faBookmark}
          style={{
            color: props.color,
            transform: 'rotate(90deg)',
            height: '10px',
            width: '10px',
          }}
          className="fa-2x"
        />
      ) : (
        props.customComponent()
      )}
    </div>
  );
};

const HorizontalLabel = (props) => {
  return (
    <>
      <span
        className="d-inline-block circle-dot mr-1"
        style={{ borderColor: props.color }}
      />
      <span className="Neutra-Demi font-size-12" style={{ color: '#3F4A50' }}>
        {props.label}
      </span>
    </>
  );
};

const ChartData = ({ slug, statuData }) => {
  const { approved, rejected, with_citizen, under_process, } = statuData;
  return (
    <CustomBarChart
      title={slugToTitle[slug]}
      data={[approved, rejected, under_process, with_citizen]}
      colors={['#1C9A5B', '#FF4E4E', '#0564AC', '#FFD700']}
    />
  );
};

const TableData = ({ slug, statuData, onClick, }) => {
  const { received, approved, rejected, with_citizen, under_process } = statuData;

  return (
    <tr className="dashboard-table-row">
      <td
        className="text-left"
        style={{ color: '#3F4A50', opacity: slug === 'Total' ? 1 : 0.73 }}
      >
        {slugToTitle[slug]}
      </td>
      <td>{received}</td>
      <td
        style={{ cursor: "pointer" }}
        onClick={() => onClick("approved", slug === 'Total' ? '' : slug)}
      >
        {approved}
      </td>
      <td
        style={{ cursor: "pointer" }}
        onClick={() => onClick("rejected", slug === 'Total' ? '' : slug)}
      >
        {rejected}
      </td>
      <td>{rejected + approved}</td>
      {/* <td>50</td>
      <td>50</td>
      <td>50</td>
      <td>150</td> */}
      <td
        style={{ cursor: "pointer" }}
        onClick={() => onClick("verification", slug === 'Total' ? '' : slug)}
      >
        {under_process}
      </td>
      <td
        style={{ cursor: "pointer" }}
        onClick={() => onClick("with_citizen", slug === 'Total' ? '' : slug)}
      >
        {with_citizen}
      </td>
    </tr>
  );
};

const Dashboardnew = ({ }) => {
  const [reports, setReports] = useState(null);
  const [year, setYear] = useState(moment().format('YYYY'));
  const [filterAuthority, setFilterAuthority] = useState([]);
  const [filterDistricts, setFilterDistricts] = useState([]);
  const [filterULBS, setFilterULBS] = useState([]);
  const [applyFilter, setApplyFilter] = useState(false);

  const { data: officerData, } = useQuery(GET_OFFICER_PROFILE);
  const [getReports, { loading, error, data, refetch }] = useLazyQuery(REPORTS);

  useEffect(() => {
    getReports({
      fetchPolicy: 'network-first',
      variables: {
        type: 'CONSOLIDATED',
        ulbs: officerData?.me?.ulbName || filterULBS.join(),
        startDate: `2020-01-01`,
        endDate: moment().format('YYYY-MM-DD'),
      },
    });
    setApplyFilter(false);
    if (officerData?.me?.authority) {
      setFilterAuthority([officerData?.me?.authority || []]);
    }
    if (officerData?.me?.district) {
      setFilterDistricts([officerData?.me?.district || []]);
    }
  }, [year, applyFilter, officerData]);

  useEffect(() => {
    if (data) {
      let reports = {
        ...data.reports,
        Total: {
          received: Object.keys(data.reports)
            .map((o) => data.reports[o].received)
            .reduce((a, c) => a + c, 0),
          approved: Object.keys(data.reports)
            .map((o) => data.reports[o].approved)
            .reduce((a, c) => a + c, 0),
          rejected: Object.keys(data.reports)
            .map((o) => data.reports[o].rejected)
            .reduce((a, c) => a + c, 0),
          with_citizen: Object.keys(data.reports)
            .map((o) => data.reports[o].with_citizen)
            .reduce((a, c) => a + c, 0),
          under_process: Object.keys(data.reports)
            .map((o) => data.reports[o].under_process)
            .reduce((a, c) => a + c, 0),
        },
      };
      setReports(reports);
    }
  }, [data]);

  const handleViewAll = (label, applicationType = '') => {
    history.push(`/summary/${label}`, {
      originComponent: 'Dashboard',
      applicationType,
      ULB: officerData?.me?.ulbName || filterULBS.join(),
    });
  };

  if (loading) return <LoadingScreen />;

  if (error) {
    return <ErrorScreen message={error} retry={refetch} />;
  }

  if (!reports) {
    return (
      <div className="dashboard-new-wrapper">
        <Header />
        <div className="row m-0">
          <CustomSidebar noOverlay={true} isOpen={true} />
        </div>
      </div>
    )
  };

  return (
    <div className="dashboard-new-wrapper">
      <Header />
      <div className="row m-0">
        <CustomSidebar noOverlay={true} isOpen={true} class="col-md-3" />
        <Container className="custom-container my-4 col-md-9">
          <ChasingCellBackButton />
          <div className="row justify-content-between">
            <div className="col-5 summary-dashboard-title ml-3">Dashboard</div>
            <div className="col-6 summary-dashboard-status-container mr-3">
              {statusLabels
                .filter((o) => !o.showOnlyInTable)
                .map((item, index) => {
                  return (
                    <div key={index} className="d-flex align-items-center">
                      <div
                        className="circle-dot mr-1"
                        style={{ borderColor: item.color }}
                      />
                      <span className="Neutra-Demi">{item.label}</span>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="col-lg-12 mt-4">
            <div className="row">
              {Object.keys(reports).map((slug, index) => {
                if (slug === 'Total') return null;
                return (
                  <div key={index} className="col-md-4">
                    <ChartData slug={slug} statuData={reports[slug]} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-5">
            <div className="summary-dashboard-title ml-3">
              Summary of TG-bPASS (Building Plans)
            </div>
            {!(officerData?.me?.ulbName) ?
              <ChasingCellFilter
                filterAuthority={filterAuthority}
                setFilterAuthority={setFilterAuthority}
                filterDistricts={filterDistricts}
                setFilterDistricts={setFilterDistricts}
                filterULBS={filterULBS}
                setFilterULBS={setFilterULBS}
                applyFilter={setApplyFilter}
                disable={officerData?.me?.authority ? true : false}
                disableDistrict={officerData?.me?.district ? true : false}
              /> : null}
            <Card
              className="col m-3 mt-4 p-5 custom-card"
              style={{
                borderRadius: 10,
                overflowX: 'scroll',
                overflowY: ' visible',
              }}
            >
              <Table borderless className="Neutra-Demi custom-table width-auto">
                <thead>
                  <tr>
                    <th className="Neutra-Demi font-size-16 min-width-160">
                      Categories
                    </th>
                    {statusLabels.map((item, index) => {
                      return (
                        <th
                          key={index}
                          className="flex-column text-center min-width-160"
                        >
                          <div className="mb-2">
                            {item.vertical ? (
                              <VerticalLabel
                                label={item.label}
                                color={item.color}
                                customComponent={item.customComponent}
                              />
                            ) : (
                              <HorizontalLabel
                                label={item.label}
                                color={item.color}
                              />
                            )}
                          </div>
                          {item.showBtn ? (
                            <div
                              onClick={() => handleViewAll(item.slug)}
                              className="chasing-cell-applications-view-btn"
                            >
                              View All
                            </div>
                          ) : null}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="text-center">
                  {Object.keys(reports).map((slug, index) => {
                    return (
                      <TableData
                        key={index}
                        slug={slug}
                        statuData={reports[slug]}
                        onClick={handleViewAll}
                      />
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default Dashboardnew;
