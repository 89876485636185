import React from 'react';
import { Container, Table, Card } from 'react-bootstrap';
import Header from '../components/Header';
import CustomSidebar from '../components/CustomSidebar';
import history from '../services/history';

const statusLabels = [
  {
    label: 'Received',
    color: '#0564AC',
    imgBgColor: '#E3F3FF',
    imgBorderColor: '#2D7CB9',
    value: 1300,
    showOnlyInTable: false,
  },
  {
    label: 'Penalty levied',
    color: '#1C9A5B',
    imgBgColor: '#E0F2FF',
    imgBorderColor: '#48B1FF',
    value: 575,
    showOnlyInTable: false,
  },
  {
    label: 'Penalty amt received',
    color: '#FF4E4E',
    imgBgColor: '#E2FFF1',
    imgBorderColor: '#4CAF7E',
    value: 483000,
    showOnlyInTable: false,
  },
  {
    label: 'Penalty to be received',
    color: '#FF8C28',
    imgBgColor: '#FFE1E1',
    imgBorderColor: '#FF4E4E',
    value: 242000,
    showOnlyInTable: false,
  },
];

const Shortfall = ({}) => {
  const handleViewAll = (label) => {
    if (label == 'Approved') {
      history.push(`/summary/${label.toLowerCase()}`);
    }
  };
  return (
    <div className="dashboard-new-wrapper">
      <Header />
      <div className="row m-0">
        <CustomSidebar noOverlay={true} isOpen={true} />
        <Container className="custom-container my-4 col-9">
          <div className="row justify-content-between">
            <div className="col-5 summary-dashboard-title ml-3">
              Building Plans – Shortfalls
            </div>
          </div>
          <div className="d-flex align-items-center mt-4 px-3">
            {statusLabels.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-around align-items-center p-4 building-plan-widget mr-2"
                >
                  <div
                    className="building-plan-widget-image"
                    style={{
                      background: item.imgBgColor,
                      border: `4px solid ${item.imgBorderColor}`,
                    }}
                  >
                    <img
                      src={require(`../assets/images/dashboard-new/graph${
                        index + 1
                      }.svg`)}
                    />
                  </div>
                  <div>
                    <p
                      className="Neutra-Demi font-size-15"
                      style={{ color: '#3F4A50' }}
                    >
                      {item.label}
                    </p>
                    <p
                      className="Neutra-Demi font-size-34"
                      style={{ color: item.color }}
                    >
                      {item.value}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-5">
            <div className="summary-dashboard-title ml-3">Summary</div>
            <Card
              className="col m-3 mt-4 p-5 custom-card"
              style={{ borderRadius: 10 }}
            >
              <Table borderless className="Neutra-Demi custom-table">
                <thead>
                  <tr>
                    <th className="Neutra-Demi font-size-16">Dept</th>
                    {statusLabels.map((item, index) => {
                      return (
                        <th key={index} className="flex-column text-center">
                          <div className="mb-2">
                            <span
                              className="d-inline-block circle-dot mr-1"
                              style={{ borderColor: item.color }}
                            />
                            <span
                              className="Neutra-Demi font-size-12"
                              style={{ color: '#3F4A50' }}
                            >
                              {item.label}
                            </span>
                          </div>
                          {item.showBtn ? (
                            <div
                              onClick={() => handleViewAll(item.label)}
                              className="chasing-cell-applications-view-btn"
                            >
                              View All
                            </div>
                          ) : null}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="text-center">
                  <tr className="dashboard-table-row">
                    <td
                      className="text-left"
                      style={{ color: '#3F4A50', opacity: 0.73 }}
                    >
                      Irrigation
                    </td>
                    <td>500</td>
                    <td>200</td>
                    <td>100</td>
                    <td>100</td>
                  </tr>
                  <tr className="dashboard-table-row">
                    <td
                      className="text-left"
                      style={{ color: '#3F4A50', opacity: 0.73 }}
                    >
                      R&B
                    </td>
                    <td>200</td>
                    <td>150</td>
                    <td>50</td>
                    <td>0</td>
                  </tr>
                  <tr className="dashboard-table-row">
                    <td
                      className="text-left"
                      style={{ color: '#3F4A50', opacity: 0.73 }}
                    >
                      R&B
                    </td>
                    <td>600</td>
                    <td>300</td>
                    <td>200</td>
                    <td>100</td>
                  </tr>
                  <tr className="dashboard-table-row">
                    <td className="text-left" style={{ color: '#3F4A50' }}>
                      Grand Total
                    </td>
                    <td>1300</td>
                    <td>650</td>
                    <td>350</td>
                    <td>200</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default Shortfall;
