import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { AppContext } from '../AppContext'
import CorrectionCards from '../components/CorrectionVerification'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import { APPLICATION_CORRECTION } from '../graphql/query/application_Correction'
import { FILTER_LIST_CORRECTION, FILTER_LIST_ENUM } from '../util/helperFunctions'
import history from '../services/history';
import ErrorScreen from '../components/ErrorScreen';
import LoadingScreen from '../components/LoadingScreen/index';

function CorrectionVerification() {
  const [currentPage, setCurrentPage] = useState(1);
  const { permissionLevel, innerCorrectionValue } = useContext(AppContext);
console.log(permissionLevel,'permissionLevel')
  const [actualFilter, setActualFilter] = useState( 
    innerCorrectionValue ||FILTER_LIST_CORRECTION.correction
  );
  console.log(actualFilter,"actualFilter");
  
   useEffect(()=>{
   
    setActualFilter(innerCorrectionValue)
    
   },[innerCorrectionValue])

  const  {loading, error,data:applicationCorrection,refetch } = useQuery(
    APPLICATION_CORRECTION,
    {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables:{
      page:currentPage,
      status: actualFilter
    },
  })


  let sortedData;
  if (applicationCorrection && applicationCorrection.applicationsCorrectionRequest?.[0]?.records.length) {
    const cloneOfficersApplications = [
      ...applicationCorrection.applicationsCorrectionRequest?.[0]?.records,
    ];

    sortedData = cloneOfficersApplications;
    // if (
    //   permissionLevel === PERMISSIONS_LEVELS.SITE_VERIFICATION ||
    //   permissionLevel === PERMISSIONS_LEVELS.TITLE_VERIFICATION
    // ) {
    //   sortedData = sortedData.filter(
    //     (app) => app.status !== 'FEE_VERIFICATION' // update this if fee-verification status is comming in another form
    //   );
    // }
  }

  return (
    <>
            <Header/>
      <Sidebar/>
      {loading && <LoadingScreen />} 
        {error && <ErrorScreen message={error} retry={refetch} />}

   { applicationCorrection && ( 
     <div className="main-content">
      <CorrectionCards
      data={sortedData}
      loading={loading}
      error={error}
      refetch={refetch}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      totalPages={applicationCorrection?.applicationsCorrectionRequest[0]?.totalPages||1}
      totalCount = {applicationCorrection?.applicationsCorrectionRequest[0]?.totalCount}
      actualFilter={actualFilter}
      />

      </div>
     )
      }   
       
      </>
  )
}

export default CorrectionVerification