import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import CorrectionView from '../components/CorrectionView'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import { APPLICATION_CORRECTION_DETAILS } from '../graphql/query/applicationCorrectionData';
import { FILTER_LIST_CORRECTION, FILTER_LIST_ENUM } from '../util/helperFunctions';

function CorrectionVerificationView() {
    const { permissionLevel, innerCorrectionValue } = useContext(AppContext);
    const [actualFilter, setActualFilter] = useState(
        innerCorrectionValue || FILTER_LIST_CORRECTION.correction
      );
      
       useEffect(()=>{
       
        setActualFilter(innerCorrectionValue)
        
       },[innerCorrectionValue])

  return (
    <div>
                    <Header />
      <Sidebar />
      <CorrectionView />
    </div>
  )
}

export default CorrectionVerificationView